import { IOrganizationType } from '@commandbar/internal/middleware/types';

class OrganizationService {
  private organization?: IOrganizationType;

  getOrganization() {
    return this.organization;
  }

  setOrganization(org?: IOrganizationType) {
    this.organization = org;
  }
}

export const organizationService = new OrganizationService();
