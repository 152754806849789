import { ITheme } from '@commandbar/internal/client/theme';
import React from 'react';

export const UnderlineFormatter = (props: { prefix: string; suffix: string; delimiter: string; theme: ITheme }) => {
  const { prefix, suffix, delimiter, theme } = props;
  return (
    <div>
      <span>{`${prefix}${delimiter} `}</span>
      <span
        style={{
          textDecoration: suffix && `${theme.base.primary} solid underline`,
          textUnderlinePosition: 'under',
          opacity: 0.7,
        }}
      >
        {suffix || '_'}
      </span>
    </div>
  );
};
