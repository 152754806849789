import React, { CSSProperties } from 'react';

interface Props {
  color: CSSProperties['color'];
  opacity: CSSProperties['opacity'];
  size: CSSProperties['width'] | CSSProperties['height'];
}

const MultiSelectDefaultIcon = (props: Props) => {
  return (
    <div
      style={{
        border: `1px solid ${props.color}`,
        opacity: props.opacity,
        borderRadius: '50%',
        height: props.size,
        width: props.size,
        display: 'inline-block',
      }}
    />
  );
};

export default MultiSelectDefaultIcon;
