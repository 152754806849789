/** @jsx jsx */

import { ITheme } from '@commandbar/internal/client/theme';
import { jsx } from '@emotion/core';
import * as React from 'react';
import { Option } from '../../../engine/option';
import a11y from '../../../util/a11y';
import { useAction } from '../../../hooks/useAction';
import * as App from '../../../store/app/actions';
import { CommandOptionRow } from '../../option/CommandOptionRow';
import { ParameterOptionRow } from '../../option/ParameterOptionRow';
import { VirtualItem } from 'react-virtual/types';
import { useCommandBarTheme } from '../../../hooks/useCommandBarTheme';
import { isCommandOption, isParameterOption } from '../../../store/engine';
import { useStore } from '../../../hooks/useStore';
import { fallbackGroup } from '../../../engine/OptionGroup';
import { optionIsInGrid } from '../../../store/app';
import useTheme from '../../../hooks/useTheme';

interface IProps {
  option: Option;
  onOptionHover?: (index: number) => void;
  isFocused: boolean;
  isError: boolean;
  index: number;
}

const selectOptionStyles = (state: { isDisabled: boolean; isGridOption: boolean }, theme: ITheme): any => {
  const borderHighlightStyles: React.CSSProperties = {
    borderLeft: '4px solid transparent',
  };

  const style = state.isGridOption
    ? {
        // We need to force non-focused options to have a transparent background
        // in order to show AnimatedOptionFocus behind it
        ...{ background: 'transparent' },
        ...theme.menuGridOption,
        ...(state.isDisabled ? theme.menuGridOptionDisabled : {}),
      }
    : {
        ...theme.option,
        // We need to force non-focused options to have a transparent background
        // in order to show AnimatedOptionFocus behind it
        ...{ background: 'transparent' },
        ...(state.isDisabled ? theme.optionDisabled : {}),
      };

  let backgroundColor = state.isDisabled ? theme.optionDisabled.background : theme.optionSelected.background;

  if (state.isGridOption) {
    backgroundColor = state.isDisabled
      ? theme.menuGridOptionDisabled.background
      : theme.menuGridOptionSelected.background;
  }

  return {
    ...borderHighlightStyles,
    userSelect: 'none',
    transition: 'background-color 0s',
    borderRadius: style.borderRadius,
    paddingTop: style.paddingTop,
    paddingBottom: style.paddingBottom,
    paddingLeft: style.paddingLeft,
    paddingRight: style.paddingRight,
    marginLeft: style.marginLeft,
    marginRight: style.marginRight,
    minHeight: style.minHeight,
    color: style.color,
    fontSize: style.fontSize,
    fontFamily: style.fontFamily,
    borderBottom: `${style.borderWidth} ${style.borderColor} solid`,
    backgroundColor: style.background,
    display: 'flex',
    alignItems: 'center',
    ':active': {
      backgroundColor,
    },
    ...(state.isGridOption && {
      height: '100%',
    }),
  };
};

const SelectOption = (props: IProps) => {
  const { index, isError, isFocused, onOptionHover } = props;
  const { theme } = useTheme();
  const isFallback = props.option.groupKey === fallbackGroup().key;

  const refocusCommandbarInput = useAction((_) => _.refContainer?.current?.focus());

  const onMouseMove = React.useCallback(() => {
    if (onOptionHover) onOptionHover(index);
  }, [onOptionHover, index]);

  const onClick = useAction(
    (_, e: React.MouseEvent<HTMLDivElement>) => {
      App.selectOption(_, props.option, isFallback, e);

      // HACK: Normally, clicking on a command will change the Options, which will have the
      // side effect of focusing the main Bar input text field. Or, it will execute the command
      // and close the bar, and then the focus state of the Bar input text field won't matter.
      //
      // However, if clicking the command *doesn't* cause a change in the options or close the Bar,
      // (e.g. this happens if the command is disabled), then the Bar input looses focus.
      //
      // We can fix this HACK when/if we re-factor Bar input focus handling
      refocusCommandbarInput();
    },
    [props.option],
  );

  const isGridOption = optionIsInGrid(useStore(), props.option);

  let optionRow: React.ReactNode;
  if (isCommandOption(props.option)) {
    optionRow = <CommandOptionRow isError={isError} isFocused={isFocused} option={props.option} />;
  } else if (isParameterOption(props.option)) {
    optionRow = <ParameterOptionRow isFocused={isFocused} option={props.option} />;
  } else {
    console.warn('attempted to render invalid option', props.option);
    optionRow = null;
  }

  return (
    // eslint-disable-next-line
    <div
      data-testid="select-option"
      /* isFocused is always false, because the focused option styles are used for the AnimatedOptionFocus */
      css={selectOptionStyles(
        {
          isDisabled: props.option.optionDisabled.isDisabled,
          isGridOption,
        },
        theme,
      )}
      // We want the onClick to happen on the parent container when in grid view
      onClick={isGridOption ? undefined : onClick}
      onMouseMove={onMouseMove}
      id={a11y.optionContentId(index)}
      aria-labelledby={'group-' + props.option.groupKey}
    >
      {optionRow}
    </div>
  );
};

/**
 * Used to animate focus change in a list of options
 * AnimatedOptionFocus is an absolutely positioned element that is translated in Y-direction
 *  to the top of the currently focused option.
 * It borrows the styles of a focused option.
 */
export const AnimatedOptionFocus = (props: { virtualItems: VirtualItem[]; focusedIndex: number }) => {
  const theme = useCommandBarTheme();
  const focusedItem = props.virtualItems.find((v) => v.index === props.focusedIndex) ?? null;
  if (!focusedItem) return <div />;

  const { borderHighlight, ...style } = {
    ...theme.option,
    ...theme.optionSelected,
  };

  const styles = {
    ...(borderHighlight.includes('gradient')
      ? {
          borderLeft: '4px solid',
          borderImage: `${borderHighlight} 1`,
          borderImageWidth: '0px 0px 0px 4px',
        }
      : {
          borderLeft: `4px solid ${borderHighlight}`,
        }),

    borderRadius: style.borderRadius,
    borderBottom: `${style.borderWidth} ${style.borderColor} solid`,
    backgroundColor: style.background,
    height: '100%',
  };

  // "padding" here creates a margin around the highlight element
  // falls back to the old "option.margin{Left,Right}" to preserve existing behavior
  const padding = (() => {
    if (theme.optionSelected.highlightMargin !== '0px') return theme.optionSelected.highlightMargin;

    if (theme.option.marginLeft !== '0px' || theme.option.marginRight !== '0px') {
      return `0px ${theme.option.marginRight} 0px ${theme.option.marginLeft}`;
    }

    return '0px';
  })();

  return (
    <div
      style={{
        width: '100%',
        height: focusedItem.size,
        top: 0,
        left: 0,
        position: 'absolute',
        transform: `translateY(${focusedItem.start}px)`,
        transition: '0.1s transform ease-out',
        willChange: 'transform',
        zIndex: 0,
        padding,
      }}
    >
      <div style={styles} />
    </div>
  );
};

export default SelectOption;
