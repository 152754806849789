import React, { CSSProperties } from 'react';

type CTAButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> & { styleOverrides?: CSSProperties };

const CTAButton = ({ styleOverrides, children, ...rest }: CTAButtonProps) => (
  <button
    style={{
      width: '100%',
      border: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      ...styleOverrides,
    }}
    {...rest}
  >
    <span
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {children}
    </span>
  </button>
);

export default CTAButton;
