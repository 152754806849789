export const isStandaloneEditorURL = (hostURL: string) => {
  const hostedRegex = /^https:\/\/[a-zA-Z0-9]+\.commandbar\.com\/editor/;
  const previewDeploymentRegex = /https:\/\/\d+--commandbar-com-branch\.netlify\.app\/editor/;

  const DEV_STANDALONE_EDITOR_URL = 'http://localhost:4000/editor';

  return (
    hostURL.startsWith(DEV_STANDALONE_EDITOR_URL) || hostedRegex.test(hostURL) || previewDeploymentRegex.test(hostURL)
  );
};

export const isStandaloneEditor = (() => {
  if (typeof window.location.href !== 'string') {
    return false;
  }

  return isStandaloneEditorURL(window.location.href);
})();

export const isHelpHubMarketingSiteURL = (hostURL: string) => {
  const HELPHUB_MARKETING_SITE_URL = 'https://helphub.commandbar.com';
  const DEV_STANDALONE_EDITOR_URL = 'http://localhost:5001';

  return hostURL.startsWith(DEV_STANDALONE_EDITOR_URL) || hostURL.startsWith(HELPHUB_MARKETING_SITE_URL);
};

export const isHelpHubMarketingSite = (() => {
  if (typeof window.location.href !== 'string') {
    return false;
  }
  return isHelpHubMarketingSiteURL(window.location.href);
})();
