import { getYoutubePlayer, getLoomPlayer, getVimeoPlayer } from './videoPlayer';

const getPlayer = (url: string, autoPlay?: boolean) => {
  try {
    const { hostname } = new URL(url);

    switch (hostname) {
      case 'www.youtube.com': {
        return getYoutubePlayer(url, autoPlay);
      }
      case 'www.loom.com': {
        return getLoomPlayer(url);
      }
      case 'vimeo.com': {
        return getVimeoPlayer(url, autoPlay);
      }
      default: {
        return undefined;
      }
    }
  } catch (err) {
    return undefined;
  }
};

export default getPlayer;
