/** @jsx jsx */
import * as React from 'react';
import { jsx, keyframes } from '@emotion/core';

import { useStore } from '../../../hooks/useStore';
import { useDelayUnmount } from '../../../hooks/useDelayUnmount';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { placeholderStyles } from './placeholderHelpers';

interface IProps {
  isFocused: boolean;
}

const AnimatedPlaceholder = ({ isFocused }: IProps) => {
  const { engine } = useStore();
  const { placeholders } = engine;
  const [index, setIndex] = React.useState<number>(0);

  const [isFirst, setIsFirst] = React.useState(true);
  const [isPreviousMounted, setIsPreviousMounted] = React.useState(true);
  const [isNextMounted, setNextMounted] = React.useState(false);

  const shouldShowPrevious = useDelayUnmount(isPreviousMounted, 500);
  const shouldShowNext = useDelayUnmount(isNextMounted, 500);
  const { theme }: { theme: ITheme } = useTheme();
  const styles = placeholderStyles(theme);

  React.useEffect(() => {
    if (isFocused) {
      const intervalID = setInterval(() => {
        setIndex((index) => (index === placeholders.length - 1 ? 0 : index + 1));
        setIsPreviousMounted((prev) => !prev);
        setNextMounted((next) => !next);
        setIsFirst(false);
      }, 3500);
      return () => {
        clearInterval(intervalID);
      };
    }
  }, [isFocused, placeholders]);

  return (
    <React.Fragment>
      {shouldShowPrevious && (
        <div
          data-testid="commandbar-input-placeholder"
          css={{
            ...styles,
            position: 'absolute',
            transform: 'translateY(0)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: '1.3em',
            textOverflow: 'ellipsis',
            width: '100%',
            zIndex: 0,
            animation: isFirst ? '' : `${isPreviousMounted ? enter : exit} 0.5s cubic-bezier(0.25, 0.85, 0.3, 0.9)`,
          }}
        >
          {placeholders[index].text}
        </div>
      )}
      {shouldShowNext && (
        <div
          data-testid="commandbar-input-placeholder"
          css={{
            ...styles,
            position: 'absolute',
            transform: 'translateY(0)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: '1.3em',
            textOverflow: 'ellipsis',
            width: '100%',
            zIndex: 0,
            animation: `${isNextMounted ? enter : exit} 0.5s cubic-bezier(0.25, 0.85, 0.3, 0.9)`,
          }}
        >
          {placeholders[index].text}
        </div>
      )}
    </React.Fragment>
  );
};

const enter = keyframes`
0% {
  opacity: 0;
  transform: translateY(50px);
}
`;

const exit = keyframes`
100% {
  opacity: 0;
  transform: translateY(-50px);
}
`;

export default AnimatedPlaceholder;
