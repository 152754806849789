import React, { CSSProperties } from 'react';
import { CgCheck } from 'react-icons/cg';

interface Props {
  color: CSSProperties['color'];
  size: CSSProperties['width'] | CSSProperties['height'];
}

const MultiSelectDefaultSelectedIcon = ({ color, size = '18px' }: Props) => {
  return (
    <div
      style={{
        border: `2px solid ${color}`,
        borderRadius: '50%',
        height: size,
        width: size,
        display: 'inline-block',
      }}
    >
      <CgCheck size={typeof size === 'string' ? `${parseFloat(size) - 4}px` : `${size - 4}px`} style={{ color }} />
    </div>
  );
};

export default MultiSelectDefaultSelectedIcon;
