/** @jsx jsx  */
import { Minus, Expand02 } from '@commandbar/design-system/icons/react';
import { css, jsx } from '@emotion/core';
import React, { CSSProperties, useEffect, useReducer } from 'react';
import * as Engine from '../../store/engine/actions';
import { useAction } from '../../hooks/useAction';
import { useStyles } from './useStyles';
import { ReactComponent as DotGridIcon } from '../../img/dotsgrid.svg';
import { ReactComponent as CloseIcon } from '../../img/xclose.svg';
import { isStandaloneEditor, isHelpHubMarketingSite } from '@commandbar/internal/util/location';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';

import HelpHub from './HelpHub';
import { Resizer } from './Resizer';

import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { useStore } from '../../hooks/useStore';

const HelpHubModal = ({ parentCallbackRef }: { parentCallbackRef: (element: HTMLDivElement | null) => void }) => {
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);
  const {
    engine: {
      helpHub: { hubDoc },
    },
  } = useStore();

  const [isMinimized, setIsMinimized] = React.useState(false);
  const isHelpHubMinimized = isMinimized && !isStandaloneEditor && !isHelpHubMarketingSite;
  const styles = useStyles();
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState<string | undefined>(undefined);
  const [width, setWidth] = React.useState<string | undefined>(undefined);

  // un-minimize whenever we change the current help doc (ENG-4706)
  useEffect(() => {
    setIsMinimized(false);
  }, [hubDoc?.commandID]);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  // HACK: if this is the helphub marketing site, need to force an update on window resize to reposition HelpHub modal
  React.useEffect(() => {
    if (!isHelpHubMarketingSite) return;
    window.addEventListener('resize', forceUpdate);
    return () => window.removeEventListener('resize', forceUpdate);
  }, []);

  const modalStyle = ((): CSSProperties => {
    let baseStyle: CSSProperties = styles.modalContainer;
    if (isHelpHubMinimized) {
      baseStyle = {
        ...baseStyle,
        height: 'auto',
      };
    } else if (isStandaloneEditor) {
      const maxWidth = document.getElementById('commandbar-editor-right-panel')?.offsetWidth;
      const maxHeight = document.getElementById('commandbar-editor-right-panel')?.offsetHeight;
      baseStyle = {
        ...baseStyle,
        top: '0',
        left: '816px',
        right: '0',
        margin: 'auto',
        width:
          maxWidth && parseFloat(styles.modalContainer.width) < maxWidth - 50
            ? styles.modalContainer.width
            : `calc(${maxWidth}px - 100px)`,
        height:
          maxHeight && parseFloat(styles.modalContainer.height) < maxHeight - 50
            ? styles.modalContainer.height
            : `calc(${maxHeight}px - 100px)`,
      };
    } else if (isHelpHubMarketingSite) {
      const placeholder = document.getElementById('helphub_placeholder__container');

      if (!!placeholder) {
        const { top, left, width, height } = placeholder.getBoundingClientRect();

        baseStyle = {
          ...baseStyle,
          position: 'absolute',
          top: `${top + window.scrollY}px`,
          left: `${left}px`,
          width: `${width}px`,
          height: `${height}px`,
        };
      }
    } else if (isMobile()) {
      baseStyle = {
        ...baseStyle,
        position: 'fixed',
        bottom: '0',
        right: '0',
        height: '100%',
        width: '100%',
        borderRadius: '0',
      };
    }

    // override with the width and height from the Resizer when NOT minimized
    if (!isHelpHubMinimized) return { ...baseStyle, ...(height && { height }), ...(width && { width }) };
    else return baseStyle;
  })();

  React.useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        toggleHelpHubVisible();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [toggleHelpHubVisible]);

  React.useEffect(() => {
    dispatchCustomEvent('commandbar-helphub-shown', {});
  }, []);

  /**
   * Moves the HH back into vertical view if the top has been cut off
   * This can happen when the HH is minimized, dragged upwards, and then maximized again
   */
  React.useEffect(() => {
    if (!modalRef.current) return;
    const bbox = modalRef.current.getBoundingClientRect();
    if (bbox.top > 0) return;

    const transform = window.getComputedStyle(modalRef.current).getPropertyValue('transform');
    // Use a regular expression to find the matrix component
    const translationMatrix = /matrix.*\((.+)\)/.exec(transform)?.[1].split(', ');
    const translateX = parseFloat(translationMatrix?.[4] ?? '0');
    const translateY = parseFloat(translationMatrix?.[5] ?? '0') - bbox.top;

    // Set the transform CSS property
    modalRef.current.style.transform = `translate(${translateX}px, ${translateY}px)`;
  }, [isHelpHubMinimized /* NOTE: only need to re-evaluate this when the HH is un-minimized */]);

  return (
    <div
      style={modalStyle}
      ref={(node) => {
        modalRef.current = node;
        parentCallbackRef(node);
      }}
      id="helphub-modal"
      className={`commandbar-helphub${!isHelpHubMinimized ? ' expanded' : ''}`}
    >
      {modalRef.current && !isHelpHubMarketingSite && (
        <Resizer resizeElement={modalRef.current} setHeight={setHeight} setWidth={setWidth} />
      )}

      <div css={{ ...styles.modal, ...(isMobile() && { borderRadius: '0' }) }}>
        <div css={styles.headerContainer}>
          <div css={styles.subheader} data-draggable="drag-zone">
            <div
              css={{
                ...styles.headerIcon,
                display: 'flex',
                userSelect: 'none',
                cursor: 'move',
              }}
            >
              <DotGridIcon
                css={[
                  css`
                    path {
                      stroke: ${styles.headerIcon.color};
                      opacity: 1;
                    }
                  `,
                ]}
              />
            </div>
            <div css={styles.headerText}>{styles.strings.title}</div>
          </div>
          <div css={styles.headerActionIcons}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
            <div
              css={{ ...styles.headerIcon, ...styles.headerMinimizeIcon }}
              onClick={() => {
                if (isHelpHubMarketingSite || isStandaloneEditor) return;
                setIsMinimized(!isMinimized);
              }}
            >
              {isHelpHubMinimized ? <Expand02 width={16} /> : <Minus width={16} />}
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
            <div css={{ ...styles.headerIcon, ...styles.headerCloseIcon }} onClick={toggleHelpHubVisible}>
              {!isHelpHubMarketingSite && (
                <CloseIcon
                  css={[
                    css`
                      path {
                        stroke: ${styles.headerIcon.color};
                        opacity: 1;
                      }
                    `,
                  ]}
                />
              )}
            </div>
          </div>
        </div>
        {!isHelpHubMinimized && <HelpHub />}
      </div>
    </div>
  );
};

export default HelpHubModal;
