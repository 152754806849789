export const isHexColor = (str: string) => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(str);
};

// Works with both RGB and RGBA colors
export const isRGBColor = (str: string) => {
  return /(rgb)a?\((\s*\d+%?\s*?,?\s*){2}(\s*\d+%?\s*?,?\s*\)?)(\s*,?\s*\/?\s*(0?\.?\d+%?\s*)?|1|0)?\)$/i.test(str);
};

export const isSupportedColor = (str?: string): boolean => {
  if (!!str) {
    return isHexColor(str) || isRGBColor(str);
  }

  return false;
};
