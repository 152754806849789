import { selectDashboardStep, getDashboardStepBreadcrumb } from './dashboard-step-helpers';
import type { Option } from '../../../engine/option';
import { Step } from '../../../engine/step';
import { getBaseStepBlock, selectBaseStep } from './choose-step-helpers';
import { getMultiSelectStepBlock, selectMultiSelectStep } from './multi-select-step-helpers';
import { getSelectStepBlock, selectSelectStep } from './select-step-helpers';
import { getTextInputStepBlock, selectTextInputStep } from './text-input-step-helpers';
import { StepType } from '../../../engine/step/Step';
import { assertNever } from '../../../util/assertNever';
import { State } from '../..';

export * from './choose-step-helpers';
export * from './multi-select-step-helpers';
export * from './select-step-helpers';
export * from './text-input-step-helpers';

export const getStepBlock = (s: Step, state: State['engine']): string | undefined => {
  switch (s.type) {
    case StepType.Base:
      return getBaseStepBlock(s);
    case StepType.Execute:
      return undefined;
    case StepType.Dashboard:
      return getDashboardStepBreadcrumb(s);
    case StepType.MultiSelect:
      return getMultiSelectStepBlock(s, state);
    case StepType.Select:
      return getSelectStepBlock(s, state);
    case StepType.TextInput:
    case StepType.LongTextInput:
      return getTextInputStepBlock(s);
    default:
      return assertNever(s);
  }
};

export const getStepPlaceholder = (s: Step): string | null =>
  hasArgument(s) ? String(s.argument.userDefinedName) : null;

export const getStepSelection = (s: Step): Record<string, unknown> => {
  switch (s.type) {
    case StepType.Base:
    case StepType.Dashboard:
    case StepType.Execute:
      return {};
    case StepType.LongTextInput:
    case StepType.MultiSelect:
    case StepType.Select:
    case StepType.TextInput:
      // Return empty array if none are selected
      if (s.selected === null) {
        return s.type === StepType.MultiSelect ? { [s.argument.userDefinedValue]: [] } : {};
      }

      if (s.selected.type !== 'parameter') {
        return {};
      }

      return { [s.argument.userDefinedValue]: s.selected.data };
    default:
      return assertNever(s);
  }
};

export const hasArgument = (s: Step): s is Step & { argument: any } => {
  switch (s.type) {
    case StepType.Base:
    case StepType.Dashboard:
    case StepType.Execute:
      return false;
    case StepType.LongTextInput:
    case StepType.MultiSelect:
    case StepType.Select:
    case StepType.TextInput:
      return true;
    default:
      return assertNever(s);
  }
};

export const selectStep = (s: Step, o: Option): Step => {
  switch (s.type) {
    case StepType.Base:
      return selectBaseStep(s, o);
    case StepType.Execute:
      return s;
    case StepType.Dashboard:
      return selectDashboardStep(s);
    case StepType.LongTextInput:
    case StepType.TextInput:
      return selectTextInputStep(s, o);
    case StepType.MultiSelect:
      return selectMultiSelectStep(s, o);
    case StepType.Select:
      return selectSelectStep(s, o);
    default:
      return assertNever(s);
  }
};
