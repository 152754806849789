import { interpret, InterpreterStatus, State } from 'xstate';
import { ref } from 'valtio';
import dayjs from 'dayjs';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import Logger from '@commandbar/internal/util/Logger';
import { updateEndUserStore } from '../end-user/actions';
import { deconstructShareLink } from '@commandbar/internal/proxy-editor/share_links';
import { runBooleanExpression } from '../../../engine/option/OptionValidate';
import { isAvailableToEndUser } from '../helpers';
import nudgeMachine, { NudgeContext, nudgePreviewMachine } from './machine';
import { getElement } from '@commandbar/internal/util/dom';

import type { EngineState } from '../state';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { isEditorOpen } from '../../util/editorUtils';
import { isStandaloneEditor } from '@commandbar/internal/util/location';

const shouldDebugNudge = (nudge: INudgeType): boolean => {
  const debugOptions = LocalStorage.get('debug:nudges', false);
  if (typeof debugOptions === 'string') {
    const parsedDebugOptions = JSON.parse(debugOptions) as Record<string, unknown>;
    if (parsedDebugOptions && 'id' in parsedDebugOptions) {
      return nudge.id === parsedDebugOptions.id;
    }
  }

  return !!debugOptions;
};

export const startNudgeMachine = (_: EngineState, nudge: INudgeType) => {
  if (_.engine.nudgeServices.get(String(nudge.id))) {
    return;
  }

  const machine = nudgeMachine(_, nudge);

  const savedContext = LocalStorage.get('nudges', false);

  const parsedSavedContext = typeof savedContext === 'string' ? JSON.parse(savedContext)[nudge.id] : null;
  const parsedOldContext =
    typeof savedContext === 'string' && nudge.old_nudge_id
      ? JSON.parse(savedContext)[`old-${nudge.old_nudge_id}`]
      : null;

  const initialContext: NudgeContext = {
    ...(parsedSavedContext ?? parsedOldContext ?? machine.initialState.context),
    ..._.engine.endUserStore.data.nudges_interactions?.[Number(nudge.id)],
  };

  const refreshedStateDefinition = {
    ...machine.initialState,
    context: {
      ...initialContext,
      currentStep:
        initialContext.currentStep > nudge.steps.length - 1 ? nudge.steps.length - 1 : initialContext.currentStep,
      nudge,
    },
  };
  const previousState = State.create(refreshedStateDefinition);

  const service = interpret(machine, {
    devTools: shouldDebugNudge(nudge),
    logger: Logger.log,
  });

  _.engine.nudgeServices.set(String(nudge.id), service);
  service.start(previousState);
};

export const triggerNudges = (_: EngineState, trigger: INudgeType['trigger'], exclude?: string[]) => {
  const isBeingShared = (nudge?: INudgeType): boolean => {
    const deconstructedShareLink = deconstructShareLink(_.engine.location.search);
    if (!deconstructedShareLink || !nudge) return false;

    const nudgeSharePageUrl = nudge.share_page_url;
    return (
      trigger.type === 'when_conditions_pass' &&
      _.engine.location.href.startsWith(nudgeSharePageUrl) &&
      deconstructedShareLink.type === 'nudge'
    );
  };

  const passesPageConditions = (nudge: INudgeType) => {
    return !nudge.show_expression || runBooleanExpression(nudge.show_expression, _.engine, '').passed;
  };

  const deconstructedShareLink = deconstructShareLink(_.engine.location.search);
  const nudgeService = _.engine.nudgeServices.get(String(deconstructedShareLink?.id));
  if (nudgeService) {
    const nudge = nudgeService.getSnapshot().context.nudge;
    if (isBeingShared(nudge) && passesPageConditions(nudge)) {
      const isNudgeAvaiableToEndUser = isAvailableToEndUser(_, nudge);
      if (isNudgeAvaiableToEndUser) {
        forceTriggerNudge(_, nudge);
      }
    }
  }

  if (isNudgeRateLimited(_)) return;

  _.engine.nudgeServices.forEach((service) => {
    const { id, trigger: nudgeTrigger, is_live } = service.getSnapshot().context.nudge;
    /**
     * Note: the 'when_share_link_viewed' trigger does not have to be set for share linking to work.
     * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
     */

    if (_.engine.activeNudge !== null) return;

    // Don't trigger draft nudges for Admin's, previewing still allowed in previewMachine
    if (!is_live) return;

    if (!exclude?.includes(String(id)) && nudgeTrigger.type !== 'when_share_link_viewed') {
      service?.send({
        type: 'trigger',
        trigger,
        ..._.engine.endUserStore.data.nudges_interactions?.[Number(id)],
        hasSyncedRemote: _.engine.endUserStore.hasRemoteLoaded,
      });
    }
  });
};

export const setNudges = (_: EngineState, nudges: INudgeType[]) => {
  if (!_.engine.products.includes('nudges')) return;

  // if a nudge no longer exists after reload, stop its preview
  if (
    !nudges.map(({ id }) => String(id)).includes(String(_.engine.nudgePreviewService?.getSnapshot().context.nudge.id))
  ) {
    _.engine.nudgePreviewService?.send('dismiss');
    _.engine.nudgePreviewService?.stop();
    _.engine.nudgePreviewService = null;
  }

  nudges.forEach((nudge) => {
    /*
     * For an old nudge:
     * - Its id is -1 when fetched from /nudges in the editor
     * - On save, a new nudge is created with a new id; the old version is filtered out
     * - Nudges are reloaded from /config and now the id has changed
     * - We need to remove the old service and start a new one with the new id, or we'll have duplicate services
     */
    const oldServiceId = `old-${nudge.old_nudge_id}`;
    const oldService = _.engine.nudgeServices.get(`old-${nudge.old_nudge_id}`);

    oldService?.send('force dismiss');
    oldService?.stop();
    _.engine.nudgeServices.delete(oldServiceId);

    const service = _.engine.nudgeServices.get(String(nudge.id));
    if (service) {
      service.send({ type: 'refresh', nudge });
    } else {
      startNudgeMachine(_, nudge);
    }

    if (nudge.trigger.type === 'when_element_appears') {
      const selector = nudge.trigger.meta.selector;
      _.engine.triggerableSelectors.push(selector);
      if (getElement(selector)) {
        triggerNudges(_, { type: 'when_element_appears', meta: { selector } });
      }
    }
  });

  // remove services for nudges that are no longer available
  _.engine.nudgeServices.forEach((service, id) => {
    if (!nudges.map(({ id }) => String(id)).includes(id)) {
      service.send('force dismiss');
      service.stop();
      _.engine.nudgeServices.delete(id);
    }
  });

  triggerNudges(_, { type: 'when_conditions_pass' });
  triggerNudges(_, { type: 'when_page_reached', meta: { url: _.engine.location.href } });
};

export const previewNudge = (_: EngineState, nudge: INudgeType, options?: { stepIndex?: number }) => {
  if ((options?.stepIndex && options.stepIndex < 0) || (!isEditorOpen() && !isStandaloneEditor)) {
    return;
  }

  const service = _.engine.nudgePreviewService;

  if (service?.status === InterpreterStatus.Running) {
    service?.send({
      type: 'preview nudge',
      nudge,
      currentStep: options?.stepIndex ?? 0,
    });
  } else {
    const machine = nudgePreviewMachine(_, nudge);

    const service = interpret(machine);
    _.engine.nudgePreviewService = ref(service);

    service.start();
    service?.send({
      type: 'preview nudge',
      nudge,
      currentStep: options?.stepIndex ?? 0,
    });
  }
};

export const forceTriggerNudge = (_: EngineState, nudge: INudgeType) => {
  const service = _.engine.nudgeServices.get(String(nudge.id));
  service?.send({
    type: 'force trigger nudge',
  });
};

export const clearNudgeData = (_: EngineState, nudge: INudgeType) => {
  const id = Number(nudge.id);

  clearNudgeDataById(_, id);
};

export const clearNudgeDataById = (_: EngineState, id: number, step?: number) => {
  const allNudgeData = { ..._.engine.endUserStore.data.nudges_interactions } || {};

  if (step !== undefined && step > 0) {
    allNudgeData[id].currentStep = step;
  } else {
    allNudgeData[id] = {};
  }

  updateEndUserStore(_, allNudgeData, 'nudges_interactions');
};

const isNudgeRateLimited = (_: EngineState) => {
  const limit = _.engine.organization?.nudge_rate_limit;
  const period = _.engine.organization?.nudge_rate_period;

  if (limit === null || limit === undefined || !period || _.engine.isAdmin) return false;

  if (period === 'session') {
    const nudgesSeenThisSession: number[] = [];

    for (const service of _.engine.nudgeServices.values()) {
      const { nudgeSeenThisSessionTs } = service.getSnapshot().context;
      nudgesSeenThisSession.push(...nudgeSeenThisSessionTs);
    }

    return nudgesSeenThisSession.length >= limit;
  }

  const allNudgesSeen = Object.values(_.engine.endUserStore.data.nudges_interactions ?? {}).flatMap(
    (nudgeData) => nudgeData.seenTs,
  );

  const nudgesSeenInPeriod = allNudgesSeen.filter((timeSeen) => {
    const timeSeenMoment = dayjs(timeSeen);
    const now = dayjs();
    const diff = now.diff(timeSeenMoment, period);

    return diff <= 1;
  }).length;

  return nudgesSeenInPeriod >= limit;
};
