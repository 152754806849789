import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('uponSanitizeAttribute', (_, data) => {
  if (data.attrName === 'onclick') {
    const script = data.attrValue;

    if (!/^window\.CommandBar\.execute\(.{1,50}\)$/.test(script)) {
      return (data.attrValue = '');
    }
  }
});

const sanitizeHtml = (html: string) =>
  DOMPurify.sanitize(html, {
    ADD_ATTR: [
      'onclick',
      'id',
      'class',
      'style',
      'allow',
      'allowfullscreen',
      'frameborder',
      'height',
      'src',
      'title',
      'width',
      'target',
    ],
    ADD_TAGS: ['iframe'],
    FORCE_BODY: true,
  });

export default sanitizeHtml;
