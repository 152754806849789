import { isPrimitive } from '../../client_api/utils';
import get from 'lodash/get';
import { EngineState } from './state';
import ExecutionPath from '../../engine/ExecutionPath';
import { State } from '../../store';
import DateTime from '../../engine/predefinedTypes/DateTime/DateTime';
import { StepType } from '../../engine/step/Step';
import { getDefaultCommandId, isCommandOption, isOption, isOptionGroup } from './options';
import a11y from '../../util/a11y';

import { SUMMON_HOTKEY_SLUG } from '../../constants';
import {
  IArgumentType,
  ICommandCategoryType,
  IResourceSettingsByContextKey,
  isContextArgument,
  isTimeArgument,
} from '@commandbar/internal/middleware/types';
import dayjs from 'dayjs';
import * as Command from '@commandbar/internal/middleware/command';

export const currentOptionHasArguments = (_: EngineState) => {
  const currentOption = _.engine.sortedOptions[_.engine.focusedIndex];
  if (isCommandOption(currentOption)) {
    if (Object.keys(currentOption.command.arguments).length > 0) {
      return true;
    }
  }
  return false;
};

export const findFirstOptionIndex = (_: EngineState) => {
  const options = _.engine.sortedOptions;
  for (let i = 0; i < options.length; i++) {
    if (isOption(options[i])) {
      return i;
    }
  }
  return 1; // Default of 1 because 0 is a category
};

export const getDefaultCommandForCurrentStep = (_: EngineState) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  const activeResource = currentStep?.type === StepType.Base && currentStep.resource;
  if (!activeResource) {
    return;
  }
  const defaultCommandId = getDefaultCommandId(activeResource);

  // FLAG: can use the current options
  return _.engine.initialOptions.find(
    (option) =>
      isCommandOption(option) && (option.command.id === defaultCommandId || option.command.name === defaultCommandId),
  );
};

export const getFocusableChildrenAtIndex = (index: number): NodeListOf<Element> | undefined => {
  // To add more focusable types, update the querySelectorAll query.
  const option = document.getElementById(a11y.optionId(index));
  return option?.querySelectorAll('[role="switch"]');
};

export const canFocusOnOptionGroup = (_: EngineState, tabKey: boolean | undefined, index: number): boolean => {
  // Enter option group focus iff keypress is tab, is an option group, and option group has a focusable child.
  if (typeof tabKey == 'undefined' || !tabKey) return false;
  if (!isOptionGroup(_.engine.sortedOptions[index])) return false;

  const children = getFocusableChildrenAtIndex(index);
  if (children && children.length > 0) {
    return true;
  }
  return false;
};

export const getNextFocusedIndex = (_: EngineState, direction: 'up' | 'down', tabKey?: boolean) => {
  const { sortedOptions, focusedIndex } = _.engine;
  switch (direction) {
    case 'up':
      for (let i = 1; i < sortedOptions.length; i++) {
        const offset = focusedIndex - i;
        const index = offset >= 0 ? offset : sortedOptions.length + offset;
        if (isOption(sortedOptions[index])) {
          return index;
        } else if (canFocusOnOptionGroup(_, tabKey, index)) {
          return index;
        }
      }
      break;
    case 'down':
      for (let i = 1; i < sortedOptions.length; i++) {
        const index = (focusedIndex + i) % sortedOptions.length;
        if (isOption(sortedOptions[index])) {
          return index;
        } else if (canFocusOnOptionGroup(_, tabKey, index)) {
          return index;
        }
      }
      break;
  }
  return focusedIndex;
};

export const getContextObjectLabel = (
  contextSettings: IResourceSettingsByContextKey,
  object: any,
  key: string,
  defaultLabelField = 'label',
) => {
  if (isPrimitive(object)) return object.toString();
  else {
    const labelField = getLabelFieldFromContextSettings(contextSettings, key);
    return get(object, labelField || defaultLabelField);
  }
};

export const getArgumentChoiceLabel = (
  arg: IArgumentType,
  object: any,
  contextSettings: IResourceSettingsByContextKey,
) => {
  if (isPrimitive(object)) {
    return object.toString();
  } else {
    const defaultLabelField = arg.label_field || 'label';
    if (isContextArgument(arg)) {
      return getContextObjectLabel(contextSettings, object, arg.value, defaultLabelField);
    } else if (isTimeArgument(arg) && object instanceof Date) {
      return DateTime.display(dayjs(object.toString()), arg.dateTimeArgumentTypeId || 1, false);
    } else {
      return get(object, defaultLabelField);
    }
  }
};

const getLabelFieldFromContextSettings = (contextSettings: IResourceSettingsByContextKey, key: string) => {
  const config = contextSettings[key];
  return config?.label_field;
};

export const selectInitialValueCallbacks = (_: EngineState): string[] =>
  Object.keys(_.engine.callbacks).filter((k) => k.startsWith('commandbar-initialvalue-'));

export const selectEndUserEndpoint = (_: EngineState): string | null => {
  if (!_.engine.endUser) return null;

  return `/u/${_.engine.endUser.identifier}/`;
};

export const selectIsHotkeyEditable = (_: EngineState): boolean => {
  if (!_.engine.organization?.end_user_shortcuts_enabled) return false;

  return true;
};

export const selectSummonHotkey = (_: EngineState): string => {
  let summonHotkey = selectDefaultSummonHotkey(_);

  if (!_.engine.organization?.end_user_shortcuts_enabled) return summonHotkey;

  if (typeof _.engine.endUserStore.data.hotkeys[SUMMON_HOTKEY_SLUG] === 'string') {
    summonHotkey = _.engine.endUserStore.data.hotkeys[SUMMON_HOTKEY_SLUG];
  }
  return summonHotkey;
};

export const selectDefaultSummonHotkey = (_: EngineState): string => {
  let summonHotkey = 'mod+k';
  if (_.engine.organization?.summon_hotkey_override === 'none') {
    summonHotkey = '';
  } else if (
    _.engine.organization?.summon_hotkey_override !== undefined &&
    _.engine.organization?.summon_hotkey_override !== null
  ) {
    summonHotkey = _.engine.organization.summon_hotkey_override;
  }

  return summonHotkey;
};

export const getCategoryField = <T extends keyof ICommandCategoryType>(_: State, categoryId: number, field: T) => {
  const { categories } = _.engine;
  const category = categories.find((obj) => obj.id === categoryId);
  if (category && category[field]) {
    return category[field];
  }

  return null;
};

export const getContextSettings = (engine: EngineState['engine']): IResourceSettingsByContextKey =>
  engine.contextSettings;

export const isActiveRecord = (engine: EngineState['engine'], contextKey: string): boolean => {
  // Active in context
  if (Boolean(engine.context[contextKey])) return true;
  // Has async search fn defined
  if (Object.keys(engine.callbacks).includes(`commandbar-search-${contextKey}`)) return true;
  // Has loader defined
  if (Object.keys(engine.callbacks).includes(`commandbar-initialvalue-${contextKey}`)) return true;
  return false;
};

export const getCommands = (engine: EngineState['engine']) => {
  const hotloadedCommandIds = new Set(engine.hotloadedCommands.map((c) => c.id));

  const commands = [
    /* commands in engine.hotloadedCommands may overlap with engine.commands, so omit those from `commands` before merging */
    ...engine.commands.filter((c) => !hotloadedCommandIds.has(c.id)),

    ...engine.programmaticCommands,
    ...engine.hotloadedCommands,
  ];

  return engine.organization?.in_bar_doc_search === true
    ? commands
    : commands.filter((command) => command.template.type !== 'helpdoc');
};

export const getCommandById = (engine: EngineState['engine'], id: string | number) =>
  getCommands(engine).find((c) => c.id === id || Command.commandUID(c) === id || c.name === id);
