import * as t from 'io-ts';
import { decodeThrowing } from './generics';
import { OptionGroupRenderAsV } from './helpers/optionGroup';

export const TabV = t.type(
  {
    id: t.number,
    organization: t.union([t.number, t.string]),
    category_ids: t.array(t.number),
    sort_key: t.number,
    icon: t.union([t.string, t.null]),

    label: t.string,

    header: t.union([t.string, t.null]),
    description: t.string,
    placeholder: t.union([t.string, t.null]),

    render_as: OptionGroupRenderAsV,
  },
  'Tab',
);

const defaults = {};

export class Tab {
  public static decode = (data: any) => decodeThrowing(TabV, { ...defaults, ...data });
}
