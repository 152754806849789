import useTheme, { getMargins } from '../../hooks/useTheme';

import type { InterpolationWithTheme } from '@emotion/core';
import type { CSSProperties } from 'react';
import type { ITheme } from '@commandbar/internal/client/theme';

const asHelpHubStyles = <T extends Record<string, InterpolationWithTheme<ITheme>>>(styles: T): T => styles;

export const useStyles = () => {
  const { theme } = useTheme();
  const headerPadding = getMargins(theme.helpHub.headerPadding);

  return asHelpHubStyles({
    modalContainer: {
      position: 'fixed',
      bottom: '12px',
      right: '12px',
      width: theme.helpHub.width,
      height: theme.helpHub.height,
      borderRadius: theme.helpHub.borderRadius,
      boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
      border: `none`,
      zIndex: theme.helpHub.zIndex,
      color: '#0A0A0F',
      fontFamily: theme.helpHub.fontFamily,
      transform: 'none',
      overflow: 'hidden',
    },
    modal: {
      height: '100%',
      width: '100%',
      background: theme.helpHub.heroBackground,
      borderRadius: theme.helpHub.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      fontFamily: theme.helpHub.fontFamily,
      zIndex: theme.helpHub.zIndex,
    },
    strings: {
      title: theme.helpHub.title,
      featuredContentLabel: theme.helpHub.featuredContentLabel,
      chatHeaderText: theme.helpHub.chatHeaderText,
      chatInputPlaceholder: theme.helpHub.chatInputPlaceholder,
      docLinkText: theme.helpHub.docLinkText,
    },
    highlightMark: {
      color: theme.helpHub.highlightTextColor,
      borderRadius: theme.helpHub.highlightBorderRadius,
      backgroundColor: theme.helpHub.highlightBackgroundColor,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflowY: 'hidden',
    },
    inputContainer: {
      height: '74px',
      padding: '4px 16px 16px 16px',
      width: '100%',
      background: theme.helpHub.inputContainer,
    },
    input: {
      width: '100%',
      padding: '0px 102px 0px 16px',
      height: '48px',
      fontFamily: theme.helpHubInput.fontFamily,
      fontSize: theme.helpHubInput.fontSize,
      fontWeight: theme.helpHubInput.fontWeight,
      lineHeight: '19.36px',
      border: 'none',
      background: theme.helpHubInput.background,
      color: theme.helpHubInput.color,
      textOverflow: 'ellipsis',
      outline: 'none',
      '&::placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
      '&::-ms-input-placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
    },
    docInputContainer: { padding: '16px', width: '100%', background: theme.helpHub.inputContainer, zIndex: 1 },
    docInputWrapper: {
      position: 'relative',
    },
    docInput: {
      width: '100%',
      padding: '0px 40px 0px 40px',
      borderRadius: theme.helpHubInput.borderRadius,
      height: '48px',
      fontFamily: theme.helpHubInput.fontFamily,
      fontSize: theme.helpHubInput.fontSize,
      fontWeight: theme.helpHubInput.fontWeight,
      lineHeight: '19.36px',
      border: `1px solid ${theme.helpHubInput.borderColor}`,
      background: theme.helpHubInput.background,
      color: theme.helpHubInput.color,
      textOverflow: 'ellipsis',
      '&:focus': {
        boxShadow: `0px 0px 0px 3px ${theme.helpHubInput.hoverBorderShadowColor}`,
        border: `1px solid ${theme.helpHubInput.hoverBorderColor}`,
      },
      '&:focus-visible': {
        boxShadow: `0px 0px 0px 3px ${theme.helpHubInput.hoverBorderShadowColor}`,
        border: `1px solid ${theme.helpHubInput.hoverBorderColor}`,
        outline: 'none',
      },
      '&::placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
      '&::-ms-input-placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
    },
    chatTextAreaContainer: { padding: '16px', width: '100%', background: theme.helpHub.inputContainer },
    chatTextAreaWrapper: {
      position: 'relative',
    },
    inlineAskAi: {
      position: 'absolute',
      top: '50%',
      right: '8px',
    },
    chatTextArea: {
      width: '100%',
      fontFamily: theme.helpHubInput.fontFamily,
      fontSize: theme.helpHubInput.fontSize,
      fontWeight: theme.helpHubInput.fontWeight,
      borderRadius: theme.helpHubInput.borderRadius,
      border: `1px solid ${theme.helpHubInput.borderColor}`,
      background: theme.helpHubInput.background,
      color: theme.helpHubInput.color,
      lineHeight: '22px',
      padding: '12px 36px 12px 20px',
      marginBottom: '-8px',
      height: 'auto',
      minHeight: '48px',
      maxHeight: '192px',
      resize: 'none',
      display: 'inline-block',
      '&:focus': {
        boxShadow: `0px 0px 0px 3px ${theme.helpHubInput.hoverBorderShadowColor}`,
        border: `1px solid ${theme.helpHubInput.hoverBorderColor}`,
      },
      '&:focus-visible': {
        boxShadow: `0px 0px 0px 3px ${theme.helpHubInput.hoverBorderShadowColor}`,
        border: `1px solid ${theme.helpHubInput.hoverBorderColor}`,
        outline: 'none',
      },
      '&::placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
      '&::-ms-input-placeholder': {
        color: theme.helpHubInput.placeholderColor,
      },
      disabled: {
        boxShadow: `0px 0px 0px 3px #EDEDEE`,
        border: `1px solid #DFDFE2`,
        outline: `1px solid #DFDFE2`,
        color: '#3b3b45',
      },
    },
    AIProfileIcon: {
      width: '73px',
      height: '74px',
      minWidth: '73px',
      minHeight: '74px',
      margin: '-17px',
    },
    chatSuggestion: {
      cursor: 'pointer',
      width: '100%',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      color: '#51515C',
      padding: '8px',
      transition: `background ${theme.main.transitionTime}`,
      '&:hover': {
        background: '#EDEDEE',
        borderRadius: '4px',
      },
    },
    inputWrapper: {
      position: 'absolute',
      zIndex: 100,
      width: 'calc(100% - 32px)',
      borderRadius: theme.helpHubInput.borderRadius,
      overflow: 'hidden',
      border: `1px solid ${theme.helpHubInput.borderColor}`,
    },
    inputWrapperFocused: {
      boxShadow: `0px 0px 0px 3px ${theme.helpHubInput.hoverBorderShadowColor}`,
      border: `1px solid ${theme.helpHubInput.hoverBorderColor}`,
      outline: 'none',
    },
    largeAskAIButtonContainer: {
      background: '#ffffff',
      padding: '12px',
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    suggestionsContainer: {
      width: '100%',
      background: '#ffffff',
      padding: '0px 4px',
      flexDirection: 'column',
    },
    searchIcon: {
      position: 'absolute',
      top: '16px',
      right: '16px',
    },
    closeIcon: {
      position: 'absolute',
      top: '16px',
      cursor: 'pointer',
    },
    initialLoadingIcon: {
      position: 'absolute',
      inset: '50%',
    },
    headerContainer: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.helpHub.headerBackground,
      borderBottom: `1px solid ${theme.helpHub.headerDividerColor}`,
    },
    headerText: {
      fontWeight: theme.helpHub.headerFontWeight,
      fontSize: theme.helpHub.headerFontSize,
      lineHeight: theme.helpHub.headerLineHeight,
      paddingLeft: '8px',
      color: theme.helpHub.headerColor,
    },
    headerActionIcons: {
      display: 'flex',
      gap: '12px',
      paddingTop: headerPadding.top,
      paddingRight: headerPadding.right,
      paddingBottom: headerPadding.bottom,
    },
    headerIcon: {
      fontWeight: theme.helpHub.headerFontWeight,
      fontSize: theme.helpHub.headerFontSize,
      lineHeight: '18px',
      cursor: 'pointer',
      color: theme.helpHub.headerIconColor,
      opacity: theme.helpHub.headerIconOpacity,
    },
    headerCloseIcon: {
      display: theme.helpHub.headerCloseIconDisplay,
    },
    headerMinimizeIcon: {
      display: theme.helpHub.headerMinimizeIconDisplay,
    },
    subheader: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      paddingTop: headerPadding.top,
      paddingLeft: headerPadding.left,
      paddingBottom: headerPadding.bottom,
    },

    recommendationsContainer: {
      position: 'relative',
      overflowY: 'auto',
      height: '100%',
      paddingBottom: '16px',
      background: `linear-gradient(${theme.helpHub.heroBackground}, ${theme.helpHub.heroBackground}) top / 100% 168px,
                linear-gradient(${theme.helpHub.background}, ${theme.helpHub.background}) bottom / 100% calc(100% - 168px)`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'local',
    },
    recommendationsListContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: '0 16px',
      gap: '16px',
    },
    recommendationsHeading: {
      zIndex: 1,
      display: 'flex',
      padding: '16px 16px 8px',
      margin: 0,
      fontWeight: theme.helpHub.subheaderFontWeight,
      fontSize: theme.helpHub.subheaderFontSize,
      lineHeight: theme.helpHub.subheaderLineHeight,
      color: theme.helpHub.subheaderColor,
    },
    gridItems: {
      zIndex: 1,
      display: 'flex',
      width: '100%',
      gap: '8px',
      flexWrap: 'wrap',
    },
    gridItem: {
      all: 'unset',
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: '0',
      overflow: 'hidden',
      gap: '8px',
      minWidth: 'calc(50% - 4px)',
      maxWidth: 'calc(50% - 4px)',
      width: '100%',
      borderRadius: theme.helpHubGridItem.borderRadius,
      cursor: 'pointer',
      boxShadow: theme.helpHubGridItem.boxShadow,
      aspectRatio: '4 / 3',
    },
    gridItemPreview: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '0 16px',
      borderRadius: theme.helpHubGridItem.borderRadius,
      cursor: 'pointer',
      pointerEvents: 'all',
      overflow: 'hidden',
      border: theme.helpHubGridItem.border,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    gridItemPreviewActive: {
      border: theme.helpHubGridItem.hoverBorder,
      boxShadow: theme.helpHubGridItem.hoverBoxShadow,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    gridItemPreviewButton: {
      position: 'absolute',
      right: '12px',
      bottom: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: theme.helpHubGridItem.ctaButtonSize,
      height: theme.helpHubGridItem.ctaButtonSize,
      backgroundColor: theme.helpHubGridItem.ctaButtonBackground,
      borderRadius: theme.helpHubGridItem.ctaButtonBorderRadius,
      overflow: 'hidden',
      boxShadow: theme.helpHubGridItem.ctaButtonBoxShadow,
      border: theme.helpHubGridItem.ctaButtonBorder,
    },
    gridItemTitle: {
      fontWeight: theme.helpHubGridItem.fontWeight,
      fontSize: theme.helpHubGridItem.fontSize,
      lineHeight: theme.helpHubGridItem.lineHeight,
      overflowWrap: 'break-word',
      color: theme.helpHubGridItem.color,
      mixBlendMode: theme.helpHubGridItem.mixBlendMode as CSSProperties['mixBlendMode'],

      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      overflow: 'hidden',

      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          theme.helpHubGridItem.mixBlendMode !== 'normal'
            ? 'linear-gradient(180deg,transparent calc(66.67% - 16px),gray calc(93.08% - 16px))'
            : 'none',
        content: '""',
      },
    },
    recommendedGridItemsEmptyContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gap: '4px',
    },
    recommendedGridItemsEmpty: {
      flex: '1',
      background: 'linear-gradient(74.86deg, #D9D9D9 16.22%, #CCCCCC 31.6%, #D9D9D9 47.44%)',
      border: '1px solid #DFDFE2',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.24)',
      borderRadius: '8px',
      position: 'relative',
      aspectRatio: '4 / 3',
    },
    recommendedGridItemsEmptyDot: {
      position: 'absolute',
      width: '32px',
      height: '32px',
      right: '12px',
      bottom: '12px',
      background: '#F0F0F0',
      borderRadius: '100px',
    },
    recommendedListItemsEmpty: {
      width: '408px',
      height: '69px',
      background: 'linear-gradient(88.74deg, #D9D9D9 13.8%, #C9C9C9 30.92%, #D9D9D9 48.56%)',
      borderRadius: '8px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 16px 16px 20px',
      gap: '16px',
    },
    recommendedListItemEmptyDot: {
      width: '24px',
      height: '24px',
      background: '#F0F0F0',
      borderRadius: '4px',
    },
    recommendedListItemEmptyRowTop: {
      width: '195px',
      height: '16px',
      background: '#F0F0F0',
      borderRadius: '4px',
      marginBottom: '4px',
    },
    recommendedListItemEmptyRowBottom: {
      width: '290px',
      height: '12px',
      background: '#F0F0F0',
      borderRadius: '4px',
    },
    recommendedListItems: {
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
    },
    recommendedListItem: {
      all: 'unset',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.helpHubListItem.padding,
      gap: theme.helpHubListItem.gap,
      border: theme.helpHubListItem.border,
      background: theme.helpHubListItem.background,
      borderRadius: theme.helpHubListItem.borderRadius,
      cursor: 'pointer',
      boxShadow: theme.helpHubListItem.boxShadow,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    recommendedListItemActive: {
      background: theme.helpHubListItem.hoverBackground,
      border: theme.helpHubListItem.hoverBorder,
      boxShadow: theme.helpHubListItem.hoverBoxShadow,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    recommendedListItemIcon: {
      display: 'flex',
      alignItems: 'center',
      color: '#7A7A85',
    },
    recommendedListItemText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '2px',
      flexGrow: 1,
      minWidth: 0,
    },
    recommendedListItemTitle: {
      fontWeight: theme.helpHubListItem.titleFontWeight,
      fontSize: theme.helpHubListItem.titleFontSize,
      lineHeight: theme.helpHubListItem.titleLineHeight,
      color: theme.helpHubListItem.titleColor,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    recommendedListItemDescription: {
      fontWeight: theme.helpHubListItem.descriptionFontWeight,
      fontSize: theme.helpHubListItem.descriptionFontSize,
      lineHeight: theme.helpHubListItem.descriptionLineHeight,
      color: theme.helpHubListItem.descriptionColor,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    resultList: { overflowY: 'auto', height: '100%', padding: '0 16px 16px' },
    resultListHeader: {
      width: '100%',
      padding: '16px 0 8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resultListHeaderTitle: {
      margin: 0,
      fontSize: theme.helpHub.subHeaderFontSize,
      fontWeight: theme.helpHub.subHeaderFontWeight,
      color: theme.helpHub.subHeaderColor,
    },
    resultListHeaderResultsFound: {
      fontSize: theme.helpHub.resultsFoundFontSize,
      fontWeight: theme.helpHub.resultsFoundFontWeight,
      color: theme.helpHub.resultsFoundColor,
    },
    resultListContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: '8px',
    },

    resultCard: {
      all: 'unset',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.helpHubListItem.padding,
      gap: theme.helpHubListItem.gap,
      border: theme.helpHubListItem.border,
      background: theme.helpHubListItem.background,

      borderRadius: theme.helpHubListItem.borderRadius,
      cursor: 'pointer',
      boxShadow: theme.helpHubListItem.boxShadow,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    resultCardActive: {
      border: theme.helpHubListItem.hoverBorder,
      background: theme.helpHubListItem.hoverBackground,
      boxShadow: theme.helpHubListItem.hoverBoxShadow,
      transition: `border ${theme.main.transitionTime} ease-in-out, box-shadow ${theme.main.transitionTime} ease-in-out`,
    },
    resultCardAnswer: {
      background: 'linear-gradient(99.43deg, rgb(230.85, 235.68, 255) 6.02%, rgb(255, 255, 255) 26.86%)',
      boxShadow: 'inset 0px -2px 0px #00000012, 0px 1px 3px #000000122',
    },
    resultCardRightCaret: {
      display: 'flex',
      alignItems: 'center',
      color: '#7A7A85',
    },
    resultCardIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    resultCardTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      flexGrow: 1,
      minWidth: 0,
    },
    resultCardMeta: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    resultCardContentType: {
      padding: '2px 4px 2px 4px',
      borderRadius: '4px',
      background: theme.helpHubListItem.contentTypeBackground,
      fontSize: theme.helpHubListItem.contentTypeFontSize,
      fontWeight: theme.helpHubListItem.contentTypeFontWeight,
      color: theme.helpHubListItem.contentTypeColor,
      textTransform: 'capitalize',
    },
    resultCardDate: {
      padding: '2px 0 2px 0',
      fontSize: '12px',
      fontWeight: 500,
      color: '#7A7A85',
    },
    resultCardText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '2px',
      flexGrow: 1,
      minWidth: 0,
    },
    resultCardTitle: {
      fontWeight: theme.helpHubListItem.titleFontWeight,
      fontSize: theme.helpHubListItem.titleFontSize,
      lineHeight: theme.helpHubListItem.titleLineHeight,
      color: theme.helpHubListItem.titleColor,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    resultCardExcerpt: {
      fontSize: theme.helpHubListItem.descriptionFontSize,
      lineHeight: theme.helpHubListItem.descriptionLineHeight,
      fontWeight: theme.helpHubListItem.descriptionFontWeight,
      color: theme.helpHubListItem.descriptionColor,
      overflowWrap: 'break-word',
      maxHeight: '72px',

      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
    },

    answerCard: {
      fontFamily: 'inherit',
      all: 'unset',
      background: theme.helpHubInstantAnswerItem.background,
      width: '100%',
      padding: '16px',
      borderTop: '1px solid #DFDFE2',
      position: 'relative',
      border: `1px solid ${theme.helpHubDocItem.dividerColor}`,
      borderRadius: '8px',
      transition: `all ${theme.main.transitionTime}`,

      '&:hover': {
        background: theme.helpHubInstantAnswerItem.hoverBackground,
      },
    },
    answerCardIcon: {
      marginRight: '8px',
      paddingBottom: '3px',
      display: 'flex',
    },
    docTitle: {
      width: '100%',
      padding: '16px 24px 8px 24px',
      background: theme.helpHubDocItem.background,
      fontSize: theme.helpHubDocItem.titleFontSize,
      lineHeight: theme.helpHubDocItem.titleLineHeight,
      color: theme.helpHubDocItem.titleColor,
      fontWeight: theme.helpHubDocItem.titleFontWeight,
    },
    docContainer: { height: '100%', backgroundColor: theme.helpHubDocItem.background },
    docExcerpt: {
      width: '100%',
      padding: '0 24px',
      background: theme.helpHubDocItem.background,
      fontSize: theme.helpHubDocItem.videoExcerptFontSize,
      lineHeight: theme.helpHubDocItem.videoExcerptLineHeight,
      color: theme.helpHubDocItem.videoExcerptColor,
      fontWeight: theme.helpHubDocItem.videoExcerptFontWeight,
    },
    docContent: {
      background: theme.helpHubDocItem.background,
      width: '100%',
      padding: '0px 24px 16px 24px',
      fontSize: theme.helpHubDocItem.contentFontSize,
      lineHeight: theme.helpHubDocItem.contentLineHeight,
      fontWeight: theme.helpHubDocItem.contentFontWeight,
      color: theme.helpHubDocItem.paragraphColor,
      flexGrow: 1,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    docHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px',
      background: theme.helpHubDocItem.background,
    },
    docHeaderRight: {
      display: 'flex',
      padding: 0,
      gap: '8px',
    },
    docHeaderButton: {
      all: 'unset',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '32px',
      padding: '8px 12px 8px 8px',
      gap: '2px',
      background: theme.helpHub.buttonBackground,
      border: theme.helpHub.buttonBorder,
      boxShadow: theme.helpHub.buttonBoxShadow,
      borderRadius: '4px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.helpHub.buttonColor,
      cursor: 'pointer',
      transition: `border ${theme.main.transitionTime} ease-in-out`,

      '&:hover': {
        color: theme.helpHub.buttonHoverColor,
        background: theme.helpHub.buttonHoverBackground,
        border: theme.helpHub.buttonHoverBorder,
        boxShadow: theme.helpHub.buttonHoverBoxShadow,
      },
    },
    searchDocsButton: {
      all: 'unset',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '32px',
      padding: '8px 12px 8px 8px',
      gap: '4px',
      background: '#FFFFFF',
      border: '1px solid #DFDFE2',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -1px 0px rgba(0, 0, 0, 0.07)',
      borderRadius: '4px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#51515C',
      cursor: 'pointer',
      flex: '1',
      transition: `border ${theme.main.transitionTime} ease-in-out, color ${theme.main.transitionTime} ease-in-out`,
      '&:hover': {
        border: '1px solid #2755F0',
        color: '#031963',
      },
    },
    askAITooltip: {
      margin: '8px 0px 0px',
      padding: '2px 10px',
      border: `1px solid ${theme.helpHubInput.borderColor}`,
      borderRadius: '6px',
      fontSize: '12px',
      width: 'fit-content',
      cursor: 'pointer',
      userSelect: 'none',
    },
    askAITooltipIcon: {
      color: 'transparent',
    },
    additionalResourcesContainer: {
      position: 'relative',
      bottom: 0,
      width: '100%',
      background: theme.helpHubAdditionalResources.background,
      borderTop: theme.helpHubAdditionalResources.borderTop,
      boxShadow: theme.helpHubAdditionalResources.boxShadow,
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      pointerEvents: 'auto',
      zIndex: 1,
    },
    additionalResourcesTitle: {
      color: theme.helpHubAdditionalResources.titleColor,
      fontWeight: theme.helpHubAdditionalResources.titleFontWeight,
      fontSize: theme.helpHubAdditionalResources.titleFontSize,
      userSelect: 'none',
      lineHeight: 1.2,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    additionalResourcesArrowExpanded: {
      margin: '-1px 0 0 8px',
      transform: 'rotate(0deg)',
      color: theme.helpHubAdditionalResources.arrowColor,
    },
    additionalResourcesArrowCollapsed: {
      margin: '3px 0 0 8px',
      transform: 'rotate(180deg)',
      color: theme.helpHubAdditionalResources.arrowColor,
    },
    additionalResourcesLinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop: '8px',
      cursor: 'pointer',
    },
    additionalResourcesLink: {
      color: theme.helpHubAdditionalResources.linkColor,
      fontWeight: theme.helpHubAdditionalResources.linkFontWeight,
      fontSize: theme.helpHubAdditionalResources.linkFontSize,
      lineHeight: 1.2,
      textDecoration: theme.helpHubAdditionalResources.linkTextDecoration,
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: `calc(${theme.helpHub.width} * 0.5 - 18px)`,
      padding: theme.helpHubAdditionalResources.linkPadding,
      transition: `all ${theme.main.transitionTime}`,
      '&:hover': {
        color: theme.helpHubAdditionalResources.linkHoverColor,
        textDecoration: 'underline',
      },
      '&:active': {
        color: theme.helpHubAdditionalResources.linkColor,
        textDecoration: 'underline',
      },
    },
    additionalResourcesLinkIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 0,
      width: theme.helpHubAdditionalResources.linkIconSize,
      height: theme.helpHubAdditionalResources.linkIconSize,
      minWidth: theme.helpHubAdditionalResources.linkIconSize,
      minHeight: theme.helpHubAdditionalResources.linkIconSize,
      marginRight: theme.helpHubAdditionalResources.linkIconMarginRight,
      color: theme.helpHubAdditionalResources.linkIconColor,
    },
    additionalResourcesCTA: {
      all: 'unset',
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.helpHubAdditionalResources.ctaColor,
      background: theme.helpHubAdditionalResources.ctaBackground,
      fontWeight: theme.helpHubAdditionalResources.ctaFontWeight,
      fontSize: theme.helpHubAdditionalResources.ctaFontSize,
      lineHeight: 1.2,
      border: theme.helpHubAdditionalResources.ctaBorder,
      padding: theme.helpHubAdditionalResources.ctaPadding,
      height: theme.helpHubAdditionalResources.ctaHeight,
      width: theme.helpHubAdditionalResources.ctaWidth,
      borderRadius: theme.helpHubAdditionalResources.ctaBorderRadius,
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: theme.helpHubAdditionalResources.ctaBoxShadow,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      transition: `all ${theme.main.transitionTime}`,

      '&:hover': {
        background: theme.helpHubAdditionalResources.hoverCTABackground,
        border: theme.helpHubAdditionalResources.hoverCTABorder,
        boxShadow: theme.helpHubAdditionalResources.hoverCTABoxShadow,
        color: theme.helpHubAdditionalResources.hoverCTAColor,
      },
    },
    continuation: {
      fontFamily: theme.helpHub.fontFamily,
      background: '#E7E7E9',
      padding: '8px 16px',
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#1E1E29',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    poweredBy: {
      all: 'unset',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px 0px',
      gap: '6px',
      width: '100%',
      height: '37px',
      borderTop: '1px solid #DFDFE2',
      background: '#FFFFFF',
      zIndex: 1,
      cursor: 'pointer',

      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '13px',
      color: '#7A7A85',

      '& path': {
        fill: '#7A7A85',
      },
    },
  });
};
