/*******************************************************************************/
/* Imports
/*******************************************************************************/

import * as t from 'io-ts';
import {
  AdminAction,
  AppcuesAction,
  BuiltInAction,
  CallbackAction,
  ClickAction,
  HelpDocAction,
  LinkAction,
  ScriptAction,
  RequestAction,
  TriggerAction,
  VideoAction,
  WebhookAction,
} from './actions';

/*******************************************************************************/
/* Template definitions
/*******************************************************************************/

export const TemplateOptionsV = t.intersection([
  t.type({}),
  t.partial({
    commandType: t.union([t.literal('independent'), t.literal('object'), t.literal('help')]),
    object: t.string,
    hoverTooltip: t.boolean,
    // fixme: can remove?
    operation: t.union([
      t.literal('router'),
      t.literal('self'),
      t.literal('help_hub'),
      t.literal('blank'),
      t.undefined,
    ]),
  }),
]);

export const AdminTemplateV = t.intersection([AdminAction, TemplateOptionsV]);
export const CallbackTemplateV = t.intersection([CallbackAction, TemplateOptionsV]);
export const LinkTemplateV = t.intersection([LinkAction, TemplateOptionsV]);
export const ClickTemplateV = t.intersection([ClickAction, TemplateOptionsV]);
export const BuiltInTemplateV = t.intersection([BuiltInAction, TemplateOptionsV]);
export const WebhookTemplateV = t.intersection([WebhookAction, TemplateOptionsV]);
export const ScriptTemplateV = t.intersection([ScriptAction, TemplateOptionsV]);
export const RequestTemplateV = t.intersection([RequestAction, TemplateOptionsV]);
export const AppcuesTemplateV = t.intersection([AppcuesAction, TemplateOptionsV]);
export const VideoTemplateV = t.intersection([VideoAction, TemplateOptionsV]);
export const HelpDocTemplateV = t.intersection([
  HelpDocAction,
  t.partial({
    doc_type: t.union([t.literal('answer'), t.literal('doc')]),
  }),
  TemplateOptionsV,
]);
export const TriggerTemplateV = t.intersection([TriggerAction, TemplateOptionsV]);

export const TemplateV = t.union([
  AdminTemplateV,
  CallbackTemplateV,
  LinkTemplateV,
  ClickTemplateV,
  BuiltInTemplateV,
  WebhookTemplateV,
  ScriptTemplateV,
  RequestTemplateV,
  AppcuesTemplateV,
  VideoTemplateV,
  HelpDocTemplateV,
  TriggerTemplateV,
]);
