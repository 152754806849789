import chroma from 'chroma-js';
import { IBaseVars, toPx } from '../theme';
import Z from '../Z';

const generateHelpDocPreviewGradient = (color: string) => {
  const gradient = chroma.scale([color, chroma(color).brighten(2)]).mode('hsl');

  return `linear-gradient(140deg,
  ${gradient(0.1)} 0%,
  ${gradient(0.33)} 80%,
  ${gradient(0.39)} 98%,
  ${gradient(0.45)} 102%,
  ${gradient(0.78)} 100%)`;
};

const generateBaseTheme = (baseVars: IBaseVars) => {
  const base = {
    primary: baseVars.primary.css(),
    background: baseVars.background.css(),
    fontColor: baseVars.fontColor.css(),
    fontFamily: baseVars.fontFamily,
    fontSize: toPx(baseVars.fontSize),
  };

  const isLightTheme = chroma(base.background).luminance() > 0.5;

  const theme = {
    base: { ...base, mode: isLightTheme ? 'light' : ('dark' as 'light' | 'dark') },
    main: {
      background: baseVars.background.css(),
      borderRadius: toPx(8),
      borderColors: baseVars.fontColor.alpha(0.05).css(),
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 10px 30px',
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize),
      color: baseVars.fontColor.css(),
      transitionTime: '0.2s',
    },
    bar: {
      top: '15%',
      width: '675px',
      menuHeight: '350px',
      menuMinHeight: '0px',
    },
    optionList: {
      paddingTop: '0px',
      paddingBottom: '0px',
      spaceBetweenCategories: '0px',
      spaceBelowHeader: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    },
    placeholder: {
      color: baseVars.fontColor.alpha(0.25).css(),
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize * 1.3),
      fontWeight: 400,
    },
    logo: {
      size: '20px',
      top: '25px',
      left: '17px',
      spaceBetweenLogoAndText: '12px',
    },
    input: {
      color: baseVars.fontColor.css(),
      caretColor: baseVars.primary.alpha(0.6).css(),
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize * 1.3),
      borderBottom: `1px solid ${baseVars.fontColor.alpha(0.05).css()}`,
      background: 'none',
      paddingLeft: '25px',
      paddingRight: '25px',
      paddingTop: '25px',
      paddingBottom: '25px',
      border: 'none',
      borderRadius: '0px',
      backgroundInactive: 'none',
      colorInactive: baseVars.fontColor.css(),
      borderBottomInactive: `1px solid ${baseVars.fontColor.alpha(0.05).css()}`,
    },
    option: {
      cursor: 'pointer',
      color: baseVars.fontColor.alpha(0.7).css(),
      // https://www.loom.com/share/f9e1e52c9c034ffdac9f526ca7d98ee5
      // iconColor: (baseVars.fontColor.alpha(0.4).css()),
      iconColor: '',
      iconOpacity: '40%',
      spaceBetweenIconAndLabel: '18px',
      iconDisplay: '',
      iconSize: '18px',
      iconAlign: 'center',
      labelFontWeight: 400,
      labelMarginTop: '0px',
      labelMarginBottom: '0px',
      lineHeight: 1.6,
      background: baseVars.background.css(),
      borderColor: baseVars.fontColor.alpha(0.01).css(),
      borderWidth: '1px',
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize),
      minHeight: '43px',
      paddingLeft: '18px',
      paddingRight: '18px',
      paddingTop: '0px',
      paddingBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      descriptionFontSize: '11px',
      descriptionFontStyle: '',
      descriptionMarginTop: '-3px',
      descriptionMarginBottom: '0px',
      descriptionColor: baseVars.fontColor.alpha(0.6).css(),
      borderRadius: '0px',
      breadcrumbsFontSize: '10px',
      breadcrumbsMarginTop: '0px',
      breadcrumbsMarginBottom: '1px',
      breadcrumbsColor: baseVars.fontColor.alpha(0.6).css(),
      breadcrumbsFontWeight: 400,
      actionIconSize: '18px',
    },
    optionSelected: {
      color: baseVars.fontColor.css(),
      // https://www.loom.com/share/f9e1e52c9c034ffdac9f526ca7d98ee5
      // iconColor: (baseVars.primary.css()),
      iconColor: '',
      iconOpacity: '100%',
      background: baseVars.fontColor.alpha(0.05).css(),
      borderColor: baseVars.fontColor.alpha(0.01).css(),
      borderHighlight: baseVars.primary.css(),
      borderWidth: '1px',
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize),
      descriptionColor: baseVars.fontColor.alpha(0.7).css(),
      breadcrumbsColor: baseVars.fontColor.alpha(0.7).css(),
      highlightMargin: '0px',
    },
    optionDisabled: {
      color: baseVars.fontColor.alpha(0.3).css(),
      iconColor: '',
      background: baseVars.background.css(),
      cursor: 'not-allowed',
      descriptionFontStyle: 'italic',
      borderWidth: '1px',
    },
    optionDisabledSelected: {
      background: baseVars.fontColor.alpha(0.02).css(),
      iconColor: '',
    },
    launcher: {
      background: baseVars.background.css(),
    },
    dashboard: {
      background: baseVars.background.css(),
      fontColor: baseVars.fontColor.css(),
    },
    menu: {
      borderRadius: '0px',
      border: 'none',
      marginTop: '0px',
      background: 'none',
    },
    menuGridContainer: {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '12px',
      paddingRight: '12px',
      itemsPerRow: '3',
      itemGap: '10px',
      minRowHeight: '',
    },
    menuGridItemCard: {
      background: 'transparent',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingRight: '13px',
      paddingLeft: '13px',
      borderRadius: '3px',
      boxShadow: 'none',
      hoverBoxShadow: '0 5px 8px 0 rgb(0 0 0 / 10%), 0 -3px 8px 0 rgb(0 0 0 / 5%)',
      coverImageDisplay: '',
    },
    menuGridItemCardSelected: {
      borderColor: '',
    },
    menuGridOption: {
      iconDisplay: 'none',
      iconSize: '18px',
      cursor: 'pointer',
      color: baseVars.fontColor.alpha(0.7).css(),
      labelFontWeight: '600',
      labelMarginTop: '5px',
      labelMarginBottom: '0px',
      lineHeight: 1,
      background: baseVars.background.css(),
      borderColor: baseVars.fontColor.alpha(0.01).css(),
      borderWidth: '1px',
      fontFamily: baseVars.fontFamily,
      fontSize: '14.5px',
      minHeight: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      descriptionFontSize: '12.5px',
      descriptionFontStyle: '',
      descriptionMarginTop: '0px',
      descriptionMarginBottom: '0px',
      descriptionColor: '#616161',
      borderRadius: '0px',
      breadcrumbsFontSize: '10px',
      breadcrumbsMarginTop: '0px',
      breadcrumbsMarginBottom: '1px',
      breadcrumbsColor: baseVars.fontColor.alpha(0.6).css(),
      breadcrumbsFontWeight: 400,
      actionIconSize: '18px',
    },
    menuGridOptionSelected: {
      color: baseVars.fontColor.css(),
      background: baseVars.fontColor.alpha(0.05).css(),
      fontFamily: baseVars.fontFamily,
      fontSize: toPx(baseVars.fontSize),
      descriptionColor: baseVars.fontColor.alpha(0.7).css(),
      breadcrumbsColor: baseVars.fontColor.alpha(0.7).css(),
    },
    menuGridOptionDisabled: {
      color: baseVars.fontColor.alpha(0.3).css(),
      background: baseVars.background.css(),
      cursor: 'not-allowed',
      descriptionFontStyle: 'italic',
    },
    menuGridOptionDisabledSelected: {
      background: baseVars.fontColor.alpha(0.02).css(),
    },
    categoryHeader: {
      color: baseVars.fontColor.alpha(0.7).css(),
      background: baseVars.fontColor.alpha(0.05).css(),
      borderTop: '1px solid rgba(0,0,0,0.1)',
      borderBottom: 'none',
      paddingTop: '5px',
      paddingRight: '20px',
      paddingBottom: '5px',
      paddingLeft: '20px',
      opacity: '1.0',
      fontSize: toPx(baseVars.fontSize * 0.85),
      fontWeight: 600,
      lineHeight: 1.25,
      loaderSize: toPx(Math.round(baseVars.fontSize * 0.85) - 1),

      expandColor: 'inherit',
      expandBackground: 'none',
      expandBorder: 'none',
      expandFont: 'inherit',
      expandSpacing: 2,
      expandPadding: '0',
      expandIconSize: '16px',
      expandTextFontWeight: 400,
      numResultsFontWeight: 700,

      textTransform: 'none',
      recommendedHeaderLabel: 'Recommended',
      recentsHeaderLabel: 'Recents',
      fallbackCommandsHeader: 'No results for "{{query}}". Try one of the actions below:',
    },
    keyboardShortcuts: {
      color: baseVars.fontColor.css(),
      background: baseVars.fontColor.alpha(0.1).css(),
      fontSize: toPx(baseVars.fontSize * 0.85),
      borderRadius: toPx(2),
      borderColor: 'transparent',
      highlight: chroma(baseVars.primary).alpha(0.8).css(),
      fontFamily: baseVars.fontFamily,
      fontWeight: 400,
      separatorLabel: 'then',
      separatorMarginLeft: '4px',
      separatorMarginRight: '4px',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '7px',
      paddingRight: '7px',
      recordingColor: 'rgb(255, 82, 82)',
    },
    previewPopover: {
      minHeight: '250px',
      maxHeight: '450px',
      minWidth: '400px',
      maxWidth: '400px',
      boxShadow: '0px 16px 24px rgba(36, 35, 41, 0.3), 0px 2px 4px rgba(36, 35, 41, 0.25)',
      opacity: 1.0,
      padding: '16px 24px',
      background: chroma.mix(baseVars.background, baseVars.fontColor, 0.05).alpha(1).css(),
      titleBarBorderBottom: `1px solid  ${baseVars.fontColor.alpha(0.05).css()}`,
    },
    textSearchMatch: {
      textDecoration: `${baseVars.primary} solid underline`,
      fontWeight: 400,
      emphasisFontWeight: 500,
      textUnderlinePosition: 'auto',
      emphasisTextUnderlinePosition: 'under',
    },
    searchableFieldMatch: {
      color: baseVars.fontColor.css(),
      background: baseVars.fontColor.alpha(0.1).css(),
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '3px',
      paddingRight: '3px',
      marginRight: '4px',
      marginLeft: '0px',
      borderRadius: '2px',
      lineHeight: '1.4',
    },
    blocks: {
      background: baseVars.background.css(),
      color: baseVars.fontColor.css(),
      border: `1px solid ${baseVars.primary.css()}`,
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingRight: '4px',
      paddingLeft: '4px',
      fontSize: '12px',
      fontWeight: 500,
      borderRadius: '4px',
    },
    blocksContainer: {
      paddingTop: '10px',
      paddingBottom: '0px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    instruction: {
      display: 'block',
      fontColor: baseVars.background.css(),
      background: baseVars.fontColor.alpha(0.6).css(),
      borderRadius: '4px',
    },
    searchTabsContainer: {
      paddingTop: '8px',
      paddingRight: '14px',
      paddingBottom: '8px',
      paddingLeft: '14px',
    },
    searchTab: {
      labelAllTab: 'All',
      paddingTop: '6px',
      paddingRight: '10px',
      paddingBottom: '6px',
      paddingLeft: '10px',
      spacing: '0px',
      borderRadius: '15px',

      activeColor: baseVars.background.css(),
      activeBorder: '1px solid transparent',
      activeBorderWidth: '',
      activeBackground: baseVars.primary.css(),

      inactiveColor: baseVars.fontColor.css(),
      inactiveBorder: '1px solid transparent',
      inactiveBorderWidth: '',
      inactiveBackground: baseVars.background.css(),
      inactivePadding: '',

      noResultsColor: '',
      noResultsBorder: '',
      noResultsBackground: '',
      noResultsOpacity: 0.2,

      hoverColor: baseVars.primary.css(),
      hoverBorder: '1px solid transparent',
      hoverBorderWidth: '',
      hoverBackground: baseVars.background.css(),
      hoverPadding: '',
      fontSize: toPx(baseVars.fontSize),
      fontWeight: 600,
      textAlign: 'center',
      transitionTime: '0.15s',
      iconSize: '18px',
      iconMarginRight: '4px',
    },
    searchTabDescription: {
      padding: '6px 10px',
      fontSize: toPx(baseVars.fontSize),
    },
    searchTabGroupHeading: {
      color: baseVars.fontColor.css(),
      fontSize: toPx(baseVars.fontSize),
      fontWeight: 700,
      textAlign: 'center',
      padding: '10px 10px 6px 10px',
    },
    footer: {
      background: 'transparent',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '6px',
      paddingBottom: '6px',
      borderTop: `1px ${baseVars.fontColor.alpha(0.05).css()} solid`,
    },
    noResults: {
      headerFontSize: '20px',
      headerFontWeight: 500,
      headerFontColor: baseVars.fontColor.css(),
      headerMarginTop: '0px',
      headerMarginBottom: '20px',
      headerFontFamily: baseVars.fontFamily,
      queryFontColor: baseVars.fontColor.alpha(0.7).css(),
      bodyFontSize: '12px',
      bodyFontColor: baseVars.fontColor.css(),
      bodyFontWeight: 400,
      bodyFontFamily: baseVars.fontFamily,
      linkColor: baseVars.primary.css(),
      headerText: 'No results for',
      bodyText: "Can't find what you're looking for?",
      linkText: 'Tell us here',
    },
    feedback: {
      submitButtonColor: baseVars.background.css(),
      submitButtonBackground: baseVars.fontColor.alpha(0.75).css(),
      submitButtonPadding: '0 4px',
      submitButtonBorderRadius: '4px',
      submitButtonFontSize: '14px',
      submitButtonFontWeight: 'normal' as const,
      hoverSubmitButtonColor: baseVars.background.css(),
      hoverSubmitButtonBackground: baseVars.fontColor.alpha(0.5).css(),
      placeholderText: 'Give feedback or suggest a new command',
      color: baseVars.fontColor.css(),
      fontFamily: baseVars.fontFamily,
      fontSize: baseVars.fontSize,
      background: baseVars.fontColor.alpha(0.03).css(),
      borderColor: baseVars.fontColor.alpha(0.2).css(),
      breadcrumbText: 'Give feedback',
      breadcrumbColor: baseVars.fontColor.css(),
      breadcrumbBackground: baseVars.fontColor.alpha(0.14).css(),
      hoverBreadcrumbColor: baseVars.fontColor.css(),
      hoverBreadcrumbBackground: baseVars.fontColor.alpha(0.1).css(),
      submissionText: 'Thanks for the feedback!',
    },
    navPane: {
      width: '175px',
      borderRight: `2px solid ${baseVars.fontColor.alpha(0.05).css()}`,
      background: baseVars.background.css(),
    },
    nextStepCommands: {
      hidden: 'false',
      borderRadius: '4px',
      color: 'rgba(0,0,0,0.75)',
      background: 'rgba(255, 255, 255, 0.65)',
      hoverColor: 'rgba(0, 0, 0, 1)',
      hoverBackgroundColor: 'rgba(255, 255, 255, 0.85)',
      boxShadow: '0 4px 30px rgb(0 0 0 / 10%)',
      iconSize: '15px',
    },
    detailPreview: {
      titleFontSize: `0.75em`,
      titleDisplay: `inline-block`,
      titleLineHeight: `1.2em`,
      titleFontWeight: `600`,
      titleColor: baseVars.fontColor.css(),
      titleMargin: '0 0 1em -0.5em',
      titlePadding: `0.25em 0.5em`,
      titleBackgroundColor: chroma.mix(baseVars.background.css(), baseVars.fontColor.css(), 0.1).css(),
      titleBorderRadius: `2px`,
      width: '65%',
      animationDuration: '0.5s',
      padding: '16px 24px 24px 24px',
    },
    nudges: {
      width: '300px',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
      background: isLightTheme ? '#FFFFFF' : '#1E1E1E',
      color: isLightTheme ? '#42424D' : '#A6A6A6',
      fontFamily: baseVars.fontFamily,
      titleFontFamily: baseVars.fontFamily,
      titleFontSize: '16px',
      titleFontWeight: 600,
      titleLineHeight: '17px',
      titleColor: isLightTheme ? '#42424D' : '#FFFFFF',
      contentFontFamily: baseVars.fontFamily,
      contentFontSize: '14px',
      contentLineHeight: '16px',
      contentFontWeight: 400,
      contentColor: isLightTheme ? '#42424D' : '#A6A6A6',
      ctaBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaColor: '#FFFFFF',
      ctaBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaBorderRadius: '4px',
      ctaPadding: '11px 12px',
      ctaFontFamily: baseVars.fontFamily,
      ctaFontSize: '14px',
      ctaLineHeight: '18px',
      ctaFontWeight: '600',
      ctaSecondaryBackground: isLightTheme ? '#FFFFFF' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaSecondaryColor: '#0A0A0F',
      ctaSecondaryBorder: isLightTheme ? '1px solid #DFDFE2' : '1px solid #101010',
      ctaSecondaryBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaSecondaryTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaSecondaryBorderRadius: '4px',
      ctaSecondaryPadding: '11px 12px',
      ctaSecondaryFontFamily: baseVars.fontFamily,
      ctaSecondaryFontSize: '14px',
      ctaSecondaryLineHeight: '18px',
      ctaSecondaryFontWeight: '600',
      closeButtonColor: isLightTheme ? '#A2A2A9' : '#A6A6A6',
      closeButtonHoverColor: isLightTheme ? '#51515C' : '#E1E1E1',
      helpDocPreviewColor: '#FFFFFF',
      helpDocPreviewBackground: generateHelpDocPreviewGradient(baseVars.primary.css()),
      helpDocPreviewMixBlendMode:
        chroma(baseVars.primary.css()).get('hsl.s') < 0.9 && chroma(baseVars.primary.css()).luminance() > 0.075
          ? 'overlay'
          : 'hard-light',
      stepCountColor: isLightTheme ? '#6E6E6E' : '#9C9C9C',
      stepCountFontFamily: baseVars.fontFamily,
      stepCountFontWeight: 500,
      stepCountFontSize: '12px',
    },
    surveyTextInput: {
      background: isLightTheme ? '#FFFFFF' : '#343434',
      boxShadow: 'none',
      border: isLightTheme ? '1px solid rgba(10, 10, 15, 0.24)' : '1px solid #101010',
      borderRadius: '4px',
      color: baseVars.fontColor.css(),
      fontFamily: baseVars.fontFamily,
      fontSize: baseVars.fontSize,
      padding: '8px',
      margin: '8px 0 0 0',
    },
    surveyRatingBox: {
      background: isLightTheme ? '#FFFFFF' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      color: baseVars.fontColor.css(),
      border: isLightTheme ? '1px solid rgba(10, 10, 15, 0.24)' : '1px solid #101010',
      boxShadow: isLightTheme
        ? 'rgba(0, 0, 0, 0.07) 0px 1px 3px, rgba(0, 0, 0, 0.07) 0px -1px 0px inset'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      borderRadius: '4px',
      selectionBorder: '1px solid #FFB422',
      selectionBackGround: '#FFF2D9',
      selectionBoxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 3px, rgba(0, 0, 0, 0.07) 0px -1px 0px inset',
    },
    questlists: {
      fontFamily: baseVars.fontFamily,
      bottom: '24px',
      right: '24px',
      left: '24px',
      boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
      borderRadius: '8px',
      bodyBackground: isLightTheme ? '#FFFFFF' : '#1E1E1E',
      headerBackground: isLightTheme ? '#F7F7F8' : '#1E1E1E',
      footerBackground: isLightTheme ? '#F7F7F8' : '#1E1E1E',
      headerPadding: '16px',
      headerGap: '4px',
      footerPadding: '8px 16px',
      footerGap: '4px',
      footerHeight: '56px',
      progressPadding: '13px 16px',
      doneBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      doneColor: isLightTheme ? '#FFFFFF' : '#FFFFFF',
      doneBorderRadius: '4px',
      donePadding: '9px 12px',
      doneFontSize: '14px',
      doneLineHeight: '14px',
      doneFontWeight: '600',
      doneBorder: isLightTheme ? '1px solid #A2A2A9' : '1px solid #101010',
      doneBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      doneTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      skipBackground: isLightTheme ? '#FFFFFF' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      skipColor: isLightTheme ? '#51515C' : '#FFFFFF',
      skipBorderRadius: '4px',
      skipPadding: '9px 12px',
      skipFontSize: '14px',
      skipLineHeight: '14px',
      skipFontWeight: '600',
      skipBorder: isLightTheme ? '1px solid #A2A2A9' : '1px solid #101010',
      skipBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      skipTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      titleFontSize: '16px',
      titleFontWeight: '600',
      titleLineHeight: '17px',
      titleColor: isLightTheme ? '#42424D' : '#FFFFFF',
      descriptionFontSize: '14px',
      descriptionLineHeight: '16px',
      descriptionFontWeight: 400,
      descriptionColor: isLightTheme ? '#42424D' : '#A6A6A6',
      itemsContainerMaxHeight: '50vh',
    },
    questlistItem: {
      ctaBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaColor: '#FFFFFF',
      ctaBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaBorderRadius: '4px',
      ctaPadding: '11px 12px',
      ctaFontSize: '14px',
      ctaLineHeight: '18px',
      ctaFontWeight: '600',
      skipBackground: 'transparent',
      skipColor: isLightTheme ? '#A2A2A9' : '#A6A6A6',
      skipBorderRadius: '4px',
      skipPadding: '5px 12px',
      skipFontSize: '14px',
      skipLineHeight: '14px',
      skipFontWeight: '500',
      skipBorder: 'none',
      titleFontSize: '16px',
      titleFontWeight: '500',
      titleLineHeight: '16px',
      titleColor: isLightTheme ? '#51515C' : '#FFFFFF',
      titleCompletedFontSize: '14px',
      titleCompletedFontWeight: '600',
      titleCompletedLineHeight: '16px',
      titleCompletedColor: isLightTheme ? '#7A7A85' : '#E7E7E9',
      titleCompletedtextDecorationLine: 'line-through',
      descriptionFontSize: '14px',
      descriptionLineHeight: '18px',
      descriptionFontWeight: 400,
      descriptionColor: isLightTheme ? '#51515C' : '#A6A6A6',
      dividerColor: isLightTheme ? '#DFDFE2' : '#42424D',
    },
    nudgePin: {
      width: '300px',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
      background: isLightTheme ? '#FFFFFF' : '#1E1E1E',
      color: isLightTheme ? '#42424D' : '#A6A6A6',
      fontFamily: baseVars.fontFamily,
      titleFontFamily: baseVars.fontFamily,
      titleFontSize: '16px',
      titleFontWeight: 600,
      titleLineHeight: '17px',
      titleColor: isLightTheme ? '#42424D' : '#FFFFFF',
      contentFontFamily: baseVars.fontFamily,
      contentFontSize: '14px',
      contentLineHeight: '16px',
      contentFontWeight: 400,
      contentColor: isLightTheme ? '#42424D' : '#A6A6A6',
      ctaBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaColor: '#FFFFFF',
      ctaBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaBorderRadius: '4px',
      ctaPadding: '11px 12px',
      ctaFontFamily: baseVars.fontFamily,
      ctaFontSize: '14px',
      ctaLineHeight: '18px',
      ctaFontWeight: '600',
      ctaSecondaryBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaSecondaryColor: '#FFFFFF',
      ctaSecondaryBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaSecondaryBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaSecondaryTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaSecondaryBorderRadius: '4px',
      ctaSecondaryPadding: '11px 12px',
      ctaSecondaryFontFamily: baseVars.fontFamily,
      ctaSecondaryFontSize: '14px',
      ctaSecondaryLineHeight: '18px',
      ctaSecondaryFontWeight: '600',
      beaconColor: isLightTheme ? '#7611F7' : '#F7C411',
      beaconPulseScale: '4',
      helpDocPreviewColor: '#FFFFFF',
      helpDocPreviewBackground: generateHelpDocPreviewGradient(baseVars.primary.css()),
      helpDocPreviewMixBlendMode:
        chroma(baseVars.primary.css()).get('hsl.s') < 0.9 && chroma(baseVars.primary.css()).luminance() > 0.075
          ? 'overlay'
          : 'hard-light',
      stepCountColor: isLightTheme ? '#6E6E6E' : '#9C9C9C',
      stepCountFontFamily: baseVars.fontFamily,
      stepCountFontWeight: 500,
      stepCountFontSize: '12px',
    },
    nudgeModal: {
      width: '400px',
      height: 'auto',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
      background: isLightTheme ? '#FFFFFF' : '#1E1E1E',
      color: isLightTheme ? '#42424D' : '#A6A6A6',
      fontFamily: baseVars.fontFamily,
      maskBackgroundColor: 'rgba(0, 0, 0, 0.1)',
      titleFontFamily: baseVars.fontFamily,
      titleFontSize: '16px',
      titleFontWeight: 600,
      titleLineHeight: '20px',
      titleColor: isLightTheme ? '#42424D' : '#FFFFFF',
      contentFontFamily: baseVars.fontFamily,
      contentFontSize: '14px',
      contentLineHeight: '20px',
      contentFontWeight: 500,
      contentColor: isLightTheme ? '#42424D' : '#A6A6A6',
      ctaBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaColor: '#FFFFFF',
      ctaBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaBorderRadius: '4px',
      ctaPadding: '11px 12px',
      ctaFontFamily: baseVars.fontFamily,
      ctaFontSize: '14px',
      ctaLineHeight: '18px',
      ctaFontWeight: '600',
      ctaSecondaryBackground: isLightTheme ? '#0A0A0F' : 'linear-gradient(180deg, #343434 0%, #303030 100%)',
      ctaSecondaryColor: '#FFFFFF',
      ctaSecondaryBorder: isLightTheme ? 'none' : '1px solid #101010',
      ctaSecondaryBoxShadow: isLightTheme
        ? 'none'
        : '0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(255, 255, 255, 0.09)',
      ctaSecondaryTextShadow: isLightTheme ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.26)',
      ctaSecondaryBorderRadius: '4px',
      ctaSecondaryPadding: '11px 12px',
      ctaSecondaryFontFamily: baseVars.fontFamily,
      ctaSecondaryFontSize: '14px',
      ctaSecondaryLineHeight: '18px',
      ctaSecondaryFontWeight: '600',
      helpDocPreviewColor: '#FFFFFF',
      helpDocPreviewBackground: generateHelpDocPreviewGradient(baseVars.primary.css()),
      helpDocPreviewMixBlendMode:
        chroma(baseVars.primary.css()).get('hsl.s') < 0.9 && chroma(baseVars.primary.css()).luminance() > 0.075
          ? 'overlay'
          : 'hard-light',
      stepCountColor: isLightTheme ? '#6E6E6E' : '#9C9C9C',
      stepCountFontFamily: baseVars.fontFamily,
      stepCountFontSize: '14px',
      stepCountFontWeight: 500,
      poweredByBorder: isLightTheme ? '1px solid #DFDFE2' : '1px solid #373737',
    },
    helpHub: {
      background: '#F7F7F8',
      height: 'calc(100vh - 24px)',
      width: '449px',
      borderRadius: '8px',
      fontFamily: baseVars.fontFamily,
      zIndex: Z.Z_HELPHUB,

      title: 'HelpHub',
      featuredContentLabel: 'Recommended',
      chatHeaderText: 'Chat with AI',
      chatInputPlaceholder: 'Ask a question...',
      docLinkText: 'View Article',

      heroBackground: baseVars.primary.css(),
      inputContainer: baseVars.primary.css(),

      headerBackground: 'transparent',
      headerDividerColor: 'transparent',
      headerPadding: '12px 16px',
      headerColor: '#000000',
      headerFontWeight: 500,
      headerFontSize: '14px',
      headerLineHeight: '18px',

      headerIconColor: '#000000',
      headerIconOpacity: '50%',

      headerCloseIconDisplay: '',
      headerMinimizeIconDisplay: '',

      subheaderFontSize: '16px',
      subheaderFontWeight: 500,
      subheaderLineHeight: '19px',
      subheaderColor: '#000000',

      highlightBackgroundColor: '#feffe6',
      highlightTextColor: '#000',
      highlightBorderRadius: '0',

      subHeaderColor: '#000',
      subHeaderFontWeight: 500,
      subHeaderFontSize: '16px',

      resultsFoundColor: '#A2A2A9',
      resultsFoundFontWeight: 500,
      resultsFoundFontSize: '12px',

      noResultsTitleFontSize: '16px',
      noResultsTitleFontWeight: 600,
      noResultsTitleColor: '#42424d',

      noResultsDescriptionFontSize: '14px',
      noResultsDescriptionFontWeight: 400,
      noResultsDescriptionColor: '#51515c',

      buttonBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -1px 0px rgba(0, 0, 0, 0.07)',
      buttonColor: '#51515C',
      buttonBackground: '#FFFFFF',
      buttonBorder: '1px solid rgba(10, 10, 15, 0.24)',
      buttonHoverBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -1px 0px rgba(0, 0, 0, 0.07)',
      buttonHoverColor: '#51515C',
      buttonHoverBackground: '#FFFFFF',
      buttonHoverBorder: '1px solid rgba(10, 10, 15, 0.29)',
    },
    helpHubInput: {
      color: '#1E1E29',
      placeholderColor: '#757575',
      fontFamily: baseVars.fontFamily,
      fontSize: '14px',
      fontWeight: 500,
      containerBackground: '#ffffff',
      background: '#ffffff',
      borderRadius: '8px',
      borderColor: '#DFDFE2',
      hoverBorderColor: '#2755F0',
      hoverBorderShadowColor: 'rgba(39, 85, 240, 0.24)',
    },
    helpHubGridItem: {
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '27px',
      color: '#000000',
      background: '#EDEDEE',
      hoverBackground: '#EDEDEE',
      border: '1px solid transparent',
      hoverBorder: '1px solid #A2A2A9',
      borderRadius: '8px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.24)',
      hoverBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.24)',
      mixBlendMode: 'hard-light',

      ctaButtonForeground: '#FFFFFF',
      ctaButtonBackground: '#0A0A0F',
      ctaButtonBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.24)',
      ctaButtonBorderRadius: '50%',
      ctaButtonBorder: 'none',

      ctaButtonSize: '32px',
      iconSize: '16px',
    },
    helpHubListItem: {
      background: '#ffffff',
      hoverBackground: '#ffffff',
      borderRadius: '8px',
      border: '1px solid rgba(10, 10, 15, 0.24)',
      hoverBorder: '1px solid #A2A2A9',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)',
      hoverBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)',
      padding: '16px 16px 16px 20px',
      gap: '16px',

      titleFontWeight: 500,
      titleFontSize: '14px',
      titleLineHeight: '17px',
      titleColor: '#000000',

      contentTypeFontWeight: 500,
      contentTypeFontSize: '12px',
      contentTypeColor: '#031963',
      contentTypeBackground: '#E6ECFE',

      descriptionFontWeight: 500,
      descriptionFontSize: '14px',
      descriptionLineHeight: '18px',
      descriptionColor: '#51515C',
      iconSize: '20px',
    },
    helpHubDocItem: {
      // these styles only apply to HelpHub articles
      // we may want to rename this to be clearer in the future
      background: 'white',
      dividerColor: '#DFDFE2',

      titleFontSize: '18px',
      titleLineHeight: '21px',
      titleFontWeight: 600,
      titleColor: '#0A0A0F',

      paragraphColor: '#42424D',
      linkColor: (chroma.valid(base.fontColor) ? chroma(base.fontColor) : chroma('rgba(0, 0, 0, 0.85)'))
        .darken(3)
        .css(),

      videoExcerptFontSize: '14px',
      videoExcerptLineHeight: '21px',
      videoExcerptFontWeight: 500,
      videoExcerptColor: '#42424D',

      contentFontSize: '14px',
      contentLineHeight: '21px',
      contentFontWeight: 400,

      contentHeadingOneColor: '#0A0A0F',
      contentHeadingOneFontSize: '18px',
      contentHeadingOneLineHeight: '',
      contentHeadingOneFontWeight: 700,

      contentHeadingTwoColor: '#0A0A0F',
      contentHeadingTwoFontSize: '16px',
      contentHeadingTwoLineHeight: 1.4,
      contentHeadingTwoFontWeight: 600,

      contentHeadingThreeColor: '#0A0A0F',
      contentHeadingThreeFontSize: '14px',
      contentHeadingThreeLineHeight: 1.5,
      contentHeadingThreeFontWeight: 600,

      contentHeadingFourColor: '#0A0A0F',
      contentHeadingFourFontSize: '13px',
      contentHeadingFourLineHeight: '',
      contentHeadingFourFontWeight: 500,

      contentButtonBackgroundColor: baseVars.primary.css(),
      contentButtonColor: baseVars.background.css(),

      contentButtonHoverBackgroundColor: baseVars.primary.css(),
      contentButtonHoverColor: baseVars.background.css(),
    },
    helpHubInstantAnswerItem: {
      background: '#F6F0FF',
      hoverBackground: '#F6F0FF',

      questionFontSize: '16px',
      questionFontWeight: 600,
      questionColor: '#401C7B',

      answerFontSize: '14px',
      answerFontWeight: 500,
      answerColor: '#401C7B',
    },
    helpHubAdditionalResources: {
      background: '#FFFFFF',
      titleFontSize: '16px',
      titleFontWeight: 500,
      titleColor: '#000000',
      borderTop: '1px solid #A2A2A9',
      arrowColor: '#7A7A85',
      boxShadow: '0px -5px 16px rgba(0, 0, 0, 0.08)',

      linkFontSize: '14px',
      linkColor: '#000000',
      linkHoverColor: '#000000',
      linkFontWeight: 500,
      linkPadding: '5px 0px',
      linkIconSize: '20px',
      linkIconColor: '#000000',
      linkIconMarginRight: '6px',
      linkTextDecoration: 'underline',

      ctaFontSize: '16px',
      ctaFontWeight: 500,
      ctaColor: '#000000',
      ctaBackground: '#FFFFFF',
      ctaPadding: '10px 12px 10px 12px',
      ctaHeight: '40px',
      ctaWidth: '100%',
      ctaBorderRadius: '4px',
      ctaBorder: '1px solid rgba(10, 10, 15, 0.24)',
      ctaBoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)',

      hoverCTABackground: '#ffffff',
      hoverCTABorder: '1px solid #CDCDD0',
      hoverCTABoxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)',
      hoverCTAColor: '#000000',
    },
  };

  return theme;
};

export default generateBaseTheme;
