/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { SVGProps } from 'react';
import { css, jsx, keyframes } from '@emotion/core';

import { useStore } from '../../hooks/useStore';
import { useHelpHubLauncherPosition } from '../../hooks/useLauncherPosition';
import chroma from 'chroma-js';
import {
  BookClosed,
  BookOpen01,
  GraduationHat02,
  Bookmark,
  MessageCircle01,
} from '@commandbar/design-system/icons/react';
import AskAIButton from '@commandbar/design-system/components/AskAIButton';
import useTheme from '../../hooks/useTheme';
import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import CustomHelpHubLauncher from './CustomHelpHubLauncher';
import { isStandaloneEditor } from '@commandbar/internal/util/location';

/*******************************************************************************/
/* Render
/*******************************************************************************/

type THelpHubType = IOrganizationSettingsType['helphub_launcher_type'];

type TIconType = Exclude<THelpHubType, 'none' | 'custom' | 'askAI'>;
const icons: {
  [key in TIconType]: React.FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  bookOpen: BookOpen01,
  graduationHat: GraduationHat02,
  bookClosed: BookClosed,
  bookmark: Bookmark,
  chatCircle: MessageCircle01,
};

const HelpHubLauncher = (props: { organization: IOrganizationType }) => {
  // const onLauncherClick = useAction(App.toggleBarFromLauncher);
  const { isAdmin } = useStore().engine;
  const launcherPosition = useHelpHubLauncherPosition(isAdmin, props.organization) as unknown as React.CSSProperties;
  const { theme } = useTheme();

  const theme_primary = theme.base?.primary;
  const theme_background = theme.launcher?.background;
  const theme_fontFamily = theme.main?.fontFamily;

  const entryAnimation = keyframes`
  0% {
    transform: scale(0.06);
  }
  100% {
    transform: scale(1);
  }
`;

  const onClick = React.useCallback(() => {
    (window as any).CommandBar.toggleHelpHub();
  }, []);

  /********************* Start text handling **********************/

  if (!props.organization?.helphub_enabled || isStandaloneEditor) {
    return null;
  }

  let hoverBackground = theme.main.background;
  try {
    hoverBackground = chroma(theme.main.background).alpha(0.98).css();
  } catch {}

  if (props.organization.helphub_launcher_type === 'none') {
    return null;
  }

  if (props.organization.helphub_launcher_type === 'custom') {
    return <CustomHelpHubLauncher organization={props.organization} />;
  }

  if (props.organization.helphub_launcher_type === 'askAI') {
    return (
      <div className="commandbar-launcher" style={launcherPosition}>
        <AskAIButton onClick={onClick} />
      </div>
    );
  }

  const LauncherIcon = icons[props.organization.helphub_launcher_type];
  return (
    <div className="commandbar-launcher" style={launcherPosition}>
      <div
        aria-hidden={true}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 9px 10px;
          color: ${theme_primary};
          background-color: ${theme_background};
          border-radius: 27px;
          box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
          font-family: ${theme_fontFamily};
          line-height: 1;
          transition: all ${theme.main.transitionTime} ease-in-out;
          animation: ${entryAnimation} 0.25s ease-out;
          &:hover {
            background-color: ${hoverBackground};
            cursor: pointer;
            transform: scale(1.3);
          }
        `}
        onClick={onClick}
      >
        <LauncherIcon width={26} />
      </div>
    </div>
  );
};

export default HelpHubLauncher;
