import axios, { AxiosRequestConfig } from 'axios';
import LocalStorage from '../util/LocalStorage';
import Logger from '../util/Logger';
import { dispatchCustomEvent } from '../util/dispatchCustomEvent';
import { getSDK } from '../client/globals';
import { _configuration } from '../client/symbols';

interface NetworkConfig extends AxiosRequestConfig {
  maxWaitTimeMs: number;
  waitTimeMs: number;
  errorCodes: any[];
  isRetryRequest: boolean;
  retryCount: number;
}

const DEFAULT_RETRY_CONFIG: NetworkConfig = {
  maxWaitTimeMs: 30 * 1000,
  waitTimeMs: 100,
  errorCodes: [],
  isRetryRequest: false,
  retryCount: 0,
};

export const getBaseURL = () => {
  let url = getSDK()?.[_configuration]?.api;

  if (url === undefined) {
    const override = new URL(window.location.href).searchParams.get('api');
    if (!!override) {
      url = override;
    } else {
      url = `${process.env.REACT_APP_API_URL}`;
    }
  }
  Logger.blue('[target]', url);
  return url;
};

const getAuthorizationHeader = () => {
  const token = LocalStorage.get('access', '');

  if (!!token) {
    return `JWT ${token}`;
  } else {
    return null;
  }
};

const isLoginPathname = () => window.location.pathname.startsWith('/login');

const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let _config = config;

    // Redirect `/t/` posts to a separate server
    if (config?.method === 'post' && config?.url && config?.url.endsWith('/t/')) {
      if (config?.baseURL && ['https://api.commandbar.com', 'https://api-cu.commandbar.com'].includes(config.baseURL)) {
        _config = {
          ...config,
          baseURL: 'https://t.commandbar.com',
        };
      }
    }

    const airgap = getSDK()?.[_configuration]?.airgap;

    if (airgap) {
      Logger.red('blocking unexpected request in airgapped mode', config);
      return null;
    }

    return { ...DEFAULT_RETRY_CONFIG, ..._config };
  },
  (error) => Promise.reject(error),
);

// Add the admin authorizaton header for every request if it's there
// We do this on every request because occasionally our token gets refreshed mid-session
axiosInstance.interceptors.request.use(
  (config) => {
    const authorizationHeader = getAuthorizationHeader();
    if (authorizationHeader && config.headers) {
      config.headers.Authorization = authorizationHeader;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const isRetryable = (error: any) => {
  const { method: httpMethod, url } = error.config;
  // const { response: { status: statusCode } = {} } = error;

  let shouldRetryForMethod = false;
  let shouldRetryForEndpoint = false;
  let shouldRetryForStatus = false;

  if (httpMethod === 'get') shouldRetryForMethod = true;

  if (
    !!url &&
    (url.endsWith('/commands/') ||
      url.endsWith('/categories/') ||
      url.endsWith('/placeholders/') ||
      url.endsWith('/settings/') ||
      url.endsWith('/contexts/') ||
      url.includes('/config/'))
  ) {
    shouldRetryForEndpoint = true;
  }

  shouldRetryForStatus = true;

  return shouldRetryForMethod && shouldRetryForEndpoint && shouldRetryForStatus;
};

const retry = (config: NetworkConfig, maxAttempts?: number) => {
  // Inspiration: https://github.com/flexdinesh/axios-retry-interceptor/blob/master/src/axios-retry-interceptor.js
  const { retryCount } = config;
  config.retryCount += 1;
  config.isRetryRequest = true;
  const delayMs = config.waitTimeMs;
  config.waitTimeMs = Math.min(delayMs * 2, config.maxWaitTimeMs); // backoff

  if (maxAttempts !== undefined && retryCount > maxAttempts) return Promise.reject('[CommandBar] Retries exceeded.');

  if (delayMs > 0) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => resolve(axiosInstance(config)), delayMs);
    });
  }
  return axiosInstance(config);
};

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    Logger.red('@ Axios Error', error, error.config);

    const originalRequestConfig = error.config;

    const frame = process.env.REACT_APP_FRAME_NAME ?? '';
    const isInjected = ['CommandBar', 'Proxy'].includes(frame);

    // Prevent infinite loops early
    if (
      error?.response?.status === 401 &&
      (originalRequestConfig.url === '/auth/refresh/' || originalRequestConfig.url === '/auth/basic/')
    ) {
      if (!isInjected && !isLoginPathname()) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }

    //  if (
    //   error.response.data.code === 'token_not_valid' &&
    //   error.response.status === 401 &&
    //   error.response.statusText === 'Unauthorized'
    // )
    if (error?.response?.status === 401) {
      let refreshToken = '';

      if (isInjected) {
        refreshToken = (window as any)?.CommandBarProxy?._refresh;
      } else {
        refreshToken = LocalStorage.get('refresh', '') as string;
      }

      if (!!refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/auth/refresh/', { refresh: refreshToken })
            .then((response) => {
              Logger.blue('@ RESET TOKENS');

              if (isInjected) {
                (window as any).CommandBarProxy._access = response.data.access;
                (window as any).CommandBarProxy._refresh = response.data.refresh;
              } else {
                LocalStorage.set('access', response.data.access);
                LocalStorage.set('refresh', response.data.refresh);
              }

              axiosInstance.defaults.headers.common['Authorization'] = 'JWT ' + response.data.access;
              originalRequestConfig.headers['Authorization'] = 'JWT ' + response.data.access;

              if (!isInjected) {
                // Notify of changes to tokens
                try {
                  const detail = {};
                  dispatchCustomEvent('CB_EDITOR_SYNC', { detail });
                } catch (err) {}
              }
              return retry(originalRequestConfig, 5);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.error('Refresh token is expired', tokenParts.exp, now);
          LocalStorage.remove('refresh');
          if (!isInjected && !isLoginPathname()) {
            window.location.href = '/login';
          }
          return Promise.reject(error);
        }
      } else {
        console.error('Refresh token not available.');
        if (!isInjected && !isLoginPathname()) {
          window.location.href = '/login' + window.location.search;
        }
        return Promise.reject(error);
      }
    }

    if (!!error?.config && isRetryable(error)) {
      return retry(error.config);
    }

    if (error?.isAxiosError && error?.code === 'ECONNABORTED') {
      // https://cmd-k.slack.com/archives/C01QKNP0C87/p1638906814026400
      // This probably only ever happens when refreshing a page with laggard requests
      // Firefox prints these errors in the console, so this conditional attempts to avoid that
      return Promise.resolve();
    }

    // endpoint specific error handling
    if (originalRequestConfig?.url.startsWith('search/commands/')) {
      return Promise.reject(error);
    }

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    return Promise.reject(error?.response?.data ?? 'Something went wrong.');
  },
);

export default axiosInstance;
