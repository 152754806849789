/** @jsx jsx  */
import { ChevronLeft } from '@commandbar/design-system/icons/react';
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import { ChatMessage, convertInternalChatHistoryToExternal } from '../../client_api/search';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import { useStore } from '../../hooks/useStore';
import { HelpHubDoc } from '../../store/engine/state';
import LoadingIndicator from '../select/input/LoadingIndicator';
import LiveAnswerCard from './LiveAnswerCard';
import { useStyles } from './useStyles';
import { ResultCard } from './SearchResults';
import { INPUT_METHOD } from '../../hooks/useKeyboardNavigation';
import { ReactComponent as SendChatIcon } from './send_chat_icon.svg';
import PoweredBy from './PoweredBy';
import { isChatOnlyMode } from '../../store/engine/help-hub/selectors';
import { isHelpHubMarketingSite } from '@commandbar/internal/util/location';
import * as Reporting from '../../analytics/Reporting';

const Chat = ({
  chatID,
  history,
  setCurrentDoc,
  setChatHistory,
  setIsChatMode,
  setChatID,
}: {
  chatID: string | undefined;
  history: ChatMessage[];
  setCurrentDoc: (doc: HelpHubDoc | null) => void;
  setIsChatMode: (isChatMode: boolean) => void;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setChatID: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { engine } = useStore();
  const styles = useStyles();

  const chatInput = useRef<HTMLTextAreaElement>(null);
  const [query, setQuery] = React.useState('');
  const [, setInputFocused] = React.useState(true);

  const lastUserMessageRef = React.useRef<HTMLDivElement>(null);
  const chatScrollContainerRef = React.useRef<HTMLDivElement>(null);

  // when user posts a new message to history, scroll it into view
  useEffect(() => {
    setTimeout(() => {
      if (!lastUserMessageRef.current) return;
      if (!chatScrollContainerRef.current) return;

      chatScrollContainerRef.current.scrollTo({
        top:
          lastUserMessageRef.current.getBoundingClientRect().top -
          chatScrollContainerRef.current.getBoundingClientRect().top +
          chatScrollContainerRef.current.scrollTop -
          16,
        behavior: 'smooth',
      });
    }, 250);
  }, [history[history.length - 1]?.type === 'user']);

  const isLoading = history.length > 0 && ['user', 'bot-incomplete'].includes(history[history.length - 1].type);

  useEffect(() => {
    if (!isHelpHubMarketingSite) {
      chatInput.current?.focus();
    }
  }, [history]);

  const sendChat = () => {
    setChatHistory((history) => [...history, { message: query, type: 'user' }]);
    setQuery('');
    if (chatInput.current) {
      chatInput.current.style.height = 'auto';
    }
    Reporting.helpHubChatMessage('user', query);
  };

  let lastUserMessageIdx: number | null = null;
  history.forEach((message, index) => {
    if (message.type === 'user') {
      lastUserMessageIdx = index;
    }
  });

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '16px',
          color: '#51515C',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>
          {!isChatOnlyMode(engine) && (
            <button
              css={{ ...styles.docHeaderButton }}
              onClick={() => {
                setIsChatMode(false);
                setCurrentDoc(null);
                setChatHistory([]);
                setChatID(undefined);
                Reporting.helpHubEngagement({ query: engine.helpHub.query }, 'chat_closed');
              }}
            >
              <ChevronLeft height={14} />
              <span>Back</span>
            </button>
          )}
        </div>
        <div css={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}>{styles.strings.chatHeaderText}</div>
        <div style={{ flex: 1 }}></div>
      </div>
      {engine.helpHub.hubDoc && (
        <div css={styles.resultListContainer} style={{ padding: '0px 16px' }}>
          <ResultCard
            doc={engine.helpHub.hubDoc}
            selectionMethod={INPUT_METHOD.Mouse}
            active={false}
            onClick={() => setIsChatMode(false)}
          />
        </div>
      )}
      <div
        ref={chatScrollContainerRef}
        style={{ overflowY: 'auto', paddingBottom: '24px', paddingTop: '16px', overscrollBehaviorY: 'contain' }}
      >
        <div>
          {history.map((message, index) => {
            return (
              <AnimationTransition
                key={index}
                entry={
                  index === 0
                    ? { keyframes: builtinKeyframes.slideUp(17), durationMs: 300 }
                    : { keyframes: builtinKeyframes.fadeInSlideDown, durationMs: 300 }
                }
                isMounted={true}
              >
                {message.type === 'user' ? (
                  <div
                    ref={index === lastUserMessageIdx ? lastUserMessageRef : undefined}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'self-end',
                      justifyContent: 'flex-end',
                      margin: '0px 16px 16px 0px',
                      fontSize: '16px',
                      color: '#05425B',
                    }}
                  >
                    <div
                      style={{
                        background: '#2755F0',
                        borderRadius: '16px 16px 0px 16px',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 500,
                        color: '#F7F7F8',
                        flexGrow: 0,
                        padding: '8px 16px',
                        maxWidth: '75%',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {message.message}
                    </div>
                  </div>
                ) : (
                  <LiveAnswerCard
                    docs={[]}
                    setCurrentDoc={(doc: HelpHubDoc) => {
                      setCurrentDoc(doc);
                      setIsChatMode(false);
                    }}
                    isLoading={message.type === 'bot-incomplete'}
                    chatID={chatID}
                    history={history.slice(0, index + 1)}
                    showContinuations={index === history.length - 1}
                    setSelectedContinuation={(continuation: string) => {
                      setChatHistory((history) => [...history, { message: continuation, type: 'user' }]);
                      setQuery('');

                      Reporting.helpHubContinuationClicked(
                        { query: engine.helpHub.query },
                        convertInternalChatHistoryToExternal(history),
                        continuation,
                      );
                    }}
                    liveAnswer={message.message}
                  />
                )}
              </AnimationTransition>
            );
          })}
        </div>
      </div>
      <div style={{ minHeight: '80px' }}></div> {/* Spacer - fixed for now, might want to use a mutation observer */}
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <div css={styles.chatTextAreaContainer}>
          <div style={styles.chatTextAreaWrapper}>
            <textarea
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={!isHelpHubMarketingSite}
              suppressContentEditableWarning={true}
              ref={chatInput}
              placeholder={styles.strings.chatInputPlaceholder}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isLoading && !!query.length) {
                  e.preventDefault();
                  sendChat();
                }
              }}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
                e.currentTarget.style.height = 'auto';
                e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              css={{
                ...styles.chatTextArea,
                ...(isLoading ? styles.chatTextArea.disabled : {}),
              }}
              disabled={isLoading}
              rows={1}
              value={query ?? ''}
            />
            {isLoading ? (
              <LoadingIndicator
                style={{
                  position: 'absolute',
                  top: '14px',
                  right: '14px',
                }}
                size={22}
                isLoading
              />
            ) : (
              // TODO: fix this a11y issue
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                style={{
                  ...styles.closeIcon,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '8px',
                  right: '8px',
                  width: '32px',
                  height: '32px',
                }}
                onClick={() => {
                  if (!!query.length) {
                    sendChat();
                  }
                }}
              >
                <SendChatIcon
                  style={{
                    ...(!query.length && { opacity: '0.6' }),
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <PoweredBy />
      </div>
    </div>
  );
};

export default Chat;
