import React, { CSSProperties } from 'react';
import chroma from 'chroma-js';

interface Props {
  commandsNumber: number;
  isFocused?: boolean;
  style?: CSSProperties;
}

export const MultiCommandsIndicator = ({ commandsNumber, style }: Props) => {
  const backgroundColor = chroma('#fff').darken(0.4);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: backgroundColor.css(),
        color: 'rgba(0,0,0,0.85)',
        fontSize: '11px',
        fontWeight: 700,
        opacity: 0.6,
        height: 14,
        padding: '0 4px',
        marginBottom: '2px',
        minWidth: 22,
        borderRadius: 2,
        boxShadow: `3px 3px ${backgroundColor.darken(1).css()}, 6px 6px 0 ${backgroundColor.darken(2).css()}`,

        ...style,
      }}
    >
      {commandsNumber}
    </div>
  );
};
