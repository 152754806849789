import React from 'react';
import _get from 'lodash/get';
import { ParameterOption } from '../../engine/option';
import ExecutionPath from '../../engine/ExecutionPath';
import SearchMatchFormatter, { showTopSearchMatch } from '../select/menu/SearchMatchFormatter';
import { UnderlineFormatter } from '../../engine/option/UnderlineFormatter';
import { SelectOrCreate } from '../../engine/features/selectOrCreate';
import OptionWrapper from './OptionWrapper';
import { useStore } from '../../hooks/useStore';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { OptionActionIcon } from './OptionActionIcon';
import { ParameterOptionAddOn } from './ParameterOptionAddOn';
import { ParameterOptionIcon } from './ParameterOptionIcon';
import { OptionHighlight } from './OptionHighlight';
import ResourceOptionIcon from './ResourceOptionIcon';
import ResourceOptionAddOn from './ResourceOptionAddOn';
import { StepType } from '../../engine/step/Step';
import { optionIsInGrid } from '../../store/app';
import { getReservedField, isParameterOptionSelected, isResourceOption } from '../../store/engine';

export interface Props {
  isFocused: boolean;
  option: ParameterOption;
}

export const ParameterOptionRow = ({ isFocused, option }: Props) => {
  const { engine } = useStore();
  const theme = useCommandBarTheme();
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

  let text: string | React.ReactElement = option.label;

  const descriptionField = option.searchOptions?.description_field;
  const descriptionText = !!descriptionField && _get(option.parameter, descriptionField);
  const extraHTML = getReservedField(option, '__extraHTML');

  const isGridOption = optionIsInGrid(useStore(), option);

  const isHTML = (str: string) => {
    // https://stackoverflow.com/a/15458987
    return /<\/?[a-z][\s\S]*>/i.test(str);
  };

  const searchMatch = showTopSearchMatch(option.searchMatches) && (
    <SearchMatchFormatter
      matches={option.searchMatches}
      formatKey={(searchKey: string, searchValue: string) => {
        /* find the name of the field corresponding to the top match */
        const matchedField = option.tags.find(({ value }) =>
          Array.isArray(value) ? value.includes(searchValue) : searchValue === value,
        );
        if (!matchedField) return '';
        return (
          <span
            style={{
              color: theme.searchableFieldMatch.color,
              background: theme.searchableFieldMatch.background,
              paddingTop: theme.searchableFieldMatch.paddingTop,
              paddingBottom: theme.searchableFieldMatch.paddingBottom,
              paddingLeft: theme.searchableFieldMatch.paddingLeft,
              paddingRight: theme.searchableFieldMatch.paddingRight,
              marginRight: theme.searchableFieldMatch.marginRight,
              marginLeft: theme.searchableFieldMatch.marginLeft,
              borderRadius: theme.searchableFieldMatch.borderRadius,
              lineHeight: theme.searchableFieldMatch.lineHeight,
              overflow: 'hidden',
              display: 'inline-flex',
            }}
          >{`${matchedField.label}`}</span>
        );
      }}
      formatValue={(value: string) => <OptionHighlight option={option} labelToHighlight="tags.value" text={value} />}
    />
  );

  let descriptionBlock;
  if (searchMatch) {
    descriptionBlock = searchMatch;
  } else if (descriptionText) {
    descriptionBlock = (
      <OptionHighlight option={option} labelToHighlight="description" text={descriptionText} emphasize />
    );
  }

  let extraBlock;
  if (extraHTML) {
    if (typeof extraHTML === 'string' && isHTML(extraHTML)) {
      extraBlock = <div dangerouslySetInnerHTML={{ __html: extraHTML }} />;
    } else if (typeof extraHTML === 'function') {
      extraBlock = <ClientCustomizableBlock afterRender={extraHTML} record={option.parameter} />;
    }
  }

  if (currentStep?.type === StepType.TextInput && typeof option.parameter === 'string') {
    text = <UnderlineFormatter prefix={option.label} suffix={option.parameter} delimiter={':'} theme={theme} />;
  }

  if (SelectOrCreate.isEnabled(currentStep) && SelectOrCreate.recordIsCreated(option.parameter)) {
    text = <UnderlineFormatter prefix={option.label} suffix={option.parameter.value} delimiter={':'} theme={theme} />;
  }

  const isMultiSelectSelection =
    currentStep?.type === StepType.MultiSelect ? isParameterOptionSelected(option, currentStep) : false;

  const icon = isResourceOption(option) ? (
    <ResourceOptionIcon isFocused={isFocused} option={option} />
  ) : (
    <ParameterOptionIcon isFocused={isFocused} option={option} isMultiSelectSelection={isMultiSelectSelection} />
  );

  const shortcut = isResourceOption(option) ? (
    <ResourceOptionAddOn isFocused={isFocused} option={option} />
  ) : (
    <ParameterOptionAddOn option={option} />
  );

  return (
    <OptionWrapper
      option={option}
      label={<OptionHighlight option={option} labelToHighlight="label" text={text} />}
      description={descriptionBlock}
      extra={extraBlock}
      type="resource"
      icon={icon}
      shortcut={shortcut}
      isError={false}
      isDisabled={false}
      isFocused={isFocused}
      heading={
        <OptionHighlight
          option={option}
          labelToHighlight="heading"
          // fallback to __breadcrumbs for backwards compatability
          text={getReservedField(option, '__heading') || getReservedField(option, '__breadcrumbs')}
          emphasize
        />
      }
      goForward={<OptionActionIcon icon={getReservedField(option, '__actionIcon')} isGridOption={!!isGridOption} />}
      isMultiSelectSelection={isMultiSelectSelection}
    />
  );
};

const ClientCustomizableBlock = (props: { afterRender: (elem: HTMLElement, record: any) => void; record: any }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (ref.current) {
      props.afterRender(ref.current, props.record);
    }
  }, [props.record]);

  return <div ref={ref} />;
};
