/** @jsx jsx */
import React, { useEffect } from 'react';
import chroma from 'chroma-js';
import { css, jsx } from '@emotion/core';

import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { useStore } from '../../hooks/useStore';
import logger from '@commandbar/internal/util/Logger';
import { useCommandBarContainer } from '../../hooks/useCommandBarContainer';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';

interface Props {
  value: string;
  title?: string;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const HtmlPreview = ({ value, title, scrollContainerRef }: Props) => {
  const { inputText } = useStore().engine;
  return <HTMLView scrollContainerRef={scrollContainerRef} html={value} title={title ?? ''} inputText={inputText} />;
};

const HTMLView = (props: {
  html: string;
  title: string;
  inputText: string;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { root } = useCommandBarContainer();
  const theme = useCommandBarTheme();

  const [activeHighlightIdx, setActiveHighlightIdx] = React.useState(0);

  const getHighlightId = (idx: number) => {
    return `cb-highlight-${idx}`;
  };
  const activeHighlightClassName = 'active-highlight';

  const scrollContainerRef = props.scrollContainerRef;

  const handleActiveHighlightChange = (oldIdx: number, newIdx: number) => {
    if (!scrollContainerRef) {
      return;
    }

    const oldHighlight = root?.getElementById(getHighlightId(oldIdx));
    const newHighlight = root?.getElementById(getHighlightId(newIdx));

    if (newHighlight) {
      if (oldHighlight) {
        oldHighlight.classList.remove(activeHighlightClassName);
      }
      setTimeout(() => scrollContainerRef.current?.scrollTo(0, Math.max(0, newHighlight?.offsetTop - 50)), 100);
      newHighlight.classList.add(activeHighlightClassName);
      return true;
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      scrollToNextMatch();
    }, 200);

    // Clean-up function
    return () => {
      clearTimeout(timerId);
    };
  }, [props.inputText]);

  const scrollToNextMatch = () => {
    const nextIdx = activeHighlightIdx + 1;
    if (handleActiveHighlightChange(activeHighlightIdx, nextIdx)) {
      setActiveHighlightIdx(nextIdx);
    }
  };

  const scrollToPrevMatch = () => {
    const prevIdx = Math.max(activeHighlightIdx - 1, 0);
    handleActiveHighlightChange(activeHighlightIdx, prevIdx);

    if (handleActiveHighlightChange(activeHighlightIdx, prevIdx)) {
      setActiveHighlightIdx(prevIdx);
    }
  };

  const highlightHTML = (html: string, query: string): string => {
    let matchIdx = 0;
    function highlightMatches(match: string) {
      if (!match || match.startsWith('<')) return match;
      matchIdx += 1;
      return `<mark id="${getHighlightId(matchIdx)}">` + match + '</mark>';
    }
    if (query.length > 2) {
      try {
        // query must be escaped before interpolated into regex
        // INFO: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
        const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regexp = new RegExp(`<[^>]+>|(${escapedQuery})`, 'gi');
        return html.replace(regexp, highlightMatches);
      } catch (err) {
        logger.error('Query could not be parsed for highlighting', err);
      }
    }
    return html;
  };

  let highlightedHTML = null;

  highlightedHTML = highlightHTML(props.html, props.inputText);

  React.useEffect(() => {
    setActiveHighlightIdx(0);
  }, [props.html]);

  if (highlightedHTML) {
    const highlightBackground = chroma
      .mix(
        chroma.valid(theme.base.background) ? theme.base.background : '#fff',
        chroma.valid(theme.base.primary) ? theme.base.primary : 'rgb(174, 177, 221)',
        0.4,
      )
      .css();
    return (
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'ArrowRight') {
            scrollToNextMatch();
            // this is necessary to prevent ArrowRight from causing a command execution in this case
            e.stopPropagation();
          } else if (e.key === 'ArrowLeft') {
            scrollToPrevMatch();
          }
        }}
        role="menu" // fixme wrong interactive elem
        css={css`
          img {
            display: block;
            max-width: 100%;
            height: auto;
            box-shadow: ${theme.main.boxShadow};
            border: 1px solid ${theme.main.borderColors};
            margin: 1em auto;
          }
          mark {
            padding: 0;
            background-color: ${highlightBackground};
            transition: background-color ${theme.main.transitionTime} ease;
          }
          .${activeHighlightClassName} {
            background-color: yellow !important;
          }
          p:empty {
            display: none;
          }
          br {
            display: none;
          }
          ol,
          ul {
            padding-inline-start: 0.5rem;
            margin-block-end: 1em;
            margin-block-start: 0.4em;
            color: ${theme.main.color};
          }
          li {
            text-align: left;
            padding-left: 0.5em;
            padding-bottom: 0.75em;
            margin-left: 1em;
          }
          a {
            color: ${chroma
              .mix(
                chroma.valid(theme.base.primary) ? theme.base.primary : 'rgb(174, 177, 221)',
                chroma.valid(theme.base.fontColor) ? theme.base.fontColor : 'rgba(0, 0, 0, 0.85)',
                0.8,
              )
              .css()};
            text-decoration: underline;
          }
          p {
            margin-block-end: 1em;
          }
          strong {
            font-weight: 600;
          }
          h1 {
            font-weight: 700;
            font-size: 18px;
            color: ${theme.main.color};
            margin-block-end: 0.5em;
            text-align: left;
          }
          h2 {
            font-weight: 600;
            line-height: 1.4;
            color: ${theme.main.color};
            font-size: 16px;
            margin-block-start: 1em;
            text-align: left;
          }
          h3 {
            font-weight: 600;
            font-size: 14px;
            color: ${theme.main.color};
            margin-block-start: 1.7em;
            line-height: 1.5;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            color: ${theme.main.color};
            margin-block-start: 1.7em;
            text-align: left;
          }
          iframe {
            position: relative;
            max-width: 100%;
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
          }
          video {
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
          }
          table {
            border-collapse: collapse;
          }
          thead {
            p {
              margin-block-start: 0.2em;
              margin-block-end: 0.2em;
            }
          }
          td {
            border: 1px solid ${theme.main.borderColors};
            padding: 0px 5px;
          }
          tbody {
            vertical-align: top;
            p {
              margin-block-start: 0.1em;
              margin-block-end: 0.1em;
            }
          }
          div {
            margin-block-end: 0.8em;
          }

          pre {
            white-space: pre-line;
          }

          button {
            all: unset;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            white-space: nowrap;
            vertical-align: middle;
            outline: transparent solid 2px;
            outline-offset: 2px;
            line-height: 1.2;
            border-radius: 0.375em;
            font-weight: 500;
            transition-property: background-color, color, transform;
            transition-duration: ${theme.main.transitionTime};
            height: 2em;
            min-width: 2em;
            font-size: 0.875em;
            padding-inline-start: 0.75em;
            padding-inline-end: 0.75em;
            background-color: ${theme.searchTab.activeBackground};
            color: ${theme.searchTab.activeColor};
            &:hover {
              background-color: ${chroma.valid(theme.searchTab.activeBackground)
                ? chroma(theme.searchTab.activeBackground).darken(0.25).css()
                : theme.searchTab.activeBackground};
            }
          }
        `}
      >
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(highlightedHTML) }} />
      </div>
    );
  }

  return null;
};

export default HtmlPreview;
