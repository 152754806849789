// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Lightning01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13.0001 2 4.0936 12.6879c-.3489.4185-.5233.6278-.526.8046a.5.5 0 0 0 .1858.3964C3.8908 14 4.1633 14 4.708 14H12l-1 8 8.9066-10.6879c.3488-.4185.5232-.6278.5258-.8046a.4999.4999 0 0 0-.1857-.3964C20.1094 10 19.8369 10 19.2921 10h-7.292l1-8Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 2 4.0934 12.6879c-.3488.4185-.5232.6278-.5258.8046a.5.5 0 0 0 .1856.3964c.1375.1111.41.1111.9548.1111H12l-1 8 8.9065-10.6879c.3488-.4185.5232-.6278.5259-.8046a.4999.4999 0 0 0-.1857-.3964C20.1092 10 19.8368 10 19.292 10H12l1-8Z"
    />
  </svg>
);
export default Lightning01;
