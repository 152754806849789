import { Step, MultiSelectStep, SelectStep } from '../../../engine/step';
import type { Option } from '../../../engine/option';
import { isParameterOption, isDateTimeOption } from '../options';
import DateTime from '../../../engine/predefinedTypes/DateTime/DateTime';
import { initSelectStep } from '../../../engine/step/SelectStep';
import { StepType } from '../../../engine/step/Step';
import { getArgumentChoiceLabel } from '../selectors';
import { State } from '../..';
import { getContextSettings } from '../selectors';

export const getSelectStepBlock = (s: SelectStep, engine: State['engine']): string | undefined => {
  if (!s.completed || s.selected === null) {
    return s.argument.userDefinedName;
  }

  if (s.selected.type !== 'parameter') {
    return s.argument.userDefinedName;
  }

  // Note: This block simply tries to catch the different possibilities
  // Since these fields are defined by us,
  // we should try to agree to a standard data structure for these selected elements
  if (Array.isArray(s.selected.data)) {
    throw new Error('Selected must be an object');
  } else {
    const contextSettings = getContextSettings(engine);
    return getArgumentChoiceLabel(s.argument, s.selected.data, contextSettings);
  }
};

// FIXME: Not the best name in the history of function names.
export const selectSelectStep = (s: SelectStep, o: Option): Step => {
  if (!isParameterOption(o)) {
    return s;
  }

  const newStep = initSelectStep(s.argument);

  let data = o.parameter;
  if (isDateTimeOption(o)) {
    data = DateTime.clientReturnValue(o.parameter.date);
  }

  newStep.selected = {
    type: 'parameter',
    category: null,
    data,
  };
  newStep.completed = s.completed;

  return newStep;
};

// IMPORTANT NOTE:
// Throughout the ExecutionPath state machine, we are extra careful to
// avoid mutating the state variables outside of the reducer logic.
// This is following React best practices which help prevent hard-to-debug issues
// in the reducer logic.
//
// In this case, creating a new step triggers calculation cascades that might shove us into an infinite loop.
// After testing, there don't seem to be side effects of the Step mutation here. But it is something that we
// should either 1) confirm is acceptable or 2) refactor if bugs start appearing in MultiSelect selections.
//
// This method is used when we want to set selections _during_ the Step (e.g. async preselect loaders)
export const setStepSelections = (s: SelectStep | MultiSelectStep, selection: unknown[] | unknown) => {
  if (Array.isArray(selection)) {
    if (s.type === StepType.Select) return;
  } else {
    if (s.type !== StepType.Select) selection = [selection];
  }
  if (s.type !== StepType.Select) selection = (selection as unknown[]).slice(0);
  s.selected = {
    type: 'parameter',
    category: null,
    data: selection,
  };
};
