export const getYoutubePlayer = (url: string, autoPlay = true) => {
  const videoID = new URL(url).searchParams.get('v');

  if (!videoID) {
    return undefined;
  }

  return `
    <iframe
      src="https://www.youtube.com/embed/${videoID}?autoplay=${autoPlay ? 1 : 0}"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  `;
};

export const getLoomPlayer = (url: string) => {
  const videoID = new URL(url).pathname.split('/').slice(-1)[0];

  if (!videoID) {
    return undefined;
  }

  return `
    <iframe
      src="https://www.loom.com/embed/${videoID}"
      title="Loom video player"
      frameborder="0"
      allowfullscreen
    ></iframe>
  `;
};

export const getVimeoPlayer = (url: string, autoPlay = true) => {
  const videoID = new URL(url).pathname;

  if (!videoID) {
    return undefined;
  }

  return `
    <iframe
      src="https://player.vimeo.com/video${videoID}?autoplay=${autoPlay ? 1 : 0}"
      title="Vimeo video player"
      frameborder="0"
      allowfullscreen
    ></iframe>
  `;
};
