import { css } from '@emotion/core';

/* Add any global styles / resets needed for the Bar here.
 *
 * These will be installed just above the Bar component and will
 * affect it even if it is located in a Shadow DOM.
 */
export default css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;
