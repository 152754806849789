/** @jsx jsx */
import { jsx } from '@emotion/core';
import getPlayer from '../video/players';
import { URLPreview, VideoEmbedPreview } from '../video/Previews';
import { isValidURL } from '../video/utils';

interface VideoPreviewProps {
  source: string;
  containerStyles?: React.CSSProperties;
  autoPlay?: boolean;
}

const VideoPreview = ({ source, containerStyles, autoPlay }: VideoPreviewProps) => {
  const isEmbeddablePreview = !isValidURL(source) || getPlayer(source);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        ...containerStyles,
      }}
    >
      {!isEmbeddablePreview ? (
        <URLPreview source={source} autoPlay={autoPlay} />
      ) : (
        <VideoEmbedPreview source={getPlayer(source, autoPlay) || source} />
      )}
    </div>
  );
};

export default VideoPreview;
