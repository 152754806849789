import { EngineState } from './state';
import { State } from '..';
import { sub } from '../util/sub';
import Analytics from '../../analytics/Analytics';
import { getCommands, selectEndUserEndpoint } from './selectors';
import * as Engine from './actions';
import ExecutionPath from '../../engine/ExecutionPath';
import isEqual from 'lodash/isEqual';
import { findOp, getPrevValue, Ops } from '../util/hasOp';
import axiosInstance from '@commandbar/internal/middleware/network';
import merge from 'lodash/merge';
import { ref } from 'valtio';
import debounce from 'lodash/debounce';

import { getElement } from '@commandbar/internal/util/dom';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Reporting from '../../analytics/Reporting';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import * as Command from '@commandbar/internal/middleware/command';
import Mousetrap from '@commandbar/internal/client/mousetrap_fork';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { StepType } from '../../engine/step/Step';
import { isCommandOption, isParameterOptionSelected, isParameterOption } from './options';
import {
  clearFromEndUserStore,
  initFromLocal,
  patchRemoteData,
  setToEndUserStore,
  setVerified,
} from './end-user/actions';
import { isRemoteEnduserStoreEnabled } from './end-user/selectors';
import { recentUIDs } from './end-user/selectors';
import { initGenerateOptionsSubs } from './generate-options/subscriptions';
import initGetRecordsSubscriptions from './generate-options/get-records-subscriptions';
import Logger from '@commandbar/internal/util/Logger';
import { createFallbackHmac } from './end-user/helpers';
import { emptyEndUserStoreState, RemoteEndUserStoreData } from './end-user/state';
import { getHelpHubRecommendations, startNudgeMachine, triggerChecklists, triggerNudges } from './actions';
import { INudgeType } from '@commandbar/internal/middleware/types';
import { queryHelpDocs } from '../../client_api/search';

// Auto scroll to single select step selected option.
// Will scroll to the selected item in two cases:
//  - on the initial render of single select step
//  - when user completely deletes text from the search input
const autoScrollStep = (_: EngineState) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  if (currentStep?.type === StepType.Select && currentStep.selected?.data && _.engine.inputText.length === 0) {
    const selectedIndex = _.engine.sortedOptions.findIndex(
      (option) => isParameterOption(option) && isParameterOptionSelected(option, currentStep),
    );

    if (selectedIndex !== -1) {
      _.engine.focusedIndex = selectedIndex;
    }
  }
};

const currentStepIndex = (_: EngineState) => {
  _.engine.currentStepIndex = ExecutionPath.currentStepAndIndex(_.engine).currentStepIndex;
};

const setLastVisibleTime = (_: EngineState) => {
  // Update the last_visible time of commands
  // Need to do this on every open, because changed variables (callbacks, context, urls) change availability
  if (_.engine.visible && !_.engine.testMode && _.engine.currentOptions.length > 0) {
    const availableCommands = _.engine.initialOptions.filter(isCommandOption).map((c) => c.command.id);
    Analytics.availability(availableCommands);
  }
};

const resetFocusedIndexOnStepChange = (_: EngineState, ops: Ops) => {
  const replacedEngine = getPrevValue<State['engine']>(findOp('set', ['engine'], ops));
  if (replacedEngine && replacedEngine.currentStepIndex === _.engine.currentStepIndex) return;
  Engine.resetFocusedIndex(_);
};

const resetFocusedIndexOnInputChange = (_: EngineState, ops: Ops) => {
  const replacedEngine = getPrevValue<State['engine']>(findOp('set', ['engine'], ops));
  if (replacedEngine && replacedEngine.inputText === _.engine.inputText) return;
  Engine.resetFocusedIndex(_);
};

const syncEndUserStore = (
  _: EngineState,
  store: 'hotkeys' | 'checklist_interactions' | 'nudges_interactions',
  remotePreferences: RemoteEndUserStoreData,
) => {
  const remote = !!remotePreferences?.[store] ? remotePreferences[store] : {};
  const local = !!_.engine.endUserStore.data[store] ? _.engine.endUserStore.data[store] : {};
  let merged: { [key: string]: any[] } = {};

  merged = merge({}, local, remote);

  if (Object.keys(merged).length === 0) {
    clearFromEndUserStore(_, store);
  } else if (!isEqual(local, merged)) {
    setToEndUserStore(_, store, merged);
  }

  if (!isEqual(remote, merged)) {
    patchRemoteData(_, merged, store);
  }
};

const resetNudgesState = (_: EngineState) => {
  _.engine.endUserStore.hasRemoteLoaded = false;

  const runningServices = new Map(_.engine.nudgeServices);
  for (const service of runningServices.values()) {
    const nudge: INudgeType = service.getSnapshot().context.nudge;
    service.send('force dismiss');
    service.stop();
    _.engine.nudgeServices.delete(String(nudge.id));
    startNudgeMachine(_, nudge);
  }
};

const refreshEndUserData = async (_: State, ops: Ops) => {
  const replacedEnduser = getPrevValue<State['engine']['endUser']>(findOp('set', ['engine', 'endUser'], ops));
  if (replacedEnduser === _.engine.endUser) return;

  initFromLocal(_);

  if (typeof replacedEnduser !== 'undefined') {
    resetNudgesState(_);
  }

  if (isRemoteEnduserStoreEnabled(_.engine) && !_.airgap && !!_.engine.endUser) {
    const endpoint = selectEndUserEndpoint(_);

    const remotePreferences = emptyEndUserStoreState().data;

    if (!!endpoint) {
      const endUserResponse = axiosInstance.get(endpoint, {
        headers: {
          'X-USER-AUTHORIZATION': !!_.engine.endUser.hmac ? _.engine.endUser.hmac : createFallbackHmac(),
        },
      });

      endUserResponse
        .then((response: { data: RemoteEndUserStoreData }) => {
          Object.assign(remotePreferences, response.data);

          if (!!_.engine.organization?.end_user_shortcuts_enabled) {
            syncEndUserStore(_, 'hotkeys', remotePreferences);
          }

          syncEndUserStore(_, 'checklist_interactions', remotePreferences);
          syncEndUserStore(_, 'nudges_interactions', remotePreferences);

          setToEndUserStore(_, 'analytics', remotePreferences?.['analytics']);
          setToEndUserStore(_, 'properties', remotePreferences?.['properties']);

          setVerified(_, !!_.engine?.endUser?.hmac);
        })
        .catch(() => {
          Logger.warn('Failed to load end user data');
          setVerified(_, false);
        })
        .finally(() => {
          _.engine.endUserStore.hasRemoteLoaded = true;
        });
    }
  } else {
    _.engine.endUserStore.hasRemoteLoaded = true;
  }
};

const resetErrorIndexOnOptionsChange = (_: EngineState) => {
  // If the options change, we keep the currently selected index as-is
  // so that the focused option doesn't jump up or down the Bar.
  //
  // If the errorIndex was set in this case, in the new list of sortedOptions,
  // it may be pointing to a different option than before. Since we don't have
  // unique IDs for options, we can't be sure, so we just reset the errorIndex
  // in this case.
  _.engine.errorIndex = -1;
};

const setupShortcuts = (_: EngineState) => {
  if (!_.engine?.organization) return;

  Mousetrap.reset(); // Clear all bound hotkeys

  if (['mac', 'windows', 'linux'].includes(_.engine.platform)) {
    getCommands(_.engine).map((cmd) => {
      let hotkey = _.engine.platform === 'mac' ? cmd.hotkey_mac : cmd.hotkey_win;
      const cmdUID = Command.commandUID(cmd);

      if (typeof _.engine.endUserStore.data.hotkeys?.[cmdUID] !== 'undefined') {
        hotkey = _.engine.endUserStore.data.hotkeys[cmdUID];
      }

      if (hotkey.length === 0 || (!cmd.is_live && !_.engine.testMode)) {
        return null;
      }

      Mousetrap.bind(hotkey, () => {
        const isDisabled = Engine.executeCommand(
          _,
          cmd,
          () => {
            dispatchCustomEvent('commandbar-shortcut-executed', { detail: { keys: hotkey } });
          },
          () => Reporting.unavailableShortcut(Hotkey.toArray(hotkey), cmd),
        );

        // https://craig.is/killing/mice#api.bind.default
        // Returning false prevents the event from bubbling up, i.e., doesn't fill in the input
        return isDisabled;
      });
      return null;
    });
  }
};

const deriveEndUserData = (_: EngineState) => {
  /**
   * For optimization, store derived info based on end user store info
   */
  _.engine.endUserStore.derived = {
    recentUIDs: recentUIDs(_.engine.endUserStore.data.recents),
  };
};

const mergeContextSettings = (_: EngineState) => {
  _.engine.contextSettings = merge({}, _.engine.serverContextSettings, _.engine.localContextSettings);
};

const locationSub = (_: State) => {
  /** inspired by https://dirask.com/posts/JavaScript-on-location-changed-event-on-url-changed-event-DKeyZj */

  const history = window.history;

  const pushState = history.pushState;
  const replaceState = history.replaceState;

  let cancelled = false;
  let oldHref = document.location.href;

  history.pushState = function (...args) {
    pushState.apply(history, args);
    if (cancelled) return;

    // NOTE: spreading window.location into new object here triggers subscribers to _.engine.location
    // without this, since window.location seems to be a singleton, subscribers would not be triggered
    _.engine.location = ref({ ...window.location });
  };

  history.replaceState = function (...args) {
    replaceState.apply(history, args);
    if (cancelled) return;

    // NOTE: spreading window.location into new object here triggers subscribers to _.engine.location
    // without this, since window.location seems to be a singleton, subscribers would not be triggered
    _.engine.location = ref({ ...window.location });
  };

  const checkHrefChange = () => {
    if (oldHref !== document.location.href) {
      oldHref = document.location.href;
      _.engine.location = ref({ ...window.location });
    }
  };

  window.addEventListener('hashchange', checkHrefChange);

  return () => {
    // NOTE: unfortunately, it is not possible to uninstall our pushState and replaceState intermediaries
    // because someone else may be holding a reference to them, or even may have replaced them in a similar
    // fashion. If we just restore the original functions, any subsequent changes to pushState and replaceState
    // would be overwritten.
    //
    // Instead, we simply cancel the effects and leave them in place.
    window.removeEventListener('hashchange', checkHrefChange);
    cancelled = true;
  };
};

const helpHubSearch = (_: EngineState) => {
  if (!_.engine.organization) return;

  const query = _.engine.helpHub.query || '';

  if (_.engine.helpHub.query === '') {
    const recommendations = getHelpHubRecommendations(_);
    if (recommendations.length) {
      _.engine.helpHub.searchResults = recommendations;
      _.engine.helpHub.liveAnswer = null;
      _.engine.helpHub.loadingLiveAnswer = false;
      return;
    }
  }

  _.engine.helpHub.loading = true;
  _.engine.helpHub.liveAnswer = null;
  _.engine.helpHub.loadingLiveAnswer = false;

  const promises = [
    queryHelpDocs(_.engine.organization.id, query, _.engine?.endUser).then((docs) => {
      _.engine.helpHub.searchResults = docs;
      if (!_.engine.helpHub.searchResults.length) {
        Reporting.noResultsForQuery(query, { type: 'helphub' });
      }
    }),
  ];

  Promise.allSettled(promises).finally(() => {
    _.engine.helpHub.loading = false;
  });

  // const isAllowedToGetLiveAnswer =
  //   !!_.engine?.organization?.helphub_ai_enabled && // live answers are enabled
  //   !!query &&
  //   !_.engine.helpHub.loadingLiveAnswer && // live answer is not loading
  //   (query.length > 5 || query?.trim().endsWith('?')); // query is long enough or ends with a question mark

  // if (isAllowedToGetLiveAnswer) {
  //   Engine.getHelpHubLiveAnswer(_);
  // }
};

let mutationObserver: MutationObserver | null = null;
const setupMutationObserver = (_: EngineState) => {
  if (mutationObserver) mutationObserver.disconnect();

  const elementTriggerFlag = LocalStorage.get('can_set_element_trigger', false);
  // feature flag
  const canSetElementTrigger = _.engine.organization?.id === 'ac965263' || !!elementTriggerFlag;

  // Only setup the observer if we are using nudges or checklists and at least one of them is triggered
  // when an element appears.
  if (
    canSetElementTrigger &&
    (_.engine.products.includes('nudges') || _.engine.products.includes('questlists')) &&
    _.engine.triggerableSelectors.length > 0
  ) {
    // Debounce the triggers to avoid triggering too many times.
    // We could run into a huge performance issue without this because it would triggered
    // everytime something is changed in the DOM.
    const debouncedTriggers = debounce(() => {
      for (let i = 0; i < _.engine.triggerableSelectors.length; i++) {
        const selector = _.engine.triggerableSelectors[i];
        const element = getElement(selector);

        if (element) {
          const style = window.getComputedStyle(element);
          const isDisplayNone = style.display === 'none';
          const isVisibilityHidden = style.visibility === 'hidden';

          const isHidden = isDisplayNone || isVisibilityHidden;

          // HACK: It's still possible for an element to be hidden due to its parent element
          // having overflow: hidden and our element being outside of its bounds
          // I've left this here for now because it's a rare case and I don't want to
          // introduce a performance hit for everyone by calling getBoundingClientRect
          // on every element and its parents.
          if (!isHidden) {
            triggerNudges(_, { type: 'when_element_appears', meta: { selector } });
            triggerChecklists(_, { type: 'when_element_appears', meta: { selector } });
            // remove the selector from the list so we don't trigger it again
            _.engine.triggerableSelectors.splice(i, 1);
          }
        }
      }
    }, 1500);

    mutationObserver = new MutationObserver(() => {
      debouncedTriggers();
    });
  }

  mutationObserver?.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true,
  });

  return () => {
    mutationObserver?.disconnect();
  };
};

export const initEngineSubs = (_: State) => [
  /**
   * [Shorcuts]: set hotkeys on each change of context, callbacks, commands
   **/
  /*
    NOTE: How this can go wrong? (can move to a doc)
    This is using our existing strategy that we might consider rethinking
    We are setting the keybinds for _all_ the commands (whether or not they are admin/drafts/available/etc)
    A protective measure we take is we check availability of a shortcut command before executing it.
    Where does the preventDefault() go?
    Decision: If a keybind (say cmd-d) is set in the host app _and_ in CB, which should fire? Should this be a setting? If CB, we definitely need to exclude draft commands
    Decision: If a command isn't available in the current state, should it preventDefault?
    Mousetrap handles duplicate keybinds by only running the first one.
    How would we handle the following situation:
      - Two commands programmed to never be available at the same time
      - Attached the same keybind (because they are conceptually similar)
      - In this case, we would need to limit shortcut setup to available commands
      - Example (although there's a more elegant solution): "Toggle Dark", "Toggle Light"
     */
  sub(_, setupShortcuts, [
    ['engine', 'commands'],
    ['engine', 'programmaticCommands'],
    ['engine', 'hotloadedCommands'],

    ['engine', 'organization'],
    ['engine', 'endUserStore', 'data', 'hotkeys'],
    ['engine', 'testMode'],
  ]),
  sub(_, refreshEndUserData, [['engine', 'endUser']]),
  sub(_, mergeContextSettings, [
    ['engine', 'serverContextSettings'],
    ['engine', 'localContextSettings'],
  ]),
  sub(_, setLastVisibleTime, [['engine', 'visible']]),
  sub(_, currentStepIndex, [['engine', 'steps']], true),
  sub(_, resetFocusedIndexOnStepChange, [['engine', 'currentStepIndex']]),
  sub(_, resetFocusedIndexOnInputChange, [['engine', 'inputText']]),
  sub(_, autoScrollStep, [
    ['engine', 'steps'],
    ['engine', 'sortedOptions'],
  ]),

  sub(_, (_) => Engine.triggerNudges(_, { type: 'when_conditions_pass' }), [
    ['engine', 'endUserStore', 'hasRemoteLoaded'], // NOTE: need this because triggerNudges depends on _.endUser
    ['engine', 'context'],
    ['engine', 'location'],
    ['engine', 'products'],
  ]),

  sub(_, (_) => Engine.triggerChecklists(_, { type: 'when_conditions_pass' }), [
    ['engine', 'endUserStore', 'hasRemoteLoaded'],
    ['engine', 'context'],
    ['engine', 'location'],
    ['engine', 'products'],
    ['engine', 'isAdmin'],
  ]),

  // FIXME: when_page_reached is deprecated an cannot be selected for new nudges and questlists. Need to keep only for pre-existing nudges and questlists. Should remove this once possible
  // NOTE: this doesn't fire for initial page load URL -- that is handled in commandbar/src/store/engine/checklists/actions.ts#setChecklists
  sub(_, (_) => Engine.triggerChecklists(_, { type: 'when_page_reached', meta: { url: _.engine.location.href } }), [
    ['engine', 'endUserStore', 'hasRemoteLoaded'],
    ['engine', 'location'],
  ]),
  // NOTE: this doesn't fire for initial page load URL -- that is handled in commandbar/src/store/engine/nudges/actions.ts#setNudges
  sub(_, (_) => Engine.triggerNudges(_, { type: 'when_page_reached', meta: { url: _.engine.location.href } }), [
    ['engine', 'endUserStore', 'hasRemoteLoaded'], // NOTE: need this because triggerNudges depends on _.endUser
    ['engine', 'location'],
  ]),

  sub(_, setupMutationObserver, [
    ['engine', 'triggerableSelectors'],
    ['engine', 'products'],
  ]),

  sub(_, (_) => Engine.updateChecklistItemConditionsGoals(_), [
    ['engine', 'activeChecklist'],
    ['engine', 'context'],
    ['engine', 'location'],
  ]),

  sub(_, resetErrorIndexOnOptionsChange, [['engine', 'sortedOptions']]),
  sub(_, deriveEndUserData, [['engine', 'endUserStore', 'data', 'recents']]),
  sub(_, helpHubSearch, [['engine', 'helpHub', 'query']]),
  ...initGenerateOptionsSubs(_),
  locationSub(_),
  ...initGetRecordsSubscriptions(_),
];
