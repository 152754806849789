/** @jsx jsx */
import React, { CSSProperties } from 'react';
import { jsx } from '@emotion/core';

import { useStore } from '../../hooks/useStore';
import { getCommandById } from '../../store/engine';
import VideoPreview from '../detailpreview/VideoPreview';
import { NudgePreviewService, NudgeService } from '../../store/engine/nudges/machine';
import { BookOpen02, Image01, VideoRecorder } from '@commandbar/design-system/icons/react';

import type { ICommandType, INudgeStepType } from '@commandbar/internal/middleware/types';
import { parseMarkdown } from './parseMarkdown';
import { SurveyResponseEvent } from '@commandbar/internal/client/EventHandler';
import TextInputBlock from './surveys/TextInputBlock';
import RatingInputBlock from './surveys/RatingInputBlock';
import helpdocService from '../../services/helpdocService';

const getStyles = (): Record<string, CSSProperties> => ({
  image: { width: '100%', borderRadius: '4px', pointerEvents: 'none' },
  helpDoc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '8px',
    height: '175px',
    borderRadius: '4px',
  },
  helpDocPreview: {
    position: 'relative',
    height: '175px',
    padding: '16px 16px 0',
    borderRadius: '4px',
    cursor: 'pointer',
    pointerEvents: 'all',
    overflow: 'hidden',
  },
  helpDocTitle: {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '39px',
  },
  ctaWithStepCount: {
    display: 'inline-flex',
    width: 'auto',
    maxWidth: '50%',
  },
});

const EyeButton = ({
  background,
  foreground,
}: {
  background: CSSProperties['fill'];
  foreground: CSSProperties['fill'];
}) => (
  <svg
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="88"
    fill="none"
    viewBox="0 0 88 80"
  >
    <g filter="url(#filter0_d_437_3698)">
      <rect width="56" height="56" x="16" y="12" fill={background} rx="28"></rect>
      <path fill={foreground} d="M44 42.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
      <path
        fill={foreground}
        fillRule="evenodd"
        d="M35.103 39.54a9.38 9.38 0 0117.794-.007c.1.302.1.627 0 .928a9.38 9.38 0 01-17.794.006c-.1-.301-.1-.627 0-.928zm13.272.46a4.375 4.375 0 11-8.75 0 4.375 4.375 0 018.75 0z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_437_3698"
        width="88"
        height="88"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="8"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_437_3698"></feBlend>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_437_3698" result="shape"></feBlend>
      </filter>
    </defs>
  </svg>
);

interface ContentProps {
  type: 'modal' | 'popover' | 'pin';
  contentBlock: INudgeStepType['content'][number];
  markdownStyles: CSSProperties;
  buttonStyles: CSSProperties;
  service?: NudgeService | NudgePreviewService;
  helpDocStyles?: {
    color: CSSProperties['color'];
    background: CSSProperties['background'];
    mixBlendMode: CSSProperties['mixBlendMode'];
  };
  styleOverrides?: CSSProperties;
  setSurveyResponse: (response: SurveyResponseEvent['response'] | undefined) => void;
}

const Content = ({
  type,
  contentBlock,
  markdownStyles,
  buttonStyles,
  service,
  helpDocStyles,
  styleOverrides,
  setSurveyResponse,
}: ContentProps) => {
  const _ = useStore();
  const styles = getStyles();

  const commandId = contentBlock?.meta && 'command' in contentBlock?.meta ? contentBlock.meta.command : undefined;
  const orgId = _.engine.organization?.id;

  const onHelpDocClick = React.useCallback(() => {
    service?.send('help doc clicked');
  }, [service]);

  const [command, setCommand] = React.useState<ICommandType | undefined>(undefined);

  React.useEffect(() => {
    setCommand(undefined); // clear any previously-set command
    if (commandId === undefined) return;

    // try to get command from list of commands in Engine
    const commandFromEngine = getCommandById(_.engine, commandId);
    if (commandFromEngine) {
      setCommand(commandFromEngine);
      return;
    }

    // --otherwise-- need to fetch from helpdoc service
    let canceled = false;
    (async () => {
      if (!orgId) return;

      const command = await helpdocService.getHelpdocCommand(orgId, commandId);
      if (canceled) return;

      if (command) setCommand(command);
    })();
    return () => {
      canceled = true;
    };
  }, [orgId, commandId]);

  switch (contentBlock?.type) {
    case 'markdown':
      if (!!contentBlock.meta.value) {
        return (
          <div style={markdownStyles} dangerouslySetInnerHTML={{ __html: parseMarkdown(contentBlock.meta.value) }} />
        );
      }
      return null;
    case 'image':
      const placeholderHeight = (() => {
        if (type === 'popover') {
          return '168px';
        }

        if (type === 'pin') {
          return '168px';
        }

        if (type === 'modal') {
          return '192px';
        }
      })();

      return contentBlock.meta.src ? (
        <img
          src={contentBlock.meta.src}
          alt={contentBlock.meta.file_name}
          style={{ ...styles.image, ...styleOverrides }}
        />
      ) : (
        <div
          style={{
            height: placeholderHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <Image01
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );
    case 'video':
      const placeholder = (
        <div
          style={{
            height: type === 'modal' ? '207px' : '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8px',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <VideoRecorder
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );

      if (contentBlock.meta.type === 'command') {
        if (command?.template.type === 'video') {
          return (
            <VideoPreview
              source={command.template.value}
              containerStyles={{ height: 'auto', marginTop: '8px', padding: 0 }}
              autoPlay={false}
            />
          );
        }
        return placeholder;
      } else if (contentBlock.meta.type === 'url') {
        return contentBlock.meta.src.length > 0 ? (
          <VideoPreview
            source={contentBlock.meta.src}
            containerStyles={{ height: 'auto', marginTop: '8px', padding: 0 }}
            autoPlay={false}
          />
        ) : (
          placeholder
        );
      }

      return null;
    case 'help_doc_command':
      if (command) {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div style={{ ...styles.helpDoc, ...styleOverrides }} onClick={onHelpDocClick}>
            <div
              style={{
                ...styles.helpDocPreview,
                background: helpDocStyles?.background,
                width: '100%',
              }}
            >
              <span
                className="commandbar-help-doc-title"
                style={{
                  ...styles.helpDocTitle,
                  color: helpDocStyles?.color,
                  mixBlendMode: helpDocStyles?.mixBlendMode,
                }}
              >
                {command?.text}
              </span>
              <EyeButton background={buttonStyles.backgroundColor} foreground={buttonStyles.color} />
            </div>
          </div>
        );
      }
      return (
        <div
          style={{
            height: styles.helpDoc.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#DFDFE2',
            border: '1px solid #DFDFE2',
            borderRadius: '4px',
          }}
        >
          <BookOpen02
            width={48}
            height={48}
            css={{
              path: {
                strokeWidth: 1,
              },
            }}
          />
        </div>
      );
    case 'survey_text': {
      return <TextInputBlock placeholder={contentBlock.meta.prompt} setSurveyResponse={setSurveyResponse} />;
    }
    case 'survey_rating': {
      return (
        <React.Fragment>
          <RatingInputBlock setSurveyResponse={setSurveyResponse} ratingBlock={contentBlock.meta} />
        </React.Fragment>
      );
    }
    default:
      return null;
  }
};

export default Content;
