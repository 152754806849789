import 'core-js/actual/object/get-own-property-symbols'
import 'core-js/actual/symbol/description'
import 'core-js/actual/symbol/async-iterator'
import 'core-js/modules/es.symbol.has-instance'
import 'core-js/modules/es.symbol.is-concat-spreadable'
import 'core-js/modules/es.symbol.iterator'
import 'core-js/modules/es.symbol.match'
import 'core-js/modules/es.symbol.match-all'
import 'core-js/modules/es.symbol.replace'
import 'core-js/modules/es.symbol.search'
import 'core-js/actual/symbol/species'
import 'core-js/modules/es.symbol.split'
import 'core-js/modules/es.symbol.to-primitive'
import 'core-js/modules/es.symbol.to-string-tag'
import 'core-js/actual/symbol/unscopables'
import 'core-js/actual/error/constructor'
import 'core-js/modules/es.aggregate-error'
import 'core-js/modules/es.aggregate-error.cause'
import 'core-js/actual/array/at'
import 'core-js/actual/array/concat'
import 'core-js/actual/array/copy-within'
import 'core-js/actual/array/fill'
import 'core-js/actual/array/filter'
import 'core-js/actual/array/find'
import 'core-js/actual/array/find-index'
import 'core-js/es/array/find-last'
import 'core-js/es/array/find-last-index'
import 'core-js/modules/es.array.flat'
import 'core-js/modules/es.array.flat-map'
import 'core-js/modules/es.array.from'
import 'core-js/actual/array/includes'
import 'core-js/actual/array/index-of'
import 'core-js/modules/es.array.iterator'
import 'core-js/actual/array/last-index-of'
import 'core-js/actual/array/map'
import 'core-js/actual/array/of'
import 'core-js/actual/array/push'
import 'core-js/actual/array/reduce'
import 'core-js/actual/array/reduce-right'
import 'core-js/actual/array/slice'
import 'core-js/actual/array/sort'
import 'core-js/modules/es.array.species'
import 'core-js/actual/array/splice'
import 'core-js/es/array/to-reversed'
import 'core-js/modules/es.array.to-sorted'
import 'core-js/es/array/to-spliced'
import 'core-js/modules/es.array.unscopables.flat'
import 'core-js/modules/es.array.unscopables.flat-map'
import 'core-js/actual/array/unshift'
import 'core-js/es/array/virtual/with'
import 'core-js/actual/date/to-primitive'
import 'core-js/actual/function/has-instance'
import 'core-js/actual/global-this'
import 'core-js/actual/json/stringify'
import 'core-js/actual/json/to-string-tag'
import 'core-js/modules/es.map'
import 'core-js/actual/math/acosh'
import 'core-js/actual/math/asinh'
import 'core-js/actual/math/atanh'
import 'core-js/actual/math/cbrt'
import 'core-js/actual/math/clz32'
import 'core-js/actual/math/cosh'
import 'core-js/actual/math/expm1'
import 'core-js/actual/math/fround'
import 'core-js/actual/math/hypot'
import 'core-js/actual/math/log10'
import 'core-js/actual/math/log1p'
import 'core-js/actual/math/log2'
import 'core-js/actual/math/sign'
import 'core-js/actual/math/sinh'
import 'core-js/actual/math/tanh'
import 'core-js/actual/math/to-string-tag'
import 'core-js/actual/math/trunc'
import 'core-js/actual/number/constructor'
import 'core-js/actual/number/epsilon'
import 'core-js/actual/number/is-integer'
import 'core-js/actual/number/is-safe-integer'
import 'core-js/actual/number/max-safe-integer'
import 'core-js/actual/number/min-safe-integer'
import 'core-js/actual/number/parse-float'
import 'core-js/actual/number/parse-int'
import 'core-js/actual/number/to-exponential'
import 'core-js/actual/object/assign'
import 'core-js/actual/object/define-getter'
import 'core-js/actual/object/define-properties'
import 'core-js/actual/object/define-property'
import 'core-js/actual/object/define-setter'
import 'core-js/actual/object/entries'
import 'core-js/actual/object/freeze'
import 'core-js/modules/es.object.from-entries'
import 'core-js/actual/object/get-own-property-descriptor'
import 'core-js/actual/object/get-own-property-descriptors'
import 'core-js/actual/object/get-own-property-names'
import 'core-js/actual/object/get-prototype-of'
import 'core-js/actual/object/has-own'
import 'core-js/actual/object/is-extensible'
import 'core-js/actual/object/is-frozen'
import 'core-js/actual/object/is-sealed'
import 'core-js/actual/object/keys'
import 'core-js/actual/object/lookup-getter'
import 'core-js/actual/object/lookup-setter'
import 'core-js/actual/object/prevent-extensions'
import 'core-js/actual/object/seal'
import 'core-js/actual/object/set-prototype-of'
import 'core-js/modules/es.object.to-string'
import 'core-js/actual/object/values'
import 'core-js/actual/parse-float'
import 'core-js/actual/parse-int'
import 'core-js/modules/es.promise'
import 'core-js/modules/es.promise.all-settled'
import 'core-js/modules/es.promise.any'
import 'core-js/modules/es.promise.finally'
import 'core-js/actual/reflect/apply'
import 'core-js/actual/reflect/construct'
import 'core-js/actual/reflect/define-property'
import 'core-js/actual/reflect/delete-property'
import 'core-js/actual/reflect/get'
import 'core-js/actual/reflect/get-own-property-descriptor'
import 'core-js/actual/reflect/get-prototype-of'
import 'core-js/actual/reflect/has'
import 'core-js/actual/reflect/is-extensible'
import 'core-js/actual/reflect/own-keys'
import 'core-js/actual/reflect/prevent-extensions'
import 'core-js/actual/reflect/set'
import 'core-js/actual/reflect/set-prototype-of'
import 'core-js/actual/reflect/to-string-tag'
import 'core-js/modules/es.regexp.constructor'
import 'core-js/modules/es.regexp.dot-all'
import 'core-js/modules/es.regexp.exec'
import 'core-js/actual/instance/flags'
import 'core-js/modules/es.regexp.sticky'
import 'core-js/modules/es.regexp.test'
import 'core-js/actual/regexp/to-string'
import 'core-js/modules/es.set'
import 'core-js/actual/string/at'
import 'core-js/actual/instance/code-point-at'
import 'core-js/actual/instance/ends-with'
import 'core-js/actual/string/from-code-point'
import 'core-js/actual/string/includes'
import 'core-js/actual/instance/is-well-formed'
import 'core-js/modules/es.string.iterator'
import 'core-js/modules/es.string.match'
import 'core-js/modules/es.string.match-all'
import 'core-js/actual/instance/pad-end'
import 'core-js/actual/instance/pad-start'
import 'core-js/actual/string/raw'
import 'core-js/actual/instance/repeat'
import 'core-js/modules/es.string.replace'
import 'core-js/modules/es.string.replace-all'
import 'core-js/modules/es.string.search'
import 'core-js/modules/es.string.split'
import 'core-js/actual/instance/starts-with'
import 'core-js/actual/instance/to-well-formed'
import 'core-js/actual/instance/trim'
import 'core-js/actual/instance/trim-end'
import 'core-js/actual/instance/trim-left'
import 'core-js/modules/es.typed-array.float32-array'
import 'core-js/modules/es.typed-array.float64-array'
import 'core-js/modules/es.typed-array.int8-array'
import 'core-js/modules/es.typed-array.int16-array'
import 'core-js/modules/es.typed-array.int32-array'
import 'core-js/modules/es.typed-array.uint8-array'
import 'core-js/modules/es.typed-array.uint8-clamped-array'
import 'core-js/modules/es.typed-array.uint16-array'
import 'core-js/modules/es.typed-array.uint32-array'
import 'core-js/actual/typed-array/at'
import 'core-js/actual/typed-array/copy-within'
import 'core-js/actual/typed-array/every'
import 'core-js/actual/typed-array/fill'
import 'core-js/actual/typed-array/filter'
import 'core-js/actual/typed-array/find'
import 'core-js/actual/typed-array/find-index'
import 'core-js/es/typed-array/find-last'
import 'core-js/es/typed-array/find-last-index'
import 'core-js/actual/typed-array/for-each'
import 'core-js/actual/typed-array/from'
import 'core-js/actual/typed-array/includes'
import 'core-js/actual/typed-array/index-of'
import 'core-js/modules/es.typed-array.iterator'
import 'core-js/actual/typed-array/join'
import 'core-js/actual/typed-array/last-index-of'
import 'core-js/actual/typed-array/map'
import 'core-js/actual/typed-array/of'
import 'core-js/actual/typed-array/reduce'
import 'core-js/actual/typed-array/reduce-right'
import 'core-js/actual/typed-array/reverse'
import 'core-js/actual/typed-array/set'
import 'core-js/actual/typed-array/slice'
import 'core-js/actual/typed-array/some'
import 'core-js/actual/typed-array/sort'
import 'core-js/actual/typed-array/to-locale-string'
import 'core-js/es/typed-array/to-reversed'
import 'core-js/modules/es.typed-array.to-sorted'
import 'core-js/actual/typed-array/to-string'
import 'core-js/es/typed-array/with'
import 'core-js/modules/es.weak-map'
import 'core-js/modules/es.weak-set'
import 'core-js/modules/web.atob'
import 'core-js/actual/dom-collections/for-each'
import 'core-js/modules/web.dom-collections.iterator'
import 'core-js/modules/web.dom-exception.constructor'
import 'core-js/modules/web.dom-exception.stack'
import 'core-js/actual/dom-exception/to-string-tag'
import 'core-js/actual/clear-immediate'
import 'core-js/actual/queue-microtask'
import 'core-js/actual/self'
import 'core-js/modules/web.structured-clone'
import 'core-js/modules/web.url'
import 'core-js/modules/web.url.can-parse'
import 'core-js/actual/url/to-json'
import 'core-js/modules/web.url-search-params'
import 'core-js/modules/web.url-search-params.delete'
import 'core-js/modules/web.url-search-params.has'
import 'core-js/modules/web.url-search-params.size'