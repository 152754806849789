/** @jsx jsx  */
import { jsx } from '@emotion/core';
import React from 'react';
import useDraggable from '../../hooks/useDraggable';
import HelpHubModal from './HelpHubModal';

const DraggableHelpHubModal = () => {
  const [modalEl, setModalEl] = React.useState<HTMLDivElement | null>(null);

  useDraggable(modalEl);

  return <HelpHubModal parentCallbackRef={setModalEl} />;
};

export default DraggableHelpHubModal;
