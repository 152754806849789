import { ChatHistory } from '@commandbar/internal/client/EventHandler';
import { getSDK } from '@commandbar/internal/client/globals';
import { _user } from '@commandbar/internal/client/symbols';
import axiosInstance from '@commandbar/internal/middleware/network';
import {
  IAIAnswerPayloadType,
  IAIAnswerType,
  ICommandType,
  IContinuationsPayloadType,
  IContinuationsType,
  IEndUserType,
  IHelpDocHitType,
  IInstantAnswerType,
  IOrganizationType,
  IQuestionSuggestionsPayloadType,
  IQuestionSuggestionsType,
} from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';

import { HelpHubDoc } from '../store/engine';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { commandToHelpHubDoc } from '../store/engine/help-hub/helpers';
import { Chat } from '@commandbar/internal/middleware/chat';
import { HelpDocsSearch } from '@commandbar/internal/middleware/helpDocsSearch';

const _queryHelpDocs = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
  abortController?: AbortController,
): Promise<IHelpDocHitType[]> => {
  const response = await HelpDocsSearch.search(
    orgId,
    { query, include_additional_docs: true },
    {
      signal: abortController?.signal,
      // @ts-expect-error: `axios` header types seem to be wonky
      headers: {
        ...axiosInstance.defaults.headers,
        ...(!!endUser?.hmac && { 'X-USER-AUTHORIZATION': endUser?.hmac }),
        ...(!!endUser?.slug && { 'X-USER-ID': endUser?.slug }),
      },
    },
  );

  const searchResults = response.data;
  if (searchResults && searchResults.length > 0) return searchResults.map((d) => d.hit);

  return [];
};

export const queryHelpDocCommands = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
  abortController?: AbortController,
): Promise<ICommandType[]> =>
  (await _queryHelpDocs(orgId, query, endUser, abortController)).map((hit) => ({
    ...hit.command,

    // Use the returned `content` if it exists, because it will have <mark>
    // tags to highlight the matches in the body of the help doc.
    ...(hit.content && { content: hit.content }),
  }));

export const queryHelpDocs = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
): Promise<HelpHubDoc[]> =>
  (
    await _queryHelpDocs(orgId, query, endUser).catch((err) => {
      Logger.warn(`Search error: ${err}`);
      return [];
    })
  ).map((hit) => commandToHelpHubDoc(hit.command, hit));

export const NO_ANSWER = {
  answer: '__no_answer__',
  command_id: null,
  command_title: '',
  passage_id: null,
  message_id: undefined,
};

/** For enabling getting a response given a chat history */
export type ChatMessage =
  | { messageId: string; message: IInstantAnswerType | null; type: 'bot-incomplete' }
  | { message: IInstantAnswerType | null; type: 'bot' }
  | { message: string; type: 'user' };

export const convertInternalChatHistoryToExternal = (history: ChatMessage[] | undefined): ChatHistory => {
  const chat_history: ChatHistory = [];

  if (history) {
    for (let i = 0; i < history.length; i++) {
      const entry = history[i];

      if (i === 0 && entry.type !== 'user') {
        continue;
      }
      if (entry.type === 'user') {
        chat_history.push({
          question: entry.message,
          answer: null,
        });
      } else {
        chat_history[chat_history.length - 1].answer = {
          message: entry.message?.answer || '',
          message_id: entry.message?.message_id,
        };
      }
    }
  }

  return chat_history;
};

enum ErrorType {
  Throttled = 'throttled',
  ModelError = 'model_error',
  Aborted = 'aborted',
  Unknwon = 'unknown',
}

export const fetchAIChatAnswer = async (
  organization: IOrganizationType,
  query: string,
  chatID?: string,
  history?: ChatMessage[],
  command_ids?: number[],
  abortController?: AbortController,
): Promise<IAIAnswerType | { error: ErrorType }> => {
  const payload: IAIAnswerPayloadType = {
    query: query,
    user: getSDK()[_user],
    chat_id: chatID,
    ...{ command_ids: command_ids },
  };

  try {
    const response = await Chat.generateAIAnswer(organization.id, payload, { signal: abortController?.signal });
    return response;
  } catch (err: any) {
    if (abortController?.signal.aborted) return { error: ErrorType.Aborted };

    Logger.warn(`AI chat response error: ${err}`);

    if (!!err.detail && err.detail === 'Error getting response from model') {
      return { error: ErrorType.ModelError };
    }
    if (!!err.detail && err.detail.startsWith('Request was throttled')) {
      return { error: ErrorType.Throttled };
    }

    return { error: ErrorType.Unknwon };
  }
};

/** For enabling getting a list of continuations given an answer */
export const fetchContinuations = async (
  organization: IOrganizationType,
  chatID: string,
  history: ChatMessage[],
  passage_ids?: number[],
  command_ids?: number[],
): Promise<IContinuationsType | null> => {
  const payload: IContinuationsPayloadType = {
    user: getSDK()[_user],
    chat_id: chatID,
    ...{ passage_ids: passage_ids },
    ...{ command_ids: command_ids },
  };

  try {
    const response = await Chat.listContinuations(organization.id, payload);
    return response;
  } catch (err) {
    Logger.warn(`Live answer error: ${err}`);
  }

  return null;
};

const extractPageInfo = (): {
  title: string;
  description: string | null;
  h1s: string[];
  h2s: string[];
  h3s: string[];
} => {
  const title = document.title;
  const metaDescription = document.querySelector('meta[name="description"]');
  const description = metaDescription && metaDescription instanceof HTMLMetaElement ? metaDescription.content : null;
  const h1s = Array.from(document.querySelectorAll('h1'))
    .map((h1) => h1.textContent)
    .filter((t): t is string => !!t);
  const h2s = Array.from(document.querySelectorAll('h2'))
    .map((h2) => h2.textContent)
    .filter((t): t is string => !!t);
  const h3s = Array.from(document.querySelectorAll('h3'))
    .map((h3) => h3.textContent)
    .filter((t): t is string => !!t);
  return {
    title,
    description,
    h1s,
    h2s,
    h3s,
  };
};

/** For enabling getting a list of search suggestions in the empty state */
export const fetchSearchSuggestions = async (organizationId: string): Promise<IQuestionSuggestionsType | null> => {
  const payload: IQuestionSuggestionsPayloadType = {
    ...(isStandaloneEditor ? {} : { page_context: extractPageInfo() }),
    user: getSDK()[_user],
  };

  try {
    const response = await Chat.generateQuestionSuggestions(organizationId, payload);
    return response;
  } catch (err) {
    Logger.warn(`Live answer error: ${err}`);
  }
  return null;
};
