import React from 'react';
import { _updateEditorRoute } from '@commandbar/internal/client/symbols';
import { TUpdateEditorRouteDetails } from '@commandbar/internal/util/dispatchCustomEvent';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { isEditorOpen } from '../store/util/editorUtils';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',

    width: '100%',
    height: '24px',

    background: '#fffcf2',
    borderTop: '1px solid #f0ede3',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',

    color: '#413303',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    width: '8px',
    height: '8px',
    marginRight: '4px',
    background: '#edbe19',
    border: '1px solid #b18f15',
    borderRadius: '50%',
    boxShadow: '0px 0px 4px #edbe19',
  },
  underlinedText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export const DraftFooter = ({ details }: { details: TUpdateEditorRouteDetails }) => {
  const onEditClick = () => {
    // @ts-expect-error private SDK method
    window.CommandBar[_updateEditorRoute](details);

    if (!isEditorOpen()) {
      window.CommandBar.openEditor();
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.indicator} />
        <div>Draft (only visible to you)</div>
      </div>
      {!isStandaloneEditor && (
        <div
          tabIndex={-1}
          style={styles.underlinedText}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEditClick();
            }
          }}
          onClick={onEditClick}
        >
          Edit↗
        </div>
      )}
    </div>
  );
};
