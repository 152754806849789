import LocalStorage from '@commandbar/internal/util/LocalStorage';

const translations: { [key: string]: any } = {
  'Choose a command to perform on this resource..': { spanish: 'Elija un comando para realizar en este recurso' },
  'Type text and press enter': { spanish: 'Escribe y presiona enter' },
  'Hit enter to select. Hit tab to continue.': {
    spanish: 'Presiona enter para seleccionar. Presiona tab para continuar.',
  },
  'What do you want to do?': { spanish: '¿Que quieres encontrar o hacer?' },
  'Detail (optional)': { spanish: 'Detalles' },
  'Enter test mode': { spanish: 'Entre el modo de examinar' },
  'Exit test mode': { spanish: 'Exita al modo de examinar' },
  'Type Ctrl+K to open and close': { spanish: 'Usa Ctrl+K para abrir y cerar' },
  'Suggest a new option': { spanish: 'Sugiera a un comando' },
  "Can't find what you're looking for?": { spanish: '¿No puedes encontrar lo que buscas?' },
  'Thanks for the suggestion!': { spanish: 'Gracias por la sugerencia' },
  Submit: { spanish: 'Enviar' },
  'to start and jump': { spanish: 'para empezar y saltar' },
  'to close': { spanish: 'cerar' },
  'Powered by CommandBar': { spanish: 'Desarrollado por CommandBar' },
  Title: { spanish: 'Titulo' },
  Cancel: { spanish: 'Cancelar' },
};

// A short-term solution
export const translate = (text: string) => {
  const isSpanish = LocalStorage.get('language', '') === 'spanish';
  if (isSpanish && text in translations) return translations[text].spanish;
  return text;
};
