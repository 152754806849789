/**
 * Use this file as the source for helper functions for the ExecutionPathReducer
 *
 * Generally, each function here should take in ExecutionPathState as a parameter
 *
 * Example:
 *   ExecutionPath.currentStep() calculates the active step in the execution path state
 */

import { Step, SelectStep, MultiSelectStep, DashboardStep } from './step';
import { ResourceOption } from './option';
import Available from './Available';

import { ICommandType, isTimeArgument, isContextArgument } from '@commandbar/internal/middleware/types';
import { IBlock, BLOCK_TYPE } from '../components/select/input/Block';
import ClientSearch from './ClientSearch';
import { EngineState } from '../store/engine/state';
import { getStepSelection, fulfillStep, getStepBlock } from '../store/engine/steps';
import { initBaseStep } from './step/BaseStep';
import { StepType } from './step/Step';
import Logger from '@commandbar/internal/util/Logger';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { isFinalStep } from '../store/engine/steps/dashboard-step-helpers';
import { ref } from 'valtio';
import { getSentry } from '@commandbar/internal/util/sentry';

type ExecutionPathState = EngineState['engine'];

////////////////////////////////////////////////////////////////////////
////////// Transition Functions
////////////////////////////////////////////////////////////////////////

export const fulfill = (state: ExecutionPathState): ExecutionPathState => {
  let pointer: 'start' | 'scan' | 'finish' = 'start';

  const updatedSteps: Step[] = [];
  state.steps.forEach((step: Step) => {
    /* Don't fulfill already completed steps */
    if (step.completed) {
      updatedSteps.push(step);
    } else if (pointer === 'finish') {
      /* Leave the remaining steps unfulfilled  */
      updatedSteps.push(step);
    } else if (pointer === 'scan') {
      if (step.type === 'execute') {
        /* Fulfill all sequential 'execute' steps */
        fulfillStep({ engine: state }, step);
        const completedStep = {
          ...step,
          completed: true,
        } as Step;
        updatedSteps.push(completedStep);
      } else {
        // We can pause in the Execution Path now
        pointer = 'finish';
        updatedSteps.push(step);
      }
    } else {
      /* Fulfill and start scan */
      pointer = 'scan';
      fulfillStep({ engine: state }, step);
      const completedStep = {
        ...step,
        completed: true,
      } as Step;
      updatedSteps.push(completedStep);
    }
  });

  state.steps = updatedSteps;
  return state;
};

export const rebase = (state: ExecutionPathState): ExecutionPathState => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(state);

  if (currentStep === undefined) {
    // HACK: prevent closing the bar on command exec when using standalone editor
    if (!isStandaloneEditor) {
      state.visible = false;
    }
    state.steps = [initBaseStep(null)];
    state.initialOptions = ref(Available.available(state));
    return state;
  } else {
    state.visible = true;
    state.initialOptions = ref(Available.available(state));
    return state;
  }
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const currentStepAndIndex = (
  state: ExecutionPathState,
): { currentStep: Step | undefined; currentStepIndex: number } => {
  if (state.steps.length === 0) {
    return { currentStep: undefined, currentStepIndex: -1 };
  }

  const currentStepIndex = state.steps.findIndex((step: Step) => {
    return !step.completed;
  });

  return { currentStep: state.steps[currentStepIndex], currentStepIndex };
};

const lastStep = (state: ExecutionPathState) => {
  return state.steps[state.steps.length - 1];
};

const blocks = (state: ExecutionPathState): IBlock[] => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(state);

  // @ts-expect-error: This error is wrong, we do filter out undefined
  const ret: IBlock[] = state.steps
    .map((step: Step, index: number) => {
      if (isFinalStep(step, state.steps, index)) {
        return undefined;
      }

      const text = getStepBlock(step, state);
      if (text === undefined) return undefined;
      let type = BLOCK_TYPE.SELECTED;
      if (index === currentStepIndex - 1 || currentStep?.type === StepType.Base) {
        type = BLOCK_TYPE.LAST_SELECTED;
      } else if (index === currentStepIndex) {
        type = BLOCK_TYPE.CURRENT_ARGUMENT;
      } else if (index > currentStepIndex) {
        type = BLOCK_TYPE.PLACEHOLDER;
      }

      return { text, type };
    })
    .filter((block) => !!block);

  return ret;
};

const selections = (state: ExecutionPathState): { [argName: string]: unknown } => {
  let obj = {};
  state.steps.forEach((step: Step) => {
    if (
      step.type === StepType.Select ||
      step.type === StepType.MultiSelect ||
      step.type === StepType.TextInput ||
      step.type === StepType.LongTextInput
    ) {
      // @FIXME This doesn't account for multiple steps referencing the same argument name

      obj = {
        ...obj,
        ...getStepSelection(step),
      };
    }
  });

  return obj;
};

// Returns the number of unchosen user input steps
const unchosen = (state: ExecutionPathState): number => {
  const { currentStepIndex } = currentStepAndIndex(state);

  let count = 0;
  state.steps.forEach((step: Step, index: number) => {
    if (currentStepIndex < index) {
      if (
        step.type === StepType.Select ||
        step.type === StepType.MultiSelect ||
        step.type === StepType.TextInput ||
        step.type === StepType.LongTextInput
      ) {
        if (step.selected !== null) {
          count = count + 1;
        }
      }
    }
  });

  return count;
};

const activeCommand = (state: ExecutionPathState): ICommandType | undefined => {
  if (state.steps.length === 0) return undefined;
  const firstStep = state.steps[0];
  if (firstStep.type === StepType.Base) return firstStep?.selected?.data;
  return undefined;
};

const activeRecord = (state: ExecutionPathState): ResourceOption | null => {
  if (state.steps.length === 0) return null;

  for (const s of state.steps) {
    if (s.type === StepType.Base && s.resource) {
      return s.resource;
    }
  }
  return null;
};

export const isSelectStep = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return currentStep?.type === StepType.Select || currentStep?.type === StepType.MultiSelect;
};

export const isDashboardStep = (currentStep: Step | undefined): currentStep is DashboardStep => {
  return currentStep?.type === StepType.Dashboard;
};

export const isTimeStep = (currentStep: SelectStep | MultiSelectStep): boolean => {
  return isSelectStep(currentStep) && isTimeArgument(currentStep.argument);
};

// NOTE: The return type must not be `currentStep is SelectStep | MultiSelectStep`.
export const isContextFunctionStep = (
  currentStep: Step | undefined,
  executionPathState: ExecutionPathState,
): boolean => {
  if (isSelectStep(currentStep) && isContextArgument(currentStep.argument)) {
    return !!Object.keys(executionPathState.callbacks).find(
      (callbackKey: string) => callbackKey === `commandbar-initialvalue-${currentStep.argument.value}`,
    );
  }

  return false;
};

/***************************************** Client Search checks *************************************/
export const isArgumentClientSearchActive = (executionPathState: ExecutionPathState) => {
  return !!getActiveArgumentClientSearchKey(executionPathState);
};

export const getActiveArgumentClientSearchKey = (executionPathState: ExecutionPathState) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
  const activeContextKey =
    isSelectStep(currentStep) && isContextArgument(currentStep.argument) ? currentStep.argument.value : undefined;

  if (activeContextKey && ClientSearch.isDefined(activeContextKey, executionPathState)) {
    return activeContextKey;
  }
  return undefined;
};

// Error handler for callbacks attached to commands
export const runAndReportFailure = (f: (arg1: any, arg2: any) => any, arg1: any, arg2: any, command: ICommandType) => {
  try {
    return f(arg1, arg2);
  } catch (e) {
    getSentry()?.captureException(e, {
      captureContext: {
        contexts: {
          command,
        },
      },
    });

    // REVIEW: This might cause a duplicate error report?
    throw e;
  }
};

export const runRequestCommandAndReportFailure = async (
  request: any,
  command: ICommandType,
  onSuccess?: any,
  onError?: any,
) => {
  try {
    const response = await request();

    if (response.ok) {
      if (onSuccess) {
        onSuccess(response);
      }
    }

    if (!response.ok) {
      if (onError) {
        onError(response);
      }

      Logger.error(
        JSON.stringify({
          statusCode: response.status,
          command,
        }),
      );
    }
  } catch (e) {
    getSentry()?.captureException(e, {
      captureContext: {
        contexts: {
          command,
        },
      },
    });
  }
};

// Never use eval()!
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
export const dangerouslyRunJS = (script: string, _args: any, _context: any) => {
  const executable = '"use strict";return ( function(args, context){' + script + '})';
  // eslint-disable-next-line no-new-func
  return Function(executable)()(_args, _context);
};

const ExecutionPath = {
  currentStepAndIndex,
  lastStep,
  blocks,
  selections,
  unchosen,
  activeCommand,
  activeRecord,
};

export default ExecutionPath;
