import { SDKConfig } from '@commandbar/internal/client/SDKConfig';

import _get from 'lodash/get';
import _set from 'lodash/set';
import { FormFactor, MetaAttributes } from '@commandbar/internal/client/CommandBarClientSDK';
import { ICommandType } from '@commandbar/internal/middleware/types';
import {
  ChecklistEvent,
  ChecklistItemEvent,
  EngagementType,
  EventCommandDetails,
  NudgeEvent,
  PreviewEvent,
  HelpHubEvent,
  HelpHubDocEvent,
  EventType,
  SurveyResponseEvent,
  ChatHistory,
} from '@commandbar/internal/client/EventHandler';
import { commandDefault as commandDefaultIcon } from '@commandbar/internal/client/Icon';

export enum EVENT_TYPE {
  Track = 't',
  Identify = 'i',
  Log = 'l',
  Availability = 'a',
}

export type EVENT_CATEGORY = 'user' | 'internal' | 'unknown';

export const eventAttributes = [
  'command',
  'commandText',
  'inputText',
  'inputText[*]',
  'length',
  'message',
  'record',
  'resource',
  'session',
  'url',
  'placeholder',
  'selections',
  'shortcut',
  'text',
  'user_attributes',
] as const;

export interface IEventAttributes {
  type: EventType;
  isAdmin: boolean;
  command: string | number;
  commands: number[];
  commandText: string;
  nudge: NudgeEvent['nudge'];
  questlist: ChecklistEvent['questlist'];
  questlist_item: ChecklistItemEvent['questlist_item'];
  preview: PreviewEvent['preview'];
  helpHub: HelpHubEvent['helpHub'];
  helpHubDoc: HelpHubDocEvent['helpHubDoc'];
  engagement_type: EngagementType;
  category: string | number | null;
  categoryText: string | null;
  source: string;
  inputText: string;
  ['inputText[*]']: string;
  length: number;
  message: string;
  description: string;
  record: string;
  resource: string;
  session: string;
  placeholder: string;
  selections: Record<string, any>;
  shortcut: string[] | boolean;
  text: string;
  url: string;
  user_attributes: string;
  user_event: boolean;
  formFactor: FormFactor['type'];
  engagementType: string;
  response: SurveyResponseEvent['response'];
  chatHistory: ChatHistory;
}

export type EventAttributeKey = keyof IEventAttributes;

export interface IEventPayload {
  context: {
    page: {
      path?: string;
      title?: string;
      url?: string;
      search?: string;
    };
    meta: MetaAttributes;
    userAgent?: string;
    groupId?: string;
    cbSource: SDKConfig;
  };
  userType: 'admin' | 'likely-admin' | 'end_user';
  type: EVENT_TYPE;
  attrs: Partial<IEventAttributes>;
  name?: string;
  id: string | null | undefined;
  session: string;
  search?: string;
  reportToSegment: boolean;
  fingerprint?: string;
  clientEventTimestamp: string;
  clientFlushedTimestamp?: string;
}

export type DenyListCategory = 'commands' | 'records' | 'urls' | 'user_inputs_and_deadends' | 'help_hub_search';

interface ICATEGORY_DENY_LIST {
  [k: string]: any;
}

/**
  Maps categories in the block list to paths in the event payload to be sanitized
 */
export const CATEGORY_DENY_LIST: ICATEGORY_DENY_LIST = {
  commands: ['attrs.command', 'attrs.commandText'],
  help_hub_search: ['attrs.helpHub.query', 'attrs.helpHubDoc.query'],
  user_inputs_and_deadends: [
    'attrs.inputText',
    /*
    We need to define this path as an array of strings since lodash parses `inputText[*]`
    as string
  */
    ['attrs', 'inputText[*]'],
    'attrs.selections',
  ],
  records: ['attrs.resource', 'attrs.record'],
  urls: ['attrs.url', 'context.page', 'attrs.commandDetails.url'],
};

/**
 * Returns true if object contains circular references
 * @param obj object
 * @returns boolean
 */
const hasCircularReferences = (obj: any) => {
  try {
    JSON.stringify(obj);
    return false;
  } catch (err) {
    return true;
  }
};

const sanitizeField = (payload: any, field: any) => {
  const value = _get(payload, field);

  /**
    If value is a string or number, mask the value and return
   */
  if (typeof value === 'string' || typeof value === 'number') {
    return _set(payload, field, '*'.repeat(`${value}`.length));
  }

  if (typeof value === 'object') {
    for (const key of Object.keys(value)) {
      /**
        Check for circular references early on in recursive function calls to avoid
        infinite loops.
       */
      if (hasCircularReferences(value)) {
        return _set(payload, field, {});
      }

      payload = _set(payload, field, sanitizeField(value, key));
    }
  }

  return payload;
};

export const stripEventAttributes = (payload: IEventPayload, categories: string[]): IEventPayload => {
  let strippedPayload = structuredClone(payload);

  for (const category of categories) {
    const denyList = CATEGORY_DENY_LIST[category] || [];

    for (const field of denyList) {
      strippedPayload = sanitizeField(strippedPayload, field);
    }
  }

  return strippedPayload;
};

/**
 * Helper function to return command details depending
 * upon the type of command
 */
export const getAdditionalCommandDetails = (command: ICommandType): EventCommandDetails => {
  const commandDetails: EventCommandDetails = {
    icon: command.icon || commandDefaultIcon(command),
  };

  if (command.template.type === 'link') {
    return {
      ...commandDetails,
      url: command.template.value,
    };
  }

  return commandDetails;
};
