import { IGuideType } from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

const hasSeenNudge = (nudge: string) => {
  // return false;
  return !!LocalStorage.get(`commandbar.${nudge}`, '');
};

const showGuide = (guide: IGuideType | string) => {
  if (typeof guide === 'string') return !hasSeenNudge(guide);
  return guide.preview || !hasSeenNudge(guide.event);
};

const markGuideAsSeen = (guide: IGuideType | string) => {
  if (typeof guide === 'string') seenNudge(guide);
  else if (!guide.preview) {
    seenNudge(guide.event);
  }
};

const seenNudge = (nudge: string) => {
  if (nudge !== '') {
    LocalStorage.set(nudge, true);
    return;
  }
};

export { showGuide, markGuideAsSeen };
