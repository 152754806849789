import * as t from 'io-ts';

import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';
import { CommandAction, DismissAction, LinkAction, OpenChatAction } from './helpers/actions';
import { AudienceV } from './helpers/audience';
import { FrequencyLimitV } from './helpers/frequencyLimit';
import { PushTriggerV } from './helpers/pushTrigger';
import { RuleExpressionV } from './helpers/rules';

export const NudgeContentMarkdownBlockV = t.type({
  type: t.literal('markdown'),
  meta: t.type({ value: t.string }),
});
export const NudgeContentImageBlockV = t.type({
  type: t.literal('image'),
  meta: t.type({ src: t.string, file_name: t.string, size: t.string }),
});

export const NudgeContentVideoBlockV = t.type({
  type: t.literal('video'),
  meta: t.union([
    t.type({ type: t.literal('url'), src: t.string }),
    t.type({ type: t.literal('command'), command: t.string }),
  ]),
});

export const NudgeContentHelpDocBlockV = t.type({
  type: t.literal('help_doc_command'),
  meta: t.type({ command: t.string }),
});

export const NudgeContentButtonBlockV = t.type({
  type: t.literal('button'),
  meta: t.union([
    t.partial({
      label: t.string,
      action: t.union([
        CommandAction,
        t.type({ type: t.literal('no_action') }),
        t.type({
          type: t.literal('click'),
          value: t.string,
        }),
        LinkAction,
        OpenChatAction,
        DismissAction,
      ]),
      button_type: t.union([t.literal('primary'), t.literal('secondary')], undefined),
    }),
    t.null,
  ]),
});

export const NudgeContentSurveyTextBlockV = t.type({
  type: t.literal('survey_text'),
  meta: t.type({ prompt: t.string }),
});

export const NudgeContentSurveyRatingBlockV = t.type({
  type: t.literal('survey_rating'),
  meta: t.union([
    t.type({
      type: t.literal('emojis'),
      lower_label: t.string,
      upper_label: t.string,
      options: t.number,
      emojis: t.array(t.string),
    }),
    t.type({
      type: t.literal('numbers'),
      lower_label: t.string,
      upper_label: t.string,
      options: t.number,
    }),
    t.type({
      type: t.literal('stars'),
      lower_label: t.string,
      upper_label: t.string,
      options: t.number,
    }),
  ]),
});

export const NudgeContentBlockV = t.union([
  NudgeContentMarkdownBlockV,
  NudgeContentImageBlockV,
  NudgeContentVideoBlockV,
  NudgeContentHelpDocBlockV,
  NudgeContentButtonBlockV,
  NudgeContentSurveyTextBlockV,
  NudgeContentSurveyRatingBlockV,
]);

const NudgeStepBaseV = t.type({
  id: t.number,
  title: t.string,
  content: t.array(NudgeContentBlockV),
  is_live: t.boolean,
});

const NudgeStepAdditionalV = t.intersection(
  [
    t.type({
      form_factor: t.union([
        t.type({
          type: t.literal('modal'),
        }),
        t.type({
          type: t.literal('popover'),
          position: t.union([
            t.literal('top-left'),
            t.literal('top-right'),
            t.literal('bottom-right'),
            t.literal('bottom-left'),
            t.literal('center'),
          ]),
        }),

        t.intersection([
          t.type({
            type: t.literal('pin'),
            anchor: t.string,
          }),
          t.partial({
            is_open_by_default: t.boolean,
            offset: t.type({
              x: t.string,
              y: t.string,
            }),
          }),
        ]),
      ]),
    }),
    t.partial({
      has_survey_response: t.boolean,
    }),
  ],
  'NudgeStepAdditional',
);

const stepDefaults: t.TypeOf<typeof NudgeStepAdditionalV> = {
  form_factor: {
    type: 'popover',
    position: 'top-right',
  },
};

export const NudgeStepV = t.intersection([NudgeStepBaseV, NudgeStepAdditionalV], 'Nudge');

const NudgeAdditionalV = t.type(
  {
    template_source: t.string,
    show_step_counter: t.boolean,
    dismissible: t.boolean,
    share_page_url: t.string,
  },
  'NudgeAdditional',
);

const defaults: t.TypeOf<typeof NudgeAdditionalV> = {
  template_source: 'none',
  show_step_counter: false,
  dismissible: true,
  share_page_url: '',
};

const NudgeBaseV = t.intersection(
  [
    t.type({
      slug: t.string,
      id: t.union([t.number, t.string]),
      organization: t.string,
      show_expression: RuleExpressionV,
      trigger: PushTriggerV,
      frequency_limit: FrequencyLimitV,
      steps: t.array(NudgeStepV),
      is_live: t.boolean,
    }),
    t.partial({
      old_nudge_id: t.union([t.number, t.null]),
      archived: t.boolean,
      audience: t.union([AudienceV, t.null]),
    }),
  ],
  'NudgeBase',
);

export const NudgeV = t.intersection([NudgeBaseV, NudgeAdditionalV], 'Nudge');

export const OldNudgeBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      slug: t.string,
      content: t.string,
      show_expression: RuleExpressionV,
      trigger: t.union([
        t.type({ type: t.literal('when_conditions_pass') }),
        t.type({ type: t.literal('on_command_execution'), meta: t.type({ command: t.string }) }),
        t.type({ type: t.literal('on_event'), meta: t.type({ event: t.string }) }),
        /**
         * Note: this trigger does not have to be set for share linking to work.
         * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
         */
        t.type({ type: t.literal('when_share_link_viewed') }),
      ]),
      on_select: t.union([t.type({ type: t.literal('no_action') }), CommandAction]),
      timeout_ms: t.union([t.number, t.null]),
      frequency_limit: t.union([
        t.literal('no_limit'),
        t.literal('once_per_session'),
        t.literal('once_per_user'),
        t.literal('until_interaction'),
      ]),
    }),
    t.partial({
      title: t.string,
      html: t.string,
      cta: t.string,
      is_live: t.boolean,
      media: t.union([
        t.type({ type: t.literal('image'), meta: t.type({ src: t.string, file_name: t.string, size: t.string }) }),
        t.type({ type: t.literal('help_doc_command'), meta: t.type({ command: t.string }) }),
        t.null,
      ]),
      is_html_override: t.boolean,
      call_custom_handler: t.boolean,
    }),
  ],
  'OldNudgeBase',
);

export const OldNudgeAdditionalV = t.type(
  {
    form_factor: t.union([
      t.type({
        type: t.literal('modal'),
      }),
      t.type({
        type: t.literal('popover'),
        position: t.union([
          t.literal('top-left'),
          t.literal('top-right'),
          t.literal('bottom-right'),
          t.literal('bottom-left'),
          t.literal('center'),
        ]),
      }),
      t.type({
        type: t.literal('pin'),
        anchor: t.string,
      }),
    ]),
    template_source: t.string,
  },
  'OldNudgeAdditional',
);

export const OldNudgeV = t.intersection([OldNudgeBaseV, OldNudgeAdditionalV], 'OldNudge');

export class Nudge {
  public static decode = (data: any) => decodeThrowing(NudgeV, { ...defaults, steps: [stepDefaults], ...data });

  public static create = createObject(NudgeV, NudgeV, 'nudges');
  public static update = updateObject(NudgeV, NudgeV, 'nudges');
  public static delete = deleteObject(NudgeV, 'nudges');
  public static list = listObject(NudgeV, 'nudges');
  public static read = readObject(NudgeV, 'nudges');
  /**
   * Returns true if the nudge is new (not yet saved to the backend)
   */
  public static isNew = (nudge: t.TypeOf<typeof NudgeV>) => {
    if (typeof nudge.id === 'number') {
      return nudge.id < 0;
    }
    return false;
  };
}
