import ExecutionPath from '../engine/ExecutionPath';
import _get from 'lodash/get';
import { InternalError } from './Errors';
import { EngineState } from '../store/engine/state';
import { ResourceOption } from './option';
import { DetailPreviewObjectType, DetailPreviewType } from '@commandbar/internal/middleware/detailPreview';

/******************************************************************/
/* Constants
/******************************************************************/

// Interpolate regexps: matches inclusive of syntax, e.g., {{context.value}}
// Extract regexps: matches exclusive of syntax, e.g., value
const COMMANDBAR_VARIABLE_REGEXP_INTERPOLATE = new RegExp(/{{([^{{]+)}}+/g);
const COMMANDBAR_CONTEXT_REFERENCE_REGEXP_INTERPOLATE = new RegExp(/{{context.([^{{]+)}}+/g);

export const MAX_UNFURL_LENGTH = 5000;

export const INTERNAL_FIELD_PREFIX = '_cb';

/******************************************************************/
/******************************************************************/

/*
 * Given an object, extract object.key, if it exists.
 */
export const _getValue = (
  value: string | number | { [arg: string]: any } | undefined | any[],
  key: string | undefined,
  throwErrorIfUndefined?: boolean,
) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  switch (typeof value) {
    case 'string':
    case 'number':
      return value;
    case 'object':
      if (Array.isArray(value)) {
        return value.toString();
      }

      /* value is an object */
      if (key) {
        const toRet = _get(value, key);
        if (toRet === undefined && throwErrorIfUndefined) {
          throw new InternalError(`Cannot interpolate ${key} from context`);
        }
        return toRet;
      }

      return '';
  }
};

export const _replaceString = (str: string, re: RegExp, fn: (match: string) => string) => {
  if (str === '') {
    return [''];
  }

  const result = str.split(re);

  // Apply fn to all odd elements
  for (let i = 1, length = result.length; i < length; i += 2) {
    result[i] = fn(result[i]);
  }

  return result;
};

interface IInterpolateObjectParams {
  s: any;
  engine: EngineState['engine'];
  interpolateContext: boolean;
  interpolateArgs: boolean;
  throwErrorIfUndefined?: boolean;
  activeResource?: ResourceOption;
}

/**
 * Interpolate variables in deep inside an object.
 * @input { "contextVar": "{{context.var}}", "argVar": "{{arg.fieldName}}" }
 * @output { "contextVar": "foo", "argVar": "bar" }
 */
export const interpolateObject = (args: IInterpolateObjectParams): any => {
  const s = args.s;

  // if type of s is string, immediately interpolate the value
  if (typeof s === 'string') {
    const { engine, interpolateContext, interpolateArgs, throwErrorIfUndefined, activeResource } = args;
    return interpolate(s, engine, interpolateContext, interpolateArgs, throwErrorIfUndefined, activeResource);
  } else if (Array.isArray(s)) {
    const a = [];
    for (const value of s) {
      a.push(
        interpolateObject({
          ...args,
          s: value,
        }),
      );
    }
    return a;
  } else if (s && typeof s === 'object') {
    const o: any = {};
    for (const p of Reflect.ownKeys(s)) {
      o[p] = interpolateObject({
        ...args,
        s: s[p],
      });
    }
    return o;
  }

  return s;
};

/**
 * Interpolate variables in strings
 * @input www.google.com/{{context.var}}/{{arg.fieldName}}
 * @output www.google.com/foo/bar
 */
export const interpolate = (
  s: string,
  engine: EngineState['engine'],
  interpolateContext: boolean,
  interpolateArgs: boolean,
  throwErrorIfUndefined?: boolean,
  activeResource?: ResourceOption,
): string => {
  let toRet = s;
  // First interpolate: www.google.com/{{context.var}}
  if (interpolateContext && s.includes('{{context')) {
    toRet = _replaceString(s, COMMANDBAR_CONTEXT_REFERENCE_REGEXP_INTERPOLATE, (match: string) => {
      // Remove any number of spaces before or after |
      const [contextKey, fallback] = match.replace(/\s*\|\s*/g, '|').split('|');
      const contextValue = _getValue(
        engine.context,
        contextKey || match,
        // Throw an error only if there is no fallback
        !fallback && throwErrorIfUndefined,
      );
      // If the context value is an object return obj.value
      return _getValue(contextValue, 'value', throwErrorIfUndefined) ?? fallback;
    }).join('');
  }
  if (interpolateArgs && s.includes('{{')) {
    // Then interpolate: www.google.com/foo/{{arg.fieldName}}
    toRet = _replaceString(toRet, COMMANDBAR_VARIABLE_REGEXP_INTERPOLATE, (match: string) => {
      const arg = match.split('.')[0];
      const key = match.split('.').slice(1).join('.');
      const selections: { [argName: string]: any } = {
        ...ExecutionPath.selections(engine),
        ...(activeResource && { record: activeResource.parameter }),
      };
      if (selections.hasOwnProperty(arg)) {
        return _getValue(selections[arg], key || 'value');
      }
      return '';
    }).join('');
  }

  return toRet;
};

const interpolatePreviewItem = (
  detailPreview: string | DetailPreviewObjectType,
  engine: EngineState['engine'],
  interpolateContext: boolean,
  interpolateArgs: boolean,
): string | DetailPreviewObjectType => {
  if (typeof detailPreview === 'string') return interpolate(detailPreview, engine, interpolateContext, interpolateArgs);

  return {
    ...detailPreview,
    type: detailPreview.type,
    value: interpolate(detailPreview.value, engine, interpolateContext, interpolateArgs),
  };
};

export const interpolatePreview = (
  detailPreview: DetailPreviewType | null | undefined,
  engine: EngineState['engine'],
  interpolateContext: boolean,
  interpolateArgs: boolean,
): DetailPreviewType | null | undefined => {
  if (!detailPreview) return detailPreview;

  if (Array.isArray(detailPreview) && detailPreview.length === 0) return detailPreview;

  if (Array.isArray(detailPreview)) {
    return detailPreview
      .filter((i) => {
        return !!i;
      })
      .map((i) => {
        return interpolatePreviewItem(i, engine, interpolateContext, interpolateArgs);
      });
  }

  return interpolatePreviewItem(detailPreview, engine, interpolateContext, interpolateArgs);
};
