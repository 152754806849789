import { EngineState } from '../state';
import { ResourceOption } from '../../../engine/option';
import { StepUpdater } from '../actions';
import { initBaseStep } from '../../../engine/step/BaseStep';

export const chooseResourceOption = (
  _: EngineState,
  o: ResourceOption,
  updateSteps: StepUpdater,
): undefined | EngineState['engine'] => {
  // Note: We could choose to store the "SelectedResource" in the .selected field for this step
  // (which would be more consistent with the rest of the design)
  // Currently, we choose to store it on a custom field on BaseStep (.resource)
  // This is so that we can avoid looping through the steps when calculating availability
  // and deciding whether there is an active resource.
  // A workstream to cache various state properties during each clock-tick would solve this
  // setSelected(selectedResource) // just because
  const newStep = initBaseStep(o);

  const steps = [..._.engine.steps, newStep];

  const updatedState = {
    ..._.engine,
    steps,
  };

  if (_.engine.simulation) {
    return updatedState;
  } else {
    updateSteps(steps);
    return;
  }
};
