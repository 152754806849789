/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';
import { jsx } from '@emotion/core';

import { ICommandType, ICommandCategoryType } from '@commandbar/internal/middleware/types';
import HotkeyTag from './hotkeys/HotkeyTag';
import RightSidePane, { headerStyle, rowStyle, SearchBar } from './RightSidePane';
import { useStore } from '../hooks/useStore';
import { useAction } from '../hooks/useAction';
import { availableCommands } from '../engine/Available';
import { selectSummonHotkey } from '../store/engine/selectors';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const getHighlightedText = (text: string, highlight: string, isInputFocused: boolean) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

  if (!isInputFocused) {
    return parts.map((part: string, index: number) => (
      <span key={`part-${part}-${index}`} style={{ opacity: 1 }}>
        {part}
      </span>
    ));
  }

  return parts.map((part: string, index: number) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={`part-${part}-${index}`} style={{ opacity: 1 }}>
        {part}
      </span>
    ) : (
      <span key={`part-${part}-${index}`} style={{ opacity: 0.7 }}>
        {part}
      </span>
    ),
  );
};

const KeyboardShortcutCheatsheet = () => {
  const { engine, showKeyboardShortcutCheatsheet: visible } = useStore();
  const { categories } = engine;
  const commands: ICommandType[] = React.useMemo(() => availableCommands(engine).map((c) => c.command), [engine]);
  const summonHotkey = selectSummonHotkey(useStore());

  const setVisible = useAction((_, _visible: boolean) => {
    _.showKeyboardShortcutCheatsheet = _visible;
  });
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleKeydown = (e: any) => {
    if (e.key === 'Escape') {
      setVisible(false);
    }
  };

  React.useEffect(() => {
    // We only want to add this event listener if the pane is visible,
    // so we don't overwrite the client keydown events
    if (visible) {
      document.addEventListener('keydown', handleKeydown);
    } else {
      document.removeEventListener('keydown', handleKeydown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [visible]);

  const filteredCommands = commands.filter((command: ICommandType) => {
    if (command === undefined) {
      return false;
    }
    const search = searchTerm.toLowerCase();

    let hasShortcut = false;
    if (['mac'].includes(engine.platform)) {
      hasShortcut = command.hotkey_mac.length > 0;
    } else if (['windows', 'linux'].includes(engine.platform)) {
      hasShortcut = command.hotkey_win.length > 0;
    }

    const commandMatchesSearch = search === '' || command.text.toLowerCase().includes(search);

    const matchedCategories = categories
      .filter((category: ICommandCategoryType) => {
        return search === '' || category.name.toLowerCase().includes(search);
      })
      .map((category: ICommandCategoryType) => {
        return category.id;
      });

    const categoryMatchesSearch = command.category !== null && matchedCategories.includes(command.category);

    return command.is_live && ((hasShortcut && commandMatchesSearch) || (hasShortcut && categoryMatchesSearch));
  });

  const otherCommands = filteredCommands.filter((command: ICommandType) => {
    return command.category === null;
  });

  const commandBarRow = (
    <div key={`command-bar-shortcut`} style={rowStyle}>
      <div>{getHighlightedText('Open Command Bar', searchTerm, isInputFocused)}</div>
      <div>
        <HotkeyTag combo={summonHotkey} />
      </div>
    </div>
  );

  const showSearchBar = true;

  return (
    <RightSidePane>
      <div style={{ fontWeight: 500, fontSize: 20, display: 'flex' }}>Keyboard Shortcuts</div>
      {showSearchBar && (
        <div style={{ marginTop: 15 }}>
          <SearchBar searchTerm={searchTerm} onSearch={onSearch} onFocus={handleInputFocus} onBlur={handleInputBlur} />
        </div>
      )}
      <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
        <br />
        {commandBarRow}
        {categories.map((category: ICommandCategoryType) => {
          const thisCategoryCommands = filteredCommands.filter((command: ICommandType) => {
            return command.category === category.id;
          });
          if (thisCategoryCommands.length === 0) {
            return null;
          }

          return (
            <div key={`category-${category.id}`}>
              <div style={headerStyle}>{category.name}</div>
              {thisCategoryCommands.map((command: ICommandType) => {
                let keys = '';
                if (['mac'].includes(engine.platform)) {
                  keys = command.hotkey_mac;
                } else if (['windows', 'linux'].includes(engine.platform)) {
                  keys = command.hotkey_win;
                }

                return (
                  <div key={`category-${category.id}-command-${command.id}`} style={{ ...rowStyle }}>
                    <div>{getHighlightedText(command.text, searchTerm, isInputFocused)}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <HotkeyTag combo={keys} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        {otherCommands.length === 0 ? null : (
          <div>
            {categories.length > 0 ? <div style={headerStyle}>other</div> : <br />}
            {otherCommands.map((command: ICommandType) => {
              let keys = '';
              if (['mac'].includes(engine.platform)) {
                keys = command.hotkey_mac;
              } else if (['windows', 'linux'].includes(engine.platform)) {
                keys = command.hotkey_win;
              }

              return (
                <div key={`other-${command.id}`} style={{ ...rowStyle }}>
                  <div>{getHighlightedText(command.text, searchTerm, isInputFocused)}</div>
                  <div>
                    <HotkeyTag combo={keys} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </RightSidePane>
  );
};

export default KeyboardShortcutCheatsheet;
