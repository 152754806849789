// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const ThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.8 2H17v11h1.8c1.1201 0 1.6802 0 2.108-.218a1.9996 1.9996 0 0 0 .874-.874C22 11.4802 22 10.9201 22 9.8V5.2c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C20.4802 2 19.9201 2 18.8 2Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.0001 2v11m5-3.2V5.2c0-1.1201 0-1.6802-.218-2.108a2 2 0 0 0-.8741-.874C20.4802 2 19.9202 2 18.8001 2H8.1181c-1.4615 0-2.1923 0-2.7825.2674a3 3 0 0 0-1.2743 1.0933c-.354.5427-.4652 1.265-.6874 2.7094l-.5231 3.4c-.2931 1.9052-.4397 2.8578-.157 3.599a3 3 0 0 0 1.3202 1.5388C4.7036 15 5.6674 15 7.595 15H8.4c.5601 0 .8401 0 1.054.109a.9999.9999 0 0 1 .437.437c.1091.2139.1091.4939.1091 1.054v2.9342C10.0001 20.896 11.104 22 12.4659 22a.822.822 0 0 0 .7511-.4881l3.3608-7.5617c.1528-.344.2292-.5159.35-.642a.9998.9998 0 0 1 .3832-.249C17.4753 13 17.6635 13 18.0398 13h.7603c1.1201 0 1.6801 0 2.1079-.218a1.9998 1.9998 0 0 0 .8741-.874c.218-.4278.218-.9879.218-2.108Z"
    />
  </svg>
);
export default ThumbsDown;
