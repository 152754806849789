import { ICommandType } from '@commandbar/internal/middleware/types';
import { ResourceOption, CommandOption } from '../option';
import { EngineState } from '../../store/engine/state';
import { initParameterOption, ParameterOption } from './ParameterOption';

export interface UnfurledCommandOption extends ParameterOption {
  resource: ResourceOption;
  command: ICommandType;
  unfurled: true;
}

export const initUnfurledCommandOption = (
  _: EngineState,
  command: ICommandType,
  parent: CommandOption,
  resource: ResourceOption,
): UnfurledCommandOption => {
  const o = initParameterOption(_, parent.label, resource.parameter, resource.searchOptions) as UnfurledCommandOption;
  o.resource = resource;
  o.command = command;
  o.unfurled = true;
  o.label = `${command.text} > ${resource.label}`;
  return o;
};

// export class UnfurledCommandOption extends CommandOption {
//   /** @deprecated use engine/option/UnfurledCommandOption.isUnfurledCommandOption */
//   public static is = isUnfurledCommandOption;
//   public resource: ResourceOption;
//   public readonly _unfurled = true;
//   constructor(engine: EngineState['engine'], command: ICommandType, parent: CommandOption, resource: ResourceOption) {
//     super(engine, command, parent);
//     this.resource = resource;
//   }

//   /** @deprecated */
//   public choose = (
//     engine: EngineState['engine'],
//     updateSteps?: StepUpdater,
//     triggeredByShortcut?: boolean,
//   ): void | EngineState['engine'] => {
//     return chooseCommandOption({ engine }, this, triggeredByShortcut, updateSteps);
//   };
// }
