import { EngineState } from '../state';
import { IStepArgumentType, isContextArgument } from '@commandbar/internal/middleware/types';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import { getArgumentChoiceLabel, getContextSettings } from '../selectors';

export const convertParameterToOption = (_: EngineState, parameter: unknown, argument: IStepArgumentType) => {
  const contextSettings = getContextSettings(_.engine);
  const label = getArgumentChoiceLabel(argument, parameter, contextSettings);
  const contextSettingsForKey = isContextArgument(argument) ? contextSettings[argument.value.toString()] : undefined;
  return initParameterOption(_, label, parameter, contextSettingsForKey);
};
