import { initDashboardStep } from './../../../engine/step/DashboardStep';
import { Step, DashboardStep } from '../../../engine/step';
import { StepType } from '../../../engine/step/Step';

export const getDashboardStepBreadcrumb = (s: DashboardStep): string | undefined => {
  if (!!s.argument.label) {
    return s.argument.label;
  }

  if (s.argument.type === 'video') {
    return 'Watch Video';
  }
  if (s.argument.type === 'html') {
    return 'Read Document';
  }

  return s.argument.label || 'Watch Video';
};

export const selectDashboardStep = (s: DashboardStep): Step => {
  const newStep = initDashboardStep(s.argument);
  newStep.completed = s.completed;
  return newStep;
};

export const isFinalStep = (step: Step, steps: Step[], stepIndex: number): boolean => {
  if (step.type === StepType.Dashboard && ['__video__', '__html__'].includes(step.argument.userDefinedValue)) {
    if (stepIndex + 1 < steps.length) {
      const executeStep = steps[stepIndex + 1];
      if (executeStep.type === 'execute') {
        if (['video', 'helpdoc'].includes(executeStep.command.template.type)) {
          return true;
        }
      }
    }
  }

  return step.type === StepType.Execute;
};
