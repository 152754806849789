/******************************************************************/
/* Hook that simulates componentDidUpdate
/*
/* Works just like React.useEffect except that the callback
/* doesn't trigger upon mount
/******************************************************************/

import * as React from 'react';

const useDidUpdate = (fn: any, dependencies: any[]) => {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) {
      fn();
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line
  }, dependencies);
};

export default useDidUpdate;
