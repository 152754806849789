import { GenericOption, OptionType, initOption, OptionCategoryMetadata } from './Option';
import { DetailPreviewType, IResourceSettings } from '@commandbar/internal/middleware/types';
import get from 'lodash/get';
import deepGet from '../fuzzysort-fork/deepGet/deepGet';
import { validateOptionDisabled } from '../../store/engine/actions';
import { EngineState } from '../../store/engine/state';
import { getReservedField } from '../../store/engine/options/helpers';

export interface ParameterOption extends GenericOption<OptionType.Parameter> {
  parameter: any;
  tags: { key: string; label: string; value: string | string[] }[];
  searchOptions?: IResourceSettings;
  description: string | undefined;
  heading: string | undefined;
}

export const initParameterOption = (
  _: EngineState,
  label: string,
  parameter: any,
  searchOptions?: IResourceSettings,
  detailPreview?: DetailPreviewType | null,
): ParameterOption => {
  let value = label;
  if (typeof parameter === 'string') {
    value = parameter;
  } else if (!!parameter && parameter.hasOwnProperty('value')) {
    value = parameter?.['value'];
  }
  const o = initOption(_, OptionType.Parameter, label, value, undefined, detailPreview) as ParameterOption;
  o.parameter = parameter;
  o.tags = (searchOptions?.search_fields || [])
    .map((t) => {
      if (typeof t === 'string') {
        // "legacy" behavior

        // Split on '.'
        let result = t.split('.')[0];
        // remove snake_case
        result = result.replace('_', ' ');
        // remove camelCase
        result = result.replace(/([A-Z])/g, ' $1');

        const label = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

        return { key: t, label };
      }

      return t;
    })
    .map(({ key, label }) => ({
      key,
      label,
      // deepGet used to fetch nested objs, i.e., comments.text where comments is an array
      value: deepGet(parameter, key),
    }));

  o.searchOptions = searchOptions;
  o.description = searchOptions?.description_field && get(o.parameter, searchOptions.description_field);
  o.heading = getReservedField(o, '__heading');

  // Current behavior:
  //    ParameterOptions that ARE NOT ResourceOptions are only ever rendered without grouping
  const category: OptionCategoryMetadata = {
    label: o.groupKey ?? '',
    contextKey: '',
    limit: searchOptions?.max_options_count ?? null,
    renderAs: 'list',
  };
  o.category = category;

  validateOptionDisabled(_, o);

  return o;
};

// export class ParameterOption extends Option {
//   /** @deprecated */
//   public static is = isParameterOption;
//   public parameter: any;
//   public tags: { key: string; value: string | string[] }[];
//   public searchOptions?: IResourceSettings;
//   public description: string | undefined;
//   public breadcrumbs: string | undefined;

//   constructor(engine: EngineState['engine'], label: string, parameter: any, searchOptions?: IResourceSettings) {
//     super(engine, 'parameter', label, label);
//     this.parameter = parameter;
//     // we need to preserve both the key and the value of searchable fields, because we use the key in highlighting
//     //    search matches. E.g., contact.address = "10 main street"
//     //    When we search 10 main, we want to show "address: 10 main street", instead of just "10 main street"
//     this.tags = (searchOptions?.search_fields || []).map((t) => ({
//       key: t,
//       // deepGet used to fetch nested objs, i.e., comments.text where comments is an array
//       value: deepGet(parameter, t),
//     }));
//     this.searchOptions = searchOptions;
//     this.description = searchOptions?.description_field && _get(this.parameter, searchOptions.description_field);
//     this.breadcrumbs = this.getReservedField('__breadcrumbs');

//     validateOptionDisabled({ engine }, this);
//   }

//   /************************ Static functions ****************************/

//   /** @deprecated */
//   public static convertParameterToOption = (
//     engine: EngineState['engine'],
//     parameter: any,
//     argument: IStepArgumentType,
//   ) => {
//     return convertParameterToOption({ engine }, parameter, argument);
//   };

//   /** @deprecated */
//   public static isSelected = (option: ParameterOption, currentStep: Step) => {
//     return isParameterOptionSelected(option, currentStep);
//   };

//   /** @deprecated */
//   public isValid = (): { isValid: boolean; isValidReason: string } => {
//     // Engine state will not be accessed in this case.
//     return isOptionValid(undefined as unknown as EngineState, this);
//   };

//   /** @deprecated */
//   public isExecutable = (): { isExecutable: boolean; isExecutableReason: string } => {
//     return isOptionExecutable(this);
//   };

//   /**********************************************************************/

//   /** @deprecated */
//   public choose = (engine: EngineState['engine'], updateSteps: StepUpdater): void | EngineState['engine'] => {
//     return chooseParameterOption({ engine }, this, updateSteps);
//   };

//   /**
//    * This command's icon
//    */

//   /** @deprecated */
//   public getReservedField = (
//     field: 'category' | 'icon' | '__preserveSVG' | '__extraHTML' | '__breadcrumbs' | '__actionIcon',
//   ) => {
//     return getReservedField(this, field);
//   };

//   /** @deprecated */
//   public isDateTimeOption = () => {
//     return isDateTimeOption(this);
//   };
// }
