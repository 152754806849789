import React from 'react';

import type { State } from '../store';

import { useSnapshot } from 'valtio';
import { StoreContext } from '../store/StoreContext';

/* NOTE: 2022-09-02 JL added "as State" hack when upgrading valtio to version 1.7.0
 *
 * Better solution is to make sure that any place where we use a snapshot can
 * handle the `readonly` modifier, but this will do for now.
 *
 * see https://github.com/pmndrs/valtio/issues/327#issuecomment-1034826921
 **/
export const useStore = (sync = false) => useSnapshot(React.useContext(StoreContext), { sync }) as State;
