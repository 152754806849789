import React, { ReactNode, useMemo } from 'react';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import useVisualViewportSize from '@commandbar/internal/util/useVisualViewportSize';
import { isMobile } from '@commandbar/internal/util/operatingSystem';

const MOBILE_TOP_MARGIN_PORTRAIT = 54;
const MOBILE_TOP_MARGIN_LANDSCAPE = 8;

interface Props {
  children: ReactNode;
  controlWrapperHeight: number | undefined;
}

export const HeightAdjustableContainer = ({ children, controlWrapperHeight = 0 }: Props) => {
  const { theme }: { theme: ITheme } = useTheme();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const { height: visualViewportHeight } = useVisualViewportSize();

  const height = useMemo(() => {
    if (isMobile()) {
      const deviceHeight = Math.max(windowHeight, visualViewportHeight);
      const mobileTopMargin = deviceHeight > windowWidth ? MOBILE_TOP_MARGIN_PORTRAIT : MOBILE_TOP_MARGIN_LANDSCAPE;
      const finalTopMargin = window._cbIsWebView ? 0 : mobileTopMargin;

      return `${deviceHeight - controlWrapperHeight - finalTopMargin}px`;
    }

    return 'auto';
  }, [windowHeight, windowWidth, controlWrapperHeight, visualViewportHeight]);

  return (
    <div
      className="commandbar-content-container"
      style={{
        height,
        overflow: 'hidden',
        border: theme.menu.border,
        borderRadius: theme.menu.borderRadius,
        marginTop: theme.menu.marginTop,
        display: 'flex',
        flexDirection: 'column',
        background: theme.menu.background,
        transition: isMobile() ? 'height .2s ease-in' : 'unset',
      }}
    >
      {children}
    </div>
  );
};
