import { ICommandType, IHelpDocHitType } from '@commandbar/internal/middleware/types';
import { HelpHubDoc } from '../store/engine';
import { commandToHelpHubDoc } from '../store/engine/help-hub/helpers';
import { HelpDocsSearch } from '@commandbar/internal/middleware/helpDocsSearch';

class HelpdocService {
  // TODO: add caching here --or-- modify the search/helpexplorer endpoint to support browser-based caching
  async getHelpdocHit(orgId: string | number, commandId: string | number): Promise<IHelpDocHitType | null> {
    const response = await HelpDocsSearch.search(orgId, {
      doc_id: commandId,
    });

    const searchResults = response.data;

    if (searchResults && searchResults.length > 0) {
      return searchResults[0].hit;
    }

    return null;
  }

  async getHelpdocCommand(orgId: string | number, commandId: string | number): Promise<ICommandType | null> {
    const hit = await this.getHelpdocHit(orgId, commandId);

    if (hit) {
      return hit.command;
    }

    return null;
  }

  async getHelpdoc(orgId: string | number, commandId: string | number): Promise<HelpHubDoc | null> {
    const hit = await this.getHelpdocHit(orgId, commandId);

    if (hit) {
      return commandToHelpHubDoc(hit.command, hit);
    }

    return null;
  }
}

const helpdocService = new HelpdocService();
export default helpdocService;
