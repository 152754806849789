import * as t from 'io-ts';
import {
  CallbackTemplateV,
  LinkTemplateV,
  WebhookTemplateV,
  ClickTemplateV,
  AppcuesTemplateV,
  RequestTemplateV,
  HelpDocTemplateV,
} from './helpers/commandTemplate';
import { ArgumentMapV } from './helpers/argument';
import { AvailabilityRuleV, RecommendationRuleV, RuleExpressionV } from './helpers/rules';
import { ConfettiConfigV } from './confetti';
import { DetailPreviewObjectV } from './detailPreview';

// Potential changes here

// Commands that can be passed programmatically from client to Command Bar
export const CommandFromClientV = t.intersection(
  [
    t.type({
      name: t.string,
      template: t.union([
        CallbackTemplateV,
        LinkTemplateV,
        WebhookTemplateV,
        ClickTemplateV,
        RequestTemplateV,
        AppcuesTemplateV,
        HelpDocTemplateV,
      ]),
      text: t.string,
    }),
    t.partial({
      shortcut_mac: t.array(t.string),
      shortcut_win: t.array(t.string),
      hotkey_mac: t.string,
      hotkey_win: t.string,
      tags: t.array(t.string),
      explanation: t.string,
      heading: t.string,
      sort_key: t.union([t.number, t.null]),
      arguments: ArgumentMapV,
      category: t.union([t.number, t.string, t.null]),
      icon: t.union([t.string, t.null]),
      icon_color: t.union([t.string, t.null]),
      image_color: t.union([t.string, t.null]),
      image: t.union([t.string, t.null]),
      celebrate: t.union([t.boolean, ConfettiConfigV, t.null]),
      availability_rules: t.array(AvailabilityRuleV), // experimental feature
      recommend_rules: t.array(RecommendationRuleV), // experimental feature
      availability_expression: t.array(RuleExpressionV), // experimental feature
      recommend_expression: t.array(RuleExpressionV), // experimental feature
      detail: t.union([t.string, DetailPreviewObjectV, t.array(DetailPreviewObjectV)]),
      content: t.union([t.string, DetailPreviewObjectV, t.array(DetailPreviewObjectV)]),
      show_preview: t.boolean,
      extra: t.union([t.string, t.null]),
    }),
  ],
  'Command',
);
