import React from 'react';
import { UnfurledCommandOption } from '../../engine/option';
import { OptionHighlight } from './OptionHighlight';

export interface Props {
  option: UnfurledCommandOption;
}

export const UnfurledCommandOptionLabel = ({ option }: Props) => {
  return (
    <span>
      <OptionHighlight option={option} labelToHighlight="label" text={option.label} />
      {option.resource && <span style={{ margin: '0px 5px' }}>{'>'}</span>}
      <span style={{ opacity: 0.6 }}>
        {option.resource && (
          <OptionHighlight option={option} labelToHighlight="resource.label" text={option.resource.label} />
        )}
      </span>
    </span>
  );
};
