import { subscribe } from 'valtio';
import { Ops, hasMatchingOp } from './hasOp';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

/**
 * A utility function for subscribing to state changes in a valtio proxy. Subscriber functions are provided a live
 * reference (not a snapshot) of the state object and an `Ops` array describing the change that triggered the callback.
 * Like React's useEffect hook, it takes a dependency array that specifies object paths to watch for changes. A wildcard
 * (`'*'`) may be used to match anything at a specific path, or `'*'` instead of an array will match all state changes.
 *
 * Subscribing to a specific array index is possible by converting the array index to a string, like so:
 * ```typescript
 * sub(state, handleFirstItemChange, [['items', '0']]);
 * ```
 *
 * It could be more performant to use subscribe or subscribeKey if rapid state changes are expected (profile it!).
 *
 * @param state The root of the state tree, which must be a valtio proxy. This object is passed on to the subscriber.
 * @param action The subscriber function that is called when any of the listed dependencies change.
 * @param dependencies Either an array of object paths (string arrays) to watch or the literal `'*'` to watch every
 * change.
 * @param async Set this to false to ensure that the subscription runs synchronously. Otherwise the subscription
 * function will not fire until the next event loop. For performance reasons it is preferable to leave this set.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const sub = <T extends {}>(
  state: T,
  action: (state: T, ops: Ops) => void,
  dependencies: (string | symbol)[][] | '*',
  async = true,
): VoidFunction => {
  const log = LocalStorage.get('logChanges', false);
  return subscribe(
    state,
    (ops) => {
      if (hasMatchingOp(dependencies, ops)) {
        if (log) console.log('subscription triggered: ', action.name, ops);
        action(state, ops);
      }
    },
    !async,
  );
};
