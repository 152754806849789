export const getQueryStringType = (x: string): 'css' | 'xpath' | 'pause' | 'blur' => {
  if (x.startsWith('pause')) {
    return 'pause';
  } else if (x.startsWith('blur')) {
    return 'blur';
  } else if (x.substring(0, 2) === '//') {
    return 'xpath';
  } else {
    return 'css';
  }
};

// https://stackoverflow.com/a/14284815/1569490
export const loadFromXPath = (x: string, doc: Document | null = null, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;

  if (!doc) doc = document;

  try {
    const xPathRes = doc.evaluate(x, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    if (xPathRes.singleNodeValue) {
      return xPathRes.singleNodeValue as HTMLElement;
    } else {
      return null;
    }
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

export const loadFromSelector = (x: string, doc: Document | null = null, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;

  if (!doc) doc = document;

  try {
    const elem = doc.querySelector(x);
    return elem;
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

export const checkSelector = (selector: string): boolean => {
  const type = getQueryStringType(selector);
  let element: HTMLElement | null;
  if (type === 'xpath') {
    element = loadFromXPath(selector) as HTMLElement | null;
  } else {
    element = loadFromSelector(selector) as HTMLElement | null;
  }

  return !!element;
};

export function clickElementsWithPause(index: number, selectors: string[], logError?: (x: string) => void) {
  if (index >= selectors.length) {
    return;
  }

  let pause = 150;
  let found = true;
  let blur = false;

  const type = getQueryStringType(selectors[index]);

  let element;
  if (type === 'pause') {
    pause = parseInt(selectors[index].replace(/^pause/, '')) ?? 150;
  } else if (type === 'blur') {
    blur = true;
  } else if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selectors[index]) as HTMLElement | SVGElement | null;
    if (!element) found = false;
  } else if (type === 'css') {
    element = loadFromSelector(selectors[index]) as HTMLElement | SVGElement | null;
    if (!element) found = false;
  }

  if (!found) {
    if (logError) {
      logError(selectors[index]);
    }
    return;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }
  if (blur) {
    // Blurring syntax: `blur element`
    const selector = selectors[index].replace(/^blur /, '');
    const _type = getQueryStringType(selector);
    let count = 0;

    const intervalID = setInterval(() => {
      count = count + 1;
      let el;
      if (_type === 'xpath') {
        el = loadFromXPath(selector);
      } else {
        el = loadFromSelector(selector);
      }
      if (el === document?.activeElement) {
        (el as HTMLElement).blur();
        clearInterval(intervalID);
      }

      // Poll for a maximum of 2 seconds (10 ms interval x 200 iterations)
      if (count > 200) {
        clearInterval(intervalID);
      }
    }, 10);
  }
  setTimeout(() => {
    clickElementsWithPause(index + 1, selectors, logError);
  }, pause);
}

/******************************************************************/

export function clickElement(selector: string, logError?: (x: string) => void) {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector) as HTMLElement | SVGElement | null;
  } else if (type === 'css') {
    element = loadFromSelector(selector) as HTMLElement | SVGElement | null;
  }

  if (!element) {
    if (logError) {
      logError(selector);
    }
    return;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }
}

/******************************************************************/

export const getElement = (selector: string, doc?: Document): HTMLElement | undefined => {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector, doc) as HTMLElement | undefined;
  } else if (type === 'css') {
    element = loadFromSelector(selector, doc) as HTMLElement | undefined;
  }

  return element;
};
