/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo } from 'react';

import MenuList from './MenuList';
import { useStore } from '../../../hooks/useStore';
import ExecutionPath from '../../../engine/ExecutionPath';
import { StepType } from '../../../engine/step/Step';
import { ITheme } from '@commandbar/internal/client/theme';

import { useTheme } from 'emotion-theming';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import CustomDefaultState from '../../DefaultState';
import DetailPreviewContainer from '../../detailpreview/DetailPreviewContainer';
import { getDetailPreviewContent } from '../../../store/app';
import { OptionGroup } from '../../../engine/OptionGroup';
import { Option } from '../../../engine/option';

interface IProps {
  onOptionHover: (optIndex: number) => void;
}

const MenuWrapper = (props: IProps) => {
  const _ = useStore();
  const { engine, showLoadingIndicator, userDefinedCustomComponents } = _;
  const { categories, inputText } = engine;
  const { expandedGroupKeys, focusedIndex, sortedOptions } = engine;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const menuWrapperRef = React.useRef<HTMLDivElement>(null);

  const { theme }: { theme: ITheme } = useTheme();

  const displayCustomDefaultState = useMemo(
    () => currentStep?.type === 'base' && userDefinedCustomComponents.defaultState() && !inputText.length,
    [currentStep?.type, userDefinedCustomComponents.defaultState, inputText],
  );

  const focusedOption = sortedOptions[focusedIndex] as OptionGroup | Option | undefined;

  if (currentStep?.type === StepType.LongTextInput) {
    // early return here so that no menu list is shown for LongTextInputSteps
    return null;
  }

  const detailPreviewContent = !!focusedOption ? getDetailPreviewContent(_, focusedOption) : null;

  return (
    <div
      id="commandbar-menu__wrapper"
      style={{
        minHeight: theme.bar.menuMinHeight,
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
        position: 'relative',
        overflowX: 'hidden',
        ...(isMobile() && { flex: 1 }),
        ...(!!detailPreviewContent && { height: theme.bar.menuHeight }),
      }}
      ref={menuWrapperRef}
    >
      <div style={{ height: '100%', flexGrow: 1 }}>
        {displayCustomDefaultState ? (
          <CustomDefaultState />
        ) : (
          <MenuList
            categories={categories}
            sortedOptions={sortedOptions}
            isLoading={showLoadingIndicator}
            focusedIndex={focusedIndex}
            onOptionHover={props.onOptionHover}
            expandedGroupKeys={expandedGroupKeys}
            menuWrapperRef={menuWrapperRef}
          />
        )}
      </div>
      {!isMobile() ? (
        <DetailPreviewContainer
          focusedOption={focusedOption}
          focusedIndex={focusedIndex}
          detailPreview={detailPreviewContent}
        />
      ) : null}
    </div>
  );
};

export default MenuWrapper;
