import React from 'react';
// import { useStore } from '../../hooks/useStore';

interface Props {
  value: string;
}

const ReactPreview = ({ value }: Props) => {
  // const { inputText } = useStore().engine;
  return <div>{value}</div>;
};

export default ReactPreview;
