import React, { useEffect, useLayoutEffect, useRef } from 'react';
import chroma from 'chroma-js';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { ITheme } from '@commandbar/internal/client/theme';
import ReactDOM from 'react-dom';
import Z from '@commandbar/internal/client/Z';
import { AiOutlineClose } from 'react-icons/ai';
import { useAction } from '../../hooks/useAction';
import NextStepCommandsMenu from '../NextStepCommandsMenu';
import { PreviewEngagementType } from '@commandbar/internal/client/EventHandler';
import useHasScroll from '../../hooks/useHasScroll';
import { useCommandBarContainer } from '../../hooks/useCommandBarContainer';

export const PreviewPopover = (props: {
  content: JSX.Element[];
  title?: string;
  focusedIndex: number;
  pageLink?: string;
  contentContainerRef: React.RefObject<HTMLDivElement>;
  reportPreviewEvent: (eventType: string, engagement?: PreviewEngagementType) => void;
}) => {
  const { root } = useCommandBarContainer();
  const refocusCommandbarInput = useAction((_) => _.refContainer?.current?.focus());
  const scrollRef = useRef<HTMLDivElement>(null);

  const popupContainer = React.useMemo(() => {
    return document.getElementById('commandbar') ?? null;
  }, []);

  const theme: ITheme = useCommandBarTheme();

  const [x, setX] = React.useState<number>(0);
  const [y, setY] = React.useState<number>(0);

  const [closed, setClosed] = React.useState<boolean>(false);

  const { hasScrolled } = useHasScroll(scrollRef);

  useEffect(() => {
    if (hasScrolled) {
      props.reportPreviewEvent('preview_engagement', 'scrolled');
    }
  }, [hasScrolled]);

  useEffect(() => {
    setClosed(false);
  }, [props.focusedIndex]);

  useLayoutEffect(() => {
    function updateSize() {
      if (!props.contentContainerRef.current) {
        return;
      }

      const menuElement = root?.getElementById(`commandbar-menu__wrapper`);
      if (!menuElement) {
        return;
      }

      const yMiddlePositon = menuElement.getBoundingClientRect().top;
      let xMiddlePositon = menuElement.getBoundingClientRect().left + menuElement.getBoundingClientRect().width - 8;

      if (window.innerWidth >= props.contentContainerRef.current.offsetWidth + xMiddlePositon) {
        setY(yMiddlePositon);
        setX(xMiddlePositon);
      } else {
        // try to slide into the bar
        const barWidth: number = menuElement.clientWidth;
        const MARGIN_RIGHT_WINDOW = 10;
        const slideOffset =
          props.contentContainerRef.current.offsetWidth + xMiddlePositon - window.innerWidth + MARGIN_RIGHT_WINDOW;
        const MAX_SLIDE_OFFSET = Math.max(barWidth - 175, 0);
        xMiddlePositon = xMiddlePositon - Math.min(slideOffset, MAX_SLIDE_OFFSET);
        setY(yMiddlePositon);
        setX(xMiddlePositon);
        // TURN OFF POPOVER BELOW FOR NOW
        // } else {
        //   // if we can still not show to the side, we render on the bottom
        //   const xMiddleBottomPositon =
        //     barElement.getBoundingClientRect().left +
        //     barElement.getBoundingClientRect().width / 2 -
        //     props.contentContainerRef.current.offsetWidth / 2;

        //   const focusedOptionElement = document.getElementById(`commandbar-option-${props.focusedIndex}`);
        //   if (!!focusedOptionElement) {
        //     const yMiddleBottomPositon =
        //       focusedOptionElement.getBoundingClientRect().top + focusedOptionElement.getBoundingClientRect().height;
        //     setY(yMiddleBottomPositon);
        //     setX(xMiddleBottomPositon);
        //   }
        // }
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [props.focusedIndex]);

  const floatingContainer = (
    <div
      ref={props.contentContainerRef}
      className="commandbar-popover-container"
      style={{
        position: 'fixed',
        zIndex: Z.Z_TOOLTIPS,
        minWidth: theme.previewPopover.minWidth,
        maxWidth: theme.previewPopover.maxWidth,
        maxHeight: theme.previewPopover.maxHeight,
        minHeight: theme.previewPopover.minHeight,
        color: theme.base.fontColor,
        fontFamily: theme.base.fontFamily,
        background: theme.previewPopover.background,
        boxShadow: theme.previewPopover.boxShadow,
        textAlign: 'left',
        opacity: theme.previewPopover.opacity,
        borderRadius: theme.main.borderRadius,
        fontSize: '12px',
        display: 'flex',
        top: y,
        left: x,
      }}
    >
      <NextStepCommandsMenu
        isHidden={theme.nextStepCommands.hidden === 'true'}
        offset={37}
        width="100%"
        reportPreviewEvent={props.reportPreviewEvent}
      />
      <div
        style={{
          borderBottom: theme.previewPopover.titleBarBorderBottom,
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '10px 8px 8px 20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          whiteSpace: 'nowrap',
          lineHeight: '18px',
          fontSize: '12px',
        }}
      >
        {props.title && (
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: 600,
            }}
          >
            {props.title}
          </span>
        )}
        <div
          style={{
            border: 'none',
            color: theme.base.fontColor,
          }}
        >
          {!!props.pageLink && (
            <a
              style={{
                marginLeft: '6px',
                padding: '4px 6px',
                borderRadius: '4px',
                background: chroma.mix(theme.base.background, theme.base.fontColor, 0.15).alpha(1).css(),
                fontFamily: theme.main.fontFamily,
                color: theme.base.fontColor,
                fontSize: '12px',
              }}
              href={props.pageLink}
              target="_blank"
              rel="noreferrer"
              onClick={() => props.reportPreviewEvent('preview_link_open')}
            >
              Open
            </a>
          )}

          <AiOutlineClose
            style={{
              marginLeft: '6px',
              borderRadius: '4px',
              fill: theme.base.fontColor,
              cursor: 'pointer',
              verticalAlign: 'middle',
            }}
            onClick={() => {
              setClosed(true);
              refocusCommandbarInput();
            }}
          />
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          scrollBehavior: 'smooth',
          flex: '1 1 auto',
          overflowY: 'auto',
          marginTop: '36px',
          padding: theme.previewPopover.padding,
        }}
      >
        {props.content}
      </div>
    </div>
  );

  if (!closed && popupContainer) {
    return ReactDOM.createPortal(floatingContainer, popupContainer);
  } else {
    return null;
  }
};
