import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { State } from '../..';

export const isChatOnlyMode = (engine: State['engine']): boolean => {
  // Tmp: Used by helphub.commandbar.com to showcase chatonly mode
  if (!!LocalStorage.get('chatOnlyMode', false)) {
    return true;
  }
  return Boolean(engine?.organization?.helphub_ai_enabled && engine?.organization?.helphub_chat_only_mode);
};
