import React from 'react';
import ExecutionPath from '../../engine/ExecutionPath';
import { useStore } from '../../hooks/useStore';
import RenderCustomComponent from '../RenderCustomComponent';

const CustomTabHeader = () => {
  const store = useStore();
  const { userDefinedCustomComponents, engine } = store;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

  const component =
    currentStep?.type !== 'execute' && userDefinedCustomComponents.tabHeader({ step: currentStep?.type });

  if (typeof component === 'string') {
    return (
      <div style={{ position: 'relative' }}>
        <div dangerouslySetInnerHTML={{ __html: component }} />
      </div>
    );
  } else if (component) {
    return <RenderCustomComponent component={component} />;
  }

  return null;
};

export default CustomTabHeader;
