import React, { useEffect } from 'react';

import * as Engine from '../../store/engine/actions';
import { useAction } from '../../hooks/useAction';
import { useStore } from '../../hooks/useStore';
import DraggableHelpHubModal from './DraggableHelpHubModal';
import { DEEP_LINK_PARAMS } from './Doc';
import { commandToHelpHubDoc } from '../../store/engine/help-hub/helpers';
import helpdocService from '../../services/helpdocService';
import { HelpHubDoc } from '../../store/engine';
import { queryHelpDocs } from '../../client_api/search';

export const HelpWidget = () => {
  const openHelpHub = useAction(Engine.setHelpHubVisible);
  const setParsingUrlParams = useAction((_, value: boolean) => {
    _.engine.helpHub.parsingUrlParams = value;
  });
  const { engine } = useStore();
  const { organization } = engine;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const commandID = urlParams.get(DEEP_LINK_PARAMS.COMMAND_ID);
    const query = decodeURIComponent(urlParams.get(DEEP_LINK_PARAMS.QUERY) ?? '');
    const scrollPosition = parseInt(urlParams.get(DEEP_LINK_PARAMS.SCROLL) ?? '0', 10);

    if (commandID && organization?.id) {
      setParsingUrlParams(true);

      queryHelpDocs(organization.id, query, engine?.endUser)
        .then((docs) => {
          let article: HelpHubDoc | null = null;
          const videoCommand = engine.commands.find(
            ({ id, template }) => template.type === 'video' && String(id) === String(commandID),
          );

          article =
            docs.find((doc) => String(doc.commandID) === String(commandID)) ??
            (videoCommand ? commandToHelpHubDoc(videoCommand) : null);

          return article;
        })
        .then((article) => {
          if (article) {
            return article;
          } else {
            return helpdocService.getHelpdoc(organization.id, commandID);
          }
        })
        .then((article: HelpHubDoc | null) => {
          if (article) {
            openHelpHub(true, { query, article, scrollPosition });
          } else if (query) {
            openHelpHub(true, { query });
          }
        })
        .finally(() => {
          setParsingUrlParams(false);
        });
    } else if (query) {
      openHelpHub(true, { query });
      setParsingUrlParams(false);
    }

    return () => {
      setParsingUrlParams(false);
    };
  }, [organization?.id]);

  return engine.helpHub.visible ? <DraggableHelpHubModal /> : null;
};

export default HelpWidget;
