import * as t from 'io-ts';

import { decodeThrowing } from './generics';

export const EnvironmentV = t.type({
  env: t.string,
  version: t.union([t.number, t.string, t.null, t.undefined]),
});

const defaults = {};

export class Environment {
  public static decode = (data: any) => decodeThrowing(EnvironmentV, { ...defaults, ...data });
}
