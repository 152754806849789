import 'core-js/stable';
import 'proxy-polyfill';

import { inspect } from '@xstate/inspect';

import React from 'react';
import ReactDOM from 'react-dom';

import { getProxySDK, initProxySDK } from '@commandbar/internal/client/globals';
import { _configuration, _orgConfig } from '@commandbar/internal/client/symbols';
import { deploy } from './client_api/deployment';
import { IInitOptions } from '@commandbar/internal/middleware/types';
import { CommandBarContainer, CommandBarContainerProps } from './components/CommandBarContainer';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

initProxySDK();

if (LocalStorage.get('debug:nudges', false)) {
  inspect({
    iframe: false, // open in new window
  });
}

const loadedFromStaticBundle = process.env.NODE_ENV === 'test' || !!process.env.REACT_APP_NPM_VERSION;

if (!loadedFromStaticBundle) {
  const sdk = getProxySDK();
  const uid = sdk[_configuration]?.uuid;
  const props: CommandBarContainerProps = {
    orgID: '42424242',
    userID: 'test-user',
  };

  if (!uid) {
    if (process.env.NODE_ENV === 'production') {
      // In production mode it should not be possible to have a missing org ID since the api endpoint that serves this
      // bundle is supposed to prepend a snippet that prepares the CommandBar global before this script is loaded. The
      // only sensible thing to do here is clear the value and force the app to hang, otherwise the end user will see
      // the test org which is almost certainly not desirable in production.
      console.warn('[COMMANDBAR] configuration is missing!');
      props.orgID = '';
    } else if (process.env.NODE_ENV === 'development') {
      console.info('[COMMANDBAR] init with development defaults: orgID=42424242, orgName=test-org, userID=test-user');
    }
  } else {
    props.orgID = uid;
    // The host application must call `boot()` to provide a user ID; nothing will render until that happens.
    props.userID = undefined;
  }

  // Final sanity check on the org ID: if it's falsy/undefined the application will never render and this is an
  // unrecoverable situation. This can happen if a) an injected snippet sets an invalid orgID because of a bug in the
  // API server, or b) this script is somehow run in production mode without a snippet at all.
  if (!props.orgID) {
    console.warn('[COMMANDBAR] org ID is invalid; please contact CommandBar for assistance.', props.orgID);
  }

  const container = deploy();
  ReactDOM.render(<CommandBarContainer {...props} />, container);
}

const SUPPORTED_DEPLOYMENT_OPTIONS = ['labs', 'prod'] as const;

type IInitOptionsFoobar = IInitOptions & {
  deployment?: (typeof SUPPORTED_DEPLOYMENT_OPTIONS)[number];
};

export const init = async (orgID: string, initOptions?: IInitOptionsFoobar) => {
  let providedLC = initOptions && initOptions.deployment;

  if (providedLC && !SUPPORTED_DEPLOYMENT_OPTIONS.includes(providedLC)) {
    console.warn(
      `[COMMANDBAR] Unsupported \`deployment\` option in \`init\` (supported options: ${SUPPORTED_DEPLOYMENT_OPTIONS.join(
        ', ',
      )})`,
    );
    providedLC = undefined;
  }
  const lc = localStorage.getItem('commandbar.lc') || providedLC || '';
  const forceStaticBundle = localStorage.getItem('commandbar.fsb') || '';
  const sdk = getProxySDK();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sdk[_configuration]!.environment = initOptions?.environment;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sdk[_configuration]!.version = initOptions?.version;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sdk[_configuration]!.config = initOptions?.config;

  if (!!lc && !!!forceStaticBundle) {
    console.warn(`[COMMANDBAR] Overwriting @commandbar/foobar for ${lc}`);
    const loadScript = (src: string, async = false) => {
      const el = document.createElement('script');
      el.type = 'text/javascript';
      el.async = async;
      el.src = src;
      document.head.appendChild(el);
    };

    const origin = lc.includes('local') ? 'http://localhost:8000' : 'https://api.commandbar.com';
    const endpoint = `${origin}/latest/${orgID}?lc=${lc}&version=2`;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (sdk[_configuration]) sdk[_configuration]!.uuid = orgID;

    loadScript(endpoint, true);
    return;
  }

  const container = deploy();
  const api = sdk[_configuration]?.api ?? 'https://api.commandbar.com';

  let params = '';
  try {
    if (!!process.env.REACT_APP_NPM_VERSION) {
      params = new URLSearchParams({
        version: process.env.REACT_APP_NPM_VERSION,
        environment: window.location.hostname,
      }).toString();
      params = `?${params}`;
    }
  } catch (err) {}

  const b_response: {
    silent_mode?: boolean;
    event_attribute_block_list?: string[];
    deployment_can_install_foobar?: boolean;
  } = await fetch(`${api}/organizations/${orgID}/b/${params}`)
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data;
    })
    .catch(() => {
      // allow by default in case of an error, e.g., dropped connection or offline mode
      return { silent_mode: true, event_attribute_block_list: [], deployment_can_install_foobar: true };
    });

  sdk[_orgConfig] = {
    name: undefined,
    silent: b_response.silent_mode,
    eventAttributeBlockList: b_response.event_attribute_block_list,
  };

  if (b_response.deployment_can_install_foobar === true) {
    ReactDOM.render(<CommandBarContainer orgID={orgID} />, container);
    return container;
  } else {
    console.error('[COMMANDBAR] This organization is not yet authorized. Please message the CommandBar team.');
    return;
  }
};

export default init;
