/**
 * This module facilitates Accessibility (a11y) support in the CommandBar.
 * a11y ids, roles, and labels that are used in more than one place should be defined here.
 */

import { OptionGroup } from '../engine/OptionGroup';

const A11Y_CONFIG = {
  listId: 'commandbar-menu',
  optionId: (index: number) => `commandbar-option-${index}`,
  optionContentId: (index: number) => `commandbar-option-${index}-content`,
  listLabel: 'List of options',
  dialogLabel: 'commandbar',
  listRole: 'listbox',
  inputRole: 'combobox',
  optionRole: 'option',
  groupRole: 'group',
  emptyListId: 'commandbar-empty-menu',
  commandbarInputId: 'commandbar-input-search',
  categoryId: (opt: OptionGroup) => `category-${opt.name}`,
};

export default A11Y_CONFIG;
