import { State } from '../..';
import ExecutionPath from '../../../engine/ExecutionPath';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import DateTime from '../../../engine/predefinedTypes/DateTime/DateTime';
import { MultiSelectStep, SelectStep } from '../../../engine/step';
import { StepType } from '../../../engine/step/Step';
import { selectHiddenCategories } from '../../app/selectors';
import {
  isCommandOption,
  isResourceOption,
  isUnfurledCommandOption,
  onlyShowDuringPowerselect,
  setOptionGroupKey,
} from '../options';
import { Option } from '../../../engine/option';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import { fallbackGroup, incrementOptionGroupSize } from '../../../engine/OptionGroup';

export const generateTimeOptions = (_: State, newInput: string, currentStep: SelectStep | MultiSelectStep) => {
  return DateTime.search({
    inputValue: newInput,
    type: (currentStep.argument as unknown as Record<string, number>).dateTimeArgumentTypeId,
  }).map((suggestion: any) => {
    return initParameterOption(_, suggestion.label, suggestion);
  });
};

export const filterOptionsForDefaultState = (_: State, options: Option[], currentInput: string) => {
  const categoriesToHideBeforeSearch = selectHiddenCategories(_);

  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  const isDefaultStep = currentStep?.type === StepType.Base && currentStep.resource == null;

  return options.filter((opt) => {
    if (isUnfurledCommandOption(opt) && currentInput.length === 0) {
      // Case: We are at the top-level; When the input is empty, we should not show UnfurledCommands
      return false;
    }

    if (onlyShowDuringPowerselect(opt)) {
      const isPowerselect = currentStep?.type === StepType.Base && !!currentStep.resource;
      return isPowerselect;
    }

    if (isResourceOption(opt)) {
      const alwaysShowRecord = opt.searchOptions?.showResources;
      if (alwaysShowRecord) return true;

      // Empty state (don't show resources)
      if (isDefaultStep && currentInput.length === 0) {
        // show resources when they are in recents
        // FIXME: RECENTS make this a selector isRecordRecent
        return opt.isRecent;
      }

      return true;
    }

    const hideCategory = (categoryId: number) => {
      if (isDefaultStep) {
        return categoriesToHideBeforeSearch.includes(categoryId);
      }
      return false;
    };

    if (isCommandOption(opt) && currentInput.length === 0) {
      if (!!opt.command.category && hideCategory(opt.command.category) && !opt.isRecommended) {
        return false;
      }
    }

    return true;
  });
};

export const getFallbackOptions = (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  // We only want to show fallback commands when we are in the base step
  if (currentStep?.type !== StepType.Base) {
    return [];
  }

  const { organization, inputText } = _.engine;

  if (!organization || !inputText) {
    return [];
  }

  const fallbackCommands = organization.fallback_commands;

  const fallbackOptions = _.engine.initialOptions
    .filter(isCommandOption)
    .filter((c) => fallbackCommands.indexOf(c.command.id) !== -1)
    .sort((a, b) => {
      if (a.isRecommended && b.isRecommended) {
        return compareObjs(
          {
            sort_key: a.command.recommend_sort_key,
            id: a.command.id,
          },
          {
            sort_key: b.command.recommend_sort_key,
            id: b.command.id,
          },
        );
      }

      if (a.isRecommended) {
        return -1;
      }

      if (b.isRecommended) {
        return 1;
      }

      return fallbackCommands.indexOf(a.command.id) - fallbackCommands.indexOf(b.command.id);
    });

  if (fallbackOptions.length) {
    const filteredOptions = filterOptionsForDefaultState(_, fallbackOptions, _.engine.inputText).filter(
      _.searchFilter?.fn ?? (() => true),
    );
    let groupInstance = fallbackGroup();

    filteredOptions.forEach((o, idx) => {
      filteredOptions[idx] = setOptionGroupKey(o, groupInstance.key);
      groupInstance = incrementOptionGroupSize(groupInstance);
    });

    if (filteredOptions.length === 0) {
      return [];
    }

    return [groupInstance, ...filteredOptions];
  }

  return fallbackOptions;
};
