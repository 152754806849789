import { computePosition, autoUpdate, offset, shift, hide, flip } from '@floating-ui/dom';

import type { INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';

export type TPin = {
  nudge: INudgeType;
  step: INudgeStepType;
  targetEl?: Element;
  isOpenByDefault: boolean;
  offset: { x: string; y: string };
};

export const BEACON_SIZE = 16;
const BEACON_INSET = 8;
const MIN_MARGIN = 24;

// check if string includes a number
const hasNumber = (str: string) => /\d/.test(str);

const calcOffsetFromInput = (offset: string) => (hasNumber(offset) ? parseInt(offset, 10) : 0);

type PositionPin = (
  pin: TPin,
  floatingElement: HTMLDivElement,
  contentElement: HTMLDivElement,
  isOpen: boolean,
  closePin: () => void,
) => (() => void) | void;

const positionPin: PositionPin = (pin, beaconElement, contentElement, isOpen, closePin) => {
  const targetEl = pin.targetEl;
  let beaconHidden: boolean;

  if (targetEl) {
    const updateBeaconPosition = () => {
      const contentWidth = contentElement.getBoundingClientRect().width;

      computePosition(targetEl, beaconElement, {
        placement: 'top-end',
        middleware: [
          offset({
            mainAxis: -BEACON_INSET + calcOffsetFromInput(pin.offset.y),
            alignmentAxis: -BEACON_INSET - calcOffsetFromInput(pin.offset.x),
          }),
          flip({
            mainAxis: false,
            crossAxis: true,
            padding: {
              left: contentWidth + MIN_MARGIN + BEACON_INSET,
              right: contentWidth + MIN_MARGIN + BEACON_INSET,
            },
          }),
          hide({ strategy: 'escaped' }),
        ],
      }).then(({ y, x, middlewareData }) => {
        beaconHidden = !!middlewareData.hide?.escaped;

        Object.assign(beaconElement.style, {
          top: `${y}px`,
          left: `${x}px`,
          visibility: middlewareData.hide?.escaped ? 'hidden' : 'visible',
        });
      });
    };
    const updateContentPosition = () => {
      computePosition(targetEl, contentElement, {
        placement: 'right-start',
        middleware: [
          offset({
            mainAxis: BEACON_SIZE + BEACON_INSET + calcOffsetFromInput(pin.offset.x),
            alignmentAxis: -calcOffsetFromInput(pin.offset.y),
          }),
          shift({ padding: MIN_MARGIN }),
          flip({
            mainAxis: true,
            crossAxis: true,
            padding: {
              left: MIN_MARGIN - BEACON_INSET,
              right: MIN_MARGIN - BEACON_INSET,
            },
          }),
          hide(),
        ],
      }).then(({ y, x, middlewareData }) => {
        Object.assign(contentElement.style, {
          top: `${y}px`,
          left: `${x}px`,
          visibility: middlewareData.hide?.referenceHidden || beaconHidden || !isOpen ? 'hidden' : 'visible',
        });
      });
    };

    const cleanupBeacon = autoUpdate(targetEl, beaconElement, updateBeaconPosition, { animationFrame: true });
    const cleanupContent = autoUpdate(targetEl, contentElement, updateContentPosition, { animationFrame: true });

    const cleanup = () => {
      cleanupBeacon();
      cleanupContent();
      closePin();
      observer.disconnect();
    };

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (const removedNode of mutation.removedNodes) {
            if (removedNode === targetEl || removedNode.contains(targetEl)) {
              cleanup();
            }
          }
        }
      }
    });

    observer.observe(document.documentElement, { childList: true, subtree: true });

    return () => observer.disconnect();
  }
};

export default positionPin;
