import { DetailPreviewObjectType, isContextArgument, LabeledAction } from '@commandbar/internal/middleware/types';
import { State } from '..';
import ExecutionPath, { isSelectStep } from '../../engine/ExecutionPath';
import { CommandOption, ParameterOption, ResourceOption, Option } from '../../engine/option';
import {
  isCommandOption,
  extractSlashFilter,
  getContextSettings,
  isOptionGroup,
  getOptionData,
  getOptionMetadata,
  getStepPlaceholder,
} from '../engine';
import { StepType } from '../../engine/step/Step';
import { OptionGroup } from './../../engine/OptionGroup';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import { ITheme } from '@commandbar/internal/client/theme';
import { availableCommands } from '../../engine/Available';
import { isFinalStep } from '../engine/steps/dashboard-step-helpers';
import slugify from '@commandbar/internal/util/slugify';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { interpolate } from '../../engine/Interpolate';
import { DEFAULT_PLACEHOLDER } from '../../components/select/input/placeholderHelpers';
import { isAction } from '@commandbar/internal/middleware/helpers/actions';

export const selectHiddenCategories = (_: State) =>
  _.engine.categories.filter((category) => category.setting_hide_before_search).map((category) => category.id);

export const selectMenuIsOpen = (_: State) => {
  const inlineFormFactor = _.engine.formFactor.type === 'inline';
  const menuIsClosed = _.dashboard || (inlineFormFactor && !_.engine.visible);

  return !menuIsClosed;
};

export const selectSlashFilterGroups = (_: State) => {
  if (!_.engine.organization?.slash_filters_enabled) {
    return [];
  }

  const retval: OptionGroup[] = _.engine.currentGroups.filter((group) => {
    return group.slash_filter_enabled;
  });

  return retval;
};

export const selectSlashFilterHint = (_: State, keyword?: string) => {
  const groups = selectSlashFilterGroups(_);

  const getMatch = (text: string, theme?: ITheme) => {
    if (!text || text === '') {
      return;
    }

    text = text.toLowerCase();

    const labelAllTab = !!theme ? slugify(theme.searchTab.labelAllTab) : 'all';

    if (labelAllTab.toLowerCase().startsWith(text)) {
      return labelAllTab;
    }

    const match = groups
      .filter((group: OptionGroup) => {
        return (
          !!group.slash_filter_keyword &&
          group.slash_filter_keyword.toLowerCase() !== text.toLowerCase() &&
          group.slash_filter_keyword.toLowerCase().startsWith(text.toLowerCase())
        );
      })
      .sort((a: OptionGroup, b: OptionGroup) =>
        !a.slash_filter_keyword || !b.slash_filter_keyword
          ? 0
          : a.slash_filter_keyword.length - b.slash_filter_keyword.length,
      )[0];

    if (match) {
      return match.slash_filter_keyword;
    } else {
      return;
    }
  };

  if (typeof keyword === 'undefined') {
    const { slashFilterKeyword } = selectSlashFilter(_);
    keyword = slashFilterKeyword;
  }

  const match = getMatch(keyword, _.engine.theme);
  const length = keyword.length;
  if (!match) {
    return '';
  } else {
    return match.slice(length);
  }
};

export const selectSlashFilter = (_: State) => {
  return extractSlashFilter(_.engine.rawInput);
};

export const selectIsFinalStep = (_: State) => {
  const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(_.engine);
  if (!currentStep) {
    return false;
  }

  return isFinalStep(currentStep, _.engine.steps, currentStepIndex);
};

export const getTabKey = (optionGroupKey: string | undefined) => {
  if (!optionGroupKey || !optionGroupKey.startsWith('TAB')) {
    return null;
  } else {
    const lastIndex = optionGroupKey.lastIndexOf('-');
    return optionGroupKey.slice(lastIndex + 1);
  }
};

export const selectCurrentTab = (_: State) => {
  const currentFilter = _.searchFilter;
  return _.engine.tabs.find((t) => getTabKey(currentFilter?.slug) === `${t.id}`);
};

export const showRotatingPlaceholders = (_: State) => {
  const placeholders = selectAllPlaceholders(_);
  return (
    !_.engine.rawInput &&
    (_.engine.visible || _.engine.formFactor.type === 'inline') &&
    Array.isArray(placeholders) &&
    placeholders.length > 1
  );
};

export const selectAllPlaceholders = (_: State): string | string[] => {
  if (_.showLoadingIndicator) {
    return 'Loading...';
  }

  if (!!_.searchFilter?.placeholder) {
    return interpolate(_.searchFilter.placeholder, _.engine, true, false);
  }

  /** We have a placeholder for the step */
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  const stepPlaceholder = currentStep && getStepPlaceholder(currentStep);
  if (stepPlaceholder) return stepPlaceholder;

  const userDefinedPlaceholders = _.engine.placeholders.map((p) => p.text);
  if (!userDefinedPlaceholders.length) return DEFAULT_PLACEHOLDER;
  return userDefinedPlaceholders;
};

export const selectFirstPlaceholder = (_: State) => {
  const placeholders = selectAllPlaceholders(_);
  if (Array.isArray(placeholders)) {
    return placeholders[0];
  } else {
    return placeholders;
  }
};

export const selectTabProperties = (_: State, groupKey: string) => {
  const tab = _.engine.tabs.find((t) => getTabKey(groupKey) === `${t.id}`);

  if (!tab) {
    return {};
  }

  return { icon: tab?.icon, header: tab?.header };
};

export const isGridView = (_: State) => {
  // Disable grid view for mobile web
  if (isMobile()) {
    return false;
  }

  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  return (
    (currentStep?.type === StepType.Base && _.searchFilter?.renderAs === 'grid') ||
    (isSelectStep(currentStep) &&
      isContextArgument(currentStep.argument) &&
      getContextSettings(_.engine)[currentStep.argument.value]?.render_as === 'grid')
  );
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const optionIsInGrid = (_: State, option?: CommandOption | ResourceOption | ParameterOption | OptionGroup) => {
  return isGridView(_);

  // TODO: Enable mixed grid+list tabs and use below logic to determine per option if it is shown as grid item or not
  // if (option === undefined) {
  //   option = _.engine.sortedOptions[_.engine.focusedIndex];
  // }

  // if (!option || isOptionGroup(option)) {
  //   return false;
  // }

  // const isRecent =
  //   (option.type === OptionType.Command && option.isRecent) ||
  //   (option.type === OptionType.Parameter &&
  //     (option as ResourceOption)?._resource === true &&
  //     (option as ResourceOption)?.isRecent);

  // const isRecommended = option.type === OptionType.Command && option.isRecommended;

  // if (option.category.renderAs === 'grid' && !isRecent && !isRecommended) {
  //   return true;
  // }
  // return false;
};

export const getNextStepOptions = (_: State): Array<CommandOption | LabeledAction> => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  let nextStepCommands: Array<string | number | LabeledAction> = [];

  if (currentStep?.type === StepType.Dashboard) {
    nextStepCommands = currentStep?.command?.next_steps || [];
  } else {
    const focusedOption = _.engine.sortedOptions[_.engine.focusedIndex];

    if (focusedOption && isCommandOption(focusedOption) && focusedOption.detailPreview) {
      nextStepCommands = focusedOption.command.next_steps;
    }
  }

  /** Performance: Avoid expensive call to availableCommands if nextStepCommands is empty */
  if (nextStepCommands.length === 0) {
    return [];
  }

  const nextStepOptions: Array<CommandOption | LabeledAction> = availableCommands(_.engine)
    .filter((o) => nextStepCommands.indexOf(o.command.id) !== -1)
    .sort((a, b) => {
      if (a.isRecommended && b.isRecommended) {
        return compareObjs(
          {
            sort_key: a.command.recommend_sort_key,
            id: a.command.id,
          },
          {
            sort_key: b.command.recommend_sort_key,
            id: b.command.id,
          },
        );
      }

      if (a.isRecommended) {
        return -1;
      }

      if (b.isRecommended) {
        return 1;
      }

      return nextStepCommands.indexOf(a.command.id) - nextStepCommands.indexOf(b.command.id);
    });

  const labeledActions: LabeledAction[] = nextStepCommands.filter((c) => isAction(c)) as LabeledAction[];

  return nextStepOptions.concat(labeledActions);
};

export const getDetailPreviewContent = (_: State, row: Option | OptionGroup): DetailPreviewObjectType[] | null => {
  if (isOptionGroup(row)) return null;
  if (isGridView(_)) return null; // Previews are currently incompatible with grids

  if (row.detailPreview) {
    return standardize(row.detailPreview);
  } else if (!!_.engine.detailPreviewGenerator) {
    return standardize(_.engine.detailPreviewGenerator(getOptionData(row), getOptionMetadata(row)));
  } else {
    return null;
  }
};
