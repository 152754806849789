/** @jsx jsx */
/** @jsxFrag React.Fragment */

import * as React from 'react';

import ExecutionPath, { isArgumentClientSearchActive } from '../../../engine/ExecutionPath';
import { useTheme } from 'emotion-theming';
import { InterpolationWithTheme, jsx } from '@emotion/core';
import { maybePluralize } from '@commandbar/internal/middleware/utils';
import { ITheme } from '@commandbar/internal/client/theme';
import featureFlag from '../../../util/featureFlags';
import { useStore } from '../../../hooks/useStore';
import * as App from '../../../store/app/actions';
import { useAction } from '../../../hooks/useAction';
import { StepType } from '../../../engine/step/Step';
import a11y from '../../../util/a11y';
import { DashboardType } from '../../../engine/step/DashboardStep';
import { getCommands, selectSearchTabs } from '../../../store/engine';
import RenderCustomComponent from '../../RenderCustomComponent';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { createSearchFilter } from '../SearchTabs';
import { isMobile } from '@commandbar/internal/util/operatingSystem';

const emptyMenuStyles = (theme: ITheme): InterpolationWithTheme<any> => ({
  color: theme.main.color,
  fontSize: theme.main.fontSize,
  fontFamily: theme.main.fontFamily,
  width: '100%',
});

const CustomEmptyState = () => {
  const { userDefinedCustomComponents, engine } = useStore();

  const component = userDefinedCustomComponents.emptyState({ step: StepType.Base });

  if (typeof component === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: component }} />;
  } else if (component) {
    return <RenderCustomComponent component={component} metadata={{ inputText: engine.inputText }} />;
  }

  return null;
};

const join = (items: React.ReactNode[]) => {
  if (items.length === 0) return null;
  if (items.length === 1) return items[0];
  if (items.length === 2)
    return (
      <>
        {items[0]} or {items[1]}
      </>
    );
  return (
    <>
      {items.slice(0, -1).flatMap((item, i) => [i > 0 && ', ', item])}, or {items[items.length - 1]}
    </>
  );
};

const NoResultsWithinSearchFilter = () => {
  const { theme }: { theme: ITheme } = useTheme();
  const store = useStore();

  const focusInput = useAction((_) => {
    if (!isMobile()) {
      _.refContainer?.current?.focus();
    }
  });

  const { engine, searchFilter } = store;
  const { optionCountsByTabGroup, inputText } = engine;

  const setSearchFilter = useAction(App.setSearchFilter);

  const tabGroups = selectSearchTabs(store, false);
  const tabGroupsHavingOptions = tabGroups.filter((g) => {
    const optionCount = optionCountsByTabGroup.get(g.key);
    if (optionCount === undefined) return false;

    return optionCount > 0;
  });

  const activeTabGroup = tabGroups.find((tabGroup) => tabGroup.key === searchFilter?.slug);
  const activeTabGroupName = activeTabGroup?.searchTabName || activeTabGroup?.name;

  const noResults = (() => {
    if (inputText.length > 0 && activeTabGroupName) {
      return (
        <div
          style={{
            /* NOTE: Need width 100% here because child NoResultsText component then makes the
             * "no search results for <query>" width 60% of this 100%. This makes long queries
             * look good and will use an ellipsis if the query is longer than 60% of the width. */
            width: '100%',
          }}
        >
          <NoResultsText
            query={inputText}
            explanation={
              <>
                No <strong>{activeTabGroupName}</strong> results for
              </>
            }
          />
        </div>
      );
    } else {
      return <div>{searchFilter?.emptyInputMessage ? searchFilter.emptyInputMessage : 'No results'}</div>;
    }
  })();

  const selectAnotherTab = (() => {
    if (tabGroupsHavingOptions.length === 0) return null;

    return (
      <div style={{ textAlign: 'center' }}>
        Try{' '}
        {join(
          tabGroupsHavingOptions.map((group) => {
            const onTabSelect = () => {
              setSearchFilter(createSearchFilter(group));
              focusInput();
            };

            return (
              <button
                key={group.key}
                css={{
                  background: 'none',
                  border: 'none',
                  margin: 0,
                  padding: 0,
                  textDecoration: 'underline',
                  color: theme.noResults.linkColor,
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onTabSelect();
                  }
                }}
                onClick={onTabSelect}
              >
                {group.searchTabName || group.name}
              </button>
            );
          }),
        )}{' '}
        instead?
      </div>
    );
  })();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: 4,
          alignItems: 'center',
        }}
      >
        {noResults}
        {selectAnotherTab}
      </div>
    </div>
  );
};

// FIXME: allow client to specify default way users can suggest a query
// FIXME: allow generator queries to specify optional empty message ("Student has no submission")
// For example, codePost might prefer to open up Intercom instead of email client
const EmptyMenu = () => {
  const { theme }: { theme: ITheme } = useTheme();
  const store = useStore();
  const { emptyMessage, engine, searchFilter, userDefinedCustomComponents } = store;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const setDashboard = useAction(App.setDashboard);

  const isInputEmpty = engine.inputText.length === 0;

  const numCommands = getCommands(engine).length;
  const nudgeTestMode =
    numCommands > 0 && currentStep?.type === StepType.Base && engine.isAdmin && isInputEmpty && !engine.testMode;

  const displayCustomEmptyState = React.useMemo(
    () => userDefinedCustomComponents.emptyState(),
    [userDefinedCustomComponents.emptyState],
  );

  let content;

  if (!!searchFilter && currentStep?.type === StepType.Base) {
    content = <NoResultsWithinSearchFilter />;
  } else if (nudgeTestMode) {
    if (isStandaloneEditor) {
      content = (
        <div>
          <p style={{ textAlign: 'center', padding: '20px 25px' }}>
            Use the editor to the left to add commands to fill out your bar, or to add Nudges or Questlists
          </p>
        </div>
      );
    } else {
      content = (
        <div>
          <p style={{ textAlign: 'center', padding: '20px 25px' }}>
            Toggle <b>Test mode</b> in the Editor Panel to see your unpublished or unavailable{' '}
            {maybePluralize(numCommands, 'command')}.
          </p>
        </div>
      );
    }
  } else if (isInputEmpty && currentStep?.type === StepType.Base) {
    /**
     * FIXME: (should revisit after state management changes)
     *
     * Avoid showing NoResults if no inputText has been provided.
     * This can happen if a user closes the bar with NoResults, re-opens it,
     * and availability calculation is slower than the re-render.
     */
    return <div />;
  } else if (currentStep?.type === StepType.Base) {
    content = featureFlag({
      organization: engine.organization,
      orgIds: ['5725a2a3'],
      activeState: displayCustomEmptyState ? <CustomEmptyState /> : customGustoNoResults(engine.inputText),
      defaultState: displayCustomEmptyState ? (
        <CustomEmptyState />
      ) : (
        <div>{emptyMessage ? emptyMessage : <NoResults setDashboard={setDashboard} query={engine.inputText} />}</div>
      ),
    });
  } else if (isInputEmpty && isArgumentClientSearchActive(engine)) {
    content = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginTop: 20, marginBottom: 20 }}>Type to search</div>
      </div>
    );
  } else {
    content = (
      <div role="status" style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginTop: 20, marginBottom: 20 }}>No results</div>
      </div>
    );
  }

  return (
    <div id="commandbar-menu" css={emptyMenuStyles(theme)}>
      <div id={a11y.emptyListId}>{content}</div>
    </div>
  );
};

/**
 * No Results state
 */

interface INoResultsProps {
  setDashboard: (dashboard: DashboardType) => void;
  query: string;
}

const NoResultsText = (props: { explanation?: React.ReactNode; query: string }) => {
  const { theme }: { theme: ITheme } = useTheme();

  return (
    <div
      role="status"
      style={{
        fontSize: theme.noResults.headerFontSize,
        color: theme.noResults.headerFontColor,
        fontFamily: theme.noResults.headerFontFamily,
        fontWeight: theme.noResults.headerFontWeight,
        marginTop: theme.noResults.headerMarginTop,
        marginBottom: theme.noResults.headerMarginBottom,
        textAlign: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        gridTemplateColumns: 'auto',
      }}
    >
      <div
        role="heading"
        aria-level={2}
        style={{
          width: '60%',
          margin: 'auto',
          lineHeight: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.explanation ?? theme.noResults.headerText}
        &nbsp;
        <span style={{ color: theme.noResults.queryFontColor }}>{props.query}</span>
      </div>
    </div>
  );
};

export const NoResults = (props: INoResultsProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const openSuggestCommand = () => props.setDashboard('feedback');

  return (
    <div
      id={a11y.emptyListId}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0px' }}
    >
      <NoResultsText query={props.query} />

      <div
        style={{
          textAlign: 'center',
          fontFamily: theme.noResults.bodyFontFamily,
          fontSize: theme.noResults.bodyFontSize,
          fontWeight: theme.noResults.bodyFontWeight,
        }}
      >
        <span
          role="link"
          aria-label="cant-find-what-you-are-looking-for-tell-us-here"
          tabIndex={0}
          data-testid="open-suggest-command"
          onClick={openSuggestCommand}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              openSuggestCommand();
            }
          }}
        >
          <div
            style={{
              all: 'unset',
              color: theme.noResults.bodyFontColor,
              marginRight: 5,
            }}
          >
            {theme.noResults.bodyText}
          </div>
          <div
            style={{ all: 'unset', color: theme.noResults.linkColor, cursor: 'pointer', textDecoration: 'underline' }}
          >
            {theme.noResults.linkText}
          </div>
        </span>
      </div>
    </div>
  );
};

// FIXME: Eventually move this to an sdk call like setEmptyState
const customGustoNoResults = (query: string) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '44px 0px 32px 0px',
      }}
    >
      <div
        style={{
          display: 'grid',
          textAlign: 'center',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          gridTemplateColumns: 'auto',
        }}
      >
        <div
          id={a11y.emptyListId}
          role="status"
          style={{
            marginInline: 'auto',
            fontSize: '32px',
            lineHeight: '40px',
            color: '#1C1C1C',
            fontWeight: 500,
            textAlign: 'center',
            width: '60%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {`No results for`}
          &nbsp;
          <span style={{ color: '#6C6C72' }}>{query}</span>
        </div>
      </div>
      <div style={{ fontSize: '20px', lineHeight: '28px', color: '#525257', marginTop: '24px', fontWeight: 400 }}>
        Explore other Gusto resources
      </div>
      <div
        className="resource-links-container"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '24px',
          fontSize: '16px',
          marginTop: '16px',
          fontWeight: 400,
          lineHeight: '24px',
        }}
      >
        <a
          className="help-center-link"
          href="https://support.gusto.com/?ref=cb"
          style={{ display: 'flex' }}
          target="_blank"
          rel="noreferrer"
        >
          <img src="https://staticassets.commandbar.com/icons/5725a2a3_empty_help.svg" alt="" />
          <span style={{ textDecoration: 'underline', color: '#0a8080', marginLeft: '8.5px' }}>Help Center</span>
        </a>
        <a
          className="talk-shop-link"
          href="https://gusto.com/blog?ref=cb"
          style={{ display: 'flex' }}
          target="_blank"
          rel="noreferrer"
        >
          <img src="https://staticassets.commandbar.com/icons/5725a2a3_empty_blog.svg" alt="" />
          <span style={{ textDecoration: 'underline', color: '#0a8080', marginLeft: '8.5px' }}>Talk Shop blog</span>
        </a>
        <a
          className="app-directory-link"
          href="https://app.gusto.com/payroll_admin/apps?ref=cb"
          style={{ display: 'flex' }}
          target="_blank"
          rel="noreferrer"
        >
          <img src="https://staticassets.commandbar.com/icons/5725a2a3_empty_app.svg" alt="" />
          <span style={{ textDecoration: 'underline', color: '#0a8080', marginLeft: '8.5px' }}>App directory</span>
        </a>
        <a
          className="covid-19-link"
          href="https://app.gusto.com/payroll_admin/business_center?ref=cb"
          style={{ display: 'flex' }}
          target="_blank"
          rel="noreferrer"
        >
          <img src="https://staticassets.commandbar.com/icons/gusto-recommendations-icon.svg" alt="" />
          <span style={{ textDecoration: 'underline', color: '#0a8080', marginLeft: '8.5px' }}>Recommendations</span>
        </a>
      </div>
    </div>
  );
};

export default EmptyMenu;
