import React, { FunctionComponent } from 'react';
import ExecutionPath from '../engine/ExecutionPath';

import { useStore } from '../hooks/useStore';
import RenderCustomComponent from './RenderCustomComponent';

const SidePanel: FunctionComponent = () => {
  const { userDefinedCustomComponents, engine } = useStore();
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

  const component =
    currentStep?.type !== 'execute' && userDefinedCustomComponents.sidepanel({ step: currentStep?.type });

  if (typeof component === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: component }} />;
  } else if (component) {
    return <RenderCustomComponent component={component} />;
  }

  return null;
};

export default SidePanel;
