import React, { Fragment, ReactElement, useEffect, useMemo, useRef } from 'react';
import { Toaster } from 'react-hot-toast';

// eslint-disable-next-line commandbar/no-css-imports
import './style/App.css';
// eslint-disable-next-line commandbar/no-css-imports
import './style/animations.css';

/* Internal imports */
import CommandBar from './components/CommandBar';

import ThemeContext, { defaultTheme } from './contexts/ThemeContext';

import useCommandBarSDK from './engine/useCommandBarSDK';
import { organizationService } from './services/organizationService';

import useDidUpdate from './util/useDidUpdate';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';

import * as Reporting from './analytics/Reporting';

import { CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';

import useCustomTheme from './contexts/useCustomTheme';
import { ThemeProvider } from 'emotion-theming';

import LauncherContainer from './components/launcher/LauncherContainer';
import Confetti from './components/Confetti';
import Nudges from './components/nudges/Nudges';
import ChecklistContainer from './components/checklist/Checklist';
import HelpHubLauncher from './components/launcher/HelpHubLauncher';
import HelpWidget from './components/helphub/HelpWidget';

import { isMobile, osControlKey } from '@commandbar/internal/util/operatingSystem';
import { getSDK } from '@commandbar/internal/client/globals';
import { _configuration, _user } from '@commandbar/internal/client/symbols';
import * as AppActions from './store/app/actions';
import * as Engine from './store/engine/actions';
import { useAction } from './hooks/useAction';
import { useLoadEditor } from './hooks/useLoadEditor';
import { useStore } from './hooks/useStore';
import { selectSummonHotkey } from './store/engine/selectors';
import { useFocusReturnToPreviousElement } from './util/useFocusReturnToPreviousElement';
import Mousetrap from '@commandbar/internal/client/mousetrap_fork';
import Z from '@commandbar/internal/client/Z';
import Logger from '@commandbar/internal/util/Logger';
import { CommandBarContainerContext } from './hooks/useCommandBarContainer';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import useEmotionBugWorkaroundStyleContainer from './util/useEmotionBugWorkaroundStyleContainer';
import { getSentry } from '@commandbar/internal/util/sentry';

/*********************************************************************************/
const MTBody = new Mousetrap(document.body, true);

const App = () => {
  useCommandBarSDK();
  useLoadEditor();

  const sentry = getSentry();
  const { active, engine, refContainer } = useStore();
  const setLogo = useAction(Engine.setLogo);
  const setChatAvatar = useAction(Engine.setChatAvatar);
  const setTheme = useAction(Engine.setTheme);
  const { organization, themeSource, baseTheme, primaryColor, visible, formFactor, isAdmin } = engine;
  useFocusReturnToPreviousElement(visible);
  const booted = useRef(active);

  const commandBarContainerContext = useMemo(() => {
    if (formFactor.type === 'inline') {
      const inlineRootElement =
        typeof formFactor.rootElement === 'string'
          ? document.getElementById(formFactor.rootElement)
          : formFactor.rootElement;
      if (!inlineRootElement) {
        Logger.error("Unable to mount; root element for 'inline' bar not found", formFactor.rootElement);
        return null;
      }
      const root = (() => {
        const n = inlineRootElement.getRootNode();
        if (n instanceof ShadowRoot) {
          return n;
        }

        return document;
      })();

      return {
        container: inlineRootElement,
        root,
      };
    } else {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        container: document.getElementById('commandbar')!,
        root: document,
      };
    }
  }, [formFactor]);

  const styleContainer = useEmotionBugWorkaroundStyleContainer();
  const emotionCache = useMemo(() => {
    return createCache({ key: 'commandbar-toplevel', container: styleContainer });
  }, [styleContainer]);

  const theme = useCustomTheme({
    userTheme: organization?.theme,
    barFormFactor: engine.formFactor,
    baseTheme,
    primaryColor,
    themeSource,
    organization: organization,
    setLogo,
    setChatAvatar,
    setTheme,
  });

  useEffect(() => {
    organizationService.setOrganization(organization);
  }, [organization]);

  const reportEndSession = () => {
    Reporting.endSession();
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', reportEndSession);
    return () => window.removeEventListener('beforeunload', reportEndSession);
  }, []);

  const refocusInputOnVisibilityChange = () => {
    if (active) {
      // FIXME: need to wait for mount to focus
      if (refContainer.current !== null) {
        if (visible && !isMobile()) {
          refContainer.current.focus();
        } else {
          refContainer.current.blur();
        }
      }
    }
  };
  /* Refresh CommandBar every visibility change after mount */
  useDidUpdate(refocusInputOnVisibilityChange, [visible]);
  useEffect(() => {
    if (visible) {
      dispatchCustomEvent('CommandBar.opened', { detail: { open: true } });
    }
  }, [visible]);

  /**
   * Toggle Bar (open or close) via summon shortcut logic
   **/

  const handleOnCMDK = useAction((_, e: KeyboardEvent) => {
    // Check if text is currently selected in an input - allows customers to keep their cmd+k to 'add link' in text
    // Check if state is active, so we don't override cmd+k behavior if CommandBar is not turned on for a user
    if (!isTextSelected() && _.active && _.engine.products.includes('bar') && _.engine.organization?.bar_enabled) {
      e.stopPropagation();
      e.preventDefault();
      AppActions.toggleBar(_, 'keyboard');
      // dispatch an event so clients know when cmd+k is pressed
      dispatchCustomEvent('commandbar-shortcut-executed', {
        detail: { keys: `${osControlKey().toLowerCase() === 'ctrl' ? 'ctrl' : 'command'}+k` },
      });
    }
  });

  const isTextSelected = () => {
    try {
      if (typeof window.getSelection != 'undefined') {
        return (window.getSelection() || '').toString().length > 0;
      }
      return false;
    } catch (err: any) {
      sentry?.captureException(err);
      return false;
    }
  };

  const summonHotkey = selectSummonHotkey(useStore());

  React.useEffect(() => {
    MTBody.reset();
    MTBody.bind(summonHotkey, (e: any) => {
      handleOnCMDK(e);
    });
  }, [handleOnCMDK, summonHotkey]);

  //******************************************************************************************/
  // Launcher configuration defined in org model
  // If org hasn't loaded, or failed to load, don't show the launcher
  let launcher: null | ReactElement;
  if (organization) {
    launcher = (
      <div>
        <LauncherContainer />
      </div>
    );
  }

  React.useEffect(() => {
    if (active) {
      booted.current = true;
      const sdk = getSDK();

      sentry?.setUser({
        configuration: sdk[_configuration],
        organization,
        organizationId: sdk[_configuration].uuid,
        organizationName: organization?.name,
        session: sdk[_configuration].session,
        userId: sdk[_user] || undefined,
      });
    }
  }, [active, organization]);

  // If the user hasn't booted, give them a console warning after 15 seconds (for admin setup)
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!booted.current && isAdmin) {
        console.warn(
          '👋 CommandBar is almost ready. You just need to call `boot()` to make it available in your app. Learn more: https://www.commandbar.com/docs/dev/installation#booting-commandbar',
        );
      }
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /******************************************************************/
  /* Render
  /******************************************************************/
  if (theme.theme === undefined) {
    return null;
  }

  const showBar = engine.products.includes('bar') && organization?.bar_enabled;
  const showNudges = engine.products.includes('nudges');
  const showChecklists = engine.products.includes('questlists');
  const showHelpHub = engine.products.includes('help_hub') && organization?.helphub_enabled;

  const renderBar = () => {
    if (showBar) {
      return (
        <Fragment>
          <CommandBar />
          {launcher}
        </Fragment>
      );
    }
  };

  // Nudges include Surveys
  const renderNudges = () => {
    if (showNudges) {
      const commandBarWrapper = document.getElementById('commandbar-wrapper');
      const commandBarWrapperRect = commandBarWrapper?.getBoundingClientRect();

      return (
        <Fragment>
          <Nudges />
          <Toaster
            containerStyle={{
              zIndex: Z.Z_EDITOR_OVERLAY,
              marginTop: isStandaloneEditor ? '51px' : undefined,
              marginLeft: isStandaloneEditor ? `${commandBarWrapperRect?.left ?? 0}px` : undefined,
            }}
          />
        </Fragment>
      );
    }
  };

  const renderChecklists = () => {
    if (showChecklists && !isMobile()) {
      return <ChecklistContainer />;
    }
  };

  const renderHelpWidget = () => {
    return (
      <Fragment>
        <HelpWidget />
        {showHelpHub && <HelpHubLauncher organization={organization} />}
      </Fragment>
    );
  };

  // commandBarContainerContext can be `null` if the inline root element is not found
  if (!commandBarContainerContext) return null;

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={defaultTheme}>
          <CommandBarContainerContext.Provider value={commandBarContainerContext}>
            {active ? (
              <div>
                {renderBar()}
                <Confetti />
                {renderChecklists()}
                {renderNudges()}
                {renderHelpWidget()}
              </div>
            ) : null}
          </CommandBarContainerContext.Provider>
        </ThemeContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
