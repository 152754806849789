import { DashboardType } from './../../engine/step/DashboardStep';
import { State } from '..';
import * as Engine from '../engine/actions';
import { markGuideAsSeen } from '../util/launcherNudgeCache';
import * as Reporting from '../../analytics/Reporting';
import { OpenedEventTrigger } from '@commandbar/internal/client/EventHandler';
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import ExecutionPath from '../../engine/ExecutionPath';
import { Option } from '../../engine/option';

import { checkForEditor, clickEditorHandle, isEditorOpen } from '../util/editorUtils';
import { getSDK } from '@commandbar/internal/client/globals';
import { _eventSubscriptions, _loadEditor } from '@commandbar/internal/client/symbols';
import { getNextFocusedIndex, getFocusableChildrenAtIndex } from '../engine/selectors';
import { isEditorBeingSummoned } from '../../client_api/utils';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { StepType } from '../../engine/step/Step';
import { isOption, chooseOption } from '../engine';
import { getContextSettings } from '../engine/selectors';
import {
  fallbackGroup,
  initOptionGroupFromCommandCategory,
  initOptionGroupFromRecordCategory,
  OptionGroup,
} from '../../engine/OptionGroup';
import a11y from '../../util/a11y';
import { CommandCategory } from '@commandbar/internal/middleware/commandCategory';
import { createSearchFilter } from '../../components/select/SearchTabs';
import slugify from '@commandbar/internal/util/slugify';
import { ISearchFilter } from '.';
import { DEFAULT_PLACEHOLDER } from '../../components/select/input/placeholderHelpers';

export type KeyEvent = { key: string; preventDefault: VoidFunction; stopPropagation: VoidFunction };
export interface OpenBarOptions {
  startingInput?: string;
  categoryFilterID?: number | string;
}

export const addBuiltInCallbacks = (_: State) => {
  Engine.addCallbacks(_, {
    'commandbar-builtin-shortcuts': () => (_.showKeyboardShortcutCheatsheet = !_.showKeyboardShortcutCheatsheet),
    'commandbar-noop': function (args: any) {
      alert(`This is a demo command!\n\nYou entered:\n\n${JSON.stringify(args, null, 2)}`);
    },
    'commandbar-open-feedback': () => {
      openBarWithOptionalText(_, 'programmatic');
      _.dashboard = 'feedback';
    },
  });
};

export const addBuiltInEventSubscriptions = (_: State) => {
  const sdk = getSDK();
  if (!!sdk[_eventSubscriptions] && sdk[_eventSubscriptions] !== undefined) {
    const symbol = Symbol('commandbar-event-tracker');
    sdk[_eventSubscriptions]?.set(symbol, (name, data) => {
      Engine.trackEvent(_, name, { ...data }, 'commandbar');
    });
  }
};

export const clearInput = (_: State) => {
  if (_.engine.inputText) {
    _.engine.previousInputText = _.engine.inputText;
  }
  _.engine.inputText = '';
  _.engine.rawInput = '';
  _.refContainer?.current?.focus();
};

export const closeBarAndReset = (_: State) => {
  // Report the end of the search. Do it before resetting state
  Reporting.endSearch(_.engine.inputText, _.engine);

  // @ts-expect-error: FIXME dashboard type definition
  if (_.dashboard?.type?.name === 'SubmissionForm') {
    setDashboard(_, undefined);
    return;
  }

  // Close the bar and clean up
  Engine.setVisible(_, false);
  setDashboard(_, undefined);
  _.searchFilter = undefined;
  _.activeGuide = { id: -1, organization: '', event: '', nudge: '', guidance: '' };
  // Reset focus to the top
  Engine.resetFocusedIndex(_);

  // Input value does not blink when command bar closes
  setTimeout(() => {
    _.engine.previousInputText = '';
    _.engine.inputText = '';
    _.engine.rawInput = '';
  }, 100);
};

export const handleKeyDown = (_: State, e: KeyEvent) => {
  if (e.key === 'Backspace') {
    if (!_.engine.isBackspacing) {
      _.engine.isBackspacing = true;
      _.engine.cachedInput = _.engine.inputText;
    }
  } else {
    // Reset any dead end backspace tracking
    if (_.engine.isBackspacing) _.engine.isBackspacing = false;
    if (_.engine.cachedInput) _.engine.cachedInput = '';
  }
};

export const openBarWithOptionalText = (_: State, trigger: OpenedEventTrigger, meta?: OpenBarOptions) => {
  if (!_.engine.products.includes('bar')) {
    return;
  }

  // Make command bar visible, reset dashboard
  setDashboard(_, undefined);
  Engine.setVisible(_, true);
  Engine.resetFocusedIndex(_);

  // Mark guides as seen so they don't show again
  // FIXME: Guides are disabled
  markGuideAsSeen('showcase');
  markGuideAsSeen(_.activeGuide.event);

  // If there is text passed in, set the text in the bar
  if (meta?.startingInput) {
    Engine.handleInputChange(_, meta.startingInput);
  }

  // Set the search filter for the category
  if (meta?.categoryFilterID) {
    let groupToFilter: OptionGroup | undefined = undefined;

    if (typeof meta.categoryFilterID === 'number') {
      const categoryToFilter = _.engine.categories.find((cat) => cat.id === meta.categoryFilterID);
      if (categoryToFilter) {
        groupToFilter = initOptionGroupFromCommandCategory(categoryToFilter, _.engine);
      }
    } else if (typeof meta.categoryFilterID === 'string') {
      //FIXME: currentGroups does included recordGroups already, but only after the first open as they are not counted as active before
      const recordGroups = Object.entries(getContextSettings(_.engine)).map(([k, v]) => {
        const group = initOptionGroupFromRecordCategory(k, _.engine, v);
        if (!!!group.slash_filter_keyword) {
          group.slash_filter_keyword = slugify(group.name);
        }
        return group;
      });

      const allGroups = [..._.engine.currentGroups, ...recordGroups];

      groupToFilter = allGroups.find((group) => meta.categoryFilterID === group.slash_filter_keyword);

      if (!groupToFilter) {
        groupToFilter = allGroups.find((group) => {
          return meta.categoryFilterID === group.name;
        });
      }
    }
    if (!!groupToFilter) {
      const filter = createSearchFilter(groupToFilter);
      setSearchFilter(_, filter);
    }
  }

  // Report the new search
  Reporting.startSearch(
    trigger,
    _.engine.placeholders.length > 0 ? _.engine.placeholders?.[0].text : DEFAULT_PLACEHOLDER,
  );
};

export const openEditor = (_: State) => {
  const isCommandBarCom = window.location.href.includes('app.commandbar.com');

  if (isCommandBarCom && _.engine.inputText !== 'revelio:editor') {
    setDashboard(_, 'editor-access-denied');
    return;
  }

  if (checkForEditor()) {
    clickEditorHandle();
    Engine.setVisible(_, false);
    return;
  }

  // Poll for the editor element, close the bar once found
  const interval = setInterval(() => {
    const foundEditor = checkForEditor();
    if (!!foundEditor) {
      clearInterval(interval);

      setTimeout(() => {
        if (!isEditorOpen()) {
          clickEditorHandle();
        }
        setDashboard(_, undefined);
        Engine.setVisible(_, false);
      }, 500);
    }
  }, 500);

  // While waiting for the editor, play an animation
  setDashboard(_, 'editor-loading');

  // If the editor doesn't show up within MAX_WAIT_TIME, then show an error message
  const MAX_WAIT_TIME = 6000; // ms
  setTimeout(() => {
    if (!checkForEditor()) {
      clearInterval(interval);
      setDashboard(_, 'editor-timeout');
    }
  }, MAX_WAIT_TIME);

  getSDK()[_loadEditor]();
};

export const setSearchFilter = (_: State, filter: ISearchFilter | undefined) => {
  if (!!filter) {
    _.searchFilter = filter;
    Engine.changeGroupExpanded(_, _.searchFilter.slug, true);
  } else {
    _.searchFilter = undefined;
    _.engine.expandedGroupKeys = [];
  }
};

export const selectCurrentOption = (_: State, event?: React.KeyboardEvent | React.MouseEvent) => {
  const currentOption = _.engine.sortedOptions[_.engine.focusedIndex];
  if (isOption(currentOption)) {
    const isFallback = currentOption.groupKey === fallbackGroup().key;
    selectOption(_, currentOption, isFallback, event);
  }
};

export const selectOption = (
  _: State,
  opt: Option,
  preserveInputText?: boolean,
  event?: React.KeyboardEvent | React.MouseEvent,
) => {
  const { inputText } = _.engine;
  const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(_.engine);
  const { isDisabled } = opt.optionDisabled;

  if (!isDisabled) {
    _.searchFilter = undefined;
    chooseOption(_, opt, undefined, undefined, false, event);

    if (currentStep?.type !== StepType.MultiSelect) {
      Engine.handleInputChange(_, '');

      const { steps } = _.engine; // The steps get updated after a

      /**
       * Hack to interpolate value from the previous inputText into the next step.
       * Here we are calling the Engine.handleInputChange twice so as to force refresh
       * the state of the inputText.
       *
       * Additionally, we want to interpolate inputText only when in the base state
       * Without the hack, the value for the inputText is incorrectly interpolated.
       * See https://app.clickup.com/t/2zpxqag
       */
      if (steps.length > 1 && currentStepIndex === 0 && preserveInputText) {
        Engine.handleInputChange(_, inputText);
      }
    }
  } else {
    // TODO fix this hack; we want to set errorIndex to the index of `opt` but we don't have that index here.
    // This assumes that the option which was selected has index focusedIndex.
    _.engine.errorIndex = _.engine.focusedIndex;
    _.engine.errorTimestamp = Date.now();
  }
};

export const setDashboard = (_: State, v: DashboardType | undefined) => {
  _.dashboard = v;
};

export const setLoading = (_: State, v: string, isLoading: boolean) => {
  _.loadingByKey[v] = isLoading;
};

export const toggleBar = (_: State, trigger: OpenedEventTrigger): boolean => {
  if (_.active) {
    if (_.engine.visible) {
      closeBarAndReset(_);
      return false;
    } else {
      openBarWithOptionalText(_, trigger);
      return true;
    }
  }
  return false;
};

export const toggleBarFromLauncher = (_: State) => toggleBar(_, 'launcher');

export const tryOpenEditor = (_: State): boolean => {
  if (isEditorBeingSummoned(_.engine.inputText)) {
    openEditor(_);
    return true;
  }

  return false;
};

export const setEnvOverride = (_: State, envOverride: { env: string } | { version: string }) => {
  LocalStorage.set('envOverride', JSON.stringify(envOverride));
  _.envOverride = envOverride;
};

export const clearEnvOverride = (_: State) => {
  LocalStorage.remove('envOverride');
  _.envOverride = null;
};

/**
 * a11y focus functions
 * Note: "child" is the terminology given to the focusable elements within a row ("See more", editable shortcut, etc.)
 */
// a11y: reset focus to input and remove focus on child
export const resetChildFocus = (_: State) => {
  _.engine.focusableChildIndex = -1;
  const inputEl = document.getElementById(a11y.commandbarInputId);
  if (inputEl) {
    inputEl.focus();
  }
};

export const changeFocus = (_: State, direction: 'up' | 'down') => {
  _.engine.focusedIndex = getNextFocusedIndex(_, direction);
  resetChildFocus(_);
};

export const focusOutOfList = (_: State) => {
  // Set index to -1 to indicate focus has moved out of the list.
  _.engine.focusedIndex = -1;
  _.engine.focusableChildIndex = -1;
};

// a11y: refocus on currently active child
export const maintainChildFocus = (_: State) => {
  const focusableChildren = getFocusableChildrenAtIndex(_.engine.focusedIndex);

  if (focusableChildren && _.engine.focusableChildIndex < focusableChildren.length) {
    const el = focusableChildren.item(_.engine.focusableChildIndex);
    if (el && el instanceof HTMLElement) {
      el.focus();
    }
  }
};

// a11y: cycle through focus on an elements children (if any)
const tryChildFocus = (_: State, direction: 'up' | 'down'): boolean => {
  const focusableChildren = getFocusableChildrenAtIndex(_.engine.focusedIndex);

  if (!focusableChildren) {
    return false;
  }

  const hasNextChild = focusableChildren.length > _.engine.focusableChildIndex + 1;
  const hasPrevChild = _.engine.focusableChildIndex > 0;

  if (direction === 'down' && hasNextChild) {
    _.engine.focusableChildIndex++;
  } else if (direction === 'up' && hasPrevChild) {
    _.engine.focusableChildIndex--;
  } else {
    resetChildFocus(_);
    return false;
  }

  const el = focusableChildren.item(_.engine.focusableChildIndex);
  if (el && el instanceof HTMLElement) {
    el.focus();
    return true;
  }
  return false;
};

// a11y: tab nav should cycle through all focusable elements instead of just options
export const changeFocusWithTab = (_: State, direction: 'up' | 'down' | 'tab'): boolean => {
  // Returns true iff should break out of list, allowing focus to move elsewhere.
  if (_.engine.sortedOptions.length === 0) {
    return true;
  }

  if (direction === 'tab') {
    focusOutOfList(_);
    return true;
  }

  if (tryChildFocus(_, direction)) {
    // If already moving through children, continue to.
    return false;
  }

  // Break out of the option list if we're at the beginning or end
  // This allows focus on footer elements (if we're at the end), or tab elements (at the beginning)
  const next_index = getNextFocusedIndex(_, direction, true);
  if (direction === 'down') {
    if (next_index < _.engine.focusedIndex) {
      focusOutOfList(_);
      return true;
    }
  } else if (next_index > _.engine.focusedIndex) {
    focusOutOfList(_);
    return true;
  }

  _.engine.focusedIndex = next_index;
  tryChildFocus(_, 'down'); // If there is a child jump right to it.

  return false;
};

export const setCategories = (_: State, categories: ICommandCategoryType[]) => {
  _.serverCategories = categories;
};

export const addCategory = (_: State, categoryName: string) => {
  const existingCategoryIdx = _.localCategories.findIndex((category) => category.name === categoryName);
  if (existingCategoryIdx === -1) {
    const id = -(_.localCategories.length + 1);
    _.localCategories.push(
      CommandCategory.decode({
        id,
        organization: '',
        name: categoryName,
        slash_filter_keyword: slugify(categoryName),
      }),
    );
    return id;
  }

  return _.localCategories[existingCategoryIdx].id;
};

export const setCategoryConfig = (_: State, categoryId: number, config: Partial<ICommandCategoryType>) => {
  _.categoryConfig[categoryId] ||= {};
  _.categoryConfig[categoryId] = { ..._.categoryConfig[categoryId], ...config };
};
