/** @jsx jsx */
/** @jsxFrag React.Fragment */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { CacheProvider, jsx } from '@emotion/core';

/* other library imports */
// eslint-disable-next-line commandbar/no-css-imports
import 'rc-dialog/assets/index.css';
import Dialog from 'rc-dialog';
import appStyles from '../style/App';

/* Internal imports + */
import Dashboard from './Dashboard';
import CommandSelect from './select/CommandSelect';
import KeyboardShortcutCheatsheet from './KeyboardShortcutCheatsheet';

import createCache from '@emotion/cache';
import ExecutionPath from '../engine/ExecutionPath';

import Z from '@commandbar/internal/client/Z';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import InlineBar from './InlineBar';
import { useStore } from '../hooks/useStore';
import a11y from '../util/a11y';
import { useAction } from '../hooks/useAction';
import * as App from '../store/app/actions';
import SystemPanel from './select/footer/SystemPanel';
import { selectMenuIsOpen } from '../store/app';

import SidePanel from './SidePanel';
import Logger from '@commandbar/internal/util/Logger';
import ReactDOM from 'react-dom';
import CustomHeader from './select/CustomHeader';
import VerticalNavPane from './VerticalNavPane';
import { StepType } from '../engine/step/Step';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { MobileCommandBarContainer } from './MobileBarContainer';

import DashboardStepDisplay, { DashboardStepDisplayProps } from './step/DashboardStepDisplay';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { useCommandBarContainer } from '../hooks/useCommandBarContainer';
import { useMemo } from 'react';
import useTheme from '../hooks/useTheme';
import useEmotionBugWorkaroundStyleContainer from '../util/useEmotionBugWorkaroundStyleContainer';
/*********************************************************************************/

enum ANIMATION_CLASS {
  opened = 'commandbar-opened', // show open animation
  selected = 'commandbar-selected', // show selected animation
}

const CommandBar: () => JSX.Element | null = () => {
  const inlineRootElementCleared = React.useRef(false);

  const closeBarAndReset = useAction(App.closeBarAndReset);
  const _ = useStore();
  const { dashboard, engine, previewMode, refContainer, showKeyboardShortcutCheatsheet } = _;
  const { visible, formFactor } = engine;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

  const unmounting = React.useRef(false);
  const windowWidth = useWindowSize().width;

  React.useEffect(
    () => () => {
      unmounting.current = true;
    },
    [],
  );

  /******************************************************************/
  /* Animations
  /******************************************************************/
  // We need to use use effects because the animation to show is dependent
  //   on previous state. If the foobar is open, and we remove an active command
  //   we don't want to re-show the opened animation
  const [animationClass, setAnimationClass] = React.useState<ANIMATION_CLASS | ''>(ANIMATION_CLASS.opened);
  const menuIsOpen = selectMenuIsOpen(useStore());

  React.useEffect(() => {
    // on every open, trigger the opened animation
    setAnimationClass(ANIMATION_CLASS.opened);
  }, [visible]);

  const handlePulse = () => {
    setAnimationClass(ANIMATION_CLASS.selected);
    setTimeout(() => {
      if (unmounting.current) return;
      setAnimationClass('');
    }, 400);
  };

  const { currentStepIndex } = ExecutionPath.currentStepAndIndex(engine);
  React.useEffect(() => {
    handlePulse();
  }, [currentStepIndex]);

  /******************************************************************/

  const { theme } = useTheme();

  const content = (
    <>
      {/* (The <div> element has a child <input> element that allows keyboard interaction so we disable no-static-element-interactions */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        id="commandbar-home"
        aria-modal={true}
        // Stop propagation of any key events that originate from the bar, to avoid triggering user keyboard listeners
        // Note: This stops any listeners that are set in the bubbling phase (the default), not the capturing phase
        // There isn't a way for us to intercept events before capturing listeners that are set prior to when our listeners are set
        // Note #2: Native JS events are only stopped above the document root, any between commandbar-home and the root will still be reached
        onKeyUp={(e: any) => {
          e.stopPropagation();
        }}
        onKeyPress={(e: any) => {
          e.stopPropagation();
        }}
        onKeyDown={(e: any) => {
          e.stopPropagation();
        }}
      >
        {dashboard ? (
          <Dashboard type={dashboard} />
        ) : currentStep?.type === StepType.Dashboard ? (
          <div id="commandbar-dashboard" className="commandbar-content-container">
            <DashboardStepDisplay {...(currentStep.argument.value as unknown as DashboardStepDisplayProps)} />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CustomHeader />
            <div style={{ display: 'flex' }}>
              <VerticalNavPane />
              <CommandSelect ref={refContainer} />
              <SidePanel />
            </div>
          </div>
        )}
      </div>
      {menuIsOpen && !isMobile() && <SystemPanel />}
    </>
  );

  const { container } = useCommandBarContainer();

  // Once; before render; clear the root element.
  // This enables a "skeleton" to be rendered in the root while the Bar is loading, which
  // is then automatically replaced with the functional Bar.
  if (formFactor.type === 'inline' && container && !inlineRootElementCleared.current) {
    container.innerHTML = '';
    inlineRootElementCleared.current = true;
  }

  const styleContainer = useEmotionBugWorkaroundStyleContainer(container);
  const emotionCache = useMemo(() => createCache({ key: 'commandbar-bar', container: styleContainer }), [container]);

  switch (formFactor.type) {
    case 'inline':
      if (!container) {
        Logger.error("Unable to mount; root element for 'inline' bar not found", formFactor.rootElement);
        return null;
      }

      try {
        return ReactDOM.createPortal(
          <CacheProvider value={emotionCache}>
            {/* needed for components/Tooltip.tsx */}
            <div id="commandbar-rc-tooltip-container" />
            <div css={appStyles}>
              <InlineBar>{content}</InlineBar>
            </div>
          </CacheProvider>,
          container,
        );
      } catch (e) {
        Logger.error(
          "Unable to mount; root element for 'inline' bar not found or not an HTMLElement",
          formFactor.rootElement,
          e,
        );
        return null;
      }
      break;

    case 'modal':
      if (isMobile()) {
        return (
          <CacheProvider value={emotionCache}>
            <MobileCommandBarContainer>{content}</MobileCommandBarContainer>
          </CacheProvider>
        );
      }

      const bodyStyle: React.CSSProperties = {
        backgroundColor: theme.main.background,
        borderRadius: theme.main.borderRadius,
        padding: '0px 0px 0px 0px',
        boxShadow: theme.main.boxShadow,
        zIndex: 1,
        margin: '0px 5px', // mobile screen padding
      };

      return (
        <CacheProvider value={emotionCache}>
          {/* needed for components/Tooltip.tsx */}
          <div id="commandbar-rc-tooltip-container" />
          <Dialog
            visible={previewMode ? true : visible}
            closable={false}
            destroyOnClose={false}
            keyboard={true}
            maskStyle={{ backgroundColor: 'rgba(55, 55, 55, 0.95)' }}
            bodyStyle={{
              ...bodyStyle,
            }}
            wrapClassName={'commandbar-modal'}
            wrapStyle={{
              maxWidth: isStandaloneEditor ? theme.bar.width : undefined,
            }}
            wrapProps={{ 'aria-label': a11y.dialogLabel }}
            bodyProps={{ className: `commandbar-animation ${animationClass}` }}
            className={'commandbar-dialog'}
            style={{
              fontFamily: theme.main.fontFamily,
              backgroundColor: 'transparent',
              top: theme.bar.top,
              width: isStandaloneEditor
                ? '100%'
                : windowWidth < parseInt(theme.bar.width, 10)
                ? undefined
                : theme.bar.width,
            }}
            zIndex={Z.Z_EDITOR}
            onClose={closeBarAndReset}
            mask={false}
            getContainer={container}
          >
            {content}
          </Dialog>
          {showKeyboardShortcutCheatsheet && <KeyboardShortcutCheatsheet />}
        </CacheProvider>
      );
  }
};

export default CommandBar;
