import { ITheme } from '@commandbar/internal/client/theme';
import {
  ICommandCategoryType,
  IResourceSettings,
  ITabType,
  OptionGroupRenderAsType,
} from '@commandbar/internal/middleware/types';

import { interpolate } from './Interpolate';
import { Option } from './option';
import { EngineState } from '../store/engine/state';

import { isCommandOption, isResourceOption, isParameterOption, getReservedField } from '../store/engine';
import ClientSearch from './ClientSearch';

const allowedOptionGroupTypes = [
  'RECOMMENDED',
  'RECENTS',
  'RECORD_CATEGORY',
  'PARAMETER',
  'COMMAND_CATEGORY',
  'NONE',
  'TAB',
  'FALLBACK',
] as const;
type OptionGroupType = (typeof allowedOptionGroupTypes)[number];

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isOptionGroup = (o: unknown): o is OptionGroup =>
  typeof (o as OptionGroup)?.type === 'string' && allowedOptionGroupTypes.includes((o as OptionGroup).type);

export type OptionGroup = {
  name: string;
  key: string;
  type: OptionGroupType;

  limit?: number | null;
  size: number;
  sortKey: number | null;

  // settings
  pinnedToBottom: boolean;
  sortFunction?: (a: any, b: any) => any;
  onInputChangeOptions?: {
    applySort?: boolean;
  };
  showWithNoResults?: boolean;
  renderAs: OptionGroupRenderAsType;

  slash_filter_enabled: boolean;
  slash_filter_keyword: string | null;

  // tab settings
  searchTabEnabled: boolean;
  searchTabName: string | null;
  searchTabInstruction: string | null;

  filterFn: (o: Option) => boolean;

  hasHotloadedCommands: boolean;
};

export type TabGroup = OptionGroup & {
  icon?: string | null;
  header?: string | null;
};

export const generateOptionGroupKey = (type: OptionGroupType, id: string | number) => {
  return `${type}-${id.toString()}`;
};

const initWithDefaults = (name: string, key: string, type: OptionGroupType): OptionGroup => {
  return {
    name,
    key,
    type,
    limit: null,
    pinnedToBottom: false,
    sortFunction: undefined,
    showWithNoResults: false,
    searchTabEnabled: false,
    searchTabName: null,
    searchTabInstruction: null,
    sortKey: null,
    renderAs: 'list',
    size: 0,
    slash_filter_enabled: false,
    slash_filter_keyword: null,
    filterFn: () => {
      return false;
    },
    hasHotloadedCommands: false,
  };
};

export const initOptionGroupFromCommandCategory = (
  commandCategory: ICommandCategoryType,
  engine: EngineState['engine'],
): OptionGroup => {
  const groupKey = generateOptionGroupKey('COMMAND_CATEGORY', commandCategory.id);
  return {
    name: interpolate(commandCategory.name, engine, true, false),
    key: groupKey,
    type: 'COMMAND_CATEGORY',
    limit: commandCategory.setting_max_options_count,
    pinnedToBottom: commandCategory.setting_pin_to_bottom,
    searchTabEnabled: commandCategory.search_tab_enabled,
    searchTabName: commandCategory.search_tab_name,
    searchTabInstruction: commandCategory.search_tab_instruction,
    sortKey: commandCategory.sort_key,
    renderAs: commandCategory.render_as || 'list',
    showWithNoResults: false,
    size: 0,
    slash_filter_enabled: commandCategory.slash_filter_enabled,
    slash_filter_keyword: commandCategory.slash_filter_keyword || null,
    filterFn: (o: Option) => {
      return (
        isCommandOption(o) &&
        !!o.command.category &&
        generateOptionGroupKey('COMMAND_CATEGORY', o.command.category) === groupKey
      );
    },
    hasHotloadedCommands: !!commandCategory.contains_hotloaded_commands,
  };
};

export const initOptionGroupFromTab = (tab: ITabType, engine: EngineState['engine']): OptionGroup => {
  const name = interpolate(tab.label, engine, true, false);

  return {
    ...initWithDefaults(name, generateOptionGroupKey('TAB', tab.id), 'TAB'),
    limit: undefined,
    pinnedToBottom: false,
    searchTabEnabled: true,
    searchTabName: tab.label,
    slash_filter_enabled: true,
    slash_filter_keyword: null,
    searchTabInstruction: tab.placeholder,
    sortKey: tab.sort_key,
    renderAs: tab.render_as,
    filterFn: (o: Option) => {
      return isCommandOption(o) && !!o.command.category && tab.category_ids.includes(o.command.category);
    },
  };
};

export const initOptionGroupFromRecordCategory = (
  contextKey: string,
  engine: EngineState['engine'],
  searchOptions?: IResourceSettings,
): OptionGroup => {
  const categoryName = searchOptions?.name || `${capitalize(contextKey)}`;
  const name = interpolate(categoryName, engine, true, false);
  const groupKey = generateOptionGroupKey('RECORD_CATEGORY', contextKey);
  return {
    ...initWithDefaults(name, groupKey, 'RECORD_CATEGORY'),
    limit: searchOptions?.max_options_count,
    pinnedToBottom: !!searchOptions?.setting_pin_to_bottom,
    sortFunction: searchOptions?.sortFunction,
    showWithNoResults: searchOptions?.search && searchOptions?.show_with_no_results,
    // Note: doesn't support unfurling
    searchTabEnabled: (searchOptions?.search_tab_enabled && !searchOptions.unfurl) || false,
    searchTabName: searchOptions?.search_tab_name || null,
    searchTabInstruction: searchOptions?.search_tab_instruction || null,
    sortKey: searchOptions?.sort_key ?? null,
    renderAs: searchOptions?.render_as || 'list',
    slash_filter_enabled: searchOptions?.slash_filter_enabled ?? true,
    slash_filter_keyword: searchOptions?.slash_filter_keyword || null,
    filterFn: (o: Option) => {
      return isResourceOption(o) && generateOptionGroupKey('RECORD_CATEGORY', o.category.contextKey) === groupKey;
    },
    hasHotloadedCommands: ClientSearch.isDefined(contextKey, engine),
    onInputChangeOptions: {
      applySort: searchOptions?.onInputChangeOptions?.applySort,
    },
  };
};

const initOptionGroupFromParameterCategory = (
  name: string,
  sortFunction?: (a: any, b: any) => any,
  renderAs?: 'list' | 'grid',
): OptionGroup => {
  return {
    ...initWithDefaults(name, generateOptionGroupKey('PARAMETER', name), 'PARAMETER'),
    sortFunction,
    renderAs: renderAs || 'list',
  };
};

export const getGroupOfOption = (
  option: Option,
  categories: ICommandCategoryType[],
  engine: EngineState['engine'],
  showRecommendedGroup: boolean,
): OptionGroup => {
  if (isCommandOption(option)) {
    if (option.isRecommended && showRecommendedGroup) {
      return initRecommendedGroup(engine);
    }

    if (option.isRecent) {
      return initRecentsGroup(engine);
    }

    const categoryID = option.command?.category;
    if (!!categoryID) {
      const category = categories.find((obj) => obj.id === categoryID);
      if (category) {
        return initOptionGroupFromCommandCategory(category, engine);
      }
    }
  } else if (isResourceOption(option)) {
    if (option.isRecent) {
      return initRecentsGroup(engine);
    }

    return initOptionGroupFromRecordCategory(option.category.contextKey, engine, option.searchOptions);
  } else if (isParameterOption(option)) {
    const category = getReservedField(option, 'category') || '';
    const name = interpolate(category, engine, true, false);

    return initOptionGroupFromParameterCategory(
      name,
      option.searchOptions?.sortFunction,
      option.searchOptions?.render_as,
    );
  }
  return noneGroup();
};

export const getDefaultHeaderHeight = (theme: ITheme, addPaddingTop: boolean) => {
  return (
    parseInt(theme.categoryHeader.paddingBottom, 10) +
    parseInt(theme.categoryHeader.paddingTop, 10) +
    parseInt(theme.categoryHeader.fontSize, 10) +
    (addPaddingTop ? parseInt(theme.optionList.spaceBetweenCategories) : 0) +
    parseInt(theme.optionList.spaceBelowHeader, 10) +
    1 // 1 px border which isn't specified by a theme, but always present
  );
};

export const getRecordGroupKey = (group: OptionGroup): null | string => {
  if (group.type !== 'RECORD_CATEGORY') {
    return null;
  } else {
    return group.key.replace('RECORD_CATEGORY-', '');
  }
};

export const compareOptionGroups = (a: OptionGroup, b: OptionGroup) => {
  // Pin to bottom
  if (a.pinnedToBottom !== b.pinnedToBottom) {
    if (a.pinnedToBottom) return 1;
    if (b.pinnedToBottom) return -1;
  }

  // Special categories
  const specialCategories = [recommendedGroup().key, recentsGroup().key, noneGroup().key];

  // sort like regular categories if there is an explicit sort key
  const specialIdxA = specialCategories.findIndex((k) => k === a.key && a.sortKey === null);
  const specialIdxB = specialCategories.findIndex((k) => k === b.key && b.sortKey === null);
  if (specialIdxA !== -1 && specialIdxB !== -1) return specialIdxA - specialIdxB;
  if (specialIdxA !== -1) return -1;
  if (specialIdxB !== -1) return 1;

  // Sort key
  if (a.sortKey === b.sortKey) return 0;
  if (b.sortKey === null) return -1;
  if (a.sortKey === null) return 1;
  return a.sortKey - b.sortKey;
};

export const isOptionGroupLoading = (group: OptionGroup, loadingByKey: Record<string, boolean>) => {
  const groupContextKey = getRecordGroupKey(group);
  return groupContextKey ? !!loadingByKey[groupContextKey] : false;
};

export const isOptionGroupOverLimit = (group: OptionGroup) => {
  if (!group.limit || !group.size || group.limit >= group.size) return false;
  return true;
};

const isOptionGroupExpanded = (group: OptionGroup, expandedGroupKeys: string[]) => {
  return expandedGroupKeys.includes(group.key);
};

export const canOptionGroupExpand = (group: OptionGroup, expandedGroupKeys: string[]) => {
  return isOptionGroupOverLimit(group) && !isOptionGroupExpanded(group, expandedGroupKeys);
};

export const shouldEnforceOptionGroupLimit = (group: OptionGroup, expandedGroupKeys: string[]) => {
  return !!group.limit && !isOptionGroupExpanded(group, expandedGroupKeys);
};
export const incrementOptionGroupSize = (group: OptionGroup) => {
  return { ...group, size: group.size + 1 };
};

export const recommendedGroup = (
  name?: string,
  searchTabInstruction?: string,
  sortKey?: number | null,
): OptionGroup => {
  return {
    ...initWithDefaults(name || 'Recommended', 'RECOMMENDED-', 'RECOMMENDED'),
    sortKey: typeof sortKey !== 'undefined' ? sortKey : null,
    searchTabInstruction: searchTabInstruction ?? null,
    filterFn: (o: Option) => isCommandOption(o) && o.isRecommended,
  };
};

export const recentsGroup = (name?: string, searchTabInstruction?: string, sortKey?: number | null): OptionGroup => {
  return {
    ...initWithDefaults(name || 'Recents', 'RECENTS-', 'RECENTS'),
    limit: 5,
    sortKey: typeof sortKey !== 'undefined' ? sortKey : null,
    searchTabInstruction: searchTabInstruction ?? null,
    filterFn: (o: Option) => (isCommandOption(o) && o.isRecent) || (isResourceOption(o) && o.isRecent),
  };
};

export const initRecentsGroup = (engine: EngineState['engine']): OptionGroup => {
  return recentsGroup(
    engine?.theme?.categoryHeader.recentsHeaderLabel,
    engine.organization?.recents_tab_instruction,
    engine?.organization?.recents_sort_key,
  );
};

export const initRecommendedGroup = (engine: EngineState['engine']): OptionGroup => {
  return recommendedGroup(
    engine?.theme?.categoryHeader?.recommendedHeaderLabel,
    engine.organization?.recommended_tab_instruction,
    engine?.organization?.recommended_sort_key,
  );
};

export const noneGroup = (): OptionGroup => {
  return initWithDefaults('', 'NONE-', 'NONE');
};

export const fallbackGroup = (): OptionGroup => {
  return initWithDefaults('Fallbacks', 'FALLBACK-', 'FALLBACK');
};
