import ExecutionPath, { fulfill, rebase, isContextFunctionStep, isSelectStep, isDashboardStep } from './ExecutionPath';
import { Option } from './option';
import { InternalError } from './Errors';
import ClientSearch from './ClientSearch';
import { State } from '../store';
import dayjs from 'dayjs';
import { EngineState } from '../store/engine/state';
import { StepType } from './step/Step';
import { hasArgument, chooseOption } from '../store/engine';
import { initParameterOption } from './option/ParameterOption';
import { getSentry } from '@commandbar/internal/util/sentry';

const simulate = (
  optionToSimulate: Option,
  state: EngineState['engine'],
): { isExecutable: boolean; isExecutableReason: string } => {
  try {
    _simulate(optionToSimulate, state);
  } catch (err) {
    getSentry()?.captureException(err);

    if (err instanceof InternalError) {
      return { isExecutable: false, isExecutableReason: err.message };
    } else {
      throw err;
    }
  }
  return { isExecutable: true, isExecutableReason: '' };
};

const _simulate = (optionToSimulate: Option, state: State['engine']) => {
  let { currentStep } = ExecutionPath.currentStepAndIndex(state);
  let _state: EngineState['engine'] = { ...state, simulation: true };

  if (currentStep?.type !== StepType.Base) {
    throw new InternalError('Simulation error: Invalid simulation step.');
  }

  /*
    Things to catch:
      - A Step has no available options
      - Renders work
      - Final selections and breadcrumbs are good

    Improvements:
      - typechecking conditional .choose return value
   */

  const pickARandomOption = (options: Option[]): Option => {
    if (options.length === 0) {
      throw new InternalError('Step has no valid options');
    }
    const randomIndex = Math.floor(Math.random() * Math.floor(options.length));

    return options[randomIndex];
  };

  let option;
  let started = false;

  while (currentStep !== undefined) {
    if (!started) {
      // If first step, we don't need to calculate the available options
      //    because we already know what command to use
      // The first rebase can also be very computationally intensive to get the available resources
      option = optionToSimulate;
      started = true;
    } else {
      if (currentStep.type === StepType.TextInput || currentStep.type === StepType.LongTextInput) {
        option = initParameterOption({ engine: _state }, 'SampleTextInput', 'SampleTextInput');
      } else if (
        hasArgument(currentStep) &&
        currentStep.argument.type === 'provided' &&
        currentStep.argument.value === 'time'
      ) {
        option = initParameterOption({ engine: _state }, 'today', { label: 'today', date: dayjs() });
      } else if (isSelectStep(currentStep) && isContextFunctionStep(currentStep, state)) {
        // @REFACTOR Figure out what to do here
        option = initParameterOption({ engine: _state }, 'SampleFunctionParameter', {
          label: 'SampleFunctionParameter',
          value: 'SampleFunctionParameter',
        });
        // REVIEW: May be better to use `String(currentStep.argument.value)`, but this may introduce different behavior
      } else if (hasArgument(currentStep) && ClientSearch.isDefined(currentStep.argument.value as string, state)) {
        // If addSearch is defined for an argument, it might
        // not have any values defined initially
        option = initParameterOption({ engine: _state }, 'SampleSearchParameter', {
          label: 'SampleSearchParameter',
          value: 'SampleSearchParameter',
        });
      } else if (isDashboardStep(currentStep)) {
        option = initParameterOption({ engine: _state }, 'SampleSearchParameter', {
          label: 'SampleSearchParameter',
          value: 'SampleSearchParameter',
        });
      } else {
        // Rebase the state to get the current available commands
        _state = rebase(_state);
        option = pickARandomOption(_state.initialOptions);
      }
    }

    // NOTE: The result should not be undefined as long as engine.simulate is true.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _state = chooseOption({ engine: _state }, option)!;
    _state = fulfill(_state);

    currentStep = ExecutionPath.currentStepAndIndex(_state).currentStep;
  }
  // Important not to do the final rebase, otherwise it will call simulate again
  // _state = rebase(_state);
};

const Simulate = {
  simulate,
};

export default Simulate;
