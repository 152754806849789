/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import { css, jsx, keyframes } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import * as React from 'react';
import { useStore } from '../../hooks/useStore';
import { Option } from '../../engine/option';
import { optionIsInGrid } from '../../store/app';
import useTheme from '../../hooks/useTheme';

interface IProps {
  option: Option;
  label: string | React.ReactNode;
  type: 'command' | 'resource' | 'parameter';
  isFocused: boolean;
  isError: boolean;
  isDisabled: boolean;
  icon?: React.ReactNode;
  draft?: React.ReactNode;
  description?: React.ReactNode;
  shortcut?: React.ReactNode;
  goForward?: React.ReactNode;
  extra?: React.ReactNode;
  heading?: React.ReactNode;
  isMultiSelectSelection?: boolean;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const getOptionWrapperStyles = (theme: ITheme, isFocused: boolean, isDisabled: boolean, renderAs = 'list') => {
  if (renderAs === 'grid') {
    return {
      ...theme.menuGridOption,
      ...(isFocused ? theme.menuGridOptionSelected : {}),
      ...(isDisabled ? theme.menuGridOptionDisabled : {}),
      ...(isFocused && isDisabled ? theme.menuGridOptionDisabledSelected : {}),
    };
  }

  return {
    ...theme.option,
    ...(isFocused ? theme.optionSelected : {}),
    ...(isDisabled ? theme.optionDisabled : {}),
    ...(isFocused && isDisabled ? theme.optionDisabledSelected : {}),
  };
};

const OptionWrapper = (props: IProps) => {
  const { theme } = useTheme();
  const _ = useStore();
  const {
    searchFilter,
    engine: { errorTimestamp },
  } = _;

  const [renderAs, isGridOption] = React.useMemo(() => {
    const _renderAs = optionIsInGrid(_, props.option) ? 'grid' : 'list';
    return [_renderAs, _renderAs === 'grid'];
  }, [props.option, searchFilter]);

  const style = getOptionWrapperStyles(theme, props.isFocused, props.isDisabled, renderAs);

  const errorAnimationKeyframes = keyframes`
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
`;

  const errorAnimation = css`
    animation: ${errorAnimationKeyframes} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  `;

  const hideIcon = isGridOption ? theme.menuGridOption.iconDisplay === 'none' : theme.option.iconDisplay === 'none';

  // FIXME: Not sure if this is the best way to do this
  if (isGridOption) {
    return (
      <div
        key={
          errorTimestamp /* NOTE: using this key here so that the errorAnimations re-runs if the command is executed and errors again */
        }
        css={props.isError ? errorAnimation : {}}
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          fontWeight: 400,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className={`commandbar-option commandbar-option-${props.type} ${
          !!props.isMultiSelectSelection && 'commandbar-option-selected'
        }`}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {hideIcon ? null : isGridOption ? (
            <div
              role="img"
              aria-hidden={true}
              style={{
                alignSelf: theme.option.iconAlign,
                lineHeight: 0, // https://stackoverflow.com/a/22300226
                marginRight: '10px',
                marginLeft: '10px',
              }}
            >
              {props.icon}
            </div>
          ) : (
            <div
              role="img"
              aria-hidden={true}
              style={{
                alignSelf: theme.option.iconAlign,
                lineHeight: 0, // https://stackoverflow.com/a/22300226
              }}
            >
              {props.icon}
            </div>
          )}
          {!isGridOption ? <div style={{ minWidth: theme.option.spaceBetweenIconAndLabel }} /> : null}
          <div
            style={{
              color: style.color,
              fontFamily: style.fontFamily,
              fontSize: style.fontSize,
              width: '100%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              lineHeight: theme.option.lineHeight,
            }}
          >
            <div>
              {props.heading && (
                <div
                  style={{
                    fontSize: style.breadcrumbsFontSize,
                    color: style.breadcrumbsColor,
                    marginBottom: style.breadcrumbsMarginBottom,
                    marginTop: style.breadcrumbsMarginTop,
                    fontWeight: style.breadcrumbsFontWeight,
                  }}
                >
                  {props.heading}
                </div>
              )}
              <span
                role="heading"
                aria-level={2}
                style={{
                  display: 'flex',
                  alignContent: 'flex-start',
                  alignItems: 'center',
                  fontWeight: style.labelFontWeight,
                  marginTop: style.labelMarginTop,
                  marginBottom: style.labelMarginBottom,
                  whiteSpace: 'normal',
                }}
              >
                {props.draft && <div style={{ paddingRight: 10 }}>{props.draft}</div>}
                {props.label}
              </span>

              {!!props.description && (
                <div
                  role="heading"
                  aria-level={3}
                  css={{
                    fontSize: style.descriptionFontSize,
                    fontStyle: style.descriptionFontStyle,
                    marginTop: style.descriptionMarginTop,
                    marginBottom: style.descriptionMarginBottom,
                    color: props.isError ? theme.option.descriptionColor : style.descriptionColor,
                    transition: 'color 0.8s',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    textAlign: 'left',
                  }}
                >
                  {props.description}
                </div>
              )}
              {!!props.extra && (
                <div role="heading" aria-level={3}>
                  {props.extra}
                </div>
              )}
            </div>
            <div></div>
          </div>
          {props.goForward && <div>{props.goForward}</div>}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {props.shortcut}
        </div>
      </div>
    );
  }

  return (
    <div
      key={
        errorTimestamp /* NOTE: using this key here so that the errorAnimations re-runs if the command is executed and errors again */
      }
      css={props.isError ? errorAnimation : {}}
      style={{
        display: 'flex',
        width: '100%',
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      className={`commandbar-option commandbar-option-${props.type} ${
        !!props.isMultiSelectSelection ? 'commandbar-option-selected' : ''
      }`}
    >
      {theme.option.iconDisplay === 'none' ? null : isGridOption ? (
        <div
          role="img"
          aria-hidden={true}
          style={{
            alignSelf: theme.option.iconAlign,
            lineHeight: 0, // https://stackoverflow.com/a/22300226
            marginRight: '10px',
            marginLeft: '10px',
          }}
        >
          {props.icon}
        </div>
      ) : (
        <div
          role="img"
          aria-hidden={true}
          style={{
            ...('iconColor' in style && { color: style.iconColor }),
            alignSelf: theme.option.iconAlign,
            lineHeight: 0, // https://stackoverflow.com/a/22300226
          }}
        >
          {props.icon}
        </div>
      )}
      {!isGridOption ? <div style={{ minWidth: theme.option.spaceBetweenIconAndLabel }} /> : null}
      <div
        style={{
          color: style.color,
          fontFamily: style.fontFamily,
          fontSize: style.fontSize,
          maxWidth: '90%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          lineHeight: theme.option.lineHeight,
        }}
      >
        {props.heading && (
          <div
            style={{
              fontSize: style.breadcrumbsFontSize,
              color: style.breadcrumbsColor,
              marginBottom: style.breadcrumbsMarginBottom,
              marginTop: style.breadcrumbsMarginTop,
              fontWeight: style.breadcrumbsFontWeight,
            }}
          >
            {props.heading}
          </div>
        )}

        <span
          style={{
            display: 'flex',
            alignContent: 'flex-start',
            alignItems: 'center',
            fontWeight: style.labelFontWeight,
            marginTop: style.labelMarginTop,
            marginBottom: style.labelMarginBottom,
          }}
        >
          {props.draft && <div style={{ paddingRight: 10 }}>{props.draft}</div>}
          {props.label}
        </span>
        {!!props.description && (
          <div
            style={{
              fontSize: style.descriptionFontSize,
              fontStyle: style.descriptionFontStyle,
              marginTop: style.descriptionMarginTop,
              marginBottom: style.descriptionMarginBottom,
              color: props.isError ? theme.option.descriptionColor : style.descriptionColor,
              transition: 'color 0.8s',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: isGridOption ? 'normal' : 'nowrap',
            }}
          >
            {props.description}
          </div>
        )}
        {!!props.extra && <div>{props.extra}</div>}
      </div>
      <div style={{ flexGrow: 1 }} />
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        {props.shortcut}
        {props.goForward}
      </div>
    </div>
  );
};

export default OptionWrapper;
