import * as t from 'io-ts';

export const EndUserV = t.type({
  username: t.string,
  slug: t.string,
  organization: t.string,
  has_access: t.boolean,
  has_opened: t.boolean,
  hotkeys_debug: t.boolean,
  hmac: t.union([t.string, t.undefined]),
  identifier: t.string,
});
