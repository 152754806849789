import React from 'react';
import snarkdown from '@bpmn-io/snarkdown';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import { useStore } from '../../hooks/useStore';
import { selectCurrentTab } from '../../store/app';
import { useTheme } from 'emotion-theming';

import { ITheme } from '@commandbar/internal/client/theme';

const TabDescription = () => {
  const currentTab = selectCurrentTab(useStore());
  const { theme }: { theme: ITheme } = useTheme();

  if (currentTab && !!currentTab.description) {
    return (
      <div
        style={{
          color: theme.base.fontColor,
          fontSize: theme.searchTabDescription.fontSize,
          fontFamily: theme.base.fontFamily,
          background: theme.base.background,
          padding: theme.searchTabDescription.padding,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(snarkdown(currentTab.description)) }}
      />
    );
  }

  return null;
};

export default TabDescription;
