/** @jsx jsx  */
import { jsx, css } from '@emotion/core';
import React, { Fragment, useEffect } from 'react';
import { HelpHubDoc } from '../../store/engine';
import { useStore } from '../../hooks/useStore';
import { ChatMessage, fetchContinuations, NO_ANSWER } from '../../client_api/search';
import { InfoCircle, ThumbsDown, ThumbsUp } from '@commandbar/design-system/icons/react';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import { draftDocToFullDoc } from '../../store/engine/help-hub/helpers';
import { IInstantAnswerType, LabeledAction } from '@commandbar/internal/middleware/types';
import Icon from '@commandbar/internal/client/Icon';
import AIProfileIcon from './AIProfileIcon';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import { DEFAULT_SUGGESTIONS_KEY } from './HelpHub';
import Logger from '@commandbar/internal/util/Logger';
import { useStyles } from './useStyles';
import useTheme from '../../hooks/useTheme';
import { isAction } from '@commandbar/internal/middleware/helpers/actions';

interface Props {
  docs: HelpHubDoc[];
  setCurrentDoc: (doc: HelpHubDoc) => void;
  isLoading: boolean;
  showContinuations: boolean;
  setSelectedContinuation: (continuation: string) => void;
  liveAnswer?: IInstantAnswerType | null;
  chatID?: string;
  history?: ChatMessage[];
}

const LiveAnswerCard = (props: Props) => {
  const { engine } = useStore();
  const { theme } = useTheme();
  const { isLoading, liveAnswer, history, chatID } = props;
  const [continuations, setContinuations] = React.useState<string[]>([]);
  const [loadingDotCount, setLoadingDotCount] = React.useState(0);
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  const assignContinuations = useAction(Engine.setContinuations);

  const executeAction = useAction(
    (_, action: LabeledAction['action'], toggleHelpHub: boolean, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      Engine.executeAction(_, action, e);

      if (toggleHelpHub) {
        toggleHelpHubVisible();
      }
    },
  );

  const isInitialBotMessage = () => history?.length === 1 && history[0].type === 'bot';

  useEffect(() => {
    if (isInitialBotMessage()) {
      setContinuations(engine.helpHub.continuations[DEFAULT_SUGGESTIONS_KEY] || []);
    }
  }, [engine.helpHub.continuations]);

  useEffect(() => {
    const hasAnswerAndHistory = !!liveAnswer && !!history && !!chatID;
    const hasPassageOrCommandId = liveAnswer?.passage_id !== null || liveAnswer.command_id !== null;

    if (
      !isInitialBotMessage() &&
      !!engine.organization &&
      hasAnswerAndHistory &&
      hasPassageOrCommandId &&
      engine.organization.helphub_continuations_enabled
    ) {
      if (!!engine.helpHub.continuations[liveAnswer.answer]) {
        setContinuations(engine.helpHub.continuations[liveAnswer.answer].slice(0, 2));
        return;
      }

      fetchContinuations(
        engine.organization,
        chatID,
        history,
        liveAnswer.passage_id !== null ? [liveAnswer.passage_id] : [],
        liveAnswer.command_id !== null ? [liveAnswer.command_id] : [],
      ).then((continuations) => {
        const newContinuations = continuations?.filter((c) => {
          return !Object.values(engine.helpHub.continuations).some((existing) => existing.includes(c));
        });

        assignContinuations(liveAnswer.answer, newContinuations ? newContinuations : []);
        setContinuations(newContinuations ? newContinuations.slice(0, 2) : []);
      });
    }
  }, [liveAnswer]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingDotCount((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 450);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const lastMessage = history && !!history.length && history[history.length - 1].message;
  const noAnswer =
    history && !!history.length && lastMessage && typeof lastMessage !== 'string' && lastMessage?.no_answer;
  const hasFallbackAction = engine.organization?.helphub_chat_fallback_actions.some(
    (action) => action.cta && action.action,
  );
  const shouldShowFallbackActions = noAnswer && hasFallbackAction;
  const state: 'loading' | 'answer' | 'no-answer' | 'none' = (() => {
    if (isLoading) {
      return 'loading';
    } else if (liveAnswer?.answer === NO_ANSWER.answer) {
      return 'no-answer';
    } else if (!!liveAnswer && !!liveAnswer.answer) {
      return 'answer';
    }
    return 'none';
  })();

  if (state === 'none') {
    return null;
  }

  const nextSteps = props?.liveAnswer?.doc?.command.next_steps || [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0 16px',
        gap: '8px',
        marginBottom: '16px',
      }}
    >
      {engine.chatAvatar ? (
        <Icon
          icon={engine.chatAvatar}
          useDefaultSVGColor={true}
          size={'40px'}
          style={{ width: '40px', height: '40px', flexShrink: 0 }}
        />
      ) : (
        <AIProfileIcon />
      )}
      <div style={{ maxWidth: '100%', minWidth: 0, ...(state === 'loading' && { minHeight: 200 }) }}>
        <Card>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={state === 'answer' || (state === 'loading' && Boolean(props.liveAnswer?.answer))}
          >
            <ContentContainer style={{ padding: '0' }}>
              <LiveAnswer {...props} />
            </ContentContainer>
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={state === 'loading' && !Boolean(props.liveAnswer?.answer)}
          >
            <ContentContainer>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: index + 1 <= loadingDotCount ? '#7A7A85' : '#DFDFE2',
                      transition: `background ${theme.main.transitionTime} ease-in-out`,
                    }}
                  />
                ))}
              </div>
            </ContentContainer>
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={state === 'no-answer'}
          >
            <ContentContainer>
              <div
                style={{
                  fontFamily: theme.helpHub.fontFamily,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#42424D',
                }}
              >
                We couldn't find the answer to your question. Please try again later.
              </div>
            </ContentContainer>
          </AnimationTransition>
        </Card>

        {shouldShowFallbackActions && (
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={shouldShowFallbackActions}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '0px',
                alignItems: 'flex-start',
                marginTop: '8px',
              }}
            >
              {engine.organization?.helphub_chat_fallback_actions.map(({ cta, action }, index) => (
                <ActionButton
                  isAction={true}
                  key={index}
                  title={cta}
                  onClick={(e?: React.MouseEvent<HTMLDivElement>) => {
                    executeAction(action, true, e);
                  }}
                />
              ))}
            </div>
          </AnimationTransition>
        )}

        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={continuations.length > 0 || nextSteps.length > 0}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '0px',
              alignItems: 'flex-start',
              marginTop: '8px',
            }}
          >
            {nextSteps.map(
              (nextStep, index) =>
                !!nextStep &&
                isAction(nextStep) &&
                !!nextStep.cta && (
                  <ActionButton
                    title={nextStep.cta}
                    isAction={true}
                    key={index}
                    onClick={(e?: React.MouseEvent<HTMLDivElement>) => {
                      executeAction(nextStep.action, false, e);
                    }}
                  />
                ),
            )}
            {props.showContinuations &&
              continuations.slice(0, Math.max(0, 2 - nextSteps.length)).map(
                (continuation, index) =>
                  !!continuation && (
                    <ActionButton
                      title={continuation}
                      key={index}
                      onClick={() => {
                        if (isLoading) return;
                        props.setSelectedContinuation(continuation);
                      }}
                    />
                  ),
              )}
          </div>
        </AnimationTransition>
      </div>
    </div>
  );
};

const Card = (props: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        position: 'relative',
        background:
          'radial-gradient(91.67% 445.85% at 95.22% 2.16%, #21B6E6 0%, #8933DF 31.63%, #0038FF 45.64%, #AFAFAF 100%)',
        boxShadow: '0px 0px 11px rgba(0, 164, 255, 0.25)',
        padding: '1px',
        borderRadius: '0px 16px 16px 16px',
        flex: 1,
      }}
    >
      <div
        style={{
          position: 'relative',
          background: '#FFFFFF',
          width: '100%',
          height: '100%',
          borderRadius: '0px 16px 16px 16px',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

const ContentContainer = (props: { children: React.ReactNode; style?: React.CSSProperties }) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '8px 16px',
        minHeight: '36px',
        flexDirection: 'column',
        justifyContent: 'center',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

const LiveAnswer = ({ liveAnswer, setCurrentDoc, history }: Props) => {
  const { engine } = useStore();
  const { theme } = useTheme();

  const markFeedbackGiven = useAction(Engine.setFeedbackGiven);

  const handleCodeCopyClick = (parent: Element) => async () => {
    const { children } = parent;
    const { innerText } = Array.from(children)[0] as HTMLElement;
    try {
      await navigator.clipboard.writeText(innerText);
    } catch (err) {
      Logger.error('Failed to copy text to clipboard:', err);
    }
  };

  useEffect(() => {
    const highlights = document.querySelectorAll('div.codehilite');

    highlights.forEach((div) => {
      // create the copy button
      const copy = document.createElement('button');
      copy.innerHTML = 'Copy';
      // add the event listener to each click
      copy.addEventListener('click', handleCodeCopyClick(div));
      // append the copy button to each code block
      div.append(copy);
    });
  }, [liveAnswer]);

  const showFooterDiv =
    (!!liveAnswer?.doc?.title && engine.helpHub?.hubDoc?.command?.id !== liveAnswer?.doc.command.id) ||
    (!!history &&
      liveAnswer?.message_id &&
      !Object.keys(engine.helpHub.gaveFeedback).includes(liveAnswer.message_id.toString()));

  const sourceUrl =
    liveAnswer?.doc?.command.template.type === 'helpdoc' && !!liveAnswer.doc.command.template.value
      ? liveAnswer.doc.command.template.value
      : null;

  const shouldShowSource = liveAnswer?.doc?.command.training_only ? !!sourceUrl : true;

  return (
    <Fragment>
      <div
        style={{
          fontFamily: theme.helpHub.fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#42424D',
          borderRadius: '0px 16px 16px 16px',
          padding: '8px 16px',
        }}
        css={css`
          & p {
            margin: 0 0 16px 0;
          }
          & p:last-child {
            margin: 0;
          }
          & pre {
            margin: 0;
          }
          & .codehilite {
            margin: 0 0 16px 0;
            overflow: auto;
            position: relative;
          }
          & ol {
            -12px;
          }
          & ul {
            -12px;
          }
          & .codehilite button {
            color: white;
            box-sizing: border-box;
            transition: ${theme.main.transitionTime} ease-out;
            cursor: pointer;
            user-select: none;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(0, 0, 0, 0);
            padding: 4px 8px;
            position: absolute;
            top: 3px;
            right: 3px;
            display: none;
            border-radius: 4px;
          }

          & .codehilite button {
            transition: all ${theme.main.transitionTime};
          }
          & .codehilite:hover button {
            display: block;
          }


        `}
        dangerouslySetInnerHTML={{ __html: liveAnswer?.answer || '' }}
      ></div>

      {showFooterDiv && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '4px',
          }}
        >
          {!!liveAnswer?.doc?.title && engine.helpHub?.hubDoc?.command?.id !== liveAnswer?.doc.command.id && (
            <div
              style={{
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px 8px 16px',
                gap: '4px',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              {shouldShowSource && (
                <Fragment>
                  <InfoCircle height={14} color="#7A7A85" />
                  <a
                    href={sourceUrl || '#'}
                    style={{
                      color: '#7A7A85',
                      fontWeight: 500,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={(e) => {
                      if (!liveAnswer?.doc) return;
                      e.preventDefault();

                      if (liveAnswer.doc.command.training_only) {
                        if (sourceUrl) window.open(sourceUrl, '_blank');
                        return;
                      }

                      if ((e.metaKey || e.ctrlKey) && sourceUrl) {
                        window.open(sourceUrl, '_blank');
                      } else {
                        setCurrentDoc(draftDocToFullDoc(liveAnswer.doc));
                      }
                    }}
                  >
                    {liveAnswer.doc.title}
                  </a>
                </Fragment>
              )}
            </div>
          )}

          {!!history &&
            history[history.length - 1].type === 'bot' &&
            liveAnswer?.message_id &&
            !Object.keys(engine.helpHub.gaveFeedback).includes(liveAnswer.message_id.toString()) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '8px',
                  marginLeft: 'auto',
                  padding: '8px 12px',
                  height: '48px',
                  background: '#F7F7F8',
                  width: '100%',
                  borderRadius: '0px 0 16px 16px',
                }}
              >
                <FeedbackButton
                  onClick={() => {
                    markFeedbackGiven(liveAnswer, -1);
                  }}
                >
                  <ThumbsDown height={16} />
                </FeedbackButton>
                <FeedbackButton
                  onClick={() => {
                    markFeedbackGiven(liveAnswer, 1);
                  }}
                >
                  <ThumbsUp height={16} />
                </FeedbackButton>
              </div>
            )}
        </div>
      )}
    </Fragment>
  );
};

const ActionButton = (props: { title: string; onClick: () => void; isAction?: boolean }) => {
  const styles = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <span
      role="button"
      tabIndex={0}
      style={{ ...styles.continuation, ...(props.isAction && { background: '#0A0A0F', color: '#ffffff' }) }}
      onClick={props.onClick}
    >
      {props.title}
    </span>
  );
};

const FeedbackButton = (props: { children: React.ReactNode; onClick: () => void }) => {
  const styles: React.CSSProperties = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
    gap: '16px',
    width: '40px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid rgba(10, 10, 15, 0.24)',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)',
    borderRadius: '4px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    cursor: 'pointer',
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <span role="button" tabIndex={0} style={styles} onClick={props.onClick}>
      {props.children}
    </span>
  );
};

export default LiveAnswerCard;
