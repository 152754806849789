import React from 'react';
import { CommandOption } from '../../engine/option';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import Tooltip from '../Tooltip';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import EditableHotkeyTag from '../hotkeys/EditableHotkeyTag';
import * as Command from '@commandbar/internal/middleware/command';
import { useStore } from '../../hooks/useStore';
import { isUnfurledCommandOption } from '../../store/engine';
import { optionIsInGrid } from '../../store/app';

export interface Props {
  option: CommandOption;
  isFocused: boolean;
  hasNextIcon: boolean;
}

export const CommandOptionShortcut = ({ option, isFocused, hasNextIcon }: Props) => {
  const theme = useCommandBarTheme();
  const _ = useStore();
  const { engine } = _;

  if (isUnfurledCommandOption(option)) return null;

  let hotkey = '';
  let defaultHotkey = '';
  const slug = Command.commandUID(option.command);

  if (engine.endUserStore.data.hotkeys.hasOwnProperty(slug)) {
    hotkey = engine.endUserStore.data.hotkeys[slug];
  } else if (['mac'].includes(engine.platform)) {
    hotkey = option.command.hotkey_mac;
  } else if (['windows', 'linux'].includes(engine.platform)) {
    hotkey = option.command.hotkey_win;
  }

  if (['mac'].includes(engine.platform)) {
    defaultHotkey = option.command.hotkey_mac;
  } else if (['windows', 'linux'].includes(engine.platform)) {
    defaultHotkey = option.command.hotkey_win;
  }

  const highlightShortcut = () => {
    if (['command.hotkey_mac', 'command.hotkey_win'].includes(option.searchMatches[0]?.path)) {
      const matchedIndices = option.searchMatches[0]?.indices[0];
      const isValidPair = !!matchedIndices && matchedIndices.length === 2;
      const pairLength = (pair: number[]) => pair[1] - pair[0] + 1;
      const SHORTCUT_LENGTH_TO_HIGHLIGHT = 2;
      if (isValidPair && pairLength(matchedIndices) >= SHORTCUT_LENGTH_TO_HIGHLIGHT) {
        return true;
      }
    }
    return false;
  };

  const highlight = highlightShortcut();

  const shortcut =
    !optionIsInGrid(_, option) && !isMobile() ? (
      <EditableHotkeyTag
        key={`editable-${Command.commandUID(option.command)}`}
        combo={hotkey}
        defaultCombo={defaultHotkey}
        highlight={highlight}
        slug={Command.commandUID(option.command)}
        isFocused={isFocused}
        hasNextIcon={hasNextIcon}
      />
    ) : null;

  return highlight ? (
    <Tooltip
      overlay={
        <span style={{ fontFamily: theme.main.fontFamily }}>You can use this hotkey when the bar is closed.</span>
      }
      placement="right"
    >
      <div>{shortcut}</div>
    </Tooltip>
  ) : (
    shortcut
  );
};
