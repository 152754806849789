import React, { Fragment, useEffect } from 'react';

export const Accordion = (props: {
  items: { header: (isOpen?: boolean) => React.ReactNode; children: React.ReactNode }[];
  defaultOpenIdx: number;
  divider?: React.ReactNode;
  onExpandedItemChange?: (idx: number) => void;
  editorTimeStamp?: number;
  containerStyles?: React.CSSProperties;
}) => {
  const { items, divider, defaultOpenIdx, onExpandedItemChange, editorTimeStamp, containerStyles } = props;

  React.useEffect(() => {
    setOpenKeys(newArr(defaultOpenIdx));
  }, [defaultOpenIdx, editorTimeStamp]);

  const isValidIdx = (idx: number) => idx >= 0 && idx < items.length;

  const newArr = (openIdx?: number) => {
    const arr = new Array(items.length).fill(false);
    if (typeof openIdx === 'number' && isValidIdx(openIdx)) arr[openIdx] = true;
    return arr;
  };

  const [openKeys, setOpenKeys] = React.useState(() => newArr(defaultOpenIdx));

  const onOpenChange = (open: boolean, idx: number) => {
    if (open) {
      setOpenKeys(newArr(idx));

      if (!!onExpandedItemChange) {
        onExpandedItemChange(idx);
      }
    }
  };

  return (
    <div style={containerStyles}>
      {items.map(({ header, children }, idx) => (
        <Fragment key={idx}>
          <Collapse
            header={header}
            onChange={(open) => onOpenChange(open, idx)}
            isControlled={true}
            open={openKeys[idx]}
          >
            {children}
          </Collapse>
          {divider}
        </Fragment>
      ))}
    </div>
  );
};

export const Collapse = (props: {
  header: (isOpen?: boolean) => React.ReactNode;
  footer?: React.ReactNode;
  defaultOpen?: boolean;
  children: React.ReactNode;
  onChange?: (open: boolean) => void;
  open?: boolean;
  isControlled?: boolean;
  editorTimeStamp?: number;
}) => {
  const [isOpen, setIsOpen] = React.useState(props.open || props.defaultOpen || false);
  const [height, setHeight] = React.useState<number | undefined>(0);
  const panelRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.open !== undefined) setIsOpen(props.open);
  }, [props.open, props.editorTimeStamp]);

  useEffect(() => {
    if (isOpen) {
      if (!panelRef.current) return undefined;
      const resizeObserver = new ResizeObserver((el) => {
        setHeight(el[0].contentRect.height);
      });
      resizeObserver.observe(panelRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const isControlled = !!props.isControlled && props.open !== undefined;

  return (
    <div style={{ width: '100%' }}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          !isControlled && setIsOpen(!isOpen);
          if (props.onChange) props.onChange(!isOpen);
        }}
        style={{ cursor: 'pointer' }}
      >
        {props.header(isOpen)}
      </div>
      <div style={{ height, transition: 'height 0.3s  cubic-bezier(0.04, 0.89, .5, 1.0)', overflow: 'hidden' }}>
        <div ref={panelRef}>{props.children}</div>
      </div>
      {isOpen && props.footer}
    </div>
  );
};
