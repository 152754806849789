import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { useEffect } from 'react';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import useElementObserver from './useElementObserver';

const CustomHelpHubLauncher = ({ organization }: { organization: IOrganizationType }) => {
  const element = useElementObserver(organization.helphub_launcher_anchor);
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  useEffect(() => {
    if (element) {
      element.addEventListener('click', toggleHelpHubVisible);
      return () => {
        element.removeEventListener('click', toggleHelpHubVisible);
      };
    }
  }, [element, toggleHelpHubVisible]);

  return null;
};

export default CustomHelpHubLauncher;
