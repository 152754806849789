/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */

import { useEffect } from 'react';
import { initSDK } from '../client_api/sdk';
import { useMutableState } from '../hooks/useMutableState';

const useCommandBarSDK = () => {
  const _ = useMutableState();

  useEffect(() => {
    initSDK(_);
  }, []);
};

export default useCommandBarSDK;
