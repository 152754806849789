import React from 'react';
import { ParameterOption } from '../../engine/option';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import DateTime from '../../engine/predefinedTypes/DateTime/DateTime';
import { isDateTimeOption } from '../../store/engine';

export interface Props {
  option: ParameterOption;
}

export const ParameterOptionAddOn = ({ option }: Props) => {
  const theme = useCommandBarTheme();

  if (isDateTimeOption(option)) {
    return (
      <div
        style={{
          fontSize: parseInt(theme.option.fontSize, 10) - 2,
          fontFamily: 'monospace',
          color: theme.option.color,
        }}
      >
        {DateTime.display(option.parameter.date, option.parameter.specificType)}
      </div>
    );
  }
  return null;
};
