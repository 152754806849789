import * as t from 'io-ts';
import { RuleExpressionV } from './rules';

export const CommandGoal = t.type({
  type: t.literal('command_executed'),
  meta: t.type({
    command: t.string,
  }),
});

export const PageVisitedGoal = t.type({
  type: t.literal('page_visited'),
  value: t.string,
});

export const ElementClickedGoal = t.type({
  type: t.literal('element_clicked'),
  value: t.string,
});

export const ConditionsMetGoal = t.type({
  type: t.literal('conditions_met'),
  expression: RuleExpressionV,
});

export const CTAClickedGoal = t.type({
  type: t.literal('cta_clicked'),
});

export const EventTrackedGoal = t.type({
  type: t.literal('event_tracked'),
  event: t.string,
});
