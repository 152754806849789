import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import partition from 'lodash/partition';
import { Option } from '../../../engine/option';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import {
  compareOptionGroups,
  getGroupOfOption,
  getRecordGroupKey,
  incrementOptionGroupSize,
  initOptionGroupFromCommandCategory,
  initOptionGroupFromRecordCategory,
  OptionGroup,
  recentsGroup,
  recommendedGroup,
  shouldEnforceOptionGroupLimit,
} from '../../../engine/OptionGroup';
import ExecutionPath from '../../../engine/ExecutionPath';
import { EngineState } from '../state';
import { Step } from '../../../engine/step';
import { StepType } from '../../../engine/step/Step';
import { getContextSettings, isParameterOption, isParameterOptionSelected, isCommandOption } from '..';
import { State } from '../..';
import { ISearchFilter } from '../../app';
import { getOptionUID, setOptionGroupKey } from '../options/helpers';

type INestedOptions = Record<
  string, // group key (for faster indexing)
  {
    group: OptionGroup;
    list: Option[];
    searchScore: number;
  }
>;

const nestOptionsByGroup = (
  options: Option[],
  categories: ICommandCategoryType[],
  engine: EngineState['engine'],
  showRecommendedGroup: boolean,
): INestedOptions => {
  const nestedOptions: INestedOptions = {};

  // loop through options and append to respective group
  options.forEach((o: Option) => {
    const groupInstance = getGroupOfOption(o, categories, engine, showRecommendedGroup);
    setOptionGroupKey(o, groupInstance.key);

    if (groupInstance.key in nestedOptions) {
      const { group, list, searchScore } = nestedOptions[groupInstance.key];
      list.push(o);
      nestedOptions[groupInstance.key].group = incrementOptionGroupSize(group);
      if (searchScore === Number.NEGATIVE_INFINITY && o.searchScore !== undefined) {
        nestedOptions[group.key].searchScore = o.searchScore;
      }
    } else {
      nestedOptions[groupInstance.key] = {
        group: { ...groupInstance, size: 1 },
        list: [o],
        searchScore: o.searchScore ?? Number.NEGATIVE_INFINITY,
      };
    }
  });

  return nestedOptions;
};

const applyPinToBottom = (groups: OptionGroup[]) => {
  const [unpinned, pinned] = partition(groups, (g) => !g.pinnedToBottom);
  return [...unpinned, ...pinned];
};

/**
 * Apply a custom sort within a group
 */

const reorderSelectedToBeginning = (options: Option[], currentStep: Step) => {
  return [...options].sort((a: Option, b: Option) => {
    if (!isParameterOption(a) || !isParameterOption(b)) return 0;
    const isASelected = isParameterOptionSelected(a, currentStep);
    const isBSelected = isParameterOptionSelected(b, currentStep);
    return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
  });
};

const applyOptionSortOverrides = (
  nestedOptions: INestedOptions,
  currentStep: Step | undefined,
  recentUIDs: string[],
) => {
  Object.keys(nestedOptions).forEach((key: string) => {
    const { group, list } = nestedOptions[key];
    const compareFunction = group.sortFunction;
    if (!!compareFunction) {
      const newList = [...list].sort((a: Option, b: Option) => {
        if (!isParameterOption(a) || !isParameterOption(b)) return 0;
        return compareFunction(
          { value: a.parameter, searchMatches: a.searchMatches },
          { value: b.parameter, searchMatches: b.searchMatches },
        );
      });
      nestedOptions[key].list = newList;
    } else if (group.key === recommendedGroup().key) {
      // Order recommended group by recommend_sort_key
      const newList = [...list].sort((a: Option, b: Option) => {
        if (!isCommandOption(a) || !isCommandOption(b)) return 0;
        return compareObjs(
          { sort_key: a.command.recommend_sort_key, id: 0 },
          { sort_key: b.command.recommend_sort_key, id: 0 },
        );
      });
      nestedOptions[key].list = newList;
    } else if (currentStep?.selected?.data && currentStep.type === StepType.MultiSelect) {
      // FIXME: This is currently turned off (currentStep === undefined)
      // Need to revisit why we turned it off
      const newList = reorderSelectedToBeginning(list, currentStep);
      nestedOptions[key].list = newList;
    } else if (group.key === recentsGroup().key) {
      const newList = [...list].sort((a: Option, b: Option) => {
        const indexA = recentUIDs.findIndex((v) => v === getOptionUID(a));
        const indexB = recentUIDs.findIndex((v) => v === getOptionUID(b));
        const sortKeyA = indexA > -1 ? indexA : 0;
        const sortKeyB = indexB > -1 ? indexB : 0;
        return compareObjs({ sort_key: sortKeyA, id: 0 }, { sort_key: sortKeyB, id: 0 });
      });
      nestedOptions[key].list = newList;
    }
  });
};

const applyGroupLimits = (nestedOptions: INestedOptions, expandedGroupKeys: string[]) => {
  Object.keys(nestedOptions).forEach((key: string) => {
    const { group, list } = nestedOptions[key];
    if (group.limit && shouldEnforceOptionGroupLimit(group, expandedGroupKeys)) {
      const truncatedList = list.slice(0, group.limit);
      nestedOptions[key].list = truncatedList;
    }
  });
};

const flattenToFinalList = (
  nestedOptions: INestedOptions,
  isEmptyState: boolean,
  includeGroupsWithNoResults: boolean,
) => {
  const sortedGroups: OptionGroup[] = (() => {
    if (isEmptyState) {
      // Sort by default order
      return Object.values(nestedOptions)
        .sort((a, b) => compareOptionGroups(a.group, b.group))
        .map(({ group }) => group);
    } else {
      // Sort by search order. Group with best search result goes to top.
      // Break ties with default order. Re-apply pin to bottom
      return applyPinToBottom(
        Object.values(nestedOptions)
          .sort((a, b) => b.searchScore - a.searchScore || compareOptionGroups(a.group, b.group))
          .map(({ group }) => group),
      );
    }
  })();

  return sortedGroups.reduce((acc: (Option | OptionGroup)[], g: OptionGroup) => {
    const { group, list } = nestedOptions[g.key];
    if (list.length > 0) {
      return [...acc, group, ...list];
    } else if (includeGroupsWithNoResults && g.showWithNoResults) {
      return [...acc, g];
    } else {
      return acc;
    }
  }, []);
};

const includeGroupsWithNoResults = (
  step: Step | undefined,
  inputText: string,
  numOptions: number,
  searchFilter: ISearchFilter | undefined,
) => {
  // only show in the default step
  const isDefaultStep = step?.type === StepType.Base && step.resource === null;
  if (!isDefaultStep) return false;

  // don't show if there's an active search filter
  if (!!searchFilter) return false;

  // only show during search
  if (inputText.length === 0) return false;

  // don't block the no results state
  if (numOptions === 0) return false;
  return true;
};

const addGroupsWithNoResults = (nestedOptions: INestedOptions, _: State) => {
  // populate with defaults. We need to do this to make sure that "No results" categories are represented
  const { engine } = _;
  const commandCategories = engine.categories.map((c) => initOptionGroupFromCommandCategory(c, _.engine));
  const recordCategories = Object.entries(getContextSettings(_.engine)).map(([k, v]) =>
    initOptionGroupFromRecordCategory(k, _.engine, v),
  );
  const groupsToShowWithNoResults = [...commandCategories, ...recordCategories].filter(
    (g: OptionGroup) => !!g.showWithNoResults,
  );

  const recentsGroupKey = recentsGroup().key;
  const recentsContextKeys = new Set();

  if (recentsGroupKey in nestedOptions) {
    nestedOptions[recentsGroupKey].list.forEach((option) => {
      if (!!option.category.contextKey) {
        recentsContextKeys.add(option.category.contextKey);
      }
    });
  }

  groupsToShowWithNoResults.forEach((g) => {
    const groupContextKey = getRecordGroupKey(g);
    if (!(g.key in nestedOptions) && !recentsContextKeys.has(groupContextKey)) {
      nestedOptions[g.key] = { group: g, list: [], searchScore: Number.NEGATIVE_INFINITY };
    }
  });
};

export const sortOptionsAndInsertGroups = (options: Option[], _: State): (OptionGroup | Option)[] => {
  const isEmptyState = _.engine.inputText.length === 0;
  const showRecommendedGroup = isEmptyState && !_.searchFilter;
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  // 1. nest options by group
  const nestedOptions = nestOptionsByGroup(options, _.engine.categories, _.engine, showRecommendedGroup);
  // 2. re-sort options within group if there is a custom sort override
  applyOptionSortOverrides(nestedOptions, undefined, _.engine.endUserStore.derived.recentUIDs);
  // 3. Enforce group limits
  applyGroupLimits(nestedOptions, _.engine.expandedGroupKeys);
  // 4. Add in groups that have `showWithNoResults` turned on
  addGroupsWithNoResults(nestedOptions, _);
  // 5. flatten the nested object and insert groups into the list
  return flattenToFinalList(
    nestedOptions,
    isEmptyState,
    includeGroupsWithNoResults(currentStep, _.engine.inputText, options.length, _.searchFilter),
  );
};
