import { ResourceOption } from '../../../engine/option';

export const getDefaultCommandId = (o: ResourceOption) => {
  return o.searchOptions?.default_command_id;
};

export const getRecordCategoryIcon = (o: ResourceOption): { icon: string | null; icon_color: string | null } => {
  return { icon: o.searchOptions?.icon || null, icon_color: o.searchOptions?.icon_color || null };
};

export const getRecordCategoryImage = (o: ResourceOption): { image: string | null; image_color: string | null } => {
  return { image: o.searchOptions?.image || null, image_color: o.searchOptions?.image_color || null };
};
