import React, { CSSProperties } from 'react';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { Option } from '../../engine/option';
import truncate from 'lodash/truncate';
import { useStore } from '../../hooks/useStore';
import { optionIsInGrid } from '../../store/app';

export interface Props {
  emphasize?: boolean;
  labelToHighlight: string;
  option: Option;
  text: string | React.ReactElement;
}

/**
 * @param text Text string
 * @param bestMatchStartIndex Start index of the best match
 * @returns Start index of the truncated text to display
 */
const getStringStartIndex = (text: string, bestMatchStartIndex: number): number => {
  // If the best match is within first 20 indices of the string, return 0
  if (bestMatchStartIndex <= 20) {
    return 0;
  }

  // Try from bestMathStartIndex - 10
  let stringStartIndex = bestMatchStartIndex - 10;

  // Move forward until either we reach the start of a new word or the bestMatchStartIndex
  while (text[stringStartIndex - 1] !== ' ' && stringStartIndex !== bestMatchStartIndex) {
    stringStartIndex++;
  }

  return stringStartIndex;
};

const getTruncatedText = (text: string, length: number, isGridOption: boolean) => {
  return isGridOption ? truncate(text, { length, omission: '...' }) : text;
};

export const OptionHighlight = ({ emphasize = false, labelToHighlight, option, text }: Props) => {
  const theme = useCommandBarTheme();

  const isGridOption = optionIsInGrid(useStore(), option);

  const maxTextLength = isGridOption ? 80 : 100;

  if (typeof text !== 'string') {
    return text || null;
  }
  if (!option.searchMatches[0] || option.searchMatches[0].path !== labelToHighlight)
    // FIXME: We might be able to clean this up with CSS; text-overflow: ellipsis
    // https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow
    return <span>{getTruncatedText(text, maxTextLength, !!isGridOption)}</span>;
  const pairLength = (pair: number[]) => pair[1] - pair[0] + 1;
  const findBestPair = (indices: number[][]) => {
    // fuse doesn't provide search scores for pairs, but the recommended way is to use the longest match
    // https://github.com/krisk/Fuse/issues/409
    let longestMatch = undefined;
    for (const pair of indices) {
      if (pair.length > 0) {
        if (!longestMatch) longestMatch = pair;
        if (pairLength(pair) > pairLength(longestMatch)) longestMatch = pair;
      }
    }
    if (longestMatch) {
      return { start: longestMatch[0], end: longestMatch[1] };
    }
    return null;
  };

  const highlightStyle: CSSProperties = {
    color: 'inherit',
    textDecoration: theme.textSearchMatch.textDecoration,
    textUnderlinePosition: emphasize
      ? theme.textSearchMatch.emphasisTextUnderlinePosition
      : theme.textSearchMatch.textUnderlinePosition,
    fontWeight: emphasize ? theme.textSearchMatch.emphasisFontWeight : theme.textSearchMatch.fontWeight,
  };

  // workaround for issue with Safari not supporting the "text-decoration" shorthand,
  // see https://obyford.com/posts/text-decoration/ and https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration
  (highlightStyle as any)['WebkitTextDecoration'] = theme.textSearchMatch.textDecoration;

  const nodes = [];
  // FIXME: magic # for the long string length
  if (text.length < maxTextLength) {
    // Short strings -- show multiple matches
    let start = 0;
    const MIN_LENGTH_TO_HIGHLIGHT = 2;
    for (const pair of option.searchMatches[0].indices) {
      if (pairLength(pair) >= MIN_LENGTH_TO_HIGHLIGHT) {
        nodes.push(text.substring(start, pair[0]));
        nodes.push(
          <span key={`search-match-${start}-${labelToHighlight}`} style={highlightStyle}>
            {text.substring(pair[0], pair[1] + 1)}
          </span>,
        );
        start = pair[1] + 1;
      }
    }
    nodes.push(text.substring(start, text.length));
  } else {
    // Long strings -- Only show the longest match
    const bestMatch: { start: number; end: number } | false | null = findBestPair(option.searchMatches[0].indices);
    if (!bestMatch) return <span>{getTruncatedText(text, maxTextLength, !!isGridOption)}</span>;
    const stringStartIndex = getStringStartIndex(text, bestMatch.start);

    nodes.push(stringStartIndex > 0 && '...');
    nodes.push(text.substring(stringStartIndex, bestMatch.start));
    nodes.push(
      <span key={`search-match-${bestMatch.start}-${labelToHighlight}`} style={highlightStyle}>
        {text.substring(bestMatch.start, bestMatch.end)}
      </span>,
    );

    const lengthRemaining = bestMatch.end - stringStartIndex;

    nodes.push(
      getTruncatedText(text.substring(bestMatch.end, text.length), maxTextLength - lengthRemaining, !!isGridOption),
    );
  }

  return <span style={{ color: 'inherit' }}>{nodes}</span>;
};
