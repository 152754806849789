import { ITheme } from '@commandbar/internal/client/theme';
import React from 'react';
import { AiOutlineClose, AiOutlineFilter } from 'react-icons/ai';
import { useStore } from '../../../hooks/useStore';
import { useTheme } from 'emotion-theming';
import { useAction } from '../../../hooks/useAction';
import { blockBackgroundStyle } from './Block';
import { selectSearchTabs } from '../../../store/engine/options/selectors';

const SearchFilterInputTag = () => {
  const clearSearchFilter = useAction((_) => {
    _.searchFilter = undefined;
  });
  const { searchFilter } = useStore();
  const { theme }: { theme: ITheme } = useTheme();

  const _ = useStore();

  if (!searchFilter || !searchFilter.inputTag || !!selectSearchTabs(_).find((v) => v.key === searchFilter?.slug))
    return null;

  const maxInputTagWidth = parseInt(theme.bar.width, 10) / 3;

  return (
    <div
      css={blockBackgroundStyle}
      style={{
        border: `1px solid ${theme.base.primary}`,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        fontFamily: theme.main.fontFamily,
        color: theme.base.fontColor,
        fontSize: theme.base.fontSize,
        padding: 4,
      }}
    >
      <AiOutlineFilter style={{ marginRight: 3 }} />
      <div style={{ maxWidth: maxInputTagWidth, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {searchFilter.inputTag}
      </div>
      <AiOutlineClose style={{ cursor: 'pointer', fontSize: 11, marginLeft: 4 }} onClick={clearSearchFilter} />
    </div>
  );
};

export default SearchFilterInputTag;
