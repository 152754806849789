/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { jsx } from '@emotion/core';

import LauncherWidget from './LauncherWidget';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { useStore } from '../../hooks/useStore';
import { useAction } from '../../hooks/useAction';
import * as App from '../../store/app/actions';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const LauncherContainer = () => {
  const onLauncherClick = useAction(App.toggleBarFromLauncher);
  const { isAdmin, organization, visible } = useStore().engine;
  React.useEffect(() => {
    dispatchCustomEvent('commandbar-launcher-ready', {});
  }, []);

  /********************* Start text handling **********************/

  if (!organization || organization?.launcher_type === 'none' || (isMobile() && (window._cbIsWebView || visible))) {
    return <div />;
  }

  return (
    <span>
      <LauncherWidget onClick={onLauncherClick} isAdmin={isAdmin} organization={organization} />
    </span>
  );
};

export default LauncherContainer;
