/** @jsx jsx */
import { jsx } from '@emotion/core';
import HtmlPreview from './detailpreview/HtmlPreview';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { CSSProperties } from 'react';

export interface HtmlDashboardProps {
  source: string;
  style?: CSSProperties;
}

const HtmlDashboard = ({ source, style }: HtmlDashboardProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  return (
    <div
      style={{
        height: theme.bar.menuHeight,
        overflow: 'auto',
        color: theme.base.fontColor,
        fontSize: '14px',
        lineHeight: 1.7,
        fontFamily: theme.base.fontFamily,
        background: theme.base.background,
        borderBottom: theme.categoryHeader.borderTop,
        textAlign: 'left',
        scrollBehavior: 'smooth',
        padding: '16px 10px 20px',
        ...style,
      }}
    >
      <HtmlPreview value={source} />
    </div>
  );
};

export default HtmlDashboard;
