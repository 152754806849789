import React from 'react';
import Z from '@commandbar/internal/client/Z';
import { toPx } from '@commandbar/internal/client/theme';

interface IResizerProps {
  resizeElement: HTMLDivElement;
  minHeight?: number;
  minWidth?: number;
  setHeight: (height: string) => void;
  setWidth: (width: string) => void;
}
export const Resizer = ({ resizeElement, minHeight = 100, minWidth = 350, setHeight, setWidth }: IResizerProps) => {
  const isResizingTop = React.useRef(false);
  const isResizingLeft = React.useRef(false);
  const originalHeight = React.useRef(449);
  const originalWidth = React.useRef(449);
  const originalY = React.useRef(449);
  const originalX = React.useRef(449);

  const onTopMouseDown = (e: React.MouseEvent) => {
    isResizingTop.current = true;
    originalHeight.current = resizeElement.getBoundingClientRect().height;
    originalY.current = e.clientY;
  };

  const onLeftMouseDown = (e: React.MouseEvent) => {
    isResizingLeft.current = true;
    originalWidth.current = resizeElement.getBoundingClientRect().width;
    originalX.current = e.clientX;
  };

  const onTopLeftMouseDown = (e: React.MouseEvent) => {
    onTopMouseDown(e);
    onLeftMouseDown(e);
  };

  React.useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation();

      const maxHeight = document.body.clientHeight - 100;
      const maxWidth = document.body.clientWidth - 100;

      if (isResizingTop.current) {
        const height = originalHeight.current - (e.clientY - originalY.current);
        if (height > minHeight && height < maxHeight) {
          setHeight(toPx(height));
        }
      }
      if (isResizingLeft.current) {
        const width = originalWidth.current - (e.clientX - originalX.current);
        if (width > minWidth && width < maxWidth) {
          setWidth(toPx(width));
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  React.useEffect(() => {
    const handleMouseUp = () => {
      isResizingTop.current = false;
      isResizingLeft.current = false;
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onMouseDown={onTopMouseDown}
        style={{
          height: '5px',
          cursor: 'ns-resize',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: Z.Z_DRAGGERS,
          backgroundColor: 'transparent',
          margin: '0px 11px',
        }}
      />
      <div
        role="button"
        tabIndex={-1}
        onMouseDown={onLeftMouseDown}
        style={{
          width: '5px',
          cursor: 'ew-resize',
          padding: '4px 0 0',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: Z.Z_DRAGGERS,
          backgroundColor: 'transparent',
          margin: '11px 0px',
        }}
      />
      <div
        role="button"
        tabIndex={-1}
        onMouseDown={onTopLeftMouseDown}
        style={{
          width: '11px',
          height: '11px',
          cursor: 'nwse-resize',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: Z.Z_DRAGGERS,
          backgroundColor: 'transparent',
        }}
      />
    </>
  );
};
