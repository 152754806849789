import React, { CSSProperties } from 'react';

import SVG from '../util/SVG';

import {
  AiFillRightSquare,
  AiFillPlusCircle,
  AiFillMinusCircle,
  AiFillClockCircle,
  AiFillEdit,
  AiFillCopy,
  AiFillDelete,
  AiFillFund,
  AiFillAlert,
  AiFillApi,
  AiFillAppstore,
  AiFillBank,
  AiFillBell,
  AiFillBulb,
  AiFillCalculator,
  AiFillCalendar,
  AiFillCloud,
  AiFillCrown,
  AiFillDashboard,
  AiFillFileAdd,
  AiFillFilter,
  AiFillFire,
  AiFillFlag,
  AiFillFolderOpen,
  AiFillHeart,
  AiFillHome,
  AiOutlineLink,
  AiFillMessage,
  AiFillPushpin,
  AiOutlineRedo,
  AiFillRocket,
  AiFillSave,
  AiFillStar,
  AiFillTool,
  AiFillThunderbolt,
  AiOutlineUndo,
  AiFillPlayCircle,
  AiFillPhone,
  AiFillMail,
  AiOutlineUsergroupAdd,
  AiOutlineUsergroupDelete,
  AiOutlineUserAdd,
  AiOutlineUserDelete,
  AiOutlineUserSwitch,
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineBook,
  AiOutlineLock,
  AiOutlineSwap,
  AiFillCloseCircle,
  AiFillAccountBook,
  AiFillBug,
  AiFillCamera,
  AiFillVideoCamera,
  AiFillBuild,
  AiFillCaretDown,
  AiFillCaretUp,
  AiFillCaretRight,
  AiFillCaretLeft,
  AiFillCheckCircle,
  AiFillCheckSquare,
  AiFillCompass,
  AiFillContacts,
  AiFillLike,
  AiFillDislike,
  AiFillFile,
  AiOutlineFileDone,
  AiFillMoneyCollect,
  AiOutlineQrcode,
  AiFillMobile,
  AiOutlineZoomOut,
  AiOutlineZoomIn,
  AiFillAudio,
  AiFillBackward,
  AiFillBoxPlot,
  AiFillCar,
  AiFillCarryOut,
  AiOutlineCodeSandbox,
  AiFillCode,
  AiFillCodepenCircle,
  AiFillContainer,
  AiFillControl,
  AiFillCreditCard,
  AiFillCustomerService,
  AiFillDatabase,
  AiFillDiff,
  AiFillFunnelPlot,
  AiFillGift,
  AiFillHourglass,
  AiFillIdcard,
  AiFillMacCommand,
  AiFillNotification,
  AiFillSound,
  AiFillSignal,
  AiFillTag,
  AiOutlineQuestion,
  AiFillFileText,
} from 'react-icons/ai';
import { ICommandType, IEditorCommandType } from '../middleware/types';

export const ICONS: any = {
  rightsquare: AiFillRightSquare,
  pluscircle: AiFillPlusCircle,
  minuscircle: AiFillMinusCircle,
  clockcircle: AiFillClockCircle,
  closecircle: AiFillCloseCircle,
  edit: AiFillEdit,
  copy: AiFillCopy,
  crown: AiFillCrown,
  delete: AiFillDelete,
  fund: AiFillFund,
  funnel: AiFillFunnelPlot,
  alert: AiFillAlert,
  api: AiFillApi,
  appstore: AiFillAppstore,
  accountbook: AiFillAccountBook,
  bank: AiFillBank,
  bell: AiFillBell,
  bulb: AiFillBulb,
  calculator: AiFillCalculator,
  calendar: AiFillCalendar,
  cloud: AiFillCloud,
  dashboard: AiFillDashboard,
  fileadd: AiFillFileAdd,
  filter: AiFillFilter,
  fire: AiFillFire,
  flag: AiFillFlag,
  folderopen: AiFillFolderOpen,
  gift: AiFillGift,
  heart: AiFillHeart,
  home: AiFillHome,
  hourglass: AiFillHourglass,
  idcard: AiFillIdcard,
  link: AiOutlineLink,
  mail: AiFillMail,
  maccommand: AiFillMacCommand,
  message: AiFillMessage,
  notification: AiFillNotification,
  play: AiFillPlayCircle,
  phone: AiFillPhone,
  pushpin: AiFillPushpin,
  redo: AiOutlineRedo,
  rocket: AiFillRocket,
  save: AiFillSave,
  signal: AiFillSignal,
  sound: AiFillSound,
  star: AiFillStar,
  tag: AiFillTag,
  tool: AiFillTool,
  thunderbolt: AiFillThunderbolt,
  undo: AiOutlineUndo,
  usergroupadd: AiOutlineUsergroupAdd,
  usergroupdelete: AiOutlineUsergroupDelete,
  useradd: AiOutlineUserAdd,
  userdelete: AiOutlineUserDelete,
  userswitch: AiOutlineUserSwitch,
  user: AiOutlineUser,
  search: AiOutlineSearch,
  book: AiOutlineBook,
  lock: AiOutlineLock,
  swap: AiOutlineSwap,
  bug: AiFillBug,
  camera: AiFillCamera,
  videocamera: AiFillVideoCamera,
  build: AiFillBuild,
  caretdown: AiFillCaretDown,
  caretup: AiFillCaretUp,
  caretright: AiFillCaretRight,
  caretleft: AiFillCaretLeft,
  checkcircle: AiFillCheckCircle,
  checksquare: AiFillCheckSquare,
  compass: AiFillCompass,
  contacts: AiFillContacts,
  like: AiFillLike,
  dislike: AiFillDislike,
  file: AiFillFile,
  filedone: AiOutlineFileDone,
  moneycollect: AiFillMoneyCollect,
  qrcode: AiOutlineQrcode,
  mobile: AiFillMobile,
  zoomin: AiOutlineZoomIn,
  zoomout: AiOutlineZoomOut,
  audio: AiFillAudio,
  backward: AiFillBackward,
  boxplot: AiFillBoxPlot,
  car: AiFillCar,
  carryout: AiFillCarryOut,
  codesandbox: AiOutlineCodeSandbox,
  code: AiFillCode,
  codepencircle: AiFillCodepenCircle,
  container: AiFillContainer,
  control: AiFillControl,
  creditcard: AiFillCreditCard,
  customerservice: AiFillCustomerService,
  database: AiFillDatabase,
  diff: AiFillDiff,
  question: AiOutlineQuestion,
  document: AiFillFileText,
};

interface IProps {
  icon: string | null;
  color?: string | null;
  default?: string | React.ReactNode;
  fill?: boolean;
  style?: CSSProperties;
  useDefaultSVGColor?: boolean;
  allowDefaultSVGColorOverride?: boolean;
  size?: string;
}

export const commandDefault = (command: Pick<ICommandType | IEditorCommandType, 'template'>) => {
  let defaultIcon;
  switch (command.template.type) {
    case 'admin':
      defaultIcon = 'thunderbolt';
      break;
    case 'link':
      defaultIcon = 'link';
      break;
    case 'clickBySelector':
    case 'clickByXpath':
    case 'callback':
    case 'click':
      defaultIcon = 'bulb';
      break;
    case 'appcues':
      defaultIcon = 'question';
      break;
    case 'video':
      defaultIcon = 'play';
      break;
    case 'helpdoc':
      defaultIcon = 'document';
      break;
    default:
      // Used for all node queries
      defaultIcon = 'folderopen';
      break;
  }

  return defaultIcon;
};

const renderAntIcon = (iconKey: string, style: CSSProperties, _filled?: boolean) => {
  const IconComponent = ICONS[iconKey];
  if (IconComponent) return <IconComponent style={style} />;
  else return <div />;
};

type IconType =
  | 'user-defined'
  | 'url'
  | 'svg'
  | 'htmlelement'
  | 'image'
  | 'emoji'
  | 'default-string'
  | 'default-element'
  | 'none';

const getIconType = (icon: string | null, _default?: string | React.ReactNode): IconType => {
  const iconWithoutStyle = icon && icon.includes('#') ? icon.split('#')[0] : icon;
  if (iconWithoutStyle && Object.keys(ICONS).includes(iconWithoutStyle.toLowerCase())) {
    return 'user-defined';
  } else if (icon?.startsWith('https://') || icon?.startsWith('http://')) {
    return 'url';
  } else if (icon?.startsWith('<svg')) {
    return 'svg';
  } else if (icon?.includes('<') && icon?.includes('>')) {
    return 'htmlelement';
  } else if (icon?.startsWith('data:image')) {
    return 'image';
  } else if (icon) {
    return 'emoji';
  } else if (_default && typeof _default === 'string') {
    return 'default-string';
  } else if (_default) {
    return 'default-element';
  }

  return 'none';
};

const hex_regex = /^[0-9a-fA-F]{3,8}$/;

const maybeUrl = (url: string) => {
  try {
    return new URL(url);
  } catch (e) {
    return null;
  }
};

/************************ ICON component ***********************/
const Icon = ({ size = '18px', ...props }: IProps) => {
  let style = { fontSize: size, ...props.style };
  const iconType: IconType = getIconType(props.icon, props.default);

  if (iconType === 'svg' && props.useDefaultSVGColor && !props.allowDefaultSVGColorOverride) {
    style = { ...style, color: undefined };
  }

  switch (iconType) {
    case 'user-defined':
      const iconWithoutStyle = props.icon && props.icon.includes('#') ? props.icon.split('#')[0] : props.icon;
      const deprecatedColor =
        props.icon?.includes('#') && hex_regex.test(props.icon.split('#')[1]) ? props.icon?.split('#')[1] : '';
      const color = props.color ? props.color : deprecatedColor;

      //   User defined icon
      if (color) {
        style = { ...style, color };
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return renderAntIcon(iconWithoutStyle!.toLowerCase(), style, props.fill);
    case 'url':
      const url = props.icon;
      if (url) {
        const formattedURL = maybeUrl(url);
        if (formattedURL) {
          const params = new URLSearchParams(formattedURL?.search);
          const isRound = params.get('round');
          if (isRound) {
            style = { ...style, borderRadius: '50%' };
          }
          return <img src={url} width={size} height={size} alt="" style={{ ...style }} />;
        }
      }

      return <div style={{ minWidth: size }} />;

    case 'svg':
      return (
        <SVG
          style={{ ...style }}
          height={size}
          width={size}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          htmlstring={props.icon!}
          useDefaultSVGColor={props.useDefaultSVGColor}
        />
      );
    case 'htmlelement':
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <div dangerouslySetInnerHTML={{ __html: props.icon! }}></div>;
    case 'image':
      const dataURL = props.icon;
      if (dataURL) {
        return <img src={dataURL} width={size} height={size} alt="" style={{ ...style }} />;
      }
      return <div style={{ minWidth: size }} />;
    case 'emoji':
      return <span style={{ fontSize: size, verticalAlign: 'middle', ...props.style }}>{props.icon}</span>;
    case 'default-string':
      return renderAntIcon(props.default as string, style, props.fill);
    case 'default-element':
      return <span>{props.default}</span>;
    default:
      return <div />;
  }
};

export default Icon;
