import React from 'react';
import { CommandOption } from '../../engine/option';
import { useStore } from '../../hooks/useStore';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import Icon, { commandDefault } from '@commandbar/internal/client/Icon';
import { getCategoryIcon, getOptionIconColorBase, getCommandIcon } from '../../store/engine';
import { getIconSize } from './helpers';

export interface Props {
  isDisabled: boolean;
  isFocused: boolean;
  option: CommandOption;
}

export const CommandOptionIcon = ({ isDisabled, isFocused, option }: Props) => {
  const _ = useStore();
  const theme = useCommandBarTheme();
  const { icon, icon_color } = getCommandIcon(_, option);
  const { icon: category_icon, icon_color: category_icon_color } = getCategoryIcon(_, option);
  const { defaultColorFromTheme, opacity, useDefaultSVGColor } = getOptionIconColorBase(theme, isFocused, isDisabled);

  const color = category_icon_color || icon_color || defaultColorFromTheme;
  const style = { color: color, opacity };

  return (
    <Icon
      icon={category_icon || icon}
      color={color}
      default={commandDefault(option.command)}
      style={style}
      useDefaultSVGColor={useDefaultSVGColor}
      size={getIconSize(_, theme)}
    />
  );
};
