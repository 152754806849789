import React from 'react';
import { OptionGroup } from '../../../engine/OptionGroup';
import OptionGroupHeader from './OptionGroupHeader';

const StickyHeader = (props: {
  group?: OptionGroup;
  themeContext: any;
  expandedGroupKeys: string[];
  onToggleExpansion?: (key: string) => void;
  paddingTop: string;
}) => {
  const { group, ...headerProps } = props;
  if (!group) {
    return null;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          position: 'absolute',
          paddingTop: props.paddingTop,
          top: 0,
          zIndex: 10,
          width: '100%',
          background: props.themeContext.main.background,
        }}
      >
        <div style={{ position: 'relative' }}>
          <OptionGroupHeader key={`sticky-header`} group={group} addTopPadding={false} {...headerProps} />
        </div>
      </div>
    </div>
  );
};

export default StickyHeader;
