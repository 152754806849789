/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';
import { AiOutlineClose } from 'react-icons/ai';
import Tooltip from '../../Tooltip';
import Tag from '../../Tag';
import chroma from 'chroma-js';
import { BLOCK_POSITION } from './StatusBar';
import { ITheme } from '@commandbar/internal/client/theme';
import * as Engine from '../../../store/engine/actions';
import { useAction } from '../../../hooks/useAction';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import { BsArrowRightShort } from 'react-icons/bs';

export interface IBlock {
  text: string;
  type: BLOCK_TYPE;
}

export enum BLOCK_TYPE {
  SELECTED,
  LAST_SELECTED,
  CURRENT_ARGUMENT,
  PLACEHOLDER,
  INSTRUCTION,
  CLICKABLE_INSTRUCTION,
}

const getBlockSuffix = (type: BLOCK_TYPE): any => {
  switch (type) {
    case BLOCK_TYPE.LAST_SELECTED:
      return (
        <div style={{ padding: '6px 0px' }}>
          <span
            style={{
              marginLeft: 3,
              display: 'flex',
              alignItems: 'center',
              width: 10,
              zIndex: 2,
              position: 'relative',
            }}
          >
            <Tooltip
              overlay={
                <span>
                  <Tag style={{ height: 12, padding: '4px 4px' }} color={chroma('rgb(174, 177, 221)')}>
                    ESC
                  </Tag>{' '}
                  to remove last selection
                </span>
              }
              placement="top"
              align={{ offset: [0, -15] }}
            >
              <AiOutlineClose style={{ fontSize: 9, position: 'absolute' }} />
            </Tooltip>
          </span>
        </div>
      );
    case BLOCK_TYPE.CLICKABLE_INSTRUCTION:
      return <BsArrowRightShort style={{ fontSize: 14 }} />;
    default:
      return <div />;
  }
};

const getMaxWidth = (type: BLOCK_TYPE, position: BLOCK_POSITION): number => {
  switch (type) {
    case BLOCK_TYPE.INSTRUCTION:
      return 250;
    default:
      if (position === BLOCK_POSITION.LEFT) {
        return 150;
      } else {
        return 250;
      }
  }
};

export const blockBackgroundStyle: CSSObject = {
  lineHeight: '19.2px',
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  position: 'relative',
  transition: 'color ease-in 0.3s',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const getBlockBackgroundStyle = (
  theme: { theme: ITheme },
  type: BLOCK_TYPE,
  position: BLOCK_POSITION,
): CSSObject => {
  const typeStyles = ((): CSSObject => {
    switch (type) {
      case BLOCK_TYPE.INSTRUCTION:
      case BLOCK_TYPE.CLICKABLE_INSTRUCTION:
        return {
          marginRight: 0,
          marginBottom: 0,
          borderRadius: theme.theme.instruction.borderRadius,
        };
      case BLOCK_TYPE.LAST_SELECTED:
      case BLOCK_TYPE.SELECTED:
        return {
          boxShadow: position === BLOCK_POSITION.LEFT ? 'rgba(0, 0, 0, 0.2) 0px 5px 30px' : undefined,
          border: theme.theme.blocks.border,
          borderRadius: theme.theme.blocks.borderRadius,
        };
      case BLOCK_TYPE.CURRENT_ARGUMENT:
        return {
          boxShadow: position === BLOCK_POSITION.LEFT ? 'rgba(0, 0, 0, 0.2) 0px 5px 30px' : undefined,
          border: `1px solid ${theme.theme.base.primary}`,
          borderRadius: theme.theme.blocks.borderRadius,
        };
      case BLOCK_TYPE.PLACEHOLDER:
        return {
          boxShadow: position === BLOCK_POSITION.LEFT ? 'rgba(0, 0, 0, 0.2) 0px 5px 30px' : undefined,
          border: `1px solid grey`,
          borderRadius: theme.theme.blocks.borderRadius,
        };
    }
  })();

  const commandbarAnimationFadeIn: CSSObject = {
    animation: '0.3s ease-out onEntry',
    transformOrigin: '100% 100%',
  };

  const positionSpecificStyle = ((): CSSObject => {
    switch (position) {
      case BLOCK_POSITION.LEFT:
        return { margin: '0px 4px 8px 4px' };
      case BLOCK_POSITION.TOP:
        return { margin: '4px', ':first-of-type': { marginLeft: 0 } };
    }
  })();

  return {
    ...typeStyles,
    ...blockBackgroundStyle,
    ...commandbarAnimationFadeIn,
    ...positionSpecificStyle,
  };
};

export const getBlockStyle = (theme: { theme: ITheme }, type: BLOCK_TYPE, _position: BLOCK_POSITION): any => {
  switch (type) {
    case BLOCK_TYPE.LAST_SELECTED:
    case BLOCK_TYPE.SELECTED:
      return {
        cursor: 'pointer',
        color: theme.theme.blocks.color,
        backgroundColor: theme.theme.blocks.background,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BLOCK_TYPE.CURRENT_ARGUMENT:
      return {
        color: getChromaColor(theme.theme.base.primary).get('lab.l') < 70 ? 'white' : 'black',
        backgroundColor: theme.theme.base.primary,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BLOCK_TYPE.PLACEHOLDER:
      return {
        backgroundColor: '#dddddd',
        color: 'grey',
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BLOCK_TYPE.INSTRUCTION:
    case BLOCK_TYPE.CLICKABLE_INSTRUCTION:
      return {
        display: theme.theme.instruction.display,
        backgroundColor: theme.theme.instruction.background,
        color: theme.theme.instruction.fontColor,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
  }
};

const FadeOut = (props: { text: string }) => {
  return (
    <Tooltip overlay={<span>{props.text}</span>} align={{ offset: [0, -7] }} placement="top">
      <span
        css={(theme) => ({
          position: 'absolute',
          left: 0,
          zIndex: 1,
          width: '100%',
          height: '100%',
          background: `linear-gradient(
  to right,
  ${getChromaColor(theme.theme.main.background).alpha(0).css()},
  ${getChromaColor(theme.theme.main.background).alpha(0).css()} 75%,
  ${getChromaColor(theme.theme.main.background).alpha(0.2).css()} 80%,
  ${getChromaColor(theme.theme.main.background).alpha(0.9).css()} 100%
)`,
        })}
      />
    </Tooltip>
  );
};

const Block = (props: { content: IBlock; position: BLOCK_POSITION; windowWidth: number; onClick?: () => void }) => {
  const ref = React.useRef<any>(undefined);
  const [showFade, setShowFade] = React.useState(false);

  React.useEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowFade(true);
    } else {
      setShowFade(false);
    }
  }, [props.content.text, props.position, props.windowWidth]);

  const { content } = props;
  const maxWidth = getMaxWidth(content.type, props.position);

  const handleRollback = useAction(
    (_) => {
      Engine.rollback(_);
      Engine.handleInputChange(_, '');
    },
    [content],
  );
  const handleClick = (() => {
    if (props.onClick) return props.onClick;
    if (content.type === BLOCK_TYPE.LAST_SELECTED) return handleRollback;
    return undefined;
  })();

  return (
    <div
      css={(theme) => getBlockBackgroundStyle(theme, content.type, props.position)}
      key={`${content.text}-background`}
      data-testid={`block-${content.type}`}
    >
      {showFade && <FadeOut text={content.text} />}
      <button
        className="commandbar-block"
        aria-label={'remove-block-' + content.text.replace(' ', '-').toLowerCase()}
        style={{
          border: 'none',
          fontStyle: 'inherit',
          fontFamily: 'inherit',
          outline: 'inherit',
          cursor: !!handleClick ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
        css={(theme) => getBlockStyle(theme, content.type, props.position)}
        onClick={handleClick}
      >
        <span style={{ maxWidth, minWidth: 0, color: 'inherit' }} ref={ref}>
          {content.text}
        </span>
        {getBlockSuffix(content.type)}
      </button>
    </div>
  );
};

export default Block;
