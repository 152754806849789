import React from 'react';
import Icon from '@commandbar/internal/client/Icon';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';

export interface Props {
  icon: string | undefined;
  isGridOption: boolean;
}

export const OptionActionIcon = ({ icon, isGridOption }: Props) => {
  const theme = useCommandBarTheme();

  const iconSize = isGridOption ? theme.menuGridOption.actionIconSize : theme.option.actionIconSize;
  if (!!icon) {
    return <Icon icon={icon} style={{ width: iconSize, height: iconSize }} useDefaultSVGColor={true} />;
  } else {
    return null;
  }
};
