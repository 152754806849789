/** @jsx jsx */
import { ICommandType, IStepArgumentType } from '@commandbar/internal/middleware/types';
import { StepType, GenericStep, initStep } from './Step';

export interface DashboardStep extends GenericStep<StepType.Dashboard> {
  command?: ICommandType;
  argument: IStepArgumentType;
  selected: null;
}

export type DashboardType = 'feedback' | 'editor-loading' | 'editor-timeout' | 'editor-access-denied';

export const initDashboardStep = (argument: IStepArgumentType, command?: ICommandType): DashboardStep => {
  const s = initStep(StepType.Dashboard) as DashboardStep;
  s.command = command;
  s.argument = argument;
  s.selected = null;
  return s;
};
