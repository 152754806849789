/** @jsx jsx */
import Icon, { commandDefault } from '@commandbar/internal/client/Icon';
import { ITheme } from '@commandbar/internal/client/theme';
import { LabeledAction } from '@commandbar/internal/middleware/types';
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { interpolateObject } from '../engine/Interpolate';
import { CommandOption, ResourceOption } from '../engine/option';
import { initUnfurledCommandOption } from '../engine/option/UnfurledCommandOption';
import { useAction } from '../hooks/useAction';
import { useStore } from '../hooks/useStore';
import { getNextStepOptions, selectOption } from '../store/app';
import { getCategoryField, handleInputChange, isResourceOption } from '../store/engine';
import * as Engine from '../store/engine/actions';

interface Props {
  icon: string | null;
  text: string;
  onClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
  styles?: any;
}

function NextStepPill(props: Props) {
  const { theme }: { theme: ITheme } = useTheme();

  return (
    <div
      tabIndex={0}
      role="button"
      css={{
        boxSizing: 'border-box',
        background: theme.nextStepCommands.background,
        boxShadow: theme.nextStepCommands.boxShadow,
        borderRadius: theme.nextStepCommands.borderRadius,
        backdropFilter: 'blur(6px)',
        color: theme.nextStepCommands.color,
        textAlign: 'center',
        fontSize: '0.9em',
        marginBottom: '10px',
        padding: '3px 12px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transition: 'all 0.3s ease-in',
        maxWidth: '100%',
        '&:hover': {
          background: theme.nextStepCommands.hoverBackgroundColor,
          color: theme.nextStepCommands.hoverColor,
        },
        ...props.styles,
      }}
      onClick={props.onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.onClick();
        }
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.icon && (
          <div
            css={{
              marginRight: '6px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Icon size={theme.nextStepCommands.iconSize} icon={props.icon} useDefaultSVGColor={true} />
          </div>
        )}
        <div
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
}

interface NextStepCommandsProps {
  isHidden?: boolean;
  width?: string;
  offset?: number;
  reportPreviewEvent?: (type: string) => void;
}

function NextStepCommandsMenu({ width, isHidden, offset, reportPreviewEvent }: NextStepCommandsProps) {
  const _ = useStore();
  const clearInput = useAction((_) => {
    handleInputChange(_, '');
  });

  const chooseRecordAction = useAction(
    (_, record: ResourceOption, action: CommandOption, e?: React.MouseEvent<HTMLDivElement>) => {
      selectOption(_, initUnfurledCommandOption(_, action.command, action, record), undefined, e);
    },
  );

  const chooseOption = useAction((_, o: CommandOption, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    selectOption(_, o, undefined, e);
  });

  const executeAction = useAction((_, action: LabeledAction, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    Engine.executeAction(_, action.action, e);
  });
  const focusedOption = _.engine.sortedOptions[_.engine.focusedIndex];

  function isCommandOption(o: CommandOption | LabeledAction): o is CommandOption {
    return (o as CommandOption).command !== undefined;
  }

  const handleOnClick = (o: CommandOption | LabeledAction, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    reportPreviewEvent && reportPreviewEvent('next_step_selected');
    clearInput();
    if (isCommandOption(o)) {
      if (focusedOption && isResourceOption(focusedOption)) {
        chooseRecordAction(focusedOption, o, e);
      } else if (o) {
        chooseOption(o, e);
      }
    } else {
      executeAction(o);
    }
  };

  let options: Array<CommandOption | LabeledAction>;

  if (focusedOption && isResourceOption(focusedOption)) {
    options = focusedOption.recordActions;
  } else {
    options = getNextStepOptions(_);
  }

  const top = !!offset ? offset + 10 : 10;

  return (
    <div
      css={{
        display: isHidden ? 'none' : 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: `${top}px`,
        right: '10px',
        width: width ?? '30%',
        zIndex: 2,
        alignItems: 'flex-end',
      }}
    >
      {options.map((o, idx) => (
        <NextStepPill
          key={`next-step-command-item-${idx}`}
          icon={
            isCommandOption(o)
              ? (o.command.category && getCategoryField(_, o.command.category, 'icon')) ||
                o.command.icon ||
                commandDefault(o.command)
              : null
          }
          text={interpolateObject({
            s: isCommandOption(o) ? o.command.text : o.cta,
            engine: _.engine,
            interpolateContext: true,
            interpolateArgs: false,
          })}
          onClick={(e?: React.MouseEvent<HTMLDivElement>) => handleOnClick(o, e)}
        />
      ))}
    </div>
  );
}

export default NextStepCommandsMenu;
