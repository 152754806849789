import { CallbackMap, Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import Available from '../../engine/Available';
import { EngineState } from './state';
import ExecutionPath, { fulfill, rebase } from '../../engine/ExecutionPath';
import { Step } from '../../engine/step';
import Analytics from '../../analytics/Analytics';
import {
  IChecklist,
  ICommandType,
  IOrganizationType,
  IPlaceholderType,
  IResourceSettings,
  IResourceSettingsByContextKey,
  ISkinType,
  ITabType,
  IUserContext,
} from '@commandbar/internal/middleware/types';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Reporting from '../../analytics/Reporting';
import { findFirstOptionIndex } from './selectors';
import { doNotTrackContext } from '../util/doNotTrack';
import { initBaseStep } from '../../engine/step/BaseStep';
import { StepType } from '../../engine/step/Step';
import { initCommandOption } from '../../engine/option/CommandOption';
import { chooseCommandOption, extractSlashFilter } from '.';
import { triggerChecklists, updateChecklistItemEventGoals } from './checklists/actions';
import { triggerNudges } from './nudges/actions';
import { ITheme } from '@commandbar/internal/client/theme';

import { ref } from 'valtio';

import type { ExecutionEventSource, NudgeEvent } from '@commandbar/internal/client/EventHandler';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';

export * from './options/actions';
export * from './steps/actions';
export * from './generate-options/actions';
export * from './end-user/actions';
export * from './checklists/actions';
export * from './nudges/actions';
export * from './help-hub/actions';

export type InputEvent = { action: string };
export type StepUpdater = (steps: Step[], useFulfill?: boolean) => void;

export const addCallbacks = (_: EngineState, callbacks: CallbackMap) => {
  const { engine } = _;
  const callbacksAfterAdd = { ...engine.callbacks, ...callbacks };
  if (isEqual(engine.callbacks, callbacksAfterAdd)) return;

  _.engine.callbacks = callbacksAfterAdd;
};

export const addCommand = (_: EngineState, command: ICommandType) => {
  // **Replace** existing command if there's already a command with this name in this category
  _.engine.programmaticCommands = _.engine.programmaticCommands.filter(
    (c) => !(c.name === command.name && c.category === command.category),
  );
  _.engine.programmaticCommands.push(command);
};

export const addContext = (_: EngineState, context: IUserContext) => {
  // Register all context keys as opaque references
  doNotTrackContext(context);
  Object.assign(_.engine.context, context);
};

export const executeCommand = (
  _: EngineState,
  command: ICommandType,
  onSuccess?: () => void,
  onFail?: () => void,
  executionEventSource: ExecutionEventSource = { type: 'bar' },
  e?: React.MouseEvent<HTMLElement, MouseEvent>,
) => {
  // Pseudo state
  const pseudoExecutionPathState = {
    ..._.engine,
    steps: [initBaseStep(null)],
  };
  const commandOption = initCommandOption({ engine: pseudoExecutionPathState }, command);
  const { isDisabled } = commandOption.optionDisabled;

  if (!isDisabled) {
    chooseCommandOption(
      { engine: pseudoExecutionPathState },
      commandOption,
      true,
      updateSteps.bind(null, _),
      executionEventSource,
      {
        openLinkInNewTab: !!e && getTriggerKey(e),
      },
    );
    onSuccess && onSuccess();
  } else {
    onFail && onFail();
  }

  return isDisabled;
};

export const fulfillAndRebase = (_: EngineState) => {
  rebase(fulfill(_.engine));
};

export const handleInputChange = (_: EngineState, newInput: string, event?: InputEvent) => {
  if (event?.action === 'input-blur' || event?.action === 'menu-close') {
    return;
  }
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  // By default, set-value is triggered when enter is pressed in the bar
  // In multiselect scenarios, we don't want to change the text or update the options when enter is pressed
  // Explanation: https://www.loom.com/share/648a4f1550f44309a80a30f3fc95af8e
  if (currentStep?.type === StepType.MultiSelect && event?.action === 'set-value') {
    return;
  }

  // Reporting
  reportDeadendIfClosed(_, newInput, event);
  reportDeadendIfBackspaced(_, newInput);

  _.engine.reportKeystrokeDebouncer(() => reportKeystroke(_, newInput));

  _.engine.rawInput = newInput;

  let cleaninput = newInput;

  //handle slash filters
  if (!!_.engine.organization?.slash_filters_enabled && currentStep?.type === StepType.Base) {
    const inputParts = extractSlashFilter(newInput);
    cleaninput = inputParts.inputText;
  }

  if (_.engine.inputText) {
    _.engine.previousInputText = _.engine.inputText;
  }
  _.engine.inputText = cleaninput.trim();

  if (!_.engine.visible && newInput !== '') {
    setVisible(_, true);
  }
};

export const removeCallback = (_: EngineState, toRemove: string) => {
  const { engine } = _;
  if (!(toRemove in engine.callbacks)) return;

  delete _.engine.callbacks[toRemove];
};

export const removeCommand = (_: EngineState, name: string) => {
  _.engine.programmaticCommands = _.engine.programmaticCommands.filter((c) => c.name !== name);
};

export const removeContext = (_: EngineState, toRemove: string) => {
  delete _.engine.context[toRemove];
  delete _.engine.localContextSettings[toRemove];
};

export const reportDeadendIfBackspaced = (_: EngineState, newInput: string) => {
  if (newInput.length === 0 && _.engine.isBackspacing && _.engine.cachedInput.length > 0) {
    Reporting.deadend(_.engine.cachedInput, 'Backspaced', _.engine);
    _.engine.cachedInput = '';
  }
};

export const reportDeadendIfClosed = (_: EngineState, newInput: string, event?: InputEvent) => {
  const didInputClear = newInput.length === 0 && _.engine.inputText.length > 0;
  const didMenuClose = event?.action === 'menu-close';

  if (didInputClear && didMenuClose) {
    Reporting.deadend(_.engine.inputText, 'Resetting search', _.engine);
  }
};

export const reportKeystroke = (_: EngineState, newInput: string) => {
  Reporting.searchInputChange(newInput, _.engine);
};

export const reset = (_: EngineState) => {
  _.engine.steps = [initBaseStep(null)];
  _.engine.initialOptions = ref(Available.available(_.engine));
};

export const resetFocusedIndex = (_: EngineState) => {
  _.engine.focusedIndex = findFirstOptionIndex(_);
  _.engine.focusableChildIndex = -1;
};

export const rollback = (_: EngineState) => {
  const { engine } = _;

  const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(engine);

  if (currentStep === undefined) {
    rebase(engine);
    return;
  }

  // Get previous step
  let steps = engine.steps.map((step: Step, index: number) => {
    // Undo current step
    if (index === currentStepIndex) {
      step.selected = null;
      return step;
    }
    // Set previous step to not complete
    if (index === currentStepIndex - 1) {
      if (step.type === StepType.LongTextInput) {
        step.completed = false;
        return step;
      } else {
        if (step.type === StepType.Select) {
          step.argument.auto_choose = false;
        }

        step.completed = false;
        step.selected = null;
        return step;
      }
    }

    return step;
  });

  // If the previous step was a BaseStep, then reset to empty state
  // With Object search we can have multiple BaseSteps in a row, causing strange behavior unless we reset
  if (steps[currentStepIndex - 1]?.type === StepType.Base) {
    steps = [initBaseStep(null)];
  }

  engine.steps = steps;
  rebase(engine);
};

export const setBaseTheme = (
  _: EngineState,
  theme: string | Pick<ISkinType, 'logo' | 'skin' | 'chat_avatar'>,
  themeSource: string,
  color?: string,
) => {
  _.engine.baseTheme = theme;
  _.engine.themeSource = themeSource;
  _.engine.primaryColor = color;
};

export const setTabs = (_: EngineState, tabs: ITabType[]) => {
  _.engine.tabs = tabs;
};

export const setCommands = (_: EngineState, commands: ICommandType[]) => {
  // NOTE: we may not need to cons up a new array here -- I just kept it this way for "backwards compatibility" -JL
  _.engine.commands = [...commands];
  _.engine.commandsLoaded = true;
};

export const setContext = (_: EngineState, context: IUserContext) => {
  // If the context is equal, don't make changes
  if (isEqual(_.engine.context, context)) return;
  doNotTrackContext(context);
  _.engine.context = context;
};

export const setContextState = (
  _: EngineState,
  callbacks?: CallbackMap,
  context?: Record<string, unknown>,
  contextSettings?: IResourceSettingsByContextKey,
) => {
  const { engine } = _;
  if (context) doNotTrackContext(context);
  const _contextAfterAdd = !!context ? { ...engine.context, ...context } : engine.context;
  const _callbacksAfterAdd = !!callbacks ? { ...engine.callbacks, ...callbacks } : engine.callbacks;

  // broke with this change https://github.com/tryfoobar/monobar/pull/702/files#r801167536
  // move this to a subscription

  if (
    isEqual(engine.context, _contextAfterAdd) &&
    isEqual(engine.callbacks, _callbacksAfterAdd) &&
    isEqual(engine.localContextSettings, contextSettings)
  ) {
    return;
  }

  // FIXME: the following can be replaced with this faster implementation once async rendering bugs are dealt with:
  // _.engine.context = ref(_contextAfterAdd);
  // _.engine.callbacks = _callbacksAfterAdd;
  // _.engine.hackContextSettings = _hackContextSettings;

  engine.context = _contextAfterAdd;
  engine.callbacks = _callbacksAfterAdd;
  if (contextSettings) {
    engine.localContextSettings = merge({}, engine.localContextSettings, contextSettings);
  }
  //_.engine.hackContextSettings = _hackContextSettings;
};

export const setLocalContextSettings = (_: EngineState, key: string, settings: IResourceSettings) => {
  const requiredSettings = { search: false };
  _.engine.localContextSettings[key] = { ...requiredSettings, ...settings };
};

export const updateLocalContextSetting = (
  _: EngineState,
  key: string,
  setting: keyof IResourceSettings,
  value: IResourceSettings[keyof IResourceSettings],
) => {
  const currentSettings = _.engine.localContextSettings[key] || {};
  _.engine.localContextSettings[key] = { ...currentSettings, [setting]: value };
};

export const setIsAdmin = (_: EngineState, value: boolean) => {
  if (value) Analytics.identifyAsAdmin();
  _.engine.isAdmin = value;
};

export const setLogo = (_: EngineState, logo: string | undefined) => {
  _.engine.logo = logo ?? '';
};

export const setChatAvatar = (_: EngineState, chatAvatar: string | undefined) => {
  _.engine.chatAvatar = chatAvatar ?? '';
};

export const setTheme = (_: EngineState, theme?: ITheme) => {
  _.engine.theme = theme;
};

export const setOrganization = (_: EngineState, organization: IOrganizationType, themeSource: string) => {
  _.engine.organization = organization;
  _.engine.logo = organization.icon ?? '';
  _.engine.chatAvatar = organization.helphub_chat_avatar ?? '';
  _.engine.themeSource = themeSource;
  _.engine.serverContextSettings = organization.resource_options;
};

export const setTestMode = (_: EngineState, value: boolean) => {
  if (value) {
    LocalStorage.set('testMode', '1');
  } else {
    LocalStorage.remove('testMode');
  }

  _.engine.testMode = value;
};

export const setVisible = (_: EngineState, value: boolean) => {
  if (value) {
    // Reset preivous input on new session; hidden -> visible
    if (_.engine.visible === false) _.engine.previousInputText = '';

    _.engine.visible = true;
  } else {
    _.engine.visible = false;
    _.engine.steps = [initBaseStep(null)];
  }
};

export const toggleGroupExpansion = (_: EngineState, header: string) => {
  const expanded = _.engine.expandedGroupKeys;
  const index = expanded.findIndex((_header: string) => _header === header);
  if (index > -1) {
    expanded.splice(index, 1);
  } else {
    expanded.push(header);
  }
};

export const changeGroupExpanded = (_: EngineState, header: string, isExpanded: boolean) => {
  const expanded = _.engine.expandedGroupKeys;
  const index = expanded.findIndex((_header: string) => _header === header);
  if (isExpanded) {
    if (index === -1) {
      // Add to expanded list
      expanded.push(header);
    }
  } else {
    if (index > -1) {
      // Remove from expanded list
      expanded.splice(index, 1);
    }
  }
};

export const updateSteps = (_: EngineState, updatedSteps: Step[], useFulfill = true) => {
  _.engine.steps = updatedSteps;

  if (useFulfill) {
    rebase(fulfill(_.engine));
  }
};

export const setInputText = (_: EngineState, value: string) => {
  if (_.engine.inputText) {
    _.engine.previousInputText = _.engine.inputText;
  }
  _.engine.inputText = value;
};

export const toggleTestMode = (_: EngineState) => {
  _.engine.testMode = !_.engine.testMode;
};

export const setPlaceholders = (_: EngineState, placeholders: IPlaceholderType[]) => {
  // without checking for changes, redundant loading requests (e.g., logging into editor)
  //  change the active placeholder, which looks choppy
  const hasChanged = !isEqual(_.engine.placeholders, placeholders);

  if (hasChanged) {
    _.engine.placeholders = placeholders;
  }
};

export const showChecklist = (_: EngineState, c: IChecklist) => {
  _.engine.activeChecklist = c;
};

export const hideChecklist = (_: EngineState) => {
  _.engine.activeChecklist = null;
};

export const trackEvent = (_: EngineState, key: string, properties: Metadata, type: 'commandbar' | 'app') => {
  if (type === 'commandbar' && !key.startsWith('commandbar-')) {
    key = `commandbar-${key}`;
  }

  const excludedNudges: string[] = [];
  if (type === 'commandbar' && key === 'commandbar-nudge_shown') {
    excludedNudges.push(String((properties as unknown as NudgeEvent).nudge.id));
  }

  triggerNudges(_, { type: 'on_event', meta: { event: key } }, excludedNudges);
  triggerChecklists(_, { type: 'on_event', meta: { event: key } });
  updateChecklistItemEventGoals(_, key);
};
