import { useEffect, useState } from 'react';
import { getElement } from '@commandbar/internal/util/dom';

// Given a target selector string, will return the element once it is loaded
const useElementObserver = (targetEl: string) => {
  const [element, setElement] = useState<HTMLElement | undefined>(undefined);
  const page = document.documentElement || document.body;

  useEffect(() => {
    const el = getElement(targetEl);
    setElement(el);

    if (!el) {
      const observer = new MutationObserver(() => {
        const newEl = getElement(targetEl);

        if (newEl) {
          setElement(newEl);
          observer.disconnect();
        }
      });

      observer.observe(page, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [targetEl]);

  return element;
};

export default useElementObserver;
