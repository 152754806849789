import { DashboardType } from './../../engine/step/DashboardStep';
import { IGuideType, ICommandCategoryType, OptionGroupRenderAsType } from '@commandbar/internal/middleware/types';
import { Option } from '../../engine/option';
import debounce from 'lodash/debounce';
import { ISelectHandle } from '../../components/select/CommandSelect';
import { RefObject } from 'react';
import { CommandBarClientSDK, CustomComponent } from '@commandbar/internal/client/CommandBarClientSDK';
import { StepType } from '../../engine/step/Step';

// Copied from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/lodash/common/function.d.ts
export interface DebouncedFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invocation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invocation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invocation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invocation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): ReturnType<T> | undefined;
}

export interface ISearchFilter {
  slug: string;
  fn: (option: Option) => boolean;
  renderAs: OptionGroupRenderAsType;
  inputTag?: string; // Optional tag to show to the left of the input
  placeholder?: string; // Placeholder when the search filter is active
  // FIXME: we need a better way to handle this. It's a way to avoid "No results" if the options are async
  emptyInputMessage?: string; // Do we want to show "No results" if a user hasn't typed and there are no results?
}

export type AppState = {
  loadingByKey: Record<string, boolean>;
  showLoadingIndicator: boolean;
  dashboard: undefined | DashboardType;
  emptyMessage: undefined | string;
  refContainer: React.RefObject<ISelectHandle>;
  previewMode: boolean;
  active: boolean;
  guides: IGuideType[];
  activeGuide: IGuideType;
  showKeyboardShortcutCheatsheet: boolean;

  // map from category name or ID to local category config
  categoryConfig: Record<number, Partial<ICommandCategoryType>>;

  serverCategories: ICommandCategoryType[];
  localCategories: ICommandCategoryType[];

  searchFilter: ISearchFilter | undefined;
  inMinLoadingWindow: boolean;
  minLoadingTimeout: number | undefined;
  searchOptionsDebouncer: DebouncedFunc<(cb: VoidFunction) => void>;

  envOverride: { env: string } | { version: string } | null;
  env: string | null;
  version: string | null;
  airgap: boolean;

  userDefinedCustomComponents: Record<
    Parameters<CommandBarClientSDK['setCustomComponent']>[0],
    (meta?: { step?: Exclude<StepType, StepType.Execute>; activeTab?: string }) => string | CustomComponent | null
  >;
  isEditorVisible: boolean;
  editorPathChangeListeners: ((path: string) => void)[];
  initialEditorPath: string | null;
};

export const initAppState = (): AppState => ({
  /**
   * VALTIO: consider moving loading to engine state (not necessarily 'engine' though)
   */
  loadingByKey: {},
  showLoadingIndicator: false,
  active: false,
  activeGuide: {
    id: -1,
    organization: '',
    event: '',
    nudge: '',
    guidance: '',
    preview: false,
  },
  categoryConfig: {},
  serverCategories: [],
  localCategories: [],
  userDefinedCustomComponents: {
    footer: () => null,
    header: () => null,
    tabHeader: () => null,
    menuHeader: () => null,
    input: () => null,
    sidepanel: () => null,
    navPaneHeader: () => null,
    navPaneFooter: () => null,
    defaultState: () => null,
    emptyState: () => null,
  },
  dashboard: undefined,
  emptyMessage: undefined,
  guides: [],
  inMinLoadingWindow: false,
  previewMode: false,
  refContainer: null as unknown as RefObject<ISelectHandle>,
  searchFilter: undefined,
  showKeyboardShortcutCheatsheet: false,
  minLoadingTimeout: undefined,
  searchOptionsDebouncer: debounce((cb) => cb(), 150),
  env: null,
  envOverride: null,
  version: null,
  airgap: false,
  isEditorVisible: false,
  editorPathChangeListeners: [],
  initialEditorPath: null,
});
