import React from 'react';

import { Hub } from '@sentry/core';

import type { BrowserClient as BrowserClientType, Event } from '@sentry/browser';

// XXX: This is a hack to reduce the Sentry bundle size as much as possible.
// By default the @sentry/browser package includes a bunch of extra global integrations which
// we aren't using, so we import these directly from the specific ES modules to get around this.

// @ts-expect-error See above
import { BrowserClient } from '@sentry/browser/esm/client';
// @ts-expect-error See above
import { defaultStackParser } from '@sentry/browser/esm/stack-parsers';
// @ts-expect-error See above
import { makeFetchTransport } from '@sentry/browser/esm/transports/fetch';
// @ts-expect-error See above
import { FunctionToString } from '@sentry/core/esm/integrations';

import { getProxySDK } from '../client/proxy';
import { _configuration, _sentry } from '../client/symbols';
import { getSDK } from '../client/globals';
import { getBaseURL } from '../middleware/network';

export const SENTRY_COMMANDBAR_DSN = 'https://ebf252b62885436e858a66d157aee9ea@o451734.ingest.sentry.io/5608348';

export const sentryHubFactory = (dsn: string): Hub | undefined => {
  try {
    const isProd = process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test';
    const environment = process.env.REACT_APP_BUILD_TARGET || 'dev';
    const release = process.env.SENTRY_RELEASE || 'dev';
    const enabled = isProd || process.env.REACT_APP_SENTRY_ENABLED === 'true';

    const client: BrowserClientType = new BrowserClient({
      dsn: enabled ? dsn : '',
      tunnel: `${getBaseURL()}/datapipeline/e/`,
      environment,
      release,
      attachStacktrace: true,
      autoSessionTracking: true,
      debug: !isProd,
      enabled,
      beforeSend(event: Event) {
        const airgap = getSDK()?.[_configuration]?.airgap;

        return airgap ? null : event;
      },
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [new FunctionToString()],
    });

    client.setupIntegrations();

    return new Hub(client);
  } catch (err) {
    console.error(err);
    // TODO: Send an error directly to Sentry via the API
    return undefined;
  }
};

export const getSentry = () => {
  return getProxySDK()[_sentry];
};

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class SentryErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  captureRejection = (ev: PromiseRejectionEvent) => {
    getSentry()?.captureException(ev.reason);
    ev.preventDefault();
  };

  componentDidMount = () => {
    window.addEventListener('unhandledrejection', this.captureRejection);
  };

  public componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.captureRejection);
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const sentry = getProxySDK()[_sentry];

    sentry?.withScope((scope) => {
      scope.setContext('errorInfo', {
        componentStack: errorInfo.componentStack,
      });

      sentry.captureException(error);
    });
  }

  public render() {
    return this.props.children;
  }
}
