import { IOrganizationType } from '@commandbar/internal/middleware/types';
import * as Reporting from '../analytics/Reporting';

export const showBranding = (org?: IOrganizationType) => {
  return org?.branding === 'branded';
};

export const navigateToBrandingSite = (org?: IOrganizationType) => {
  Reporting.clickedBranding();
  window.open(`https://commandbar.com/powered-by-commandbar?org=${org?.name || encodeURI('This app')}`, '_blank');
};
