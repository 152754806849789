/** @jsx jsx */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { jsx } from '@emotion/core';
import React from 'react';

import Modal from './Modal';

import { useStore } from '../../hooks/useStore';

const Nudges = () => {
  const {
    engine: { currentModalNudge },
  } = useStore();

  return (
    <div>
      {currentModalNudge && (
        <Modal
          step={currentModalNudge.step}
          service={currentModalNudge.service}
          dismissible={currentModalNudge.dismissible === undefined ? true : currentModalNudge.dismissible}
          stepCount={currentModalNudge.stepCount}
        />
      )}
    </div>
  );
};

export default Nudges;
