import Logger from '@commandbar/internal/util/Logger';
import { _configuration } from '@commandbar/internal/client/symbols';
import { getProxySDK } from '@commandbar/internal/client/globals';

////////////////////////////////////////////////////////////////////////////
// index.html content
// ----------------------
//
// <div
//   id="commandbar-wrapper"
//   data-commandbar-org-id="42424242"
//   data-commandbar-org-name="Gusto"
// >
//   <div id="commandbar"></div>
// </div>
// <link rel="stylesheet" type="text/css" href="%PUBLIC_URL%/commandbar.css" />
// <script src="%PUBLIC_URL%/commandbar.js"></script>
//
////////////////////////////////////////////////////////////////////////////

const genSession = () => {
  const len = 12;
  const factor = Math.pow(10, len);
  return Math.floor(factor + Math.random() * 9 * factor).toString();
};

const getConfig = (_uuid?: string) => {
  const lc = localStorage.getItem('commandbar.lc');

  const cleanedLc = !!lc && lc.startsWith('preview') ? 'preview' : lc;

  switch (cleanedLc) {
    case 'prod':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-prod.commandbar.com',
        editor: 'https://frames-editor-prod.commandbar.com',
      };
    case 'prod-stable':
    case 'stable':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-stable.commandbar.com',
        editor: 'https://frames-editor-stable.commandbar.com',
      };
    case 'labs':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-labs.commandbar.com',
        editor: 'https://frames-editor-labs.commandbar.com',
      };
    case 'api-labs':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-prod.commandbar.com',
        editor: 'https://frames-editor-prod.commandbar.com',
      };
    case 'api-labs-local':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-prod.commandbar.com',
        editor: 'https://frames-editor-prod.commandbar.com',
      };
    case 'sandbox':
      return {
        api: 'https://api-sandbox.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-sandbox.commandbar.com',
        editor: 'https://frames-editor-sandbox.commandbar.com',
      };
    case 'branch':
      return {
        api: 'https://api-branch.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-branch.commandbar.com',
        editor: 'https://frames-editor-branch.commandbar.com',
      };
    case 'branch2':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-branch2.commandbar.com',
        editor: 'https://frames-editor-branch2.commandbar.com',
      };
    case 'stress':
      return {
        api: 'https://api-stress.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-labs.commandbar.com',
        editor: 'https://frames-editor-labs.commandbar.com',
      };
    case 'local':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'http://localhost:3002',
        editor: 'http://localhost:3003',
      };
    case 'test':
    case 'local-dev':
      return {
        api: 'http://localhost:8000',
        commandbar: '__local__',
        proxy: 'http://localhost:3002',
        editor: 'http://localhost:3003',
      };
    case 'local-docker':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'http://localhost:5002',
        editor: 'http://localhost:5003',
      };
    case 'local-dev-docker':
      return {
        api: 'http://localhost:8000',
        commandbar: '__local__',
        proxy: 'http://localhost:5002',
        editor: 'http://localhost:5003',
      };
    case 'mobile':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-labs.commandbar.com',
        editor: 'https://frames-editor-labs.commandbar.com',
      };
    case 'preview':
      // DO NOT MERGE: update origin to test on labs
      const origin = !!lc && lc.includes('local-dev') ? 'http://localhost:8000' : 'https://api-labs.commandbar.com';
      let proxy = 'https://frames-proxy-labs.commandbar.com';
      let editor = 'https://frames-editor-labs.commandbar.com';

      if (!!lc && lc.includes('_')) {
        const branchIdentifier = lc.substring(lc.lastIndexOf('_') + 1);

        proxy = `https://${branchIdentifier}--proxy-branch2.netlify.app`;
        editor = `https://${branchIdentifier}--editor-branch2.netlify.app`;
      }

      return {
        api: origin,
        commandbar: '__local__',
        proxy: proxy,
        editor: editor,
      };
    default:
      return {
        api: process.env.REACT_APP_API_URL || 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: process.env.REACT_APP_PROXY_URL || 'https://frames-proxy-prod.commandbar.com',
        editor: process.env.REACT_APP_EDITOR_URL || 'https://frames-editor-prod.commandbar.com',
      };
  }
};

/** Initialize the global client API proxy (window.CommandBar) and prepares the DOM for rendering. */
export const deploy = (): HTMLElement => {
  Logger.blue('[CommandBar] Loading...');

  const sdk = getProxySDK();

  let wrapper = document.getElementById('commandbar-wrapper');
  let container = document.getElementById('commandbar');

  if (!wrapper) {
    wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'commandbar-wrapper');
    wrapper.setAttribute('data-commandbar', '1');

    document.body.appendChild(wrapper);
  }

  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', 'commandbar');
    container.setAttribute('data-commandbar', '1');
    wrapper.appendChild(container);
  }

  const session = genSession();

  sdk[_configuration] = {
    ...sdk[_configuration],
    session,
    foobarVersion: process.env.REACT_APP_NPM_VERSION,
    ...getConfig(sdk[_configuration]?.uuid),
  };

  return container;
};
