import React from 'react';
import { isMobile, getOperatingSystem, getTriggerKey, osControlKey } from './operatingSystem';

const useOS = () => {
  const details = React.useMemo(() => {
    return {
      getEventTrigger: getTriggerKey,

      triggerKey: osControlKey(),
      os: getOperatingSystem(),
      isMobile: isMobile(),
    };
  }, []);

  return details;
};

export default useOS;
