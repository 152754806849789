/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React, { useState, useEffect } from 'react';
import { generateTheme, ITheme } from '@commandbar/internal/client/theme';
import chroma from 'chroma-js';
import { IOrganizationType, ISkinType } from '@commandbar/internal/middleware/types';
import { Skin } from '@commandbar/internal/middleware/skin';
import { isSupportedColor } from '../util/colors';
import { FormFactor } from '@commandbar/internal/client/CommandBarClientSDK';
import { useStore } from '../hooks/useStore';

interface IThemeAttributes {
  [attribute: string]: string;
}

const DARK_MODE_BASE_FONT_COLOR = 'rgba(255,255,255,0.85)';
const LIGHT_MODE_BASE_FONT_COLOR = 'rgba(0,0,0,0.85)';

const pickFontColorForBackground = (backgroundColor: string) => {
  // Web Standards recommend at least a 4:5:1 contrast ratio between text and background
  // https://www.w3.org/TR/WCAG20-TECHS/G18.html
  if (chroma.contrast(backgroundColor, DARK_MODE_BASE_FONT_COLOR) > 4.5) {
    return DARK_MODE_BASE_FONT_COLOR;
  } else {
    return LIGHT_MODE_BASE_FONT_COLOR;
  }
};

const THEME: Record<string, IThemeAttributes> = {
  dark: {
    background: 'rgb(36,35,41)',
    fontColor: DARK_MODE_BASE_FONT_COLOR,
  },
  light: { background: 'rgba(255,255,255, 1.0)', fontColor: LIGHT_MODE_BASE_FONT_COLOR },
};

const useCustomTheme = (props: {
  userTheme: any;
  barFormFactor: FormFactor;
  baseTheme?: string | Pick<ISkinType, 'logo' | 'skin'>;
  primaryColor?: string;
  themeSource: string;
  organization: IOrganizationType | undefined;
  setLogo: (svgString: string) => void;
  setChatAvatar: (svgString: string) => void;
  setTheme: (theme?: ITheme) => void;
}) => {
  const { isAdmin, theme } = useStore().engine;
  const [semaphore, setSemaphore] = React.useState(false);
  const { userTheme, barFormFactor, baseTheme, primaryColor, themeSource, organization, setTheme } = props;
  const [prevThemeSource, setPrevThemeSource] = useState('');

  const primary = isSupportedColor(primaryColor) ? primaryColor : userTheme?.base?.primary ?? theme?.base?.primary;

  const setProgrammaticTheme = async (
    baseTheme: string | Pick<ISkinType, 'logo' | 'chat_avatar' | 'skin'>,
    barFormFactor: FormFactor,
  ) => {
    if (typeof baseTheme !== 'string') {
      if (!isAdmin) {
        return;
      }
      const { skin, logo, chat_avatar } = baseTheme;
      setTheme(generateTheme({ ...skin, base: { ...skin.base, ...(primaryColor && { primary: primaryColor }) } }));
      props.setLogo(logo ?? '');
      props.setChatAvatar(chat_avatar ?? '');
    } else if (['light', 'dark'].includes(baseTheme)) {
      const baseThemeStyles = {
        ...THEME[baseTheme],
        primary,
        fontFamily: userTheme?.base?.fontFamily ?? 'Avenir Next, proxima-nova, sans-serif',
      };
      setTheme(generateTheme({ ...userTheme, base: baseThemeStyles }, barFormFactor));
    } else if (isSupportedColor(baseTheme)) {
      const fontColor = pickFontColorForBackground(baseTheme);

      const baseThemeStyles = {
        background: baseTheme,
        fontColor,
        primary,
        fontFamily: userTheme?.base?.fontFamily ?? 'Avenir Next, proxima-nova, sans-serif',
      };
      setTheme(generateTheme({ ...userTheme, base: baseThemeStyles }, barFormFactor));
    } else {
      if (!organization) return;
      if (baseTheme === 'reset') return;

      try {
        const data = await Skin.read(baseTheme ?? '', { org: organization.id.toString() });
        /*
          If baseTheme is a slug, override the primary color only if it is explicitly set since we are
          fetching it from the backend.
        */
        setTheme(
          generateTheme(
            { ...data.skin, base: { ...data.skin.base, ...(primaryColor && { primary: primaryColor }) } },
            barFormFactor,
          ),
        );
        props.setLogo(data?.logo ?? '');
        props.setChatAvatar(data?.chat_avatar ?? '');
      } catch (err) {
        console.warn(`Theme slug ${baseTheme} not found.`);
      }
    }
  };

  React.useEffect(() => {
    if (baseTheme) {
      setProgrammaticTheme(baseTheme, barFormFactor);
      return;
    }

    if (userTheme !== undefined) {
      setTheme(generateTheme(userTheme, barFormFactor));
    }
  }, [userTheme, baseTheme, themeSource]);

  React.useEffect(() => {
    if (baseTheme) {
      // Prevent flashing -- this will cause the main commandbar/src/App.tsx component to return "null"
      // (and therefore not render anything) until the theme is set.
      // This is only done when formFactor changes. Otherwise, there will be flashing when editing skins.
      setTheme(undefined);
      setProgrammaticTheme(baseTheme, barFormFactor);
      return;
    }

    if (userTheme !== undefined) {
      setTheme(generateTheme(userTheme, barFormFactor));
    }
  }, [barFormFactor]);

  useEffect(() => {
    setPrevThemeSource(themeSource);
  }, [themeSource]);

  useEffect(() => {
    if (baseTheme && !semaphore && prevThemeSource === 'setThemeFunction') {
      setProgrammaticTheme(baseTheme, barFormFactor);
    }
  }, [prevThemeSource, themeSource, baseTheme, barFormFactor]);

  React.useEffect(() => {
    if (!semaphore) {
      setSemaphore(true);
    }
  }, [theme, semaphore]);

  /******************************************************************/

  return {
    userTheme: props.userTheme,
    theme,
  };
};

export default useCustomTheme;
