import { useCallback, useEffect, useState } from 'react';

enum NAV_KEYS {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

export enum INPUT_METHOD {
  Keyboard = 'keyboard',
  Mouse = 'mouse',
}

const isArrowKey = (key: string): key is NAV_KEYS => key in NAV_KEYS;

type UseKeyboardNavigation = <T>(
  list: T[] | null,
  onEnter: ((item: T | null) => void) | ((item: T) => void),
) => {
  currentIndex: number;
  selectionMethod: INPUT_METHOD;
  onMouseMove: (index: number) => void;
  onMouseLeave: () => void;
};

const useKeyboardNavigation: UseKeyboardNavigation = (list, onEnter) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectionMethod, setSelectionMethod] = useState<INPUT_METHOD>(INPUT_METHOD.Keyboard);

  const handleArrowKeys = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      const increment = event.key === NAV_KEYS.ArrowDown ? 1 : -1;
      const totalItems = list?.length || 0;

      setSelectionMethod(INPUT_METHOD.Keyboard);
      setCurrentIndex((previousIndex) => {
        const nextIndex = previousIndex !== -1 ? previousIndex + increment : 0;
        return (nextIndex + totalItems) % totalItems;
      });
    },
    [list?.length, setCurrentIndex],
  );

  const handleEnterKey = useCallback(
    (event: KeyboardEvent) => {
      if (currentIndex !== -1) {
        event.preventDefault();

        const selectedItem = list?.[currentIndex];
        if (selectedItem) {
          onEnter(selectedItem);
        }
      }
    },
    [onEnter, currentIndex, list],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isArrowKey(event.key)) {
        handleArrowKeys(event);
      } else if (event.key === 'Enter') {
        handleEnterKey(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleArrowKeys, handleEnterKey]);

  const onMouseMove = (index: number) => {
    setSelectionMethod(INPUT_METHOD.Mouse);
    setCurrentIndex(index);
  };

  const onMouseLeave = () => {
    setCurrentIndex(-1);
  };

  return { currentIndex, selectionMethod, onMouseMove, onMouseLeave };
};

export default useKeyboardNavigation;
