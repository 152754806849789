import { useLayoutEffect, useState, useCallback } from 'react';

const useResizeObserver = (element: HTMLElement | null) => {
  const [size, setSize] = useState<{ width: number; height: number }>();

  const handleResize = useCallback((entries) => {
    const [entry] = entries;
    const { width, height } = entry.contentRect;
    setSize({ width, height });
  }, []);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(handleResize);

    if (element) {
      observer.observe(element);
    } else {
      setSize({ width: 0, height: 0 });
    }

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return { width: size?.width, height: size?.height };
};

export default useResizeObserver;
