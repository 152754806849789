import { GenericOption } from '../option';
import { ICommandType } from '@commandbar/internal/middleware/types';
import { interpolate } from '../Interpolate';
import { validateOptionDisabled } from '../../store/engine/actions';
import { EngineState } from '../../store/engine/state';
import { isCommandOptionRecommended } from '../../store/engine/options/selectors';
import { OptionType, initOption, OptionCategoryMetadata } from './Option';
import { getOptionUID } from '../../store/engine';

export interface CommandOption extends GenericOption<OptionType.Command> {
  command: ICommandType;
  isRecommended: boolean;
  isRecent: boolean;
}

export const initCommandOption = (_: EngineState, command: ICommandType, parent?: CommandOption): CommandOption => {
  const o = initOption(
    _,
    OptionType.Command,
    parent ? parent.label : interpolate(command.text, _.engine, true, false),
    command.text,
    undefined,
    command.detail ?? (command.show_preview ? command.content : null),
  ) as CommandOption;

  o.command = {
    ...command,
    explanation: interpolate(command.explanation, _.engine, true, false),
    heading: interpolate(command.heading, _.engine, true, false),
  };
  o.isRecommended = isCommandOptionRecommended(_, o);

  if (parent) {
    o.optionDisabled = parent.optionDisabled;
  } else {
    validateOptionDisabled(_, o);
  }

  const optionCategory = _.engine.categories.find((c) => c.id === command.category);

  const category: OptionCategoryMetadata = {
    label: '',
    contextKey: '',
    limit: null,
    renderAs: optionCategory?.render_as || 'list',
  };
  o.category = category;

  const optionUID = getOptionUID(o);
  o.isRecent = !!(
    optionUID &&
    optionCategory?.track_recents &&
    _.engine.endUserStore.derived.recentUIDs.includes(optionUID)
  );

  return o;
};
