import React, { CSSProperties } from 'react';
import {
  canOptionGroupExpand,
  getDefaultHeaderHeight,
  isOptionGroupLoading,
  isOptionGroupOverLimit,
  OptionGroup,
} from '../../../engine/OptionGroup';
import { MdExpandMore } from 'react-icons/md';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { useStore } from '../../../hooks/useStore';
import { useAction } from '../../../hooks/useAction';
import * as Engine from '../../../store/engine/actions';
import LoadingIndicator from '../input/LoadingIndicator';
import truncate from 'lodash/truncate';

interface IProps {
  group: OptionGroup;
  addTopPadding: boolean;
  isFocused?: boolean;

  // group expansion
  onToggleExpansion?: (key: string) => void;
}

const getOptionGroupDisplayName = (group: OptionGroup, themeContext: ITheme, inputText: string) => {
  if (group.type === 'RECOMMENDED' && themeContext.categoryHeader.recommendedHeaderLabel) {
    return themeContext.categoryHeader.recommendedHeaderLabel;
  }

  if (group.type === 'RECENTS' && themeContext.categoryHeader.recentsHeaderLabel) {
    return themeContext.categoryHeader.recentsHeaderLabel;
  }

  if (group.type === 'FALLBACK' && themeContext.categoryHeader.fallbackCommandsHeader) {
    return themeContext.categoryHeader.fallbackCommandsHeader.replace(
      '{{query}}',
      // Truncate the input text to avoid long labels
      truncate(inputText, {
        length: 20,
        omission: '...',
      }),
    );
  }

  return group.name;
};

const OptionGroupHeader = (props: IProps) => {
  const { group } = props;
  const themeContext = useTheme<{ theme: ITheme }>().theme;
  const { searchFilter } = useStore();
  const { inputText } = useStore().engine;
  const focusInput = useAction((_) => _.refContainer?.current?.focus());
  const toggleExpansion = useAction(
    (_) => {
      Engine.toggleGroupExpansion(_, group.key);
      if (props.onToggleExpansion) props.onToggleExpansion(group.key);
    },
    [props.onToggleExpansion, group.key],
  );

  const hideExpansionToggle = (() => {
    const isActiveSearchTab = group.key === searchFilter?.slug;
    return isActiveSearchTab;
  })();

  const { loadingByKey } = useStore();
  const { expandedGroupKeys } = useStore().engine;
  const isLoading = isOptionGroupLoading(group, loadingByKey);

  return (
    <div
      style={{
        paddingTop: themeContext.categoryHeader.paddingTop,
        paddingBottom: themeContext.categoryHeader.paddingBottom,
        paddingLeft: themeContext.categoryHeader.paddingLeft,
        paddingRight: themeContext.categoryHeader.paddingRight,
        color: themeContext.categoryHeader.color,
        fontSize: themeContext.categoryHeader.fontSize,
        opacity: themeContext.categoryHeader.opacity,
        fontWeight: themeContext.categoryHeader.fontWeight,
        lineHeight: themeContext.categoryHeader.lineHeight,
        background: themeContext.categoryHeader.background,
        borderTop: themeContext.categoryHeader.borderTop,
        borderBottom: themeContext.categoryHeader.borderBottom,
        marginTop: props.addTopPadding ? themeContext.optionList.spaceBetweenCategories : 0,
        marginBottom: themeContext.optionList.spaceBelowHeader,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        userSelect: 'none',
        maxHeight: getDefaultHeaderHeight(themeContext, props.addTopPadding),
        textTransform: themeContext.categoryHeader.textTransform as CSSProperties['textTransform'],
      }}
      key={group.key}
      // The sticky header lives outside of the bar, so clicking it transfer focus
      // HACK: on click, transfer focus back to the input
      aria-hidden={true}
      onClick={focusInput}
    >
      <div style={{ display: 'flex', color: 'inherit', alignItems: 'center' }}>
        <span
          role="heading"
          data-testid="option-group-header"
          aria-level={2}
          style={{ height: '100%', color: 'inherit' }}
        >
          {getOptionGroupDisplayName(group, themeContext, inputText)}
        </span>
        <span style={{ width: 5 }} />
        <LoadingIndicator isLoading={isLoading} size={parseInt(themeContext.categoryHeader.loaderSize, 10)} />
      </div>

      <div>
        {isOptionGroupOverLimit(group) && !hideExpansionToggle && (
          <button
            id={'overlimit-' + getOptionGroupDisplayName(group, themeContext, inputText)}
            role="switch"
            tabIndex={props.isFocused ? 0 : -1} // Only make switch accessible when this row is in focus.
            aria-checked={!canOptionGroupExpand(group, expandedGroupKeys)}
            style={{
              display: 'flex',
              background: themeContext.categoryHeader.expandBackground,
              color: themeContext.categoryHeader.expandColor,
              border: themeContext.categoryHeader.expandBorder,
              padding: themeContext.categoryHeader.expandPadding,
              font: themeContext.categoryHeader.expandFont,
              cursor: 'pointer',
            }}
            onClick={toggleExpansion}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontWeight: themeContext.categoryHeader.expandTextFontWeight,
                  color: 'inherit',
                }}
              >
                {canOptionGroupExpand(group, expandedGroupKeys) ? 'See more' : 'See less'}
              </span>
              <span
                style={{
                  marginLeft: themeContext.categoryHeader.expandSpacing,
                  color: 'inherit',
                }}
              >
                <span style={{ fontWeight: themeContext.categoryHeader.numResultsFontWeight }}>({group.size})</span>{' '}
              </span>
              <MdExpandMore
                size={themeContext.categoryHeader.expandIconSize}
                style={{ marginLeft: themeContext.categoryHeader.expandSpacing }}
                className={
                  canOptionGroupExpand(group, expandedGroupKeys)
                    ? 'commandbar-category-expand'
                    : 'commandbar-category-collapse'
                }
              />
            </div>
          </button>
        )}
        {group.size === 0 && group.showWithNoResults && (
          <span style={{ fontWeight: themeContext.categoryHeader.expandTextFontWeight }}>No results</span>
        )}
      </div>
    </div>
  );
};

export default OptionGroupHeader;
