/** @jsx jsx  */
/** @jsxFrag */
import { jsx, css } from '@emotion/core';
import React, { useEffect, useRef } from 'react';

import { useStyles } from './useStyles';
import useTheme from '../../hooks/useTheme';
import { useStore } from '../../hooks/useStore';
import useKeyboardNavigation, { INPUT_METHOD } from '../../hooks/useKeyboardNavigation';
import useHasScroll from '../../hooks/useHasScroll';

import * as Reporting from '../../analytics/Reporting';

import { NoSearchResults } from './NoSearchResults';
import { LeftIcon, MetaHeader, renderRightIcon } from './RecommendationCard';

import type { HelpHubDoc } from '../../store/engine';

type ResultCardProps = {
  doc: HelpHubDoc;
  selectionMethod: INPUT_METHOD;
  active: boolean;
  onClick: () => void;
  onMouseMove?: () => void;
};

export const ResultCard = ({ doc, active, selectionMethod, onClick, onMouseMove }: ResultCardProps) => {
  const styles = useStyles();
  const { theme } = useTheme();
  const activeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (active && selectionMethod === INPUT_METHOD.Keyboard) {
      activeRef?.current && activeRef.current.scrollIntoView({ block: 'center' });
    }
  }, [active, selectionMethod]);

  return (
    // also include styles for the mark element
    <button
      css={[
        {
          ...styles.resultCard,
          ...(active && styles.resultCardActive),
          ...(doc.command.template.type === 'helpdoc' &&
            doc.command.template.doc_type === 'answer' &&
            styles.resultCardAnswer),
        },
        css`
          mark {
            padding: 0;
            color: ${styles.highlightMark.color};
            border-radius: ${styles.highlightMark.borderRadius};
            background-color: ${styles.highlightMark.backgroundColor};
            transition: background-color ${theme.main.transitionTime} ease;
          }
        `,
      ]}
      onClick={onClick}
      onMouseMove={onMouseMove}
      ref={activeRef}
      key={doc.commandID}
      aria-label={doc.title}
    >
      <div css={styles.resultCardIcon} aria-hidden>
        <LeftIcon doc={doc} />
      </div>

      <div css={styles.resultCardTextContainer}>
        {doc.command.template.type === 'helpdoc' && doc.command.template.doc_metadata && (
          <MetaHeader metadata={doc.command.template.doc_metadata} />
        )}

        <div css={styles.resultCardText}>
          <span css={styles.resultCardTitle}>{doc.title}</span>
          <div css={styles.resultCardExcerpt} dangerouslySetInnerHTML={{ __html: doc.excerpt }} />
        </div>
      </div>

      <div css={{ ...styles.resultCardRightCaret, opacity: active ? '1' : '0' }}>{renderRightIcon(doc, theme)}</div>
    </button>
  );
};

const SearchResults = ({ docs, setCurrentDoc }: { docs: HelpHubDoc[]; setCurrentDoc: (doc: HelpHubDoc) => void }) => {
  const scrollRef = useRef(null);
  const { hasScrolled } = useHasScroll(scrollRef);
  const { engine } = useStore();
  const styles = useStyles();

  const { currentIndex, selectionMethod, onMouseMove, onMouseLeave } = useKeyboardNavigation(docs, setCurrentDoc);

  React.useEffect(() => {
    if (hasScrolled) {
      Reporting.helpHubEngagement({ query: engine.helpHub.query }, 'scrolled');
    }
  }, [hasScrolled]);

  const renderContent = () => {
    if (docs.length === 0) {
      return <NoSearchResults />;
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div css={styles.resultListContainer} role="list" onMouseLeave={onMouseLeave}>
        {docs.map((doc: HelpHubDoc, index: number) => {
          const active = currentIndex === index;

          return (
            <ResultCard
              key={doc.commandID}
              doc={doc}
              onClick={() => setCurrentDoc(doc)}
              active={active}
              selectionMethod={selectionMethod}
              onMouseMove={() => onMouseMove(index)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div css={styles.resultList} ref={scrollRef}>
      <div css={styles.resultListHeader}>
        <h1 css={styles.resultListHeaderTitle}>Search Results</h1>
        <span css={styles.resultListHeaderResultsFound}>
          {docs.length} item{docs.length === 1 ? '' : 's'} found
        </span>
      </div>
      {renderContent()}
    </div>
  );
};

export default SearchResults;
