import * as t from 'io-ts';
import { CommandV } from './command';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';

import { AudienceV } from './helpers/audience';
import { RuleExpressionV } from './helpers/rules';
import { IRecommendationSet } from './types';

const RecommendationSetReadOnlyV = t.type(
  {
    commands: t.array(CommandV),
  },
  'RecommendationSetReadOnly',
);

const EditorRecommendationSetAdditionalV = t.type(
  {
    command_ids: t.array(t.number),
  },
  'EditorRecommendationSetAdditional',
);

const RecommendationSetBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      widget: t.string,
      default: t.boolean,
      name: t.string,
      show_expression: RuleExpressionV,
      audience: AudienceV,
    }),
    t.partial({}),
  ],
  'RecommendationSetBase',
);

const RecommendationSetAdditionalV = t.type({}, 'RecommendationSetAdditional');
type IRecommendationSetAdditional = t.TypeOf<typeof RecommendationSetAdditionalV>;

export const RecommendationSetV = t.intersection(
  [RecommendationSetAdditionalV, RecommendationSetBaseV, RecommendationSetReadOnlyV],
  'RecommendationSet',
);

export const EditorRecommendationSetV = t.intersection(
  [RecommendationSetAdditionalV, RecommendationSetBaseV, EditorRecommendationSetAdditionalV],
  'RecommendationSet',
);

const defaults: IRecommendationSetAdditional = {};

export class RecommendationSet {
  public static decode = (data: any) => {
    const recommendationSet: IRecommendationSet = { ...defaults, ...data };

    return decodeThrowing(RecommendationSetV, recommendationSet);
  };

  public static create = createObject(EditorRecommendationSetV, EditorRecommendationSetV, 'recommendation_sets');

  public static update = updateObject(EditorRecommendationSetV, EditorRecommendationSetV, 'recommendation_sets');

  public static delete = deleteObject(EditorRecommendationSetV, 'recommendation_sets');
  public static list = listObject(EditorRecommendationSetV, 'recommendation_sets');
  public static read = readObject(EditorRecommendationSetV, 'recommendation_sets');
}
