/** @jsx jsx  */
import { jsx } from '@emotion/core';
import React, { useMemo, useEffect } from 'react';
import { useStyles } from './useStyles';
import Icon from '@commandbar/internal/client/Icon';
import { useStore } from '../../hooks/useStore';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import { ReactComponent as AdditionalResourcesArrow } from './additional_resources_arrow.svg';
import { IAdditionalResource } from '@commandbar/internal/middleware/types';
import { getCommandById } from '../../store/engine';

const AdditionalResources = () => {
  const storageKey = 'commandbar.helphub.resourcesIsExpanded';
  const storedIsExpanded = sessionStorage.getItem(storageKey);
  const [isExpanded, setIsExpanded] = React.useState(storedIsExpanded ? JSON.parse(storedIsExpanded) : true);

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(isExpanded));
  }, [isExpanded]);

  const styles = useStyles();
  const { engine } = useStore();
  const { helpHub } = engine;
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);
  const executeAction = useAction((_, action: IAdditionalResource['action']) => {
    Engine.executeAction(_, action);

    const isHelpDoc =
      action.type === 'execute_command' && getCommandById(engine, action.meta.command)?.template.type === 'helpdoc';
    if (!isHelpDoc) {
      toggleHelpHubVisible();
    }
  });

  const additionalResources = helpHub.additionalResources
    .filter((resource) => !resource.show_as_primary_cta)
    .sort((a, b) => a.sort_key - b.sort_key);

  const primaryCTA = useMemo(() => {
    return helpHub.additionalResources.find((resource) => resource.show_as_primary_cta);
  }, [helpHub.additionalResources]);

  const additionalResourcesContent = additionalResources.length > 0 && (
    <div>
      <div
        css={styles.additionalResourcesTitle}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsExpanded(!isExpanded);
        }}
        role="button"
        tabIndex={0}
      >
        Additional Resources
        <div css={isExpanded ? styles.additionalResourcesArrowExpanded : styles.additionalResourcesArrowCollapsed}>
          <AdditionalResourcesArrow />
        </div>
      </div>
      <div css={styles.additionalResourcesLinkContainer} style={{ display: isExpanded ? 'flex' : 'none' }}>
        {additionalResources.map((resource) => (
          <div
            css={styles.additionalResourcesLink}
            onClick={() => executeAction(resource.action)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') executeAction(resource.action);
            }}
            role="button"
            tabIndex={0}
            key={resource.id}
          >
            <div css={styles.additionalResourcesLinkIcon}>
              <Icon
                color={
                  styles.additionalResourcesLinkIcon.color !== '#000000'
                    ? styles.additionalResourcesLinkIcon.color
                    : resource.icon_color
                }
                icon={resource.icon}
                useDefaultSVGColor={true}
                size={styles.additionalResourcesLinkIcon.height}
              />
            </div>
            <div>{resource.label}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const primaryCTAContent = primaryCTA && primaryCTA.label.trim() !== '' && (
    <button
      css={[styles.additionalResourcesCTA, { marginTop: additionalResources.length > 0 ? '16px' : '0px' }]}
      onClick={() => executeAction(primaryCTA.action)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') executeAction(primaryCTA.action);
      }}
      tabIndex={0}
    >
      {primaryCTA.label}
    </button>
  );

  if (!(primaryCTAContent || additionalResourcesContent)) return null;

  return (
    <div css={styles.additionalResourcesContainer}>
      {additionalResourcesContent}
      {primaryCTAContent}
    </div>
  );
};

export default AdditionalResources;
