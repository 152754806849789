import React from 'react';
import { init, initSubs as initSubscriptions } from '../store';
import { devtools } from 'valtio/utils';
import { ref } from 'valtio';
import { StoreContext } from './StoreContext';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

let globalInstanceId = 0;

export const StoreProvider = (props: { children: React.ReactNode }) => {
  const store = React.useRef(init());
  React.useEffect(() => initSubscriptions(store.current), []);
  store.current.refContainer = ref(React.useRef(null));
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') return;
    if (!LocalStorage.get('logChanges', false)) return;
    return devtools(store.current, `CommandBar:${globalInstanceId++}`);
  }, []);
  if (process.env.NODE_ENV !== 'production') {
    Object.assign(window, { CB: store.current });
  }
  return <StoreContext.Provider value={store.current}>{props.children}</StoreContext.Provider>;
};
