/**
 * Warning: We might unship this component
 * Currently used for FreshWorks only. Should think about whether we want this to be a primitive
 *   before having other folks on it
 */
import { CustomComponent } from '@commandbar/internal/client/CommandBarClientSDK';
import React, { FunctionComponent } from 'react';
import ExecutionPath from '../engine/ExecutionPath';
import { StepType } from '../engine/step/Step';
import { useCommandBarTheme } from '../hooks/useCommandBarTheme';

import { useStore } from '../hooks/useStore';
import RenderCustomComponent from './RenderCustomComponent';
import SearchTabs from './select/SearchTabs';

const VerticalNavPane: FunctionComponent = () => {
  const { userDefinedCustomComponents, engine } = useStore();
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const theme = useCommandBarTheme();

  const inBaseState = currentStep?.type === StepType.Base && !currentStep?.resource;
  const showVerticalSearchTabs = engine.organization?.tab_direction === 'vertical';

  if (!inBaseState || !showVerticalSearchTabs) {
    return null;
  }

  const HTMLComponent = (component: string | CustomComponent | null, key: string) => {
    if (typeof component === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: component }} key={key} />;
    } else if (component) {
      return <RenderCustomComponent component={component} key={key} />;
    }
  };

  const children = [
    HTMLComponent(userDefinedCustomComponents.navPaneHeader({ step: currentStep?.type }), 'search-tabs-header'),
    showVerticalSearchTabs ? (
      <div key="search-tabs-container" style={{ flexGrow: 1 }}>
        <SearchTabs direction="vertical" />
      </div>
    ) : null,
    HTMLComponent(userDefinedCustomComponents.navPaneFooter({ step: currentStep?.type }), 'search-tabs-footer'),
  ].filter((v) => !!v);

  if (children.length > 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: theme.navPane.background,
          borderRight: theme.navPane.borderRight,
          width: theme.navPane.width,
          borderRadius: `${theme.main.borderRadius} 0px 0px ${theme.main.borderRadius}`,
        }}
      >
        {children}
      </div>
    );
  }

  return null;
};

export default VerticalNavPane;
