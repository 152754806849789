import React from 'react';
import { CommandOption } from '../../engine/option';
import { UnderlineFormatter } from '../../engine/option/UnderlineFormatter';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { useStore } from '../../hooks/useStore';
import { isFallbackCommandWithFirstStepTextInput } from '../../store/engine';
import { OptionHighlight } from './OptionHighlight';

export interface Props {
  option: CommandOption;
}

export const CommandOptionLabel = ({ option }: Props) => {
  const theme = useCommandBarTheme();
  const { inputText } = useStore().engine;

  /**
   * If current option is a fallback command option and the first argument is a text input,
   * then we highlight the interpolated text input.
   */
  if (isFallbackCommandWithFirstStepTextInput(option)) {
    return <UnderlineFormatter prefix={option.label} suffix={inputText} delimiter={`:`} theme={theme} />;
  }

  return (
    <span style={{ color: 'inherit' }}>
      <OptionHighlight option={option} labelToHighlight="label" text={option.label} />
    </span>
  );
};
