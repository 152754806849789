/*
  This hook's purpose is to save the element that had focus when CommandBar opened,
  and then return focus to it when CommandBar closes. E.g., if a user was in an input
  before opening CommandBar, the input will be refocused after CommandBar closes.

  It works by listening for each blur event outside the #commandbar element, and
  storing a reference to the blurred element. If the blur event happens within
  a specified time window before CommandBar opens, then re-focus it once CommandBar
  closes.
 */

import { getSentry } from '@commandbar/internal/util/sentry';
import { useEffect, useRef } from 'react';

const isEventTargetFocusable = (eventTarget: EventTarget | null) =>
  eventTarget && eventTarget instanceof Element && !eventTarget.closest('#commandbar');

const isElementExistsInDOM = (element: Element) => document.body.contains(element);

export const useFocusReturnToPreviousElement = (isCommandBarVisible: boolean) => {
  const sentry = getSentry();

  const lastFocusedElement = useRef<Element | null>(null);
  const lastBlurEventTimestamp = useRef(0);

  const blurEventHandler = (event: FocusEvent) => {
    const { target } = event;

    if (isEventTargetFocusable(target)) {
      lastFocusedElement.current = target as Element;
      lastBlurEventTimestamp.current = Date.now();
    }
  };

  useEffect(() => {
    try {
      if (isCommandBarVisible) {
        const elementWasFocusedRecently =
          lastFocusedElement.current && Date.now() - lastBlurEventTimestamp.current < 500;

        if (!elementWasFocusedRecently) {
          lastFocusedElement.current = null;
        }
      }

      if (!isCommandBarVisible && lastFocusedElement.current && isElementExistsInDOM(lastFocusedElement.current)) {
        setTimeout(() => {
          (lastFocusedElement.current as HTMLElement).focus({ preventScroll: true });
        }, 1);
      }
    } catch (err) {
      sentry?.captureException(err);
    }
  }, [isCommandBarVisible]);

  useEffect(() => {
    document.addEventListener('blur', blurEventHandler, true);

    return () => {
      document.removeEventListener('blur', blurEventHandler);
    };
  }, []);
};
