/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { useStore } from '../../../hooks/useStore';
import { useAction } from '../../../hooks/useAction';
import * as App from '../../../store/app/actions';
import a11y from '../../../util/a11y';
import SelectOption from './SelectOption';
import {
  getCategoryIcon,
  getCategoryImage,
  getCommandImage,
  getCommandIcon,
  isCommandOption,
  isOptionGroup,
  isParameterOption,
  isParameterOptionSelected,
  isResourceOption,
  getRecordCategoryImage,
  getRecordCategoryIcon,
  getReservedField,
} from '../../../store/engine';
import { Option } from '../../../engine/option';
import ExecutionPath from '../../../engine/ExecutionPath';
import Icon from '@commandbar/internal/client/Icon';
import { OptionGroup } from '../../../engine/OptionGroup';

interface GridItemProps {
  option: Option | OptionGroup;
  index: number;
  onOptionHover?: (index: number) => void;
}

export const GridItem = (props: GridItemProps) => {
  const _ = useStore();
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  const categories = _.engine;
  const { errorIndex, focusedIndex } = _.engine;

  const { theme }: { theme: ITheme } = useTheme();
  const { index, onOptionHover, option } = props;

  const refocusCommandbarInput = useAction((_) => _.refContainer?.current?.focus());

  const onMouseMove = React.useCallback(() => {
    if (onOptionHover) onOptionHover(index);
  }, [onOptionHover, index]);

  const onClick = useAction(
    (_, e: React.MouseEvent<HTMLDivElement>) => {
      if (!isOptionGroup(props.option)) {
        App.selectOption(_, props.option, undefined, e);
      }

      // HACK: Normally, clicking on a command will change the Options, which will have the
      // side effect of focusing the main Bar input text field. Or, it will execute the command
      // and close the bar, and then the focus state of the Bar input text field won't matter.
      //
      // However, if clicking the command *doesn't* cause a change in the options or close the Bar,
      // (e.g. this happens if the command is disabled), then the Bar input looses focus.
      //
      // We can fix this HACK when/if we re-factor Bar input focus handling
      refocusCommandbarInput();
    },
    [props.option],
  );

  const image: { src: string | null; color: string | null } = React.useMemo(() => {
    if (isCommandOption(option)) {
      if (option.command.category) {
        const { image: category_image, image_color: category_image_color } = getCategoryImage(_, option);
        const { icon: category_icon, icon_color: category_icon_color } = getCategoryIcon(_, option);
        const { image: command_image, image_color: command_image_color } = getCommandImage(_, option);
        const { icon: command_icon, icon_color: command_icon_color } = getCommandIcon(_, option);

        if (category_image) {
          return { src: category_image, color: category_image_color };
        } else if (command_image) {
          return { src: command_image, color: command_image_color };
        } else if (category_icon) {
          return { src: category_icon, color: category_icon_color };
        } else if (command_icon) {
          return { src: command_icon, color: command_icon_color };
        }

        return { src: null, color: null };
      }
    }

    if (isResourceOption(option)) {
      const recordImage: string | undefined = getReservedField(option, '__image');
      const recordIcon: string | undefined = getReservedField(option, 'icon');

      const { image: category_image, image_color: category_image_color } = getRecordCategoryImage(option);
      const { icon: category_icon, icon_color: category_icon_color } = getRecordCategoryIcon(option);

      if (recordImage) {
        return { src: recordImage, color: null };
      } else if (category_image) {
        return { src: category_image, color: category_image_color };
      } else if (recordIcon) {
        return { src: recordIcon, color: null };
      } else if (category_icon) {
        return { src: category_icon, color: category_icon_color };
      }
      return { src: null, color: null };
    }

    if (isParameterOption(option)) {
      const image = getReservedField(option, '__image');
      const icon = getReservedField(option, 'icon');
      return { src: image || icon, color: null };
    }

    return { src: null, color: null };
  }, [categories, option]);

  if (isOptionGroup(option)) {
    return null;
  }

  return (
    // eslint-disable-next-line
    <div
      onClick={onClick}
      role={a11y.optionRole}
      onMouseMove={onMouseMove}
      style={{
        border:
          isParameterOption(option) && currentStep && isParameterOptionSelected(option, currentStep)
            ? `2px solid ${theme.menuGridItemCardSelected.borderColor}`
            : undefined,
        cursor: theme.menuGridOption.cursor,
        display: 'flex',
        flexDirection: 'column',
        background: theme.menuGridItemCard.background,
        paddingTop: theme.menuGridItemCard.paddingTop,
        paddingBottom: theme.menuGridItemCard.paddingBottom,
        paddingLeft: theme.menuGridItemCard.paddingLeft,
        paddingRight: theme.menuGridItemCard.paddingRight,
        overflow: 'hidden',
        transition: 'box-shadow .3s ease',
        borderRadius: theme.menuGridItemCard.borderRadius,
        boxShadow: focusedIndex === index ? theme.menuGridItemCard.hoverBoxShadow : theme.menuGridItemCard.boxShadow,
      }}
    >
      {/* Card Header */}
      <div
        style={{
          width: '100%',
          display: theme.menuGridItemCard.coverImageDisplay === 'none' ? 'none' : 'block',
          textAlign: 'center',
          color: theme.base.fontColor,
        }}
      >
        {image.src ? (
          <Icon
            color={image.color}
            style={{
              width: '100%',
              height: '100%',
              aspectRatio: '4 / 3',
              objectFit: 'cover',
              borderRadius: theme.menuGridItemCard.borderRadius,
              // Size of emoji icons
              fontSize: '50px',
            }}
            size="100%"
            icon={image.src}
            useDefaultSVGColor={true}
          />
        ) : (
          <div
            style={{
              width: '100%',
              aspectRatio: '4 / 3',
              background: `linear-gradient(45deg, ${theme.base.primary}, ${theme.base.background})`,
            }}
          ></div>
        )}
      </div>
      {/* Card Body */}
      <div style={{ flex: 1 }}>
        <SelectOption
          index={index}
          isError={errorIndex === index}
          isFocused={focusedIndex === index}
          onOptionHover={undefined}
          option={option}
        />
      </div>
    </div>
  );
};
