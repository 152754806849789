import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import { EngineState } from '../state';

export const dontShowChecklist = (_: EngineState, checklist: IChecklist) => {
  const checklistData = getChecklistData(_, checklist);
  return checklistData?.isCompleted || checklistData?.isSkipped;
};

export const previouslyShownChecklist = (_: EngineState) => {
  return _.engine.checklists.find((checklist) => {
    return checklist.id === _.engine.endUserStore.data.checklist_interactions?.activeChecklistId;
  });
};

export const getChecklistData = (_: EngineState, checklist: IChecklist) => {
  return _.engine.endUserStore.data.checklist_interactions?.checklists?.[checklist.id];
};

export const isItemCompleted = (_: EngineState, parent: IChecklist, item: IChecklistItem) => {
  return !!getChecklistData(_, parent)?.items?.[item.id]?.completedTS;
};

export const getChecklistProgress = (_: EngineState, checklist: IChecklist) => {
  const itemData = getChecklistData(_, checklist)?.items;
  const completedItems = checklist.items.filter((item) => !!itemData?.[item.id]?.completedTS).length;
  const totalItems = checklist.items.length;
  return { completedItems, totalItems };
};

export const hasIncompleteItems = (_: EngineState, checklist: IChecklist) => {
  const itemData = getChecklistData(_, checklist)?.items || {};

  for (const item of checklist.items) {
    if (!itemData[item.id]?.completedTS) {
      return true;
    }
  }
  return false;
};

export const isExpanded = (_: EngineState, checklist: IChecklist) => {
  const expandedState = getChecklistData(_, checklist)?.isExpanded;
  return expandedState === undefined ? checklist.open_by_default : expandedState;
};

export const isChecklistInPreviewMode = (checklist: IChecklist) => {
  return !!checklist._editorState;
};
