import { EventSubscriber } from '@commandbar/internal/client/EventHandler';
import { getSDK } from '@commandbar/internal/client/globals';
import { _eventSubscriptions } from '@commandbar/internal/client/symbols';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

function toFlatObject(obj: object, keySeparator = '.') {
  const flattenRecursive = (obj: object, parentProperty?: string, propertyMap: Record<string, unknown> = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}

export function setupHeapIntegration(integrations: IOrganizationType['integrations']) {
  if (!!integrations?.heap?.send_events_to_heap) {
    const sdk = getSDK();

    const eventSubscriptions = sdk[_eventSubscriptions];

    if (!eventSubscriptions) return;

    const sendEventToHeap: EventSubscriber = (name, data) => {
      const heap = (window as any).heap;
      if (!heap) return;

      heap.track('CommandBar ' + name, toFlatObject(data));
    };

    const s = Symbol();
    eventSubscriptions.set(s, sendEventToHeap);
  }
}

function isNoCodeAlgoliaIntegration(integration: IOrganizationType['integrations']): integration is {
  algolia: { indexes: { [x: string]: string }; applicationID: string; apiKey: string };
} {
  return (
    integration.algolia !== undefined &&
    integration.algolia.apiKey !== undefined &&
    integration.algolia.applicationID !== undefined &&
    typeof integration.algolia.indexes === 'object' &&
    integration.algolia.indexes !== null &&
    Object.keys(integration.algolia.indexes).length > 0
  );
}

export function setupAlgoliaIntegration(integrations: IOrganizationType['integrations']) {
  if (isNoCodeAlgoliaIntegration(integrations)) {
    const algoliaIndexes = integrations.algolia.indexes;

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'X-Algolia-Application-Id': integrations.algolia.applicationID,
        'X-Algolia-API-Key': integrations.algolia.apiKey,
      }),
    };

    const sdk = getSDK();

    for (const [key, value] of Object.entries(algoliaIndexes)) {
      sdk.addRecords(key, [], {
        onInputChange: async (query) => {
          const response = await fetch(
            `https://${integrations.algolia.applicationID}-dsn.algolia.net/1/indexes/${key}/query`,
            {
              ...requestOptions,
              body: JSON.stringify({ query }),
            },
          );
          const { hits } = await response.json();
          return (
            hits as {
              [key: string]: any;
            }[]
          ).map((hit) => ({
            ...hit,
            icon: hit[value],
          }));
        },
      });
    }

    return () => {
      for (const [key] of Object.entries(algoliaIndexes)) {
        sdk.removeContext(key);
      }
    };
  }
}

export function tearDownAlgoliaIntegration(integrations: IOrganizationType['integrations']) {
  if (isNoCodeAlgoliaIntegration(integrations)) {
    const algoliaIndexes = integrations.algolia.indexes;

    const sdk = getSDK();

    for (const [key] of Object.entries(algoliaIndexes)) {
      sdk.removeContext(key);
    }
  }
}
