export type TUpdateEditorRouteDetails =
  | { type: 'nudge'; nudgeId: string | number; stepId: string | number }
  | { type: 'checklist'; checklistId: string | number }
  | { type: 'theme'; route_replacement: string };

export function dispatchCustomEvent<T>(eventType: string, detail: T, canBubble = false, cancelable = false) {
  const ev: CustomEvent<T> = document.createEvent('CustomEvent');
  ev.initCustomEvent(eventType, canBubble, cancelable, detail);
  document.dispatchEvent(ev);
  return ev;
}
