import React from 'react';
import { useStore } from '../../../hooks/useStore';
import { Option } from '../../../engine/option';
import { OptionGroup } from '../../../engine/OptionGroup';
import { VirtualItem } from 'react-virtual/types';
import a11y from '../../../util/a11y';
import OptionGroupHeader from './OptionGroupHeader';
import SelectOption from './SelectOption';
import { useTheme } from 'emotion-theming';
import { isOptionGroup } from '../../../store/engine';
import { ITheme } from '@commandbar/internal/client/theme';
import { GridItem } from './GridItem';

interface MenuRowOption {
  type: 'single';
  item: Option;
  index: number;
}

interface MenuRowGrid {
  type: 'grid';
  item: Option[];
  index: number;
}

interface MenuRowHeader {
  type: 'header';
  item: OptionGroup;
  index: number;
}

export type MenuRowContent = MenuRowOption | MenuRowGrid | MenuRowHeader;

export function hideItem(index: number, menuRow: MenuRowContent) {
  const isEmptyHeaderAtTop = menuRow.type === 'header' && index === 0 && menuRow.item.name.length === 0;
  return isEmptyHeaderAtTop;
}

export interface Props extends VirtualItem {
  onOptionHover: (index: number) => void;
  onToggleOptionGroup: () => void;
  groupPositions: Record<string, number>;
  content: MenuRowContent;
}

export const MenuRow = (props: Props) => {
  const { theme }: { theme: ITheme } = useTheme();

  const engine = useStore().engine;
  const { focusedIndex, errorIndex } = engine;

  if (!props?.content?.item || hideItem(props.index, props.content)) {
    return <div style={{ height: 0 }} ref={(ref) => props.measureRef(ref)} />;
  }

  if (props.content.type === 'grid') {
    return (
      <>
        <div
          ref={(ref) => props.measureRef(ref)}
          style={{
            display: 'grid',
            gap: theme.menuGridContainer.itemGap,
            gridTemplateColumns: `repeat(${theme.menuGridContainer.itemsPerRow}, 1fr)`,
            gridAutoRows: theme.menuGridContainer.minRowHeight
              ? `minmax(${theme.menuGridContainer.minRowHeight}, auto)`
              : 'auto',
            position: 'absolute',
            transform: `translateY(${props.start}px)`,
            width: '100%',
            height: 'auto',
            minHeight: theme.menuGridContainer.minRowHeight,
            paddingLeft: theme.menuGridContainer.paddingLeft,
            paddingRight: theme.menuGridContainer.paddingRight,
            paddingTop: theme.menuGridContainer.paddingTop,
            paddingBottom: theme.menuGridContainer.paddingBottom,
          }}
        >
          {props.content.item.map((option, index) =>
            isOptionGroup(option) ? null : (
              <GridItem key={index} option={option} index={props.index + index} onOptionHover={props.onOptionHover} />
            ),
          )}
        </div>
      </>
    );
  }

  if (props.content.type === 'single' || props.content.type === 'header') {
    return (
      <div
        role={props.content.type === 'header' ? a11y.groupRole : a11y.optionRole}
        aria-label={props.content.type === 'header' ? a11y.categoryId(props.content.item) : undefined}
        aria-describedby={
          props.content.type === 'single' && props.content.item.groupKey
            ? a11y.optionId(props.groupPositions[props.content.item.groupKey])
            : undefined
        }
        id={a11y.optionId(props.index)}
        aria-selected={props.index === focusedIndex}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${props.start}px)`,
          ...(props.content.type === 'single' ? { cursor: theme.option.cursor } : {}),
          zIndex: 1,
        }}
        ref={(ref) => props.measureRef(ref)}
      >
        {props.content.type === 'header' ? (
          <OptionGroupHeader
            group={props.content.item}
            isFocused={focusedIndex === props.index}
            onToggleExpansion={props.onToggleOptionGroup}
            addTopPadding={props.index > 0}
          />
        ) : (
          <>
            <SelectOption
              option={props.content.item as Option}
              onOptionHover={props.onOptionHover}
              isFocused={focusedIndex === props.index}
              isError={errorIndex === props.index}
              index={props.index}
            />
          </>
        )}
      </div>
    );
  }
  return null;
};

export default MenuRow;
