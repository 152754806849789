import { assign, createMachine, Interpreter } from 'xstate';

type Context = {
  inputText: string;
  results: any[] | null;
};

type CallbackResult = any[] | { data: any[] };

type Event =
  | { type: 'set input text'; inputText: string }
  | {
      type: 'done.invoke.query';
      data: CallbackResult;
    };

export default function mkMachine(callback: (input: string) => Promise<CallbackResult>, onError: (e: Error) => void) {
  return createMachine(
    {
      // https://xstate.js.org/docs/guides/actions.html
      predictableActionArguments: true,

      context: { inputText: '', results: null },
      tsTypes: {} as import('./get-records-machine.typegen').Typegen0,
      schema: { context: {} as Context, events: {} as Event },
      id: 'Get Records from Search Function',
      initial: 'initial',
      states: {
        loading: {
          invoke: {
            src: 'query',
            id: 'query',
            onDone: [
              {
                actions: 'set results',
                target: 'done',
              },
            ],
            onError: [
              {
                actions: 'log error',
                target: 'failure',
              },
            ],
          },
          tags: 'loading',
        },
        failure: {},
        initial: {},
        debounce: {
          after: {
            '150': {
              target: 'loading',
            },
          },
          tags: 'loading',
        },
        done: {},
        'handle input text': {
          always: [
            {
              cond: 'input text is empty',
              target: 'initial',
            },
            {
              target: 'debounce',
            },
          ],
        },
      },
      on: {
        'set input text': {
          actions: ['clear results', 'set input text'],
          target: '.handle input text',
        },
      },
    },
    {
      services: {
        query: async (context) => await callback(context.inputText),
      },
      actions: {
        'set input text': assign({
          inputText: (_, event) => event.inputText,
        }),
        'set results': assign((context, event) => {
          // event.data is either itself an array of results,
          // or an object with shape {data: any[]; pagination: any}
          const data: any[] | { data: any[] } = event.data;

          if (Array.isArray(data)) {
            return { results: data };
          } else {
            console.warn('Pagination is no longer supported with records.');
            return { results: data.data };
          }
        }),
        'clear results': assign((_context) => {
          return { results: null };
        }),
        'log error': (_context, event) => {
          onError(event.data as Error);
        },
      },
      guards: {
        'input text is empty': (context) => context.inputText === '',
      },
    },
  );
}

export type GetRecordsService = Interpreter<Context, any, Event, { value: any; context: Context }, any>;
