import { Step, MultiSelectStep } from '../../../engine/step';
import type { Option } from '../../../engine/option';
import DateTime from '../../../engine/predefinedTypes/DateTime/DateTime';
import isEqual from 'lodash/isEqual';
import { isParameterOption, isDateTimeOption } from '../options';
import { initMultiSelectStep } from '../../../engine/step/MultiSelectStep';
import { getArgumentChoiceLabel } from '../selectors';
import { State } from '../..';
import { getContextSettings } from '../selectors';

export const getMultiSelectStepBlock = (s: MultiSelectStep, engine: State['engine']): string | undefined => {
  if (s.selected === null) {
    return s.argument.userDefinedName;
  }

  if (s.selected.type !== 'parameter') {
    return s.argument.userDefinedName;
  }

  // Note: This block simply tries to catch the different possibilities
  // Since these fields are defined by us,
  // we should try to agree to a standard data structure for these selected elements
  if (!Array.isArray(s.selected.data)) {
    throw new Error('Multi select selected must be an array');
  } else {
    if (s.selected.data.length === 0) {
      return s.argument.userDefinedName;
    }
    const contextSettings = getContextSettings(engine);
    const selectedChoicesLabels: string[] = s.selected.data.map((val: unknown) => {
      return getArgumentChoiceLabel(s.argument, val, contextSettings);
    });
    return selectedChoicesLabels.join(', ');
  }
};

export const selectMultiSelectStep = (s: MultiSelectStep, o: Option): Step => {
  if (!isParameterOption(o)) {
    return s;
  }

  // Add created flag if applicable
  let newParam = o.parameter;
  if (isDateTimeOption(o)) {
    newParam = DateTime.clientReturnValue(o.parameter.date);
  }

  // For a multiselect step, toggle whether the parameter is selected
  //     If it's already selected, remove it. If not, add it.
  const newSelections: any[] = s.selected?.data ? [...s.selected.data] : [];
  const index = newSelections.findIndex((s: any) => isEqual(s, newParam));
  if (index > -1) {
    newSelections.splice(index, 1);
  } else {
    newSelections.push(newParam);
  }

  const newStep = initMultiSelectStep(s.argument);
  newStep.selected = {
    type: 'parameter',
    category: null,
    data: newSelections,
  };
  newStep.completed = s.completed;

  return newStep;
};
