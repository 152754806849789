import { AppState, initAppState } from './app/state';
import { EngineState, initEngineState } from './engine/state';
import { initAppSubs } from './app/subscriptions';
import { initEngineSubs } from './engine/subscriptions';
import { proxy } from 'valtio';
import * as AppActions from './app/actions';
import * as AppSelectors from './app/selectors';
import * as EngineActions from './engine/actions';
import * as EngineSelectors from './engine/selectors';
import * as EngineHelpers from './engine/helpers';

export type State = AppState & EngineState;

export const Actions = {
  ...AppActions,
  ...EngineActions,
};

export const Selectors = {
  ...AppSelectors,
  ...EngineSelectors,
};

export const MutationHelpers = {
  ...EngineHelpers,
};

export const init = (): State =>
  proxy({
    ...initAppState(),
    ...initEngineState(),
  });

export const initSubs = (_: State): VoidFunction => {
  const unsubs = [...initAppSubs(_), ...initEngineSubs(_)];
  return () => unsubs.forEach((unsub) => unsub());
};
