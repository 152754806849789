/** @jsx jsx  */
import { jsx } from '@emotion/core';
import { useState, useRef, useEffect, Fragment } from 'react';
import { useStyles } from './useStyles';
import { useStore } from '../../hooks/useStore';
import { ReactComponent as CloseIcon } from '../../img/xclose.svg';
import LoadingIndicator from '../select/input/LoadingIndicator';
import AskAIButton from './AskAIButton';
import { ReactComponent as SearchIcon } from '../../img/search-md.svg';
import LoadingSkeletion from './LoadingSkeletion';

const Input = ({
  query,
  onChangeQuery,
  onSubmit,
  suggestionsState,
  children,
  setIsChatMode,
}: {
  query: string | null;
  onChangeQuery: (query: string) => void;
  onSubmit: (query: string) => void;
  suggestionsState: 'none' | 'loading' | 'loaded';
  children?: any;
  setIsChatMode?: (isChatMode: boolean) => void;
}) => {
  const styles = useStyles();
  const { engine } = useStore();
  const { loading: isLoading } = engine.helpHub;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  let inputPaddingRight = 64;
  if (engine?.organization?.helphub_ai_enabled && !inputFocused) {
    inputPaddingRight += 60;
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      /* eslint-disable */
      if (inputContainerRef.current && !inputContainerRef.current.contains(event.target as Node)) {
        setInputFocused(false);
      } else if (inputRef.current?.contains(event.target as Node)) {
        setInputFocused(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputContainerRef]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div ref={inputContainerRef} css={styles.inputContainer} id="helphub-input-container">
      <div
        css={{
          ...styles.inputWrapper,
          ...(inputFocused ? styles.inputWrapperFocused : {}),
        }}
      >
        <input
          value={query ?? ''}
          onChange={(e) => onChangeQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (e.shiftKey && setIsChatMode) {
                setIsChatMode(true);
              } else {
                onSubmit(query ?? '');
                setInputFocused(false);
                inputRef.current?.blur();
              }
            }
          }}
          css={{
            ...styles.input,
            paddingRight: inputPaddingRight,
          }}
          placeholder={
            engine?.organization?.helphub_ai_enabled ? 'Search or ask a question...' : 'What can we help you find?'
          }
          onBlur={(e) => {
            if (query?.length === 0) {
              onSubmit('');
            }
          }}
          ref={inputRef}
        />
        {query && query.length > 0 && !isLoading && (
          <CloseIcon
            style={{
              ...styles.closeIcon,
              right: !engine?.organization?.helphub_ai_enabled || inputFocused ? '14px' : '103px',
            }}
            onClick={() => {
              onChangeQuery('');
              onSubmit('');
              setInputFocused(true);
              inputRef.current?.focus();
            }}
          />
        )}
        {isLoading && query?.length !== 0 ? (
          <LoadingIndicator
            style={{
              ...styles.closeIcon,
              top: '14px',
              right: '14px',
            }}
            size={22}
            isLoading
          />
        ) : engine?.organization?.helphub_ai_enabled && setIsChatMode && !inputFocused ? (
          <div style={styles.inlineAskAi}>
            <AskAIButton onClick={() => setIsChatMode(true)} />
          </div>
        ) : null}
        <div
          style={{
            display:
              engine?.organization?.helphub_ai_enabled && inputFocused && suggestionsState !== 'none' ? 'flex' : 'none',
            ...styles.suggestionsContainer,
          }}
        >
          {suggestionsState !== 'loaded' ? (
            <Fragment>
              <LoadingSkeletion percentWidth={90} />
              <LoadingSkeletion percentWidth={70} />
            </Fragment>
          ) : (
            children
          )}
        </div>
        {inputFocused && engine?.organization?.helphub_ai_enabled && (
          <div style={styles.largeAskAIButtonContainer}>
            <button
              css={{ ...styles.searchDocsButton }}
              onClick={() => {
                if (query && query.length > 0) {
                  onSubmit(query);
                  setInputFocused(false);
                  inputRef.current?.blur();
                } else {
                  setInputFocused(true);
                  inputRef.current?.focus();
                }
              }}
            >
              <SearchIcon role="img" focusable={false} />
              Search
            </button>

            <div style={{ flex: 1, position: 'relative' }}>
              <div style={{ top: '50%', position: 'absolute', right: '0', width: '100%' }}>
                <AskAIButton onClick={() => setIsChatMode && setIsChatMode(true)} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
