import { ExecutionEventSource } from '@commandbar/internal/client/EventHandler';
import { ICommandType } from '@commandbar/internal/middleware/types';
import { GenericStep, initStep, StepType } from './Step';

export interface ExecuteStep extends GenericStep<StepType.Execute> {
  command: ICommandType;
  selected: null;
  triggeredByShortcut: boolean;
  eventSource: ExecutionEventSource;
  alternateBehavior?: { openLinkInNewTab?: boolean };
}

export const initExecuteStep = (
  command: ICommandType,
  triggeredByShortcut: boolean,
  executionEventSource: ExecutionEventSource,
  alternateBehavior?: { openLinkInNewTab?: boolean },
): ExecuteStep => {
  const s = initStep(StepType.Execute) as ExecuteStep;
  s.command = command;
  s.selected = null;
  s.triggeredByShortcut = triggeredByShortcut;
  s.eventSource = executionEventSource;
  s.alternateBehavior = alternateBehavior;
  return s;
};
