// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Link04 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.5 7H7c-2.7614 0-5 2.2386-5 5s2.2386 5 5 5h2c2.7614 0 5-2.2386 5-5m2.5 5h.5c2.7614 0 5-2.2386 5-5s-2.2386-5-5-5h-2c-2.7614 0-5 2.2386-5 5"
    />
  </svg>
);
export default Link04;
