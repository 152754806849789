// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const GraduationHat02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.3578 3.679c-.1312-.0656-.1968-.0984-.2656-.1113a.4997.4997 0 0 0-.1844 0c-.0688.0129-.1344.0457-.2656.1113L2 8.5l9.6422 4.8212c.1312.0656.1968.0984.2656.1113a.5014.5014 0 0 0 .1844 0c.0688-.0129.1344-.0457.2656-.1113L22 8.5001 12.3578 3.679Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 14.5001v-3.0056c0-.1795 0-.2692-.0273-.3485a.5008.5008 0 0 0-.1155-.1868c-.0586-.0599-.1389-.1-.2994-.1802L12 8.5m-8 1v6.8067c0 .3719 0 .5578.058.7207.0513.1439.1349.2742.2444.3808.1238.1205.2929.198.631.353l6.4 2.9333c.2452.1124.3678.1686.4955.1908.1132.0196.229.0196.3422 0 .1277-.0222.2503-.0784.4955-.1908l6.4-2.9333c.3381-.155.5072-.2325.631-.353a1.0002 1.0002 0 0 0 .2444-.3808c.058-.1629.058-.3488.058-.7207V9.5001m-18-1 9.6422-4.8211c.1312-.0656.1968-.0984.2656-.1113a.4997.4997 0 0 1 .1844 0c.0688.0129.1344.0457.2656.1113L22 8.5l-9.6422 4.8212c-.1312.0656-.1968.0984-.2656.1113a.5014.5014 0 0 1-.1844 0c-.0688-.0129-.1344-.0457-.2656-.1113L2 8.5001Z"
    />
  </svg>
);
export default GraduationHat02;
