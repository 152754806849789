/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useStore } from '../../hooks/useStore';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import useTheme from '../../hooks/useTheme';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';

interface Props {
  source: string;
  isDashBoard?: boolean;
  autoPlay?: boolean;
}

export const VideoEmbedPreview = ({ source, isDashBoard }: Props) => {
  const { theme } = useTheme();
  const { testMode } = useStore().engine;

  const _html = React.useMemo(() => {
    return sanitizeHtml(source);
  }, [source]);

  if (!_html && testMode) {
    return (
      <div>
        <div
          css={{
            width: '100%',
            aspectRatio: '16 / 9',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: isDashBoard ? `${theme.main.borderRadius} ${theme.main.borderRadius} 0 0` : undefined,
            background: getChromaColor(theme.main.background).darken().css(),
          }}
        >
          <h2>Unsupported</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        css={{
          width: '100%',
          aspectRatio: '16 / 9',
          position: 'relative',
          borderRadius: isDashBoard ? `${theme.main.borderRadius} ${theme.main.borderRadius} 0 0` : undefined,
          overflow: 'hidden',
          '& > iframe': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            inset: 0,
          },
        }}
        dangerouslySetInnerHTML={{ __html: _html }}
      />
    </div>
  );
};

export const URLPreview = ({ source, autoPlay }: Props) => {
  return (
    <video
      style={{
        width: '100%',
        aspectRatio: '16 / 9',
        maxHeight: '100%',
      }}
      controls
      autoPlay={autoPlay}
    >
      <source src={source} type="video/mp4"></source>
      <track src="captions_en.vtt" kind="captions" label="english_captions"></track>
      Your browser does not support HTML video.
    </video>
  );
};
