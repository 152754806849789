import { BaseStep, Step } from '../../../engine/step';
import type { Option } from '../../../engine/option';
import { isCommandOption } from '../options';
import { initBaseStep } from '../../../engine/step/BaseStep';

export const getBaseStepBlock = (s: BaseStep) => {
  if (s.selected !== null && s.selected.type === 'command') {
    return s.selected.label;
  }

  if (s.resource) {
    return s.resource.label;
  }

  return undefined;
};

export const selectBaseStep = (s: BaseStep, o: Option): Step => {
  if (isCommandOption(o)) {
    // immutable
    const newStep = initBaseStep(s.resource);

    newStep.selected = {
      type: 'command',
      label: o.label,
      category: o.command.category,
      data: o.command,
    };
    newStep.completed = s.completed;

    return newStep;
  }

  return s;
};
