import { State } from '..';

/**
 * An "Action" function is a function that accepts the root state as its first argument, plus any number of additional
 * arguments, and produces some kind of side effect. This typically means mutating the state object but may also
 * involve other side effects like asynchronous API or IO calls as well.
 */
export type ActionFn<Args extends unknown[], Return> = (_: State, ...args: Args) => Return;

export type ActionMap = Record<string, ActionFn<any, any>>;

export type BoundActions<Actions extends ActionMap> = {
  [K in keyof Actions]: Actions[K] extends ActionFn<infer Args, infer Return> ? (...args: Args) => Return : never;
};

/**
 * Binds state as the first argument to a map of action functions, creating a new map of action functions that do not
 * require state to be passed in. This is intended to be used as a way to bind a whole module's worth of action
 * functions at once so that state does not need to be passed around as an argument everywhere.
 *
 * ```typescript
 * import * as Dialog from '../store/dialog/actions';
 * import * as User from '../store/user/actions';
 * const dialog = bindActions(state, Dialog);
 * const user = bindActions(state, User);
 * user.login(username, password);
 * dialog.showMessage('welcome', state.currentUser.name);
 * user.logout();
 * ```
 */
export const bindActions = <Actions extends ActionMap>(_: State, actions: Actions): BoundActions<Actions> => {
  const bound = {} as BoundActions<Actions>;
  Object.keys(actions).forEach((k: keyof typeof bound) => {
    bound[k] = actions[k].bind(null, _) as any;
  });
  return bound;
};
