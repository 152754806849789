import { useCallback, useEffect } from 'react';
import { useAction } from './useAction';
import * as App from '../store/app/actions';
import { useStore } from './useStore';
import { isMobile } from '@commandbar/internal/util/operatingSystem';

export const useTapToLaunchCommandBar = () => {
  const openBarWithOptionalText = useAction(App.openBarWithOptionalText);
  const {
    engine: { visible },
  } = useStore();

  const handleGestureStart = useCallback(
    (e: TouchEvent) => {
      if (e.touches.length >= 2 && !visible) {
        document.addEventListener('touchend', handleGestureEnd);
        document.addEventListener('touchmove', handleTouchMove);
      }
    },
    [visible],
  );

  // Launch CommandBar only if the user lifts their finger off of the screen
  // to prevent unintentional CommandBar open during zoom or scale gestures
  const handleGestureEnd = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();

      if (!visible) {
        openBarWithOptionalText('programmatic');
      }

      document.removeEventListener('touchend', handleGestureEnd);
      document.removeEventListener('touchmove', handleTouchMove);
    },
    [visible],
  );

  const handleTouchMove = () => {
    document.removeEventListener('touchend', handleGestureEnd);
    document.removeEventListener('touchmove', handleTouchMove);
  };

  useEffect(() => {
    if (isMobile() && !window._cbIsWebView) {
      document.addEventListener('touchstart', handleGestureStart);
    }

    return () => {
      document.removeEventListener('touchstart', handleGestureStart);
      document.removeEventListener('touchend', handleGestureEnd);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
};
