import { IResourceSettings } from '@commandbar/internal/middleware/types';
import { ParameterOption, initParameterOption } from './ParameterOption';
import { CommandOption } from './CommandOption';
import { OptionCategoryMetadata } from './Option';
import { EngineState } from '../../store/engine/state';
import { getOptionUID } from '../../store/engine';

export interface ResourceOption extends ParameterOption {
  recordActions: CommandOption[];
  readonly _resource: true;
  isRecent: boolean;
}

export const initResourceOption = (
  _: EngineState,
  resource: any,
  label: string,
  resourceKey: string,
  searchOptions: IResourceSettings,
  recordActions: CommandOption[],
): ResourceOption => {
  // searchOptions.renderOptions.preview
  const o = initParameterOption(
    _,
    label,
    resource,
    searchOptions,
    resource?.__detailPreview || (searchOptions.detail ?? (searchOptions.show_preview ? searchOptions.content : null)),
  ) as ResourceOption;
  Object.assign(o, { _resource: true });

  const category: OptionCategoryMetadata = {
    label: searchOptions.name ?? resourceKey,
    contextKey: resourceKey,
    limit: searchOptions?.max_options_count ?? null,
    renderAs: searchOptions.render_as || 'list', // FIXME: Grid
  };

  o.category = category;
  o.recordActions = [...recordActions];

  const optionUID = getOptionUID(o);
  const track_recents = searchOptions.track_recents === undefined || searchOptions.track_recents;

  o.isRecent = !!(optionUID && track_recents && _.engine.endUserStore.derived.recentUIDs.includes(optionUID));

  return o;
};

// export class ResourceOption extends ParameterOption {
//   /** @deprecated */
//   public static is = isResourceOption;
//   public category: { contextKey: string; label: string; limit?: number | null };
//   public numLinkedCommands: number;
//   public readonly _resource = true;

//   constructor(
//     engine: EngineState['engine'],
//     resource: any,
//     label: string,
//     resourceKey: string,
//     searchOptions: IResourceSettings,
//     linkedCommands: CommandOption[],
//   ) {
//     super(engine, label, resource, searchOptions);
//     this.category = {
//       label: searchOptions.name ?? resourceKey,
//       contextKey: resourceKey,
//       limit: searchOptions?.max_options_count,
//     };
//     this.numLinkedCommands = linkedCommands.length;
//   }

//   /************************ Static functions ****************************/
//   // Get the custom label field for a resource.
//   //   Example: object is {title: string, address: string}
//   //            User sets the label field to be "title" (stored in argument.label_field)
//   //            The function returns "title". If label_field is undefined, default is "label"

//   /** @deprecated */
//   public static getLabel = (resource: any, key: string, engine: EngineState['engine']) => {
//     return getLabel({ engine }, resource, key);
//   };

//   /** @deprecated */
//   public static getLabelField = (key: string, engine: EngineState['engine']): string => {
//     return getLabelField({ engine }, key);
//   };

//   /**********************************************************************/

//   /************************ Validators **********************************/
//   /** @deprecated */
//   public isValid = (): { isValid: boolean; isValidReason: string } => {
//     // Engine state will note be accessed in this case.
//     return isOptionValid(undefined as unknown as EngineState, this);
//   };

//   /** @deprecated */
//   public isAvailable = (): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
//     // Passing undefined state is OK; it won't be accessed in this case.
//     return validateOptionAvailable(undefined as unknown as EngineState, this);
//   };

//   /** @deprecated */
//   public isExecutable = (): { isExecutable: boolean; isExecutableReason: string } => {
//     return isOptionExecutable(this);
//   };

//   /**********************************************************************/

//   /** @deprecated */
//   public choose = (engine: EngineState['engine'], updateSteps: StepUpdater): void | EngineState['engine'] => {
//     return chooseResourceOption({ engine }, this, updateSteps);
//   };

//   /** @deprecated */
//   public getDefaultCommandId = () => {
//     return getDefaultCommandId(this);
//   };
// }
