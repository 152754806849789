import { DataRow, MultiSearchFunction } from '@commandbar/internal/client/AddContextOptions';

/** Utility to support custom search functions that return multiple record types
 * without invoking them multiple times for the same query. */
export class MultiSearch {
  private lastQuery: string | undefined;
  private cachedResults: { [key: string]: DataRow[] } | Promise<{ [key: string]: DataRow[] }> | undefined;

  public createSearchFunction: (type: string) => MultiSearchFunction;

  constructor(onInputChange: MultiSearchFunction) {
    this.createSearchFunction = (type: string) => {
      return (query: string) => {
        if (query === this.lastQuery && this.cachedResults) {
          return Promise.resolve(this.cachedResults).then((results: any) => results[type]);
        }

        this.cachedResults = onInputChange(query);
        this.lastQuery = query;

        return Promise.resolve(this.cachedResults).then((results: any) => results[type]);
      };
    };
  }
}
