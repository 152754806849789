import React from 'react';
import chroma from 'chroma-js';
import memoize from 'lodash/memoize';
import { EditorDashboards } from '../store/util/editorUtils';

export const getEditorDashboards = memoize((background: string): EditorDashboards => {
  let isLightBackground = true;
  try {
    isLightBackground = chroma(background).luminance() > 0.8;
  } catch {}
  return {
    loading: !!isLightBackground ? (
      <div>
        <img alt="light paperplane" src="https://staticassets.commandbar.com/commandbar/paperplane_white_300.gif" />
      </div>
    ) : (
      <div>
        <img alt="dark paperplane" src="https://staticassets.commandbar.com/commandbar/paperplane_300.gif" />
      </div>
    ),
    timeout: <div>Something went wrong... Please try again.</div>,
    whoaThereBuddy: (
      <div>
        <div>Whoa, whoa there!</div>
        <div>{"How about loading the editor on your own site, why don't ya 😃"}</div>
      </div>
    ),
  };
});
