/** @jsx jsx  */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import { useAction } from '../../hooks/useAction';
import { useStore } from '../../hooks/useStore';
import { useStyles } from './useStyles';
import useTheme from '../../hooks/useTheme';

import * as Engine from '../../store/engine/actions';
import * as Reporting from '../../analytics/Reporting';

import Doc from './Doc';
import SearchResults from './SearchResults';
import Recommendations from './Recommendations';
import Input from './Input';
import AdditionalResources from './AdditionalResources';
import LoadingIndicator from '../select/input/LoadingIndicator';

import type { IMessageType } from '@commandbar/internal/middleware/types';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import Chat from './Chat';
import {
  ChatMessage,
  fetchSearchSuggestions,
  fetchAIChatAnswer,
  NO_ANSWER,
  convertInternalChatHistoryToExternal,
} from '../../client_api/search';
import PoweredBy from './PoweredBy';
import ChatOnly from './ChatOnly';
import { isChatOnlyMode } from '../../store/engine/help-hub/selectors';
import { Chat as ChatClient } from '@commandbar/internal/middleware/chat';

export const DEFAULT_SUGGESTIONS_KEY = '__commandbar_suggestions';

const ANSWER_POLLING_INTERVAL = 250; //ms;
const ANSWER_POLLING_REQUEST_TIMEOUT = 25000; //ms;
const ANSWER_POLLING_TIMEOUT = 60000; //ms;

function getChatMessageWithTimeout(
  chatId: string,
  messageId: string,
  timeout: number,
): Promise<IMessageType | 'timeout'> {
  const abort = new AbortController();

  return Promise.race([
    ChatClient.readMessage(chatId, messageId, abort.signal),
    new Promise<'timeout'>((resolve) =>
      setTimeout(() => {
        resolve('timeout');
        abort.abort();
      }, timeout),
    ),
  ]);
}

const HelpHub = () => {
  const { engine } = useStore();
  const { query, hubDoc: currentDoc, searchResults: docs, parsingUrlParams, loading: isLoading } = engine.helpHub;
  const setIsLoading = useAction((_, loading: boolean) => {
    _.engine.helpHub.loading = loading;
  });
  const [localQuery, setLocalQuery] = useState(query);
  const setHelpHubQuery = useAction(Engine.setHelpHubQuery);
  const setCurrentDoc = useAction(Engine.setHelpHubDoc);
  const { theme } = useTheme();
  const styles = useStyles();

  //** Local state for chat that we can move to engine if needed  */
  const [isChatMode, _setIsChatMode] = useState(false);
  const [chatID, setChatID] = React.useState<string | undefined>(undefined);
  const [chatHistory, setChatHistory] = React.useState<ChatMessage[]>([]);

  const setIsChatMode = (value: boolean) => {
    _setIsChatMode(value);
    if (value) {
      Reporting.helpHubEngagement({ query: localQuery }, 'chat_started');
    }
  };

  const chatOnlyMode = isChatOnlyMode(engine);

  const setSuggestions = useAction(Engine.setContinuations);
  const [suggestionState, setSuggestionState] = useState<'none' | 'loading' | 'loaded'>('none');

  React.useEffect(() => {
    if (engine.organization?.helphub_ai_enabled && engine.organization?.helphub_suggested_queries_enabled) {
      if (engine.organization.helphub_manual_suggested_queries.length > 0) {
        const randomizedItems = [...engine.organization.helphub_manual_suggested_queries]
          .sort(() => 0.5 - Math.random())
          .slice(0, 3);
        setSuggestions(DEFAULT_SUGGESTIONS_KEY, randomizedItems);
        setSuggestionState('loaded');
        return;
      }
      setSuggestionState('loading');
      fetchSearchSuggestions(engine.organization.id.toString()).then((resp) => {
        setSuggestions(DEFAULT_SUGGESTIONS_KEY, resp ? resp?.suggestions.slice(0, 2) : []);
        if (resp?.suggestions && resp?.suggestions?.length > 0) {
          setSuggestionState('loaded');
        } else {
          setSuggestionState('none');
        }
      });
    }
  }, [
    engine.organization?.id,
    engine.organization?.helphub_ai_enabled,
    engine.organization?.helphub_suggested_queries_enabled,
    engine.organization?.helphub_manual_suggested_queries,
  ]);

  const suggestions = engine.helpHub.continuations[DEFAULT_SUGGESTIONS_KEY] || [];

  React.useEffect(() => {
    let canceled = false;
    const abort = new AbortController();
    const lastMessage = chatHistory[chatHistory.length - 1];

    if (chatHistory.length > 0 && lastMessage.type === 'user') {
      if (!!engine.organization) {
        fetchAIChatAnswer(
          engine.organization,
          lastMessage.message,
          chatID,
          chatHistory.slice(0, -1),
          engine.helpHub.hubDoc ? [engine.helpHub.hubDoc.command.id] : undefined,
          abort,
        )
          .then((response) => {
            if (canceled) return;
            if (!response) return;
            if (!(helpHubStateRef.current === 'chat' || helpHubStateRef.current === 'chatOnly')) return;
            if ('error' in response) {
              setChatHistory((chatHistory) => [
                ...chatHistory,
                {
                  type: 'bot',
                  message: {
                    ...NO_ANSWER,
                    answer: 'We are getting too many requests right now. Please try again in a moment.',
                  },
                },
              ]);
              return;
            }

            setChatID(response?.chat_id);
            setChatHistory((chatHistory) => [
              ...chatHistory,
              {
                messageId: response.message_id,
                message: {
                  answer: '',
                  command_id: null,
                  command_title: '',
                  passage_id: null,
                },
                type: 'bot-incomplete',
              },
            ]);

            const pollForAnswer = async () => {
              const startTs = Date.now();
              try {
                while (true) {
                  if (!response.chat_id || !response.message_id) return;

                  // timeout reached -- stop polling
                  if (Date.now() - startTs > ANSWER_POLLING_TIMEOUT) {
                    throw new Error('Polling timeout reached');
                  }

                  const msg = await getChatMessageWithTimeout(
                    response.chat_id,
                    response.message_id,
                    ANSWER_POLLING_REQUEST_TIMEOUT,
                  );

                  if (msg === 'timeout') {
                    // response took too long to return, will try again in ANSWER_POLLING_INTERVAL
                    continue;
                  }

                  setChatHistory((chatHistory) =>
                    // update matching message with new data
                    chatHistory.map((h) => {
                      if ('messageId' in h && h.messageId === response.message_id) {
                        if (!msg.incomplete)
                          return {
                            type: 'bot',
                            message: {
                              ...msg.value,
                              no_answer: msg?.no_answer,
                            },
                          };
                        else
                          return {
                            ...h,
                            message: {
                              ...msg.value,
                            },
                          };
                      }
                      return h;
                    }),
                  );

                  // keep polling until message is marked completed by backend
                  if (!msg.incomplete) {
                    if (msg?.no_answer) {
                      Reporting.noChatResponse(convertInternalChatHistoryToExternal(chatHistory));
                    } else {
                      Reporting.helpHubChatMessage('ai', msg.value.answer);
                    }

                    return;
                  }

                  await new Promise((resolve) => setTimeout(resolve, ANSWER_POLLING_INTERVAL));
                }
              } catch (e) {
                setChatHistory((chatHistory) =>
                  chatHistory.map((h) => {
                    if ('messageId' in h && h.messageId === response.message_id) {
                      return {
                        ...h,
                        type: 'bot',
                        message: {
                          ...NO_ANSWER,
                          answer: 'We are getting too many requests right now. Please try again in a moment.',
                        },
                      };
                    }
                    return h;
                  }),
                );
              }
            };

            pollForAnswer();
          })
          .catch((e) => {
            if (canceled) return;
            throw e;
          });
      }
    }

    return () => {
      canceled = true;
      abort.abort();
    };
  }, [chatHistory]);

  const debounceOnChangeQuery = React.useCallback(
    debounce((value: string) => {
      setHelpHubQuery(value);
      setIsLoading(false);
      Reporting.helpHubEngagement({ query: value }, 'search');
    }, 500),
    [],
  );

  const onChangeQuery = (value: string) => {
    setLocalQuery(value);

    if (!engine?.organization?.helphub_ai_enabled) {
      setIsLoading(true);
      debounceOnChangeQuery(value);
    }
  };

  React.useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  const submitQuery = (query: string) => {
    if (engine.helpHub.query === query) return;
    setHelpHubQuery(query || '');
    if (!query) return;
    setIsLoading(true);
    Reporting.helpHubEngagement({ query: query }, 'search');
  };

  type HELPHUB_STATE =
    | 'chatOnly'
    | 'parsingUrlParams'
    | 'empty'
    | 'search-loading'
    | 'search-finished'
    | 'doc'
    | 'chat';

  const helpHubStateRef = React.useRef<HELPHUB_STATE>();
  helpHubStateRef.current = (() => {
    /** tmp: explicit states for hh */
    if (chatOnlyMode) return 'chatOnly';
    if (parsingUrlParams) return 'parsingUrlParams'; /** fixme - might be able to remove this */
    if (isChatMode && engine.organization?.helphub_ai_enabled) return 'chat';
    if (currentDoc) return 'doc';
    if (!!chatHistory.length && engine.organization?.helphub_ai_enabled) return 'chat';
    if (isLoading) return 'search-loading';
    if (query) return 'search-finished';
    return 'empty';
  })();

  const helpHubState = helpHubStateRef.current;

  const containerStyles = {
    ...styles.contentContainer,
    background: theme.helpHub.background,
    position: 'relative' as const,
  };

  switch (helpHubState) {
    case 'chatOnly':
      return (
        <ChatOnly
          chatID={chatID}
          containerStyles={containerStyles}
          chatHistory={chatHistory}
          setChatID={setChatID}
          setChatHistory={setChatHistory}
          setIsChatMode={setIsChatMode}
        />
      );
    case 'parsingUrlParams':
      return (
        <div style={containerStyles}>
          <LoadingIndicator style={styles.initialLoadingIcon} size={40} isLoading />
        </div>
      );

    case 'doc':
      return (
        <div style={containerStyles}>
          {!!currentDoc ? (
            <Doc
              doc={currentDoc}
              setCurrentDoc={setCurrentDoc}
              setChatHistory={setChatHistory}
              setIsChatMode={setIsChatMode}
            />
          ) : null}
        </div>
      );
    case 'search-loading':
    case 'search-finished':
    case 'empty':
      const hasAdditionalResources =
        engine.helpHub.additionalResources && engine.helpHub.additionalResources.length > 0;

      return (
        <div style={containerStyles}>
          <AnimationTransition isMounted>
            <Input
              query={localQuery}
              onChangeQuery={onChangeQuery}
              onSubmit={submitQuery}
              suggestionsState={suggestionState}
              setIsChatMode={(isChatMode) => {
                if (isChatMode && !chatHistory.length && localQuery?.length === 0) {
                  setChatHistory([
                    {
                      message: {
                        ...NO_ANSWER,
                        answer:
                          engine.organization?.helphub_chat_welcome_message || 'Hey there! What can we help with?',
                      },
                      type: 'bot',
                    },
                  ]);
                } else if (isChatMode && !chatHistory.length && localQuery && localQuery?.length !== 0) {
                  setChatHistory((prev) => [...prev, { message: localQuery, type: 'user' }]);
                }
                setIsChatMode(isChatMode);
              }}
              children={
                !!suggestions?.length &&
                !!engine?.organization?.helphub_suggested_queries_enabled &&
                !!engine?.organization?.helphub_ai_enabled && (
                  <React.Fragment>
                    {suggestions.map((continuation) => {
                      const selectSuggestedQuery = () => {
                        setChatHistory((prev) => [...prev, { message: continuation, type: 'user' }]);
                        setIsChatMode(true);
                        Reporting.helpHubSuggestedQuery({ query: engine.helpHub.query }, continuation);
                      };

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          key={continuation}
                          css={styles.chatSuggestion}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              selectSuggestedQuery();
                            }
                          }}
                          onClick={() => {
                            selectSuggestedQuery();
                          }}
                        >
                          <span style={{ marginLeft: '5px' }}>{continuation}</span>
                        </div>
                      );
                    })}
                  </React.Fragment>
                )
              }
            />
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeInSlideDown }}
            isMounted={helpHubState === 'search-finished'}
            style={{ overflowY: 'auto' }}
          >
            <SearchResults docs={docs} setCurrentDoc={setCurrentDoc} />
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 100 }}
            isMounted={helpHubState === 'empty'}
            style={{ overflowY: 'auto', height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Recommendations setCurrentDoc={setCurrentDoc} />
            {hasAdditionalResources && <AdditionalResources />}
            <PoweredBy
              style={{
                boxShadow: hasAdditionalResources ? 'none' : theme.helpHubAdditionalResources.boxShadow,
              }}
            />
          </AnimationTransition>
        </div>
      );
    case 'chat':
      return (
        <div style={containerStyles}>
          <AnimationTransition isMounted style={{ height: '100%' }}>
            <Chat
              chatID={chatID}
              history={chatHistory}
              setCurrentDoc={setCurrentDoc}
              setChatHistory={setChatHistory}
              setIsChatMode={setIsChatMode}
              setChatID={setChatID}
            />
          </AnimationTransition>
        </div>
      );
  }
};

export default HelpHub;
