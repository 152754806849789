import { Step, LongTextInputStep, TextInputStep } from '../../../engine/step';
import type { Option } from '../../../engine/option';
import { isParameterOption } from '../options';
import { initLongTextInputStep } from '../../../engine/step/LongTextInputStep';
import { initTextInputStep } from '../../../engine/step/TextInputStep';

export const getTextInputStepBlock = (s: TextInputStep | LongTextInputStep): string | undefined => {
  if (s.selected === null) {
    return s.argument.userDefinedName;
  }

  if (s.selected.type !== 'parameter') {
    return s.argument.userDefinedName;
  }

  return s.selected.data;
};

export const selectTextInputStep = (s: TextInputStep | LongTextInputStep, o: Option): Step => {
  if (!isParameterOption(o)) {
    return s;
  }

  // immutable
  const newStep = s.type === 'longtextinput' ? initLongTextInputStep(s.argument) : initTextInputStep(s.argument);

  newStep.selected = {
    type: 'parameter',
    category: null,
    data: String(o.value),
  };
  newStep.completed = s.completed;

  return newStep;
};
