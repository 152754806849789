/**
 * When a client defines a search function:
 *   - The "initial values" defined are added to context, indexed by the plain context key
 *   - The "dynamic search vales" are hot added to and cleared from context, indexed by the special key 'commandbar-search-{context_key}'
 *
 *  See example here: https://www.loom.com/share/2f3178f641c747899a0d1ebbac5af6d2
 */
import Logger from '@commandbar/internal/util/Logger';
import { EngineState } from '../store/engine/state';

const get = (key: string, engine: EngineState['engine']) => {
  const searchResults = engine.records[key];

  let records;

  if (searchResults) {
    records = searchResults.records;
  } else {
    records = engine.context[key];
  }

  if (!Array.isArray(records)) {
    Logger.warn(`Client search results and initial value for ${key} must be an array`);
  }

  return records;
};

const isDefined = (key: string, engine: EngineState['engine']) => {
  return Object.keys(engine.callbacks).some((callbackKey: string) => callbackKey.includes(`commandbar-search-${key}`));
};

const ClientSearch = {
  isDefined,
  get,
};

export default ClientSearch;
