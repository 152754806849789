import { ITheme } from '@commandbar/internal/client/theme';
import Z from '@commandbar/internal/client/Z';
import { IChecklist } from '@commandbar/internal/middleware/types';
import chroma from 'chroma-js';
import React from 'react';

// copied from https://www.figma.com/file/fprgH0Bfv2pmRXc4bB6Be3/Checklists?node-id=4%3A106&t=avazkUcK68lMhKJj-0
// missing:
// - Checklist:
//   - Progress bar

const switchByMode = (theme: ITheme, options: Record<ITheme['base']['mode'], string>) => {
  return options[theme.base.mode];
};

const generateProgressGradient = (color: string) => {
  const gradient = chroma.scale([color, chroma(color).brighten(2)]).mode('hsl');

  return `linear-gradient(140deg,
  ${gradient(0.1)} 0%,
  ${gradient(0.33)} 80%,
  ${gradient(0.39)} 98%,
  ${gradient(0.45)} 102%,
  ${gradient(0.78)} 100%)`;
};

export const CHECKLIST_WIDTH = 360;

const checklist = {
  container: (theme: ITheme, position: IChecklist['position']): React.CSSProperties => ({
    fontFamily: theme.questlists.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    width: `${CHECKLIST_WIDTH}px`,
    background: theme.questlists.bodyBackground,
    boxShadow: theme.questlists.boxShadow,
    borderRadius: theme.questlists.borderRadius,
    bottom: theme.questlists.bottom,
    right: position === 'bottomRight' ? theme.questlists.right : 'auto',
    left: position === 'bottomLeft' ? theme.questlists.left : 'auto',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: Z.Z_CHECKLIST,
  }),
  itemsContainer: (theme: ITheme): React.CSSProperties => ({
    maxHeight: theme.questlists.itemsContainerMaxHeight,
    overflowY: 'auto',
  }),
  textContainer: (theme: ITheme): React.CSSProperties => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.questlists.headerPadding,
    gap: theme.questlists.headerGap,
    background: theme.questlists.headerBackground,
    userSelect: 'none',
  }),
  title: (theme: ITheme): React.CSSProperties => ({
    fontWeight: theme.questlists.titleFontWeight,
    fontSize: theme.questlists.titleFontSize,
    lineHeight: theme.questlists.titleLineHeight,
    color: theme.questlists.titleColor,
  }),
  description: (theme: ITheme): React.CSSProperties => ({
    fontWeight: theme.questlists.descriptionFontWeight,
    fontSize: theme.questlists.descriptionFontSize,
    lineHeight: theme.questlists.descriptionLineHeight,
    color: theme.questlists.descriptionColor,
  }),
  footer: (theme: ITheme): React.CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.questlists.footerPadding,
    gap: theme.questlists.footerGap,
    height: theme.questlists.footerHeight,
    width: '100%',
    background: theme.questlists.footerBackground,
  }),
  brandingText: (theme: ITheme): React.CSSProperties => ({
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '13px',
    color: switchByMode(theme, { light: '#A2A2A9', dark: '#A2A2A9' }),
    display: 'flex',
    alignItems: 'center',
  }),

  primaryBtn: (theme: ITheme): React.CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    gap: '5px',

    border: theme.questlists.doneBorder,
    color: theme.questlists.doneColor,
    padding: theme.questlists.donePadding,
    borderRadius: theme.questlists.doneBorderRadius,
    boxShadow: theme.questlists.doneBoxShadow,
    textShadow: theme.questlists.doneTextShadow,
    background: theme.questlists.doneBackground,
    fontSize: theme.questlists.doneFontSize,
    fontWeight: theme.questlists.doneFontWeight,
    lineHeight: theme.questlists.doneLineHeight,
  }),

  secondaryBtn: (theme: ITheme): React.CSSProperties => ({
    width: 'fit-content',
    cursor: 'pointer',

    border: theme.questlists.skipBorder,
    color: theme.questlists.skipColor,
    padding: theme.questlists.skipPadding,
    borderRadius: theme.questlists.skipBorderRadius,
    boxShadow: theme.questlists.skipBoxShadow,
    textShadow: theme.questlists.skipTextShadow,
    background: theme.questlists.skipBackground,
    fontSize: theme.questlists.skipFontSize,
    fontWeight: theme.questlists.skipFontWeight,
    lineHeight: theme.questlists.skipLineHeight,
  }),

  divider: (theme: ITheme): React.CSSProperties => ({
    width: '100%',
    height: '1px',
    background: theme.questlistItem.dividerColor,
  }),
};

const progressBar = {
  container: (theme: ITheme): React.CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.questlists.progressPadding,
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  }),
  progressContainer: (theme: ITheme): React.CSSProperties => ({
    background: switchByMode(theme, { light: '#EDEDEE', dark: '#51515C' }),
    borderRadius: '100px',
    flexGrow: 1,
    height: '8px',
  }),

  progressFill: (_theme: ITheme): React.CSSProperties => ({
    background: generateProgressGradient(_theme.base.primary),
    borderRadius: '100px',
    height: '8px',
  }),

  text: (theme: ITheme): React.CSSProperties => ({
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    color: switchByMode(theme, { light: '#7A7A85', dark: '#CDCDD0' }),
  }),
};

const checklistItem = {
  titleContainer: (_theme: ITheme): React.CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 16px',
  }),
  expandedTitleContainer: (_theme: ITheme): React.CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 16px 4px 16px',
  }),

  title: (theme: ITheme): React.CSSProperties => ({
    fontWeight: theme.questlistItem.titleFontWeight,
    fontSize: theme.questlistItem.titleFontSize,
    lineHeight: theme.questlistItem.titleLineHeight,
    color: theme.questlistItem.titleColor,
  }),

  titleCompleted: (theme: ITheme): React.CSSProperties => ({
    fontWeight: theme.questlistItem.titleCompletedFontWeight,
    fontSize: theme.questlistItem.titleCompletedFontSize,
    lineHeight: theme.questlistItem.titleCompletedLineHeight,
    color: theme.questlistItem.titleCompletedColor,
    textDecorationLine: theme.questlistItem.titleCompletedtextDecorationLine,
  }),

  description: (theme: ITheme): React.CSSProperties => ({
    fontWeight: theme.questlistItem.descriptionFontWeight,
    fontSize: theme.questlistItem.descriptionFontSize,
    lineHeight: theme.questlistItem.descriptionLineHeight,
    color: theme.questlistItem.descriptionColor,
  }),

  checkboxIncomplete: (theme: ITheme): React.CSSProperties => ({
    width: '20px',
    height: '20px',
    borderWidth: '1px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: switchByMode(theme, { light: '#CDCDD0', dark: '#51515C' }),
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: 'transparent',
  }),

  checkboxComplete: (theme: ITheme): React.CSSProperties => ({
    width: '20px',
    height: '20px',
    borderWidth: '1px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: switchByMode(theme, { light: '#0A0A0F', dark: '#42424D' }),
    background: switchByMode(theme, { light: '#0A0A0F', dark: '#42424D' }),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: '#FFFFFF',
  }),

  bodyContainer: (_theme: ITheme): React.CSSProperties => ({
    padding: '0px 16px 14px 44px',
  }),

  ctaButton: (theme: ITheme): React.CSSProperties => ({
    gap: '6px',
    cursor: 'pointer',
    border: theme.questlistItem.ctaBorder,
    color: theme.questlistItem.ctaColor,
    boxShadow: theme.questlistItem.ctaBoxShadow,
    textShadow: theme.questlistItem.ctaTextShadow,
    padding: theme.questlistItem.ctaPadding,
    borderRadius: theme.questlistItem.ctaBorderRadius,
    background: theme.questlistItem.ctaBackground,
    fontSize: theme.questlistItem.ctaFontSize,
    fontWeight: theme.questlistItem.ctaFontWeight,
    lineHeight: theme.questlistItem.ctaLineHeight,
  }),

  textBtn: (theme: ITheme): React.CSSProperties => ({
    width: 'fit-content',
    cursor: 'pointer',

    border: theme.questlistItem.skipBorder,
    color: theme.questlistItem.skipColor,
    padding: theme.questlistItem.skipPadding,
    borderRadius: theme.questlistItem.skipBorderRadius,
    background: theme.questlistItem.skipBackground,
    fontSize: theme.questlistItem.skipFontSize,
    fontWeight: theme.questlistItem.skipFontWeight,
    lineHeight: theme.questlistItem.skipLineHeight,
  }),
};

/** fixme: not specific to checklist */
const helpers = {
  noBtnStyle: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
  },
};

const styles = { checklist, checklistItem, helpers, progressBar };
export default styles;
