import { OrgConfig } from './OrgConfig';
import { SDKConfig } from './CommandBarSDK';
import {
  _configuration,
  _disposed,
  _isProxy,
  _queue,
  _sentry,
  _unwrap,
  _orgConfig,
  _eventSubscriptions,
} from './symbols';
import type { Hub } from '@sentry/browser';

/**
 * A proxy object that absorbs function calls into a queue that can be later consumed. The queue contains the name of
 * properties accessed for function calls including any arguments passed.
 *
 * This is useful to allow clients to "queue up" calls to the SDK before it can be "upgraded" with state and callbacks
 * from the React component tree.
 */
export type CommandBarProxySDK = CommandBarProxyGlobal &
  Record<string, Function> & {
    // Optional properties become a bit dangerous once proxied; they might return a function instead! This type adds
    // `Function` to the signature of these properties to correctly illustrate what can happen. The non-optional
    // properties do not need this.
    [_configuration]?: Function | Partial<SDKConfig>;
    [_orgConfig]?: Function | OrgConfig;
  };

/**
 * The global SDK object, which is usually hidden behind a Proxy at `window.CommandBar`. Use
 * `window.CommandBar[_unwrap]()` (or ideally `getProxySDK()`) from anywhere to access this.
 *
 * The properties defined here are the minimal properties that can always be expected to be present in the global
 * object. Many more are added once "upgraded" using `initSDK`.
 */
export interface CommandBarProxyGlobal {
  /**
   * Marks the proxy as disposed when true; this will cause a new global object to be created with the next `getSDK()`
   * or `getProxySDK()` call.
   */
  [_disposed]: boolean;
  [_isProxy]: true;
  /**
   * A command queue that builds up commands executed early in the application lifecycle, before the App component has
   * had a chance to render.
   */
  [_queue]: unknown[][];
  /**
   * A sneaky way to get access to the object that's being proxied, the one that holds the real global state. Use this
   * to avoid triggering unwanted proxy behavior when accessing properties that are unassigned.
   *
   * The typedefs of the optional properties (_org, _reporter) in CommandBarProxySDK illustrate the risk of working with
   * a wrapped proxy global.
   */
  [_unwrap]: () => CommandBarProxyGlobal;
  [_configuration]?: Partial<SDKConfig>;
  [_orgConfig]?: OrgConfig;
  [_sentry]: Hub | undefined;

  [_eventSubscriptions]: undefined;
}

/** Tests if the given object is a "proxy" SDK object that has not yet been upgraded to a client SDK object. */
export function isProxySDK(x: unknown): x is CommandBarProxySDK {
  return (x as CommandBarProxySDK)?.[_isProxy] === true;
}
