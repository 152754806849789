import { NUDGE_ROUTE, CHECKLIST_ROUTE } from './editor_routes';
/**
 * share link query params should look like this:
 * `/?${SHARE_LINK_PARAM}=${SHARE_LINK_NUDGE_ID}${nudgeId}`
 */
export const SHARE_LINK_PARAM = 'cb-eid';

/**
 * These should be 1 character long to simplify the id extraction below
 */
export const SHARE_LINK_NUDGE_ID = 'n';
export const SHARE_LINK_QUESTLIST_ID = 'q';

export const buildShareLink = (
  baseUrl: string,
  type: 'nudge' | 'questlist',
  id: string | number,
  isStandaloneEditor: boolean | undefined,
) => {
  if (isStandaloneEditor === undefined) return '';

  if (isStandaloneEditor) {
    return `${baseUrl}/editor${type === 'nudge' ? NUDGE_ROUTE : CHECKLIST_ROUTE}/${id}`;
  }
  if (!baseUrl) return '';

  let url;
  try {
    url = new URL(baseUrl);
  } catch (e) {
    return '';
  }

  const base = url.href.replace(url.hash, '').replace(url.search, '');
  const searchParams = new URLSearchParams(url.search);
  searchParams.delete(SHARE_LINK_PARAM);
  searchParams.toString();
  const baseSearchParams = searchParams.toString();

  const shareLinkParam = `${SHARE_LINK_PARAM}=${type === 'nudge' ? SHARE_LINK_NUDGE_ID : SHARE_LINK_QUESTLIST_ID}${id}`;
  return `${base}${!!baseSearchParams ? `${baseSearchParams}&` : '?'}${shareLinkParam}${url.hash}`;
};

/**
 * - Returns the id of the nudge or QL from the share link hash
 * - If the path does not include the necessary components of a share
 *  link, this function will return undefined
 */

export type DeconstructedShareLink =
  | {
      type: 'nudge' | 'questlist';
      id: number;
    }
  | undefined;
export const deconstructShareLink = (searchString: string): DeconstructedShareLink => {
  const searchParams = new URLSearchParams(searchString);
  const shareLinkParam = searchParams.get(SHARE_LINK_PARAM);

  if (!shareLinkParam) return undefined;

  const type =
    shareLinkParam[0] === SHARE_LINK_NUDGE_ID
      ? 'nudge'
      : shareLinkParam[0] === SHARE_LINK_QUESTLIST_ID
      ? 'questlist'
      : undefined;

  if (!type) return undefined;

  return {
    type,
    id: Number(shareLinkParam.slice(1)),
  };
};
