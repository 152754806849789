import { Step } from '../../engine/step';
import {
  IOrganizationType,
  IResourceSettingsByContextKey,
  ICommandType,
  IUserContext,
  ICallbackMap,
  IEndUserType,
  IPlaceholderType,
  IEnvironmentType,
  ICommandCategoryType,
  ISkinType,
  ITabType,
  IChecklist,
  INudgeStepType,
  DetailPreviewType,
  IAdditionalResource,
  IRecommendationSet,
  IInstantAnswerType,
} from '@commandbar/internal/middleware/types';
import { Option } from '../../engine/option';
import { OptionGroup } from '../../engine/OptionGroup';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { DEFAULT_PLACEHOLDER } from '../../components/select/input/placeholderHelpers';
import { PlatformType, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import { initBaseStep } from '../../engine/step/BaseStep';
import { CustomComponent, FormFactorConfig, ProductConfig } from '@commandbar/internal/client/CommandBarClientSDK';
import { emptyEndUserStoreState, EndUserStore } from './end-user/state';
import { ITheme } from '@commandbar/internal/client/theme';
import { GetRecordsService } from './generate-options/get-records-machine';
import { ref } from 'valtio';
import { proxyMap } from 'valtio/utils';

import type { NudgePreviewService, NudgeService } from './nudges/machine';

export type AvailabilityCallback = (engine: EngineState['engine']) => Option[];

// Copied from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/lodash/common/function.d.ts
export interface DebouncedFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invocation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invocation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invocation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invocation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): ReturnType<T> | undefined;
}

/** Subkeys of engine state that are to be ignored when deciding if it's necessary to recompute option availability. */
const omitAvailabilityIgnoreKeysFromSnapshot = (snapshot: EngineState['engine']) => {
  const { initialOptions, simulation, visible, ...partialSnapshot } = snapshot;
  return partialSnapshot;
};

/**
 * Given two snapshots of the `engine` state tree, this will return true if there is a need to recompute option
 * availability.
 */
const needToComputeOptions = (prevSnapshot: EngineState['engine'], nextSnapshot: EngineState['engine']) => {
  if (!nextSnapshot.visible) return false;

  const prevEngine = omitAvailabilityIgnoreKeysFromSnapshot(prevSnapshot);
  const nextEngine = omitAvailabilityIgnoreKeysFromSnapshot(nextSnapshot);
  const equal = isEqual(prevEngine, nextEngine);
  return !equal;
};

type Records = {
  records: any[];
};

type HelpDocBase = {
  commandID: number | string;
  command: ICommandType;
  title: string;
  excerpt: string;
  content: undefined | null | DetailPreviewType;
  thumbnail?: ICommandType['thumbnail'];
  icon?: string | null;
};

export type HelpHubDoc = HelpDocBase &
  (
    | {
        type: 'helpdoc';
      }
    | {
        type: 'link';
      }
    | {
        type: 'video';
        videoUrl: string;
      }
    | {
        type: 'other';
      }
  );

export interface EngineState {
  engine: {
    /************************************/
    /**** Availability Dependencies *****/
    /************************************/

    /* current URL */
    location: Location;

    /* Organization */
    organization?: IOrganizationType;

    formFactor: FormFactorConfig;

    products: ProductConfig;

    endUser: IEndUserType | null | undefined;

    endUserStore: EndUserStore;

    logo: string;
    chatAvatar: string;
    themeSource: string;

    /* Object search settings -- HACK to remove*/
    serverContextSettings: IResourceSettingsByContextKey;
    localContextSettings: IResourceSettingsByContextKey;

    // merge({}, serverContextSettings, localContextSettings)
    contextSettings: IResourceSettingsByContextKey;

    /* commands */
    programmaticCommands: ICommandType[];
    commands: ICommandType[];
    hotloadedCommands: ICommandType[];

    /* complete context (specified by client through window) */
    context: IUserContext;

    /* records fetching machines */
    recordsMachines: Map<string, { callback: any; service: GetRecordsService }>;

    /* "search callbacks" will load records into here via state machine defined in "get-records-machine.ts" */
    records: Record<string, Records>;

    /* all callbacks */
    callbacks: ICallbackMap;

    /* an admin can preview and edit commands */
    testMode: boolean;

    /* the user is an admin */
    isAdmin: boolean;
    /* available environments for env switcher -- only relevant for admins */
    environments: IEnvironmentType[] | null;

    inputText: string;
    rawInput: string;

    // Preserve input text for reporting.
    previousInputText: string;

    /*******************************/
    /**** Command Bar Snapshot *****/
    /*******************************/

    /* have the commands loaded? */
    commandsLoaded: boolean;
    /* command bar visibility */
    visible: boolean;

    /* the available set of initial options (doesn't include async options or synthetic options created during a search) */
    initialOptions: Option[];

    /* the current set of options */
    currentOptions: Option[];

    /* sorted & filtered set of currentOptions */
    sortedOptions: (Option | OptionGroup)[];

    /* count of available options by tab group key */
    optionCountsByTabGroup: Map<string, number>;

    /* the current set of OptionGroups */
    currentGroups: OptionGroup[];

    /* the full command execution path */
    steps: Step[];

    /* flag if this is a simulated execution path */
    simulation: boolean;
    baseTheme: undefined | string | Pick<ISkinType, 'logo' | 'skin'>;
    primaryColor: undefined | string;

    expandedGroupKeys: string[];

    // Execution tracking
    // Store command execution history by command id.
    executionHistory: string[];

    // Deadend tracking
    isBackspacing: boolean;
    cachedInput: string;

    reportKeystrokeDebouncer: DebouncedFunc<(cb: VoidFunction) => void>;

    // Indicates the virtual focus on MenuList.
    // When set to -1, allows focus to move outside the of the MenuList.
    focusedIndex: number;

    // Indicates focusable element index on MenuRows (e.g. "See More" on OptionGroupHeaders).
    // WHen set to -1, there are no focusable elements within the MenuRow.
    focusableChildIndex: number;

    // If a command fails due to an error (e.g. because it is unavailable):
    //   `errorIndex` is set to the index of the failed command
    //   `errorTimestamp` is set to the current timestamp from Date.now()
    //
    // NOTE: In the future, we would like to store this in Option; for now,
    // there's no way to do that, because mutating state inside Option doesn't
    // cause a re-render
    errorIndex: number;
    errorTimestamp: number;

    // NOTE: The proxy version must be passed as an argument because it needs to be evaluated as part of the memoization
    // logic (it cannot be calculated by calling getVersion on the first argument because that's a reference that will
    // always point to the live store object, thus always returning the same version number in this context).
    availableOptionMemoizer: (
      _: EngineState,
      engineSnapshot: EngineState['engine'],
      cb: AvailabilityCallback,
    ) => Option[];

    currentStepIndex: number;

    activePlaceholder: string;
    placeholders: IPlaceholderType[];

    /*
     * We use xstate to define and interact with the state machine that handles nudges.
     * Services are interpreted state machines, and we can use them in a couple ways:
     * - Peek inside with service.getSnapshot().
     * This will return the current state of the machine including its context.
     * - service.send() to send events to the machine.
     * The services are fully typed, so you can see which actions are available
     * to send and what data need to be included.
     */
    nudgeServices: Map<string, NudgeService>;
    nudgePreviewService: NudgePreviewService | null;
    activeNudge: string | null;
    currentModalNudge: {
      step: INudgeStepType;
      service: NudgeService | NudgePreviewService;
      dismissible?: boolean;
      stepCount?: string;
    } | null;

    triggerableSelectors: string[];

    checklists: IChecklist[];
    activeChecklist: IChecklist | null;
    queuedChecklist: IChecklist | null;

    platform: PlatformType;

    detailPreviewGenerator: any;

    components: {
      [key: string]:
        | {
            name: string;
            component: CustomComponent;
          }
        | undefined;
    };

    categories: ICommandCategoryType[];

    tabs: ITabType[];

    theme?: ITheme;

    editingEndUserShortcutSlug: string | null;

    helpHub: {
      visible: boolean;
      loading: boolean;
      scrollPosition: number;
      query: string | null;
      searchResults: HelpHubDoc[];
      hubDoc: HelpHubDoc | null;
      recommendationSets: IRecommendationSet[] | null;
      previewedRecommendationSetId: number | null;
      additionalResources: IAdditionalResource[];
      liveAnswer: IInstantAnswerType | null;
      loadingLiveAnswer: boolean;
      parsingUrlParams: boolean;
      continuations: Record<string, string[]>;
      gaveFeedback: Record<string, number>;
    };
  };
}

export const initEngineState = (): EngineState => ({
  engine: {
    location: ref(window.location),

    organization: undefined,
    formFactor: { type: 'modal' },
    products: ['bar', 'questlists', 'nudges'],
    endUser: undefined,
    endUserStore: emptyEndUserStoreState(),
    logo: '',
    chatAvatar: '',
    themeSource: '',

    localContextSettings: {},
    serverContextSettings: {},
    contextSettings: {},

    programmaticCommands: [],
    commands: [],
    hotloadedCommands: [],

    recordsMachines: proxyMap(),
    records: {},

    context: {},
    callbacks: {},
    testMode: false,
    isAdmin: false,
    environments: null,
    inputText: '',
    rawInput: '',
    previousInputText: '',

    commandsLoaded: false,
    visible: false,
    initialOptions: [],

    // Steps should always have at least one actionable step; this is the default
    steps: [initBaseStep(null)],

    simulation: false,
    baseTheme: undefined,
    primaryColor: undefined,
    currentOptions: [],
    expandedGroupKeys: [],
    sortedOptions: [],

    optionCountsByTabGroup: new Map(),

    currentGroups: [],

    executionHistory: [],
    isBackspacing: false,
    cachedInput: '',

    reportKeystrokeDebouncer: debounce((cb) => cb(), 500),

    focusedIndex: 0,
    focusableChildIndex: -1,
    errorIndex: -1,
    errorTimestamp: 0,

    // NOTE: instead of importing a function here (would cause a dependency cycle) or defining it here (would be
    // sloppy and might still cause a cycle), the caller is instead expected to pass the implementation
    // (dependency-injection style) of the function at call time.
    availableOptionMemoizer: memoizeOne(
      (_, _engine, cb) => cb(_.engine),
      // The callback argument is expected to be static and is ignored entirely for this equality function.
      ([_n, nextSnapshot], [_p, prevSnapshot]) =>
        prevSnapshot === nextSnapshot || !needToComputeOptions(prevSnapshot, nextSnapshot),
    ),

    currentStepIndex: 0,

    activePlaceholder: DEFAULT_PLACEHOLDER,
    placeholders: [],
    nudgeServices: new Map(),
    nudgePreviewService: null,
    activeNudge: null,

    checklists: [],
    activeChecklist: null,
    queuedChecklist: null,
    currentModalNudge: null,

    triggerableSelectors: [],

    platform: getOperatingSystem(),

    detailPreviewGenerator: null,
    components: {},

    categories: [],
    tabs: [],

    theme: undefined,

    editingEndUserShortcutSlug: null,

    helpHub: {
      visible: false,
      loading: false,
      scrollPosition: 0,
      query: null,
      searchResults: [],
      hubDoc: null,
      recommendationSets: null,
      previewedRecommendationSetId: null,
      additionalResources: [],
      liveAnswer: null,
      loadingLiveAnswer: false,
      parsingUrlParams: false,
      continuations: {},
      gaveFeedback: {},
    },
  },
});
