import React from 'react';
import Icon from '@commandbar/internal/client/Icon';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import MultiSelectDefaultSelectedIcon from './MultiSelectDefaultSelectedIcon';
import { isGridView } from '../../store/app';
import { getIconSize } from '../option/helpers';
import { useStore } from '../../hooks/useStore';

interface Props {
  userDefinedIcon: string | null;
  useDefaultSVGColor: boolean;
}

const MultiSelectSelectedIcon = (props: Props) => {
  const _ = useStore();
  const isGridOption = isGridView(useStore());
  const theme = useCommandBarTheme();
  const selectedColor = theme.optionSelected.iconColor;

  if (!!props.userDefinedIcon) {
    return (
      <Icon
        icon={props.userDefinedIcon}
        default={<div style={{ minWidth: getIconSize(_, theme), display: 'inline-block' }} />}
        style={{
          minWidth: getIconSize(_, theme),
          padding: 1,
          border: isGridOption ? undefined : `2px solid ${selectedColor}`,
        }}
        useDefaultSVGColor={props.useDefaultSVGColor}
        size={getIconSize(_, theme)}
      />
    );
  } else {
    return <MultiSelectDefaultSelectedIcon color={selectedColor} size={getIconSize(_, theme)} />;
  }
};

export default MultiSelectSelectedIcon;
