export interface EditorDashboards {
  loading: React.ReactNode;
  timeout: React.ReactNode;
  whoaThereBuddy: React.ReactNode;
}

export const checkForEditor = () => {
  return document.getElementById('commandbar-editor-drawer');
};

export const getEditorHandle = (): HTMLElement | null => {
  const handle = document.getElementById('commandbar-editor-drawer')?.querySelector('.commandbar-editor-drawer-handle');
  if (!!handle) return handle as HTMLElement;
  return null;
};

export const clickEditorHandle = () => {
  const drawerToggle = getEditorHandle();
  if (drawerToggle) {
    drawerToggle.click();
    drawerToggle.style.visibility = 'visible';
  }
};

export const openEditorIfLoaded = () => {
  if (checkForEditor() && !isEditorOpen()) {
    clickEditorHandle();
  }
};

export const isEditorOpen = (): boolean => {
  const hasOpenClassSuffix = document
    .getElementById('commandbar-editor-drawer')
    ?.classList.contains('commandbar-editor-drawer-open');
  return !!hasOpenClassSuffix;
};
