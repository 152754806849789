import * as t from 'io-ts';
import { LabeledAction } from '../types';

export const AdminAction = t.type({
  type: t.literal('admin'),
  value: t.string,
});

export const CallbackAction = t.type({
  type: t.literal('callback'),
  value: t.string,
});

export const OpenBarAction = t.intersection([
  t.type({
    type: t.literal('open_bar'),
  }),
  t.partial({
    value: t.string,
    categoryFilter: t.number,
  }),
]);

export const LinkAction = t.intersection([
  t.type({
    type: t.literal('link'),
    value: t.string,
  }),
  t.partial({
    operation: t.union([t.literal('router'), t.literal('self'), t.literal('blank'), t.undefined]),
  }),
]);

export const OpenChatAction = t.type({
  type: t.literal('open_chat'),
  meta: t.type({
    type: t.string,
  }),
});

export const DismissAction = t.type({
  type: t.literal('dismiss'),
});

export const ClickAction = t.type({
  type: t.union([t.literal('click'), t.literal('clickBySelector'), t.literal('clickByXpath')]),
  value: t.array(t.string),
});

export const BuiltInAction = t.type({
  type: t.literal('builtin'),
  value: t.string,
});

export const WebhookAction = t.type({
  type: t.literal('webhook'),
  value: t.string,
});

export const AppcuesAction = t.type({
  type: t.literal('appcues'),
  value: t.string,
});

export const ScriptAction = t.type({
  type: t.literal('script'),
  value: t.string,
});

export const VideoAction = t.type({
  type: t.literal('video'),
  value: t.string,
});

export const HelpDocAction = t.intersection([
  t.type({
    type: t.literal('helpdoc'),
    value: t.string,
  }),
  t.partial({
    operation: t.union([
      t.literal('router'),
      t.literal('self'),
      t.literal('blank'),
      t.literal('help_hub'),
      t.undefined,
    ]),
    doc_metadata: t.partial({
      content_type: t.string,
      date: t.string,
    }),
  }),
]);
export const CommandAction = t.type({
  type: t.literal('execute_command'),
  meta: t.type({
    command: t.string,
  }),
});

export const NoAction = t.type({ type: t.literal('no_action') });

export const ClickActionNew = t.type({
  type: t.literal('click'),
  value: t.string,
});

export const NudgeAction = t.type({
  type: t.literal('nudge'),
  value: t.number,
});

export const ActionV = t.union([CommandAction, NoAction, ClickActionNew, LinkAction, OpenChatAction, DismissAction]);

export const LabeledActionV = t.type({
  cta: t.string,
  action: ActionV,
});

export const isAction = (o: string | number | LabeledAction): o is LabeledAction => {
  return (o as LabeledAction).action !== undefined;
};

/** Request */

const MethodV = t.union([
  t.literal('get'),
  t.literal('delete'),
  t.literal('head'),
  t.literal('options'),
  t.literal('post'),
  t.literal('put'),
  t.literal('patch'),
]);

export const RequestV = t.intersection([
  t.type({
    method: MethodV,
    url: t.string,
  }),
  t.partial({
    headers: t.UnknownRecord,
    body: t.UnknownRecord,
    onSend: t.string,
    onSuccess: t.string,
    onError: t.string,
  }),
]);

export const RequestAction = t.type({
  type: t.literal('request'),
  value: RequestV,
});

export const TriggerAction = t.type({
  type: t.literal('trigger'),
  value: t.any, // unused, added for easier types
});
