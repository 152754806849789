// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const BookOpen02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2 7.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 4 4.08 4 5.2 4h.4c2.2402 0 3.3603 0 4.216.436a4 4 0 0 1 1.748 1.748C12 7.0397 12 8.1598 12 10.4V20H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 18.4802 2 17.9201 2 16.8V7.2Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 20H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 18.4802 2 17.9201 2 16.8V7.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 4 4.08 4 5.2 4h.4c2.2402 0 3.3603 0 4.216.436a4 4 0 0 1 1.748 1.748C12 7.0397 12 8.1598 12 10.4m0 9.6v-9.6m0 9.6h6.8c1.1201 0 1.6802 0 2.108-.218a1.9996 1.9996 0 0 0 .874-.874C22 18.4802 22 17.9201 22 16.8V7.2c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C20.4802 4 19.9201 4 18.8 4h-.4c-2.2402 0-3.3603 0-4.216.436a4.0002 4.0002 0 0 0-1.748 1.748C12 7.0397 12 8.1598 12 10.4"
    />
  </svg>
);
export default BookOpen02;
