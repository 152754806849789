/** @jsx jsx */

/*******************************************************************************/
/* Imports
/*******************************************************************************/

import { css, jsx } from '@emotion/core';

/* React imports */
import * as React from 'react';
import Tooltip from '../../Tooltip';
import tooltipStyles from '../../../style/rc-tooltip/style';
import chroma from 'chroma-js';

import { useTheme } from 'emotion-theming';

import { osControlKey, isMobile } from '@commandbar/internal/util/operatingSystem';
import Tag from '../../Tag';

import { SuggestCommandIcon } from './SuggestCommand';
import HotkeyTag from '../../hotkeys/HotkeyTag';
import { translate } from '../../../util/languages';
import { ReactComponent as PoweredBySvg } from '../../../img/powered.svg';
import { ITheme } from '@commandbar/internal/client/theme';
import { useStore } from '../../../hooks/useStore';
import ExecutionPath from '../../../engine/ExecutionPath';
import { selectSummonHotkey } from '../../../store/engine/selectors';
import RenderCustomComponent from '../../RenderCustomComponent';
import { navigateToBrandingSite, showBranding } from '../../../util/branding';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const Footer = () => {
  const { theme }: { theme: ITheme } = useTheme();
  const { engine, userDefinedCustomComponents } = useStore();
  const { organization } = engine;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

  const summonHotkey = selectSummonHotkey(useStore());

  const styles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.footer.paddingTop,
    paddingBottom: theme.footer.paddingBottom,
    paddingLeft: theme.footer.paddingLeft,
    paddingRight: theme.footer.paddingRight,
    borderTop: theme.footer.borderTop,
    background: theme.footer.background,
    borderRadius: `0px 0px ${theme.main.borderRadius} ${theme.main.borderRadius}`,
  };

  // HARDCODE: OneSignal (31faae81)
  const customBetaTag =
    organization?.id === '31faae81' ? (
      <Tag color={chroma('#5289b7')} style={{ marginLeft: 12 }}>
        Beta
      </Tag>
    ) : null;

  if (!organization) {
    return null;
  }

  const branding = showBranding(organization) ? (
    <button
      tabIndex={0}
      role="link"
      css={css`
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      `}
      aria-label="powered-by-commandbar"
      style={{
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
      }}
      onClick={() => navigateToBrandingSite(organization)}
    >
      <PoweredBySvg role="img" focusable={false} style={{ marginRight: '5px', opacity: 0.5 }} />
      <span style={{ fontSize: '12px', color: theme.main.color, opacity: 0.7 }}>
        {translate('Powered by CommandBar')}
      </span>
    </button>
  ) : null;

  const component = currentStep?.type !== 'execute' && userDefinedCustomComponents.footer({ step: currentStep?.type });

  if (typeof component === 'string') {
    return (
      <div style={{ position: 'relative' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: component,
          }}
        />
      </div>
    );
  } else if (component) {
    return <RenderCustomComponent component={component} />;
  }

  // custom gusto footer
  if (organization?.id === '5725a2a3') {
    let ctrlKey = 'cmd';
    if (['windows', 'linux', 'android'].includes(engine.platform)) {
      ctrlKey = 'ctrl';
    }
    const spacer = <div style={{ width: 5 }} />;
    const customGustoFooter = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          fontWeight: 400,
          fontSize: '14px',
          color: '#525257',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Press</span>
          {spacer}
          <span style={{ fontWeight: 600 }}>{ctrlKey}</span>
          {spacer}
          <span style={{ fontWeight: 600 }}>+</span>
          {spacer}
          <span style={{ fontWeight: 600 }}>K</span>
          {spacer}
          <span>to access search anytime</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            style={{
              color: '#0A8080',
              fontWeight: 500,
              fontSize: '14px',
              letterSpacing: '0.02em',
              textDecorationLine: 'underline',
            }}
            href="https://gusto.az1.qualtrics.com/jfe/form/SV_54M1UflaJc3Lf5I"
            target="_blank"
            rel="noreferrer"
          >
            Leave feedback
          </a>
        </div>
      </div>
    );
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ ...styles, fontFamily: theme.main.fontFamily }}>{customGustoFooter}</div>
      </div>
    );
  }

  return (
    <div role="contentinfo" style={{ position: 'relative' }}>
      <div style={styles}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {branding}
          <div style={{ flexGrow: 1 }} />
          <span style={{ width: 10 }} />
          {organization?.in_bar_feedback === false ? null : <SuggestCommandIcon organization={organization} />}
          {customBetaTag}
          {!isMobile() && !!summonHotkey && (
            <React.Fragment>
              <style>${tooltipStyles}</style>
              <Tooltip
                trigger={['hover', 'focus']}
                overlay={
                  summonHotkey === 'mod+k' ? (
                    <span style={{ fontFamily: theme.main.fontFamily }}>
                      {translate(`Type ${osControlKey()}+K to open and close`)}
                    </span>
                  ) : (
                    <span style={{ fontFamily: theme.main.fontFamily }}>
                      <span>Type </span>
                      <HotkeyTag combo={summonHotkey} />
                      <span> to open and close</span>
                    </span>
                  )
                }
                placement="bottom"
              >
                <div
                  tabIndex={0} // eslint-disable-line
                  aria-label="press-command-k-to-open-and-close"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'default',
                    marginLeft: 13,
                  }}
                >
                  <HotkeyTag key={summonHotkey} combo={summonHotkey} />
                </div>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
