/** Fixme: instead of being callbacks, this can probably be actions */

import {
  ClickAction,
  CommandAction,
  HelpDocAction,
  LinkAction,
  NudgeAction,
  OpenChatAction,
} from '@commandbar/internal/middleware/helpers/actions';
import { EngineState, executeCommand, getCommandById, forceTriggerNudge, HelpHubDoc } from '..';
import { InternalError } from '../../../engine/Errors';
import { checkSelector, clickElement, clickElementsWithPause } from '@commandbar/internal/util/dom';
import { interpolate } from '../../../engine/Interpolate';
import * as t from 'io-ts';
import { State } from '../../../store';
import { getNudgeById } from '../nudges/selectors';
import * as Engine from '../../engine/actions';
import type { ExecutionEventSource } from '@commandbar/internal/client/EventHandler';
import { ICommandType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { HelpDocsSearch } from '@commandbar/internal/middleware/helpDocsSearch';
import { getSentry } from '@commandbar/internal/util/sentry';

declare global {
  interface Window {
    Intercom: any;
    Beacon: any;
    FreshworksWidget: any;
    $crisp: any;
    zE: any;
    LiveChatWidget: any;
    olark: any;
  }
}

export const helpDocExecutable = (
  engine: EngineState['engine'],
  action: t.TypeOf<typeof LinkAction | typeof HelpDocAction>,
  command: ICommandType,
) => {
  if (!engine.organization?.helphub_enabled || engine.organization?.helphub_chat_only_mode)
    return linkExecutable(engine, action);

  const hasSupportedPreviewType = (article: HelpHubDoc) => {
    const standardized = standardize(article.content);
    return standardized && standardized.every((item) => item.type === 'html');
  };

  const query = engine.inputText;

  return () => {
    if (!engine.organization?.id) {
      Logger.warn(`Search error: Organization ID not found`);
      linkExecutable(engine, action)();
    } else {
      HelpDocsSearch.search(engine.organization.id, { query, doc_id: command.id })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const hit = response.data[0]['hit'];
            const article: HelpHubDoc = {
              type: 'helpdoc',
              commandID: hit.command.id,
              command: hit.command,
              title: hit.title,
              excerpt: hit.excerpt,
              content: hit.content,
            };
            if (hasSupportedPreviewType(article)) {
              Engine.setHelpHubVisible({ engine }, true, {
                article: article,
                query,
              });
            } else {
              linkExecutable(engine, action)();
            }
          } else {
            linkExecutable(engine, action)();
          }
        })
        .catch((err: any) => {
          Logger.warn(`Search error: ${err}`);
          linkExecutable(engine, action)();
        });
    }
  };
};

export const linkExecutable = (
  engine: EngineState['engine'],
  action: t.TypeOf<typeof LinkAction | typeof HelpDocAction>,
  forceNewTab?: boolean,
) => {
  // Is the interpolated URL valid?
  const _url = interpolate(action.value, engine, true, true, true);

  if (!!forceNewTab) {
    return () => window.open(_url, '_blank');
  }

  switch (action.operation) {
    case 'router':
      const routerFunc = engine.callbacks['commandbar-router'];
      if (routerFunc) {
        return () => routerFunc(_url);
      } else {
        throw new InternalError('Link is of router type, but router is not defined.');
      }
    case 'self':
      return () => window.open(_url, '_self');
    case 'blank':
      return () => window.open(_url, '_blank');
    default:
      return () => window.open(_url, '_blank');
  }
};

export const clickExecutable = (engine: EngineState['engine'], action: t.TypeOf<typeof ClickAction>) => {
  const values = action.value.map((el: any) => interpolate(el, engine, true, true));
  if (!checkSelector(values[0])) {
    throw new InternalError(`Cannot find element to click: [${values[0]}]`);
  }

  const reportFailure = (_selector: string) => {
    getSentry()?.captureMessage(`Click failed`);
  };

  if (action.value.length === 1) {
    return () => clickElement(values[0], reportFailure);
  } else {
    return () => clickElementsWithPause(0, values, reportFailure);
  }
};

export const commandExecutable = (
  _: State,
  action: t.TypeOf<typeof CommandAction>,
  executionEventSource?: ExecutionEventSource,
) => {
  const command = getCommandById(_.engine, action.meta.command);
  if (!command) return null;
  return () => {
    executeCommand(
      _,
      command,
      undefined,
      () =>
        getSentry()?.captureMessage(`Command failed to execute`, 'error', {
          captureContext: {
            contexts: {
              command,
            },
          },
        }),
      executionEventSource,
    );
  };
};

export const nudgeExecutable = (_: State, action: t.TypeOf<typeof NudgeAction>) => {
  const nudge = getNudgeById(_.engine, action.value);
  return () => {
    if (nudge) {
      forceTriggerNudge(_, nudge);
    }
  };
};

export const openChatExecutable = (action: t.TypeOf<typeof OpenChatAction>) => {
  let cb = () => undefined;
  switch (action?.meta?.type) {
    case 'intercom':
      cb = () => window.Intercom('show');
      break;
    case 'helpscout':
      cb = () => window.Beacon('open');
      break;
    case 'freshdesk':
      cb = () => window.FreshworksWidget('open');
      break;
    case 'crisp':
      cb = () => window.$crisp.push(['do', 'chat:open']);
      break;
    case 'zendesk':
      cb = () => window.zE('messenger', 'open');
      break;
    case 'liveChat':
      cb = () => window.LiveChatWidget.call('maximize');
      break;
    case 'olark':
      cb = () => window.olark('api.box.expand');
      break;
  }
  try {
    cb();
  } catch {}
};
