import * as React from 'react';
import chroma, { Color } from 'chroma-js';

export interface IBaseTheme {
  primary: Color;
  background: Color;
  font: Color;
  fontFamily: string;
}

export interface ITheme extends IBaseTheme {
  fontSecondary: Color;
}

// const defaultBaseTheme: IBaseTheme = {
//   primary: chroma('#80abe9'),
//   background: chroma('#425566'),
//   font: chroma('#fafafa'),
// };

const defaultBaseTheme: IBaseTheme = {
  primary: chroma('rgb(174, 177, 221)'),
  background: chroma('rgba(36, 35, 41, 0.98)'),
  font: chroma('rgba(255,255,255,0.85)'),
  fontFamily: 'Avenir Next, proxima-nova, sans-serif',
};

const getTheme = (theme: IBaseTheme): ITheme => {
  // const factor = theme.background.lch()[0] > 50 ? 'darken' : 'lighten';
  // const fontSecondary = chroma.blend(theme.primary, '#ddd', factor);
  const fontSecondary = chroma('#808080');

  return {
    ...theme,
    fontSecondary,
  };
};

export const defaultTheme = getTheme(defaultBaseTheme);

const ThemeContext = React.createContext<ITheme>(defaultTheme);

export default ThemeContext;
