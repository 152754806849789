import {
  DetailPreviewObjectType,
  DetailPreviewType,
  OptionGroupRenderAsType,
} from '@commandbar/internal/middleware/types';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { EngineState } from '../../store/engine/state';
import { interpolatePreview } from '../Interpolate';

export type SearchMatch = {
  path: string;
  value: string;
  indices: number[][];
};

export enum OptionType {
  Command = 'command',
  Parameter = 'parameter',
}

export interface OptionCategoryMetadata {
  label: string;
  contextKey: string;
  limit: number | null;
  renderAs: OptionGroupRenderAsType;
}

export interface GenericOption<T extends OptionType> {
  type: T;
  label: string;
  value: string;
  optionDisabled: {
    isDisabled: boolean;
    isDisabledReason: string;
    reasonIsUserDefined: boolean;
  };
  searchMatches: SearchMatch[];
  searchScore: number | undefined;
  groupKey: string | undefined;
  category: OptionCategoryMetadata;
  detailPreview: DetailPreviewObjectType[] | undefined | null;
}

export interface GenericOptionMetadata<T extends OptionType>
  extends Pick<GenericOption<T>, 'type' | 'label' | 'value'> {
  contextKey?: string;
  uid?: string;
}

export const initOption = <T extends OptionType>(
  _: EngineState,
  type: T,
  label: string,
  value: string,
  _searchKeys?: string[],
  _detailPreview?: DetailPreviewType | null,
): GenericOption<T> => {
  // FLAG PERFORMANCE: Here we try to interpolate the details of all commands and records.
  // Interpolating into records isn't as common (since they aren't editor-defined).
  // So, if someone has a very large record-list and is experiencing slowness, then this might be related.
  // We try to fast-fail with lookaheads (e.g s.includes('{{')), but we could speed it up by simply not attempting to interpolate.
  const detailPreview = standardize(interpolatePreview(_detailPreview, _.engine, true, false));

  const defaultCategoryMetadata: OptionCategoryMetadata = {
    label: '',
    contextKey: '',
    limit: null,
    renderAs: 'list',
  };

  return {
    type,
    label,
    value,
    detailPreview,
    optionDisabled: { isDisabled: false, isDisabledReason: '', reasonIsUserDefined: false },
    searchMatches: [],
    searchScore: undefined,
    groupKey: undefined,
    category: defaultCategoryMetadata,
  };
};

// export abstract class Option {
//   public static is = (o: unknown): o is Option =>
//     o instanceof Option || (o as Option)?.type === 'command' || (o as Option)?.type === 'parameter';
//   public type: string;
//   public label: string;
//   public value: string;
//   public optionDisabled: { isDisabled: boolean; isDisabledReason: string; reasonIsUserDefined: boolean };
//   public fuseMatches: IFuseSearchMatchType[];
//   public fuseScore: number | undefined;

//   constructor(_engine: EngineState['engine'], type: string, label: string, value: string, _searchKeys?: string[]) {
//     this.type = type;
//     this.label = label;
//     this.value = value;
//     // Careful calling this 'disabled' or 'isDisabled'; React-Select will catch that as a prop
//     this.optionDisabled = { isDisabled: false, isDisabledReason: '', reasonIsUserDefined: false };
//     this.fuseMatches = [];
//     this.fuseScore = undefined;
//     doNotTrack(this);
//   }

//   /** @deprecated  */
//   abstract isValid(engine: EngineState['engine']): { isValid: boolean; isValidReason: string };

//   /** @deprecated */
//   abstract isExecutable(engine: EngineState['engine']): { isExecutable: boolean; isExecutableReason: string };

//   /**
//    * When a user clicks on an option
//    */
//   /** @deprecated */
//   abstract choose(engine: EngineState['engine'], updateSteps: StepUpdater): void;

//   /** @deprecated  use engine/option/Option:getOptionIconColorBase */
//   public iconColorBase = (theme: ITheme, isFocused: boolean, isDisabled: boolean) => {
//     return getOptionIconColorBase(theme, isFocused, isDisabled);
//   };
// }
