import * as t from 'io-ts';

import { AxiosRequestConfig } from 'axios';
import axiosInstance from './network';
import { decodeToPromise } from './generics';
import {
  IAIAnswerPayloadType,
  IContinuationsPayloadType,
  ICreateAnswerFeedbackPayloadType,
  IOrganizationType,
  IQuestionSuggestionsPayloadType,
} from './types';
import { MessageV } from './chatAnalytics';

export const QuestionSuggestionsV = t.type({
  suggestions: t.array(t.string),
});

export const QuestionSuggestionsPayloadV = t.partial({
  user: t.union([t.string, t.null, t.undefined]),
  page_context: t.type({
    title: t.string,
    description: t.union([t.string, t.null]),
    h1s: t.array(t.string),
    h2s: t.array(t.string),
    h3s: t.array(t.string),
  }),
});

export const ContinuationsV = t.array(t.string);
export const ContinuationsPayloadV = t.type({
  command_ids: t.union([t.array(t.number), t.undefined]),
  passage_ids: t.union([t.array(t.number), t.undefined]),
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.string,
});

export const AIAnswerPayloadV = t.type({
  command_ids: t.union([t.array(t.number), t.undefined]),
  query: t.string,
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.union([t.string, t.undefined]),
});

export const AIAnswerV = t.type({
  chat_id: t.string,
  message_id: t.string,
});

export const CreateAnswerFeedbackPayloadV = t.type({
  message_id: t.number,
  rating: t.number,
});

export class Chat {
  public static listContinuations = async (orgUID: IOrganizationType['id'], payload: IContinuationsPayloadType) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/continuations`, payload);
    return await decodeToPromise(t.array(t.string), result.data);
  };

  public static createAnswerFeedback = async (
    orgUID: IOrganizationType['id'],
    payload: ICreateAnswerFeedbackPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/feedback`, payload);
    return await decodeToPromise(t.union([t.undefined, t.null, t.string]), result.data);
  };

  public static generateAIAnswer = async (
    orgUID: IOrganizationType['id'],
    payload: IAIAnswerPayloadType,
    config?: AxiosRequestConfig,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/live`, payload, config);
    return await decodeToPromise(t.exact(AIAnswerV), result.data);
  };

  public static generateQuestionSuggestions = async (
    orgUID: IOrganizationType['id'],
    payload: IQuestionSuggestionsPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/question-suggestions/${orgUID}`, payload);
    return await decodeToPromise(t.exact(QuestionSuggestionsV), result.data);
  };

  public static readMessage = async (chatId: string, messageId: string, signal?: AbortSignal) => {
    const result = await axiosInstance.get(`ml/chats/${chatId}/${messageId}`, { signal: signal });

    return await decodeToPromise(MessageV, result.data);
  };
}
