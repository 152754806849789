/** @jsx jsx */
import * as React from 'react';
import { useTheme } from 'emotion-theming';
import { jsx } from '@emotion/core';

import { OptionGroup, getDefaultHeaderHeight } from '../../../engine/OptionGroup';
import { Option } from '../../../engine/option';

import StickyHeader from './StickyHeader';
import { InterpolationWithTheme } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import { useStore } from '../../../hooks/useStore';
import { useVirtual, defaultRangeExtractor, Range, VirtualItem } from 'react-virtual';
import useResizeObserver from '../../../hooks/useResizeObserver';
import a11y from '../../../util/a11y';
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import { hideItem, MenuRow, MenuRowContent } from './MenuRow';

import EmptyMenu from './EmptyMenu';
import { AnimatedOptionFocus } from './SelectOption';
import ExecutionPath from '../../../engine/ExecutionPath';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { isOptionGroup } from '../../../store/engine';
import { isGridView } from '../../../store/app';

interface IProps {
  categories: ICommandCategoryType[];
  expandedGroupKeys: string[];
  focusedIndex: number;
  isLoading: boolean;
  onOptionHover: (optIndex: number) => void;
  sortedOptions: (Option | OptionGroup)[];
  menuWrapperRef: React.RefObject<HTMLDivElement>;
}

const menuListStyles = (_theme: ITheme): InterpolationWithTheme<any> => {
  return {
    scrollbarWith: 'none' /* Firefox */,
    overscrollBehavior: 'contain',
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    ':focus': {
      outline: 'none',
    },
  };
};

// An override to bypass measuring the parent element's size since jsdom does not have a layout engine and thus DOM
// measurements do not work correctly. This will convince react-virtual to always render everything at once.
const testObserver = () => ({ height: Number.MAX_SAFE_INTEGER, width: Number.MAX_SAFE_INTEGER });

function getMinItemHeight(index: number, theme: ITheme, rows: MenuRowContent[]) {
  if (!rows[index]) {
    return 0;
  }

  if (hideItem(index, rows[index])) return 0;

  if (rows[index].type === 'grid') {
    return parseInt(theme.menuGridContainer.minRowHeight, 10) || 250;
  } else {
    const isHeader = rows[index].type === 'header';
    const defaultMinHeight = isHeader ? getDefaultHeaderHeight(theme, index > 0) : parseInt(theme.option.minHeight, 10);
    // Use theme option minHeight instead of cache default minHeight
    return defaultMinHeight;
  }
}

const MenuList = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const { engine } = useStore();
  const parentRef = React.useRef<any>(undefined);
  const { currentStepIndex } = ExecutionPath.currentStepAndIndex(engine);

  const firstVisibleRowIndexRef = React.useRef<number | null>(null);
  const blockAutoScrollRef = React.useRef<boolean>(false);
  const activeStickyHeaderRef = React.useRef<number | undefined>(undefined);

  const sortedOptions = engine.sortedOptions;

  const isEmptyLoadingState = props.isLoading && sortedOptions.length === 0;
  const isEmptyState = sortedOptions.length === 0;

  const isGrid = isGridView(useStore());

  const [rowsToRender, optionIndexToRowMap] = React.useMemo(() => {
    const newRows: MenuRowContent[] = [];
    const newOptionIndexToRowMap: Record<number, number> = {};

    let counter = 0;
    for (let i = 0; i < sortedOptions.length; ) {
      let itemsProcessedInIteration = 0;

      const currentOption = sortedOptions[i];

      if (isOptionGroup(currentOption)) {
        newRows.push({ type: 'header', item: currentOption, index: counter });
        newOptionIndexToRowMap[counter] = newRows.length - 1;
        itemsProcessedInIteration++;
        counter++;

        if (isGrid) {
          let rowToAdd: MenuRowContent = { type: 'grid', item: [], index: counter };
          for (let j = i + 1; j < sortedOptions.length; j++) {
            const innerCurrentOption = sortedOptions[j];

            if (isOptionGroup(innerCurrentOption)) {
              break;
            } else {
              rowToAdd.item.push(innerCurrentOption);
              newOptionIndexToRowMap[counter] = newRows.length;
              itemsProcessedInIteration++;
              counter++;

              if (rowToAdd.item.length >= Number(theme.menuGridContainer.itemsPerRow || 3)) {
                newRows.push(rowToAdd);
                rowToAdd = { type: 'grid', item: [], index: counter };
              }
            }
          }

          if (rowToAdd.item.length >= 1) {
            newRows.push(rowToAdd);
          }
        }
      } else {
        newRows.push({ type: 'single', item: currentOption, index: counter });
        newOptionIndexToRowMap[counter] = newRows.length - 1;
        itemsProcessedInIteration++;
        counter++;
      }

      i = i + itemsProcessedInIteration;
    }

    return [newRows, newOptionIndexToRowMap];
  }, [sortedOptions]);

  /*********************************** Sticky header **************************************/
  const findActiveStickyHeader = React.useCallback(
    (startIndex: number): number | undefined => {
      const headerIndexesInReverse = rowsToRender
        .map((_, index) => index)
        .filter((index) => rowsToRender[index].type === 'header' && !hideItem(index, rowsToRender[index]))
        .reverse();
      return headerIndexesInReverse.find((headerIndex) => headerIndex <= startIndex);
    },
    [rowsToRender],
  );

  const getStickyHeader = (): OptionGroup | null => {
    if (activeStickyHeaderRef.current !== undefined) {
      const row = rowsToRender[activeStickyHeaderRef.current];
      if (row.type === 'header') {
        return row.item;
      }
    }
    return null;
  };
  /********************************************************************************/

  const estimateRowHeight = React.useCallback(
    (index: number) => getMinItemHeight(index, theme, rowsToRender),
    [theme, rowsToRender],
  );

  const rowVirtualizer = useVirtual({
    size: rowsToRender.length,
    parentRef,
    paddingStart: parseInt(theme.optionList.paddingTop, 10),
    paddingEnd: parseInt(theme.optionList.paddingBottom, 10),
    overscan: 5,
    rangeExtractor: React.useCallback(
      (range: Range) => {
        firstVisibleRowIndexRef.current = range.start;
        activeStickyHeaderRef.current = findActiveStickyHeader(range.start);
        return defaultRangeExtractor(range);
      },
      [findActiveStickyHeader],
    ),
    estimateSize: estimateRowHeight,
    useObserver: process.env.NODE_ENV === 'test' ? testObserver : undefined,
  });

  /*********************************** Autoscrolling logic ******************************/

  const itemIndexToAutoScrollTo = React.useMemo(() => {
    if (blockAutoScrollRef.current) {
      blockAutoScrollRef.current = false;
      return undefined;
    }

    if (firstVisibleRowIndexRef.current !== null && getStickyHeader()) {
      if (props.focusedIndex <= firstVisibleRowIndexRef.current + 1) {
        return Math.max(props.focusedIndex - 1, 0);
      }
    }

    return optionIndexToRowMap[props.focusedIndex];
  }, [props.focusedIndex]);

  React.useEffect(() => {
    if (itemIndexToAutoScrollTo !== undefined) {
      // if a category has padding at the top, scrollToIndex(0) doesn't include it
      // instead scrollToOffset(0)
      if (itemIndexToAutoScrollTo === 0) {
        rowVirtualizer.scrollToOffset(0);
      } else {
        rowVirtualizer.scrollToIndex(itemIndexToAutoScrollTo);
      }
    }
  }, [itemIndexToAutoScrollTo]);

  const onOptionHover = React.useCallback(
    (index: number) => {
      props.onOptionHover(index);
      blockAutoScrollRef.current = true;
    },
    [props.onOptionHover, blockAutoScrollRef],
  );

  const onToggleOptionGroup = React.useCallback(() => {
    blockAutoScrollRef.current = true;
  }, [blockAutoScrollRef]);

  /*********************************** RENDER **************************************/
  // Pass in group positions for a11y reasons
  const groupPositions: { [key: string]: number } = React.useMemo(() => {
    const positions: { [key: string]: number } = {};
    sortedOptions.forEach((opt, idx) => {
      if (isOptionGroup(opt)) {
        positions[opt.key] = idx;
      }
    });
    return positions;
  }, [sortedOptions]);

  const rowRenderer = React.useCallback(
    (item: VirtualItem) => {
      const row = rowsToRender[item.index];

      if (!row) {
        return null;
      }

      return (
        <MenuRow
          {...item}
          content={row}
          key={item.index}
          index={row.index}
          onToggleOptionGroup={onToggleOptionGroup}
          onOptionHover={onOptionHover}
          groupPositions={groupPositions}
        />
      );
    },
    [rowsToRender, optionIndexToRowMap],
  );

  // "Disable" virtualized row rendering by using a huge number for the max height in test environments; this allows for
  // predictable test results since there is no layout engine in jsdom and thus no way to properly scroll the list.
  const maxHeight = process.env.NODE_ENV === 'test' ? Number.MAX_SAFE_INTEGER : parseInt(theme.bar.menuHeight, 10);
  const [emptyMenuEl, setEmptyMenuEl] = React.useState<HTMLElement | null>(null);
  const emptyMenuRef = React.useCallback((node: HTMLDivElement) => {
    setEmptyMenuEl(node);
  }, []);
  const { height: emptyMenuHeight } = useResizeObserver(emptyMenuEl);
  const { height: menuWrapperHeight } = useResizeObserver(props.menuWrapperRef.current);

  if (!engine.visible) {
    return (
      <div>
        <div />
      </div>
    );
  }

  const getMenuHeight = () => {
    const defaultSize = isMobile() ? menuWrapperHeight : Math.min(rowVirtualizer.totalSize, maxHeight);

    if (isEmptyState) {
      return emptyMenuHeight ?? defaultSize;
    }

    return defaultSize;
  };

  const menuHeight = getMenuHeight();
  return (
    <div
      style={{
        height: menuHeight,
        // Hide the scrollbars if we don't compute the size of the container correctly.
        // This is a workaround for a bug causing an infinite loop involving the scrollbar
        // appearing, changing the size of the container, disappearing,
        // changing the size of the container, etc. which causes a loop.
        overflow: 'hidden',
        transition: '0.1s height ease-out',
      }}
    >
      {isEmptyState ? (
        <div
          ref={emptyMenuRef}
          id="commandbar_empty_menu"
          style={{
            minHeight: theme.bar.menuMinHeight,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isEmptyLoadingState ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            />
          ) : (
            <EmptyMenu />
          )}
        </div>
      ) : (
        <div>
          <StickyHeader
            group={getStickyHeader() || undefined}
            themeContext={theme}
            expandedGroupKeys={props.expandedGroupKeys}
            paddingTop={theme.optionList.paddingTop}
          />
          <div
            id="commandbar_menu_list"
            style={{
              marginTop: theme.optionList.marginTop,
              marginBottom: theme.optionList.marginBottom,
              maxHeight: isMobile() ? menuHeight : maxHeight,
              height: isMobile() ? menuHeight : 'auto',
              overflow: 'auto',
              scrollbarWidth: 'none',
            }}
            css={menuListStyles(theme)}
            ref={parentRef}
          >
            <div
              role={a11y.listRole}
              aria-label={a11y.listLabel}
              id={a11y.listId}
              style={{
                height: `${rowVirtualizer.totalSize}px`,
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {!!rowsToRender[optionIndexToRowMap[props.focusedIndex]] &&
                rowsToRender[optionIndexToRowMap[props.focusedIndex]].type !== 'grid' && (
                  <AnimatedOptionFocus
                    // remount focus on step change to prevent
                    // highlight from translating long distances
                    // when step changes
                    key={`focus-${currentStepIndex}`}
                    virtualItems={rowVirtualizer.virtualItems}
                    focusedIndex={optionIndexToRowMap[props.focusedIndex]}
                  />
                )}
              {rowVirtualizer.virtualItems.map(rowRenderer)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuList;
