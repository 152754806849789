/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import { isContextArgument } from '@commandbar/internal/middleware/types';
import { getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import ExecutionPath from './ExecutionPath';
import { Option } from './option';
import fuzzy, { Fuzzysort } from './fuzzysort-fork/fuzzysort';
import partition from 'lodash/partition';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { EngineState } from '../store/engine/state';
import {
  setOptionMatches,
  isCommandOption,
  isResourceOption,
  isParameterOption,
  isUnfurledCommandOption,
} from '../store/engine/options/helpers';
import * as Command from '@commandbar/internal/middleware/command';
import { SearchMatch } from './option/Option';
import deepGet from './fuzzysort-fork/deepGet/deepGet';
import { EndUserStore } from '../store/engine/end-user/state';

const fuzzysort = fuzzy();

interface SearchResult {
  item: Option;
  score: number;
  matches: SearchMatch[];
  refIndex: number;
}

export const SEARCH_CONFIG = {
  defaultFuzzyThreshold: 100, // scale of 0 - 100
  minStringLengthToExactSearch: 100, // for strings > X, we run an exact search (instead of fuzzy) for speed
  scoreCutoffThreshold: -100000, // anything with a score < X is cut off from results (for speed)
  maxSearchScore: -100, // anything above this is capped so that small differences for great matches are ignored
};

const os = getOperatingSystem();

const searchableFields: Record<string, { weight: number; path: string; desc: string }> = {
  label: {
    path: 'label',
    weight: 1,
    desc: "[All] The label of any option. It's what you see in the bar",
  },
  categoryLabel: {
    weight: 0.5,
    path: 'category.label',
    desc: "[All] The label of an option's category.",
  },
  commandExplanation: {
    weight: 0.6,
    path: 'command.explanation',
    desc: '[CommandOption] The explanation of a command.',
  },
  commandHeading: {
    weight: 0.6,
    path: 'command.heading',
    desc: '[CommandOption] The heading of a command.',
  },
  commandSynonyms: {
    weight: 0.5,
    path: 'command.tags',
    desc: '[CommandOption] The synonyms of a command.',
  },
  parameterUserDefinedSearchFields: {
    weight: 0.8,
    path: 'tags.value',
    desc: '[ParameterOption] User-defined searchable fields.',
  },
  parameterDescription: {
    weight: 0.8,
    path: 'description',
    desc: '[ParameterOption] The description of a parameter.',
  },
  parameterHeading: {
    weight: 0.8,
    path: 'heading',
    desc: '[ParameterOption] The heading of a parameter.',
  },
  ...(['mac'].includes(os) && {
    commandHotkeyMac: {
      weight: 1.01,
      path: 'command.hotkey_mac',
      desc: '[CommandOption] The hotkey for a command on Mac.',
    },
  }),
  ...(['windows', 'linux'].includes(os) && {
    commandHotkeyWindows: {
      weight: 1.01,
      path: 'command.hotkey_win',
      desc: '[CommandOption] The hotkey for a command on Windows / Linux.',
    },
  }),
};

// Given an array of ordered match indexes, return all sequences
//    Input: [1,2,3,5,7,8]
//    Output: [[1,3], [7,8]]
export const findAllSequences = (indexes: number[]): number[][] => {
  let count = 0;
  const pairs = [];

  if (indexes.length === 1) return [[indexes[0], indexes[0]]];
  for (let i = 1; i < indexes.length; i++) {
    if (i > 0 && indexes[i] === indexes[i - 1] + 1) {
      // Continue the sequence
      count += 1;
    } else {
      if (count > 1) {
        // Sequence ended with last index
        const prevIndex = i - 1;
        pairs.push([indexes[prevIndex - count], indexes[prevIndex]]);
      }
      count = 0;
    }
  }
  if (count > 0) {
    // If we're still in a sequence, add it
    const maxIndex = indexes.length - 1;
    pairs.push([indexes[maxIndex - count], indexes[maxIndex]]);
  }
  return pairs;
};

const compareSearchResults = (a: SearchResult, b: SearchResult): number => {
  // Javascript sort isn't stable. Use the original index as a secondary sorter
  if (a.score === b.score) {
    // Low is better
    return a.refIndex - b.refIndex;
  }
  // Higher is better
  return b.score - a.score;
};

const transformRawResult = (rawResult: Fuzzysort.KeysResult<Option>, keys: string[], index: number): SearchResult => {
  const bestMatchIndex = rawResult.findIndex(
    (match: Fuzzysort.Result, index: number) => weightScore(keys[index], match?.score) === rawResult.score,
  );

  // If match doesn't exist (-Infinity) return a default match
  // This can happen if we do a threshold of -Infinity

  const bestMatch = !!rawResult[bestMatchIndex]
    ? {
        path: searchableFields[keys[bestMatchIndex]].path,
        value: rawResult[bestMatchIndex].target,
        indices: findAllSequences(rawResult[bestMatchIndex].indexes),
      }
    : { path: 'label', value: rawResult.obj.label, indices: [[]] };

  const toRet = { item: rawResult.obj, score: rawResult.score, refIndex: index, matches: [bestMatch] };
  return toRet;
};

function weightScore(key: string, score: number | undefined | null) {
  if (score === null || score === undefined) return -Infinity;
  const cappedScore = capSearchScore(score);
  const weight = searchableFields[key].weight;
  if (!weight) return cappedScore;
  return cappedScore - (1 - weight) * 100;
}

function getSearchTargetFromOption(userCustomizedHotkeys: any, opt: Option, key: string) {
  const path = searchableFields[key].path;
  let value = deepGet(opt, path);

  // If we're getting a shortcut, filter out any keys that aren't a single character (e.g., Command)
  if (['command.hotkey_win', 'command.hotkey_mac'].includes(path)) {
    if (isCommandOption(opt)) {
      const cmdUID = Command.commandUID(opt.command);
      if (userCustomizedHotkeys.hasOwnProperty(cmdUID)) {
        value = userCustomizedHotkeys[cmdUID];
      }
    }

    if (!!value && Array.isArray(Hotkey.toArray(value))) {
      value = Hotkey.toArray(value)
        .filter((shortcut: string) => shortcut.length === 1)
        .join('');
    }
  }

  // For unfurled commands, concatenate the resource label
  // Allows for searches like "open record 42" or "add to groceries"
  if (path === 'label' && isUnfurledCommandOption(opt)) {
    return opt.label + ' ' + opt.resource.label;
  }

  return value;
}

function searchFuzzy(
  userCustomizedHotkeys: EndUserStore['data']['hotkeys'],
  options: Option[],
  input: string,
  opts?: Partial<Fuzzysort.Options>,
) {
  const keysToSearch = Object.keys(searchableFields);
  const results = fuzzysort.go(input, options, {
    keys: keysToSearch,
    threshold: SEARCH_CONFIG.scoreCutoffThreshold,
    allowTypo: true,
    minLengthForExactSearch: SEARCH_CONFIG.minStringLengthToExactSearch,
    shouldSort: false, // Off because fuzzysort sort isn't stable
    getFn: (opt: Option, path: string) => getSearchTargetFromOption(userCustomizedHotkeys, opt, path),
    scoreFn: function (a: ReadonlyArray<Fuzzysort.KeyResult<Option>>) {
      const results = keysToSearch.map((key, index) => {
        return weightScore(key, a[index]?.score);
      });
      return Math.max(...results);
    },
    ...opts,
  });
  return results.map((r: Fuzzysort.KeysResult<Option>, i: number) =>
    transformRawResult(r, Object.keys(searchableFields), i),
  );
}

// cap score so that exact matches with small differences are equal
const capSearchScore = (score: number) => {
  // estimate -- might have to update
  return Math.min(score, SEARCH_CONFIG.maxSearchScore);
};

/****************************** Auto-show options ***********************/
function isClientSearchOption(option: Option, engine: EngineState['engine']): boolean {
  if (isCommandOption(option)) {
    return option.command.isAsync ? option.command.isAsync : false;
  }
  if (isResourceOption(option)) {
    const addSearchKey = `commandbar-search-${option.category.contextKey}`;
    return !!engine.callbacks[addSearchKey];
  }
  if (isParameterOption(option)) {
    const { currentStep } = ExecutionPath.currentStepAndIndex(engine);

    // @ts-expect-error: FIXME step types
    if (!currentStep || !currentStep.argument || !currentStep.argument.value) {
      return false;
    }

    // @ts-expect-error: FIXME step types
    const argument = currentStep.argument;

    if (!isContextArgument(argument)) return false;
    const addSearchKey = `commandbar-search-${argument.value}`;
    return !!engine.callbacks[addSearchKey];
  }
  return false;
}
/****************************** API functions ***********************/

const search = (input: string, engine: EngineState['engine'], options: Option[]): Option[] => {
  if (input === '') {
    // Return options with cleared matches
    return options.map((x: Option) => setOptionMatches(x, [], undefined));
  }

  const [standardOptions, clientSearchOptions] = partition(options, (o: Option) => !isClientSearchOption(o, engine));
  const [clientSearchOptionsToSort, nonSortedClientSearchOptions] = partition(
    clientSearchOptions,
    (o: Option) => isResourceOption(o) && o.searchOptions?.onInputChangeOptions?.applySort,
  );

  // Threshold of 0 === Exact search
  const fuzzyThreshold = engine.organization?.search_fuzzy_threshold ?? SEARCH_CONFIG.defaultFuzzyThreshold;
  const shouldUseExactSearch = fuzzyThreshold === 0;
  const standardResults = searchFuzzy(
    engine,
    standardOptions,
    input,
    shouldUseExactSearch
      ? { minLengthForExactSearch: 0 }
      : {
          // Org threshold positive #. 1 (close to exact match ) => -1000; 100 (default) => -100,000
          threshold: fuzzyThreshold * -1000,
        },
  ).sort(compareSearchResults);

  const sortedClientSearchOptions = searchFuzzy(engine.endUserStore.data.hotkeys, clientSearchOptionsToSort, input, {
    threshold: -Infinity,
    shouldSort: true,
  });

  const unsortedClientSearchOptions = searchFuzzy(
    engine.endUserStore.data.hotkeys,
    nonSortedClientSearchOptions,
    input,
    {
      threshold: -Infinity,
      shouldSort: false,
    },
  );

  const searchResults: SearchResult[] = [
    ...standardResults,
    ...sortedClientSearchOptions,
    ...unsortedClientSearchOptions,
  ];

  return searchResults.map((x: SearchResult) => setOptionMatches(x.item, x.matches, x.score));
};

export default search;
