/** @jsx jsx  */

import { jsx, css, keyframes } from '@emotion/core';
import Ai from '@commandbar/design-system/icons/react/Ai';

const glowPulseEffect = keyframes`
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1.025);
    }
  `;

const borderPulseEffect = keyframes`
    to {
      transform: rotate(360deg);
    }
  `;

const styles = {
  glowWrapper: css`
    position: relative;
    display: inline-flex;
    transform: translateY(-50%);
    transition: all 0.25s;
    width: 42px;
    height: 42px;
    top: 20px;
    &:after {
      content: '';
      position: absolute;
      border-radius: 100px;
      left: 0;
      top: 0;
      background-color: #b02aef;
      width: 100%;
      height: 100%;
      box-shadow: -4px 4px 12px -2px #b02aef, 4px -4px 12px -2px #0051ff;
      animation: ${glowPulseEffect} 2s linear infinite alternate;
    }
  `,
  btnWrapper: css`
    position: relative;
    overflow: hidden;
    background-color: transparent;
    display: inline-flex;
    border-radius: 100px;
    z-index: 1;
    transition: all 0.25s;
    top: 0px;
    &:after {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      width: 250px;
      height: 250px;
      top: -112px;
      left: -84px;
      /* 	Controls border pulse color */
      background: linear-gradient(90deg, #ffffff 49.69%, #aa56ff 51.75%, #aa56ff 53.26%, #ffffff 55.32%);
      animation: ${borderPulseEffect} linear 4.5s infinite;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all 0.25s;
    }
  `,
  btn: css`
    background: linear-gradient(
      94.91deg,
      #1f1f1f 12.99%,
      #262a49 25.99%,
      #1f1f1f 35.82%,
      #272c5e 73.6%,
      #1f1f1f 92.23%
    );
    color: #d2d2d2;
    margin: 1px;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
    transition: all 0.25s;
    white-space: nowrap;
    gap: 4px;
    transition: all 0.25s;
    position: relative;
    width: 40px;
    height: 40px;
  `,
};

const AskAIButton = () => {
  return (
    <div css={styles.glowWrapper}>
      <div css={styles.btnWrapper}>
        <div css={styles.btn}>
          <Ai width="14.6" height="16" />
        </div>
      </div>
    </div>
  );
};

export default AskAIButton;
