/** @jsx jsx */
import * as React from 'react';
import { useTheme } from 'emotion-theming';
import { jsx } from '@emotion/core';

import SVG from '@commandbar/internal/util/SVG';

import Z from '@commandbar/internal/client/Z';
import { inputStyles } from './Input';
import LoadingIndicator from './LoadingIndicator';
import ClearIndicator from './ClearIndicator';
import { ITheme } from '@commandbar/internal/client/theme';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import Button from '../../Button';
import * as App from '../../../store/app/actions';
import * as Engine from '../../../store/engine/actions';
import { useAction } from '../../../hooks/useAction';
import { useStore } from '../../../hooks/useStore';
import a11y from '../../../util/a11y';
import { selectFirstPlaceholder, selectMenuIsOpen, showRotatingPlaceholders } from '../../../store/app/selectors';
import ExecutionPath from '../../../engine/ExecutionPath';
import { StepType } from '../../../engine/step/Step';
import SearchFilterInputTag from './SearchFilterInputTag';
import { LongTextInputStep } from '../../../engine/step';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import SlashFilterInput from './SlashFilterInput';
import { ISearchFilter } from '../../../store/app';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import StatusBar from './StatusBar';
import RenderCustomComponent from '../../RenderCustomComponent';
import AnimatedPlaceholder from './AnimatedPlaceholder';

interface IProps {
  inputRef: React.RefObject<any>;
  setControlWrapperRef: React.RefCallback<HTMLElement>;
}

const controlStyles = (theme: ITheme, inputInactive: boolean): React.CSSProperties => ({
  border: theme.input.border,
  borderRadius: theme.input.borderRadius,
  borderBottom: inputInactive ? theme.input.borderBottomInactive : theme.input.borderBottom,
  background: inputInactive ? theme.input.backgroundInactive : theme.input.background,
  // borderRadius: `${theme.main.borderRadius} ${theme.main.borderRadius} 0px 0px`,
  // fontSize: theme.input.fontSize,
  // color: theme.input.color,
  // background: theme.main.background,
  zIndex: Z.Z_COMMANDBAR,
});

interface ILongTextInputProps extends Omit<IProps, 'setControlWrapperRef'> {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  currentStep: LongTextInputStep;
}

const LongTextInput = ({ currentStep, handleInputChange, inputRef }: ILongTextInputProps) => {
  const _ = useStore(true);
  const engine = _.engine;
  const { theme }: { theme: ITheme } = useTheme();

  const placeholder = selectFirstPlaceholder(useStore());

  // We duplicate the code here since shifting onKeyDown handler here causes multiple calls
  const handleSubmit = useAction((_, e: React.MouseEvent<HTMLButtonElement>) => {
    const option = initParameterOption(_, currentStep.argument.userDefinedName, _.engine.inputText);
    App.selectOption(_, option);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {' '}
        <textarea
          name="longtextinput"
          value={engine.rawInput}
          placeholder={placeholder}
          ref={inputRef}
          onChange={handleInputChange}
          rows={8}
          css={{
            display: 'flex',
            width: 'calc(100% + 15px)',
            padding: 16,
            color: theme.main.color,
            fontFamily: theme.main.fontFamily,
            backgroundColor: getChromaColor(theme.base.fontColor).alpha(0.03).css(),
            border: `1px solid ${getChromaColor(theme.base.fontColor).alpha(0.2).css()}`,
            borderRadius: 8,
            fontSize: theme.base.fontSize,
            lineHeight: '16px',
            fontWeight: 500,
            resize: 'none',
            '&:focus': {
              outline: 'none',
              border: `2px solid ${getChromaColor(theme.base.fontColor).alpha(0.2).css()}`,
            },
          }}
        />
      </div>

      <div
        css={{
          display: isMobile() ? 'flex' : 'none',
          width: '100%',
          justifyContent: 'center',

          '& Button': {
            marginTop: '16px',
          },
        }}
      >
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  );
};

const Control = (props: IProps) => {
  const handleInputChange = useAction((_, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    Engine.handleInputChange(_, e.target.value);
  });
  const { theme }: { theme: ITheme } = useTheme();
  const { engine, showLoadingIndicator, userDefinedCustomComponents } = useStore(true);
  const { focusedIndex } = engine;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const currentStepIsLongTextInputStep = currentStep?.type === StepType.LongTextInput;
  const menuIsOpen = selectMenuIsOpen(useStore());

  const resetFocusedIndex = useAction(Engine.resetFocusedIndex);
  const menuEmpty = engine.sortedOptions.length === 0;
  const logo = engine.logo;

  const setSearchFilter = useAction((_, filter: ISearchFilter | undefined) => (_.searchFilter = filter));
  const { searchFilter } = useStore();

  const component = currentStep?.type !== 'execute' && userDefinedCustomComponents.input({ step: currentStep?.type });

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (engine.rawInput === '' && !!searchFilter?.inputTag) {
        setSearchFilter(undefined);
      }
    }
  };

  const styles = inputStyles(theme, engine.rawInput, menuIsOpen);
  const placeholder = selectFirstPlaceholder(useStore());

  const [isFocused, setIsFocused] = React.useState(false);
  const hasRotatingPlaceholders = showRotatingPlaceholders(useStore());

  const createInput = () => {
    const inputEl = (
      <input
        onKeyDown={onKeyDown}
        id={a11y.commandbarInputId}
        data-testid="commandbar-input"
        css={styles}
        value={engine.rawInput}
        placeholder={hasRotatingPlaceholders ? '' : placeholder}
        onChange={handleInputChange}
        ref={props.inputRef}
        autoComplete="off"
        role={a11y.inputRole}
        aria-expanded={menuIsOpen}
        aria-controls={menuEmpty ? a11y.emptyListId : a11y.listId}
        aria-owns={menuEmpty ? a11y.emptyListId : a11y.listId}
        aria-activedescendant={menuEmpty ? a11y.emptyListId : a11y.optionId(focusedIndex)}
        aria-label={placeholder}
        aria-placeholder={placeholder}
        onBlur={() => setIsFocused(false)}
        onFocus={(_) => {
          // When focusing on the input, set the virtual focus in to the list.
          setIsFocused(true);
          if (focusedIndex === -1) resetFocusedIndex();
        }}
      />
    );

    return (
      <React.Fragment>
        {hasRotatingPlaceholders && <AnimatedPlaceholder isFocused={isFocused || engine.formFactor.type === 'modal'} />}
        {inputEl}
        {!!engine.organization?.slash_filters_enabled && currentStep?.type === StepType.Base && (
          <SlashFilterInput inputStyles={styles} />
        )}
      </React.Fragment>
    );
  };

  const isInlineBar = engine.formFactor.type === 'inline';
  const focusTimer = React.useRef<ReturnType<typeof setTimeout>>();
  const openIfClosed = useAction((_) => {
    if (!_.engine.visible) {
      if (App.toggleBarFromLauncher(_)) {
        // If an element in the input is clicked (e.g., a border) that takes focus, we'll need
        // to transfer that focus to the input
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clearTimeout(focusTimer.current!);
        focusTimer.current = setTimeout(() => _.refContainer.current?.focus(), 200);
      }
    }
  });

  return (
    // The <div> element has a child <input> element that allows keyboard interaction
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      id="commandbar__control_wrapper"
      onClick={isInlineBar ? () => openIfClosed() : undefined}
      style={controlStyles(theme, !menuIsOpen)}
      ref={props.setControlWrapperRef}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <StatusBar />
        <div
          id="commandbar__control"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.input.paddingLeft,
            paddingRight: theme.input.paddingRight,
            paddingTop: theme.input.paddingTop,
            paddingBottom: theme.input.paddingBottom,
            cursor: 'text',
          }}
        >
          {!currentStepIsLongTextInputStep && <Logo ariaHidden={true} svgString={logo} />}
          <SearchFilterInputTag />

          <div
            style={{ flexGrow: 1, display: 'flex', alignItems: 'center', position: 'relative' }}
            className="commandbar__input"
          >
            {currentStepIsLongTextInputStep ? (
              <LongTextInput
                currentStep={currentStep as LongTextInputStep}
                handleInputChange={handleInputChange}
                inputRef={props.inputRef}
              />
            ) : (
              createInput()
            )}

            <div
              role="progressbar"
              aria-label="progress bar"
              style={{ maxHeight: 0, display: 'flex', alignItems: 'center' }}
            >
              {showLoadingIndicator ? (
                <div>
                  <LoadingIndicator size={22} isLoading />
                </div>
              ) : (
                engine.inputText !== '' &&
                !currentStepIsLongTextInputStep && (
                  <div>
                    <ClearIndicator />
                  </div>
                )
              )}
            </div>
            {typeof component === 'string' ? (
              <div
                // Spacing of right side input elements should match those on left
                style={{ marginLeft: theme.logo.spaceBetweenLogoAndText }}
                dangerouslySetInnerHTML={{ __html: component }}
              />
            ) : (
              component && <RenderCustomComponent component={component} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = (props: { ariaHidden: boolean; svgString: string }) => {
  const { theme }: { theme: ITheme } = useTheme();
  if (props.svgString.length > 0) {
    return (
      <SVG
        ariaHidden={props.ariaHidden}
        style={{
          height: theme.logo.size,
          width: theme.logo.size,
          left: theme.logo.left,
          top: theme.logo.top,
          marginRight: theme.logo.spaceBetweenLogoAndText,
        }}
        htmlstring={props.svgString}
        useDefaultSVGColor={true}
      />
    );
  } else {
    return null;
  }
};

export default Control;
