/** @jsx jsx  */
import React, { ReactElement, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { css, jsx } from '@emotion/core';
import chroma from 'chroma-js';

import { useAction } from '../../hooks/useAction';
import { useStyles } from './useStyles';
import useHasScroll from '../../hooks/useHasScroll';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { useStore } from '../../hooks/useStore';

import * as Reporting from '../../analytics/Reporting';
import Logger from '@commandbar/internal/util/Logger';
import { AnalyticsEngagementTracking } from '../detailpreview/AnalyticsEngagementTracking';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { removeDeepLinkParams } from '../../store/engine/help-hub/helpers';

import { ArrowUpRight, ChevronLeft, CornerDownRight, Link04 } from '@commandbar/design-system/icons/react';
import VideoPreview from '../detailpreview/VideoPreview';
import { ReactComponent as CloseIcon } from '../../img/xclose.svg';

import { HelpHubDoc } from '../../store/engine';
import type { ICommandType, LabeledAction } from '@commandbar/internal/middleware/types';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import AskAIButton from './AskAIButton';
import { ChatMessage } from '../../client_api/search';
import { isAction } from '@commandbar/internal/middleware/helpers/actions';
import * as Engine from '../../store/engine/actions';

export enum DEEP_LINK_PARAMS {
  COMMAND_ID = 'helphub_id',
  QUERY = 'helphub_query',
  SCROLL = 'helphub_scroll',
}

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    Logger.error('Failed to copy text to clipboard:', err);
  }
};

const generateDeepLink = async (commandID: number | string, query: string | null, scrollPosition: number) => {
  const currentUrl = new URL(window.location.href);

  const urlParams = currentUrl.searchParams;
  urlParams.set(DEEP_LINK_PARAMS.COMMAND_ID, encodeURIComponent(commandID));
  urlParams.set(DEEP_LINK_PARAMS.QUERY, encodeURIComponent(query || ''));
  urlParams.set(DEEP_LINK_PARAMS.SCROLL, String(scrollPosition));

  const deepLink = currentUrl.toString();
  await copyToClipboard(deepLink);

  toast.success('Copied to clipboard!');
};

export const isHelpDocWithLink = (
  template: ICommandType['template'],
): template is ICommandType['template'] & { type: 'helpdoc' } => !!template.value && template.type === 'helpdoc';

const Doc = ({
  doc,
  setCurrentDoc,
  setChatHistory,
  setIsChatMode,
}: {
  doc: HelpHubDoc;
  setCurrentDoc: (doc: HelpHubDoc | null) => void;
  setChatHistory?: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setIsChatMode?: (isChatMode: boolean) => void;
}) => {
  const [startTime] = React.useState<number>(Date.now());
  const { engine } = useStore();
  const query = engine.helpHub.query;
  const styles = useStyles();
  const setHelpHubScrollPosition = useAction((_, scrollPosition: number) => {
    _.engine.helpHub.scrollPosition = scrollPosition;
  });
  const docTemplate = doc.command.template;

  const scrollRef = useRef<HTMLDivElement>(null);
  const { hasScrolled } = useHasScroll(scrollRef);

  const [localQuery, setLocalQuery] = React.useState('');
  const [isLocalChatMode, setIsLocalChatMode] = React.useState(false);

  React.useEffect(() => {
    const scrollPosition = engine.helpHub.scrollPosition;

    if (scrollRef && scrollPosition) {
      scrollRef?.current?.scrollTo(0, scrollPosition);
      setHelpHubScrollPosition(0);
    }
  }, [engine.helpHub.scrollPosition, setHelpHubScrollPosition]);

  const docEventData = {
    query: query ?? undefined,
    command: doc.commandID,
  };

  React.useEffect(() => {
    if (hasScrolled) {
      Reporting.helpHubDocEngagement({ ...docEventData }, 'scrolled');
    }
  }, [hasScrolled]);

  React.useEffect(() => {
    Reporting.helpHubDocOpened({ ...docEventData });
  }, []);

  const anchorClickHandler = () => Reporting.helpHubDocEngagement({ ...docEventData }, 'clicked_link');
  const videoPlayHandler = () => Reporting.helpHubDocEngagement({ ...docEventData }, 'watched_video');

  React.useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        setCurrentDoc(null);
        removeDeepLinkParams();
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const renderContent = (content: HelpHubDoc['content']) => {
    const standardized = standardize(content);
    if (!standardized) return null;
    return standardized.map((contentBlock, index) => {
      switch (contentBlock.type) {
        case 'html':
          return (
            <StyledHtml key={index}>
              <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(contentBlock.value) }} />
            </StyledHtml>
          );
        default:
          /** Video, react, markdown, plaintext, component unimplemented */
          throw Error('Invalid content type');
      }
    });
  };

  const nextSteps = doc?.command.next_steps || [];

  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  const executeAction = useAction(
    (_, action: LabeledAction['action'], toggleHelpHub: boolean, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      Engine.executeAction(_, action, e);

      if (toggleHelpHub) {
        toggleHelpHubVisible();
      }
    },
  );

  return (
    <AnalyticsEngagementTracking anchorClickHandler={anchorClickHandler} videoPlayHandler={videoPlayHandler}>
      <div css={styles.docContainer}>
        <div css={styles.docHeader}>
          <button
            css={{ ...styles.docHeaderButton, width: '72px' }}
            onClick={() => {
              setCurrentDoc(null);
              Reporting.helpHubDocClosed({ ...docEventData, time_shown: (Date.now() - startTime) / 1000 });
              removeDeepLinkParams();
            }}
          >
            <ChevronLeft height={14} />
            <span>Back</span>
          </button>

          <div css={styles.docHeaderRight}>
            <button
              css={{ ...styles.docHeaderButton, padding: '8px', width: '40px' }}
              onClick={() => generateDeepLink(doc.commandID, query, scrollRef?.current?.scrollTop ?? 0)}
            >
              <Link04 height={16} />
            </button>

            {isHelpDocWithLink(docTemplate) && (
              <button
                css={{
                  ...styles.docHeaderButton,
                  width: 'auto',
                  maxWidth: '176px',
                }}
                onClick={() => {
                  window.open(docTemplate.value, '_blank');
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {styles.strings.docLinkText}
                </span>
                <ArrowUpRight height={14} />
              </button>
            )}
          </div>
        </div>
        <div
          style={{ height: 'calc(100% - 66px)', overflowY: 'auto', paddingBottom: '50px' }}
          className="commandbar-helphub-article"
          ref={scrollRef}
        >
          <div css={styles.docTitle}>{doc.title}</div>

          {doc.type === 'video' && <div css={styles.docExcerpt}>{doc.excerpt}</div>}
          <div css={styles.docContent}>
            {doc.type === 'video' ? (
              <VideoPreview
                source={doc.videoUrl}
                containerStyles={{ height: 'auto', margin: '8px 0', padding: 0 }}
                autoPlay={false}
              />
            ) : (
              renderContent(doc.content)
            )}

            {nextSteps.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  padding: '0px',
                  alignItems: 'flex-start',
                  marginTop: '8px',
                }}
              >
                {nextSteps.map(
                  (nextStep, index) =>
                    isAction(nextStep) &&
                    !!nextStep.cta && (
                      <button
                        key={index}
                        onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                          executeAction(nextStep.action, false, e);
                        }}
                        style={{
                          width: '100%',
                          border: 'none',
                          cursor: 'pointer',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          background: '#0A0A0F',
                          color: '#FFFFFF',
                          borderRadius: '4px',
                          padding: '11px 12px',
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontWeight: '600',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '4px',
                        }}
                      >
                        {nextStep.cta}
                      </button>
                    ),
                )}
              </div>
            )}
          </div>
        </div>

        {engine?.organization?.helphub_ai_enabled && !!setChatHistory && !!setIsChatMode && (
          <React.Fragment>
            {isLocalChatMode ? (
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div css={styles.docInputContainer}>
                  <div style={styles.docInputWrapper}>
                    <CornerDownRight
                      style={{ position: 'absolute', top: '16px', left: '16px', opacity: 0.5 }}
                      height={14}
                    />
                    <input
                      autoFocus // eslint-disable-line
                      value={localQuery || ''}
                      onChange={(e) => setLocalQuery(e.target.value)}
                      css={styles.docInput}
                      placeholder={'ask a question about this doc...'}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          setChatHistory((prev) => [...prev, { message: localQuery, type: 'user' }]);
                          setIsChatMode(true);
                        } else if (e.key === 'Escape') {
                          e.preventDefault();
                          e.stopPropagation();
                          setLocalQuery('');
                          setIsLocalChatMode(false);
                        }
                      }}
                    />
                    {localQuery && localQuery.length > 0 && (
                      <CloseIcon
                        style={{
                          ...styles.closeIcon,
                          right: '16px',
                        }}
                        onClick={() => setLocalQuery('')}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ position: 'absolute', bottom: '8px', right: '24px', zIndex: '1' }}>
                <AskAIButton onClick={() => setIsLocalChatMode(true)} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </AnalyticsEngagementTracking>
  );
};

const StyledHtml = ({ children }: { children: ReactElement }) => {
  const theme = useCommandBarTheme();
  const styles = useStyles();

  return (
    <div
      css={css`
        img {
          display: block;
          max-width: 100%;
          height: auto;
          box-shadow: ${theme.main.boxShadow};
          border: 1px solid ${theme.main.borderColors};
          margin: 1em auto;
        }
        mark {
          padding: 0;
          transition: background-color ${theme.main.transitionTime} ease;
          color: ${styles.highlightMark.color};
          border-radius: ${styles.highlightMark.borderRadius};
          background-color: ${styles.highlightMark.backgroundColor};
        }
        p:empty {
          display: none;
        }
        br {
          display: none;
        }
        ol,
        ul {
          padding-inline-start: 0.5rem;
          margin-block-end: 1em;
          margin-block-start: 0.4em;
        }
        li {
          text-align: left;
          padding-left: 0.5em;
          margin-left: 1em;
        }
        a {
          color: ${theme.helpHubDocItem.linkColor};
          text-decoration: underline;
        }
        p {
          margin-block-end: 1em;
        }
        strong {
          font-weight: 600;
        }
        h1 {
          font-weight: ${theme.helpHubDocItem.contentHeadingOneFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingOneFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingOneLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingOneColor || theme.helpHubDocItem.titleColor};
          margin-block-end: 0.5em;
          text-align: left;
        }
        h2 {
          font-weight: ${theme.helpHubDocItem.contentHeadingTwoFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingTwoFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingTwoLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingTwoColor || theme.helpHubDocItem.titleColor};

          margin-block-start: 1em;
          text-align: left;
          margin: 0;
        }
        h3 {
          font-weight: ${theme.helpHubDocItem.contentHeadingThreeFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingThreeFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingThreeLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingThreeColor || theme.helpHubDocItem.titleColor};

          margin-block-start: 1.7em;
          text-align: left;
        }
        h4 {
          font-weight: ${theme.helpHubDocItem.contentHeadingFourFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingFourFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingFourLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingFourColor || theme.helpHubDocItem.titleColor};

          text-transform: uppercase;
          margin-block-start: 1.7em;
          text-align: left;
        }
        iframe {
          position: relative !important;
          max-width: 100%;
          width: 100% !important;
          height: auto;
          aspect-ratio: 16 / 9;
        }
        video {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
        table {
          border-collapse: collapse;
        }
        thead {
          p {
            margin-block-start: 0.2em;
            margin-block-end: 0.2em;
          }
        }
        td {
          border: 1px solid ${theme.main.borderColors};
          padding: 0px 5px;
        }
        tbody {
          vertical-align: top;
          p {
            margin-block-start: 0.1em;
            margin-block-end: 0.1em;
          }
        }
        div {
          margin-block-end: 0.8em;
        }

        pre {
          white-space: pre-line;
        }

        button {
          all: unset;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          white-space: nowrap;
          vertical-align: middle;
          outline: transparent solid 2px;
          outline-offset: 2px;
          line-height: 1.2;
          border-radius: 0.375em;
          font-weight: 500;
          transition-property: background-color, color, transform;
          transition-duration: ${theme.main.transitionTime};
          height: 2em;
          min-width: 2em;
          font-size: 0.875em;
          padding-inline-start: 0.75em;
          padding-inline-end: 0.75em;
          background-color: ${theme.helpHubDocItem.contentButtonBackgroundColor};
          color: ${theme.helpHubDocItem.contentButtonColor};
          &:hover {
            background-color: ${chroma.valid(theme.helpHubDocItem.contentButtonBackgroundColor)
              ? chroma(theme.helpHubDocItem.contentButtonBackgroundColor).darken(0.25).css()
              : theme.helpHubDocItem.contentButtonBackgroundColor};
          }
        }
      `}
    >
      {children}
    </div>
  );
};

export default Doc;
