import chroma from 'chroma-js';
import { InterpolationWithTheme } from '@emotion/core';
import { placeholderStyles } from './placeholderHelpers';
import { isEditorBeingSummoned } from '../../../client_api/utils';
import { ITheme } from '@commandbar/internal/client/theme';

export const inputStyles = (theme: ITheme, inputText: string, menuIsOpen: boolean): InterpolationWithTheme<any> => {
  const isInactive = !menuIsOpen;

  const sharedStyles: InterpolationWithTheme<any> = {
    overflowX: 'hidden',
    caretColor: theme.input.caretColor,
    fontFamily: theme.input.fontFamily,
    fontSize: theme.input.fontSize,
    width: '100%',
    margin: 0,
    // override for clickup global styles
    transition: 'none !important',
    boxShadow: 'none !important',
    background: 'none  !important',
    isolation: 'isolate',
    '*': {
      font: 'inherit',
    },
    '::placeholder': placeholderStyles(theme),
  };

  const defaultReactSelectStyles: InterpolationWithTheme<any> = {
    gridArea: '1 / 2',
    font: 'inherit',
    minWidth: '2px',
    border: 0,
    margin: 0,
    outline: 0,
    padding: 0,
  };

  if (isEditorBeingSummoned(inputText)) {
    const highlightColorOptions = ['darkgreen', 'plum', 'indigo', 'pink'];

    // Web Standards recommend at least a 4:5:1 contrast ratio between text and background
    // https://www.w3.org/TR/WCAG20-TECHS/G18.html
    const contrasts = highlightColorOptions.filter((color: string) => {
      return chroma.contrast(theme.main.background, color) > 4.5;
    });

    const color = contrasts.length > 0 ? contrasts[0] : 'blue';

    return {
      ...defaultReactSelectStyles,
      ...sharedStyles,
      color,
      fontWeight: 600,
    };
  }

  return {
    ...defaultReactSelectStyles,
    ...sharedStyles,
    color: isInactive ? theme.input.colorInactive : theme.input.color,
  };
};
