/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable commandbar/no-event-target */
import React, { MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useDrag } from '@use-gesture/react';
import { RemoveScroll } from 'react-remove-scroll';

import Z from '@commandbar/internal/client/Z';
import useWindowSize from '@commandbar/internal/util/useWindowSize';

import { useAction } from '../hooks/useAction';
import * as App from '../store/app/actions';
import { useStore } from '../hooks/useStore';
import { useTapToLaunchCommandBar } from '../hooks/useTapToLaunchCommandBar';
import { ITheme } from '@commandbar/internal/client/theme';

declare global {
  interface Window {
    CommandBarToggleStateChannel: {
      postMessage: (str: string) => void;
    };
    _cbIsWebView?: boolean;
  }
}

interface Props {
  children: ReactNode;
}

window._cbIsWebView = window._cbIsWebView || false;

const getMenuScrollTop = () => document.getElementById('commandbar_menu_list')?.scrollTop ?? 0;

export const MobileCommandBarContainer = ({ children }: Props) => {
  useTapToLaunchCommandBar();
  const { theme }: { theme: ITheme } = useTheme();
  const openBarWithOptionalText = useAction(App.openBarWithOptionalText);
  const closeBarAndReset = useAction(App.closeBarAndReset);
  const { engine } = useStore();

  const visible = window._cbIsWebView || engine.visible;
  const gestureStartTimeRef = useRef(0);
  const { width, height } = useWindowSize();
  const animationDistance = Math.max(width, height);
  const [newY, setNewY] = useState(0);
  const [scrollLockEnabled, setScrollLockEnabled] = useState(false);
  const [isBarClosable, setIsBarClosable] = useState(false);

  const open = () => {
    setNewY(animationDistance);
    setIsBarClosable(true);
  };

  const close = (_velocity = 0) => {
    setNewY(0);
    closeBarAndReset();
    setIsBarClosable(false);
  };

  const bindGesture = useDrag(
    (state) => {
      const {
        last,
        velocity: [, vy],
        direction: [, dy],
        movement: [, my],
        startTime,
        cancel,
        event,
      } = state;

      // if scrolling down
      if (dy === 1) {
        // Do not trigger "swipe down to close" gesture if menu is scrolled
        if (state.first && getMenuScrollTop() !== 0) {
          gestureStartTimeRef.current = startTime;

          return;
        } else if (startTime === gestureStartTimeRef.current) {
          event.preventDefault();

          return false;
        }
      }

      if (my < -70) {
        cancel();
      }

      if (last) {
        my > animationDistance * 0.65 || (vy > 0.5 && dy > 0) ? close(vy) : open();
      } else {
        setNewY(animationDistance - my);
      }
    },
    {
      enabled: !window._cbIsWebView,
      drag: {
        filterTaps: true,
        bounds: { top: 0 },
        rubberband: false,
        axis: 'y',
        pointer: {
          touch: true,
        },
      },
    },
  );

  const handleBackdropClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.target === event.currentTarget && isBarClosable) {
        close();
      }
    },
    [isBarClosable],
  );

  const disableWindowScroll = () => {
    setScrollLockEnabled(true);
  };

  const enableWindowScroll = () => {
    setScrollLockEnabled(false);
  };

  useEffect(() => {
    if (!engine.visible && window._cbIsWebView) {
      // Since we are forcing MobileCommandBarContainer to be visible regardless of the engine.visible value,
      // the CommandBar state should be re-initialized after command execution
      openBarWithOptionalText('programmatic');

      // Notify flutter app to close bottom panel
      if (window.CommandBarToggleStateChannel) {
        window.CommandBarToggleStateChannel.postMessage('close');
      }
    }
  }, [engine.visible]);

  useEffect(() => {
    if (visible) {
      open();
      disableWindowScroll();
    } else {
      close();
      enableWindowScroll();
    }

    return () => {
      enableWindowScroll();
    };
  }, [visible]);

  return (
    <div
      id={'commandbar-mobile-container'}
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: Z.Z_EDITOR,
        touchAction: 'none',
        backgroundColor: newY === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
        display: newY === 0 ? 'none' : 'block',
      }}
      onClick={handleBackdropClick}
    >
      <RemoveScroll enabled={scrollLockEnabled}>
        <div
          {...bindGesture()}
          style={{
            touchAction: 'none',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: Z.Z_EDITOR,
            backgroundColor: theme.main.background,
            borderRadius: theme.main.borderRadius,
            padding: '0px',
            boxShadow: theme.main.boxShadow,
            transform: `translateY(${newY ? animationDistance - newY : 0}px`,
            transition: `transform ${newY === 0 || newY === animationDistance ? 0.5 : 0}s linear`,
          }}
        >
          {children}
        </div>
      </RemoveScroll>
    </div>
  );
};
