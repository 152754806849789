/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { jsx } from '@emotion/core';
import { useAction } from '../hooks/useAction';
import * as App from '../store/app/actions';
import { SuggestCommandForm } from './select/footer/SuggestCommand';
import { getEditorDashboards } from './EditorDashboard';
import { useCommandBarTheme } from '../hooks/useCommandBarTheme';
import { DashboardType } from '../engine/step/DashboardStep';

interface IProps {
  type: DashboardType;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const Dashboard = (props: IProps) => {
  const theme = useCommandBarTheme();
  const handleEscape = useAction((_, e: KeyboardEvent) => {
    if (e.key === 'Escape') App.closeBarAndReset(_);
  });

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscape, true);
    return () => {
      document.removeEventListener('keydown', handleEscape, true);
    };
  }, [handleEscape]);

  const editorDashboards = getEditorDashboards(theme.main.background);

  switch (props.type) {
    case 'feedback':
      return (
        <Container>
          <SuggestCommandForm />
        </Container>
      );

    case 'editor-loading':
      return <Container>{editorDashboards.loading}</Container>;
    case 'editor-timeout':
      return <Container>{editorDashboards.timeout}</Container>;
    case 'editor-access-denied':
      return <Container>{editorDashboards.whoaThereBuddy}</Container>;
    default:
      return (
        <Container>
          <div />
        </Container>
      );
  }
};

const Container = (props: { children: React.ReactNode }) => {
  return (
    <div
      id="commandbar-dashboard"
      className="commandbar-content-container"
      css={(theme) => ({
        textAlign: 'center',
        padding: '30px 50px',
        backgroundColor: theme.theme.dashboard.background,
        borderRadius: theme.theme.main.borderRadius,
        fontFamily: theme.theme.main.fontFamily,
        color: theme.theme.dashboard.fontColor,
      })}
    >
      {props.children}
    </div>
  );
};

export default Dashboard;
