import { EngineState } from '../state';
import { Option } from '../../../engine/option';
import { isAvailable } from '../../../engine/option/OptionValidate';
import { isOptionValid, isOptionExecutable } from './selectors';
import { isResourceOption, isParameterOption, isCommandOption, isUnfurledCommandOption } from './helpers';
import * as EngineActions from '../actions';
import { assertNever } from '../../../util/assertNever';
import { chooseResourceOption, chooseParameterOption, updateSteps, chooseCommandOption } from '..';
import { getDefaultCommandForCurrentStep } from '../selectors';
import * as Engine from '../../engine/actions';
import { ExecutionEventSource } from '@commandbar/internal/client/EventHandler';
import React from 'react';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';

export * from './command-option-actions';
export * from './parameter-option-actions';
export * from './resource-option-actions';

/** WARNING: may have side effects, but is not supposed to! */
export const validateOptionAvailable = (
  _: EngineState,
  o: Option,
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  switch (o.type) {
    case 'parameter':
      if (!isResourceOption(o)) break;
      return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }

  return isAvailable(o, _.engine);
};

/** WARNING: may have side effects, but is not supposed to! */
export const validateOptionDisabled = (_: EngineState, o: Option) => {
  const disabled = (msg: string, reasonIsUserDefined = false) => {
    return { isDisabled: true, isDisabledReason: msg, reasonIsUserDefined };
  };

  const { isValid, isValidReason } = isOptionValid(_, o);
  if (!isValid) {
    o.optionDisabled = disabled(isValidReason);
    return o.optionDisabled;
  }

  const { isExecutable, isExecutableReason } = isOptionExecutable(_, o);
  if (!isExecutable) {
    o.optionDisabled = disabled(isExecutableReason);
    return o.optionDisabled;
  }

  const { isAvailable, isAvailableReason, reasonIsUserDefined } = validateOptionAvailable(_, o);
  if (!isAvailable) {
    o.optionDisabled = disabled(isAvailableReason, reasonIsUserDefined);
    return o.optionDisabled;
  }

  return { isDisabled: false, isDisabledReason: '', reasonIsUserDefined: false };
};

export const chooseOption = (
  _: EngineState,
  o: Option,
  _updateSteps?: EngineActions.StepUpdater,
  executionEventSource?: ExecutionEventSource,
  triggeredByShortcut?: boolean,
  event?: React.KeyboardEvent | React.MouseEvent,
): undefined | EngineState['engine'] => {
  const updater = _updateSteps ?? updateSteps.bind(null, _);
  if (isResourceOption(o)) {
    // auto selects the default option on a resource that can be power-selected.
    const resourceOption = chooseResourceOption(_, o, updater);
    const defaultCommand = getDefaultCommandForCurrentStep(_);
    // execute default command unless Shift is held in which case we implicitly enter power-select mode
    if (defaultCommand && !event?.shiftKey) {
      const updateSteps = Engine.updateSteps.bind(null, _);
      chooseOption(_, defaultCommand, updateSteps, executionEventSource, undefined, event);
    }
    return resourceOption;
  } else if (isCommandOption(o) || isUnfurledCommandOption(o)) {
    return chooseCommandOption(_, o, triggeredByShortcut, updater, executionEventSource, {
      openLinkInNewTab: !!event && getTriggerKey(event),
    });
  } else if (isParameterOption(o)) {
    return chooseParameterOption(_, o, updater);
  }
  return assertNever(o);
};
