/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import Z from '@commandbar/internal/client/Z';
import * as React from 'react';
import { useAction } from '../hooks/useAction';
import { useCommandBarContainer } from '../hooks/useCommandBarContainer';
import useTheme from '../hooks/useTheme';
import * as App from '../store/app/actions';

/*********************************************************************************/
interface IProps {
  children: React.ReactNode;
}

const InlineBar = (props: IProps) => {
  const { theme } = useTheme();

  const closeIfOpen = useAction((_) => {
    if (_.engine.visible) {
      App.toggleBarFromLauncher(_);
      setTimeout(() => {
        /** Hack to handle blurring the input in case there's a race condition. We have other .focus() listeners elsewhere
         */
        _.refContainer.current?.blur();
      }, 200);
    }
  });

  useOnClickOutside(closeIfOpen);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          fontFamily: theme.main.fontFamily,
          backgroundColor: theme.main.background,
          borderRadius: theme.main.borderRadius,
          width: 'inherit',
          zIndex: Z.Z_EDITOR,
          boxShadow: theme.main.boxShadow,
          position: 'absolute',
          top: 0,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

// https://stackoverflow.com/a/42234988
const useOnClickOutside = (onClickOutside: () => void) => {
  const { container } = useCommandBarContainer();
  React.useEffect(() => {
    function handleClick(event: MouseEvent) {
      if ((event as any).__commandbar_clicked_on_container) return;

      // if the click was witin other CommandBar elements (e.g. launcher, popover preview) outside the shadow DOM, ignore it also
      // eslint-disable-next-line commandbar/no-event-target
      if (document.getElementById('commandbar')?.contains(event.target as Node)) return;

      onClickOutside();
    }

    /** We could close the bar on every window blur, but there may be scenarios where a user wants to switch tabs / windows with the bar
     * open (e.g., a video)
     */

    function closeOnBlurIfClickedOnIframe() {
      setTimeout(() => {
        const activeIframe = document.activeElement;
        if (activeIframe?.tagName === 'IFRAME') {
          /** Note, we have to use commandbar-home here instead of commandbar because the inline bar is portaled in and no longer is a direct child of 'commandbar' */
          const commandBarContainer = document.getElementById('commandbar-home');
          if (commandBarContainer && !commandBarContainer.contains(activeIframe)) {
            onClickOutside();
          }
        }
      });
    }

    const handleClickInside = (event: MouseEvent) => {
      // HACK: Add a boolean which we can check --
      // A click inside the commandBarContainer is NOT a click outside, and all other clicks ARE outside
      (event as any).__commandbar_clicked_on_container = true;
    };

    container?.addEventListener('mousedown', handleClickInside);
    document.addEventListener('mousedown', handleClick);
    window.addEventListener('blur', closeOnBlurIfClickedOnIframe);
    return () => {
      container?.removeEventListener('mousedown', handleClickInside);
      document.removeEventListener('mousedown', handleClick);
      window.removeEventListener('blur', closeOnBlurIfClickedOnIframe);
    };
  }, [container, onClickOutside]);
};

export default InlineBar;
