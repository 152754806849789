import * as Reporting from '../../../analytics/Reporting';
import { executeCommand } from '../actions';
import * as OptionValidate from '../../../engine/option/OptionValidate';
import { commandToHelpHubDoc, findMostSpecificRecommendationSet, removeDeepLinkParams } from './helpers';
import { linkExecutable, clickExecutable, openChatExecutable } from '../steps/executables';
import { getCommandById } from '../selectors';
import { interpolate } from '../../../engine/Interpolate';

import type { EngineState, HelpHubDoc } from '../state';

import type {
  Action,
  ICommandType,
  IInstantAnswerType,
  IRecommendationSet,
} from '@commandbar/internal/middleware/types';

import Logger from '@commandbar/internal/util/Logger';
import { isChatOnlyMode } from '../../../store/engine/help-hub/selectors';
import { isHelpHubMarketingSite, isStandaloneEditor } from '@commandbar/internal/util/location';

import helpdocService from '../../../services/helpdocService';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { Chat } from '@commandbar/internal/middleware/chat';

export const setHelpHubQuery = (_: EngineState, query: string) => {
  _.engine.helpHub.query = query;
};

export const setContinuations = (_: EngineState, answer: string, continuations: string[]) => {
  _.engine.helpHub.continuations[answer] = continuations;
};

export const setFeedbackGiven = async (_: EngineState, liveAnswer: IInstantAnswerType, rating: number) => {
  const { message_id, answer } = liveAnswer;

  if (!!message_id && !!_.engine.organization) {
    _.engine.helpHub.gaveFeedback[message_id.toString()] = rating;

    const payload = {
      message_id,
      rating,
    };
    Reporting.helpHubChatFeedback({ message_id, rating, message: answer });
    try {
      await Chat.createAnswerFeedback(_.engine.organization.id, payload);
    } catch (err: any) {
      Logger.warn(`AI feedback error: ${err}`);
    }
  }
};

export const setHelpHubDoc = (_: EngineState, doc: HelpHubDoc | null, scrollPosition?: number) => {
  _.engine.helpHub.hubDoc = doc;

  if (scrollPosition) {
    _.engine.helpHub.scrollPosition = scrollPosition;
  }
};

export const toggleHelpHubVisible = (_: EngineState) => {
  if (_.engine.helpHub.visible) {
    if (isStandaloneEditor || isHelpHubMarketingSite) return;
    Reporting.helpHubClosed({ query: _.engine.helpHub.query });
  } else {
    Reporting.helpHubOpened({ query: _.engine.helpHub.query });
  }
  _.engine.helpHub.visible = !_.engine.helpHub.visible;
  setHelpHubQuery(_, '');

  if (!_.engine.helpHub.visible) {
    removeDeepLinkParams();
  }
};

export const setHelpHubVisible = (
  _: EngineState,
  visible: boolean,
  config?: { article?: HelpHubDoc | null; query?: string; scrollPosition?: number },
) => {
  const wasVisible = _.engine.helpHub.visible;
  _.engine.helpHub.visible = visible;

  if (!wasVisible && visible) Reporting.helpHubOpened({ query: _.engine.helpHub.query });
  else if (wasVisible && !visible) Reporting.helpHubClosed({ query: _.engine.helpHub.query });

  if (isChatOnlyMode(_.engine)) {
    Reporting.helpHubEngagement({ query: _.engine.helpHub.query }, 'chat_started');
  }

  setHelpHubQuery(_, config?.query || '');
  if (config?.article) {
    setHelpHubDoc(_, config?.article, config?.scrollPosition);
    Reporting.helpHubDocOpened({
      query: _.engine.helpHub.query,
      command: config?.article?.commandID,
    });
  } else if (config?.article === null) {
    setHelpHubDoc(_, null, config?.scrollPosition);
  }
};

export const setHelpHubRecommendationSets = (_: EngineState, recommendationSets: IRecommendationSet[]) => {
  _.engine.helpHub.recommendationSets = recommendationSets;
};

export const setPreviewRecommendationSet = (_: EngineState, recommendationSetId: number | null) => {
  _.engine.helpHub.previewedRecommendationSetId = recommendationSetId;
};

export const getHelpHubRecommendations = (_: EngineState): HelpHubDoc[] => {
  const getHelpDocsFromRecSet = (recommendationSet: IRecommendationSet): HelpHubDoc[] =>
    recommendationSet.commands
      .filter((command) => command.is_live && OptionValidate.isCommandAvailable(command, _.engine).isAvailable)
      .map((command) => commandToHelpHubDoc(command));

  if (_.engine.helpHub.previewedRecommendationSetId) {
    const recommendationSet = _.engine.helpHub.recommendationSets?.find(
      (set) => set.id === _.engine.helpHub.previewedRecommendationSetId,
    );

    if (recommendationSet) {
      return getHelpDocsFromRecSet(recommendationSet);
    }
  }

  const passesAudienceConditions = ({ audience }: IRecommendationSet) => {
    switch (audience.type) {
      case 'all_users':
        return true;
      case 'rule_expression':
        return OptionValidate.runBooleanExpression(audience.expression, _.engine, '').passed;
      default:
        return false;
    }
  };

  const passesPageConditions = ({ show_expression }: IRecommendationSet) =>
    !show_expression || OptionValidate.runBooleanExpression(show_expression, _.engine, '').passed;

  const validRecommendationSets = _.engine.helpHub.recommendationSets?.filter(
    (set) => passesAudienceConditions(set) && passesPageConditions(set),
  );

  if (validRecommendationSets?.length) {
    const mostSpecificRecommendationSet = findMostSpecificRecommendationSet(validRecommendationSets);
    if (mostSpecificRecommendationSet) {
      return getHelpDocsFromRecSet(mostSpecificRecommendationSet);
    }
  }

  return [];
};

export const resetLiveAnswer = (_: EngineState) => {
  _.engine.helpHub.liveAnswer = null;
  _.engine.helpHub.loadingLiveAnswer = false;
};

export const executeResourceCommand = (
  _: EngineState,
  command: ICommandType,
  e?: React.MouseEvent<HTMLElement, MouseEvent>,
) => {
  executeCommand(_, command, undefined, undefined, { type: 'helphub_additional_resource' }, e);
};

export const executeAction = (_: EngineState, action: Action, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
  // Note that this executes all commands, even commands with arguments. This could
  // potentially get us into trouble if an org has the bar disabled + uses a command
  // with arguments in their additional resources, but it's also still useful to have
  // this functionality and the risk should be very low.
  switch (action?.type) {
    case 'click':
      clickExecutable(_.engine, {
        type: action.type,
        value: [action.value],
      })();
      break;
    case 'execute_command':
      const command = getCommandById(_.engine, action.meta.command);
      if (command) {
        executeResourceCommand(_, command, e);
      } else if (_.engine.organization) {
        helpdocService.getHelpdocCommand(_.engine.organization.id, action.meta.command).then((command) => {
          if (command) {
            executeResourceCommand(_, command, e);
          }
        });
      }
      break;
    case 'link':
      if (isStandaloneEditor) {
        const _url = interpolate(action.value, _.engine, true, true, true);
        // the standalone editor has a custom router that will display
        // a message indicating where the link would go in lieu of actually
        // navigating to it
        _.engine.callbacks['commandbar-router'](_url);
        return;
      } else {
        linkExecutable(_.engine, action, !!e && getTriggerKey(e))();
      }
      break;
    case 'open_chat':
      if (isStandaloneEditor) {
        const upperCase = `${action?.meta?.type.charAt(0).toUpperCase()}${action?.meta?.type.slice(1)}`;
        _.engine.callbacks['__standalone-editor-cb_hh_cta'](upperCase);
      } else {
        openChatExecutable(action);
        setHelpHubVisible(_, false, { article: null });
      }
      break;
  }
};
