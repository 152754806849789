import { isSelectStep } from '../ExecutionPath';
import { Option } from '../option';
import { MultiSelectStep, SelectStep, Step } from '../step';
import { EngineState } from '../../store/engine/state';
import { initParameterOption } from '../option/ParameterOption';
import { isParameterOption } from '../../store/engine';

const CREATED_FIELD_NAME = '_createdByCommandBar';

// Is the create feature turned on?
const isEnabled = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return isSelectStep(currentStep) && !!currentStep.argument.allow_create;
};

// Is a given record created by CommandBar?
const recordIsCreated = (record: any) => {
  return !!record[CREATED_FIELD_NAME];
};

// helper to create an option
const _createOption = (engine: EngineState['engine'], currentStep: SelectStep | MultiSelectStep, newInput: string) => {
  return initParameterOption(
    { engine },
    `Create new ${currentStep.argument?.allow_create_label ?? currentStep.argument.userDefinedName}`,
    {
      [CREATED_FIELD_NAME]: true,
      value: newInput,
    },
  );
};

// add a created option a given list
const addCreatedOptionToList = ({
  options,
  engine,
  currentStep,
  inputValue,
}: {
  options: Option[];
  engine: EngineState['engine'];
  currentStep: SelectStep | MultiSelectStep;
  inputValue: string;
}) => {
  if (!isEnabled(currentStep)) {
    return options;
  }
  if (options.length > 0 && !isParameterOption(options[0])) {
    return options;
  }

  if (!inputValue.length) {
    return options;
  }

  // If an exact match of input (case sensitive) exists, don't show create option
  const hasMatch = options.find((o) => o.label === inputValue);
  if (hasMatch) {
    return options;
  } else {
    return [...options, _createOption(engine, currentStep, inputValue)];
  }
};

export const SelectOrCreate = {
  isEnabled,
  recordIsCreated,
  addCreatedOptionToList,
};
