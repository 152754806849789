import React from 'react';
import { ReactComponent as PoweredBySvg } from '../../img/powered.svg';
import type { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { translate } from '../../util/languages';
import { navigateToBrandingSite } from '../../util/branding';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

export const PoweredBy = (props: { organization: IOrganizationType }) => {
  const { theme }: { theme: ITheme } = useTheme();

  return (
    <div
      style={{ justifyContent: 'center', display: 'flex', padding: '8px', borderTop: theme.nudgeModal.poweredByBorder }}
    >
      <button
        tabIndex={0}
        role="link"
        aria-label="powered-by-commandbar"
        style={{
          background: 'none',
          color: 'inherit',
          border: 'none',
          padding: 0,
          font: 'inherit',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => navigateToBrandingSite(props.organization)}
      >
        <PoweredBySvg role="img" focusable={false} style={{ marginRight: '5px', opacity: 0.5 }} />
        <span style={{ fontSize: '14px', color: theme.main.color, opacity: 0.5 }}>
          {translate('Powered by CommandBar')}
        </span>
      </button>
    </div>
  );
};
