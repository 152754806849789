import { getSDK } from '@commandbar/internal/client/globals';
import { _loadEditor } from '@commandbar/internal/client/symbols';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import React from 'react';

const LOCAL_STORAGE_FLAG = 'editor';
const QUERY_PARAM_FLAG = 'cb_editor';

export const useLoadEditor = () => {
  React.useEffect(() => {
    let showEditor = LocalStorage.get(LOCAL_STORAGE_FLAG, '');

    try {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has(QUERY_PARAM_FLAG)) {
        showEditor = true;

        searchParams.delete('cb_editor');
        const newPath = !!searchParams.toString()
          ? `${window.location.pathname}?${searchParams.toString()}`
          : window.location.pathname;

        // @ts-expect-error: https://stackoverflow.com/a/41061471
        window.history.replaceState(null, null, newPath);
      }
    } catch {}

    if (!!showEditor) {
      getSDK()[_loadEditor]();
    }
  }, []);
};
