import React from 'react';
import { ParameterOption } from '../../engine/option';
import ExecutionPath from '../../engine/ExecutionPath';
import Icon from '@commandbar/internal/client/Icon';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { useStore } from '../../hooks/useStore';
import MultiSelectDefaultSelectedIcon from '../step/MultiSelectDefaultSelectedIcon';
import MultiSelectDefaultIcon from '../step/MultiSelectDefaultIcon';
import MultiSelectSelectedIcon from '../step/MultiSelectSelectedIcon';
import SelectDefaultIcon from '../step/SelectDefaultIcon';
import { StepType } from '../../engine/step/Step';
import { getOptionIconColorBase, getReservedField, isParameterOptionSelected } from '../../store/engine';
import { getIconSize } from './helpers';

export interface Props {
  isFocused: boolean;
  option: ParameterOption;
  isMultiSelectSelection: boolean;
}

export const ParameterOptionIcon = ({ isFocused, option, isMultiSelectSelection }: Props) => {
  const _ = useStore();
  const { engine } = _;
  const theme = useCommandBarTheme();
  const { defaultColorFromTheme, opacity, useDefaultSVGColor } = getOptionIconColorBase(theme, isFocused, false);
  const preserveSVG = !!getReservedField(option, '__preserveSVG');
  const userDefinedIcon = getReservedField(option, 'icon');
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  if (currentStep?.type === StepType.TextInput) {
    return <div />;
  }

  if (currentStep?.type === StepType.MultiSelect) {
    if (isMultiSelectSelection) {
      return (
        <MultiSelectSelectedIcon
          userDefinedIcon={userDefinedIcon}
          useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
        />
      );
    } else if (!!userDefinedIcon) {
      return (
        <Icon
          icon={userDefinedIcon}
          default={<div style={{ minWidth: getIconSize(_, theme), display: 'inline-block' }} />}
          style={{ color: defaultColorFromTheme, minWidth: getIconSize(_, theme) }}
          useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
          size={getIconSize(_, theme)}
        />
      );
    } else {
      return <MultiSelectDefaultIcon color={defaultColorFromTheme} opacity={opacity} size={getIconSize(_, theme)} />;
    }
  }

  if (currentStep?.type === StepType.Select) {
    const thisOptionIsSelected = isParameterOptionSelected(option, currentStep);
    const customIcon = userDefinedIcon || option.searchOptions?.icon;
    const useCalculatedOpacity = !userDefinedIcon; // Don't overwrite opacity of user defined icon
    if (thisOptionIsSelected) {
      return <MultiSelectDefaultSelectedIcon color={theme.optionSelected.iconColor} size={getIconSize(_, theme)} />;
    }
    if (!!customIcon) {
      const styles = {
        defaultColorFromTheme,
        minWidth: getIconSize(_, theme),
        opacity: useCalculatedOpacity ? opacity : undefined,
      };
      return (
        <Icon
          icon={customIcon}
          default={<div style={{ minWidth: getIconSize(_, theme), display: 'inline-block' }} />}
          style={styles}
          useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
          size={getIconSize(_, theme)}
        />
      );
    } else {
      return <SelectDefaultIcon color={defaultColorFromTheme} opacity={opacity} size={getIconSize(_, theme)} />;
    }
  }

  return <div style={{ minWidth: getIconSize(_, theme) }} />;
};
