/** @jsx jsx  */
import React, { CSSProperties, ReactNode } from 'react';
import useKeypressRecorder from './useKeypressRecorder';
import HotkeyTag, { HotkeyTagProps } from './HotkeyTag';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import { selectIsHotkeyEditable } from '../../store/engine/selectors';
import { useStore } from '../../hooks/useStore';
import { AiOutlineStop, AiOutlineUndo } from 'react-icons/ai';
import Tooltip from '../Tooltip';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { css, jsx, keyframes } from '@emotion/core';
import Hotkey from '@commandbar/internal/client/Hotkey';
import * as Command from '@commandbar/internal/middleware/command';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import { getCommands } from '../../store/engine';
import { optionIsInGrid } from '../../store/app';
import type { State } from '../../store';

interface EditableHotkeyTagProps extends HotkeyTagProps {
  slug: string; // Eventually, this could be a reference to any of { command, record, unfurled command, ... }
  defaultCombo: string;
  isFocused: boolean;
  hasNextIcon: boolean;
}

const EditableHotkeyTag = (props: EditableHotkeyTagProps) => {
  const { slug, ...hotkeyTagProps } = props;
  const { theme }: { theme: ITheme } = useTheme();

  const _ = useStore();
  const { engine, refContainer } = _;
  const isHotkeyEditable = selectIsHotkeyEditable(useStore());
  const updateEndUserHotkey = useAction(Engine.updateEndUserHotkey);

  const editing = _.engine.editingEndUserShortcutSlug === slug;
  const setEditing = useAction(
    (_: State, val: boolean) => {
      if (val) {
        _.engine.editingEndUserShortcutSlug = slug;
      } else {
        if (_.engine.editingEndUserShortcutSlug === slug) {
          _.engine.editingEndUserShortcutSlug = null;
        }
      }
    },
    [slug],
  );

  // reset editing to "false" on unmount
  React.useEffect(() => {
    return () => {
      setEditing(false);
    };
  }, [setEditing]);

  const [error, setError] = React.useState<ReactNode | undefined>(undefined);

  const [hovered, setHovered] = React.useState(false);

  const [combo, setCombo] = React.useState(hotkeyTagProps.combo);
  const hasHotkey = combo.length > 0;

  const resetInputFocus = () => {
    if (!!refContainer.current) {
      refContainer.current.focus();
    }
  };

  const onEdit = (mousetrapString: string | undefined) => {
    resetInputFocus();
    if (mousetrapString === undefined) {
      setEditing(false);
      setHovered(false);
      setError(<span>Invalid input - no changes made</span>);
      setTimeout(() => setError(undefined), 3000);
    } else {
      const platformString = mousetrapString;
      mousetrapString = Hotkey.toModString(mousetrapString, ['mac'].includes(engine.platform) ? 'mac' : 'win');

      const collisions = getCommands(_.engine).filter((command) => {
        const cmdUID = Command.commandUID(command);

        if (cmdUID === props.slug || !mousetrapString) {
          return false;
        }

        let commandHotkey = ['mac'].includes(engine.platform) ? command.hotkey_mac : command.hotkey_win;

        if (engine.endUserStore.data.hotkeys.hasOwnProperty(cmdUID)) {
          commandHotkey = engine.endUserStore.data.hotkeys[cmdUID];
        }

        return Hotkey.checkForConflicts(mousetrapString, commandHotkey);
      });

      if (collisions.length > 0) {
        setEditing(false);
        setHovered(false);
        setError(
          <span>
            <i>{platformString}</i> conflicts with <i>{collisions.map((c) => c.text).join(', ')}</i>
          </span>,
        );
        setTimeout(() => setError(undefined), 3000);
        return;
      }

      setCombo(mousetrapString);
      setEditing(false);
      setHovered(false);

      updateEndUserHotkey(props.slug, mousetrapString);
    }
  };

  const onReset = () => {
    resetInputFocus();
    setCombo(props.defaultCombo);
    setEditing(false);
    setHovered(false);
    updateEndUserHotkey(props.slug, undefined);
  };

  const onRemove = () => {
    onEdit('');
  };

  React.useEffect(() => {
    setHovered(props.isFocused && hasHotkey);
  }, [props.isFocused]);

  React.useEffect(() => {
    setCombo(props.combo);
  }, [props.combo]);

  if (!isHotkeyEditable) return <HotkeyTag {...hotkeyTagProps} />;

  if (!editing) {
    if (!!error) {
      return (
        <div
          style={{
            color: theme.option.descriptionColor,
            fontSize: '11px',
            whiteSpace: 'nowrap',
          }}
        >
          {error}
        </div>
      );
    }

    // hovering over the right side of a row should reveal a hotkey setting component
    const hotKeyTagWrapperPosition: undefined | { position: CSSProperties['position']; right: CSSProperties['right'] } =
      optionIsInGrid(_) || hasHotkey || hovered
        ? undefined
        : /* when hovered, the box will be visible and take up space inline as usual */
          {
            // the box must be hoverable at all times
            // the box must be hidden when the row is not hovered
            position: 'absolute',
            // the box should shift further to the left if there is an icon to the right
            right: props.hasNextIcon ? '43px' : '18px',
          };

    return (
      <div
        aria-hidden={true}
        style={{
          ...hotKeyTagWrapperPosition,
          background: 'transparent',
          borderRadius: '4px',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          minWidth: hasHotkey ? 'auto' : '100px',
          minHeight: hasHotkey ? 'auto' : '30px',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditing(true);
        }}
      >
        {
          <div
            style={{
              display: 'inline-block',

              margin: '6px 12px',
              fontSize: theme.keyboardShortcuts.fontSize,
              ...(!hovered && { visibility: 'hidden' }),
            }}
          >
            {hasHotkey ? 'Edit' : 'Add shortcut'}
          </div>
        }
        <HotkeyTag
          combo={combo}
          highlight={props.highlight}
          isUserCustomized={combo !== props.defaultCombo && combo !== ''}
        />
      </div>
    );
  }

  return <KeypressRecorder onFinish={onEdit} onReset={onReset} onRemove={onRemove} />;
};

interface KeypressRecorderProps {
  onFinish: (val: string | undefined) => void;
  onRemove: () => void;
  onReset: () => void;
}

const KeypressRecorder = (props: KeypressRecorderProps) => {
  const combo = useKeypressRecorder({ onFinish: props.onFinish });
  const { theme }: { theme: ITheme } = useTheme();

  const pulsate = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${getChromaColor(theme.keyboardShortcuts.recordingColor).alpha(0.7).css()};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 4px ${getChromaColor(theme.keyboardShortcuts.recordingColor).alpha(0).css()};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${getChromaColor(theme.keyboardShortcuts.recordingColor).alpha(0).css()};
    }
`;

  return (
    <div
      aria-hidden={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.keyboardShortcuts.background,
        fontSize: '11px',
        padding: '3px 6px',
        minHeight: '30px',
        cursor: 'text',
      }}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {combo.length === 0 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Press any key</div>
          <Tooltip overlay={<div style={{ fontFamily: theme.main.fontFamily }}>Remove shortcut</div>} placement="top">
            <AiOutlineStop
              css={{
                '&:hover': {
                  color: theme.base.primary,
                  cursor: 'pointer',
                  transform: 'scale(1.2)',
                },
                transition: 'all .3s ease-in-out',
                fontWeight: 700,
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '6px',
              }}
              onClick={props.onRemove}
            />
          </Tooltip>
          <Tooltip overlay={<div style={{ fontFamily: theme.main.fontFamily }}>Reset shortcut</div>} placement="top">
            <AiOutlineUndo
              css={{
                '&:hover': {
                  color: theme.base.primary,
                  cursor: 'pointer',
                  transform: 'scale(1.2)',
                },
                transition: 'all .3s ease-in-out',
                fontWeight: 700,
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              onClick={props.onReset}
            />
          </Tooltip>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HotkeyTag highlight={false} combo={combo} />
          <div
            css={css`
              background: ${theme.keyboardShortcuts.recordingColor};
              box-shadow: 0 0 0 0 ${theme.keyboardShortcuts.recordingColor};
              animation: ${pulsate} 2s infinite;
              border-radius: 50%;
              margin-left: 6px;
              height: 8px;
              width: 8px;
              transform: scale(1);
            `}
          />
        </div>
      )}
    </div>
  );
};

export default EditableHotkeyTag;
