import React from 'react';
import { CommandOption } from '../../engine/option';
import { useStore } from '../../hooks/useStore';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import Tag from '../Tag';
import chroma from 'chroma-js';
import SVG from '@commandbar/internal/util/SVG';
import { AiOutlineArrowRight } from 'react-icons/ai';
import OptionWrapper from './OptionWrapper';
import { CommandOptionIcon } from './CommandOptionIcon';
import { OptionHighlight } from './OptionHighlight';
import { CommandOptionShortcut } from './CommandOptionShortcut';
import { CommandOptionLabel } from './CommandOptionLabel';
import {
  getOptionData,
  getOptionMetadata,
  isHotloadedCommandOption,
  isOptionValid,
  isUnfurledCommandOption,
} from '../../store/engine/options';
import { UnfurledCommandOptionLabel } from './UnfurledCommandOptionLabel';
import RenderCustomComponent from '../RenderCustomComponent';

export interface Props {
  isError: boolean;
  isFocused: boolean;
  option: CommandOption;
}

export const CommandOptionRow = ({ isError, isFocused, option }: Props) => {
  const _ = useStore();
  const { engine } = _;
  const theme = useCommandBarTheme();

  let { isDisabled, isDisabledReason } = option.optionDisabled;
  const { reasonIsUserDefined } = option.optionDisabled;

  // This is only important for proactively catching click errors since the underlying app state may have shifted
  if (!isDisabled) {
    const { isValid, isValidReason } = isOptionValid(_, option);
    if (!isValid) {
      isDisabled = !isValid;
      isDisabledReason = isValidReason;
    }
  }

  const icon = <CommandOptionIcon option={option} isFocused={isFocused} isDisabled={isDisabled} />;

  const draft = !option.command.is_live && engine.isAdmin && (
    <span>
      <Tag color={chroma('lightgrey')}>Draft</Tag>{' '}
    </span>
  );

  const explanation = (() => {
    if (isHotloadedCommandOption(option, _.engine.organization) && option.command.show_preview) {
      return null;
    }

    if (isDisabled && isDisabledReason) {
      if (reasonIsUserDefined) {
        return <span>{isDisabledReason}</span>;
      }

      if (engine.isAdmin) {
        return <span style={{ color: 'orange' }}>{isDisabledReason}</span>;
      }
    }

    if (option.command.explanation) {
      return (
        <span>
          <OptionHighlight
            labelToHighlight="command.explanation"
            text={option.command.explanation}
            option={option}
            emphasize
          />
        </span>
      );
    }
    return null;
  })();

  const shouldHideIcon =
    Object.keys(option.command.arguments).length === 0 ||
    /**
     * Technically, video commands internally use arguments. In future, we may support video commands
     * with multiple steps. Though a use-case for this is rare, we should probably still show a
     * "go forward" icon.
     */
    (option.command.template.type === 'video' && Object.keys(option.command.arguments).length === 1);

  const shortcut = <CommandOptionShortcut option={option} isFocused={isFocused} hasNextIcon={!shouldHideIcon} />;

  const goForward = !shouldHideIcon && (
    <span
      role="img"
      aria-hidden={true}
      style={{
        marginLeft: 10,
        marginTop: 6,
        color: isFocused ? theme.optionSelected.iconColor : theme.option.iconColor,
      }}
    >
      {!!engine?.organization?.icon_go_forward ? (
        <SVG
          htmlstring={engine.organization.icon_go_forward}
          height="20px"
          width="20px"
          style={{ color: isFocused ? theme.optionSelected.iconColor : theme.option.iconColor }}
        />
      ) : (
        <AiOutlineArrowRight />
      )}
    </span>
  );

  const label = isUnfurledCommandOption(option) ? (
    <UnfurledCommandOptionLabel option={option} />
  ) : (
    <CommandOptionLabel option={option} />
  );

  const extra = (() => {
    if (option.command.extra) {
      const component = engine.components[option.command.extra];
      if (!component) {
        return null;
      }

      return (
        <RenderCustomComponent
          key={option.command.id}
          data={getOptionData(option)}
          metadata={getOptionMetadata(option)}
          component={component.component}
        />
      );
    }
  })();

  return (
    <OptionWrapper
      option={option}
      label={label}
      type="command"
      icon={icon}
      draft={draft}
      description={explanation}
      heading={
        <OptionHighlight option={option} labelToHighlight="command.heading" text={option.command.heading} emphasize />
      }
      shortcut={shortcut}
      goForward={goForward}
      isFocused={isFocused}
      isError={isError}
      isDisabled={isDisabled}
      extra={extra}
    />
  );
};
