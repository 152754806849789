/*
 * rc-tooltip styles
 * TODO set up a less loader and use the built in rc-tooltip.less files instead
 * of manually overwriting the css file with specific selectors
 */

const style = `
  .commandbar-tooltip.commandbar-tooltip-zoom-enter,
  .commandbar-tooltip.commandbar-tooltip-zoom-leave {
    display: block;
  }
  .commandbar-tooltip-zoom-enter,
  .commandbar-tooltip-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-play-state: paused;
  }
  .commandbar-tooltip-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
    animation-play-state: paused;
  }
  .commandbar-tooltip-zoom-enter.commandbar-tooltip-zoom-enter-active,
  .commandbar-tooltip-zoom-appear.commandbar-tooltip-zoom-appear-active {
    animation-name: rcToolTipZoomIn;
    animation-play-state: running;
  }
  .commandbar-tooltip-zoom-leave.commandbar-tooltip-zoom-leave-active {
    animation-name: rcToolTipZoomOut;
    animation-play-state: running;
  }
  @keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
  }
  .commandbar-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    opacity: 0.9;
  }
  .commandbar-tooltip-hidden {
    display: none;
  }
  .commandbar-tooltip-placement-top,
  .commandbar-tooltip-placement-topLeft,
  .commandbar-tooltip-placement-topRight {
    padding: 5px 0 9px 0;
  }
  .commandbar-tooltip-placement-right,
  .commandbar-tooltip-placement-rightTop,
  .commandbar-tooltip-placement-rightBottom {
    padding: 0 5px 0 9px;
  }
  .commandbar-tooltip-placement-bottom,
  .commandbar-tooltip-placement-bottomLeft,
  .commandbar-tooltip-placement-bottomRight {
    padding: 9px 0 5px 0;
  }
  .commandbar-tooltip-placement-left,
  .commandbar-tooltip-placement-leftTop,
  .commandbar-tooltip-placement-leftBottom {
    padding: 0 9px 0 5px;
  }
  .commandbar-tooltip-inner {
    padding: 8px 10px;
    color: #d5d6d7 !important;
    text-align: left;
    text-decoration: none;
    background-color: #373737;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  }

  .commandbar-tooltip-inner > span {
    color: inherit;
  }

  .commandbar-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .commandbar-tooltip-placement-top .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-topLeft .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-topRight .commandbar-tooltip-arrow {
    bottom: 4px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #373737;
  }
  .commandbar-tooltip-placement-top .commandbar-tooltip-arrow {
    left: 50%;
  }
  .commandbar-tooltip-placement-topLeft .commandbar-tooltip-arrow {
    left: 15%;
  }
  .commandbar-tooltip-placement-topRight .commandbar-tooltip-arrow {
    right: 15%;
  }
  .commandbar-tooltip-placement-right .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-rightTop .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-rightBottom .commandbar-tooltip-arrow {
    left: 4px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #373737;
  }
  .commandbar-tooltip-placement-right .commandbar-tooltip-arrow {
    top: 50%;
  }
  .commandbar-tooltip-placement-rightTop .commandbar-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .commandbar-tooltip-placement-rightBottom .commandbar-tooltip-arrow {
    bottom: 15%;
  }
  .commandbar-tooltip-placement-left .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-leftTop .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-leftBottom .commandbar-tooltip-arrow {
    right: 4px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #373737;
  }
  .commandbar-tooltip-placement-left .commandbar-tooltip-arrow {
    top: 50%;
  }
  .commandbar-tooltip-placement-leftTop .commandbar-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .commandbar-tooltip-placement-leftBottom .commandbar-tooltip-arrow {
    bottom: 15%;
  }
  .commandbar-tooltip-placement-bottom .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-bottomLeft .commandbar-tooltip-arrow,
  .commandbar-tooltip-placement-bottomRight .commandbar-tooltip-arrow {
    top: 4px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #373737;
  }
  .commandbar-tooltip-placement-bottom .commandbar-tooltip-arrow {
    left: 50%;
  }
  .commandbar-tooltip-placement-bottomLeft .commandbar-tooltip-arrow {
    left: 15%;
  }
  .commandbar-tooltip-placement-bottomRight .commandbar-tooltip-arrow {
    right: 15%;
  }
`;

export default style;
