import React from 'react';

import type { CustomComponent, CustomComponentInstance } from '@commandbar/internal/client/CommandBarClientSDK';

const RenderCustomComponent = ({
  component,
  data,
  metadata,
}: {
  component: CustomComponent;
  data?: any;
  metadata?: any;
}) => {
  const instance = React.useRef<CustomComponentInstance | null>(null);

  const mountCustomComponent = (elem: HTMLDivElement) => {
    if (instance.current) {
      instance.current.unmount();
      instance.current = null;
    }

    if (elem) {
      instance.current = component.mount(elem);
      instance.current.render(data, metadata);
    }
  };

  React.useEffect(() => {
    return () => {
      if (instance.current) {
        instance.current.unmount();
      }
    };
  }, []);

  instance.current?.render(data, metadata);

  return <div ref={mountCustomComponent} />;
};

export default RenderCustomComponent;
