import React from 'react';

const useVisualViewportSize = () => {
  const getSize = () => {
    /**
     * FIXME: This is a temporary fix for the visual viewport size for test environment.
     *
     * The visual viewport is the portion of the page that is currently visible
     *
     * In some environments, window.visualViewport may not be available. In this
     * case, we fall back to using window.innerWidth and window.innerHeight as
     * the viewport size. This is a hack to get our tests to pass and in most browsers,
     * window.visualViewport is available. However, we should find a polyfill for this in future.
     */
    return {
      width: window.visualViewport?.width ?? window.innerWidth,
      height: window.visualViewport?.height ?? window.innerHeight,
    };
  };

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []); // Only run on mount and unmount

  return windowSize;
};

export default useVisualViewportSize;
