import React, { FC } from 'react';
import { CgClose } from 'react-icons/cg';

import * as App from '../../../store/app/actions';

import { useAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';

const ClearIndicator: FC = () => {
  const { theme } = useTheme();
  const clearInput = useAction(App.clearInput);

  const onClick = () => {
    clearInput();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '16px',
      }}
    >
      <CgClose size={16} onClick={onClick} style={{ color: theme.base.fontColor, cursor: 'pointer', opacity: 0.7 }} />
    </div>
  );
};

export default ClearIndicator;
