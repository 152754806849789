/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { useTheme } from 'emotion-theming';
import chroma from 'chroma-js';

import Tag from '../Tag';
import { ITheme } from '@commandbar/internal/client/theme';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { useStore } from '../../hooks/useStore';

/*******************************************************************************/
/* Render
/*******************************************************************************/

export interface HotkeyTagProps {
  combo: string;
  highlight?: boolean;
  isUserCustomized?: boolean;
}

const HotkeyTag = (props: HotkeyTagProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const { platform } = useStore().engine;

  if (props.combo.length === 0) {
    return null;
  }

  // split into sequence parts
  const keys = props.combo.split(' ');

  if (!['mac', 'windows', 'linux'].includes(platform)) {
    return null;
  }

  let color: any;
  try {
    color = chroma(theme.keyboardShortcuts.color);
  } catch {
    color = chroma('rgba(255, 255, 255, 0.85)');
  }

  const tags: any = [];
  keys.forEach((s: string, index: number) => {
    tags.push(
      <Tag
        key={`${s}-${index}`}
        color={color}
        borderRadius={theme.keyboardShortcuts.borderRadius}
        backgroundColor={theme.keyboardShortcuts.background}
        borderColor={props.highlight ? theme.keyboardShortcuts.highlight : theme.keyboardShortcuts.borderColor}
        fontSize={theme.keyboardShortcuts.fontSize}
        style={{
          paddingTop: theme.keyboardShortcuts.paddingTop,
          paddingBottom: theme.keyboardShortcuts.paddingBottom,
          paddingLeft: theme.keyboardShortcuts.paddingLeft,
          paddingRight: theme.keyboardShortcuts.paddingRight,
          fontFamily: theme.keyboardShortcuts.fontFamily,
          fontWeight: theme.keyboardShortcuts.fontWeight,
          borderBottomColor:
            props.isUserCustomized || props.highlight
              ? theme.keyboardShortcuts.highlight
              : theme.keyboardShortcuts.borderColor,
          borderBottomWidth: props.isUserCustomized ? '3px' : '1px',
          boxSizing: 'border-box',
        }}
      >
        {s.split('+').map((k: string, i, arr) => {
          return (
            <span key={i}>
              {Hotkey.translateToPlatformSymbol(
                k,
                ['windows', 'linux'].includes(platform) ? 'win' : 'mac',
                i === arr.length - 1,
              )}
            </span>
          );
        })}
      </Tag>,
    );

    if (index !== keys.length - 1) {
      tags.push(
        <span
          aria-hidden={true}
          key={`then-${index}`}
          style={{
            fontSize: theme.keyboardShortcuts.fontSize,
            marginTop: '0px',
            marginBottom: '0px',
            marginLeft: theme.keyboardShortcuts.separatorMarginLeft,
            marginRight: theme.keyboardShortcuts.separatorMarginRight,
          }}
        >
          {theme.keyboardShortcuts.separatorLabel}
        </span>,
      );
    }
  });

  return tags;
};

export default HotkeyTag;
