// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Ai = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 14" {...props}>
    <path
      stroke="url(#paint0_linear_930_6209)"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.75 1.4584v5.5417m0 0 4.9583-2.7547M5.75 7.0001.7917 4.2454M5.75 7.0001v5.5417m4.9583-2.787-4.505-2.5029c-.1655-.0919-.2482-.1379-.3358-.1559a.5835.5835 0 0 0-.235 0c-.0876.018-.1703.064-.3358.1559L.7917 9.7547M11 9.3676v-4.735c0-.1999 0-.2998-.0294-.389a.5839.5839 0 0 0-.125-.2123c-.0636-.069-.1509-.1175-.3257-.2146L6.2033 1.4186c-.1655-.092-.2482-.1379-.3358-.156a.5835.5835 0 0 0-.235 0c-.0876.0181-.1703.064-.3358.156L.9801 3.8167c-.1748.097-.2621.1456-.3257.2146a.5833.5833 0 0 0-.125.2124C.5 4.3327.5 4.4327.5 4.6326v4.735c0 .1998 0 .2998.0294.389a.5833.5833 0 0 0 .125.2122c.0636.0691.151.1176.3257.2147l4.3166 2.3981c.1655.0919.2482.1379.3358.1559a.585.585 0 0 0 .235 0c.0876-.018.1703-.064.3358-.1559l4.3166-2.3981c.1748-.0971.2621-.1456.3257-.2147a.5838.5838 0 0 0 .125-.2123C11 9.6674 11 9.5675 11 9.3675Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_930_6209"
        x1={1.0516}
        x2={11.2998}
        y1={1.8548}
        y2={11.2129}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C892FF" />
        <stop offset={1} stopColor="#4CBEFF" />
      </linearGradient>
    </defs>
  </svg>
);
export default Ai;
