import React from 'react';
import { toast as toastHandler } from 'react-hot-toast';
import { ref } from 'valtio';

import { getElement } from '@commandbar/internal/util/dom';
import { EngineState } from '../../store/engine';
import { interpolateObject } from '../../engine/Interpolate';
import { DraggablePopover } from './Popover';
import Pin from './Pin';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import { isStandaloneEditor } from '@commandbar/internal/util/location';

export const renderNudge = (_: EngineState, nudge: INudgeType, options?: { preview?: boolean; stepIndex?: number }) => {
  nudge = interpolateObject({ s: nudge, engine: _.engine, interpolateContext: true, interpolateArgs: false });

  const step = nudge.steps[options?.stepIndex ?? 0];
  const service = options?.preview ? _.engine.nudgePreviewService : _.engine.nudgeServices.get(String(nudge.id));

  const stepCount =
    nudge.show_step_counter && options?.stepIndex !== undefined && nudge.steps.length > 1
      ? `${options?.stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  const dismissibleOrUndefined = nudge.dismissible === undefined ? true : nudge.dismissible;

  if (service) {
    switch (step.form_factor.type) {
      case 'popover':
        if (step.form_factor.position === 'center' || isStandaloneEditor) {
          toastHandler.custom(
            () => (
              <DraggablePopover
                step={step}
                service={service}
                dismissible={dismissibleOrUndefined}
                stepCount={stepCount}
                center
              />
            ),
            {
              id: `${nudge.id}-${String(step.id)}`,
              duration: Infinity,
            },
          );
        } else {
          toastHandler.custom(
            () => (
              <DraggablePopover
                step={step}
                service={service}
                dismissible={dismissibleOrUndefined}
                stepCount={stepCount}
              />
            ),
            {
              id: `${nudge.id}-${String(step.id)}`,
              position: step.form_factor.position,
              duration: Infinity,
            },
          );
        }
        break;
      case 'modal':
        _.engine.currentModalNudge = ref({
          step,
          service: service,
          dismissible: dismissibleOrUndefined,
          stepCount: stepCount,
        });
        break;
      case 'pin':
        const selector = step.form_factor.anchor;
        const { offset: nudgeOffset, is_open_by_default } = step.form_factor;
        const isOpenByDefault = is_open_by_default === undefined ? true : is_open_by_default;
        const offset = nudgeOffset === undefined ? { x: '0px', y: '0px' } : nudgeOffset;
        const targetEl = getElement(selector);

        toastHandler.custom(
          () => (
            <Pin
              pin={{ nudge, step, targetEl, isOpenByDefault, offset }}
              service={service}
              preview={options?.preview}
              dismissible={dismissibleOrUndefined}
              stepCount={stepCount}
            />
          ),
          {
            id: `${nudge.id}-${String(step.id)}`,
            duration: Infinity,
          },
        );
        break;
      default:
        toastHandler.custom(
          () => (
            <DraggablePopover
              step={step}
              service={service}
              dismissible={dismissibleOrUndefined}
              stepCount={stepCount}
            />
          ),
          {
            id: `${nudge.id}-${String(step.id)}`,
            position: 'top-right',
            duration: Infinity,
          },
        );
    }
  }
};
