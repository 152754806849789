import { CommandOption, Option } from '../../../engine/option';
import { EngineState } from '../state';
import { isCommandOptionValid } from './command-option-selectors';
import { OptionType } from '../../../engine/option/Option';
import { compareOptionGroups, TabGroup } from '../../../engine/OptionGroup';
import { assertNever } from '../../../util/assertNever';
import { getTabKey } from '../../app/selectors';

export * from './command-option-selectors';
export * from './parameter-option-selectors';
export * from './resource-option-selectors';

export const isOptionExecutable = (
  _: EngineState,
  o: Option,
): { isExecutable: boolean; isExecutableReason: string } => {
  switch (o.type) {
    case OptionType.Command:
    // Commenting out for now in case we want to switch back to Simulate
    // return isCommandOptionExecutable(_, o);
    // falls through
    case OptionType.Parameter:
      return { isExecutable: true, isExecutableReason: '' };
    default:
      return assertNever(o);
  }
};

export const isOptionValid = (_: EngineState, o: Option): { isValid: boolean; isValidReason: string } => {
  switch (o.type) {
    case 'command':
      return isCommandOptionValid(_, o as CommandOption);
    case 'parameter':
      return { isValid: true, isValidReason: '' };
    default:
      throw new Error('unreachable code');
  }
};

const selectTabProperties = (_: EngineState, groupKey: string) => {
  const tab = _.engine.tabs.find((t) => getTabKey(groupKey) === `${t.id}`);

  if (!tab) {
    return {};
  }

  return { icon: tab?.icon, header: tab?.header };
};

export const selectSearchTabs = (_: EngineState, includeHeaders?: boolean): TabGroup[] => {
  const { engine } = _;

  const { currentGroups } = engine;

  let retVal = currentGroups
    .filter((g) => !!g.searchTabEnabled)
    .sort(compareOptionGroups)
    .map((group) => {
      const tabGroup: TabGroup = { ...selectTabProperties(_, group.key), ...group };
      return tabGroup;
    });

  const minSortKeys: {
    [key: string]: number;
  } = {};

  if (!!includeHeaders) {
    const headerSortCompare = (a: TabGroup, b: TabGroup) => {
      const aHeader = a.header || '__no_header__';
      const bHeader = b.header || '__no_header__';

      if (!aHeader && !bHeader) {
        return compareOptionGroups(a, b);
      }
      if (!bHeader) {
        return -1;
      }
      if (!aHeader) {
        return 1;
      }

      if (aHeader === bHeader) {
        return compareOptionGroups(a, b);
      }

      // if headers are defined and unequal, but non of the tabs have a sort_key set, we need a tie breaker that make sure that tabs are still grouped together
      if (typeof minSortKeys[aHeader] === 'undefined' && typeof minSortKeys[bHeader] === 'undefined') {
        return aHeader.localeCompare(bHeader);
      }
      if (typeof minSortKeys[bHeader] === 'undefined') {
        return -1;
      }
      if (typeof minSortKeys[aHeader] === 'undefined') {
        return 1;
      }

      if (minSortKeys[aHeader] < minSortKeys[bHeader]) {
        return -1;
      } else if (minSortKeys[aHeader] > minSortKeys[bHeader]) {
        return 1;
      } else {
        return aHeader.localeCompare(bHeader);
      }
    };

    for (const group of retVal) {
      const headerkey = group.header || '__no_header__';
      if (group.sortKey !== null) {
        if (typeof minSortKeys[headerkey] === 'number') {
          minSortKeys[headerkey] = Math.min(group.sortKey, minSortKeys[headerkey]);
        } else {
          minSortKeys[headerkey] = group.sortKey;
        }
      }
    }

    retVal = retVal.sort(headerSortCompare);
  }

  return retVal;
};
