/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React, { useState } from 'react';
import { AiOutlineMessage, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import { jsx } from '@emotion/core';

import SVG from '@commandbar/internal/util/SVG';
import Tooltip from '../../Tooltip';
import { translate } from '../../../util/languages';
import * as Reporting from '../../../analytics/Reporting';

import Button from '../../Button';
import { useAction } from '../../../hooks/useAction';
import * as App from '../../../store/app/actions';
import useTheme from '../../../hooks/useTheme';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { isMobile } from '@commandbar/internal/util/operatingSystem';

interface ISuggestCommandIconProps {
  organization?: IOrganizationType;
}

export const SuggestCommandIcon = (props: ISuggestCommandIconProps) => {
  const { theme } = useTheme();
  const setDashboard = useAction(App.setDashboard);
  const openSuggestCommand = () => setDashboard('feedback');

  return (
    <Tooltip
      trigger={['hover', 'focus']}
      overlay={<span style={{ fontFamily: theme.main.fontFamily }}>{translate(theme.feedback.breadcrumbText)}</span>}
      placement="bottom"
    >
      <button
        aria-label="feedback"
        style={{
          background: 'none',
          color: 'inherit',
          border: 'none',
          padding: 0,
          font: 'inherit',
          cursor: 'pointer',
        }}
        onClick={openSuggestCommand}
      >
        {!!props?.organization?.icon_suggest ? (
          <SVG
            htmlstring={props.organization.icon_suggest}
            height="20px"
            width="20px"
            style={{ fill: 'none', color: theme.main.color, verticalAlign: 'middle' }}
          />
        ) : (
          <AiOutlineMessage style={{ color: theme.main.color, verticalAlign: 'middle', fontSize: 20 }} />
        )}
      </button>
    </Tooltip>
  );
};

export const SuggestCommandForm = () => {
  const [text, setText] = useState('');
  const [complete, setComplete] = useState(false);
  const setDashboard = useAction(App.setDashboard);
  const { theme } = useTheme();
  const textArea: any = React.useRef(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    Reporting.suggestion(text);
    setComplete(true);
    setTimeout(() => {
      setDashboard(undefined);
    }, 2500);
  };

  React.useEffect(() => {
    const handleKeydown = (e: any) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(e);
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  });

  React.useEffect(() => {
    textArea.current.focus();
  }, []);

  const handleTextChange = (e: any) => {
    const value = e.target.value;
    setText(value);
  };

  const goBack = () => {
    setDashboard(undefined);
  };

  if (complete) {
    return <span style={{ fontSize: theme.base.fontSize }}>{translate(theme.feedback.submissionText)}</span>;
  }

  return (
    <div style={{ margin: '-15px -35px' }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',

          '& Button': {
            display: 'flex',
            alignItems: 'center',
            fontSize: theme.base.fontSize,
            fontWeight: 500,
            height: 24,
            padding: '0 4px',
            border: 'none',
            borderRadius: 4,
          },
        }}
      >
        <Button
          aria-label="exit-feedback"
          onClick={goBack}
          css={{
            color: theme.feedback.breadcrumbColor,
            background: theme.feedback.breadcrumbBackground,
            '&:hover': {
              color: theme.feedback.hoverBreadcrumbColor,
              background: theme.feedback.hoverBreadcrumbBackground,
            },
            cursor: 'pointer',
          }}
        >
          {translate(theme.feedback.breadcrumbText)}
          <AiOutlinePlus
            onClick={goBack}
            style={{ transform: 'rotate(45deg)', marginTop: 2, marginLeft: 4, opacity: 0.55, cursor: 'pointer' }}
          />
        </Button>

        <Button
          aria-label="submit-feedback"
          onClick={handleSubmit}
          disabled={!text}
          style={{
            color: theme.feedback.submitButtonColor,
            background: theme.feedback.submitButtonBackground,
            padding: `${theme.feedback.submitButtonPadding} !important`,
            borderRadius: `${theme.feedback.submitButtonBorderRadius} !important`,
            fontSize: `${theme.feedback.submitButtonFontSize} !important`,
            fontWeight: `${theme.feedback.submitButtonFontWeight} !important`,
          }}
          css={{
            '&:hover': {
              color: theme.feedback.hoverSubmitButtonColor,
              background: theme.feedback.hoverSubmitButtonBackground,
            },
          }}
        >
          {translate('Submit')}
          <BsArrowRightShort style={{ fontSize: '20px', marginLeft: 4 }} />
        </Button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <textarea
          data-testid="feedback-input"
          name="title"
          value={text}
          ref={textArea}
          onChange={handleTextChange}
          placeholder={translate(theme.feedback.placeholderText)}
          rows={8}
          css={{
            width: '100%',
            padding: 16,
            margin: '15px 0 0 0',
            color: theme.feedback.color,
            fontFamily: theme.feedback.fontFamily,
            backgroundColor: theme.feedback.background,
            border: `1px solid ${theme.feedback.borderColor}`,
            borderRadius: 8,
            fontSize: isMobile() ? '16px' : theme.feedback.fontSize,
            lineHeight: '16px',
            fontWeight: 500,
            resize: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        />
      </div>
    </div>
  );
};
