/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import { css, jsx, keyframes } from '@emotion/core';

/* Local imports */
import useLauncherPosition from '../../hooks/useLauncherPosition';
import chroma from 'chroma-js';
import { ReactComponent as Bolt } from '../../img/bolt.svg';
import { ReactComponent as Clubhouse } from '../../img/ch.svg';
import { osControlKey, isMobile } from '@commandbar/internal/util/operatingSystem';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { CSSProperties } from 'react';
import { useStore } from '../../hooks/useStore';
import { selectSummonHotkey } from '../../store/engine/selectors';
import HotkeyTag from '../hotkeys/HotkeyTag';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import useTheme from '../../hooks/useTheme';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  onClick: () => void;
  isAdmin: boolean;
  organization: IOrganizationType;
}

const LauncherWidget = (props: IProps) => {
  const { isAdmin, organization } = props;
  const launcherPosition = useLauncherPosition(isAdmin, organization) as unknown as CSSProperties;
  const summonHotkey = selectSummonHotkey(useStore());

  const { theme } = useTheme();

  const theme_primary = theme.base?.primary;
  let theme_background = theme.launcher?.background;
  const theme_fontFamily = theme.main?.fontFamily;
  const theme_color = theme.main?.color;

  let LAUNCHER_WIDTH = 0;

  const showSummonHotkey = () => {
    return !isMobile() && !!summonHotkey;
  };

  const entryAnimation = keyframes`
  0% {
    transform: scale(0.06);
  }
  100% {
    transform: scale(1);
  }
`;

  /********************* Start text handling **********************/

  let toRet = <div />;
  switch (organization?.launcher_type) {
    case 'none':
      return null;
    case 'minimal':
      let hoverBackground = theme.main.background;
      try {
        hoverBackground = chroma(theme.main.background).alpha(0.98).css();
      } catch {}

      let icon = (
        <Bolt
          id="bolt"
          width={'26px'}
          height={'26px'}
          fill={theme_primary}
          style={{
            transition: 'all .3s ease-in-out',
            verticalAlign: 'baseline',
          }}
        />
      );

      if (organization?.id === 'f7aa22b3') {
        icon = (
          <Clubhouse
            id="clubhouse"
            width={'26px'}
            height={'26px'}
            style={{
              transition: 'all .3s ease-in-out',
              verticalAlign: 'baseline',
              transform: 'translate(2px, 2px)',
            }}
          />
        );
        theme_background = '#6515dd';
        hoverBackground = '#6515dd';
      }

      LAUNCHER_WIDTH = 50; // padding + bolt
      toRet = (
        <div className="commandbar-launcher" style={launcherPosition}>
          <div
            aria-hidden={true}
            css={css`
              padding: 9px 10px;
              color: ${theme_color};
              background-color: ${theme_background};
              border-radius: 27px;
              box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
              font-family: ${theme_fontFamily};
              line-height: 1;
              transition: all 0.2s ease-in-out;
              animation: ${entryAnimation} 0.25s ease-out;
              &:hover {
                background-color: ${hoverBackground};
                cursor: pointer;
                transform: scale(1.3);
              }
            `}
            onClick={props.onClick}
          >
            {icon}
          </div>
        </div>
      );
      break;
    case 'alternate':
      LAUNCHER_WIDTH = showSummonHotkey() ? 70 : 0; // padding + bolt
      toRet = (
        <div className="commandbar-launcher" style={launcherPosition}>
          <div
            aria-hidden={true}
            data-testid="launcher"
            css={css`
              display: flex;
              justify-content: space-around;
              padding: 10px 8px;
              color: ${theme_color};
              background-color: ${theme_background};
              border-radius: 4px;
              box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.22);
              font-size: 13px;
              font-family: ${theme_fontFamily};
              min-width: ${LAUNCHER_WIDTH}px;
              transition: all 0.3s ease-in-out;
              line-height: 1;
              cursor: pointer;
              animation: ${entryAnimation} 0.25s ease-out;

              &:hover {
                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.16);
                color: ${theme_color};
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                transform: scale(1.1);

                #bolt {
                  transform: scale(1.2);
                  transition: all 0.3s ease-in-out;
                }
              }
            `}
            onClick={props.onClick}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', padding: showSummonHotkey() ? '4px 9px 4px 0px' : '4px' }}
            >
              <Bolt
                id="bolt"
                width={'14px'}
                height={'14px'}
                fill={theme_primary}
                style={{ transition: 'all .3s ease-in-out', verticalAlign: 'baseline', lineHeight: 1 }}
              />
            </div>
            {showSummonHotkey() && <div>{OSKeys(theme_primary, summonHotkey)}</div>}
          </div>
        </div>
      );
      break;
    case 'prompt':
      LAUNCHER_WIDTH = showSummonHotkey() ? 150 : 0; // padding + bolt
      toRet = (
        <div className="commandbar-launcher" style={launcherPosition}>
          <div
            aria-hidden={true}
            css={css`
              display: flex;
              justify-content: space-between;
              padding: 4px 8px;
              color: #8792a2;
              background-color: #f7fafc;
              border-radius: 4px;
              box-shadow: 4px 5px 10px 0px rgba(0, 0, 0, 0.16);
              font-size: 13px;
              font-family: ${theme_fontFamily};
              min-width: ${LAUNCHER_WIDTH}px;
              transition: all 0.3s ease-in-out;
              line-height: 2;
              cursor: pointer;

              &:hover {
                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.16);
                color: #697386;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                #bolt {
                  transform: scale(1.3);
                  transition: all 0.3s ease-in-out;
                }
              }
            `}
            onClick={props.onClick}
          >
            <div style={{ padding: '1px 4px 0px 0px' }}>
              <Bolt
                id="bolt"
                width={'14px'}
                height={'14px'}
                fill={theme_primary}
                style={{ transition: 'all .3s ease-in-out', verticalAlign: 'baseline' }}
              />
            </div>
            <div>Find anything</div>
            {showSummonHotkey() && <div style={{ flexGrow: 1 }} /> && OSKeys(theme_primary, summonHotkey)}
          </div>
        </div>
      );
      break;
  }

  return toRet;
};

const OSKeys = (color: string, summonHotkey: any) => {
  const Tag = (props: { children: any }) => {
    return (
      <span
        css={css`
          background-color: ${getChromaColor(color).alpha(0.2).css()};
          border-radius: 2px;
          font-size: 12px;
          padding: 3px 5px;
          color: ${color};
        `}
      >
        {props.children}
      </span>
    );
  };

  if (summonHotkey === 'mod+k') {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', lineHeight: 1, height: '100%' }}>
        <Tag>{osControlKey()}</Tag>&nbsp;&nbsp;
        <Tag>K</Tag>
      </div>
    );
  } else {
    return <HotkeyTag combo={summonHotkey} />;
  }
};

export default LauncherWidget;
