import { snapshot, ref } from 'valtio';
import { State } from '../..';
import ExecutionPath, { isSelectStep, isTimeStep, isContextFunctionStep } from '../../../engine/ExecutionPath';
import { StepType } from '../../../engine/step/Step';
import { sub } from '../../util/sub';
import { isContextArgument } from '@commandbar/internal/middleware/types';
import * as App from '../../app/actions';
import * as Engine from '../actions';
import { convertParameterToOption, selectSearchTabs } from '../options';
import { isEditorBeingSummoned } from '../../../client_api/utils';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import search from '../../../engine/Search';
import { SelectOrCreate } from '../../../engine/features/selectOrCreate';

import { sortOptionsAndInsertGroups } from './option-list-selectors';
import { callAllLoaders, updateHotloadedHelpdocCommands } from './actions';
import { filterOptionsForDefaultState, generateTimeOptions, getFallbackOptions } from './selectors';
import Available from '../../../engine/Available';
import { findOp, getPrevValue, Ops } from '../../util/hasOp';
import * as Reporting from '../../../analytics/Reporting';

const isSearchCallback = (key: string) => key.includes('commandbar-search-');

/**
 * When initial options change, update the current options based on what type of step we're on
 */
const updateCurrentOptionsOnInitialOptionsChange = (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  // If pre-selected text input, set the input text
  if (
    (currentStep?.type === StepType.TextInput || currentStep?.type === StepType.LongTextInput) &&
    currentStep.selected?.data
  ) {
    if (_.engine.inputText) {
      _.engine.previousInputText = _.engine.inputText;
    }
    _.engine.inputText = currentStep.selected?.data;
    _.engine.rawInput = currentStep.selected?.data;
    _.engine.currentOptions = ref(_.engine.initialOptions);
  }

  if (isSelectStep(currentStep) && isTimeStep(currentStep)) {
    _.engine.currentOptions = ref(generateTimeOptions(_, _.engine.inputText, currentStep));
  } else if (isSelectStep(currentStep) && isContextFunctionStep(currentStep, _.engine)) {
    /**
     * ASYNC PARAMETERS
     */
    _.engine.currentOptions = [];

    const fnName = isContextArgument(currentStep.argument)
      ? `commandbar-initialvalue-${currentStep.argument.value}`
      : null;

    const asyncFn = fnName && _.engine.callbacks[fnName];

    if (asyncFn) {
      App.setLoading(_, fnName, true);
      const chosenValues = ExecutionPath.selections(_.engine);
      try {
        const callAsyncFnAndSetResults = async () => {
          const values = await asyncFn(chosenValues);
          if (values && Array.isArray(values)) {
            // only update if step hasn't changed to avoid race conditions
            const newStep = ExecutionPath.currentStepAndIndex(_.engine).currentStep;
            if (isSelectStep(newStep) && currentStep.argument.userDefinedName === newStep?.argument?.userDefinedName) {
              const parameterOptions = values.map((obj: any) => convertParameterToOption(_, obj, currentStep.argument));
              _.engine.currentOptions = ref(parameterOptions);
              Engine.handleAsyncPreselect(_);
            }
          }
        };
        callAsyncFnAndSetResults();
      } finally {
        App.setLoading(_, fnName, false);
      }
    }
  } else {
    // We only update options if the input is empty (to avoid jumpiness during typing) or if addSearch is defined
    if (_.engine.inputText.length === 0) {
      /**
       * When the input is empty...
       *
       * If...
       *   We are on a multi-select AND
       *   The options are generated by a search function AND
       *   There are no default options specified
       * Then...
       *   Instead of an empty state ("Type to search"),
       *   Show the preselected options
       *
       * Otherwise...
       *   Filter and set as normal
       */
      if (
        currentStep?.type === StepType.MultiSelect &&
        Object.keys(_.engine.callbacks).find(isSearchCallback) &&
        _.engine.initialOptions.length === 0 &&
        Array.isArray(currentStep.selected?.data)
      ) {
        _.engine.currentOptions = ref(
          (currentStep.selected?.data || []).map((val: any) => {
            return convertParameterToOption(_, val, currentStep.argument);
          }),
        );
      } else {
        // Empty
        _.engine.currentOptions = ref(_.engine.initialOptions);
      }
    } else {
      // addSearch is defined
      // Update the currentOptions based on a change to initialOptions
      if (
        Object.keys(_.engine.callbacks).find((callbackKey) => callbackKey.includes('commandbar-search-')) ||
        _.engine.organization?.hotload_help_docs
      ) {
        _.engine.currentOptions = ref(_.engine.initialOptions);
      }
    }
  }
};

const optionFilteringAndSorting = (_: State) => {
  const isFilterActive = !!_.searchFilter;
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  const options = (() => {
    if (isEditorBeingSummoned(_.engine.inputText)) {
      return [];
    } else if (currentStep?.type === StepType.TextInput) {
      return [initParameterOption(_, currentStep.argument.userDefinedName, _.engine.inputText)];
    } else if (isSelectStep(currentStep) && isTimeStep(currentStep)) {
      return generateTimeOptions(_, _.engine.inputText, currentStep);
    } else {
      let toRet = search(_.engine.inputText, _.engine, _.engine.currentOptions);
      if (SelectOrCreate.isEnabled(currentStep)) {
        toRet = SelectOrCreate.addCreatedOptionToList({
          engine: _.engine,
          options: toRet,
          inputValue: _.engine.inputText,
          currentStep: currentStep,
        });
      }
      return toRet;
    }
  })();

  const allFilteredOptions = isFilterActive ? options : filterOptionsForDefaultState(_, options, _.engine.inputText);

  const tabGroups = selectSearchTabs(_, false);

  // filter out options that don't match the search filter (e.g. selected tab or slash filter)
  const selectedFilteredOptions = _.searchFilter?.fn
    ? allFilteredOptions.filter(_.searchFilter.fn)
    : allFilteredOptions;

  const afterSort = sortOptionsAndInsertGroups(selectedFilteredOptions, _);

  _.engine.sortedOptions = ref(afterSort.length ? afterSort : getFallbackOptions(_));

  if (_.engine.inputText.length !== 0) {
    const allFilteredOptionsByGroup = new Map(
      tabGroups.map((tabGroup) => [tabGroup.key, options.filter(tabGroup.filterFn).length]),
    );

    _.engine.optionCountsByTabGroup = ref(allFilteredOptionsByGroup);
  } else {
    _.engine.optionCountsByTabGroup = ref(new Map());
  }

  if (!options.length && _.engine.inputText.length > 0) {
    Reporting.noResultsForQuery(_.engine.inputText);
  }
};

const updateInitialOptions = (_: State) => {
  // HACK: memoize-one always verifies the equality of the `this` value, which will change since _.engine is a live
  // reference and one that probably just changed recently if this subscription is being called. To bypass this issue
  // Function.prototype.call is used with an explicit `this` value of `null`.
  const newOptions = _.engine.commandsLoaded
    ? _.engine.availableOptionMemoizer.call(null, _, snapshot(_.engine) as State['engine'], Available.available)
    : [];
  if ((newOptions.length === 0 && _.engine.initialOptions.length === 0) || newOptions === _.engine.initialOptions)
    return;
  _.engine.initialOptions = ref(newOptions);
};

const callLoadersOnOpen = (_: State, ops: Ops) => {
  // FIXME: We can get rid of checking the replacedEngine now that we don't
  // replace the engine anymore
  const replacedEngine = getPrevValue<State['engine']>(findOp('set', ['engine'], ops));
  if (replacedEngine && replacedEngine.visible === _.engine.visible) return;
  if (_.engine.visible) {
    callAllLoaders(_);
  }
};

export const initGenerateOptionsSubs = (_: State) => [
  sub(_, updateInitialOptions, [
    ['engine', 'visible'],

    ['engine', 'commands'],
    ['engine', 'programmaticCommands'],
    ['engine', 'hotloadedCommands'],

    ['engine', 'localContextSettings'],
    ['engine', 'serverContextSettings'],
    ['engine', 'context'],
    ['engine', 'records'],
    ['engine', 'callbacks'],
    ['engine', 'testMode'],
  ]),
  sub(_, updateCurrentOptionsOnInitialOptionsChange, [['engine', 'initialOptions']]),

  // TODO: separate hotloaded commands into separate `engine.hotloadedCommands` state,
  // then merge with regular commands when we need allCommands
  sub(_, updateHotloadedHelpdocCommands, [['engine', 'inputText'], ['active']]),
  sub(_, optionFilteringAndSorting, [
    ['engine', 'categories'],
    ['searchFilter'],
    ['engine', 'expandedGroupKeys'],
    ['engine', 'currentOptions'],
    ['engine', 'inputText'],
  ]),
  sub(_, callLoadersOnOpen, [['engine', 'visible']]),
];
