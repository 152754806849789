/** @jsx jsx  */
/** @jsxFrag */
import { useCallback, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';

import { useStore } from '../../hooks/useStore';
import useTheme from '../../hooks/useTheme';
import { useStyles } from './useStyles';
import useKeyboardNavigation from '../../hooks/useKeyboardNavigation';
import useHasScroll from '../../hooks/useHasScroll';
import { useAction } from '../../hooks/useAction';

import * as Engine from '../../store/engine/actions';
import * as Reporting from '../../analytics/Reporting';
import { linkExecutable } from '../../store/engine/steps/executables';
import { getHelpHubRecommendations, HelpHubDoc } from '../../store/engine';

import RecommendationCard from './RecommendationCard';
import React from 'react';
import { RecommendationEmptyGrid, RecommendationEmptyList } from './RecommendationEmptyState';

interface RecommendationsProps {
  setCurrentDoc: (doc: HelpHubDoc | null) => void;
}

const Recommendations = ({ setCurrentDoc }: RecommendationsProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const _ = useStore();
  const { loading: isLoading } = _.engine.helpHub;
  const executeResourceCommand = useAction(Engine.executeResourceCommand);
  const NUM_GRID = _.engine.organization?.featured_item_cards ? 2 : 0;

  // There should always be a default recommendation set. However, in the event that
  // it's not there, fallback to the old behavior of showing the first 10 search results.
  const fallbackDocs: HelpHubDoc[] = _.engine.helpHub.searchResults.slice(0, 10);

  const recommendations = getHelpHubRecommendations(_);
  const initialDocs =
    recommendations.length || _.engine.helpHub.previewedRecommendationSetId ? recommendations : fallbackDocs;

  const [gridRecommendations, listRecommendations] = [initialDocs?.slice(0, NUM_GRID), initialDocs?.slice(NUM_GRID)];
  const hasEmptyStateDocs = !!initialDocs?.length;

  const scrollRef = useRef(null);
  const { hasScrolled } = useHasScroll(scrollRef);

  useEffect(() => {
    if (hasScrolled) {
      Reporting.helpHubEngagement({ query: _.engine.helpHub.query }, 'scrolled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScrolled]);

  const handleOnClick = useCallback(
    (doc: HelpHubDoc) => {
      switch (doc.command.template.type) {
        case 'video':
        case 'helpdoc':
          setCurrentDoc(doc);
          break;
        case 'link':
          linkExecutable(_.engine, doc.command.template)();
          break;
        default:
          executeResourceCommand(doc.command);
          break;
      }
    },
    [setCurrentDoc],
  );

  const { currentIndex, selectionMethod, onMouseMove, onMouseLeave } = useKeyboardNavigation(
    initialDocs,
    handleOnClick,
  );

  return (
    <div ref={scrollRef} css={styles.recommendationsContainer}>
      {!isLoading && (
        <React.Fragment>
          <h1 css={styles.recommendationsHeading}>{theme.helpHub.featuredContentLabel}</h1>
          <div css={styles.recommendationsListContainer} role="list">
            {_.engine.organization?.featured_item_cards && (
              /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
              <div css={styles.gridItems} role="group" aria-label="Grid Recommendations" onMouseLeave={onMouseLeave}>
                {!!hasEmptyStateDocs ? (
                  <React.Fragment>
                    {gridRecommendations?.map((doc, index) => {
                      const active = currentIndex === index;

                      return (
                        <RecommendationCard
                          key={`${doc.commandID}-${index}`}
                          doc={doc}
                          active={active}
                          selectionMethod={selectionMethod}
                          onClick={() => handleOnClick(doc)}
                          onMouseMove={() => onMouseMove(index)}
                          grid
                        />
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <RecommendationEmptyGrid />
                )}
              </div>
            )}
            {!!hasEmptyStateDocs ? (
              <React.Fragment>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <div
                  css={styles.recommendedListItems}
                  role="group"
                  aria-label="Recommendations"
                  onMouseLeave={onMouseLeave}
                >
                  {listRecommendations?.map((doc, index) => {
                    const active = currentIndex === index + (gridRecommendations?.length || 0);

                    return (
                      <RecommendationCard
                        key={`${doc.commandID}-${index}`}
                        doc={doc}
                        active={active}
                        selectionMethod={selectionMethod}
                        onClick={() => handleOnClick(doc)}
                        onMouseMove={() => onMouseMove(index + (gridRecommendations?.length || 0))}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            ) : (
              <RecommendationEmptyList numListItems={_.engine.organization?.featured_item_cards ? 3 : 5} />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Recommendations;
