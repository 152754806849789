import React from 'react';
import { useStore } from '../../hooks/useStore';
import { useDelayUnmount } from '../../hooks/useDelayUnmount';
import { getOptionData, getOptionMetadata, isCommandOption, isOption } from '../../store/engine';
import { DetailPreviewObjectType } from '@commandbar/internal/middleware/detailPreview';
import { interpolate } from '../../engine/Interpolate';
import { OptionGroup } from '../../engine/OptionGroup';
import { Option } from '../../engine/option';
import DetailPreview from './DetailPreview';

interface PreviewProps {
  focusedIndex: number;
  focusedOption: OptionGroup | Option | undefined;
  detailPreview: DetailPreviewObjectType[] | null;
}

const DetailPreviewContainer = ({ focusedOption, focusedIndex, detailPreview }: PreviewProps) => {
  const _ = useStore();
  const { engine } = _;
  const previewRef = React.useRef<HTMLDivElement>(null);

  const { label, optionData, optionMetadata } = React.useMemo(() => {
    if (isOption(focusedOption) && !!detailPreview) {
      return {
        label: focusedOption.label,
        optionData: getOptionData(focusedOption),
        optionMetadata: getOptionMetadata(focusedOption),
      };
    } else {
      return { label: undefined, optionData: undefined, optionMetadata: undefined };
    }
  }, [focusedOption]);

  const pageLink = React.useMemo(() => {
    if (isCommandOption(focusedOption) && !!detailPreview) {
      if (focusedOption.command.template.type === 'link' || focusedOption.command.template.type === 'helpdoc') {
        return interpolate(focusedOption.command.template.value, engine, true, true, false);
      }
    }
    return undefined;
  }, [focusedOption]);

  const [isMounted, setIsMounted] = React.useState(false);
  const shouldRenderChild = useDelayUnmount(isMounted, 300);

  React.useEffect(() => {
    if (focusedOption && !!detailPreview) {
      setIsMounted(true);
    } else {
      setIsMounted(false);
    }
  }, [focusedOption]);

  return (
    <>
      {shouldRenderChild && (
        <DetailPreview
          focusedIndex={focusedIndex}
          shouldRenderChild={shouldRenderChild}
          previewRef={previewRef}
          content={detailPreview ?? []}
          isMounted={isMounted}
          optionData={optionData}
          optionMetadata={optionMetadata}
          title={label}
          uid={optionMetadata?.uid}
          pageLink={pageLink}
        />
      )}
    </>
  );
};

export default DetailPreviewContainer;
