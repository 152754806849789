import React, { useEffect } from 'react';
import App from '../App';

import { getSDK } from '@commandbar/internal/client/globals';
import { dispose } from '@commandbar/internal/util/Disposable';
import { _configure } from '@commandbar/internal/client/symbols';
import { StoreProvider } from '../store/StoreProvider';
import { SentryErrorBoundary } from '@commandbar/internal/util/sentry';

export interface CommandBarContainerProps {
  orgID: string;
  userID?: string;
}

/**
 * This component takes care of initializing and mounting CommandBar within your application. At minimum an orgID is
 * required. If a userID is not provided here then window.CommandBar.boot('user-id') must be called before the
 * CommandBar will become active.
 */
export const CommandBarContainer: React.FC<CommandBarContainerProps> = (props: CommandBarContainerProps) => {
  const [initialized, setInitialized] = React.useState(false);

  useEffect(
    () => () => {
      dispose(getSDK());
    },
    [],
  );

  useEffect(() => {
    // this *must* happen before we render <App />
    getSDK()[_configure](props.orgID);

    if (typeof props.userID !== 'undefined') {
      getSDK().boot(props.userID);
    }

    setInitialized(true);
  }, [props.orgID]);

  return (
    <SentryErrorBoundary>
      <StoreProvider>{initialized && <App />}</StoreProvider>;
    </SentryErrorBoundary>
  );
};
