import { useEffect, useRef } from 'react';
/**
 * Emotion v11 has a bug where it will move Emotion v10 style tags from their original container to the document <head>.
 *
 * This causes a problem because the CSS rules in the <style> tag are added with the "insertRule" API in "speedy" mode,
 * and when they are moved the rules inside of them are dropped entirely. Additionally, when we render an Emotion
 * <style> tag inside of a Shadow DOM root, it would be very important that the style tag is not moved outside of the
 * Shadow DOM root.
 *
 * The result is that the Bar is unstyled after this bug is triggered.
 *
 * This Emotion bug appears to be fixed in https://github.com/emotion-js/emotion/pull/2361, but LaunchDarkly has not
 * upgraded and was running into this issue.
 *
 * To work around, we can set the `data-s` attribute on any Emotion style cache <style> tags; this will prevent
 * Emotion v11 from moving them because it has a check for this attribute in the code that moves the style tags.
 *
 * @returns A div element that can be used as a container for Emotion styles. Styles inside this container will have the `data-s` attribute set to `""` to work around a bug in Emotion.
 */

const useEmotionBugWorkaroundStyleContainer = (parentContainer: HTMLElement = document.body) => {
  const container = useRef<HTMLDivElement | null>(null);
  const observer = useRef<MutationObserver | null>(null);

  const parentContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    return () => {
      observer.current?.disconnect();
      container.current?.remove();
    };
  }, []);

  if (container.current === null || parentContainer !== parentContainerRef.current) {
    observer.current?.disconnect();
    container.current?.remove();

    parentContainerRef.current = parentContainer;

    container.current = document.createElement('div');
    container.current.setAttribute('data-commandbar-toplevel-styles', '');
    container.current.setAttribute('hidden', '');
    parentContainer.prepend(container.current);

    // watch for any subtree modifications and set data-s attribute to "" for any children <style> tags managed by Emotion
    observer.current = new MutationObserver(() => {
      container.current &&
        container.current.querySelectorAll('style[data-emotion]:not([data-s])').forEach((style) => {
          // set attribute data-s to ""
          style.setAttribute('data-s', '');
        });
    });
    observer.current.observe(container.current, {
      childList: true,
      subtree: true,
    });
  }

  return container.current;
};

export default useEmotionBugWorkaroundStyleContainer;
