export enum CASE {
  OLD_ADD_CONTEXT,
  NEW_ADD_CONTEXT,
}
export enum FIRST_ARG_KEY_TYPE {
  INVALID,
  OBJECT,
  KEY,
}
export enum SECOND_ARG_INITVALUE_TYPE {
  INVALID,
  FUNCTION,
  STATIC,
}
export enum THIRD_ARG_META_TYPE {
  INVALID,
  NONE,
  OBJECT,
}

// NOTE: We should turn these validators and type checks into a class / module

export const getFirstType = (first: unknown): FIRST_ARG_KEY_TYPE => {
  if (first === null || first === undefined) return FIRST_ARG_KEY_TYPE.INVALID;
  if (typeof first === 'object') return FIRST_ARG_KEY_TYPE.OBJECT;
  if (typeof first === 'string') return FIRST_ARG_KEY_TYPE.KEY;
  return FIRST_ARG_KEY_TYPE.INVALID;
};

export const getSecondType = (second: unknown): SECOND_ARG_INITVALUE_TYPE => {
  if (typeof second === 'function') return SECOND_ARG_INITVALUE_TYPE.FUNCTION;
  return SECOND_ARG_INITVALUE_TYPE.STATIC;
};

export const getThirdType = (third: unknown): THIRD_ARG_META_TYPE => {
  if (
    third === null ||
    third === undefined ||
    (typeof third === 'object' && Object.keys(third as Record<string, unknown>).length === 0)
  )
    return THIRD_ARG_META_TYPE.NONE;
  if (typeof third === 'object') return THIRD_ARG_META_TYPE.OBJECT;
  return THIRD_ARG_META_TYPE.INVALID;
};

export const getCase = (
  firstArgType: FIRST_ARG_KEY_TYPE,
  secondArgType: SECOND_ARG_INITVALUE_TYPE,
  thirdArgType: THIRD_ARG_META_TYPE,
) => {
  // Invalid input
  if (
    firstArgType === FIRST_ARG_KEY_TYPE.INVALID ||
    secondArgType === SECOND_ARG_INITVALUE_TYPE.INVALID ||
    thirdArgType === THIRD_ARG_META_TYPE.INVALID
  ) {
    return 'Invalid use of addContext';
  }

  switch (firstArgType) {
    case FIRST_ARG_KEY_TYPE.OBJECT:
      return CASE.OLD_ADD_CONTEXT;
    case FIRST_ARG_KEY_TYPE.KEY:
      return CASE.NEW_ADD_CONTEXT;
  }
};
