// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const BookOpen01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2 6.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 3 4.08 3 5.2 3h.4c2.2402 0 3.3603 0 4.216.436a4 4 0 0 1 1.748 1.748C12 6.0397 12 7.1598 12 9.4V21l-.1001-.1501c-.6946-1.0419-1.0419-1.5629-1.5008-1.9401a3.9997 3.9997 0 0 0-1.3775-.7372C8.4533 18 7.8271 18 6.5748 18H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 16.4802 2 15.9201 2 14.8V6.2Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m12 21-.1001-.1501c-.6946-1.0419-1.0419-1.5629-1.5008-1.9401a3.9998 3.9998 0 0 0-1.3775-.7372C8.4533 18 7.8271 18 6.5748 18H5.2c-1.1201 0-1.6802 0-2.108-.218a1.9997 1.9997 0 0 1-.874-.874C2 16.4802 2 15.9201 2 14.8V6.2c0-1.1201 0-1.6802.218-2.108a1.9999 1.9999 0 0 1 .874-.874C3.5198 3 4.08 3 5.2 3h.4c2.2402 0 3.3603 0 4.216.436a4 4 0 0 1 1.748 1.748C12 6.0397 12 7.1598 12 9.4M12 21V9.4M12 21l.1001-.1501c.6946-1.0419 1.0419-1.5629 1.5008-1.9401a3.999 3.999 0 0 1 1.3775-.7372C15.5467 18 16.1729 18 17.4252 18H18.8c1.1201 0 1.6802 0 2.108-.218a1.9996 1.9996 0 0 0 .874-.874C22 16.4802 22 15.9201 22 14.8V6.2c0-1.1201 0-1.6802-.218-2.108a1.9997 1.9997 0 0 0-.874-.874C20.4802 3 19.9201 3 18.8 3h-.4c-2.2402 0-3.3603 0-4.216.436a4.0002 4.0002 0 0 0-1.748 1.748C12 6.0397 12 7.1598 12 9.4"
    />
  </svg>
);
export default BookOpen01;
