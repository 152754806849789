/** @jsx jsx */
import { css, keyframes, jsx } from '@emotion/core';
import React from 'react';

const load = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const LoadingSkeletion = ({ percentWidth }: { percentWidth: number }) => {
  return (
    <div
      css={css`
        background: #e0e0e0;
        height: 14px;
        margin: 8px;
        width: ${percentWidth}%;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translateX(-100%);
          background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
          animation: ${load} 1.5s infinite;
        }
      `}
    />
  );
};

export default LoadingSkeletion;
