import { ref } from 'valtio';

const doNotTrack = (o: unknown) => {
  if (typeof o === 'object' && o !== null) ref(o);
};

export const doNotTrackContext = (ctx: Record<string, unknown>) => {
  Object.values(ctx).forEach((v) => {
    if (Array.isArray(v)) {
      v.forEach(doNotTrack);
    } else {
      doNotTrack(v);
    }
  });
};
