import { CommandOption, Option, ParameterOption, ResourceOption, UnfurledCommandOption } from '../../../engine/option';
import { ITheme } from '@commandbar/internal/client/theme';
import { GenericOption, GenericOptionMetadata, SearchMatch, OptionType } from '../../../engine/option/Option';
import { Step } from '../../../engine/step';
import isEqual from 'lodash/isEqual';
import { OptionGroup, isOptionGroup as _isOptionGroup } from '../../../engine/OptionGroup';
import * as Command from '@commandbar/internal/middleware/command';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

export const isOption = (o: unknown): o is Option =>
  (o as Option)?.type === OptionType.Command || (o as Option)?.type === OptionType.Parameter;

export const isCommandOption = (option: unknown): option is CommandOption =>
  (option as CommandOption)?.type === OptionType.Command;

export const isHelpHubCommand = (option: unknown): boolean =>
  isCommandOption(option) && option.command.template.type === 'helpdoc';

export const isHotloadedCommandOption = (
  option: unknown,
  organization: IOrganizationType | undefined,
): option is CommandOption => {
  return (
    isCommandOption(option) && Command.isHelpSyncCommand(option.command) && !!organization?.has_hotloaded_help_docs
  );
};

export const isOptionGroup = (option: unknown): option is OptionGroup => _isOptionGroup(option);

export const setOptionMatches = <O extends Option>(o: O, matches: SearchMatch[], score: number | undefined): O => {
  return {
    ...o,
    searchMatches: matches,
    searchScore: score,
  };
};

export const setOptionGroupKey = <O extends Option>(o: O, groupKey: string): O => {
  return {
    ...o,
    groupKey,
  };
};

export const getOptionIconColorBase = (theme: ITheme, isFocused: boolean, isDisabled: boolean) => {
  let defaultColorFromTheme = (() => {
    if (isFocused) {
      if (isDisabled) {
        return theme.optionDisabledSelected.iconColor;
      }
      return theme.optionSelected.iconColor;
    } else {
      if (isDisabled) {
        return theme.optionDisabled.iconColor;
      }
      return theme.option.iconColor;
    }
  })();
  const useDefaultSVGColor = !defaultColorFromTheme;

  if (useDefaultSVGColor) {
    defaultColorFromTheme = theme.base.fontColor;
  }

  const opacity = isFocused ? theme.optionSelected.iconOpacity : theme.option.iconOpacity;

  return { defaultColorFromTheme, opacity, useDefaultSVGColor };
};

export const getReservedField = (
  o: ParameterOption,
  field:
    | 'category'
    | 'icon'
    | '__preserveSVG'
    | '__extraHTML'
    | '__heading'
    | '__actionIcon'
    | '__image'
    /** @deprecated */
    | '__breadcrumbs',
) => {
  switch (field) {
    case 'category':
      if (typeof o.parameter?.category === 'string') {
        return o.parameter?.category;
      }
      break;
    case 'icon':
      if (typeof o.parameter?.icon === 'string') {
        return o.parameter?.icon;
      }
      break;
    case '__actionIcon':
      if (typeof o.parameter?.__actionIcon === 'string') {
        return o.parameter?.__actionIcon;
      }
      break;
    case '__image':
      if (typeof o.parameter?.__image === 'string') {
        return o.parameter?.__image;
      }
      break;
    case '__preserveSVG':
      if (!!o.parameter?.__preserveSVG) {
        return o.parameter?.__preserveSVG;
      }
      break;
    case '__extraHTML':
      if (
        !!o.parameter?.__extraHTML &&
        (typeof o.parameter?.__extraHTML === 'string' || typeof o.parameter?.__extraHTML === 'function')
      ) {
        return o.parameter?.__extraHTML;
      }
      break;
    case '__heading':
      if (!!o.parameter?.__heading && typeof o.parameter?.__heading === 'string') {
        return o.parameter?.__heading;
      }
      break;
    case '__breadcrumbs':
      if (!!o.parameter?.__breadcrumbs && typeof o.parameter?.__breadcrumbs === 'string') {
        return o.parameter?.__breadcrumbs;
      }
      break;
  }
  return undefined;
};

export const isDateTimeOption = (o: ParameterOption) => {
  return o.parameter?.type === 'datetime' && typeof o.parameter?.specificType === 'number';
};

export const isParameterOption = (o: unknown): o is ParameterOption => (o as Option)?.type === 'parameter';

export const isParameterOptionSelected = (o: ParameterOption, currentStep: Step) => {
  const selected = currentStep?.selected?.data;
  if (Array.isArray(selected)) {
    return selected.some((selected) => isEqual(o.parameter, selected));
  } else {
    return isEqual(o.parameter, selected);
  }
};

export const isResourceOption = (o: unknown): o is ResourceOption =>
  (o as ResourceOption)?.type === OptionType.Parameter && (o as ResourceOption)?._resource === true;

export const isUnfurledCommandOption = (o: unknown): o is UnfurledCommandOption =>
  (o as UnfurledCommandOption).type === OptionType.Parameter && (o as UnfurledCommandOption).unfurled === true;

export const onlyShowDuringPowerselect = (o: unknown): boolean => {
  if (isCommandOption(o) && !isUnfurledCommandOption(o)) {
    return Command.isRecordAction(o.command) && !Command.showInDefaultList(o.command);
  }
  return false;
};

export const getOptionUID = (o: unknown): string | undefined => {
  if (isCommandOption(o)) {
    return Command.commandUID(o.command);
  } else if (isResourceOption(o)) {
    const contextKey = o?.category?.contextKey;
    if (!contextKey || !o.label) {
      return;
    }
    const slug = o.label.toLowerCase();
    return `${contextKey}-${slug}`;
  } else {
    return;
  }
};

export const getOptionMetadata = <T extends OptionType>(o: GenericOption<T>): GenericOptionMetadata<T> => {
  const { type, label, value } = o;

  const contextKey = isResourceOption(o) ? o?.category?.contextKey : undefined;
  const uid = getOptionUID(o);

  return {
    type,
    label,
    value,
    contextKey,
    uid,
  };
};

export const getOptionData = <T extends OptionType>(o: GenericOption<T>): any => {
  if (isParameterOption(o)) return o.parameter;
  else if (isCommandOption(o)) return o.command;
  return null;
};
