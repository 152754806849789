/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { useStore } from '../../../hooks/useStore';
import { selectSlashFilterHint, selectSlashFilter } from '../../../store/app/selectors';

interface IProps {
  inputStyles: any;
}

const SlashFilterInput = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const { inputText, slashFilter, slashFilterKeyword } = selectSlashFilter(useStore());

  const hint = selectSlashFilterHint(useStore(), slashFilterKeyword);

  if (!slashFilter) {
    return null;
  } else {
    return (
      <span
        className="rich-hint-wrapper"
        style={{
          display: 'flex',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none',
          color: 'rgba(0, 0, 0, 0.35)',
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <span
          className="rich-text-filler"
          style={{
            visibility: 'hidden',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            pointerEvents: 'none',
            whiteSpace: 'pre',
            fontFamily: theme.input.fontFamily,
            fontSize: theme.input.fontSize,
          }}
        >
          {inputText}
        </span>
        <span
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'transparent',
            borderBottom: `1px solid ${theme.base.primary}`,
            boxShadow: 'none',
            pointerEvents: 'none',
            whiteSpace: 'pre',
            fontFamily: theme.input.fontFamily,
            fontSize: theme.input.fontSize,
          }}
        >
          {slashFilter}
        </span>
        <input
          className="rich-hint"
          css={props.inputStyles}
          style={{
            pointerEvents: 'none',
            background: 'transparent',
            width: '100%',
            outline: 'none',
            borderBottom: `1px solid transparent`,
            boxShadow: 'none',
            padding: 0,
            margin: 0,
            caretColor: 'transparent',
          }}
          placeholder={hint}
          tabIndex={-1}
        />
      </span>
    );
  }
};

export default SlashFilterInput;
