/** @jsx jsx */
import React, { CSSProperties } from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Z from '@commandbar/internal/client/Z';
import type { ITheme } from '@commandbar/internal/client/theme';
import { ReactComponent as CloseIcon } from './close_icon.svg';

interface CloseButtonProps {
  onClick: React.MouseEventHandler;
  styleOverrides?: CSSProperties;
}

const CloseButton = ({ onClick, styleOverrides }: CloseButtonProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const closeIconCSS = {
    width: '12px',
    height: '12px',
    cursor: 'pointer',
    transition: `opacity ${theme.main.transitionTime} ease-in`,
    zIndex: Z.Z_COMMANDBAR - 1,
    flexShrink: 0,
    alignSelf: 'flex-start',
    color: theme.nudges.closeButtonColor,

    '&:hover': {
      color: theme.nudges.closeButtonHoverColor,
    },
  };

  return (
    <CloseIcon
      css={closeIconCSS}
      style={{
        ...styleOverrides,
      }}
      onClick={onClick}
    />
  );
};

export default CloseButton;
