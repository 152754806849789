/** @jsx jsx  */
import { ArrowRight, Ai } from '@commandbar/design-system/icons/react';
import React from 'react';
import { jsx, css, keyframes } from '@emotion/core';

const glowPulseEffect = keyframes`
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1.025);
    }
  `;

const borderPulseEffect = keyframes`
    to {
      transform: rotate(360deg);
    }
  `;

const styles = {
  glowWrapper: css`
    width: inherit;
    display: inline-flex;
    transform: translateY(-50%);
    transition: all 0.25s;
    &:after {
      content: '';
      position: absolute;
      border-radius: 8px;
      left: 0;
      top: 0;
      background-color: #b02aef;
      width: 100%;
      height: 100%;
      box-shadow: -4px 4px 12px -2px #b02aef, 4px -4px 12px -2px #0051ff;
      animation: ${glowPulseEffect} 2s linear infinite alternate;
    }
  `,
  btnWrapper: css`
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    display: inline-flex;
    border-radius: 8px;
    z-index: 1;
    transition: all 0.25s;
    top: 0px;

    &:after {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      width: 250px;
      height: 250px;
      top: -112px;
      left: -84px;
      /* 	Controls border pulse color */
      background: linear-gradient(90deg, #ffffff 49.69%, #aa56ff 51.75%, #aa56ff 53.26%, #ffffff 55.32%);
      animation: ${borderPulseEffect} linear 4.5s infinite;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all 0.25s;
    }

    &:active {
      top: 2px;
    }
  `,
  btn: css`
    width: 100%;
    height: 32px;
    background: linear-gradient(
      94.91deg,
      #1f1f1f 12.99%,
      #262a49 25.99%,
      #1f1f1f 35.82%,
      #272c5e 73.6%,
      #1f1f1f 92.23%
    );
    color: #d2d2d2;
    margin: 1px;
    border-radius: 6.5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    gap: 4px;
    transition: all 0.25s;
    position: relative;

    &:hover {
      background-color: #1f1f1f;
      color: #fff;
      transition: all 0.25s;
    }
  `,
  btnIcon: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  btnText: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    background: linear-gradient(360deg, #e1c4ff 21.72%, #ffffff 46.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  `,
};

const combinedStyles = `
.commandbar-ask-with-ai-btn-icon--arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 0;
  transition: all 0.25s;

  &:hover {
    opacity: 1;
    width: 10px;
    transition: all 0.25s;
  }
}
.commandbar-ask-with-ai-btn-wrapper:hover
  .commandbar-ask-with-ai-btn-icon--arrow {
  opacity: 1;
  width: 10px;
  transition: all 0.25s;
}
`;

const AskAIButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <React.Fragment>
      <style>{combinedStyles}</style>
      <div
        className={'commandbar-ask-with-ai-btn-wrapper'}
        css={styles.glowWrapper}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick();
          }
        }}
      >
        <div css={styles.btnWrapper}>
          <div css={styles.btn}>
            <div css={styles.btnIcon}>
              <Ai width="12" height="14" role="img" focusable={false} />
            </div>
            <div css={styles.btnText}>Ask AI</div>
            <div className={'commandbar-ask-with-ai-btn-icon--arrow'}>
              <ArrowRight height={10} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AskAIButton;
