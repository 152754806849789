import React from 'react';
import RcTooltip from 'rc-tooltip';
import { useCommandBarContainer } from '../hooks/useCommandBarContainer';
import Z from '@commandbar/internal/client/Z';

interface CustomProps {
  disabled?: boolean;
}

const Tooltip = (props: CustomProps & React.ComponentProps<typeof RcTooltip>) => {
  const { root } = useCommandBarContainer();

  if (!!props.disabled) return props.children ?? null;
  if (!root) return null;
  const rcTooltipContainer = root.getElementById('commandbar-rc-tooltip-container');
  if (!rcTooltipContainer) return null;

  return (
    <RcTooltip
      prefixCls="commandbar-tooltip"
      getTooltipContainer={() => rcTooltipContainer}
      overlayStyle={{ zIndex: Z.Z_INDEX_MAX }}
      {...props}
    />
  );
};

export default Tooltip;
