import React from 'react';
import { ResourceOption } from '../../engine/option';
import Icon from '@commandbar/internal/client/Icon';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { getOptionIconColorBase, getRecordCategoryIcon, getReservedField } from '../../store/engine';
import { getIconSize } from './helpers';
import { useStore } from '../../hooks/useStore';

interface Props {
  isFocused: boolean;
  option: ResourceOption;
}

const ResourceOptionIcon = ({ isFocused, option }: Props) => {
  const _ = useStore();
  const theme = useCommandBarTheme();
  const preserveSVG = !!getReservedField(option, '__preserveSVG');

  const { icon: category_icon, icon_color: category_icon_color } = getRecordCategoryIcon(option);
  const { defaultColorFromTheme, opacity, useDefaultSVGColor } = getOptionIconColorBase(theme, isFocused, false);

  const style = { defaultColorFromTheme, opacity, minWidth: getIconSize(_, theme) };

  const userDefinedIcon = getReservedField(option, 'icon');

  if (!!userDefinedIcon) {
    return (
      <Icon
        icon={userDefinedIcon}
        default={<div style={{ minWidth: getIconSize(_, theme), display: 'inline-block' }} />}
        style={style}
        useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
        size={getIconSize(_, theme)}
      />
    );
  }

  return (
    <Icon
      icon={category_icon}
      color={category_icon_color}
      default={<div style={{ minWidth: getIconSize(_, theme), display: 'inline-block' }} />}
      style={style}
      useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
      size={getIconSize(_, theme)}
    />
  );
};

export default ResourceOptionIcon;
