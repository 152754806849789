/** @jsx jsx */

import { jsx } from '@emotion/core';
/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';
import ExecutionPath from '../../engine/ExecutionPath';

import Control from './input/Control';
import { getTriggerKey, osControlKey, isMobile } from '@commandbar/internal/util/operatingSystem';
import MenuWrapper from './menu/MenuWrapper';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { useStore } from '../../hooks/useStore';
import { useAction } from '../../hooks/useAction';
import * as App from '../../store/app/actions';
import * as Engine from '../../store/engine/actions';
import { currentOptionHasArguments as processRightArrow } from '../../store/engine';
import { selectMenuIsOpen, selectSlashFilterHint } from '../../store/app';
import { getEditorDashboards } from '../EditorDashboard';
import SearchTabs from './SearchTabs';
import { StepType } from '../../engine/step/Step';
import Footer from './footer/Footer';
import useResizeObserver from '../../hooks/useResizeObserver';
import { HeightAdjustableContainer } from './HeightAdjustableContainer';
import TabDescription from './TabDescription';
import { submitLongTextInput, submitMultiSelect } from '../../store/engine/actions';
import { ExecuteStep } from '../../engine/step';
import { useCommandBarContainer } from '../../hooks/useCommandBarContainer';
import CustomMenuHeader from './CustomMenuHeader';
import CustomTabHeader from './CustomTabHeader';

/*******************************************************************************/
/* Interfaces
/*******************************************************************************/

export interface ISelectHandle {
  onInputChange: (newInput: string) => void;
  selectCurrentOption: () => void;
  focus: () => void;
  blur: () => void;
}

const CommandSelect: React.ForwardRefRenderFunction<ISelectHandle> = (props, ref) => {
  const { root } = useCommandBarContainer();
  const handleInputChange = useAction(Engine.handleInputChange);
  const selectCurrentOption = useAction(App.selectCurrentOption);
  const { engine } = useStore();
  const currentStep = ExecutionPath.currentStepAndIndex(engine).currentStep;

  const inputRef: any = React.useRef();
  const controlWrapperRef = React.useRef<HTMLElement | null>(null);

  const setControlWrapperRef: React.RefCallback<HTMLElement> = React.useCallback((element: HTMLElement) => {
    if (element) {
      controlWrapperRef.current = element;
    }
  }, []);

  const { theme }: { theme: ITheme } = useTheme();

  // Block option hover when a user presses a key. Copied from react-select.
  // This avoids the focused option from changing when options change because of passive mouse position
  // https://github.com/JedWatson/react-select/blob/b0411ff46bc1ecf45d9bca4fb58fbce1e57f847c/packages/react-select/src/Select.js#L1245
  const blockOptionHoverRef = React.useRef(false);
  const controlKey = React.useMemo(osControlKey, []);
  const inlineFormFactor = engine.formFactor.type === 'inline';
  const menuIsOpen = selectMenuIsOpen(useStore());
  const editorDashboards = getEditorDashboards(theme.main.background);

  const focusInput = React.useCallback(() => {
    // NOTE: Depending on timing, this can pull focus away from the environment override selector
    // in the SystemPanel component! We avoid this by checking for a special classname.
    if (
      root?.activeElement?.classList?.contains('commandbar__system_panel') ||
      root?.activeElement?.id === 'commandbar-editor'
    ) {
      return;
    }

    setTimeout(() => inputRef?.current?.focus(), 0);
  }, [inputRef]);

  const blurInput = React.useCallback(() => {
    inputRef?.current?.blur();
  }, [inputRef]);

  // Tmp implementation to have the same api as react-select
  React.useImperativeHandle(ref, () => ({
    onInputChange: handleInputChange,
    selectCurrentOption,
    focus: focusInput,
    blur: blurInput,
  }));

  const { height: controlWrapperHeight } = useResizeObserver(controlWrapperRef.current);

  // Autofocus on each render. Copied from react-select.
  React.useEffect(() => {
    if (!isMobile() && (!inlineFormFactor || menuIsOpen)) {
      focusInput();
    }
  });

  const onKeyDown = useAction(
    (_, e: React.KeyboardEvent<HTMLElement>) => {
      const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

      if (_.engine.focusedIndex === -1) {
        // When focused outside of the List ignore any keyboard events except Escape.
        return;
      }

      if (currentStep?.type === StepType.LongTextInput) {
        if (e.key === 'Enter' && getTriggerKey(e)) {
          // construct a new ParamterOption on-the-fly from the entered text
          submitLongTextInput(_, _.engine.inputText);
          e.stopPropagation();
          e.preventDefault();
        }

        return;
      }

      blockOptionHoverRef.current = true;

      if (e.key === 'ArrowDown') {
        App.changeFocus(_, 'down');
        e.stopPropagation();
        e.preventDefault();
      }
      if (e.key === 'ArrowUp') {
        App.changeFocus(_, 'up');
        e.stopPropagation();
        e.preventDefault();
      }

      if (e.key === 'ArrowRight') {
        if (processRightArrow(_)) {
          App.selectCurrentOption(_);
          e.stopPropagation();
          e.preventDefault();
        } else {
          applySlashFilter();
        }
      }

      if (e.key === 'Enter') {
        if (
          !!getTriggerKey(e) &&
          ExecutionPath.currentStepAndIndex(_.engine).currentStep?.type === StepType.MultiSelect
        ) {
          submitMultiSelect(_);
          e.preventDefault();
          e.stopPropagation();
        } else if (_.engine.focusableChildIndex > -1) {
          App.maintainChildFocus(_);
          return;
        } else if (App.tryOpenEditor(_)) {
          e.stopPropagation();
          e.preventDefault();
        } else {
          App.selectCurrentOption(_, e);
          e.stopPropagation();
          e.preventDefault();
        }
      }

      /*
       *  Emacs arrow key shortcuts:
       *      - ctrl + n (down)
       *      - ctrl + p (up)
       * */
      if (e.key === 'n' && e.ctrlKey && controlKey.toLowerCase() !== 'ctrl') {
        App.changeFocus(_, 'down');
        e.stopPropagation();
        e.preventDefault();
      }

      if (e.key === 'p' && e.ctrlKey && controlKey.toLowerCase() !== 'ctrl') {
        App.changeFocus(_, 'up');
        e.stopPropagation();
        e.preventDefault();
      }

      if (e.key === 'Tab') {
        if (applySlashFilter()) {
          return;
        }

        if (e.getModifierState('Shift')) {
          if (App.changeFocusWithTab(_, 'up')) {
            return;
          }
        } else {
          if (App.changeFocusWithTab(_, 'tab')) {
            return;
          }
        }
        e.stopPropagation();
        e.preventDefault();
      }

      // https://github.com/JedWatson/react-select/issues/3562#issuecomment-518841754
      if (e.key === 'Home') {
        e.stopPropagation();
        e.preventDefault();
        // eslint-disable-next-line commandbar/no-event-target
        if (e.target instanceof HTMLInputElement) {
          if (e.shiftKey) {
            e.target.selectionStart = 0;
          } else {
            e.target.setSelectionRange(0, 0);
          }
        }
      }

      if (e.key === 'End') {
        e.stopPropagation();
        e.preventDefault();
        // eslint-disable-next-line commandbar/no-event-target
        if (e.target instanceof HTMLInputElement) {
          const len = e.target.value.length;
          if (e.shiftKey) {
            e.target.selectionEnd = len;
          } else {
            e.target.setSelectionRange(len, len);
          }
        }
      }

      App.handleKeyDown(_, e);

      function applySlashFilter() {
        // eslint-disable-next-line commandbar/no-event-target
        if (e.target instanceof HTMLInputElement) {
          const caretIsAtTextEnd = (() => {
            const isNonSelectableType = e.target.selectionEnd === null;
            const caretIsAtTextEnd = isNonSelectableType || e.target.selectionEnd === e.target.value.length;

            return caretIsAtTextEnd;
          })();

          if (caretIsAtTextEnd) {
            const hint = selectSlashFilterHint(_);
            if (hint !== '') {
              Engine.handleInputChange(_, _.engine.rawInput + hint);
              e.preventDefault();
              e.stopPropagation();
              return true;
            }
          }
        }
        return false;
      }
    },
    [blockOptionHoverRef, editorDashboards],
  );

  const onOptionHover = useAction(
    (_, optionIndex: number) => {
      if (blockOptionHoverRef.current) {
        blockOptionHoverRef.current = false;
        return;
      }
      if (optionIndex === _.engine.focusedIndex) {
        return;
      }
      _.engine.focusedIndex = optionIndex;
    },
    [blockOptionHoverRef],
  );

  const closeBarAndReset = useAction(App.closeBarAndReset);
  const handleEscape = useAction((_) => {
    Engine.rollback(_);
    Engine.handleInputChange(_, '');
  });

  /******************************************************************/

  /*
   * Explanation for use of keyup, keydown, and keypress event handlers:
   *  https://www.loom.com/share/ea2e91b99ce545d3a30a25da3823992a
   */
  const _ = useStore();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{ width: '100%' }}
      onKeyPressCapture={(e) => {
        if (e.key !== 'Escape') return;
        e.stopPropagation();
      }}
      onKeyUpCapture={(e) => {
        if (e.key !== 'Escape') return;
        e.stopPropagation();

        const { currentStepIndex } = ExecutionPath.currentStepAndIndex(engine);
        const lastStep = ExecutionPath.lastStep(engine);

        if (currentStepIndex === 0) {
          closeBarAndReset();
        } else if (lastStep.type === 'execute' && (lastStep as ExecuteStep).triggeredByShortcut) {
          closeBarAndReset();
        } else {
          handleEscape();
        }
      }}
      onKeyDownCapture={(e) => {
        if (e.key === 'Escape') {
          e.stopPropagation();
        }
      }}
      onKeyDown={onKeyDown}
    >
      <Control inputRef={inputRef} setControlWrapperRef={setControlWrapperRef} />
      {menuIsOpen ? (
        <HeightAdjustableContainer controlWrapperHeight={controlWrapperHeight}>
          <CustomTabHeader />
          {currentStep?.type === StepType.Base &&
            !currentStep?.resource &&
            !(engine.organization?.tab_direction === 'vertical') && <SearchTabs direction="horizontal" />}
          <TabDescription />
          <CustomMenuHeader />
          <MenuWrapper onOptionHover={onOptionHover} />
          <Footer />
        </HeightAdjustableContainer>
      ) : null}
    </div>
  );
};

export default React.forwardRef(CommandSelect);
