import React, { FunctionComponent } from 'react';

import { useStore } from '../hooks/useStore';
import { StepType } from '../engine/step/Step';
import RenderCustomComponent from './RenderCustomComponent';

const DefaultState: FunctionComponent = () => {
  const { userDefinedCustomComponents } = useStore();

  const component = userDefinedCustomComponents.defaultState({ step: StepType.Base });

  if (typeof component === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: component }} />;
  } else if (component) {
    return <RenderCustomComponent component={component} />;
  }

  return null;
};

export default DefaultState;
