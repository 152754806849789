import React from 'react';
import Mousetrap from '@commandbar/internal/client/mousetrap_fork';
import { KeyRecorder } from '@commandbar/internal/client/KeyRecorder';

const useKeypressRecorder = (props: { onFinish: (val: string | undefined) => void }) => {
  const [keys, setKeys] = React.useState('');

  React.useEffect(() => {
    Mousetrap.pause();
    const x = new KeyRecorder(new Mousetrap(document.body, true), setKeys, props.onFinish);
    return () => {
      x.stop();
      Mousetrap.unpause();
    };
  }, [props.onFinish]);

  return keys;
};

export default useKeypressRecorder;
