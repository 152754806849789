import { assign, createMachine, Interpreter, InterpreterStatus } from 'xstate';
import { toast } from 'react-hot-toast';
import isEqual from 'lodash/isEqual';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { getElement } from '@commandbar/internal/util/dom';
import Logger from '@commandbar/internal/util/Logger';

import { renderNudge } from '../../../components/nudges/RenderNudge';
import * as Reporting from '../../../analytics/Reporting';
import { runBooleanExpression } from '../../../engine/option/OptionValidate';
import { getCommandById } from '../selectors';
import { executeCommand, updateEndUserStore } from '../actions';
import { clickExecutable, linkExecutable, openChatExecutable } from '../steps/executables';

import type { EngineState } from '../state';
import type {
  INudgeStepContentButtonBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { initCommandOption } from '../../../engine/option/CommandOption';
import helpdocService from '../../../services/helpdocService';
import { openEditorIfLoaded } from '../../util/editorUtils';

type CommonContext = NudgeContext | NudgePreviewContext;

const closeModalNudge = (_: EngineState) => {
  _.engine.currentModalNudge = null;
};
const getCommandByIdIncludingHelpdocCommands = (_: EngineState) => async (commandId: string) => {
  let command = getCommandById(_.engine, commandId) ?? null;

  if (!command) {
    if (_.engine.organization?.id)
      command = await helpdocService.getHelpdocCommand(_.engine.organization?.id, commandId);
  }

  return command;
};

const fireStepExitAction =
  (_: EngineState) =>
  async ({ nudge, currentStep }: CommonContext, event: NudgeEvents) => {
    const step = nudge.steps[currentStep];
    const action = step.content.find(
      (block): block is INudgeStepContentButtonBlockType =>
        block.type === 'button' && event.type === 'advance' && block.meta?.button_type === event?.button_type,
    )?.meta?.action;

    if (action?.type === 'click' && action.value.length) {
      if (step.form_factor.type === 'pin' && event.type === 'advance' && !!event.isPinClick) {
        const anchor = getElement(step.form_factor.anchor);
        const clickTarget = getElement(action.value);

        // if anchor contains the event target, do not execute the click action
        if (anchor?.contains(clickTarget as Node)) {
          return;
        }
      }

      clickExecutable(_.engine, {
        type: action.type,
        value: [action.value],
      })();
    } else if (action?.type === 'execute_command') {
      const command = await getCommandByIdIncludingHelpdocCommands(_)(action.meta.command);

      if (command) {
        executeCommand(_, command, undefined, undefined, { type: 'nudge', id: nudge.id });
      }
    } else if (action?.type === 'link') {
      linkExecutable(_.engine, action)();
    } else if (action?.type === 'open_chat') {
      if (isStandaloneEditor) {
        const upperCase = `${action?.meta?.type.charAt(0).toUpperCase()}${action?.meta?.type.slice(1)}`;
        _.engine.callbacks['__standalone-editor-cb_hh_cta'](upperCase);
      } else {
        openChatExecutable(action);
      }
    }
  };

const executeHelpDocCommand =
  (_: EngineState) =>
  async ({ nudge, currentStep }: CommonContext) => {
    const step = nudge.steps[currentStep];
    const commandId = step.content.find(
      (block): block is INudgeStepContentHelpDocBlockType => block.type === 'help_doc_command',
    )?.meta.command;

    if (commandId) {
      const command = await getCommandByIdIncludingHelpdocCommands(_)(commandId);
      if (command) {
        executeCommand(_, command, undefined, undefined, { type: 'nudge', id: nudge.id });
      }
    }
  };

const passesConditions = (_: EngineState, nudge: INudgeType) => {
  if (!nudge.is_live && !_.engine.isAdmin) {
    return false;
  }

  const passesAudienceConditions = () => {
    if (nudge.audience) {
      switch (nudge.audience.type) {
        case 'all_users':
          return true;
        case 'rule_expression':
          return runBooleanExpression(nudge.audience.expression, _.engine, '').passed;
        default:
          return false;
      }
    }
    return true;
  };

  const passesPageConditions = () => {
    return !nudge.show_expression || runBooleanExpression(nudge.show_expression, _.engine, '').passed;
  };

  return passesAudienceConditions() && passesPageConditions();
};

const checkTriggerMatch = (nudgeTrigger: INudgeType['trigger'], trigger?: INudgeType['trigger']) => {
  if (trigger?.type === 'when_page_reached' && nudgeTrigger.type === 'when_page_reached') {
    return trigger.meta.url.includes(nudgeTrigger.meta.url);
  }

  return isEqual(nudgeTrigger, trigger);
};

const stepsRemaining = ({ currentStep, nudge }: CommonContext) => currentStep < nudge.steps.length - 1;
const stepHasTargetElement = ({ nudge, currentStep }: CommonContext) => {
  const step = nudge.steps[currentStep];
  return step.form_factor.type === 'pin' && !!step.form_factor.anchor.length;
};

export const isPreviewService = (service: NudgeService | NudgePreviewService): service is NudgePreviewService =>
  service.id === NUDGE_PREVIEW_SERVICE_ID;

export type NudgeContext = {
  currentStep: number;
  nudge: INudgeType;
  passedConditions: boolean;
  nudgeSeen: boolean;
  nudgeSeenThisSession: boolean;
  nudgeSeenThisSessionTs: number[];
  nudgeInteracted: boolean;
  triggerQueue: INudgeType['trigger'][];
  hasSyncedRemote: boolean;
  trigger?: INudgeType['trigger'];
  lastTriggeredTs?: number;
  seenTs?: number[];
};

export type NudgeEvents =
  | { type: 'refresh'; nudge: INudgeType }
  | { type: 'reset state'; step: number }
  | {
      type: 'trigger';
      trigger: INudgeType['trigger'];
      hasSyncedRemote: boolean;
      currentStep?: number;
      nudgeSeen?: boolean;
      nudgeInteracted?: boolean;
    }
  | { type: 'force trigger nudge' }
  | { type: 'found target element' }
  | { type: 'command available' }
  | { type: 'advance'; isPinClick?: boolean; button_type?: 'primary' | 'secondary' }
  | { type: 'help doc clicked' }
  | { type: 'dismiss' }
  | { type: 'force dismiss' };

export type NudgeService = Interpreter<NudgeContext, any, NudgeEvents, { value: any; context: NudgeContext }, any>;

export const NUDGE_SERVICE_ID = 'nudge';

const nudgeMachine = (_: EngineState, nudge: INudgeType) => {
  const nudgesSeenFromStorage = LocalStorage.get('nudgesSeen', 'false');

  const context: NudgeContext = {
    currentStep: 0,
    nudge,
    passedConditions: false,
    // initial read from localStorage for backward compatibility
    nudgeSeen: typeof nudgesSeenFromStorage === 'string' ? nudgesSeenFromStorage.includes(`${nudge.id}`) : false,
    nudgeSeenThisSession: false,
    nudgeSeenThisSessionTs: [],
    nudgeInteracted:
      typeof nudgesSeenFromStorage === 'string' ? nudgesSeenFromStorage.includes(`${nudge.id}#interacted`) : false,
    triggerQueue: [],
    hasSyncedRemote: false,
  };

  return createMachine(
    {
      id: NUDGE_SERVICE_ID,
      initial: 'inactive',
      on: {
        refresh: {
          target: '#nudge',
          actions: 'update nudge',
          cond: 'nudge changed',
          internal: true,
        },
        'reset state': {
          actions: 'reset state',
          target: '#nudge.inactive',
          cond: 'step changed',
          internal: true,
        },
      },
      states: {
        inactive: {
          on: {
            'force trigger nudge': {
              target: 'active',
              cond: 'has steps',
            },

            trigger: {
              target: 'inactive',
              internal: true,
              actions: 'enqueue trigger',
            },
          },

          always: {
            target: 'checking conditions',
            actions: 'set trigger',
            cond: 'remote synced and queued',
          },

          entry: 'remove active nudge',
        },

        'checking conditions': {
          exit: 'set passed conditions',
          always: [
            {
              target: 'active',
              cond: 'conditions met',
            },
            {
              target: 'inactive',
            },
          ],
        },

        active: {
          entry: ['update last triggered time'],

          states: {
            'step shown': {
              entry: ['set active nudge', 'render step', 'mark seen', 'report seen', 'save context'],

              on: {
                advance: [
                  {
                    target: 'checking dependencies',
                    cond: 'steps remaining',

                    actions: ['fire step exit action', 'report interaction', 'advance step', 'save context'],
                  },
                  {
                    target: '#nudge.inactive',
                    actions: [
                      'fire step exit action',
                      'report interaction',
                      'mark interacted',
                      'report completed',
                      'reset step',
                      'save context',
                    ],
                  },
                ],
                trigger: {
                  actions: 'set passed conditions',
                },
                'help doc clicked': [
                  {
                    target: 'checking dependencies',
                    cond: 'steps remaining',

                    actions: ['execute help doc command', 'report interaction', 'advance step', 'save context'],
                  },
                  {
                    target: '#nudge.inactive',
                    actions: [
                      'execute help doc command',
                      'report interaction',
                      'mark interacted',
                      'report completed',
                      'reset step',
                      'save context',
                    ],
                  },
                ],
                dismiss: {
                  target: '#nudge.inactive',
                  actions: ['mark interacted', 'report dismissed', 'save context'],
                },
                'force dismiss': {
                  target: '#nudge.inactive',
                },
              },

              exit: 'close step',
            },

            'checking dependencies': {
              states: {
                'looking for target element': {
                  states: {
                    'element found': {
                      type: 'final',
                    },

                    pending: {
                      always: [
                        {
                          target: 'searching for target element',
                          cond: 'step has target element',
                        },
                        'no target element',
                      ],
                    },

                    'no target element': {
                      type: 'final',
                    },

                    'searching for target element': {
                      invoke: {
                        src: 'search for element',
                      },

                      on: {
                        'found target element': 'element found',
                      },

                      after: {
                        'element search timeout': '#nudge.inactive',
                      },
                    },
                  },

                  initial: 'pending',
                },
                'checking command validity': {
                  states: {
                    'command valid': {
                      type: 'final',
                    },

                    pending: {
                      always: [
                        {
                          target: 'checking command',
                          cond: 'step has command',
                        },
                        'no command',
                      ],
                    },

                    'no command': {
                      type: 'final',
                    },

                    'checking command': {
                      invoke: {
                        src: 'check command availability',
                      },

                      on: {
                        'command available': 'command valid',
                      },

                      after: {
                        'command check timeout': '#nudge.inactive',
                      },
                    },
                  },

                  initial: 'pending',
                },
              },

              type: 'parallel',
              onDone: {
                target: 'step shown',
              },
            },
          },

          on: {
            refresh: {
              target: 'active',
              internal: true,
              actions: 'update nudge',
              cond: 'nudge changed',
            },
          },

          initial: 'checking dependencies',
        },
      },
      tsTypes: {} as import('./machine.typegen').Typegen0,
      schema: {
        context: {} as typeof context,
        events: {} as NudgeEvents,
      },
      context,
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        'set active nudge': ({ nudge }) => {
          _.engine.activeNudge = String(nudge.id);
        },
        'remove active nudge': ({ nudge }) => {
          if (_.engine.activeNudge === String(nudge.id)) {
            _.engine.activeNudge = null;
          }
        },
        'enqueue trigger': assign(({ triggerQueue, ...context }, { trigger, ...rest }) => ({
          ...context,
          ...rest,
          triggerQueue: [...(triggerQueue || []), trigger],
        })),
        'update nudge': assign({
          nudge: (_, { nudge }) => nudge,
        }),
        'render step': ({ nudge, currentStep }) => {
          renderNudge(_, nudge, { stepIndex: currentStep, preview: false });
        },
        'fire step exit action': fireStepExitAction(_),

        'execute help doc command': executeHelpDocCommand(_),
        'report seen': ({ nudge, currentStep }) => {
          Reporting.nudgeShown(nudge, currentStep);
        },
        'report interaction': ({ nudge, currentStep }) => {
          Reporting.nudgeStepClicked(nudge, currentStep);
        },
        'report completed': ({ nudge, currentStep }) => {
          Reporting.nudgeCompleted(nudge, currentStep);
        },
        'report dismissed': ({ nudge, currentStep }) => {
          Reporting.nudgeDismissed(nudge, currentStep);
        },
        'close step': ({ nudge, currentStep }) => {
          if (nudge.steps[currentStep].form_factor.type === 'modal') {
            closeModalNudge(_);
          } else {
            toast.remove(`${nudge.id}-${String(nudge.steps[currentStep].id)}`);
          }
        },
        'save context': ({ nudge, currentStep, nudgeSeen, nudgeInteracted, seenTs }) => {
          const contextFromStorage = LocalStorage.get('nudges', false);
          const jsonContextFromStorage = typeof contextFromStorage === 'string' ? JSON.parse(contextFromStorage) : {};

          const updatedContext = {
            ...(typeof jsonContextFromStorage[nudge.id] === 'object' ? jsonContextFromStorage[nudge.id] : {}),
            [nudge.id]: {
              currentStep,
              nudgeSeen,
              nudgeInteracted,
              seenTs,
            },
          };

          if (!_.engine.nudgePreviewService) {
            try {
              updateEndUserStore(_, updatedContext, 'nudges_interactions');
            } catch (e) {
              Logger.error('unable to save', e);
            }
          }
        },
        'set passed conditions': assign(({ nudge }) => ({
          passedConditions: passesConditions(_, nudge),
        })),
        'advance step': assign({
          currentStep: ({ currentStep }) => currentStep + 1,
        }),
        'set trigger': assign(({ triggerQueue, ...context }) => {
          const [newTrigger, ...newTriggerQueue] = triggerQueue;

          return {
            ...context,
            trigger: newTrigger,
            triggerQueue: newTriggerQueue,
          };
        }),
        'update last triggered time': assign(({ lastTriggeredTs }) => ({
          lastTriggeredTs: Date.now() || lastTriggeredTs,
        })),
        'mark seen': assign({
          nudgeSeen: (_) => true,
          nudgeSeenThisSession: (_) => true,
          seenTs: ({ seenTs }) => [...(seenTs ?? []), Date.now()],
          nudgeSeenThisSessionTs: ({ nudgeSeenThisSessionTs }) => [...(nudgeSeenThisSessionTs ?? []), Date.now()],
        }),
        'mark interacted': assign({
          nudgeInteracted: (_) => true,
        }),
        'reset step': assign({
          currentStep: (_) => 0,
        }),
        'reset state': assign({
          currentStep: ({ currentStep }, event) => {
            return event?.step ?? currentStep;
          },
          passedConditions: (_) => false,
        }),
      },
      guards: {
        'remote synced and queued': ({ hasSyncedRemote, triggerQueue }) => hasSyncedRemote && triggerQueue.length > 0,
        'has steps': ({ nudge }) => nudge.steps.length > 0,
        'conditions met': ({
          nudge,
          nudgeSeenThisSession,
          nudgeSeen,
          passedConditions,
          nudgeInteracted,
          trigger,
          currentStep,
        }) => {
          const isPreviewServiceRunning = _.engine.nudgePreviewService?.status === InterpreterStatus.Running;
          const isTriggerMatch = checkTriggerMatch(nudge.trigger, trigger);
          const hasNoSteps = nudge.steps.length < 1;
          const isPastLastStep = currentStep >= nudge.steps.length;

          const shouldSkipEvaluation =
            isPreviewServiceRunning || !isTriggerMatch || hasNoSteps || isStandaloneEditor || isPastLastStep;
          if (shouldSkipEvaluation) {
            return false;
          }

          const hasExceededFrequency = (() => {
            switch (nudge.frequency_limit) {
              case 'no_limit':
                return false;
              case 'once_per_session':
                return nudgeSeenThisSession;
              case 'once_per_user':
                return nudgeSeen;
              case 'until_interaction':
                return nudgeInteracted;
            }
          })();

          if (hasExceededFrequency) {
            return false;
          }

          return (() => {
            const _passesConditions = passesConditions(_, nudge);

            switch (trigger?.type) {
              case 'when_conditions_pass':
                const hasFlipped = !passedConditions && _passesConditions;
                return hasFlipped;
              case 'on_command_execution':
                return _passesConditions;
              case 'on_event':
                return _passesConditions;
              case 'when_page_reached':
                return _passesConditions;
              case 'when_element_appears':
                return _passesConditions;
              default:
                return false;
            }
          })();
        },
        'steps remaining': stepsRemaining,
        'step has target element': stepHasTargetElement,
        'step has command': ({ nudge, currentStep }) =>
          nudge.steps[currentStep].content.find(
            (block): block is INudgeStepContentButtonBlockType => block.type === 'button',
          )?.meta?.action?.type === 'execute_command',
        'nudge changed': ({ nudge }, { nudge: newNudge }) => !isEqual(nudge, newNudge),
        'step changed': ({ currentStep }, { step: newStep }) => !isEqual(currentStep, newStep),
      },
      services: {
        'search for element':
          ({ currentStep, nudge }) =>
          (callback) => {
            const step = nudge.steps[currentStep];

            const interval = setInterval(() => {
              if (step.form_factor.type === 'pin' && step.form_factor.anchor.length) {
                const { anchor } = step.form_factor;

                if (!!getElement(anchor)) {
                  callback('found target element');
                }
              }
            }, 100);

            return () => {
              clearInterval(interval);
            };
          },
        'check command availability':
          ({ currentStep, nudge }) =>
          (callback) => {
            let canceled = false;

            (async () => {
              const action = nudge.steps[currentStep].content.find(
                (block): block is INudgeStepContentButtonBlockType => block.type === 'button',
              )?.meta?.action;

              if (action?.type === 'execute_command') {
                const command = await getCommandByIdIncludingHelpdocCommands(_)(action.meta.command);

                if (!command) return;

                while (!canceled) {
                  const option = initCommandOption(_, command);

                  // NOTE: allow draft commands in "test mode", but command must always be executable for nudge to be shown
                  if (!option.optionDisabled.isDisabled && (command.is_live || _.engine.testMode))
                    callback('command available');

                  await new Promise((resolve) => setTimeout(resolve, 100));
                }
              }
            })();

            return () => {
              canceled = true;
            };
          },
      },
      delays: {
        'command check timeout': 500,
        'element search timeout': 5000,
      },
    },
  );
};

export type NudgePreviewContext = {
  currentStep: number;
  nudge: INudgeType;
};

export type NudgePreviewEvents =
  | { type: 'refresh'; nudge: INudgeType }
  | { type: 'preview nudge'; nudge: INudgeType; currentStep?: number }
  | { type: 'found target element' }
  | { type: 'advance' }
  | { type: 'help doc clicked' }
  | { type: 'dismiss'; stepIndex?: number };

export type NudgePreviewService = Interpreter<
  NudgePreviewContext,
  any,
  NudgePreviewEvents,
  { value: any; context: NudgePreviewContext },
  any
>;

export const NUDGE_PREVIEW_SERVICE_ID = 'nudge-preview';

export const nudgePreviewMachine = (_: EngineState, nudge: INudgeType) => {
  const context: NudgePreviewContext = {
    currentStep: 0,
    nudge,
  };

  return createMachine(
    {
      id: NUDGE_PREVIEW_SERVICE_ID,
      initial: 'inactive',
      states: {
        inactive: {
          on: {
            'preview nudge': {
              target: 'active',
              actions: ['force dismiss nudges', 'set current step', 'update nudge'],
              cond: 'has steps',
            },
          },
        },

        active: {
          initial: 'triggering step',

          states: {
            'triggering step': {
              always: [
                {
                  target: 'step shown',
                },
              ],
            },

            'step shown': {
              entry: 'render step',

              on: {
                advance: [
                  {
                    target: 'triggering step',
                    cond: 'steps remaining',
                    actions: ['fire step exit action', 'advance step'],
                  },
                  {
                    target: '#nudge-preview.complete',
                    actions: ['fire step exit action', 'reset step'],
                  },
                ],

                'help doc clicked': [
                  {
                    target: 'triggering step',
                    cond: 'steps remaining',
                    actions: ['execute help doc command', 'advance step'],
                  },
                  {
                    target: '#nudge-preview.complete',
                    actions: ['execute help doc command', 'reset step'],
                  },
                ],

                'preview nudge': {
                  target: '#nudge-preview.active',
                  actions: ['set current step', 'update nudge'],
                },

                dismiss: {
                  target: '#nudge-preview.complete',
                  cond: 'is running',
                },
              },

              exit: 'close step',
            },
          },

          exit: 'stop preview service',
        },

        complete: {
          entry: 're-open editor',
          always: {
            target: 'inactive',
          },
        },
      },
      tsTypes: {} as import('./machine.typegen').Typegen1,
      schema: {
        context: {} as typeof context,
        events: {} as NudgePreviewEvents,
      },
      context,
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        'force dismiss nudges': () => {
          _.engine.nudgeServices.forEach((service) => {
            service.send({
              type: 'force dismiss',
            });
          });
        },
        'render step': ({ nudge, currentStep }) => {
          renderNudge(_, nudge, { stepIndex: currentStep, preview: true });
        },
        'fire step exit action': fireStepExitAction(_),
        'execute help doc command': executeHelpDocCommand(_),
        'close step': ({ nudge, currentStep }) => {
          if (nudge.steps[currentStep].form_factor.type === 'modal') {
            closeModalNudge(_);
          } else {
            toast.remove(`${nudge.id}-${String(nudge.steps[currentStep].id)}`);
          }
        },
        're-open editor': () => {
          /** This is part of the "Preview" experience. The editor closes when a nudge is previewed and re-opens when the preview is completed. */
          openEditorIfLoaded();
        },
        'advance step': assign({
          currentStep: ({ currentStep }) => currentStep + 1,
        }),
        'set current step': assign({
          currentStep: ({ currentStep }, event) => event.currentStep ?? currentStep,
        }),
        'update nudge': assign({
          nudge: (_, { nudge }) => nudge,
        }),
        'reset step': assign({
          currentStep: (_) => 0,
        }),
        'stop preview service': () => {
          _.engine.nudgePreviewService?.stop();
        },
      },
      guards: {
        'has steps': ({ nudge }) => nudge.steps.length > 0,
        'steps remaining': stepsRemaining,
        'is running': ({ currentStep }, event) =>
          typeof event?.stepIndex === 'undefined' || currentStep === event.stepIndex,
      },
    },
  );
};

export default nudgeMachine;
