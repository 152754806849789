import { EngineState } from '../state';
import { ParameterOption } from '../../../engine/option';
import { StepUpdater } from '../actions';
import ExecutionPath from '../../../engine/ExecutionPath';
import { Step } from '../../../engine/step';
import { selectStep } from '../steps';
import { StepType } from '../../../engine/step/Step';

export const chooseParameterOption = (_: EngineState, o: ParameterOption, updateSteps: StepUpdater) => {
  const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(_.engine);

  if (currentStep === undefined) {
    throw new Error('Invalid parameter step');
  }

  /**
   * SelectStep:
   *  - Add this parameter to {state.selected}
   *  - Fulfill immediately
   */
  switch (currentStep.type) {
    case StepType.Dashboard:
    case StepType.Select:
    case StepType.TextInput:
    case StepType.LongTextInput: {
      // @IMPROVEMENT: Can create a helper to loop through steps and edit one of them
      const steps = _.engine.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return selectStep(step, o);
        }
        return step;
      });

      const updatedState = {
        ..._.engine,
        steps,
      };

      if (_.engine.simulation) {
        return updatedState;
      } else {
        updateSteps(steps);
        return;
      }
    }
    case StepType.MultiSelect: {
      const steps = _.engine.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return selectStep(step, o);
        }
        return step;
      });

      const updatedState = {
        ..._.engine,
        steps,
      };

      if (_.engine.simulation) {
        return updatedState;
      } else {
        // This is the only case where rebase and fulfill should _not_ occur.
        updateSteps(steps, false);
        return;
      }
    }
    default:
      throw new Error('Unknown parameter step');
  }
};
