/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import { SurveyResponseEvent } from '@commandbar/internal/client/EventHandler';
import { ITheme } from '@commandbar/internal/client/theme';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { useTheme } from 'emotion-theming';
import { ChangeEventHandler, useState } from 'react';
import { jsx } from '@emotion/core';

const TextInputBlock = ({
  setSurveyResponse,
  placeholder,
}: {
  setSurveyResponse: (response: SurveyResponseEvent['response']) => void;
  placeholder: string;
}) => {
  const [text, setText] = useState('');
  const { theme }: { theme: ITheme } = useTheme();

  const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value;
    setText(value);

    setSurveyResponse({ type: 'string', value });
  };

  return (
    <textarea
      value={text}
      onChange={handleTextChange}
      placeholder={placeholder}
      onClick={(e) => e.stopPropagation()}
      rows={5}
      style={{
        width: '100%',
        padding: theme.surveyTextInput.padding,
        margin: theme.surveyTextInput.margin,
        color: theme.surveyTextInput.color,
        fontFamily: theme.surveyTextInput.fontFamily,
        fontSize: isMobile() ? '16px' : theme.surveyTextInput.fontSize,
        border: theme.surveyTextInput.border,
        borderRadius: theme.surveyTextInput.borderRadius,
        background: theme.surveyTextInput.background,
        boxShadow: theme.surveyTextInput.boxShadow,
        lineHeight: '16px',
        fontWeight: 400,
        resize: 'none',
      }}
      css={{
        '&:focus': {
          outline: 'none',
        },
        '::placeholder': {
          color: '#A2A2A9',
        },
      }}
    />
  );
};

export default TextInputBlock;
