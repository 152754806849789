import * as t from 'io-ts';

export const PushTriggerV = t.union([
  t.type({ type: t.literal('when_conditions_pass') }),
  t.type({ type: t.literal('when_page_reached'), meta: t.type({ url: t.string }) }),
  t.type({ type: t.literal('on_command_execution'), meta: t.type({ command: t.string }) }),
  t.type({ type: t.literal('on_event'), meta: t.type({ event: t.string }) }),
  t.type({ type: t.literal('when_element_appears'), meta: t.type({ selector: t.string }) }),
  /**
   * Note: this trigger does not have to be set for share linking to work.
   * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
   */
  t.type({ type: t.literal('when_share_link_viewed') }),
]);
