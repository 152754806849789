import React, { CSSProperties } from 'react';
import { SearchSm } from '@commandbar/design-system/icons/react';
import useTheme from '../../hooks/useTheme';

const containerStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '24px 0',
};

const iconContainerStyles: CSSProperties = {
  marginTop: '12px',
  marginBottom: '16px',
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '8px 16px',
  gap: '8px',
  width: '112px',
  overflow: 'hidden',
  background: 'linear-gradient(90deg, #ffedbf 0%, rgba(255, 237, 191, 0) 72.73%)',
  border: '1px solid #a77800',
  borderRadius: '100px',
  color: '#a77800',
};

const iconContainerInnerStyles: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  maskImage: 'linear-gradient(to right, black 0%, transparent 100%)',
};

export const NoSearchResults = () => {
  const { theme } = useTheme();

  const titleStyles: CSSProperties = {
    fontWeight: theme.helpHub.noResultsTitleFontWeight,
    fontSize: theme.helpHub.noResultsTitleFontSize,
    color: theme.helpHub.noResultsTitleColor,
    textAlign: 'center',
  };

  const descriptionStyles: CSSProperties = {
    fontWeight: theme.helpHub.noResultsDescriptionFontWeight,
    fontSize: theme.helpHub.noResultsDescriptionFontSize,
    color: theme.helpHub.noResultsDescriptionColor,
    textAlign: 'center',
  };

  return (
    <div style={containerStyles}>
      <div style={iconContainerStyles}>
        <div style={iconContainerInnerStyles}>
          <SearchSm width={32} />
          <SearchSm width={32} />
          <SearchSm width={32} />
        </div>
      </div>
      <div style={titleStyles}>Hmm. No results found.</div>
      <div style={descriptionStyles}>Try searching with a question instead.</div>
    </div>
  );
};
