import type { EngineState } from '../engine/state';
import type { INudgeType, IChecklist } from '@commandbar/internal/middleware/types';

export * from './options/helpers';
export * from './steps/helpers';

export const extractSlashFilter = (input: string) => {
  const words = input.split(' ');

  const lastWord = words.pop();

  if (!!lastWord && lastWord.startsWith('/')) {
    const slashIndex: number =
      words.reduce((previousValue, currentValue) => previousValue + currentValue.length, 0) + words.length;
    return {
      inputText: input.substring(0, slashIndex),
      slashFilter: lastWord,
      slashFilterKeyword: lastWord.substring(1),
    };
  } else {
    return { inputText: input, slashFilter: '', slashFilterKeyword: '' };
  }
};

/**
 * A Nudge/Checklist is available to the end user if
 * - it is live
 * - or if it is NOT live, then the user must be an admin.
 */
export const isAvailableToEndUser = (_: EngineState, item: IChecklist | INudgeType): boolean =>
  item.is_live || _.engine.isAdmin;
