import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { EngineState } from '../state';
import { EndUserStore } from './state';

export const commandUID = (command: number | string | undefined) => {
  if (!!command) {
    return `${command}`;
  }
  return '';
};

export const recentUIDs = (recents: EndUserStore['data']['recents']): string[] => {
  return Array.from(new Set(recents.map((r) => r.optionUID)));
};

export const isRemoteEnduserStoreEnabled = (engine: EngineState['engine']) => {
  const canDoNonVerified = !engine.organization?.force_end_user_identity_verification;

  return !!engine.endUser && !isStandaloneEditor && (!!engine.endUser.hmac || canDoNonVerified);
};
