import React from 'react';
import { ResourceOption } from '../../engine/option';
import chroma from 'chroma-js';
import Tooltip from '../Tooltip';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import Tag from '../Tag';
import { MultiCommandsIndicator } from '../../engine/option/MultiCommandsIndicator';
import * as App from '../../store/app/actions';
import { useAction } from '../../hooks/useAction';

interface Props {
  isFocused: boolean;
  option: ResourceOption;
}

const ResourceOptionAddOn = ({ isFocused, option }: Props) => {
  const theme = useCommandBarTheme();

  const selectOption = useAction((_, e: React.KeyboardEvent | React.MouseEvent) => {
    e.shiftKey = true;
    App.selectOption(_, option, undefined, e);
    e.stopPropagation();
    e.preventDefault();
  });

  if (!isFocused || option.recordActions.length <= 1) {
    return null;
  }

  const tagColor = chroma('#fff');
  const tagStyle = { height: '20px', minWidth: '20px', padding: '0 4px' };

  return (
    <Tooltip
      overlay={
        <div
          style={{
            fontFamily: theme.main.fontFamily,
            fontSize: theme.main.fontSize,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>Show all commands</div>
          <div>
            <Tag color={tagColor} style={tagStyle}>
              Shift
            </Tag>
            +
            <Tag color={tagColor} style={tagStyle}>
              Enter
            </Tag>{' '}
          </div>
        </div>
      }
      placement="right"
      align={{ offset: [10, 0] }}
    >
      <div
        tabIndex={0}
        role="button"
        onClick={selectOption}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            selectOption(e);
          }
        }}
      >
        <MultiCommandsIndicator commandsNumber={option.recordActions.length} />
      </div>
    </Tooltip>
  );
};

export default ResourceOptionAddOn;
