import { EngineState } from '../state';
import { CommandOption, ResourceOption, UnfurledCommandOption } from '../../../engine/option';
import ExecutionPath from '../../../engine/ExecutionPath';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { Step } from '../../../engine/step';
import * as EngineActions from '../actions';
import { selectStep, fulfillStep, preSelectIfApplicable } from '../steps';
import { initSelectStep } from '../../../engine/step/SelectStep';
import { initMultiSelectStep } from '../../../engine/step/MultiSelectStep';
import { initLongTextInputStep } from '../../../engine/step/LongTextInputStep';
import { initTextInputStep } from '../../../engine/step/TextInputStep';
import { initExecuteStep } from '../../../engine/step/ExecuteStep';
import { StepType } from '../../../engine/step/Step';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import { isUnfurledCommandOption } from './helpers';
import { getSortedArgs } from './command-option-selectors';
import { initDashboardStep } from '../../../engine/step/DashboardStep';

import type { ExecutionEventSource } from '@commandbar/internal/client/EventHandler';

export const chooseCommandOption = (
  _: EngineState,
  option: CommandOption | UnfurledCommandOption,
  triggeredByShortcut?: boolean,
  updateSteps?: EngineActions.StepUpdater,
  executionEventSource?: ExecutionEventSource,
  alternateBehavior?: { openLinkInNewTab?: boolean },
) => {
  const { engine } = _;
  const { currentStepIndex } = ExecutionPath.currentStepAndIndex(engine);
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const activeResource: ResourceOption | null = (() => {
    if (currentStep?.type === StepType.Base && !!currentStep.resource) return currentStep.resource;
    if (isUnfurledCommandOption(option)) return option.resource;
    return null;
  })();

  // Transform command arguments into an array, sorted by order_key
  const sortedArgs = getSortedArgs(option);

  // Turn the arguments into Steps
  const argSteps = sortedArgs
    .map((arg: IStepArgumentType) => {
      switch (arg.type) {
        case 'set':
        case 'context':
          if (arg.input_type === 'multi-select') {
            return initMultiSelectStep(arg);
          } else {
            return initSelectStep(arg);
          }
        case 'provided':
          if (arg.value === 'time') return initSelectStep(arg);
          if (arg.value === 'text') {
            if (arg.input_type === 'longtext') return initLongTextInputStep(arg);
            else return initTextInputStep(arg);
          }
          throw new Error(`Invalid provided arg type`);
        case 'video':
        case 'html':
          return initDashboardStep(arg, option.command);
        default:
          throw new Error('Invalid arg type');
      }
    })
    .map((step: Step) => {
      let newStep = step;

      // If pre-selected value, pre-select it
      if (
        newStep.type === StepType.Select ||
        newStep.type === StepType.MultiSelect ||
        newStep.type === StepType.LongTextInput ||
        newStep.type === StepType.TextInput
      ) {
        newStep = preSelectIfApplicable(_, newStep, activeResource ?? undefined);
      }

      // Pre-fill selected values in generated Steps with the active resource
      if (activeResource) {
        // ParameterOption from context resource
        if (
          step.type === StepType.Select ||
          step.type === StepType.MultiSelect ||
          step.type === StepType.LongTextInput ||
          step.type === StepType.TextInput
        ) {
          if (step.argument !== undefined && step.argument.type === 'context') {
            if (step.argument.value === activeResource.category.contextKey) {
              const _parameterOption = initParameterOption(
                _,
                activeResource.label,
                activeResource.parameter,
                undefined,
                activeResource.detailPreview,
              );
              newStep = fulfillStep({ engine }, selectStep(step, _parameterOption));
            }
          }
        }
      }

      return newStep;
    });

  const updatedSteps = engine.steps.map((step: Step, index: number) => {
    if (currentStepIndex === index) {
      return selectStep(step, option);
    }
    return step;
  });

  const steps = [
    ...updatedSteps,
    ...argSteps,
    initExecuteStep(
      option.command,
      triggeredByShortcut || false,
      executionEventSource || { type: 'bar' },
      alternateBehavior,
    ),
  ];
  const updatedState = {
    ...engine,
    steps,
  };

  if (engine.simulation) {
    return updatedState;
  } else {
    if (updateSteps) updateSteps(steps);
  }
};
