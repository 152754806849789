import * as t from 'io-ts';

export const operators = [
  'is',
  'isNot',
  'isTrue',
  'isFalse',
  'isTruthy',
  'isFalsy',
  'startsWith',
  'endsWith',
  'includes',
  'doesNotInclude',
  'matchesRegex',
  'isGreaterThan',
  'isLessThan',
  'isBefore',
  'isAfter',
  'isDefined',
  'isNotDefined',
  'classnameOnPage',
  'idOnPage',
  'selectorOnPage',
  'classnameNotOnPage',
  'idNotOnPage',
  'selectorNotOnPage',
] as const;

// https://stackoverflow.com/a/65838169
function keyObject<T extends readonly string[]>(arr: T): { [K in T[number]]: null } {
  return Object.fromEntries(arr.map((v) => [v, null])) as any;
}
const OperatorV = t.keyof(keyObject(operators));

export const ConditionV = t.intersection([
  t.type({
    type: t.union([
      t.literal('context'),
      t.literal('url'),
      t.literal('element'),
      t.literal('executions'),
      t.literal('shortcuts'),
      t.literal('last_seen'),
      t.literal('first_seen'),
      t.literal('sessions'),
      t.literal('opens'),
      t.literal('deadends'),
      t.literal('heap'),
    ]),
    operator: OperatorV,
  }),
  t.partial({
    field: t.string,
    value: t.string,
    reason: t.string,
    rule_id: t.union([t.number, t.string]),
  }),
]);

// # rule-expr:
// #  AND ( <rule-expr>, ... ) |
// #  OR  ( <rule-expr>, ... ) |
// #  LITERAL( true ) | LITERAL( false ) |
// #  CONDITION({ "type": "context" | "url" | "element" | "named_rule", "operator": <operator>, "field": <field-name>, "value": <value>, "reason", <reason> })

export const getConditions = (expr: RuleExpression): ExpressionCondition[] => {
  if (expr.type === 'CONDITION') {
    return [expr.condition];
  } else {
    if (expr.type === 'AND' || expr.type === 'OR') {
      return expr.exprs.flatMap(getConditions);
    }

    return [];
  }
};

const _canonicalize = (expr: RuleExpression): RuleExpression | null => {
  if (isCompoundExpression(expr)) {
    const exprs: RuleExpression[] = [];
    expr.exprs.forEach((expr) => {
      const canonicalized = _canonicalize(expr);
      if (canonicalized) {
        exprs.push(canonicalized);
      }
    });
    const canonicalizedExpr = {
      ...expr,
      exprs,
    };

    if (canonicalizedExpr.exprs.length === 0) {
      return null;
    }

    return canonicalizedExpr;
  }

  return expr;
};

export const canonicalize = (expr: RuleExpression): RuleExpression => {
  const canonicalizedExpr = _canonicalize(expr);

  return (
    canonicalizedExpr || {
      type: 'AND',
      exprs: [],
    }
  );
};

export function isCompoundExpression(expr: RuleExpression): expr is RuleExpressionAnd | RuleExpressionOr {
  return expr.type === 'AND' || expr.type === 'OR';
}

export type ExpressionCondition = t.TypeOf<typeof ConditionV> | t.TypeOf<typeof NamedRuleReferenceV>;

export type RuleExpression = RuleExpressionAnd | RuleExpressionOr | RuleExpressionLiteral | RuleExpressionCondition;

export type RuleExpressionAnd = {
  type: 'AND';
  exprs: RuleExpression[];
};

export type RuleExpressionOr = {
  type: 'OR';
  exprs: RuleExpression[];
};

export type RuleExpressionLiteral = {
  type: 'LITERAL';
  value: boolean;
};

export type RuleExpressionCondition = {
  type: 'CONDITION';
  condition: ExpressionCondition;
};

export const RuleExpressionAndV: t.Type<RuleExpressionAnd> = t.recursion('RuleExpressionAndV', () =>
  t.type({
    type: t.literal('AND'),
    exprs: t.array(RuleExpressionV),
  }),
);

export const RuleExpressionOrV: t.Type<RuleExpressionOr> = t.recursion('RuleExpressionOrV', () =>
  t.type({
    type: t.literal('OR'),
    exprs: t.array(RuleExpressionV),
  }),
);

export const RuleExpressionLiteralV: t.Type<RuleExpressionLiteral> = t.recursion('RuleExpressionLiteralV', () =>
  t.type({
    type: t.literal('LITERAL'),
    value: t.boolean,
  }),
);

export const RuleExpressionConditionV: t.Type<RuleExpressionCondition> = t.recursion('RuleExpressionConditionV', () =>
  t.type({
    type: t.literal('CONDITION'),
    condition: t.union([ConditionV, NamedRuleReferenceV]),
  }),
);

export const RuleExpressionV: t.Type<RuleExpression> = t.recursion('RuleExpressionV', () =>
  t.union([RuleExpressionAndV, RuleExpressionOrV, RuleExpressionLiteralV, RuleExpressionConditionV]),
);

export const RuleExpressionTrue: t.TypeOf<typeof RuleExpressionLiteralV> = { type: 'LITERAL', value: true };
export const RuleExpressionFalse: t.TypeOf<typeof RuleExpressionLiteralV> = { type: 'LITERAL', value: false };

export const NamedRuleReferenceV = t.intersection([
  t.type({
    type: t.literal('named_rule'),
    rule_id: t.union([t.number, t.string]),
  }),
  t.partial({ reason: t.string }),
]);

export const RecommendationRuleAlwaysV = t.intersection([
  t.type({
    type: t.literal('always'),
  }),
  t.partial({
    operator: t.union([t.undefined, t.null]),
    field: t.union([t.undefined, t.null]),
    value: t.union([t.undefined, t.null]),
    reason: t.union([t.undefined, t.null]),
    rule_id: t.union([t.number, t.string]),
  }),
]);

export const AvailabilityRuleV = ConditionV;
export const RecommendationRuleV = t.union([RecommendationRuleAlwaysV, ConditionV]);

export const EditorAvailabilityRuleV = t.union([AvailabilityRuleV, NamedRuleReferenceV]);
export const EditorRecommendationRuleV = t.union([RecommendationRuleV, NamedRuleReferenceV]);

export const NamedRuleBaseV = t.type({
  id: t.union([t.number, t.string]),
  name: t.string,
  expression: RuleExpressionV,
});

const NamedRuleAdditionalV = t.type({
  is_audience: t.boolean,
});

export const defaults: t.TypeOf<typeof NamedRuleAdditionalV> = {
  is_audience: false,
};

export const NamedRuleV = t.intersection([NamedRuleBaseV, NamedRuleAdditionalV]);

export type IRuleOperator = t.TypeOf<typeof OperatorV>;

export type IAvailabilityRule = t.TypeOf<typeof AvailabilityRuleV>;
export type IEditorAvailabilityRule = t.TypeOf<typeof EditorAvailabilityRuleV>;

export type IRecommendationRule = t.TypeOf<typeof RecommendationRuleV>;
export type IEditorRecommendationRule = t.TypeOf<typeof EditorRecommendationRuleV>;

export type ICondition = t.TypeOf<typeof ConditionV>;
export type IAvailabilityRuleType = IAvailabilityRule['type'];
export type IRecommendationRuleType = IRecommendationRule['type'];
export type IRule = IAvailabilityRule | IRecommendationRule;
export type IEditorRule = IEditorAvailabilityRule | IEditorRecommendationRule;
export type INamedRule = t.TypeOf<typeof NamedRuleV>;
