import { Ops } from './hasOp';

let i = 0;

export const logChanges = (ops: Ops) => {
  console.group(`state change [${i++}]`);
  ops.forEach((op) => {
    console.log(`${op[0]} ${op[1].join('.')}`);
  });
  console.groupEnd();
};
