import React, { useLayoutEffect, useState } from 'react';
import PlaintextPreview from './PlaintextPreview';
import MarkdownPreview from './MarkdownPreview';
import ReactPreview from './ReactPreview';
import HtmlPreview from './HtmlPreview';
import VideoPreview from './VideoPreview';
import { DetailPreviewObjectType } from '@commandbar/internal/middleware/types';
import { useStore } from '../../hooks/useStore';
import Logger from '@commandbar/internal/util/Logger';
import RenderCustomComponent from '../RenderCustomComponent';
import { InlinePreview } from './InlinePreview';
import { PreviewPopover } from './PopOverPreview';
import * as Reporting from '../../analytics/Reporting';
import { useCommandBarContainer } from '../../hooks/useCommandBarContainer';
import { PreviewEngagementType } from '@commandbar/internal/client/EventHandler';
import { AnalyticsEngagementTracking } from './AnalyticsEngagementTracking';

interface PreviewProps {
  content: DetailPreviewObjectType[];
  title?: string;
  uid?: string;
  focusedIndex: number;
  pageLink?: string;
  optionData: any;
  optionMetadata: any;
  previewRef: React.RefObject<HTMLDivElement>;
  isMounted: boolean;
  shouldRenderChild: boolean;
}

const DetailPreview = (props: PreviewProps) => {
  const { engine } = useStore();
  const [renderType, setRenderType] = React.useState<'inline' | 'popover'>('inline');
  const [positionSetByContent, setPositionSetByContent] = React.useState<boolean>(false);
  const [shouldRenderPreview, setShouldRenderPreview] = React.useState(false);
  const [startTime] = useState<number>(Date.now());
  const previewEventID = `${props?.uid}-${startTime}`;

  const { root } = useCommandBarContainer();

  React.useEffect(() => {
    const renderDelay = window.setTimeout(() => setShouldRenderPreview(true), 100);
    return () => {
      window.clearTimeout(renderDelay);
    };
  }, []);

  useLayoutEffect(() => {
    if (!positionSetByContent) {
      const barElement = root?.getElementById('commandbar-menu__wrapper') ?? null;
      const barWidth = barElement?.offsetWidth || 0;
      setRenderType(barWidth > 575 ? 'inline' : 'popover');
    }
  });

  const reportPreviewEvent = (eventType: string, engagement?: PreviewEngagementType) => {
    const preview = {
      preview_type: props?.content?.[0]?.type,
      form_factor: renderType,
      id: previewEventID,
      source: {
        command: props.optionMetadata.uid,
      },
    };
    switch (eventType) {
      case 'preview_engagement':
        if (!!engagement) {
          return Reporting.previewEngagement({ ...preview }, engagement);
        }
        break;
      case 'preview_shown':
        return Reporting.previewShown({
          ...preview,
          time_shown: (Date.now() - startTime) / 1000,
        });
      case 'next_step_selected':
        return Reporting.nextStepSelected({ ...preview });
      case 'preview_link_open':
        return Reporting.previewLinkOpen({ ...preview });
      default:
        return;
    }
  };

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      if (props.content.length > 0) {
        reportPreviewEvent('preview_shown');
      }
    }, 350);

    // Clean-up function
    return () => {
      clearTimeout(timerId);
    };
  }, [props.uid]);

  React.useEffect(() => {
    props.previewRef.current?.scrollTo && props.previewRef.current?.scrollTo({ top: 0, behavior: 'instant' });
  }, [props.uid]);

  const content: JSX.Element[] = React.useMemo(() => {
    const result: JSX.Element[] = [];

    props.content.forEach((_content: DetailPreviewObjectType, index: number) => {
      const element = (() => {
        if (_content.position) {
          setRenderType(_content.position);
          setPositionSetByContent(true);
        } else {
          setPositionSetByContent(false);
        }

        switch (_content.type) {
          case 'plaintext':
            return <PlaintextPreview value={_content.value} key={`${index}-${props.uid}`} />;
          case 'markdown':
            return <MarkdownPreview value={_content.value} key={`${index}-${props.uid}`} />;
          case 'html':
            return (
              <HtmlPreview
                scrollContainerRef={props.previewRef}
                value={_content.value}
                key={`${index}-${props.uid}`}
                title={props.title}
              />
            );
          case 'react':
            return <ReactPreview value={_content.value} key={`${index}-${props.uid}`} />;
          case 'video':
            return <VideoPreview source={_content.value} key={`${index}-${props.uid}`} />;
          case 'component':
            const component = engine.components[_content.value];
            if (!component) {
              Logger.warn(
                `DetailPreview: Component not found: '${_content.value}' when rendering preview`,
                JSON.parse(JSON.stringify(props.optionData)), // NOTE: JSON.stringify to de-proxyify the object
                JSON.parse(JSON.stringify(props.optionMetadata)), // NOTE: JSON.stringify to de-proxyify the object
              );
              return null;
            }

            return (
              <RenderCustomComponent
                key={index}
                data={props.optionData}
                metadata={props.optionMetadata}
                component={component.component}
              />
            );
          default:
            throw Error('Invalid content type');
        }
      })();

      if (element) {
        result.push(element);
      }
    });
    return result;
  }, [props.content, props.optionData, props.optionMetadata]);

  if (
    !engine.visible ||
    !shouldRenderPreview ||
    (!props.shouldRenderChild && props.content.length === 0) ||
    (renderType === 'popover' && props.content.length === 0)
  ) {
    return null;
  }

  const anchorClickHandler = () => reportPreviewEvent('preview_engagement', 'clicked_link');
  const videoPlayHandler = () => reportPreviewEvent('preview_engagement', 'watched_video');

  return (
    <AnalyticsEngagementTracking anchorClickHandler={anchorClickHandler} videoPlayHandler={videoPlayHandler}>
      {renderType === 'inline' ? (
        <InlinePreview
          contentContainerRef={props.previewRef}
          reportPreviewEvent={reportPreviewEvent}
          content={content}
          title={props.title}
          isMounted={props.isMounted}
        />
      ) : (
        <PreviewPopover
          contentContainerRef={props.previewRef}
          content={content}
          title={props.title}
          reportPreviewEvent={reportPreviewEvent}
          focusedIndex={props.focusedIndex}
          pageLink={props.pageLink}
        />
      )}
    </AnalyticsEngagementTracking>
  );
};

export default DetailPreview;
