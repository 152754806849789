// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Star01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11.2827 3.4533c.2304-.467.3457-.7004.5021-.775a.5.5 0 0 1 .4304 0c.1565.0746.2717.308.5022.775l2.1867 4.43c.068.1378.102.2068.1517.2603a.5016.5016 0 0 0 .1555.113c.0663.0308.1423.042.2944.0642l4.8913.715c.5151.0752.7726.1128.8918.2386a.5.5 0 0 1 .1327.4094c-.0227.1718-.2091.3534-.582.7166l-3.5381 3.446c-.1102.1074-.1654.1612-.201.2251a.4994.4994 0 0 0-.0595.183c-.0088.0726.0042.1485.0302.3002l.8349 4.8674c.088.5134.132.7701.0493.9224a.5.5 0 0 1-.3482.253c-.1705.0316-.401-.0897-.862-.3321l-4.3727-2.2996c-.1363-.0716-.2044-.1074-.2762-.1215a.4983.4983 0 0 0-.1924 0c-.0718.0141-.1399.0499-.2761.1215l-4.3728 2.2996c-.461.2424-.6915.3637-.862.3321a.5001.5001 0 0 1-.3482-.253c-.0827-.1523-.0387-.409.0494-.9224l.8348-4.8674c.026-.1517.039-.2276.0302-.3002a.4994.4994 0 0 0-.0595-.183c-.0356-.0639-.0907-.1177-.201-.2251l-3.538-3.446c-.373-.3632-.5594-.5448-.582-.7166a.5.5 0 0 1 .1327-.4094c.1191-.1258.3767-.1634.8917-.2387l4.8913-.715c.1521-.0222.2282-.0333.2944-.064a.5002.5002 0 0 0 .1555-.1131c.0497-.0535.0837-.1225.1518-.2603l2.1867-4.43Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.2827 3.4533c.2304-.467.3457-.7004.5021-.775a.5.5 0 0 1 .4304 0c.1565.0746.2717.308.5022.775l2.1867 4.43c.068.1378.102.2068.1517.2603a.5016.5016 0 0 0 .1555.113c.0663.0308.1423.042.2944.0642l4.8913.715c.5151.0752.7726.1128.8918.2386a.5.5 0 0 1 .1327.4094c-.0227.1718-.2091.3534-.582.7166l-3.5381 3.446c-.1102.1074-.1654.1612-.201.2251a.4994.4994 0 0 0-.0595.183c-.0088.0726.0042.1485.0302.3002l.8349 4.8674c.088.5134.132.7701.0493.9224a.5.5 0 0 1-.3482.253c-.1705.0316-.401-.0897-.862-.3321l-4.3727-2.2996c-.1363-.0716-.2044-.1074-.2762-.1215a.4983.4983 0 0 0-.1924 0c-.0718.0141-.1399.0499-.2761.1215l-4.3728 2.2996c-.461.2424-.6915.3637-.862.3321a.5001.5001 0 0 1-.3482-.253c-.0827-.1523-.0387-.409.0494-.9224l.8348-4.8674c.026-.1517.039-.2276.0302-.3002a.4994.4994 0 0 0-.0595-.183c-.0356-.0639-.0907-.1177-.201-.2251l-3.538-3.446c-.373-.3632-.5594-.5448-.582-.7166a.5.5 0 0 1 .1327-.4094c.1191-.1258.3767-.1634.8917-.2387l4.8913-.715c.1521-.0222.2282-.0333.2944-.064a.5002.5002 0 0 0 .1555-.1131c.0497-.0535.0837-.1225.1518-.2603l2.1867-4.43Z"
    />
  </svg>
);
export default Star01;
