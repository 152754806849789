/** @jsx jsx */

import React from 'react';
import { css, jsx, keyframes } from '@emotion/core';

import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import { ITheme } from '@commandbar/internal/client/theme';
import NextStepCommandsMenu from '../NextStepCommandsMenu';
import { PreviewEngagementType } from '@commandbar/internal/client/EventHandler';
import useHasScroll from '../../hooks/useHasScroll';

const slideIn = keyframes`
0% {
  transform: translateX(100%);
}
`;

const slideOut = keyframes`
100% {
  transform: translateX(110%);
}
`;

export const InlinePreview = (props: {
  content: JSX.Element[];
  contentContainerRef: React.RefObject<HTMLDivElement>;
  title?: string;
  isMounted: boolean;
  reportPreviewEvent: (eventType: string, engagement?: PreviewEngagementType) => void;
}) => {
  const theme: ITheme = useCommandBarTheme();
  const { hasScrolled } = useHasScroll(props.contentContainerRef);

  React.useEffect(() => {
    if (hasScrolled) {
      props.reportPreviewEvent('preview_engagement', 'scrolled');
    }
  }, [hasScrolled]);

  return (
    <div
      ref={props.contentContainerRef}
      css={css`
        animation: ${props.isMounted ? slideIn : slideOut} ${theme.detailPreview.animationDuration} forwards;
        animation-timing-function: cubic-bezier(0.15, 1, 0.25, 1);
      `}
      style={{
        transform: 'translateX(0%)',
        width: theme.detailPreview.width,
        height: theme.bar.menuHeight,
        overflow: 'auto',
        color: theme.base.fontColor,
        fontSize: '14px',
        lineHeight: 1.7,
        fontFamily: theme.base.fontFamily,
        background: theme.base.background,
        boxShadow: '-4px 0px 14px -4px rgb(0 0 0 / 25%)',
        clipPath: 'inset(0px 0px 0px -15px)',
        borderTop: theme.categoryHeader.borderTop,
        textAlign: 'left',
        scrollBehavior: 'smooth',
        padding: theme.detailPreview.padding,
        zIndex: '10',
        top: '0',
        right: '0',
        position: 'absolute',
      }}
    >
      <div style={{ position: 'sticky', top: '0' }}>
        <NextStepCommandsMenu
          isHidden={theme.nextStepCommands.hidden === 'true'}
          width="50%"
          reportPreviewEvent={props.reportPreviewEvent}
        />
      </div>
      {props.title && (
        <h1
          style={{
            display: theme.detailPreview.titleDisplay,
            fontSize: theme.detailPreview.titleFontSize,
            lineHeight: theme.detailPreview.titleLineHeight,
            fontWeight: theme.detailPreview.titleFontWeight,
            color: theme.detailPreview.titleColor,
            margin: theme.detailPreview.titleMargin,
            padding: theme.detailPreview.titlePadding,
            backgroundColor: theme.detailPreview.titleBackgroundColor,
            borderRadius: theme.detailPreview.titleBorderRadius,
          }}
        >
          {props.title}
        </h1>
      )}
      {props.content}
    </div>
  );
};
