import { State } from '../../';
import { ICommandType, IEndUserType, IOrganizationType } from '@commandbar/internal/middleware/types';
import ExecutionPath, { isSelectStep } from '../../../engine/ExecutionPath';
import { selectInitialValueCallbacks } from '../selectors';
import * as Engine from '../actions';
import Logger from '@commandbar/internal/util/Logger';
import { hasInitialValueFnDefined } from '../../../engine/option/OptionValidate';
import { setStepSelections } from '../steps';
import isEqual from 'lodash/isEqual';
import { queryHelpDocCommands } from '../../../client_api/search';
import axios from 'axios';
import { getSentry } from '@commandbar/internal/util/sentry';

const requestInstanceKey = 'commandbar-hotload-commands';

function DebounceWithCancellation<T>(
  fn: (abort: AbortController) => (...args: any[]) => Promise<T>,
  timeout: number,
): (...args: any[]) => Promise<T> {
  let timeoutId: any = null;
  let abortLastPromise: AbortController | null = null;
  return (...args: Parameters<ReturnType<typeof fn>>) => {
    if (timeoutId) {
      abortLastPromise?.abort();
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    return new Promise<T>((resolve, reject) => {
      timeoutId = setTimeout(() => {
        abortLastPromise = new AbortController();
        fn(abortLastPromise)(...args).then(
          (value) => {
            if (!abortLastPromise?.signal.aborted) resolve(value);
          },
          (error) => {
            if (!abortLastPromise?.signal.aborted) reject(error);
          },
        );
      }, timeout);
    });
  };
}

// "default commands" are shown when the input text is empty
let defaultCommands: ICommandType[] | null = null;

const getHotloadedHelpdocCommands = DebounceWithCancellation(
  (abort: AbortController) =>
    async (organization: IOrganizationType, input: string, endUser: IEndUserType | null | undefined) => {
      try {
        return {
          commands: await queryHelpDocCommands(organization.id, input, endUser, abort),
        };
      } catch (e: any) {
        if (!axios.isCancel(e) && e?.request?.status !== 404) {
          getSentry()?.captureException(e);
        }
      }
      return null;
    },
  150,
);

export const updateHotloadedHelpdocCommands = async (_: State) => {
  if (!_.engine.organization?.has_hotloaded_help_docs || !_.engine.organization?.in_bar_doc_search) {
    return;
  }

  const setLoading = (isLoading: boolean) => {
    _.loadingByKey[requestInstanceKey] = isLoading;
  };

  // NOTE: going to try commenting this out -- a better experience if we leave existing results in place while fetching new ones
  // NOTE2: DO NOT COMMENT THIS OUT; if you do, extremely poor performance when typing quickly due to excessive React rerendering of the options list (I think)
  _.engine.hotloadedCommands = [];

  const inputText = _.engine.inputText;

  if (_.engine.visible) {
    setLoading(true);
  }

  // if we've previously fetched default commands, use them (and re-fetch in case they've been updated)
  if (inputText === '' && defaultCommands !== null) {
    _.engine.hotloadedCommands = defaultCommands;
  }

  try {
    const result = await getHotloadedHelpdocCommands(_.engine.organization, inputText, _.engine?.endUser);
    if (!result) return;

    const { commands } = result;

    if (commands && Array.isArray(commands)) {
      _.engine.hotloadedCommands = commands.map((command, idx) => {
        let modifiedArguments = command.arguments;
        if (
          command.template.type === 'helpdoc' &&
          _.engine.organization?.helphub_enabled &&
          !_.engine.organization.helphub_chat_only_mode
        ) {
          // remove __html__ special argument so that help doc will open in HelpHub rather than Dashboard
          const { __html__, ...rest } = command.arguments;
          modifiedArguments = rest;
        }

        return {
          ...command,
          arguments: modifiedArguments,
          sort_key: idx, // sort by order returned by Elastic Search -- NOT the order dictated by the user in the Editor
          isAsync: true /* this causes the command to bypass filtering and always be displayed */,
        };
      });

      // store "default commands" if the input text is empty for future use
      if (inputText === '') defaultCommands = [..._.engine.hotloadedCommands];
    }
  } finally {
    setLoading(false);
  }
};

export const callAllLoaders = (_: State) => {
  // Note: we fetch initial values on open & close so that async initial values show up immediately when the bar opens
  // FIXME: One improvement here could be to only call it on first load & open
  return Promise.all(selectInitialValueCallbacks(_).map((k) => callLoader(_, k)));
};

const callLoader = async (_: State, callbackKey: string) => {
  const contextKey = callbackKey.split('commandbar-initialvalue-')[1];
  try {
    const results = await _.engine.callbacks[callbackKey]();
    const prevResults = _.engine.context[contextKey];
    if (prevResults !== results && !isEqual(prevResults, results)) Engine.addContext(_, { [contextKey]: results });
  } catch (e) {
    Logger.error(`Function loader caused an error. Key: ${contextKey}`);
  }
};

// FIXME: Handler to update preselected values for steps when preselected values are async
// This is a quick solution, we should probably just handle all preselection in the same place
// Doesn't need to be done when commands are chosen
export const handleAsyncPreselect = async (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  if (!isSelectStep(currentStep)) return;
  if (!!currentStep?.argument?.preselected_key) {
    if (hasInitialValueFnDefined(currentStep?.argument?.preselected_key, _.engine)) {
      // Abstract this out
      const fnName = `commandbar-initialvalue-${currentStep?.argument?.preselected_key}`;
      const asyncFn = _.engine.callbacks[fnName];
      const chosenValues = ExecutionPath.selections(_.engine);
      const results = await asyncFn(chosenValues);
      setStepSelections(currentStep, results);
    }
  }
};
