import * as t from 'io-ts';

import { read } from './generics';
import { CommandV } from './command';

const sharedMessageFields = t.type({
  uuid: t.union([t.null, t.string]),
  error: t.string,
  incomplete: t.boolean,
  no_answer: t.boolean,
  used_fallback: t.boolean,
  feedback: t.union([t.null, t.type({ rating: t.union([t.literal(-1), t.literal(0), t.literal(1)]) })]),
  continuations: t.union([t.null, t.array(t.string)]),
  fallback_ctas: t.union([t.null, t.array(t.string)]),
});

export const AIMessageV = t.type({
  message_type: t.literal('AI'),
  value: t.type({
    chat_id: t.string,
    answer: t.string,
    command_id: t.union([t.null, t.number]),
    command_title: t.union([t.null, t.string]),
    passage_id: t.union([t.null, t.number]),
    message_id: t.union([t.null, t.number]),
    doc: t.union([
      t.null,
      t.type({
        title: t.string,
        excerpt: t.string,
        content: t.string,
        command: CommandV,
      }),
    ]),
  }),
  ...sharedMessageFields.props,
});

export const UserMessageV = t.type({
  message_type: t.literal('USER'),
  value: t.type({
    question: t.string,
    command_ids: t.array(t.number),
  }),
  ...sharedMessageFields.props,
});

// deprecated message type
const ContinuationMessageV = t.type({
  message_type: t.literal('CONTINUATION'),
  value: t.any,
  ...sharedMessageFields.props,
});

// deprecated message type
const AnswerMessageV = t.type({
  message_type: t.literal('ANSWER'),
  value: t.any,
  ...sharedMessageFields.props,
});

export const MessageV = t.union([AIMessageV, UserMessageV, ContinuationMessageV, AnswerMessageV]);

export const ChatAnalyticsV = t.type({
  total_count: t.number,
  current_count: t.number,
  next: t.union([t.null, t.number]),
  previous: t.union([t.null, t.number]),
  chat_id_found: t.boolean,

  results: t.array(
    t.type({
      id: t.string,
      messages: t.array(MessageV),
      created: t.string,
      modified: t.string,
      context: t.any,
      organization: t.number,
      end_user: t.union([t.null, t.number]),
    }),
  ),
});

const ChatAnalyticsSummaryV = t.type({
  total_chats: t.number,
  total_chats_change: t.number,
  unique_end_users: t.number,
  unique_end_users_change: t.number,
  percent_chats_without_fallback: t.number,
  percent_chats_without_fallback_change: t.number,
});

export class ChatAnalytics {
  public static listChats = read(ChatAnalyticsV, 'ml/chats');
  public static getSummary = read(ChatAnalyticsSummaryV, 'ml/chats/summary');
}
