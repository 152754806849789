import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';

import { ClickAction, CommandAction, LinkAction, NudgeAction, OpenBarAction } from './helpers/actions';
import { AudienceV } from './helpers/audience';
import {
  CommandGoal,
  ConditionsMetGoal,
  CTAClickedGoal,
  ElementClickedGoal,
  PageVisitedGoal,
  EventTrackedGoal,
} from './helpers/goals';
import { PushTriggerV } from './helpers/pushTrigger';

import { RuleExpressionV } from './helpers/rules';

const ChecklistItemBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      title: t.string,
      goal: t.union([
        CommandGoal,
        PageVisitedGoal,
        ElementClickedGoal,
        ConditionsMetGoal,
        CTAClickedGoal,
        EventTrackedGoal,
      ]),
      action: t.union([LinkAction, ClickAction, CommandAction, NudgeAction, OpenBarAction]),
      celebrate: t.boolean,
    }),
    t.partial({
      description: t.string,
      cta: t.string,
      celebration: t.union([t.literal('confetti'), t.literal('coin')]),
    }),
  ],
  'ChecklistItemBase',
);
type IChecklistItemBase = t.TypeOf<typeof ChecklistItemBaseV>;

const ChecklistItemAdditionalV = t.type(
  {
    skippable: t.boolean,
  },
  'ChecklistItemAdditional',
);
type IChecklistItemAdditional = t.TypeOf<typeof ChecklistItemAdditionalV>;

export const ChecklistItemV = t.intersection([ChecklistItemAdditionalV, ChecklistItemBaseV], 'ChecklistItem');
type IChecklistItem = t.TypeOf<typeof ChecklistItemV>;

export const ChecklistBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      title: t.string,
      show_expression: RuleExpressionV,
      trigger: PushTriggerV,
      items: t.array(ChecklistItemV),
    }),
    t.partial({
      description: t.string,
      is_live: t.boolean,
      audience: t.union([AudienceV, t.null]),
    }),
  ],
  'ChecklistBase',
);

const ChecklistAdditionalV = t.type(
  {
    celebrate: t.boolean,
    template_source: t.string,
    position: t.union([t.literal('bottomRight'), t.literal('bottomLeft')]),
    skippable: t.boolean,
    open_by_default: t.boolean,
    share_page_url: t.string,
  },
  'ChecklistAdditional',
);

const defaults: t.TypeOf<typeof ChecklistAdditionalV> = {
  celebrate: false,
  position: 'bottomRight',
  open_by_default: true,
  skippable: true,
  template_source: 'none',
  share_page_url: '',
};

export const ChecklistV = t.intersection([ChecklistBaseV, ChecklistAdditionalV], 'Checklist');

const itemDefaults: IChecklistItemAdditional = {
  skippable: false,
};

export class Checklist {
  public static decode = (data: any) => {
    const items = data.items.map(
      (item: IChecklistItemBase & Partial<IChecklistItemAdditional>): IChecklistItem => ({
        ...itemDefaults,
        ...item,
      }),
    );
    return decodeThrowing(ChecklistV, { ...defaults, ...data, items });
  };

  public static create = createObject(ChecklistV, ChecklistV, 'checklists');
  public static update = updateObject(ChecklistV, ChecklistV, 'checklists');
  public static delete = deleteObject(ChecklistV, 'checklists');
  public static list = listObject(ChecklistV, 'checklists');
  public static read = readObject(ChecklistV, 'checklists');
}
