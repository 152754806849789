/** @jsx jsx */
import React, { CSSProperties } from 'react';

import { jsx } from '@emotion/core';

import { useAction } from '../../../hooks/useAction';
import { useStore } from '../../../hooks/useStore';
import * as App from '../../../store/app/actions';
import * as Engine from '../../../store/engine/actions';
import { getEnvOrVersion } from '../../../client_api/config';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import Tag from '../../Tag';
import chroma from 'chroma-js';
import { useCommandBarTheme } from '../../../hooks/useCommandBarTheme';
import { AiOutlineClose, AiOutlineEllipsis } from 'react-icons/ai';
import { checkForEditor, clickEditorHandle, getEditorHandle } from '../../../store/util/editorUtils';
import Tooltip from '../../Tooltip';
import { IRelease } from '@commandbar/internal/middleware/types';
import { Releases as ReleasesAPI } from '@commandbar/internal/middleware/releases';
import { isStandaloneEditor } from '@commandbar/internal/util/location';

const labelStyles = (): CSSProperties => ({
  fontSize: 11,
  fontWeight: 600,
  marginRight: 7,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  color: 'hsla(0, 0%, 0%, 0.85)',
});

type VisibilityOptions = 'visible' | 'hidden' | 'minimized';
const SystemPanel = () => {
  const [visibility, setVisibility] = React.useState<VisibilityOptions>('visible');

  const {
    engine: { environments, isAdmin, testMode, organization },
    envOverride,
  } = useStore();

  const showReleasesSelector = !!environments?.length || !!organization?.releases_enabled;
  const customContext = (() => {
    const contextStr = LocalStorage.get('customcontext', '');
    if (typeof contextStr === 'string' && contextStr.length > 0) {
      try {
        return JSON.parse(contextStr);
      } catch (e) {}
    }
    return null;
  })();
  const isActive = !!testMode || (!!environments && !!envOverride) || !!customContext;

  const containerStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: `0px 0px 6px 6px`,
    opacity: isActive ? 1 : 0.7,
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.3) inset',
    background: 'white',
    transition: 'all 0.3s ease-in',
    overflow: 'visible',
  };
  if (!isAdmin) return null;

  const isMinimized = visibility === 'minimized';
  const isHidden = visibility === 'hidden';

  return (
    <div
      id="commandbar-system-panel"
      style={{
        position: 'absolute',
        display: isHidden ? 'none' : 'flex',
        width: '100%',
        justifyContent: 'center',
        color: 'grey',
      }}
    >
      <div
        style={{
          ...containerStyles,
          maxWidth: isMinimized ? '100px' : '1000px',
          height: isMinimized ? 25 : 45,
          transition: 'all 0.3s ease-out',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            aria-hidden={true}
            style={{
              fontSize: 12,
              fontWeight: 600,
              marginLeft: 5,
              marginRight: 5,
              cursor: 'pointer',
              whiteSpace: 'nowrap',
            }}
            onClick={() => (isMinimized ? setVisibility('visible') : setVisibility('minimized'))}
          >
            Editor Panel
          </div>
          {!isMinimized && (
            <React.Fragment>
              <Divider />
              <TestModeSwitch />
              {showReleasesSelector && <Divider />}
              {showReleasesSelector && <ReleasesSelector />}
              {!!customContext && <Divider />}
              {!!customContext && <CustomContextTag context={customContext} />}
              {!isStandaloneEditor && (
                <React.Fragment>
                  <Divider />
                  <MiscHelpersDropdown changePanelVisiblity={(v: VisibilityOptions) => setVisibility(v)} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const Divider = () => {
  return (
    <div
      style={{
        position: 'relative',
        borderLeft: `2px solid rgba(215, 215, 215, 0.5)`,
        marginLeft: 10,
        marginRight: 10,
        height: 31,
      }}
    />
  );
};

const TestModeSwitch = () => {
  const toggleTestMode = useAction(Engine.toggleTestMode);
  const {
    engine: { testMode },
  } = useStore();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={labelStyles()}>Test mode</span>
      <Switch value={testMode} onChange={(_e) => toggleTestMode()} width={30} height={16} spacing={3} />
    </div>
  );
};

const ReleasesSelector = () => {
  const theme = useCommandBarTheme();
  const [releases, setReleases] = React.useState<IRelease[]>([]);
  const setEnvOverride = useAction(App.setEnvOverride);
  const clearEnvOverride = useAction(App.clearEnvOverride);
  const ref = React.useRef<HTMLSelectElement>(null);

  const {
    engine: { environments },
    env,
    envOverride,
    version,
    refContainer,
  } = useStore();

  const envOrVersion = getEnvOrVersion({ env, envOverride, version });

  React.useEffect(() => {
    const getReleases = async () => {
      const releases: IRelease[] = await ReleasesAPI.listReleases();
      // only show last 10 releases to limit dropdown size
      // fixme: add scroll
      setReleases(releases.slice(0, 10));
    };
    getReleases();
  }, []);

  const selectStyles = {
    background: '#F8F8F8',
    border: '2px solid #F2F2F2',
    borderRadius: 5,
    fontSize: 11,
    fontFamily: theme.main.fontFamily,
    outline: 'none',
    cursor: 'pointer',
  };

  return (
    <div>
      {
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={labelStyles()}>Releases</span>
          <select
            ref={ref}
            className={
              /* NOTE: commandbar__system_panel is a special classname to mark this element so
                  that focus won't be pulled away from it. Without this, the code that
                  focuses the main Bar input will pull focus away from the environment
                  override selector. */
              'commandbar__system_panel'
            }
            onBlur={() => {
              refContainer.current?.focus();
            }}
            css={selectStyles}
            value={JSON.stringify(envOrVersion)}
            onChange={(e) => {
              setEnvOverride(JSON.parse(e.target.value));

              // blur so that the main Bar input can take the focus
              ref.current?.blur();
            }}
          >
            {!!environments && (
              <optgroup label="Environments">
                {environments.map(({ env, version }) => (
                  <option disabled={version == null && env !== 'latest'} key={env} value={JSON.stringify({ env })}>
                    {env}
                  </option>
                ))}
              </optgroup>
            )}
            {releases.length > 0 ? (
              <optgroup label="Release versions">
                {releases.map((release) => {
                  const version_num = release.history_event.version_num;
                  return (
                    <option key={version_num} value={JSON.stringify({ version: `v${version_num}` })}>
                      v{release.history_event.version_num}{' '}
                      {release.notes.length > 10 ? release.notes.substr(0, 9) + '...' : release.notes}
                    </option>
                  );
                })}
              </optgroup>
            ) : (
              <optgroup label="Release versions">
                <option disabled>None</option>
              </optgroup>
            )}
          </select>
          {!!envOverride && (
            <Tooltip overlay={'Clear environment changes'} placement="bottom">
              <AiOutlineClose
                onClick={() => clearEnvOverride()}
                style={{ fontSize: 10, marginLeft: 5, cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </div>
      }
    </div>
  );
};

const CustomContextTag = (props: { context: any }) => {
  if (!props.context) return null;

  return (
    <div>
      <span style={labelStyles()}>Custom context</span>
      <Tag color={chroma('grey')} style={{ padding: 2 }}>
        {props.context.name}
      </Tag>
    </div>
  );
};

const Switch = (props: {
  value: boolean;
  onChange: (e: any) => void;
  width: number;
  height: number;
  spacing: number;
}) => {
  const { spacing } = props;
  const checkRadius = props.height - 2 * spacing;

  // turn into props if we need to edit
  const activeColor = 'radial-gradient(87.5% 262.5% at 76.56% 96.88%, #88fbc4 0%, #01dc73 100%)';
  const inactiveColor = 'radial-gradient(93.75% 293.75% at 76.56% 96.87%, #ebe8e8 0%, #9e9e9e 100%)';
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      id="switch"
      style={{ position: 'relative', display: 'inline-block', width: props.width, height: props.height }}
    >
      <input
        type="checkbox"
        checked={props.value}
        onChange={props.onChange}
        style={{ opacity: 0, width: 0, height: 0 }}
      />
      <span
        css={{
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: props.value ? activeColor : inactiveColor,
          transition: '.4s',
          borderRadius: props.height,
          ':before': {
            position: 'absolute',
            background: 'white',
            content: '""',
            height: checkRadius,
            width: checkRadius,
            left: spacing,
            bottom: spacing,
            transition: '.4s',
            borderRadius: '50%',
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            transform: props.value ? `translateX(${props.width - 2 * spacing - checkRadius}px)` : 'translateX(0px)',
          },
        }}
      />
    </label>
  );
};

/** Misc helpers */

const DropdownOption = (props: { children: React.ReactChild; [x: string]: any }) => {
  const { children, ...restProps } = props;
  return (
    <div
      css={{
        fontSize: 10,
        fontWeight: 500,
        background: 'transparent',
        transition: '0.3s all ease-in',
        borderRadius: 3,
        padding: '3px 8px',
        cursor: 'pointer',
        ':hover': {
          background: '#ddd',
        },
      }}
      {...restProps}
    >
      {props.children}
    </div>
  );
};

const DropdownDivider = () => <div style={{ borderBottom: '1px solid #ddd', marginRight: 5, marginLeft: 5 }} />;

const MiscHelpersDropdown = (props: { changePanelVisiblity: (v: VisibilityOptions) => void }) => {
  const theme = useCommandBarTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      aria-hidden={true}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.main.fontFamily,
        color: 'hsla(0, 0%, 0%, 0.85)',
        padding: 4,
      }}
    >
      <AiOutlineEllipsis css={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }} />
      <div
        css={{
          // display: isHovered ? 'inline-block' : 'none',
          position: 'absolute',
          backgroundColor: '#f9f9f9',
          width: '140px',
          boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
          padding: '6px',
          zIndex: 1,
          top: 10,
          marginLeft: '15px',
          overflow: 'hidden',
          borderRadius: 5,
          opacity: isHovered ? 1 : 0,
          display: isHovered ? 'inline-block' : 'none',
          transition: `opacity ${theme.main.transitionTime} ease-out`,
        }}
      >
        <ToggleEditorOption />
        <DropdownDivider />
        <LogContextOption />
        <DropdownDivider />
        <LogCallbacksOption />
        <DropdownDivider />
        <HidePanelOptions changePanelVisiblity={props.changePanelVisiblity} />
        <DropdownDivider />
        <HideHandleOptions />
      </div>
    </div>
  );
};

const ToggleEditorOption = () => {
  const openEditor = useAction(App.openEditor);
  const closeEditor = () => {
    clickEditorHandle();
  };
  /* FIXME: logic here is a bit hacky, probably should have an editor state / open editor attached to the window */
  const isEditorOpen = checkForEditor()?.classList?.contains('drawer-open');
  return (
    <DropdownOption onClick={() => (isEditorOpen ? closeEditor() : openEditor())}>
      <div>{isEditorOpen ? 'Close editor' : 'Open editor'}</div>
    </DropdownOption>
  );
};

const LogContextOption = () => {
  return (
    <DropdownOption onClick={() => console.log(window.CommandBar.shareContext())}>
      <div>Console log context</div>
    </DropdownOption>
  );
};

const LogCallbacksOption = () => {
  // Use Object.assign here to unproxy callbacks
  return (
    <DropdownOption onClick={() => console.log(Object.assign({}, window.CommandBar.shareCallbacks()))}>
      <div>Console log callbacks</div>
    </DropdownOption>
  );
};

const HidePanelOptions = (props: { changePanelVisiblity: (v: VisibilityOptions) => void }) => {
  return (
    <React.Fragment>
      <DropdownOption onClick={() => props.changePanelVisiblity('minimized')}>
        <div>Minimize panel</div>
      </DropdownOption>
      <DropdownDivider />
      <DropdownOption onClick={() => props.changePanelVisiblity('hidden')}>
        <div>Hide panel until next page refresh</div>
      </DropdownOption>
    </React.Fragment>
  );
};

const HideHandleOptions = () => {
  const hideHandle = () => {
    const handle = getEditorHandle();
    if (handle) {
      handle.style.visibility = 'hidden';
    }
  };
  return (
    <React.Fragment>
      <DropdownOption onClick={() => hideHandle()}>
        <div>Hide handle until next page refresh</div>
      </DropdownOption>
    </React.Fragment>
  );
};

export default SystemPanel;
