/** @jsx jsx  */
import { jsx } from '@emotion/core';

import { useStyles } from './useStyles';
import { useStore } from '../../hooks/useStore';

import { translate } from '../../util/languages';
import { ReactComponent as PoweredBySvg } from '../../img/powered.svg';
import { navigateToBrandingSite } from '../../util/branding';

import type { ComponentPropsWithoutRef } from 'react';

const PoweredBy = (buttonProps: Omit<ComponentPropsWithoutRef<'button'>, 'children'>) => {
  const styles = useStyles();
  const { engine } = useStore();
  const { organization } = engine;

  if (organization?.branding === 'branded') {
    return (
      <button css={{ ...styles.poweredBy }} onClick={() => navigateToBrandingSite(organization)} {...buttonProps}>
        <PoweredBySvg role="img" focusable={false} width={16} />
        <span>{translate('Powered by CommandBar')}</span>
      </button>
    );
  }

  return null;
};

export default PoweredBy;
