/** @jsx jsx */
import { jsx } from '@emotion/core';
import { isValidURL } from './utils';
import getPlayer from './players';
import { URLPreview, VideoEmbedPreview } from './Previews';

export interface VideoDashboardProps {
  source: string;
}

const VideoDashboard = ({ source }: VideoDashboardProps) => {
  const isEmbeddablePreview = !isValidURL(source) || getPlayer(source);

  if (isEmbeddablePreview) {
    return <VideoEmbedPreview isDashBoard={true} source={getPlayer(source) || source} />;
  } else {
    return <URLPreview source={source} />;
  }
};

export default VideoDashboard;
