/** @jsx jsx  */
/** @jsxFrag */
import { jsx } from '@emotion/core';
import { useStyles } from './useStyles';
import React from 'react';

export const RecommendationEmptyGrid = () => {
  const styles = useStyles();

  return (
    <div css={styles.recommendedGridItemsEmptyContainer}>
      <div css={styles.recommendedGridItemsEmpty}>
        <div style={styles.recommendedGridItemsEmptyDot}></div>
      </div>
      <div css={styles.recommendedGridItemsEmpty}>
        <div style={styles.recommendedGridItemsEmptyDot}></div>
      </div>
    </div>
  );
};

export const RecommendationEmptyList = ({ numListItems }: { numListItems: number }) => {
  const styles = useStyles();
  const listItems = Array.from(Array(numListItems).keys());
  return (
    <React.Fragment>
      {listItems.map((key) => (
        <div key={key} css={styles.recommendedListItemsEmpty}>
          <div style={styles.recommendedListItemEmptyDot}></div>
          <div>
            <div style={styles.recommendedListItemEmptyRowTop}></div>
            <div style={styles.recommendedListItemEmptyRowBottom}></div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};
