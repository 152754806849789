import * as Command from '@commandbar/internal/middleware/command';
import axiosInstance from '@commandbar/internal/middleware/network';
import { ICommandType } from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { EngineState, getOptionMetadata, selectEndUserEndpoint } from '..';
import * as Reporting from '../../../analytics/Reporting';
import ExecutionPath from '../../../engine/ExecutionPath';
import { ResourceOption } from '../../../engine/option';
import { ExecuteStep } from '../../../engine/step';
import {
  CommandWithTimestamp,
  emptyEndUserStoreState,
  EndUserStore,
  getStoreKey,
  initLocalEndUserState,
  LocalStorageKey,
  RecordWithTimestamp,
} from './state';
import { getCommands } from '../selectors';
import { isRemoteEnduserStoreEnabled } from './selectors';
import Analytics from '../../../analytics/Analytics';
import { createFallbackHmac } from './helpers';

export function setToEndUserStore<T extends LocalStorageKey>(_: EngineState, field: T, value: EndUserStore['data'][T]) {
  const storekey = getStoreKey(field, _.engine.endUser);
  LocalStorage.set(storekey, JSON.stringify(value));
  _.engine.endUserStore.data[field] = value;
}

export function setVerified(_: EngineState, isVerified: boolean) {
  _.engine.endUserStore.verified = isVerified;
}

export function clearFromEndUserStore(_: EngineState, field: LocalStorageKey) {
  const storekey = getStoreKey(field, _.engine.endUser);
  LocalStorage.remove(storekey);

  _.engine.endUserStore.data[field] = emptyEndUserStoreState().data[field];
}

export const storeRecentsOnExecute = (_: EngineState, executeStep: ExecuteStep) => {
  if (!_.engine.organization?.end_user_recents_enabled) return;

  const activeRecord = ExecutionPath.activeRecord(_.engine);
  if (activeRecord) {
    storeRecordOptionAsRecent(_, activeRecord);
  } else {
    storeCommandAsRecent(_, executeStep.command);
  }
};

const storeAsRecent = (_: EngineState, recent: CommandWithTimestamp | RecordWithTimestamp) => {
  const filtered = _.engine.endUserStore.data.recents.filter(({ optionUID: oUID }) => oUID !== recent.optionUID);

  // Sort by most recent first
  const updated = prependToFixedLengthArray(filtered, recent, 20);

  setToEndUserStore(_, 'recents', updated);
};

/** Fixme: For consistency w/ records, should pass in CommandOption here as well instead of command */
const storeCommandAsRecent = (_: EngineState, command: ICommandType) => {
  const commandWithTS: CommandWithTimestamp = {
    type: 'command',
    command,
    timestamp: Date.now(),
    optionUID: Command.commandUID(command),
  };

  storeAsRecent(_, commandWithTS);
};

const prependToFixedLengthArray = <T>(input: T[], item: T, length = 20): T[] => {
  const output = [item].concat(input);
  if (output.length > length) {
    output.splice(length);
  }
  return output;
};

const storeRecordOptionAsRecent = (_: EngineState, option: ResourceOption) => {
  const { uid, contextKey } = getOptionMetadata(option);
  if (!uid || !contextKey) return;

  const recordWithTS: RecordWithTimestamp = {
    type: 'record',
    record: option.parameter,
    categoryKey: contextKey,
    timestamp: Date.now(),
    optionUID: uid,
  };

  storeAsRecent(_, recordWithTS);
};

export const patchRemoteData = async <T extends 'checklist_interactions' | 'nudges_interactions' | 'hotkeys'>(
  _: EngineState,
  updated: EndUserStore['data'][T],
  store: T,
) => {
  if (!_.engine.endUser || !isRemoteEnduserStoreEnabled(_.engine)) return;

  const endpoint = selectEndUserEndpoint(_);

  if (!!endpoint) {
    axiosInstance.patch(
      endpoint,
      { [store]: updated },
      {
        headers: {
          'X-USER-AUTHORIZATION': !!_.engine.endUser.hmac ? _.engine.endUser.hmac : createFallbackHmac(),
        },
      },
    );
  }
};

export const updateEndUserStore = <T extends 'checklist_interactions' | 'nudges_interactions' | 'hotkeys'>(
  _: EngineState,
  records: EndUserStore['data'][T],
  store: T,
) => {
  let updated = _.engine.endUserStore.data[store];

  for (const [key, val] of Object.entries(records)) {
    // Reset to default if val is undefined
    if (val === undefined) {
      const { [key]: k, ...rest } = updated;
      updated = rest;
    } else {
      updated = {
        ...updated,
        [key]: val,
      };
    }
  }

  setToEndUserStore(_, store, updated);

  patchRemoteData(_, updated, store);

  return updated;
};

export const updateEndUserHotkey = (_: EngineState, key: string, val: any) => {
  if (!!_.engine.organization?.end_user_shortcuts_enabled) {
    const store = 'hotkeys';
    const updated = updateEndUserStore(_, { [key]: val }, store);

    const updatedCommand = getCommands(_.engine).find((command) => Command.commandUID(command) === key);
    if (!!updatedCommand) {
      const defaultShortcut = ['windows', 'linux'].includes(_.engine.platform)
        ? updatedCommand?.hotkey_win
        : updatedCommand?.hotkey_mac;
      const oldShortcut = _.engine.endUserStore.data.hotkeys[key] || updatedCommand?.hotkey_win;
      Reporting.changedShortcut(updatedCommand, oldShortcut, val, defaultShortcut || '');
    }

    return updated;
  }
};

export const initFromLocal = (_: EngineState) => {
  const enabledKeys: LocalStorageKey[] = [];
  if (!!_.engine.organization?.end_user_recents_enabled) {
    enabledKeys.push('recents');
  }

  if (!!_.engine.organization?.end_user_shortcuts_enabled) {
    enabledKeys.push('hotkeys');
  }

  if (!Analytics.isSilentMode()) {
    enabledKeys.push('analytics');
  }

  enabledKeys.push('checklist_interactions');
  enabledKeys.push('nudges_interactions');
  enabledKeys.push('properties');

  _.engine.endUserStore = initLocalEndUserState(enabledKeys, _.engine.endUser);
};
