import { DashboardStep, MultiSelectStep, Step, StepWithArgument } from '../../../engine/step';
import { EngineState } from '../state';
import { fulfillExecuteStep } from './execute-step-actions';
import { StepType } from '../../../engine/step/Step';
import { assertNever } from '../../../util/assertNever';
import { selectStep } from './helpers';
import { convertParameterToOption } from '../options';
import { interpolate } from '../../../engine/Interpolate';
import { ResourceOption } from '../../../engine/option';
import get from 'lodash/get';
import { initParameterOption } from '../../../engine/option/ParameterOption';
import { selectOption } from '../../app';
import ExecutionPath from '../../../engine/ExecutionPath';
import { State } from '../..';
import { fulfillAndRebase } from '../actions';

export * from './execute-step-actions';

export const fulfillStep = (_: EngineState, s: Step): Step => {
  switch (s.type) {
    case StepType.Execute:
      return fulfillExecuteStep(_, s);
    case StepType.Base:
    case StepType.LongTextInput:
    case StepType.MultiSelect:
    case StepType.Select:
    case StepType.TextInput:
    case StepType.Dashboard:
      s.completed = true;
      return s;
    default:
      return assertNever(s);
  }
};

export const submitLongTextInput = (_: State, text: string) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  if (currentStep?.type === StepType.LongTextInput) {
    const option = initParameterOption(_, currentStep.argument.userDefinedName, text);
    selectOption(_, option);
  }
};

export const submitMultiSelect = (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  if (currentStep?.type === StepType.MultiSelect) {
    fulfillAndRebase(_);
    // React-select auto updates inputText on select, but here we need to manually trigger it
    //   because we're manually triggering an execution
    // If we don't do this, we'll have a bug where the inputText doesn't reset across steps,
    //   and the options won't be refreshed because we only refresh options if the input is empty.
    if (_.engine.inputText) {
      _.engine.previousInputText = _.engine.inputText;
    }
    _.engine.inputText = '';
    _.engine.rawInput = '';
  }
};

export const submitDashboardStep = (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  if (currentStep?.type === StepType.Dashboard) {
    const option = initParameterOption(_, (currentStep as DashboardStep).argument.userDefinedName, _.engine.inputText);
    selectOption(_, option);
  }
};

export const preSelectIfApplicable = (_: EngineState, s: StepWithArgument, activeResource?: ResourceOption): Step => {
  const arg = s.argument;
  if (arg.preselected_key === undefined) return s;
  if (typeof arg.preselected_key !== 'string') return s;

  // "interpolate handlebars" here if preselected_key starts with "{{"
  // see Interpolate.tsx

  let preSelected;
  const preselected_key = arg.preselected_key;
  if (!preselected_key.startsWith('{{')) {
    preSelected = get(_.engine.context, arg.preselected_key);
  } else {
    preSelected = interpolate(preselected_key, _.engine, true, true, undefined, activeResource);
  }

  if ((preSelected === undefined || preSelected === null) && !!preselected_key) {
    preSelected = preselected_key;
  }

  if (!preSelected) {
    return s;
  }

  if (s.type === StepType.MultiSelect) {
    const _preSelected = Array.isArray(preSelected) ? preSelected : [preSelected];
    let newStep = s;
    _preSelected.forEach((value) => {
      const _parameterOption = convertParameterToOption(_, value, arg);
      newStep = selectStep(newStep, _parameterOption) as MultiSelectStep;
    });
    return newStep;
  } else {
    // Arrays are not valid as preselects for SelectSteps
    // FIXME: How can we report this better to the user?
    if (Array.isArray(preSelected)) return s;

    const _parameterOption = convertParameterToOption(_, preSelected, arg);
    return selectStep(s, _parameterOption);
  }
};
