import { interpret } from 'xstate';

import Logger from '@commandbar/internal/util/Logger';
import { isContextArgument } from '@commandbar/internal/middleware/types';

import { isSelectStep } from '../../../engine/ExecutionPath';
import { sub } from '../../util/sub';
import { State } from '../..';
import getRecordsMachine from './get-records-machine';
import ExecutionPath from '../../../engine/ExecutionPath';
import { getContextSettings } from '../selectors';
import { StepType } from '../../../engine/step/Step';
import { ref } from 'valtio';
import { getSentry } from '@commandbar/internal/util/sentry';

const COMMANDBAR_SEARCH_CALLBACK_PREFIX = 'commandbar-search-';
const isSearchCallback = (key: string) => key.startsWith(COMMANDBAR_SEARCH_CALLBACK_PREFIX);

const contextKeyToKey = (contextKey: string) => contextKey.slice(COMMANDBAR_SEARCH_CALLBACK_PREFIX.length);

type SearchCallbackFn = (input: string) => Promise<any[] | { data: any[] }>;

const spawnRecordsMachines = (_: State) => {
  const machines = _.engine.recordsMachines;

  const searchCallbacks = new Set(Object.keys(_.engine.callbacks).filter(isSearchCallback));

  const machinesToSpawn = new Set(
    [...searchCallbacks].filter(
      (contextKey) =>
        !machines.has(contextKey) || machines.get(contextKey)?.callback !== _.engine.callbacks[contextKey],
    ),
  );
  const machinesToStop = new Set([...machines.keys()].filter((contextKey) => !searchCallbacks.has(contextKey)));

  machinesToSpawn.forEach((contextKey) => {
    /*
     * e.g.
     * contextKey = 'commandbar-search-contacts'
     * key = 'contacts'
     *
     * machines[contextKey] = < instance of getRecordsMachine >
     *
     * callback = _.engine.callbacks[contextKey] // a function we call to get the records
     *
     * records[key] =  <results from calling callback>
     * context[key] =  <initial value -- either from a function or static array passed to addRecord()>
     *
     * loadingByKey['contacts'] = true // we set this to true when we start loading
     * loadingByKey['contacts'] = false // we set this to false when we finish loading
     *
     * errors are reported to Sentry via getSentry()?.captureException()
     *
     */
    const callback = _.engine.callbacks[contextKey] as SearchCallbackFn;

    const key = contextKeyToKey(contextKey);
    const existingMachine = _.engine.recordsMachines.get(contextKey);

    if (existingMachine) {
      Logger.info(`Stopping existing machine for ${key}`);
      existingMachine.service.stop();
      _.engine.recordsMachines.delete(contextKey);
    }

    Logger.info('Spawning records machine', key);
    const machine = getRecordsMachine(callback, (e) => {
      getSentry()?.captureException(e);
    });
    const service = interpret(machine);
    machines.set(contextKey, { callback, service });

    service.onTransition((state) => {
      if (!state.changed) /* do nothing if no state change */ return;

      const isLoading = state.hasTag('loading');
      _.loadingByKey[key] = isLoading;

      if (state.context.results !== null) {
        /**
         * NOTE:
         *
         * We "ref" the records array because they're immutable; if/when records are updated
         * (e.g. because we load a new page of results, or search query changes), we re-assign
         * the whole records object here.
         *
         * This improves performance because valtio does not recursively proxy
         * the records array.
         */
        _.engine.records[key] = {
          records: ref(state.context.results),
        };
      } else {
        if (_.engine.records[key] && !isLoading) {
          delete _.engine.records[key];
        }
      }
    });
    service.start();
  });

  machinesToStop.forEach((contextKey) => {
    machines.get(contextKey)?.service.stop();
    machines.delete(contextKey);
  });
};

const setInputText = (_: State) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);
  const contextSettings = getContextSettings(_.engine);

  const isTopLevel =
    (currentStep?.type === StepType.Base && currentStep.resource === null) || currentStep === undefined;
  const searchableFromTopLevel = Object.keys(contextSettings).filter((key) => !!contextSettings[key].search);

  const activeContextKey =
    isSelectStep(currentStep) && isContextArgument(currentStep.argument) ? currentStep.argument.value : undefined;

  const shouldRunCallback = (contextKey: string): boolean => {
    // Search if:
    //    1. Top level
    //    2. Active argument is a searchable context key
    const key = contextKeyToKey(contextKey);
    if (isTopLevel && searchableFromTopLevel.includes(key)) {
      return true;
    }
    if (!isTopLevel && activeContextKey === key) {
      return true;
    }
    return false;
  };

  _.engine.recordsMachines.forEach(({ service }, contextKey) => {
    if (!shouldRunCallback(contextKey)) return;

    service.send({ type: 'set input text', inputText: _.engine.inputText });
  });
};

const initSubs = (_: State) => [
  sub(_, spawnRecordsMachines, [['engine', 'callbacks']]),
  sub(_, setInputText, [['engine', 'inputText']]),
];

export default initSubs;
